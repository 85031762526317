import UserObject from '../util/UserObject';

export function navigationGuard(to, from, next) {
  const userRoles = UserObject?.employeeRoles || []; // Safely access employeeRoles



  // If the route has access restrictions
  if (to.meta?.teams) {
    const hasAccess = to.meta.teams.some((team) =>
      userRoles.some((userRole) => userRole.team === team)
    );

    if (!hasAccess) {
      next({ name: 'AccessDenied' }); // Redirect to Access Denied page
      return;
    }
  }

  next(); // Allow navigation if access is granted
}
