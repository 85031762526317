<template>
  <div
    class="modal fade"
    id="folderModal"
    tabindex="-1"
    aria-labelledby="folderModalLabel"
    aria-hidden="true"
    data-bs-backdrop="false"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <div style="display: flex; align-items: center">
            <!-- Back Button -->
            <!-- <i
              class="fa-regular fa-circle-left"
              style="padding: 10px; cursor: pointer"
              v-if="folderHierarchy.length > 1"
              @click="goBack(folderHierarchy.length - 2)"
            ></i> -->

            <!-- Folder Hierarchy -->
            <div class="folder-hierarchy">
              <img
                @click="this.fetchFolders()"
                class="homefolder"
                style="width: 25px; height: 25px; padding: 3px"
                src="../../assets/icons/homefolder.png"
              />
              <span
                v-for="(folder, index) in folderHierarchy"
                :key="index"
                style="cursor: pointer; padding: 5px"
                @click="fetchChild(folder.id, folder.name)"
              >
                {{ folder.name }}
                <span v-if="index !== folderHierarchy.length - 1">
                  <i class="fa-solid fa-chevron-right"></i>
                </span>
              </span>
            </div>
          </div>

          <div class="buttons-section">
            <button class="btn btn-primary btn-sm" @click="toggleFolderInput">
              Create Folder
            </button>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
        </div>

        <div class="modal-body">
          <div class="folders-container" v-for="folder in folders" :key="folder.id">
            <div
              class="folder-item"
              v-if="folders.length >= 0"
              :key="folder.id"
              @click="fetchChild(folder.id, folder.folderName)"
            >
              <img class="folder-img" src="../../assets/icons/folders.png" />
              {{ folder.folderName }}
            </div>

            <div v-else class="folder-item">
              <i class="bi bi-folder-fill"></i>
              <input
                type="text"
                readonly
                placeholder="No Folders Available"
                class="form-control"
              />
            </div>
          </div>

          <div class="folder-item" v-if="showFolderInput">
            <img class="folder-img" src="../../assets/icons/folders.png" />

            <!-- Input for folder name -->
            <input
              type="text"
              v-model="NewFolder"
              placeholder="New Folder Name"
              class="form-control"
            />

            <!-- Checkbox -->
            <label>
              <input
                type="checkbox"
                :disabled="!NewFolder"
                v-model="isChecked"
                @change="createFolder"
              />
            </label>
          </div>
        </div>
        <div class="modal-footer">
          <div class="buttons">
            <!-- <button
              type="button"
              v-if="this.showFolderInput == true"
              class="btn btn-primary"
              @click="createFolder()"
            >
              Save Folder
            </button>
            <button type="button" v-else class="btn btn-primary" @click="MoveNote()">
              Save
            </button> -->
            <button
              type="button"
              class="btn btn-primary"
              v-if="this.type == 'Folder'"
              @click="MoveFolder()"
            >
              Move Folder
            </button>

            <button
              type="button"
              v-if="this.type == 'Note'"
              class="btn btn-primary"
              @click="MoveNote()"
            >
              Move Note
            </button>

            <button
              type="button"
              class="btn btn-secondary"
              @click="FolderidNull()"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    FolderId: {
      type: Number,
      required: true,
    },
    NoteId: {
      type: Number,
      required: true,
    },
    clickedFolder: {
      type: Number,
      required: false,
    },
    type: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      folders: [],
      folderHierarchy: [],
      selectingfolderId: null,
      folderID: "",
      folderName: "",
      NewFolder: "",
      showFolderInput: false,
      isChecked: false, // Tracks the state of the checkbox
    };
  },

  watch: {
    FolderId: {
      immediate: true,
      handler(newVal) {
        if (newVal !== undefined && newVal !== null) {
          this.selectingfolderId = newVal;
          this.fetchFolders();
        }
      },
    },

    clickedFolder: {
      immediate: true,
      handler(newVal) {
        if (newVal !== undefined && newVal !== null) {
          this.folderID = newVal;
          console.log("recieved clicked folder id is:", this.folderID);
          this.fetchFolders();
        }
      },
    },
  },

  mounted() {
    this.fetchFolders();
  },

  methods: {
    toggleFolderInput() {
      this.showFolderInput = !this.showFolderInput;
      console.log("im recieving prentFolder id as:", this.selectingfolderId);
    },

    // async fetchFolders() {
    //   console.log(`Modal Page` + this.selectingfolderId);

    //   try {
    //     const response = await axios.get(
    //       `${this.$apiEndPoint}/noteFolder/getFolders/${this.selectingfolderId}`
    //     );
    //     this.folders = response.data;
    //     console.log("Fetched folders:", this.folders);
    //     console.log("parentid recieved is :", this.FolderId);

    //     this.FolderidNull();
    //   } catch (error) {
    //     console.error("Error fetching folders:", error);
    //   }
    // },

    async fetchFolders() {
      console.log(`Modal Page - selectingfolderId:`, this.selectingfolderId);
      console.log(`Current Folder ID (should be excluded):`, this.folderID);
      this.selectingfolderId = 0;
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/noteFolder/getFolders/${this.selectingfolderId}`
        );

        // Exclude the folder with the same ID as the one being moved
        this.folders = response.data.filter((folder) => folder.id !== this.folderID);
        this.FolderidNull();
        console.log("Fetched folders (excluding selected folder):", this.folders);
      } catch (error) {
        console.error("Error fetching folders:", error);
      }
    },

    async onCheckboxChange() {
      if (this.isChecked) {
        await this.createFolder(); // Call the createFolder function
      }
    },

    // async createFolder() {
    //   try {
    //     console.log("Starting createFolder method");
    //     console.log("Parent Folder ID:", this.selectingfolderId);
    //     console.log("New Folder Name:", this.NewFolder);

    //     const updatedFolder = {
    //       parentFolderId: this.selectingfolderId, // Ensure `this.folderId` is set correctly
    //     };
    //     const response = await axios.post(
    //       `${this.$apiEndPoint}/noteFolder/createFolder`,
    //       {
    //         parentFolderId: this.selectingfolderId || 0,
    //         folderName: this.NewFolder,
    //       }
    //     );

    //     console.log("Response received:", response.data);
    //     this.fetchFolders();
    //     this.NewFolder = "";
    //     this.showFolderInput = false;
    //     this.isChecked = false;

    //     const Folder = response.data;
    //     Folder.contentType = "Folder";

    //     this.$toast("Folder Created", "success");
    //   } catch (error) {
    //     console.error("Error creating folder:", error);
    //     this.$toast("Failed to create folder", "error");
    //   }
    // },

    // async createFolder() {
    //   try {
    //     console.log("Starting createFolder method");
    //     console.log("Parent Folder ID:", this.selectingfolderId);
    //     console.log("New Folder Name:", this.NewFolder);

    //     const updatedFolder = {
    //       parentFolderId: this.selectingfolderId,
    //       folderName: this.NewFolder,
    //     };

    //     console.log("emitting folder data:", updatedFolder);
    //     this.NewFolder = "";
    //     this.showFolderInput = false;
    //     this.isChecked = false;
    //     this.$emit("creating-folder", updatedFolder); // Emitting the event with updatedNote
    //     this.fetchFolders();
    //   } catch (error) {
    //     console.error("Error creating folder:", error);
    //     this.$toast("Failed to create folder", "error");
    //   }
    // },

    async createFolder() {
      try {
        console.log("Starting createFolder method");
        console.log("Parent Folder ID:", this.selectingfolderId);
        console.log("New Folder Name:", this.NewFolder);

        const response = await axios.post(
          `${this.$apiEndPoint}/noteFolder/createFolder`,
          {
            parentFolderId: this.selectingfolderId || 0,
            folderName: this.NewFolder,
          }
        );

        console.log("Response received:", response.data);
        this.fetchFolders();
        this.NewFolder = "";
        this.showFolderInput = false;
        this.isChecked = false;

        const Folder = response.data;
        Folder.contentType = "Folder";

        this.$toast("Folder Created", "success");
      } catch (error) {
        console.error("Error creating folder:", error);
        this.$toast("Failed to create folder", "error");
      }
    },

    MoveNote() {
      const updatedNote = {
        folderId: this.selectingfolderId, // Ensure `this.folderId` is set correctly
      };
      console.log("emitting folder id:", this.selectingfolderId);
      // this.selectFolder(this.selectingfolderId, "FolderNamePlaceholder");
      this.$emit("move-note", updatedNote); // Emitting the event with updatedNote
      this.closeFolderModal();
      this.$emit("moving-note", updatedNote); // Emitting the event with updatedNote
      this.closeFolderModal();
    },

    MoveFolder() {
      const updatedFolder = {
        parentFolderId: this.selectingfolderId, // Ensure `this.folderId` is set correctly
      };

      console.log("response", updatedFolder);
      console.log("emitting folder id:", this.selectingfolderId);
      // this.selectFolder(this.selectingfolderId, "FolderNamePlaceholder");
      this.$emit("move-folder", updatedFolder); // Emitting the event with updatedNote
      this.closeFolderModal();
      this.$emit("moving-folder", updatedFolder); // Emitting the event with updatedNote
      this.closeFolderModal();
    },

    closeFolderModal() {
      document.getElementById("folderModal").classList.remove("show");
      document.getElementById("folderModal").style.display = "none";
      document.body.classList.remove("modal-open");
      const backdrop = document.querySelector(".modal-backdrop");
      if (backdrop) backdrop.remove();
    },

    selectFolder() {
      this.$router.push({
        name: "ViewFolder",
        params: {
          Id: this.selectingfolderId,
          folderName: this.folderName,
        },
      });
    },

    FolderidNull() {
      this.$emit("FolderidNull");
      this.$emit("Folderid");
      this.folderHierarchy = [];
      console.log("emiting to make parent folder id to null from child");
    },

    // async fetchChild(id, foldername) {
    //   this.selectingfolderId = id;
    //   console.log(`dobule click `, this.selectingfolderId);

    //   this.folderName = foldername;
    //   console.log("Accessing child of folder ID:", this.selectingfolderId);
    //   this.folderHierarchy.push({ id: this.selectingfolderId, name: this.folderName });

    //   try {
    //     const response = await axios.get(
    //       `${this.$apiEndPoint}/noteFolder/getFolders/${this.selectingfolderId}`
    //     );
    //     this.folders = response.data;
    //     this.showFolderInput = false;
    //     console.log("Fetched folders:", this.folders);
    //   } catch (error) {
    //     console.error("Error fetching folders:", error);
    //   }
    // },

    async fetchChild(id, folderName) {
      this.selectingfolderId = id;
      console.log(`Clicked folder ID:`, this.selectingfolderId);

      this.folderName = folderName;
      console.log("Accessing child of folder ID:", this.selectingfolderId);

      // Update hierarchy without duplicates
      const index = this.folderHierarchy.findIndex((folder) => folder.id === id);
      if (index === -1) {
        this.folderHierarchy.push({ id: this.selectingfolderId, name: this.folderName });
      } else {
        this.folderHierarchy = this.folderHierarchy.slice(0, index + 1);
      }

      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/noteFolder/getFolders/${this.selectingfolderId}`
        );

        this.folders = response.data;
        this.showFolderInput = false;
        console.log("Fetched folders:", this.folders);
      } catch (error) {
        console.error("Error fetching folders:", error);
      }
    },

    goBack(index) {
      // Keep only the clicked level and above
      this.folderHierarchy = this.folderHierarchy.slice(0, index + 1);

      // Fetch data for the selected folder
      const folderId = this.folderHierarchy[index]?.id;
      this.getFolderData(folderId);
    },
  },
};
</script>

<style scoped>
.modal-content {
  margin-top: 20%;
}
.modal-header {
  border-bottom: 1px solid gray;
  display: flex;
  justify-content: space-between; /* Pushes the title and buttons to opposite ends */
  align-items: center; /* Aligns items vertically */
}

.modal-body {
  max-height: 350px;
  min-height: 350px;
  overflow-y: auto;
}

.modal-title {
  margin: 0; /* Keeps the title aligned properly */
}

.buttons-section {
  display: flex; /* Ensures buttons are laid out in a row */
  gap: 20px; /* Adds space between buttons */
  align-items: center; /* Aligns buttons vertically */
}

.folders-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px;
}

.folder-item {
  display: flex;
  align-items: center;
  width: 50%;
  gap: 10px;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
}

.folder-item input {
  width: 100%;
}

.folder-img {
  color: #ffc107; /* Folder icon color */
  width: 25px;
  height: 25px;
}

.modal-footer {
  border-top: 1px solid grey;
  display: flex;
  justify-content: space-between; /* Ensures space between the input and buttons */
  align-items: center; /* Aligns items vertically */
  gap: 10px; /* Adds some spacing between child elements */
}

.modal-footer .Transfer {
  flex-grow: 1; /* Makes the input container take up the remaining space */
}

.modal-footer .form-control {
  width: 100%; /* Ensures the input fills the container */
}

.modal-footer .buttons {
  display: flex;
  gap: 10px; /* Adds spacing between the buttons */
}
</style>
