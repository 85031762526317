import { logout } from './authService';
import { globalState } from "../plugins/Store";

export function handleGlobalError(error, toast) {
  globalState.isLoading = false;

  const status = error.response?.status;
  const data = error.response?.data;

  if (status === 404) return; // Do nothing for 404 errors

  if (status === 401 && data?.message === 'User is not Authorized!') {
    logout();
    return; // Exit early to prevent further execution
  }

  const errorMessage = status === 500 ? 'Something went wrong, please try again later.' :data?.message || error.message;

  if (errorMessage) {
    toast(errorMessage, 'error');
  }
}
