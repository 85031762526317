<template>
  <div>
    <Header />
    <div class="content-container">
      <Nav />
      <div class="containerChild">
        <div class="Performancepage">
          <div class="PerformancepageHeader">
            <h5 class="ms-3">Employee Performance</h5>
            <div class="filters">
              <div class="filter-group">
                <section>Department</section>
                <select
                  v-model="selectedTeam"
                  class="form-select"
                  @change="fetchEmployees(selectedTeam)"
                >
                  <option value="" disabled>Select Department</option>
                  <option
                    v-for="department in employeeRoles"
                    :key="department.team"
                    :value="department.team"
                  >
                    {{ department.team }}
                  </option>
                </select>
              </div>
              <div
                class="filter-group"
                v-if="
                  Designation === 'Manager' ||
                  this.$route.params.moduleName === 'hr' ||
                  this.$LocalUser.employeeRoles.some(
                    (employee) =>
                      employee.team === selectedTeam && employee.role === 'Manager'
                  )
                "
              >
                <section>Employee</section>
                <select
                  v-model="selectedEmployeeId"
                  class="form-select"
                  @change="featchEmployeeDetails()"
                >
                  <option value="" disabled>Select Employee</option>
                  <option
                    v-for="employee in employees"
                    :key="employee.employeeId"
                    :value="employee.employeeId"
                  >
                    {{ employee["employees.firstName"] }}
                    {{ employee["employees.lastName"] }}
                  </option>
                </select>
              </div>

              <button
                class="btn btn-sm btn-light report-btn"
                @click="openApprovalModal()"
              >
                <img
                  src="../../assets/icons/calandar.png"
                  class="calendar-icon"
                  alt="Calendar Icon"
                />
                Report -
                <span v-if="fromDate || toDate">
                  {{ $formatDateDMY(fromDate) }} to {{ $formatDateDMY(toDate) }}
                </span>
                <span v-else>from past week.</span>
              </button>
            </div>
          </div>

          <div class="performance-cards">
            <div class="card employee-details">
              <h6 class="employee-header mb-2">
                <img src="../../assets/icons/profile.png" alt="Profile" class="icon" />
                Employee Details
              </h6>

              <div class="profile mt-2">
                <img
                  v-if="employeeDetails.signedUrl"
                  :src="employeeDetails.signedUrl"
                  alt="Profile"
                  class="profile-img UserProfilePicture"
                  srcset=""
                />
                <img
                  v-else
                  src="../../assets/icons/profile.png"
                  alt="Profile"
                  class="profile-img"
                />
                <div class="profile-info">
                  <h5>{{ employeeDetails.firstName }} {{ employeeDetails.lastName }}</h5>
                  <span class="badge active">Active</span>
                </div>
              </div>

              <!-- Two-column layout for Employee Information -->
              <div class="basic-info">
                <div class="info-section left">
                  <h6>Basic Information</h6>
                  <p>
                    <strong>Name:</strong> <br />
                    {{ employeeDetails.firstName }} {{ employeeDetails.lastName }}
                  </p>
                  <p>
                    <strong>Email ID:</strong> <br />
                    {{ employeeDetails.employeeEmailId }}
                  </p>
                  <p>
                    <strong>Hiring Date:</strong> <br />
                    {{ this.$formatDateDMY(employeeDetails.dateOfHire) }}
                  </p>
                  <p>
                    <strong>Designation:</strong> <br />
                    {{ Designation }}
                  </p>
                </div>

                <div class="info-section right">
                  <h6 style="visibility: hidden">Contact Details</h6>
                  <p>
                    <strong>Employee ID:</strong> <br />
                    {{ employeeDetails.employeeNumber }}
                  </p>
                  <p>
                    <strong>Contact:</strong> <br />
                    {{ employeeDetails.mobileNumber }}
                  </p>
                  <p>
                    <strong>Address:</strong> <br />
                    {{ employeeDetails["addresses.address"] }}
                  </p>
                </div>
              </div>
            </div>
            <div
              class="d-flex justify-content-between gap-3"
              v-if="selectedTeam === 'Sales'"
            >
              <div class="card lead-conversion">
                <h6>Lead Conversation Ratio</h6>
                <div class="gauge mt-3">
                  <div id="" class="mt-2">
                    <apexchart
                      type="radialBar"
                      :options="chartOptionss"
                      :series="seriess"
                      height="260"
                    ></apexchart>
                  </div>

                  <p class="text-start text-muted">
                    Sold Leads: {{ this.leadRatio.soldLeads }}
                  </p>
                  <p class="text-start text-muted">
                    Assigned leads: {{ this.leadRatio.totalLeads }}
                  </p>
                </div>
              </div>

              <div class="card converted-leads">
                <h6>Converted Leads 🚀</h6>
                <div class="lead-box">
                  <p><strong>This Week</strong></p>
                  <p>4 Leads | ₹6,00,000</p>
                  <span class="growth">+12.2%</span>
                </div>
                <div class="lead-box">
                  <p><strong>This Month</strong></p>
                  <p>4 Leads | ₹6,00,000</p>
                  <span class="growth">+12.2%</span>
                </div>
                <div class="lead-box">
                  <p><strong>This Quarter</strong></p>
                  <p>4 Leads | ₹6,00,000</p>
                  <span class="growth">+12.2%</span>
                </div>
                <div class="lead-box">
                  <p><strong>This Year</strong></p>
                  <p>4 Leads | ₹6,00,000</p>
                  <span class="growth">+12.2%</span>
                </div>
              </div>
            </div>

            <div
              class="card attendance-summary overflow-auto"
              v-if="selectedTeam === 'Design'"
            >
              <h6>Project Stage Summary</h6>
              <div id="attendanceChart">
                <apexchart
                  type="area"
                  width="650"
                  :options="designStageChartOptions"
                  :series="designStageSeries"
                ></apexchart>
              </div>
            </div>
            <div
              class="card attendance-summary overflow-auto"
              v-if="selectedTeam === 'Onsite' || selectedTeam === 'Design'"
            >
              <h6>Project Timeline Overview</h6>
              <div id="attendanceChart">
                <apexchart
                  type="bar"
                  width="650"
                  :options="onSiteProjectChartOptions"
                  :series="onSiteProjectSeries"
                ></apexchart>
              </div>
            </div>
            <div
              class="card attendance-summary overflow-auto"
              v-if="selectedTeam === 'Design'"
            >
              <h6>Project Stage-wise Duration Breakdown</h6>
              <div id="attendanceChart">
                <apexchart
                  type="bar"
                  width="650"
                  :options="designProjectChartOptions"
                  :series="designProjectSeries"
                ></apexchart>
              </div>
            </div>

            <div class="card task-summary">
              <h6>Task Summary</h6>
              <div id="attendanceChart">
                <apexchart
                  type="pie"
                  width="500"
                  height="400"
                  :options="chartOptions"
                  :series="series"
                ></apexchart>
              </div>
            </div>

            <div class="card task-summary" v-if="selectedTeam === 'Procurement'">
              <h6>Purchase Request Summary</h6>
              <div id="chart">
                <apexchart
                  type="pie"
                  width="500"
                  height="400"
                  :options="PRChartOptions"
                  :series="PRSeries"
                ></apexchart>
              </div>
            </div>

            <div class="card attendance-summary" v-if="selectedTeam === 'Procurement'">
              <h6>Purchase Order Summary</h6>
              <div id="attendanceChart">
                <apexchart
                  type="pie"
                  width="500"
                  height="400"
                  :options="POChartOptions"
                  :series="POSeries"
                ></apexchart>
              </div>
            </div>
            <div class="card attendance-summary">
              <h6>Attendance Summary</h6>
              <div id="attendanceChart">
                <apexchart
                  type="pie"
                  width="500"
                  height="400"
                  :options="attendanceChartOptions"
                  :series="attendanceSeries"
                ></apexchart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <transition name="slide">
    <!-- Modal -->
    <div class="modal show d-block" v-if="showModal" style="z-index: 99999">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h5 class="modal-title" id="pettyCashReportModalLabel">Report Data</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="showModal = false"
            ></button>
          </div>

          <!-- Modal Body -->
          <div class="modal-body">
            <!-- Form with @submit.prevent to stop reload -->
            <form @submit.prevent="submitReport(fromDate, toDate)">
              <!-- From Date Field -->
              <div class="mb-3">
                <label for="fromDate" class="form-label">From Date</label>
                <input
                  type="date"
                  v-model="fromDate"
                  class="form-control"
                  id="fromDate"
                />
              </div>

              <!-- To Date Field -->
              <div class="mb-3">
                <label for="toDate" class="form-label">To Date</label>
                <input type="date" v-model="toDate" class="form-control" id="toDate" />
              </div>

              <!-- Buttons -->
              <div class="d-flex justify-content-between">
                <!-- Submit Button -->
                <button type="submit" class="btn btn-sm btn-primary">Submit</button>

                <!-- Reset Button -->
                <button
                  type="button"
                  class="btn btn-sm btn-secondary"
                  @click="ResetReport"
                >
                  Reset
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
import Header from "../Header.vue";
import Nav from "../Nav.vue";
import axios from "axios";

export default {
  name: "EmployeePerformance",
  components: {
    Header,
    Nav,
    apexchart: VueApexCharts,
  },
  data() {
    return {
      Designation: "",
      showModal: false,
      employees: [],
      employeeRoles: this.$LocalUser.employeeRoles,
      selectedEmployeeId: this.$LocalUser.user.employeeId,
      selectedTeam: this.$LocalUser.employeeRoles[0].team,
      fromDate: null,
      toDate: null,
      employeeDetails: {},
      leadRatio: {},

      seriess: [],
      onSiteProjectSeries: [],
      onSiteProjectChartOptions: {
        chart: {
          type: "bar",
          height: 1000,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: -6,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        xaxis: {
          categories: [],
        },
      },

      chartOptionss: {
        chart: {
          type: "radialBar",
          offsetY: -20,
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          radialBar: {
            startAngle: -115,
            endAngle: 115,
            track: {
              background: "#e7e7e7",
              strokeWidth: "97%",
              margin: 10, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                color: "#999",
                opacity: 1,
                blur: 2,
              },
            },
            dataLabels: {
              name: {
                fontSize: "16px",
                color: undefined,
                offsetY: 105,
              },
              value: {
                offsetY: -13,
                offsetX: 2,
                fontSize: "22px",
                fontweight: "800",
              },
            },
          },
        },
        grid: {
          padding: {
            top: -10,
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            shadeIntensity: 0.4,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 53, 91],
          },
        },
        labels: [""],
      },
      taskRatio: [0, 0, 0],
      series: [], // Task counts (completed, pending, overdue)
      chartOptions: {
        chart: {
          type: "pie",
          offsetY: 20,
          offsetX: 100,
        },
        labels: ["Completed", "Pending", "Overdue"],
        colors: ["#28a745", "#8979ff", "#ff92ba"], // Soft pastel shades

        dataLabels: {
          enabled: true,
          formatter: (val, opts) => `${opts.w.globals.series[opts.seriesIndex]}`, // Show task count
        },
        legend: {
          position: "right",
          formatter: (seriesName, opts) => {
            const taskCounts = this.taskRatio ?? [0, 0, 0]; // Prevent undefined errors
            return `${seriesName}: ${taskCounts[opts.seriesIndex]} Tasks`;
          },
        },
        title: {
          align: "center",
        },
      },
      attendanceData: {
        present: 0,
        absent: 0,
        leave: 0,
        weeklyOff: 0,
        compOff: 0,
      },
      attendanceSeries: [],
      attendanceChartOptions: {
        chart: {
          type: "pie",
          offsetY: 20,
          offsetX: 100,
        },
        labels: ["Present", "Absent", "Leave", "Weekly Off", "Comp Off"],
        colors: ["#28a745", "#dc3545", "#ffc107", "#007bff", "#6c757d"], // Green, Red, Yellow, Blue, Grey
        dataLabels: {
          enabled: true,
          formatter: (val, opts) => `${opts.w.globals.series[opts.seriesIndex]} Days`,
        },
        legend: {
          position: "right",
          formatter: (seriesName, opts) => {
            const counts = [
              this.attendanceData.present,
              this.attendanceData.absent,
              this.attendanceData.leave,
              this.attendanceData.weeklyOff,
              this.attendanceData.compOff,
            ];
            return `${seriesName}: ${counts[opts.seriesIndex]} Days`;
          },
        },
        title: {
          align: "center",
        },
      },
      PRData: [0, 0, 0, 0, 0, 0, 0],

      PRSeries: [],
      PRChartOptions: {
        chart: {
          type: "pie",
          offsetY: 20,
          offsetX: 100,
        },
        labels: [
          "Draft",
          "Pending",
          "Approved",
          "Ordered",
          "Delivered",
          "SemiDelivered",
          "Rejected",
        ],
        colors: [
          "#28a745", // Draft - Green
          "#dc3545", // Pending - Red
          "#ffc107", // Approved - Yellow
          "#007bff", // Ordered - Blue
          "#17a2b8", // Delivered - Teal (Different from Blue)
          "#6f42c1", // SemiDelivered - Purple (Different from Blue & Grey)
          "#6c757d", // Rejected - Grey
        ],

        dataLabels: {
          enabled: true,
          formatter: (val, opts) => `${opts.w.globals.series[opts.seriesIndex]} `,
        },
        legend: {
          position: "right",
          formatter: (seriesName, opts) => {
            const taskCounts = this.PRData ?? [0, 0, 0, 0, 0, 0, 0]; // Prevent undefined errors
            return `${seriesName}: ${taskCounts[opts.seriesIndex]}`;
          },
        },
        title: {
          align: "center",
        },
      },
      POData: [0, 0, 0, 0, 0, 0, 0],
      POSeries: [],
      POChartOptions: {
        chart: {
          type: "pie",
          offsetY: 20,
          offsetX: 100,
        },
        labels: [
          "Draft",
          "Pending",
          "Approved",
          "Ordered",
          "Delivered",
          "SemiDelivered",
          "Rejected",
        ],
        colors: [
          "#28a745", // Draft - Green
          "#dc3545", // Pending - Red
          "#ffc107", // Approved - Yellow
          "#007bff", // Ordered - Blue
          "#17a2b8", // Delivered - Teal (Different from Blue)
          "#6f42c1", // SemiDelivered - Purple (Different from Blue & Grey)
          "#6c757d", // Rejected - Grey
        ],

        dataLabels: {
          enabled: true,
          formatter: (val, opts) => `${opts.w.globals.series[opts.seriesIndex]} `,
        },
        legend: {
          position: "right",
          formatter: (seriesName, opts) => {
            const taskCounts = this.POData ?? [0, 0, 0, 0, 0, 0, 0]; // Prevent undefined errors
            return `${seriesName}: ${taskCounts[opts.seriesIndex]}`;
          },
        },
        title: {
          align: "center",
        },
      },
      designProjectSeries: [],
      designProjectChartOptions: {},
      designStageSeries: [],
      designStageChartOptions: {},
    };
  },
  computed: {
    dynamicChartHeight() {
      return this.onSiteProjectChartOptions.xaxis.categories.length * 50; // Adjust multiplier as needed
    },
  },
  mounted() {
    // Ensure you're accessing route parameters correctly
    if (this.$route.params.moduleName === "hr") {
      this.employeeRoles = [
        { team: "Sales" },
        { team: "Design" },
        { team: "Onsite" },
        { team: "Procurement" },
        { team: "Inventory" },
        { team: "Accounts" },
        { team: "HR" },
        { team: "Admin" },
      ];
    }
    this.selectedTeam = this.employeeRoles[0].team;
    this.featchEmployeeDetails();

    if (this.selectedTeam) {
      this.fetchEmployees(this.selectedTeam);
    }
    this.fetchAttendanceSummary();
  },

  methods: {
    fetchPurchaseSummary() {
      axios
        .post(
          `${this.$apiEndPoint}/performanceReport/procurementReport/${this.selectedEmployeeId}`,
          {
            date: {
              fromDate: this.fromDate,
              toDate: this.toDate,
            },
          }
        )
        .then((response) => {
          const data = response.data;
          this.PRData = [
            parseInt(data.prReport.draft || "0"),
            parseInt(data.prReport.pending || "0"),
            parseInt(data.prReport.approved || "0"),
            parseInt(data.prReport.ordered || "0"),
            parseInt(data.prReport.delivered || "0"),
            parseInt(data.prReport.semiDelivered || "0"),
            parseInt(data.prReport.rejected || "0"),
          ];

          // Update chart series with attendance counts
          this.PRSeries = [
            parseFloat(data.prReport.draftPercentage),
            parseFloat(data.prReport.pendingPercentage),
            parseFloat(data.prReport.approvedPercentage),
            parseFloat(data.prReport.orderedPercentage),
            parseFloat(data.prReport.deliveredPercentage),
            parseFloat(data.prReport.rejectedPercentage),
            parseFloat(data.prReport.semiDeliveredPercentage),
          ];

          this.PRChartOptions.legend.formatter = (seriesName, opts) => {
            const taskCounts = this.PRData; // Use updated task counts
            const index = opts.seriesIndex;
            return `${seriesName}: ${taskCounts[index]} PR`; // Format: "Pending: 0 Tasks"
          };
          // Update legend formatter dynamically

          this.POData = [
            parseInt(data.poReport.draft || "0"),
            parseInt(data.poReport.pending || "0"),
            parseInt(data.poReport.approved || "0"),
            parseInt(data.poReport.ordered || "0"),
            parseInt(data.poReport.delivered || "0"),
            parseInt(data.poReport.semiDelivered || "0"),
            parseInt(data.poReport.rejected || "0"),
          ];

          // Update chart series with attendance counts
          this.POSeries = [
            parseFloat(data.poReport.draftPercentage),
            parseFloat(data.poReport.pendingPercentage),
            parseFloat(data.poReport.approvedPercentage),
            parseFloat(data.poReport.orderedPercentage),
            parseFloat(data.poReport.deliveredPercentage),
            parseFloat(data.poReport.rejectedPercentage),
            parseFloat(data.poReport.semiDeliveredPercentage),
          ];

          this.POChartOptions.legend.formatter = (seriesName, opts) => {
            const taskCounts = this.POData; // Use updated task counts
            const index = opts.seriesIndex;
            return `${seriesName}: ${taskCounts[index]} PO`; // Format: "Pending: 0 Tasks"
          };
        })
        .catch((error) => {
          console.error("Error fetching attendance summary:", error);
        });
    },
    submitReport(fromDate, toDate) {
      this.fromDate = fromDate;
      this.toDate = toDate;

      this.featchEmployeeDetails();
      this.showModal = false;
    },
    ResetReport() {
      this.fromDate = null;
      this.toDate = null;

      this.featchEmployeeDetails();
      this.showModal = false;
    },

    fetchAttendanceSummary() {
      axios
        .post(
          `${this.$apiEndPoint}/performanceReport/attendanceSummary/${this.selectedEmployeeId}`,
          {
            date: {
              fromDate: this.fromDate,
              toDate: this.toDate,
            },
          }
        )
        .then((response) => {
          const data = response.data;
          this.attendanceData = {
            present: parseInt(data.present || "0"),
            absent: parseInt(data.absent || "0"),
            leave: parseInt(data.leave || "0"),
            weeklyOff: parseInt(data.weeklyOff || "0"),
            compOff: parseInt(data.compOff || "0"),
          };

          // Update chart series with attendance counts
          this.attendanceSeries = [
            this.attendanceData.present,
            this.attendanceData.absent,
            this.attendanceData.leave,
            this.attendanceData.weeklyOff,
            this.attendanceData.compOff,
          ];

          // Update legend formatter dynamically
          this.attendanceChartOptions = {
            ...this.attendanceChartOptions,
            legend: {
              position: "right",
              formatter: (seriesName, opts) => {
                const counts = this.attendanceSeries;
                return `${seriesName}: ${counts[opts.seriesIndex]} Days`;
              },
            },
          };
        })
        .catch((error) => {
          console.error("Error fetching attendance summary:", error);
        });
    },
    fetchTaskSummary() {
      axios
        .post(
          `${this.$apiEndPoint}/performanceReport/taskSummary/${this.selectedEmployeeId}`,
          {
            date: {
              fromDate: this.fromDate,
              toDate: this.toDate,
            },
          }
        )
        .then((response) => {
          const data = response.data;
          this.taskRatio = [
            parseInt(data.completedTasks), // Completed
            parseInt(data.pendingTasks), // Pending
            parseInt(data.overdueTasks), // Overdue
          ];

          // Update chart series (Task counts instead of percentages)
          this.series = [
            parseFloat(data.completedPercentage), // Completed
            parseFloat(data.pendingPercentage), // Pending
            parseFloat(data.overduePercentage), // Overdue
          ];

          // Fix the legend to display counts properly
          this.chartOptions.legend.formatter = (seriesName, opts) => {
            const taskCounts = this.taskRatio; // Use updated task counts
            const index = opts.seriesIndex;
            return `${seriesName}: ${taskCounts[index]} Tasks`; // Format: "Pending: 0 Tasks"
          };
        })
        .catch((error) => {
          console.error("Error fetching task summary:", error);
        });
    },

    async fetchEmployees(Team) {
      console.log("Team: ", Team);
      try {
        const response = await axios.get(`${this.$apiEndPoint}/employee/myTeam/${Team}`);
        this.employees = response.data;
        this.featchEmployeeDetails();
      } catch (error) {
        this.$handleError(error);
      }
    },
    openApprovalModal() {
      this.showModal = !this.showModal;
    },

    featchEmployeeProfile(employeeId) {
      const PayLoad = {
        entityId: employeeId,
        fileCategory: "profile",
        moduleName: "company",
      };

      // Make API request to fetch employee profile photo URL
      axios
        .post(`${this.$apiEndPoint}/company/getFileSignedURL/`, PayLoad)
        .then((response) => {
          // Update profileImage with the received URL
          this.employeeDetails.signedUrl = Object.values(response.data)[0];
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    fetchDesignProjectStatusReport() {
      axios
        .post(
          `${this.$apiEndPoint}/performanceReport/designReport/${this.selectedEmployeeId}`,
          {
            date: {
              fromDate: this.fromDate,
              toDate: this.toDate,
            },
          }
        )
        .then((response) => {
          const apiData = response.data.result;

          // Ensure unique project names
          const categories = apiData.map((item) => String(item["projects.projectName"]));
          const plannedDurations = apiData.map((item) => item.totalPlannedDuration || 0);
          const completedDurations = apiData.map((item) => item.completedDuration || 0);
          const delayDurations = apiData.map((item) => item.delayDuration || 0);

          this.onSiteProjectChartOptions = {
            ...this.onSiteProjectChartOptions, // Keep existing options
            chart: {
              type: "bar",
              height: categories.length * 80, // Dynamic height based on items
            },
            xaxis: {
              categories: categories,
              labels: {
                show: true,
                style: {
                  fontSize: "14px",
                  fontWeight: 600,
                  colors: "#000",
                },
                offsetX: -10,
              },
            },
          };

          this.onSiteProjectSeries = [
            {
              name: "Planned Duration",
              data: plannedDurations,
            },
            {
              name: "Completed Duration",
              data: completedDurations,
            },
            {
              name: "Delay Duration",
              data: delayDurations,
            },
          ];

          //No of Project-Stage wise

          const StageCategories = Object.keys(response.data.stageSummary).filter(
            (key) => key !== "null"
          ); // Remove "null" if needed
          const dataValues = StageCategories.map(
            (key) => response.data.stageSummary[key]
          );

          // Set dynamic height based on number of categories (minimum 350px, increases with more categories)
          const chartHeight = Math.max(320, StageCategories.length * 40);

          // Function to truncate long category names
          const truncateText = (text, length = 12) => {
            if (!text || typeof text !== "string") return ""; // Ensure text is always a valid string
            return text.length > length ? text.substring(0, length) + "..." : text;
          };

          // Update chart data
          this.designStageSeries = [
            {
              name: "Stages",
              data: dataValues,
            },
          ];

          this.designStageChartOptions = {
            chart: {
              type: "line",
              height: chartHeight + "px", // Set dynamic height
            },
            xaxis: {
              categories: StageCategories,
              labels: {
                rotate: -45, // Rotate labels for better visibility
                style: {
                  fontSize: "12px",
                },
                formatter: (value) => truncateText(value, 10), // Apply truncation
              },
            },
            yaxis: {
              title: {
                text: "Count",
              },
            },
            tooltip: {
              x: {
                show: true,
              },
            },
          };

          //Design Stage wise below

          const projectData = response.data.projectStageDurationArray;
          console.log("API Response:", projectData);

          // Step 1: Extract all unique stage names
          let allStages = new Set();
          projectData.forEach((project) => {
            const projectStages = Object.values(project)[0]; // Extract stage object
            Object.keys(projectStages).forEach((stage) => allStages.add(stage));
          });

          const uniqueStages = Array.from(allStages); // Convert Set to Array

          // Step 2: Extract project names and stage-wise data
          let stagecategories = [];
          let stageDataMap = {};

          uniqueStages.forEach((stage) => {
            stageDataMap[stage] = [];
          });

          projectData.forEach((project) => {
            const projectName = Object.keys(project)[0]; // Extract project name
            stagecategories.push(projectName);

            const projectStages = project[projectName];

            uniqueStages.forEach((stage) => {
              stageDataMap[stage].push(projectStages[stage] || 0); // Fill missing values with 0
            });
          });

          // Step 3: Convert to ApexCharts series format
          const updatedSeries = uniqueStages.map((stage) => {
            return {
              name: stage,
              data: stageDataMap[stage],
            };
          });

          // Step 4: Force Vue to reactively update the chart
          this.designProjectSeries = [...updatedSeries];

          this.designProjectChartOptions = {
            chart: {
              type: "bar",
              height: Math.max(stagecategories.length * 50, 300), // Auto-adjust height
              stacked: true,
            },
            plotOptions: {
              bar: {
                horizontal: true,
                barHeight: "60%", // Make bars bigger
                dataLabels: {
                  total: {
                    enabled: true,
                    offsetX: 0,
                    style: {
                      fontSize: "14px",
                      fontWeight: 900,
                    },
                  },
                },
              },
            },
            xaxis: {
              categories: [...stagecategories], // Ensure reactivity
              labels: {
                show: true,
                style: {
                  fontSize: "14px",
                  fontWeight: 600,
                  colors: "#000",
                },
                offsetX: -10,
              },
            },
            yaxis: {
              labels: {
                style: {
                  fontSize: "14px",
                  fontWeight: 600,
                },
              },
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return val.toLocaleString(); // Format numbers properly
                },
              },
            },
            legend: {
              position: "top",
              horizontalAlign: "left",
              offsetX: 40,
            },
          };
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    fetchOnsiteProjectStatusReport() {
      axios
        .post(
          `${this.$apiEndPoint}/performanceReport/onsiteReport/${this.selectedEmployeeId}`,
          {
            date: {
              fromDate: this.fromDate,
              toDate: this.toDate,
            },
          }
        )
        .then((response) => {
          const apiData = response.data;

          // Ensure unique project names
          const categories = apiData.map((item) => String(item["projects.projectName"]));
          const plannedDurations = apiData.map((item) => item.totalPlannedDuration || 0);
          const completedDurations = apiData.map((item) => item.completedDuration || 0);
          const delayDurations = apiData.map((item) => item.delayDuration || 0);

          this.onSiteProjectChartOptions = {
            ...this.onSiteProjectChartOptions, // Keep existing options
            chart: {
              type: "bar",
              height: categories.length * 80, // Dynamic height based on items
            },
            xaxis: {
              categories: categories,
              labels: {
                show: true,
                style: {
                  fontSize: "14px",
                  fontWeight: 600,
                  colors: "#000",
                },
                offsetX: -10,
              },
            },
          };

          this.onSiteProjectSeries = [
            {
              name: "Planned Duration",
              data: plannedDurations,
            },
            {
              name: "Completed Duration",
              data: completedDurations,
            },
            {
              name: "Delay Duration",
              data: delayDurations,
            },
          ];
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    featchLeadConversionRatio() {
      axios
        .post(
          `${this.$apiEndPoint}/performanceReport/salesReport/${this.selectedEmployeeId}`,
          {
            // "interval": "week",
            date: {
              fromDate: this.fromDate,
              toDate: this.toDate,
            },
          }
        )
        .then((response) => {
          console.log("Lead Conversion Ratio: ", response.data);
          this.leadRatio = response.data.leadRatio;
          this.seriess = [parseFloat(response.data.leadRatio.conversionRatio)];
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    featchEmployeeDetails() {
      console.log("Selected Employee ID: ", this.selectedEmployeeId);

      axios
        .get(`${this.$apiEndPoint}/employee/${this.selectedEmployeeId}`)
        .then((response) => {
          this.employeeDetails = response.data;
          this.Designation = this.employeeDetails.employeeRoles.find(
            (role) => role.team === this.selectedTeam
          ).role;
          this.fetchTaskSummary();
          this.fetchAttendanceSummary();
          this.featchEmployeeProfile(response.data.employeeUserId);

          if (this.selectedTeam === "Sales") {
            this.featchLeadConversionRatio();
          }
          if (this.selectedTeam === "Procurement") {
            this.fetchPurchaseSummary();
          }
          if (this.selectedTeam === "Onsite") {
            this.fetchOnsiteProjectStatusReport();
          }
          if (this.selectedTeam === "Design") {
            this.fetchDesignProjectStatusReport();
          }
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
  },
};
</script>

<style scoped>
.PerformancepageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5px;
  border-bottom: 1px solid #ccc;
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: white;
}

.filters {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 16px;
}

.filter-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.filters section {
  font-size: 14px;
  font-weight: 500;
}

select {
  padding: 3px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 12px;
  width: 200px;
  margin-top: 5px;
}

.report-btn {
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 6px 10px;
}

.calendar-icon {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}
.performance-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  margin-top: 20px;
  padding: 10px 15px;
  overflow-y: scroll;
  height: 75vh;
}

.card {
  background: white;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  height: 400px;
}

.employee-details .profile {
  display: flex;
  align-items: center;
  gap: 10px;
}

.profile-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.badge.active {
  background: #28a745;
  color: white;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  margin-top: 8px;
}

.gauge {
  display: flex;
  flex-direction: column;

  font-size: 16px;
  font-weight: bold;
}

.gauge.green {
  color: green;
}

.gauge.red {
  color: red;
}

.lead-box {
  background: #f8f9fa;
  padding: 5px;
  margin-bottom: 5px;
  border-radius: 6px;
}

.growth {
  color: green;
  font-size: 14px;
  font-weight: bold;
}

.chart-img {
  width: 100%;
  height: auto;
}
.employee-details {
  background: white;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.profile {
  display: flex;
  align-items: center;
  gap: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #ddd;
}

.profile-img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.profile-info h5 {
  margin: 0;
  font-size: 18px;
}

.badge.active {
  background: #28a745;
  color: white;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
}

.basic-info {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.info-section {
  flex: 1;
  padding: 10px;
  border-radius: 6px;
}

h6 {
  font-size: 16px;
  margin-bottom: 10px;
  color: #333;
}

.basic-info p {
  font-size: 14px;
  margin: 10px 0;
}
.employee-header {
  display: flex;
  align-items: center;
  gap: 8px; /* Space between icon and text */
  font-size: 16px;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
}

.employee-header .icon {
  width: 20px;
  height: 20px;
}
.lead-conversion,
.converted-leads {
  width: 100%;
}
.lead-box p {
  font-size: 10px;
  padding: 5px;
  margin: 0px;
}

@media screen and (max-width: 768px) {
  .performance-cards {
    grid-template-columns: 1fr;
  }
  .card {
    height: auto;
  }
  .card.attendance-summary {
    height: 500px;
  }
}
</style>
