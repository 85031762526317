<template>
  <div class="EmployeesignupGroup">
    <div class="row d-flex align-items-center">
      <div class="col-md-7 col-sm-7 col-lg-7">
        <div class="loginImg d-flex justify-content-center">
          <img src="../assets/icons/loginImage.png" alt="" />
        </div>
      </div>

      <div class="col-md-5 col-sm-5 col-lg-5">
        <div class="login align-items-center">
          <div class="logo d-flex justify-content-center">
            <h1 class="toolNameFontFamily">arivu</h1>
          </div>
          <div class="Employeesignup">
            <span class="input-wrapper mt-3">
              <label for="password">&nbsp;New Password&nbsp;</label>
              <input
                :type="showPassword ? 'text' : 'password'"
                v-model="password"
                id="password"
              />
              <i
                :class="showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"
                @click="togglePassword('password')"
              ></i>
            </span>
            <span class="input-wrapper mt-5">
              <label for="confirmPassword">&nbsp;Confirm Password&nbsp;</label>
              <input
                :type="showConfirmPassword ? 'text' : 'password'"
                v-model="confirmPassword"
                id="confirmPassword"
              />
              <i
                :class="showConfirmPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"
                @click="togglePassword('confirmPassword')"
              ></i>
            </span>
            <p v-if="passwordMismatch" class="text-danger">Passwords do not match.</p>
            <button id="EmployeeSignButton" @click="changePassword()">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "UpdatePassword",
  data() {
    return {
      password: "",
      confirmPassword: "",
      passwordReset: {},
      email: "",
      passwordMismatch: false,
      showPassword: false,
      showConfirmPassword: false,
    };
  },
  methods: {
    async validateUUID() {
      const response = await axios.get(
        `${this.$apiEndPoint}/users/validateUUID/${this.UUID}`
      );
      if (response.data === null) {
        alert("Password reset link expired, Send Mail Again");
        this.$router.push({ name: "Login" });
      }
      this.passwordReset = response.data;
    },
    async changePassword() {
      if (this.password !== this.confirmPassword) {
        this.passwordMismatch = true;
        return;
      }
      this.passwordMismatch = false;
      const result = await axios.put(
        `${this.$apiEndPoint}/users/updatePassword/${this.UUID}`,
        {
          email: this.passwordReset.email,
          password: this.password,
        }
      );

      alert("Password Changed Successfully");
      if (result.data) {
        this.$router.push({ name: "Login" });
      }
    },
    togglePassword(field) {
      if (field === "password") {
        this.showPassword = !this.showPassword;
      } else if (field === "confirmPassword") {
        this.showConfirmPassword = !this.showConfirmPassword;
      }
    },
  },
  mounted() {
    this.UUID = this.$route.params.UUID;
    this.validateUUID();
  },
};
</script>

<style scoped>
.loginImg img {
  max-width: 80%;
  max-height: 80%;
}

.logo h1 {
  margin-bottom: 8%;
}

.EmployeesignupGroup {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
}

.Employeesignup {
  display: flex;
  margin-top: 2%;
  flex-direction: column;
}

#EmployeeSignButton {
  padding: 5px;
  background-color: #4884f7;
  color: #fff;
  font-size: 1rem;
  border-radius: 5px;
  outline: none;
  border: none;
  cursor: pointer;
  margin: 10% 30%;
  margin-top: 10%;
}

.input-wrapper {
  min-width: 400px;
  height: 45px;
  position: relative;
  display: flex;
  align-items: center;
  border: 1.5px solid black;
  border-radius: 5px;
  padding: 5px;
}

.input-wrapper input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding-left: 10px;
  font-size: 1rem;
  font-weight: bold;
}

.input-wrapper i {
  position: absolute;
  right: 10px;
  cursor: pointer;
  color: #555;
  font-size: 1rem;
}

.input-wrapper label {
  position: absolute;
  top: -10px;
  left: 10px;
  font-size: 1rem;
  font-weight: 500;
  background-color: #ffffff;
  padding: 0 5px;
}

@media (max-width: 768px) {
  .loginImg img {
    display: none;
  }
  .input-wrapper {
    min-width: 320px;
  }
}
</style>
