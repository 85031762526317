<template>
  <div
    class="modal fade"
    id="newNoteModal"
    tabindex="-1"
    aria-labelledby="newNoteModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-3">
        <div class="modal-body">
          <div class="note-input-group">
            <!-- <label for="noteTitle" class="note-label">New Note Title</label> -->
            <input
              type="text"
              class="note-input"
              id="noteTitle"
              v-model="noteTitle"
              placeholder="Write Note Title"
            />
            <button type="button" class="btn btn-primary note-button" @click="saveNote">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- folder section -->

  <div
    class="modal fade"
    id="newFolder"
    tabindex="-1"
    aria-labelledby="newNoteModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-3">
        <div class="modal-body">
          <div class="note-input-group">
            <label for="folderName" class="note-label">Folder Name</label>
            <input type="text" class="note-input" id="noteTitle" v-model="folderName" />
            <button type="button" class="btn btn-primary note-button" @click="saveFolder">
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- folder delete section -->

  <!-- Modal -->
  <div
    class="modal fade"
    id="deleteModal"
    tabindex="-1"
    aria-labelledby="deleteModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="deleteModalLabel">Confirm Deletion</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">Are you sure you want to delete this folder</div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Cancel
          </button>
          <button
            @click="deleteFolder(clickedFolder)"
            type="button"
            class="btn btn-danger"
            id="confirmDeleteBtn"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    folderId: {
      type: Number, // Define the expected type (Number in this case)
      required: false, // It's optional initially if no folder is selected
    },
    clickedFolder: {
      type: Number, // Define the expected type (Number in this case)
      required: false, //
    },
  },
  watch: {
    folderId(newVal) {
      console.log("Received Folder ID from Parent is:", newVal, this.folderId);
    },

    clickedFolderID(newVal) {
      console.log("Received Folder ID from Parent:", newVal, this.clickedFolder);
    },
  },

  data() {
    return {
      noteTitle: "",
      folderName: "",
    };
  },
  methods: {
    saveNote() {
      if (!this.noteTitle.trim()) {
        console.error("Note title cannot be empty!");
        return;
      }

      const noteData = {
        header: this.noteTitle,
        folderId: this.folderId,
      };
      this.closeNoteModal();
      this.$emit("save-note", noteData); // Emit to parent
      this.$emit("saving-note", noteData); //emiting to viewfolder page to create note
      console.log("Emitting note data as:", noteData);
      this.noteTitle = ""; // Clear the input field after emitting
    },

    saveFolder() {
      if (!this.folderName.trim()) {
        console.error("Note title cannot be empty!");
        return;
      }

      const folderData = {
        parentFolderId: this.folderId,
        folderName: this.folderName,
      };
      console.log("Emitting folder data as:", folderData);
      this.$emit("save-folder", folderData); // Emit to parent
      this.$emit("create-folder", folderData); // Emit to parent

      this.folderName = ""; // Clear the input field
      this.closeModal(); // Call the separate closeModal function
    },

    closeNoteModal() {
      document.getElementById("newNoteModal").classList.remove("show");
      document.getElementById("newNoteModal").style.display = "none";
      document.body.classList.remove("modal-open");
      const backdrop = document.querySelector(".modal-backdrop");
      if (backdrop) backdrop.remove();
    },

    closeModal() {
      document.getElementById("newFolder").classList.remove("show");
      document.getElementById("newFolder").style.display = "none";
      document.body.classList.remove("modal-open");
      const backdrop = document.querySelector(".modal-backdrop");
      if (backdrop) backdrop.remove();
    },

    closeDeleteModal() {
      document.getElementById("deleteModal").classList.remove("show");
      document.getElementById("deleteModal").style.display = "none";
      document.body.classList.remove("modal-open");
      const backdrop = document.querySelector(".modal-backdrop");
      if (backdrop) backdrop.remove();
    },

    deleteFolder(clickedFolderID) {
      this.$emit("delete-folder", clickedFolderID);
      this.$emit("deleting-folder", clickedFolderID);
      console.log("recieved folder if from parent is :", this.clickedFolderID);
      this.closeDeleteModal();
    },
  },
};
</script>

<style scoped>
.modal-dialog {
  max-width: 600px; /* Width of the modal */
}

.modal-content {
  border-radius: 10px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  border: 1px solid #ddd;
}

.note-input-group {
  display: flex;
  align-items: center;
  gap: 15px; /* Space between label, input, and button */
}

.note-label {
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap; /* Prevent label wrapping */
}

.note-input {
  flex: 1;
  font-size: 14px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
}

.note-input:focus {
  outline: none;
  border-color: #007bff;
}

.note-button {
  font-size: 14px;
  padding: 8px 16px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.note-button:hover {
  background-color: #0056b3;
}
</style>
