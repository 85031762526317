<template>
  <div class="access-denied">
    <div class="content">
      <div class="icon-container">
        <i class="fas fa-ban"></i>
        <!-- Using a 'ban' icon to represent access denial -->
      </div>
      <h1 class="title">Access Denied</h1>
      <p class="message">You do not have the necessary permissions to view this page.</p>
      <button class="go-home" @click="goToHome">Go to Home</button>
    </div>
  </div>
</template>

<style scoped>
.access-denied {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.content {
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  padding: 40px 60px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.icon-container {
  font-size: 80px;
  color: #ff4d4f;
  margin-bottom: 20px;
}

.title {
  font-size: 32px;
  font-weight: 600;
  color: #333;
  margin-bottom: 15px;
}

.message {
  font-size: 18px;
  color: #777;
  margin-bottom: 25px;
}

.go-home {
  padding: 10px 20px;
  background-color: #ff4d4f;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.go-home:hover {
  background-color: #e94e4e;
}
</style>

<script>
export default {
  methods: {
    goToHome() {
      this.$router.push("/home"); // Redirect to home page
    },
  },
};
</script>
