<template>
  <div>
    <div
      class="modal fade"
      id="OnSiteGrnDetailsModal"
      tabindex="-1"
      aria-labelledby="OnSiteGrnDetailsModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-fullscreen">
        <div class="modal-content d-flex flex-column">
          <div class="modal-header d-flex justify-content-between">
            <h5
              class="modal-title text-decoration-underline"
              id="OnSiteGrnDetailsModalLabel"
              style="visibility: hidden"
            >
              Goods Received Note
            </h5>
            <div class="d-flex align-content-center">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                data-toggle="tooltip"
                data-placement="top-start"
                title="close"
              ></button>
            </div>
          </div>
          <div
            class="modal-body d-flex flex-column OnSiteGrnDetailsModal"
            ref="printContent"
          >
            <div class="">
              <p class="text-center" style="font-size: 18px; font-weight: 600">
                Goods Received Note
              </p>

              <div class="d-flex justify-content-between" style="padding: 0 2.5%">
                <!-- Company Information -->
                <div style="font-weight: 400; font-size: 14px">
                  <strong>{{ CompanyData.companyName }}</strong> <br />
                  {{ CompanyData.address }} {{ CompanyData.city }} <br />
                  {{ CompanyData.state }} {{ -CompanyData.pincode }}<br />
                  <span>Phone No.: {{ CompanyData.contactNumber }}</span
                  ><br />
                  <span>Email ID : {{ CompanyData.companyEmail }}</span
                  ><br />
                  <span>GST No. : {{ CompanyData.gstNumber }}</span
                  ><br />
                </div>

                <!-- Company Logo and Details -->
                <div>
                  <img
                    v-if="this.$CompanyLogoUrl"
                    :src="this.$CompanyLogoUrl"
                    alt="Company Logo"
                    id="companylogoInput"
                  />
                  <br />
                  <span style="font-size: 14px; margin-top: 2px">Date: 12/02/2024</span
                  ><br />
                  <span style="font-size: 14px; margin-top: 2px"
                    >GRN No.: {{ GRN.grnNumber }}</span
                  >
                </div>
              </div>
              <div
                class="d-flex justify-content-center text-center"
                style="background-color: #ccc; padding: 5px 0px; margin-top: 20px"
              >
                Details of Received Goods
              </div>
              <section style="margin-top: 20px; padding: 0 2.5%">
                <div class="table-responsive GRNHeaderTable overflow-hidden">
                  <div class="d-none">Voucher details</div>

                  <!-- Loop for Header Details -->
                  <div class="row mb-3">
                    <div class="col-md-6">
                      <div class="label-value-pair d-flex justify-content-between">
                        <span class="label">GRN No.:</span>
                        <span class="value">{{ GRN.grnNumber }}</span>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="label-value-pair d-flex justify-content-between">
                        <span class="label">PO Number:</span>
                        <span class="value">{{ GRN["purchaseOrders.poNumber"] }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-md-6">
                      <div class="label-value-pair d-flex justify-content-between">
                        <span class="label">Delivery DOC. Type:</span>
                        <span class="value">{{ GRN.documentType }}</span>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="label-value-pair d-flex justify-content-between">
                        <span class="label">Document No.:</span>
                        <span class="value">{{ GRN.documentNo }}</span>
                      </div>
                    </div>
                  </div>

                  <!-- Additional Header Details -->
                  <div class="row mb-3">
                    <div class="col-md-6">
                      <div class="label-value-pair d-flex justify-content-between">
                        <span class="label">Vendor Name:</span>
                        <span class="value">{{
                          GRN["purchaseOrders.vendors.companyName"]
                        }}</span>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="label-value-pair d-flex justify-content-between">
                        <span class="label">Vendor Contact No.:</span>
                        <span class="value">{{
                          GRN["purchaseOrders.vendors.primaryContactNo"]
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-md-6">
                      <div class="label-value-pair d-flex justify-content-between">
                        <span class="label">Received By:</span>
                        <span class="value">{{ GRN.receiverName }}</span>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="label-value-pair d-flex justify-content-between">
                        <span class="label">Receiver Contact No.:</span>
                        <span class="value">{{ GRN.receiverContactNo }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-md-6">
                      <div class="label-value-pair d-flex justify-content-between">
                        <span class="label">Ordered Date:</span>
                        <span class="value">{{ GRN["purchaseOrders.date"] }}</span>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="label-value-pair d-flex justify-content-between">
                        <span class="label">Received Date:</span>
                        <span class="value">{{ GRN.receivedDate }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-md-6">
                      <div class="label-value-pair d-flex justify-content-between">
                        <span class="label">Store Name:</span>
                        <span class="value">{{ GRN["projects.projectName"] }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="table-responsive">
                  <table class="table table-bordered">
                    <caption class="d-none">
                      Product Details
                    </caption>
                    <thead>
                      <tr>
                        <th>Sl.No</th>
                        <th>Product Name(Description)</th>
                        <th>Ordered Qty</th>
                        <th>Received Qty</th>
                        <th>Damage Qty</th>
                        <th>Missing Qty</th>
                        <th>Remark</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in lineItems" :key="item.id">
                        <td>{{ index + 1 }}</td>
                        <td>
                          {{ item["productLists.productName"] }} ({{
                            item["productLists.description"]
                          }})
                        </td>
                        <td class="text-end">
                          {{ item.orderQuantity }}
                        </td>

                        <td class="text-end">{{ item.receivedQuantity }}</td>
                        <td class="text-end">{{ item.damagedQuantity }}</td>
                        <td class="text-end">{{ item.missingQuantity }}</td>
                        <td class="text-end">{{ item.remark }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
            </div>
            <div class="footer-section mt-auto">
              <pre v-if="GRN.comments && GRN.comments.trim() !== ''">{{
                GRN.comments
              }}</pre>
              <div class="w-100">
                <br />
                <span class="d-flex justify-content-between">
                  <span class="text-start">Receiver Signature</span>
                  <span class="text-end">Department Signature</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "OnSiteGrnDetailsModal",
  props: {
    GRNId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      CompanyLogo: null,
      lineItems: [],
      GRN: {},
      CompanyData: {},
      isDataFetched: false, // Flag to indicate if data fetching is complete
    };
  },
  watch: {
    GRNId(newVal) {
      if (newVal !== null) {
        // Only fetch if newVal is not null
        this.fetchGRNById(newVal);
      }
    },
  },
  mounted() {
    this.fetchCompanyData();
  },

  methods: {
    fetchCompanyData() {
      const companyId = this.$LocalUser.user.companyId;
      axios
        .get(`${this.$apiEndPoint}/company/${companyId}`)
        .then((response) => {
          this.CompanyData = response.data;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    async fetchGRNById(Id) {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/OnSiteGrn/getById/${Id}`);
        this.lineItems = response.data.lineItems;
        this.GRN = response.data;
        this.isDataFetched = true; // Set the flag to true after data is fetched
      } catch (error) {
        this.$handleError(error);
      }
    },
    downloadPDF() {
      if (!this.isDataFetched) {
        this.$handleError("Data not fetched yet. Please wait.");
        return;
      }
      const contentToPrint = document.querySelector(".OnSiteGrnDetailsModal");

      // Save the original styles
      const originalWidth = contentToPrint.style.width;
      const originalMargin = contentToPrint.style.margin;
      const originalBorder = contentToPrint.style.border;
      const originalPadding = contentToPrint.style.padding;
      const fileName = `Goods_Received_Note_${this.GRN.grnNumber}.pdf`;
      // Create a new jsPDF instance
      const doc = new this.$jsPDF();

      // Temporarily change the styles for PDF generation
      contentToPrint.style.width = "100%";
      contentToPrint.style.margin = "0%";
      contentToPrint.style.border = "none";
      contentToPrint.style.padding = "5%";

      // Use setTimeout to delay style restoration and PDF generation
      setTimeout(() => {
        // Use jsPDF's html() method to render the HTML content directly
        doc.html(contentToPrint, {
          callback: function (doc) {
            // Save the generated PDF
            doc.save(fileName);

            // Restore the original styles after the PDF is generated
            contentToPrint.style.width = originalWidth;
            contentToPrint.style.margin = originalMargin;
            contentToPrint.style.border = originalBorder;
            contentToPrint.style.padding = originalPadding;
          },
          // x: 5, // Optional: X position of the content
          // y: 5, // Optional: Y position of the content
          margin: [5, 0, 5, 0], // Adjust margins if needed
          width: 210, // Set max width of the content
          windowWidth: 1000, // Specify window width for content rendering
          autoPaging: true, // Ensure content flows across multiple pages if necessary
        });
      }, 0); // Set timeout for async execution to allow changes to take effect
    },
  },
};
</script>

<style scoped>
.modal-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.modal-fullscreen {
  margin-left: 0%;
  width: 100%;
}
.modal-body {
  width: 60%;
  margin: 0 20%;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  border: 1px solid #000000;
}

.table-bordered th {
  background-color: #ccc;
}
.table-bordered {
  border: 1px solid #000000 !important;
}

pre {
  display: block;
  margin: 10px;
  text-align: left;
  width: 80%;
  min-height: 100px;
  max-height: auto;
  border: 1px solid #000;
  border-radius: 10px;
  padding: 10px;
  word-break: break-all;
  white-space: pre-wrap;
}

#companylogoInput {
  height: 90px;
  max-height: 90px;
  width: auto;
  padding: 5px;
  border-radius: 5px;
  max-width: 250px;
}

.mt-auto {
  margin-top: auto;
}

.content-section {
  flex-grow: 1; /* This will allow the content section to take up remaining space */
}

.footer-section {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  margin-top: auto; /* Pushes the footer to the bottom */
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid #ccc; /* Optional: to create separation from content */
}

.cell {
  display: flex;
  border: none !important;
}

.label {
  flex: 0.5;
  min-width: 150px; /* Adjust as needed */
  font-size: 12px;
}

.value {
  flex: 0.5;
  font-size: 12px;
}
.label-value-pair {
  margin: 0px;
}
.table-responsive th,
.table-responsive td {
  font-size: 10px;
}
@media screen and (max-width: 768px) {
  .modal-content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .modal-fullscreen {
    margin-left: 0%;
    width: 100%;
  }
  .modal-body {
    width: 100%;
    margin: 0%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    border: 1px solid #000000;
  }

  .table-bordered th {
    background-color: #ccc;
  }

  pre {
    display: block;
    margin: 10px;
    text-align: left;
    width: 80%;
    min-height: 100px;
    max-height: auto;
    border: 1px solid #000;
    border-radius: 10px;
    padding: 10px;
    word-break: break-all;
    white-space: wrap;
  }

  #companylogoInput {
    min-height: 80px;
    max-height: 90px;
    object-fit: cover;
    image-rendering: auto;
    padding: 5px;
    border-radius: 5px;
    width: 100%;
  }

  .mt-auto {
    margin-top: auto;
  }

  .content-section {
    flex-grow: 1; /* This will allow the content section to take up remaining space */
  }

  .footer-section {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    margin-top: auto; /* Pushes the footer to the bottom */
    width: 100%;
    box-sizing: border-box;
    border-top: 1px solid #ccc; /* Optional: to create separation from content */
  }
}
</style>
