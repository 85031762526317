<template>
  <div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="ProcurementTicketDetails"
      aria-labelledby="ProcurementTicketDetails"
      aria-hidden="true"
      ref="ProcurementTicketDetailsModal"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg custom-modal">
        <div class="modal-content">
          <div class="modal-header text-left w-100">
            <h5 class="modal-title w-100" id="ProcurementTicketDetails">
              Transit Detail
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form>
              <div class="container-fluid">
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label">Transportation Date:</label>
                  <div class="col-sm-8">
                    <p class="form-control-plaintext">{{ form.transportationDate }}</p>
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label">Document Type:</label>
                  <div class="col-sm-8">
                    <p class="form-control-plaintext">{{ form.documentType }}</p>
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label">Document Number:</label>
                  <div class="col-sm-8">
                    <p class="form-control-plaintext">{{ form.documentNumber }}</p>
                  </div>
                </div>

                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label">From Store:</label>
                  <div class="col-sm-8">
                    <p class="form-control-plaintext">
                      {{ form["fromStore.storeName"] }}
                    </p>
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label"
                    >Shipping From<br />
                    Store Address:</label
                  >
                  <div class="col-sm-8">
                    <p class="form-control-plaintext">
                      {{ form["fromAddress.address"] }},
                      {{ form["fromAddress.city"] }}
                    </p>
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label">To Store:</label>
                  <div class="col-sm-8">
                    <p class="form-control-plaintext">{{ form["toStore.storeName"] }}</p>
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label"
                    >Shipping To <br />
                    Store Address:</label
                  >
                  <div class="col-sm-8">
                    <p class="form-control-plaintext">
                      {{ form["toAddress.address"] }} ,{{ form["toAddress.city"] }}
                    </p>
                  </div>
                </div>

                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label">Vehicle Number:</label>
                  <div class="col-sm-8">
                    <p class="form-control-plaintext">{{ form.vehicleNumber }}</p>
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label"
                    >Transportation <br />
                    Amount:</label
                  >
                  <div class="col-sm-8">
                    <p class="form-control-plaintext">{{ form.transportationAmount }}</p>
                  </div>
                </div>

                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label">No of Labours:</label>
                  <div class="col-sm-8">
                    <p class="form-control-plaintext">{{ form.noOfLabours }}</p>
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label">Labour Amount:</label>
                  <div class="col-sm-8">
                    <p class="form-control-plaintext">{{ form.labourAmount }}</p>
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label">Total Amount:</label>
                  <div class="col-sm-8">
                    <p class="form-control-plaintext">{{ form.totalAmount }}</p>
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label">Paid By:</label>
                  <div class="col-sm-8">
                    <p class="form-control-plaintext">
                      {{ form["employees.firstName"] }} {{ form["employees.lastName"] }}
                    </p>
                  </div>
                </div>
                <div class="row mb-1">
                  <label class="col-sm-4 col-form-label">Receipt:</label>
                  <div class="col-sm-8">
                    <p class="form-control-plaintext">
                      <span
                        @click="
                          $viewFile(
                            form['awsFiles.awsFileName'],
                            form['awsFiles.fileName'],
                            'procurementTransit',
                            'procurement'
                          )
                        "
                        style="
                          font-size: 12px;
                          font-weight: 600;
                          margin-top: 2px;
                          cursor: pointer;
                        "
                        v-if="this.fileName"
                        >{{ this.fileName }}</span
                      >
                    </p>
                  </div>
                </div>
              </div>
              <div class="modal-footer d-flex justify-content-center">
                <button
                  type="submit"
                  class="btn btn-secondary btn-sm"
                  data-bs-dismiss="modal"
                  @click="updateProcurementTicketDetails"
                  style="letter-spacing: 2px"
                >
                  Edit
                  <img
                    src="../../assets/icons/yellowEdit.png"
                    style="width: 15px; height: 15px"
                    alt="Edit"
                  />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "OnsiteProcurementTicketDetailsModal",
  props: {
    TicketId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {}, // Initialize form data
      fileName: "",
    };
  },
  watch: {
    TicketId(newValue) {
      if (newValue) {
        this.fetchTicketDetailsById(newValue);
      }
    },
  },
  methods: {
    async fetchTicketDetailsById(id) {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/procurementTransit/getById/${id}`
        );
        this.form = response.data;
        this.fileName = response.data["awsFiles.fileName"];
      } catch (error) {
        this.$handleError(error);
      }
    },
    updateProcurementTicketDetails() {
      this.$router.push({
        name: "AddTicket",
        params: { TicketId: this.TicketId },
      });
    },
  },
};
</script>

<style scoped>
.modal-dialog.custom-modal {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  margin: 0;
  width: 30%;
}

.modal-content {
  border-radius: 15px;
  height: calc(100%);
}

.modal-body {
  max-height: 100%;
  overflow-y: auto;
  font-weight: 400;
  padding: 4px;
}

.col-form-label,
.form-control-plaintext {
  font-size: 14px !important;
  white-space: nowrap;
}

.redSubHeading {
  color: #ff6b00;
  font-size: 16px;
  margin-left: 3px;
  white-space: nowrap;
  border-bottom: 1px solid #000000;
  width: 70%;
}

@media (max-width: 768px) {
  .modal-dialog.custom-modal {
    width: 100%;
  }
}
</style>
