<template>
  <div class="EmployeesignupGroup">
    <div class="row d-flex align-items-center">
      <div class="col-md-7 col-sm-7 col-lg-7">
        <div class="loginImg d-flex justify-content-center">
          <img src="../assets/icons/loginImage.png" alt="" />
        </div>
      </div>
      <div class="col-md-5 col-sm-5 col-lg-5">
        <div class="login align-items-center">
          <div class="logo d-flex justify-content-center">
            <h1 class="toolNameFontFamily">arivu</h1>
          </div>
          
          <h5 class="d-flex justify-content-center" v-if="showCompanyDetails">
            <b>Company Setup</b>
          </h5>
          <div class="Employeesignup" v-if="showCompanyDetails">
            <span class="input-wrapper mt-4">
              <label for="companyName">Company Name</label>
              <input
                type="text"
                id="companyName"
                ref="companyName"
                v-model="companyDetails.companyName"
              />
            </span>
            <span class="input-wrapper mt-4">
              <label for="companyEmail">Email ID</label>
              <input
                type="text"
                id="companyEmail"
                ref="companyEmail"
                v-model="companyDetails.companyEmail"
              />
            </span>
            <small v-if="errors.companyEmail" class="text-danger mt-2">{{
              errors.companyEmail
            }}</small>
            <span class="input-wrapper mt-4">
              <label for="companyPhoneNumber">Phone Number</label>
              <input
                type="tel"
                pattern="[0-9]*"
                id="companyPhoneNumber"
                ref="companyPhoneNumber"
                v-model="companyDetails.companyPhoneNumber"
              />
            </span>
            <small v-if="errors.companyPhoneNumber" class="text-danger mt-2">{{
              errors.companyPhoneNumber
            }}</small>
            <span class="input-wrapper mt-4">
              <label for="companyLocation">Location</label>
              <input
                type="text"
                id="companyLocation"
                ref="companyLocation"
                v-model="companyDetails.companyLocation"
              />
            </span>
            <div class="d-flex justify-content-center align-content-center">
              <button
                id="signUpNext"
                class="btn btn-sm btn-primary text-center resetSubmitButton"
                :disabled="
                  !companyDetails.companyName ||
                  !companyDetails.companyEmail ||
                  !companyDetails.companyPhoneNumber ||
                  !companyDetails.companyLocation
                "
                @click="submitCompanyDetails"
              >
                Next
              </button>
            </div>
          </div>
          <h5 class="d-flex justify-content-center" v-if="showAdminDetails">
            <b>Admin Account</b>
          </h5>

          <div class="Employeesignup" v-if="showAdminDetails">
            <span class="input-wrapper mt-4">
              <label for="firstName">First Name</label>
              <input
                type="text"
                id="firstName"
                ref="firstName"
                v-model="adminDetails.firstName"
              />
            </span>
            <span class="input-wrapper mt-4">
              <label for="lastName">Last Name</label>
              <input
                type="text"
                id="lastName"
                ref="lastName"
                v-model="adminDetails.lastName"
              />
            </span>
            <span class="input-wrapper mt-4">
              <label for="adminEmail">Email ID</label>
              <input
                type="email"
                id="adminEmail"
                ref="adminEmail"
                v-model="adminDetails.adminEmail"
              />
            </span>
            <small v-if="errors.adminEmail" class="text-danger mt-2">{{
              errors.adminEmail
            }}</small>

            <span class="input-wrapper mt-4">
              <label for="adminPhoneNumber">Phone Number</label>
              <input
                type="tel"
                pattern="[0-9]*"
                id="adminPhoneNumber"
                ref="adminPhoneNumber"
                v-model="adminDetails.adminPhoneNumber"
              />
            </span>
            <small v-if="errors.adminPhoneNumber" class="text-danger mt-2">{{
              errors.adminPhoneNumber
            }}</small>
            <span class="input-wrapper mt-4">
              <label for="password">Password</label>

              <input
                :type="showPassword ? 'text' : 'password'"
                id="password"
                v-model="adminDetails.password"
              />
            </span>

            <span class="input-wrapper mt-4">
              <label for="confirmPassword" style="z-index: 999">Confirm Password</label>
              <span
                class="password-wrapper"
                style="position: relative; display: flex; align-items: center"
              >
                <input
                  :type="showConfirmPassword ? 'text' : 'password'"
                  id="confirmPassword"
                  v-model="adminDetails.confirmPassword"
                  style="
                    flex: 1;

                    height: 35px;
                    border-radius: 5px;
                  "
                />
                <i
                  v-if="adminDetails.confirmPassword"
                  :class="showConfirmPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"
                  @click="toggleConfirmPasswordVisibility"
                  style="
                    position: absolute;
                    right: 10px;
                    cursor: pointer;
                    color: #555;
                    z-index: 999;
                  "
                ></i>
              </span>
              <small v-if="passwordError" class="text-danger">{{ passwordError }}</small>
            </span>

            <div class="d-flex justify-content-between">
              <button
                id="signUpBack"
                class="btn btn-sm btn-secondary resetSubmitButton"
                @click="submitCompanyDetails"
              >
                Back
              </button>
              <button
                id="signUpSubmit"
                class="btn btn-sm btn-primary resetSubmitButton"
                @click="signUp"
                :disabled="submitDisabled"
              >
                Submit
              </button>
            </div>
          </div>
          <div class="signUp d-flex justify-content-center align-items-center">
            Already Registered? &nbsp;
            <a href="/login" style="text-decoration: none">Sign In</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { isValidEmail, isValidPhoneNumber } from "../util/ValidateFuntion.js";
export default {
  name: "SignUp",
  data() {
    return {
      showCompanyDetails: true,
      showAdminDetails: false,
      companyDetails: {
        companyName: "",
        companyEmail: "",
        companyPhoneNumber: "",
        companyLocation: "",
      },

      adminDetails: {
        firstName: "",
        lastName: "",
        adminEmail: "",
        adminPhoneNumber: "",
        password: "",
        confirmPassword: "",
      },
      showPassword: false,
      showConfirmPassword: false,
      passwordError: "",
      submitDisabled: false,
      errors: {
        companyEmail: "",
        companyPhoneNumber: "",
        adminEmail: "",
        adminPhoneNumber: "",
      },
    };
  },
  watch: {
    "companyDetails.companyEmail"(newVal) {
      if (!newVal) {
        this.errors.companyEmail = ""; // Don't show error if empty
      } else if (!isValidEmail(newVal)) {
        this.errors.companyEmail = "Invalid Email Format";
      } else {
        this.errors.companyEmail = "";
      }
    },

    "companyDetails.companyPhoneNumber"(newVal) {
      if (!newVal) {
        this.errors.companyPhoneNumber = ""; // Don't show error if empty
      } else if (!isValidPhoneNumber(newVal)) {
        this.errors.companyPhoneNumber = "Invalid Phone Number";
      } else {
        this.errors.companyPhoneNumber = "";
      }
    },
    "adminDetails.adminEmail"(newVal) {
      if (!newVal) {
        this.errors.adminEmail = ""; // Don't show error if empty
      } else if (!isValidEmail(newVal)) {
        this.errors.adminEmail = "Invalid Email Format";
      } else {
        this.errors.adminEmail = "";
      }
    },

    "adminDetails.adminPhoneNumber"(newVal) {
      if (!newVal) {
        this.errors.adminPhoneNumber = ""; // Don't show error if empty
      } else if (!isValidPhoneNumber(newVal)) {
        this.errors.adminPhoneNumber = "Invalid Phone Number";
      } else {
        this.errors.adminPhoneNumber = "";
      }
    },
    "adminDetails.confirmPassword"(newVal) {
      if (newVal && newVal !== this.adminDetails.password) {
        this.passwordError = "Passwords do not match!";
      } else {
        this.passwordError = "";
      }
    },
  },

  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },

    toggleConfirmPasswordVisibility() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
    submitCompanyDetails() {
      // Check if all company details are filled
      if (
        this.companyDetails.companyName &&
        this.companyDetails.companyLocation &&
        this.companyDetails.companyPhoneNumber &&
        this.companyDetails.companyEmail
      ) {
        this.showAdminDetails = !this.showAdminDetails;
        this.showCompanyDetails = !this.showCompanyDetails;
      } else {
        this.$toast("Please fill all company details fields", "error");
      }
    },

    async signUp() {
      this.submitDisabled = true; // Disable the button

      try {
        const Payload = {
          companyName: this.companyDetails.companyName,
          companyEmail: this.companyDetails.companyEmail,
          contactNumber: this.companyDetails.companyPhoneNumber,
          address: this.companyDetails.companyLocation,
          firstname: this.adminDetails.firstName,
          lastname: this.adminDetails.lastName,
          mobile: this.adminDetails.adminPhoneNumber,
          email: this.adminDetails.adminEmail,
          password: this.adminDetails.password,
        };

        console.log(Payload);
        // Step 1: Create the company
        const userResponse = await axios.post(`${this.$apiEndPoint}/company/`, Payload);

        // if (companyResponse.status !== 201) {
        //   throw new Error("Failed to create company");
        // }

        console.log("Company Created:", userResponse.data);

        // Step 2: Create the user
        // const userResponse = await axios.post(`${this.$apiEndPoint}/users/register`, {
        //   companyId: companyResponse.data.id,

        // });

        if (userResponse.status === 201) {
          this.$showAlert("Success", "Registered Successfully!", "success", "OK");

          // Store user data in localStorage
          // localStorage.setItem("user-info", JSON.stringify(userResponse.data));

          // Redirect to Login page
          this.$router.push({
            name: "Login",
            params: {
              email: this.adminDetails.adminEmail,
              password: this.adminDetails.password,
            },
          });
        }
      } catch (error) {
        console.error("Signup Error:", error);
        // this.$handleError(error);

        // Show an alert for the error
        this.$showAlert(
          "Error",
          `${error.response.data.message || "Registration Failed"}`,
          "error",
          "OK"
        );
      } finally {
        this.submitDisabled = false; // Re-enable button
      }
    },
  },
};
</script>

<style scoped>
.toggle-password {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.loginImg img {
  max-width: 80%;
  max-height: 80%;
}

.logo h1 {
  margin-bottom: 8%;
}

.EmployeesignupGroup {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
}

#EmployeeSignupHeading {
  margin-top: 5%;
  font-size: 2rem;
}

.Employeesignup {
  display: flex;
  margin-top: 2%;
  flex-direction: column;
}

/* .Employeesignup p {
    color: #fff;
} */

.resetSubmitButton {
  margin: 10% 0%;
  margin-top: 10%;
  padding: 5px;
  background-color: #4884f7;
  color: #fff;
  font-size: 1rem;
  border-radius: 5px;
  outline: none;
  border: none;
  cursor: pointer;
  width: 35%;
}
#resetBackButton {
  margin: 10% 0%;
  margin-top: 10%;
  padding: 5px;

  color: #fff;
  font-size: 1rem;
  border-radius: 5px;
  outline: none;
  border: none;
  cursor: pointer;
  width: 40%;
}
.signUp {
  margin-top: -5%;
}

.EmployeeSignUpFooter a {
  color: #4884f7;
  cursor: pointer;
}

.input-wrapper {
  min-width: 400px;
  height: 40px;
  position: relative;
}

/* .input-wrapper p {
  margin-left: 55%;
  margin-top: 2%;
} */

.input-wrapper input {
  padding: 5% 2%;
  height: 100%;
  width: 100%;
  z-index: 99;
  border: 1.5px solid black;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease, background-color 0.3s ease;
  background-color: #ffffff;
  font-weight: bolder;
  /* Set input text color */
  font-size: 1rem;
}

.input-wrapper textarea {
  padding: 5% 2%;
  height: 100%;
  width: 100%;
  z-index: 99;
  border: 1.5px solid black;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease, background-color 0.3s ease;
  background-color: #ffffff;
  font-weight: bolder;
  /* Set input text color */
  font-size: 1rem;
}

.input-wrapper label {
  white-space: nowrap;
  text-align: left;
  /* Adjusted to align left */
  position: absolute;
  margin-top: -14px;
  /* Adjusted to overlap with input when empty */
  left: 10px;
  pointer-events: none;
  z-index: 1;
  font-size: 1rem;
  transition: all 0.4s ease;
  color: #000000;
  font-weight: 500;
  background-color: #ffffff;
}

.input-wrapper input:focus {
  border-color: #4884f7;
}

.input-wrapper input:focus ~ label,
.input-wrapper input:not(:placeholder-shown) ~ label {
  margin-top: -20px;
  /* Adjusted to move label up */
  left: 10px;
  font-size: 16px;
  background-color: #084f5b;
}

.input-wrapper input:not(:focus):not(:placeholder-shown) ~ label {
  font-size: 1.1rem;
  margin-top: -10px;
  /* Adjusted to overlap with input when empty */
  left: 10px;
  background-color: #084f5b;
}

@media (max-width: 768px) {
  .loginImg img {
    display: none;
  }

  .input-wrapper {
    min-width: 320px;
    height: 40px;
    position: relative;
  }

  .EmployeesignupGroup h2 {
    margin-bottom: 10%;
  }

  #EmployeeSignupHeading {
    margin-top: -40%;
    font-size: 1.5rem;
  }
}
</style>
