<template>
  <AddOnSiteCategoryModal @fetchCategories="fetchCategories" />
  <Header />
  <div class="content-Container">
    <Nav />
    <div class="containerChild">
      <div class="contractorDetails">
        <div class="contractorDetailsHeader">
          <section class="headerTitle mainHeading">New Contractor Detail</section>
          <section>
            <img
              src="../../../assets/icons/cancel.png"
              alt="Cancel Icon"
              class="headerIcon"
              @click="goBack"
            />
          </section>
        </div>
        <div class="contractorPrimaryDetails p-3">
          <p class="row redSubHeading">Primary Contact Details</p>
          <div class="row col-md-12 mb-3">
            <div class="col-md-4">
              <div class="col-md-4">
                <label for="contractorName" class="form-label"
                  >Contractor Name <span class="text-danger">*</span></label
                >
              </div>
              <div class="col-md-10">
                <input
                  type="text"
                  class="form-control"
                  id="contractorName"
                  v-model="form.name"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="col-md-4">
                <label for="contractorID" class="form-label">Contractor ID</label>
              </div>
              <div class="col-md-10">
                <input
                  type="text"
                  class="form-control"
                  id="contractorID"
                  v-model="form.contractorID"
                  placeholder="Auto Generate"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="col-md-4">
                <label for="contactNumber" class="form-label"
                  >Contact number <span class="text-danger">*</span></label
                >
              </div>
              <div class="col-md-10">
                <input
                  type="number"
                  class="form-control"
                  id="contactNo"
                  v-model="form.contactNo"
                />
              </div>
            </div>
          </div>
          <div class="row col-md-12 mb-3">
            <div class="col-md-4">
              <div class="col-md-4">
                <label for="emailID" class="form-label">Email ID</label>
              </div>
              <div class="col-md-10">
                <input
                  type="text"
                  class="form-control"
                  id="emailID"
                  v-model="form.email"
                />
              </div>
            </div>
            <div class="col-md-4" ref="dropdownContainer">
              <div class="col-md-4">
                <label for="category" class="form-label">
                  Category <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-md-10">
                <div class="d-flex align-items-center">
                  <div
                    class="selected-categories form-select inputBg"
                    style="min-height: 40px; vertical-align: top"
                    id="category"
                    @click="toggleDropdown"
                  >
                    <span
                      v-for="categoryId in form.selectedCategories"
                      :key="categoryId"
                      class="category-tag"
                    >
                      {{ getCategoryNameById(categoryId) }}
                      <span @click.stop="removeCategory(categoryId)" class="remove-icon"
                        >&times;</span
                      >
                    </span>
                  </div>

                  <img
                    src="../../../assets/icons/addImage.png"
                    class="icon ms-2"
                    data-bs-toggle="modal"
                    data-bs-target="#AddOnSiteCategory"
                    alt="icon"
                    style="cursor: pointer"
                  />
                </div>

                <!-- Custom dropdown with checkboxes -->
                <div
                  v-if="showDropdown"
                  class="category-dropdown form-control"
                  style="z-index: 999 !important"
                >
                  <div
                    v-for="category in categories"
                    :key="category.id"
                    class="category-checkbox"
                  >
                    <span class="custom-checkbox">
                      <input
                        type="checkbox"
                        :id="'category_' + category.id"
                        :value="category.id"
                        v-model="form.selectedCategories"
                      />
                    </span>
                    <label class="ms-3" :for="'category_' + category.id">{{
                      category.categoryName
                    }}</label>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="col-md-4">
                <label for="address" class="form-label">Address</label>
              </div>
              <div class="col-md-10">
                <input
                  type="text"
                  class="form-control"
                  id="address"
                  v-model="form.address"
                />
              </div>
            </div>
          </div>
          <div class="row col-md-12 mb-3">
            <div class="col-md-4">
              <div class="col-md-4">
                <label for="city" class="form-label">City</label>
              </div>
              <div class="col-md-10">
                <input type="text" class="form-control" id="city" v-model="form.city" />
              </div>
            </div>
            <div class="col-md-4">
              <div class="col-md-4">
                <label for="state" class="form-label">State</label>
              </div>
              <div class="col-md-10">
                <input type="text" class="form-control" id="state" v-model="form.state" />
              </div>
            </div>
            <div class="col-md-4">
              <div class="col-md-4">
                <label for="pinCode" class="form-label">Pin Code</label>
              </div>
              <div class="col-md-10">
                <input
                  type="tel"
                  pattern="[0-9]*"
                  class="form-control"
                  id="pinCode"
                  v-model="form.pinCode"
                />
              </div>
            </div>
          </div>
          <hr />
          <p class="row redSubHeading">Primary Contact Details</p>
          <div class="row col-md-12 mb-3">
            <div class="col-md-4">
              <div class="col-md-4">
                <label for="contactPerson" class="form-label">Contact Person</label>
              </div>
              <div class="col-md-10">
                <input
                  type="text"
                  class="form-control"
                  id="contactPerson"
                  v-model="form.secondaryContactName"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="col-md-4">
                <label for="contactEmailID" class="form-label">Email ID</label>
              </div>
              <div class="col-md-10">
                <input
                  type="text"
                  class="form-control"
                  id="contactEmailID"
                  v-model="form.secondaryContactEmail"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="col-md-4">
                <label for="contactNumber" class="form-label">Contact number</label>
              </div>
              <div class="col-md-10">
                <input
                  type="number"
                  class="form-control"
                  id="contactNumber"
                  v-model="form.secondaryContactNumber"
                />
              </div>
            </div>
          </div>
          <hr />
          <p class="row redSubHeading mt-2">Bank Details</p>
          <div class="row col-md-12 mb-3">
            <div class="col-md-4">
              <div class="col-md-4">
                <label for="beneficiaryName" class="form-label">Beneficiary Name</label>
              </div>
              <div class="col-md-10">
                <input
                  type="text"
                  class="form-control"
                  id="beneficiaryName"
                  v-model="bankDetails.beneficiaryName"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="col-md-4">
                <label for="accountNumber" class="form-label">Account Number</label>
              </div>
              <div class="col-md-10">
                <input
                  type="text"
                  class="form-control"
                  id="accountNumber"
                  v-model="bankDetails.accountNumber"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="col-md-4">
                <label for="ifscCode" class="form-label">IFSC Code</label>
              </div>
              <div class="col-md-10">
                <input
                  type="text"
                  class="form-control"
                  id="ifscCode"
                  v-model="bankDetails.IFSC"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="col-md-4">
                <label for="branchName" class="form-label">Branch Name</label>
              </div>
              <div class="col-md-10">
                <input
                  type="text"
                  class="form-control"
                  id="branchName"
                  v-model="bankDetails.bankName"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="col-md-4">
                <label for="bankAddress" class="form-label">Bank Address</label>
              </div>
              <div class="col-md-10">
                <input
                  type="text"
                  class="form-control"
                  id="bankAddress"
                  v-model="bankDetails.branchAddress"
                />
              </div>
            </div>
          </div>
        </div>
        <footer class="footer">
          <button
            class="btn btn-sm btn-primary"
            :disabled="!form.name || !form.contactNo || loading"
            @click="saveForm"
          >
            Save
          </button>
          <button class="btn btn-sm btn-secondary" @click="goBack">Cancel</button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Header from "../../Header.vue";
import Nav from "../../Nav.vue";
import AddOnSiteCategoryModal from "@/components/Modal/AddOnSiteCategoryModal.vue";

export default {
  name: "CreateContractor",
  components: {
    Header,
    Nav,
    AddOnSiteCategoryModal,
  },
  data() {
    return {
      form: {
        name: "",
        contactNo: "",
        email: "",
        contractorCategory: null,
        address: "",
        city: "",
        state: "",
        pinCode: "",
        secondaryContactName: "",
        secondaryContactEmail: "",
        secondaryContactNumber: "",
        selectedCategories: [],
      },
      loading: false,
      showDropdown: false,
      categories: [],
      bankDetails: {
        beneficiaryName: "",
        accountName: "",
        accountNumber: "",
        bankName: " ",
        IFSC: "",
        branchAddress: " ",
        entityId: null,
        entityName: "Contractor",
        id: null,
      },
    };
  },
  mounted() {
    this.fetchCategories();
    document.addEventListener("click", this.handleOutsideClick);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleOutsideClick);
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    closeDropdown() {
      this.showDropdown = false;
    },
    getCategoryNameById(id) {
      const category = this.categories.find((cat) => cat.id === id);
      return category ? category.categoryName : "";
    },
    removeCategory(categoryId) {
      this.form.selectedCategories = this.form.selectedCategories.filter(
        (id) => id !== categoryId
      );
    },
    handleOutsideClick(event) {
      const dropdownContainer = this.$refs.dropdownContainer;
      if (!dropdownContainer.contains(event.target)) {
        this.closeDropdown();
      }
    },
    async fetchCategories() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/OnSiteCategory`);
        this.categories = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async saveForm() {
      this.loading = true;
      const contractorDetails = {
        ...this.form,
        contractorCategory: this.form.selectedCategories, // Array of selected category IDs
      };
      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/onSiteContractor/`,
          contractorDetails
        );
        this.$toast(`Contractor created successfull.`, "success");
        this.createBankDetails(response.data.createNewContractor.id);

        this.loading = false;
      } catch (error) {
        this.$toast(`Failed to  create Contractor   .`, "error");
        this.loading = false;
        this.$handleError(error);
      }
    },
    async createBankDetails(contractorID) {
      try {
        this.bankDetails.entityId = contractorID;
        await axios.post(`${this.$apiEndPoint}/bankDetails`, this.bankDetails);

        this.goBack();
      } catch (error) {
        this.$handleError(error);
      }
    },
    goBack() {
      this.$router.go(-1);

      // Navigate back one step in the history stack
    },
  },
};
</script>

<style scoped>
.redSubHeading {
  color: #ff6b00;
  font-size: 16px;
  margin-left: 3px;
}

.contractorDetails {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.contractorDetailsHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  border-bottom: 2px solid #000000;
}

.contractorPrimaryDetails {
  margin-bottom: 30px;
}

.headerTitle {
  flex: 1;
  text-align: left;
  padding: 10px;
}

.headerIcon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-right: 10px;
}

label,
input {
  font-size: 14px;
}

.footer {
  position: fixed;
  bottom: 12px;
  border-radius: 0 0 15px 15px;

  width: calc(100% - 74px);
  background: white;
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.1);
  /* Box shadow at the top */
  padding: 10px;
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}
input:focus,
textarea:focus,
select:focus {
  outline: none;
  /* Remove default outline */
  box-shadow: none;
  /* Add a custom focus shadow */
}
.select-image-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.no-border {
  border: none;
  box-shadow: none;
  flex: 1;
}

.icon {
  width: 22px;
  height: 22px;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 5px;
}

.category-dropdown {
  position: absolute;
  background-color: #ffffff;
  border: 1px solid #ddd;
  padding: 10px;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 5px;
  width: 355px;
  font-size: 14px;
}

.selected-categories {
  display: flex;
  flex-wrap: wrap;
  gap: 5px; /* Optional: adds space between the tags */
}

.category-tag {
  display: flex;
  align-items: center;
  background-color: #e0e0e0;
  padding: 5px 10px;
  border-radius: 5px;
}

.remove-icon {
  cursor: pointer;
  margin-left: 5px;
  color: red;
}

/* Responsive styles for mobile */
@media (max-width: 768px) {
  .headerTitle {
    margin-bottom: 10px;
  }

  .footer {
    position: fixed;
    bottom: 0px;
    border-radius: 0 0 15px 15px;
    width: calc(100%);
    background: white;
    box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.1);
    /* Box shadow at the top */
    padding: 10px;
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
  }

  .contractorPrimaryDetails {
    margin-bottom: 120px;
  }

  .headerIcon {
    align-self: flex-end;
  }
}
</style>
