<template>
  <div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="AddDesignActivity"
      tabindex="-1"
      aria-labelledby="AddDesignActivityLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header text-center w-100">
            <h5 class="modal-title w-100" id="AddDesignActivityLabel">Add Activity</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="submitForm">
              <div class="mb-3 row">
                <label for="activityName" class="col-sm-3 col-form-label"
                  >Activity Name</label
                >
                <div class="col-sm-9">
                  <input
                    v-model="form.activity"
                    type="text"
                    class="form-control"
                    id="activityName"
                    placeholder="Enter activity name"
                  />
                </div>
              </div>
              <div class="mb-3 row">
                <label for="assignee" class="col-sm-3 col-form-label">Assignee</label>
                <div class="col-sm-9">
                  <select
                    v-model="form.assignedTo"
                    id="assignTo"
                    class="form-control form-select"
                  >
                    <option value="" disabled>Select assignee</option>
                    <option
                      v-for="employee in employees"
                      :key="employee.employeeId"
                      :value="employee.employeeId"
                    >
                      {{ employee["employees.firstName"] }}
                      {{ employee["employees.lastName"] }}
                    </option>
                  </select>
                </div>
              </div>
              <!-- Other form fields remain unchanged -->
              <div class="mb-3 row">
                <label for="startDate" class="col-sm-3 col-form-label">Start Date</label>
                <div class="col-sm-9">
                  <input
                    v-model="form.plannedStartDate"
                    type="date"
                    class="form-control"
                    id="startDate"
                    @change="validatePlannedDates"
                  />
                </div>
              </div>
              <div class="mb-3 row">
                <label for="endDate" class="col-sm-3 col-form-label">End Date</label>
                <div class="col-sm-9">
                  <input
                    v-model="form.plannedEndDate"
                    type="date"
                    class="form-control"
                    id="endDate"
                    :min="form.plannedStartDate"
                  />
                </div>
              </div>
              <div class="mb-3 row">
                <label for="actualStartDate" class="col-sm-3 col-form-label"
                  >Actual Start Date</label
                >
                <div class="col-sm-9">
                  <input
                    v-model="form.actualStartDate"
                    type="date"
                    class="form-control"
                    id="actualStartDate"
                    @change="validateActualDates"
                  />
                </div>
              </div>
              <div class="mb-3 row">
                <label for="actualEndDate" class="col-sm-3 col-form-label"
                  >Actual End Date</label
                >
                <div class="col-sm-9">
                  <input
                    v-model="form.actualEndDate"
                    type="date"
                    class="form-control"
                    id="actualEndDate"
                    :min="form.actualStartDate"
                  />
                </div>
              </div>

              <div class="mb-3 row">
                <label for="remark" class="col-sm-3 col-form-label">Remark</label>
                <div class="col-sm-9">
                  <textarea
                    v-model="form.remarks"
                    class="form-control"
                    id="remark"
                    rows="3"
                    placeholder="Enter remarks"
                  ></textarea>
                </div>
              </div>
              <div class="modal-footer d-flex justify-content-center">
                <button
                  v-if="!this.form.id"
                  type="submit"
                  class="btn btn-primary btn-sm"
                  @click="AddActivity"
                  data-bs-dismiss="modal"
                >
                  Submit
                </button>
                <button
                  v-else
                  type="submit"
                  class="btn btn-primary btn-sm"
                  @click="UpdateActivity"
                  data-bs-dismiss="modal"
                >
                  Update
                </button>
                <button
                  type="button"
                  class="btn btn-danger btn-sm"
                  @click="DeleteTimeLine"
                  v-if="this.form.id"
                  data-bs-dismiss="modal"
                >
                  Delete
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "AddDesignActivityModal",
  props: {
    projectId: {
      type: String,
      required: true,
    },
    timelineId: {
      type: String,
      required: true,
    },
    onSitetimelineId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      employees: [], // Store fetched employees
      form: {
        activity: "",
        plannedStartDate: null,
        plannedEndDate: null,
        actualStartDate: null,
        actualEndDate: null,
        remarks: "",
        assignedTo: null,
        onSiteProjectId: this.projectId,
        designProjectId: this.projectId,
        id: null,
      },
    };
  },
  watch: {
    timelineId(newValue) {
      if (newValue !== null) {
        this.fetchEmployees();
        this.DesignTimeLineId = newValue;
        this.fetchDesignTimeLine();
      } else {
        this.form.id = null;
        this.fetchEmployees();
        this.resetForm();
      }
    },
    onSitetimelineId(newValue) {
      if (newValue !== null) {
        this.fetchEmployees();
        this.onSiteTimelineId = newValue;
        this.fetchDesignTimeLine();
      } else {
        this.form.id = null;
      }
    },
    projectId(newValue) {
      if (newValue !== null) {
        this.fetchEmployees();
        this.form.onSiteProjectId = newValue;
        this.form.designProjectId = newValue;
      } else {
        this.form.id = newValue;
        this.fetchEmployees();
        this.resetForm();
      }
    },
  },
  mounted() {
    this.fetchEmployees();
  },
  methods: {
    validatePlannedDates() {
      if (
        this.form.plannedEndDate &&
        this.form.plannedEndDate < this.form.plannedStartDate
      ) {
        this.form.plannedEndDate = this.form.plannedStartDate; // Reset invalid end date
      }
    },
    validateActualDates() {
      if (
        this.form.actualEndDate &&
        this.form.actualEndDate < this.form.actualStartDate
      ) {
        this.form.actualEndDate = this.form.actualStartDate; // Reset invalid actual end date
      }
    },
    fetchEmployees() {
      let endpoint = null;

      // Determine the correct endpoint based on the available IDs
      if (this.projectId && this.projectId !== "null") {
        endpoint = `${this.$apiEndPoint}/designProjectTeam/${this.projectId}`;
      } else if (this.timelineId && this.timelineId !== "null") {
        endpoint = `${this.$apiEndPoint}/designProjectTeam/${this.projectId}`;
      } else if (this.onSitetimelineId && this.onSitetimelineId !== "null") {
        endpoint = `${this.$apiEndPoint}/employee/myTeam/Onsite`;
      }

      // If an endpoint was determined, make the API request
      if (endpoint) {
        axios
          .get(endpoint)
          .then((response) => {
            this.employees = response.data.filter((item) => item !== null);
            this.mapAssignedEmployee();
          })
          .catch((error) => {
            this.$handleError(error);
          });
      }
    },

    resetForm() {
      this.form = {
        activity: "",
        plannedStartDate: null,
        plannedEndDate: null,
        actualStartDate: null,
        actualEndDate: null,
        remarks: "",
        assignedTo: null,
        onSiteProjectId: this.projectId,
        designProjectId: this.projectId,
        id: null,
      };
    },
    async fetchDesignTimeLine() {
      if (this.timelineId && this.timelineId !== "null") {
        try {
          const response = await axios.get(
            `${this.$apiEndPoint}/designTimeline/timelineById/${this.timelineId}`
          );
          this.form = response.data;
          this.mapAssignedEmployee();
        } catch (error) {
          this.$handleError(error);
        }
      } else if (this.onSitetimelineId && this.onSitetimelineId !== "null") {
        try {
          const response = await axios.get(
            `${this.$apiEndPoint}/onSiteTimeline/timelineById/${this.onSitetimelineId}`
          );
          this.form = response.data;
          this.mapAssignedEmployee();
        } catch (error) {
          this.$handleError(error);
        }
      }
    },
    async AddActivity() {
      this.form.onSiteProjectId = this.projectId;
      this.form.designProjectId = this.projectId;

      if (this.projectId && this.projectId !== "null") {
        try {
          this.form.onSiteProjectId = null;
          await axios.post(`${this.$apiEndPoint}/designTimeline/`, this.form);
          this.$emit("fetchTimeLineData");

          this.resetForm();
        } catch (error) {
          this.$handleError(error);
        }
      } else {
        try {
          this.form.designProjectId = null;
          await axios.post(`${this.$apiEndPoint}/onSiteTimeline/`, this.form);
          this.$emit("fetchTimeLineData");
          this.resetForm();
        } catch (error) {
          this.$handleError(error);
        }
      }
    },
    async UpdateActivity() {
      if (this.timelineId) {
        try {
          await axios.put(
            `${this.$apiEndPoint}/designTimeline/${this.timelineId}`,
            this.form
          );
          this.$emit("fetchTimeLineData");
        } catch (error) {
          this.$handleError(error);
        }
      } else if (this.onSitetimelineId) {
        try {
          await axios.put(
            `${this.$apiEndPoint}/onSiteTimeline/${this.onSitetimelineId}`,
            this.form
          );
          this.$emit("fetchTimeLineData");
        } catch (error) {
          this.$handleError(error);
        }
      }
    },
    async DeleteTimeLine() {
      const result = await this.$showAlert(
        "",
        "Are you sure you want to delete this activity?",
        "warning",
        "Yes, delete it",
        "Cancel"
      );

      // If the user clicked Cancel, don't proceed
      if (!result.isConfirmed) return;

      if (this.timelineId) {
        try {
          await axios.delete(`${this.$apiEndPoint}/designTimeline/${this.timelineId}`);
          this.$emit("fetchTimeLineData");
          this.resetForm();
        } catch (error) {
          this.$handleError(error);
        }
      } else if (this.onSitetimelineId) {
        try {
          await axios.delete(
            `${this.$apiEndPoint}/onSiteTimeline/${this.onSitetimelineId}`
          );
          this.$emit("fetchTimeLineData");
          this.resetForm();
        } catch (error) {
          this.$handleError(error);
        }
      }
    },
    mapAssignedEmployee() {
      if (this.form.assignedTo) {
        const assignedEmployee = this.employees.find(
          (emp) => emp.employeeId === this.form.assignedTo
        );
        if (assignedEmployee) {
          this.form.assignedTo = assignedEmployee.employeeId;
        }
      }
    },
  },
};
</script>

<style scoped>
input:focus,
textarea:focus,
select:focus {
  outline: none;
  /* Remove default outline */
  box-shadow: none;
  /* Add a custom focus shadow */
}
label,
input,
select {
  font-size: 13px;
}

.employee-dropdown {
  position: absolute;
  display: block;
  background-color: #fff;
  border: 1px solid #ccc;
  max-height: 200px;
  border-radius: 5px;
  overflow-y: auto;
  z-index: 1000;
  margin-left: 28%;
  /* Ensure the dropdown appears above other elements */
  width: calc(100% - 28%); /* Adjust width to match the input */
  color: #000000;
  top: 20%; /* Position the dropdown below the input */
  left: 0; /* Align the left edge of the dropdown with the left edge of the input */
}

.employee-dropdown ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.employee-dropdown ul li {
  padding: 8px 10px;
  cursor: pointer;
}

.employee-dropdown ul li:hover {
  background-color: #f0f0f0;
}
</style>
