<template>
  <div
    v-if="projectId"
    class="modal fade"
    id="deleteProjectModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="deleteProjectModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="deleteProjectModalLabel">
            Delete Project {{ ProjectName }}
          </h5>
          <img
            src="../../assets/icons/cancel.png"
            alt=""
            data-bs-dismiss="modal"
            aria-label="Close"
            style="width: 20px; height: 20px; cursor: pointer"
          />
        </div>
        <div class="modal-body">
          <p>This project will be deleted along with all its data permanently.</p>
          <p class="text-warning">Warning: This action is not reversible.</p>
          <div class="form-group">
            <label for="enteredProjectName"
              >Enter The Project Name:
              <span
                :class="{ 'correct-project-name': enteredProjectName === ProjectName }"
                >{{ ProjectName }}</span
              >
            </label>
            <input
              type="text"
              class="form-control border-black"
              id="enteredProjectName"
              v-model="enteredProjectName"
            />
          </div>
          <div class="form-group mt-1">
            <label for="confirmationText"
              >To verify, type
              <span class=" " style="font-weight: bolder">"Delete My Project" </span>
              below:</label
            >
            <input
              type="text"
              class="form-control border-black"
              id="confirmationText"
              v-model="confirmationText"
            />
          </div>
        </div>
        <div class="modal-footer justify-content-center">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            @click="cancel"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-danger"
            @click="deleteProject"
            :disabled="
              enteredProjectName !== ProjectName ||
              confirmationText !== 'Delete My Project'
            "
            data-bs-dismiss="modal"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="SourceId"
    class="modal fade"
    id="deleteProjectModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="deleteProjectModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="deleteProjectModalLabel">
            Delete Project {{ SourceName }}
          </h5>
          <img
            src="../../assets/icons/cancel.png"
            alt=""
            data-bs-dismiss="modal"
            aria-label="Close"
            style="width: 20px; height: 20px; cursor: pointer"
          />
        </div>
        <div class="modal-body">
          <p>This project will be deleted along with all its data permanently.</p>
          <p class="text-warning">Warning: This action is not reversible.</p>
          <div class="form-group">
            <label for="enteredProjectName"
              >Enter The Project Name:
              <span
                :class="{ 'correct-project-name': enteredProjectName === SourceName }"
                >{{ SourceName }}</span
              >
            </label>
            <input
              type="text"
              class="form-control border-black"
              id="enteredProjectName"
              v-model="enteredProjectName"
            />
          </div>
          <div class="form-group mt-1">
            <label for="confirmationText"
              >To verify, type
              <span class=" " style="font-weight: bolder">"Delete My Project" </span>
              below:</label
            >
            <input
              type="text"
              class="form-control border-black"
              id="confirmationText"
              v-model="confirmationText"
            />
          </div>
        </div>
        <div class="modal-footer justify-content-center">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            @click="cancel"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-danger"
            @click="deleteProject"
            :disabled="
              enteredProjectName !== SourceName ||
              confirmationText !== 'Delete My Project'
            "
            data-bs-dismiss="modal"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: {
    projectId: {
      type: String,
      required: true,
    },
    ProjectName: {
      type: String,
      required: true,
    },
    SourceId: {
      type: String,
      required: true,
    },
    SourceName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      enteredProjectName: "",
      confirmationText: "",
      projectName: this.ProjectName || this.SourceName, // Add your project name data
    };
  },
  methods: {
    async deleteProject() {
      // Check if projectId is provided
      if (this.projectId) {
        if (
          this.enteredProjectName !== this.ProjectName ||
          this.confirmationText !== "Delete My Project"
        ) {
          this.$handleError(`Entered project name or confirmation text is incorrect.`);
          return;
        }

        // If correct, proceed with deletion
        try {
          await axios.delete(`${this.$apiEndPoint}/designProjects/${this.projectId}`);

          // Optionally, navigate to a different route or take other actions after deletion
        } catch (error) {
          this.$handleError(error);
          alert("An error occurred while deleting the project. Please try again later.");
        }
      } else {
        if (
          this.enteredProjectName !== this.SourceName ||
          this.confirmationText !== "Delete My Project"
        ) {
          this.$handleError(`Entered source name or confirmation text is incorrect.`);
          return;
        }

        // If correct, proceed with deletion
        try {
          await axios.delete(`${this.$apiEndPoint}/source/${this.SourceId}`);
          this.$router.push({
            name: "SalesSource",
          });
          // Optionally, navigate to a different route or take other actions after deletion
        } catch (error) {
          this.$handleError(error);
          alert("An error occurred while deleting the project. Please try again later.");
        }
      }
    },
  },
};
</script>

<style scoped>
input:focus,
textarea:focus,
select:focus {
  outline: none; /* Remove default outline */
  box-shadow: none; /* Add a custom focus shadow */
}
.correct-project-name {
  color: green; /* Change the color to green when the project name matches the entered project name */
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}
</style>
