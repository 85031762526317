<template>
  <CreateReminderModal
    :selectedReminderId="selectedReminderId"
    @fetchData="fetchReminders()"
  />
  <taskSidePanel
    :selectedTaskId="selectedTaskId"
    @submit-form="fetchTasks()"
    @task-deleted="fetchTasks()"
  />
  <CreateEventModal :selectedEventId="selectedEventId" @fetchData="fetchEvents()" />
  <Header />
  <div class="content-Container">
    <Nav />
    <div class="containerChild">
      <!-- Calendar Header -->
      <div v-if="isLoading" class="loading-indicator">
        <div class="loader">
          <div class="spinner"></div>
          <div class="loading-text">Loading...</div>
        </div>
      </div>
      <header>
        <div class="header-left SubheadingUnderLine WEB">
          <h4>Calendar</h4>
        </div>
        <div
          class="header-center month-calender month-info"
          style="font-weight: bolder; font-size: 18px"
        >
          <img src="../../assets/icons/leftmove.png" alt="" @click="prevDay" />
          <div class="dayCalender-CurrentDay" style="border-bottom: 3px solid blueviolet">
            {{ currentDay }}
          </div>
          <img src="../../assets/icons/rightmove.png" alt="" @click="nextDay" />
        </div>
        <div class="d-flex viewSection" style="margin-right: 3.4%">
          <p class="mt-1 WEB">View :</p>
          <select
            style="
              margin-left: 3px;
              font-size: 12px;
              height: 30px;
              width: 150px;
              cursor: pointer;
              :focus {
                outline: none;
                box-shadow: none;
              }
            "
            class="form-select"
            v-model="selectedEmployeeId"
            @change="setSelectedEmployee"
          >
            <option
              v-for="employee in employees"
              :key="employee.employeeUserId"
              :value="employee.employeeUserId"
            >
              {{ employee["employees.firstName"] }}
              {{ employee["employees.lastName"] }}
            </option>
          </select>
        </div>
        <div class="header-right WEB" style="font-weight: bold; cursor: pointer">
          <span style="cursor: pointer" @click="changeView('year')">Year</span>
          <span @click="changeView('month')" style="margin: 0 20px; cursor: pointer"
            >Month</span
          >
          <span
            style="cursor: pointer"
            @click="changeView('day')"
            :class="{ active: currentView === 'day' }"
            >Day</span
          >
        </div>
      </header>

      <!-- Header for the day -->

      <div class="dayCalender-Occasion" style="margin: 0 2%"></div>
      <!--SideBar-->
      <div class="sidebar mb-5" style="margin: 0 2%">
        <div class="hours">
          <span class="text-muted" style="font-size: 10px">GMT+05:30</span>
          <div class="hour" v-for="hour in hours" :key="hour">
            <div class="hour-label">
              <h6>{{ hour }}</h6>
            </div>
            <div class="RTE-Item-Title">
              <!-- Render reminders -->
              <div
                v-for="item in getItemsForHour(hour, 'reminders')"
                :key="item.id"
                class="reminder-box ms-2 tableTextEvenRow"
                style="padding: 8px 8px; border-bottom: 1px solid #e0e0e0"
                data-bs-toggle="modal"
                data-bs-target="#reminderModal"
                @click="openReminderModal(item.id)"
              >
                <div
                  class="d-flex flex-column justify-content-between align-items-center"
                  style="margin-right: 10px"
                >
                  <span>
                    <img src="../../assets/icons/reminder.png.png" alt="" />
                  </span>
                  <span>
                    <img
                      src="../../assets/icons/time.png"
                      alt="Calendar"
                      style="width: 15px; height: 15px; margin-top: 5px"
                    />
                  </span>
                </div>
                <div class="d-flex flex-column justify-content-between">
                  <span>
                    <span>{{ item.title }}</span>
                    <span class="text-muted" style="font-size: 10px; display: block">
                      {{ item.description }}
                    </span>
                  </span>
                  <span class="text-start">
                    <span
                      class="text-muted"
                      style="font-size: 10px; display: block; margin-top: 5px"
                    >
                      {{ convertTo12HourFormat(item.fromTime) }} To
                      {{ convertTo12HourFormat(item.toTime) }}
                    </span>
                  </span>
                </div>
              </div>

              <!-- Render tasks -->
              <div
                v-for="item in getItemsForHour(hour, 'tasks')"
                :key="item.id"
                class="task-box tableTextEvenRow"
                @click="openTaskModal(item.id)"
                data-bs-toggle="modal"
                data-bs-target="#taskModal"
              >
                <div>
                  <img
                    v-if="item.status === 'Completed'"
                    src="../../assets/icons/completedTask.png"
                    alt="Green Tick"
                    class="icon status-icon"
                  />
                  <img v-else src="../../assets/icons/Task.png.png" alt="" />
                </div>
                <div class="d-flex flex-column ms-2">
                  <span> {{ item.title }}</span>
                  <span class="text-muted" style="font-size: 10px"
                    >{{ item.description }}
                  </span>
                </div>
              </div>

              <!-- Render events -->
              <div
                v-for="item in getItemsForHour(hour, 'events')"
                :key="item.id"
                class="event-box tableTextEvenRow"
                @click="openEventModal(item.id)"
                data-bs-toggle="modal"
                data-bs-target="#eventModal"
                style="padding: 8px 5px; border-bottom: 1px solid #e0e0e0"
              >
                <!-- Event Icon and Time Icon -->
                <div
                  class="d-flex flex-column ms-2 justify-content-between align-items-center"
                  style="margin-right: 10px"
                >
                  <span>
                    <img src="../../assets/icons/event.png" alt="Event" />
                  </span>
                  <span>
                    <img
                      src="../../assets/icons/time.png"
                      alt="Calendar"
                      style="width: 15px; height: 15px; margin-top: 5px"
                    />
                  </span>
                </div>

                <!-- Event Details -->
                <div class="d-flex flex-column">
                  <span>{{ item.title }}</span>
                  <span class="text-muted" style="font-size: 10px; display: block">
                    {{ item.description }}
                  </span>
                  <span>
                    <!-- Safely render the meeting link -->
                    <a
                      :href="sanitizeUrl(item.meetingLink)"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {{ item.meetingLink }}
                    </a>
                  </span>

                  <!-- Time and Participants -->
                  <div class="d-flex justify-content-between align-content-center">
                    <span
                      class="text-muted"
                      style="font-size: 8px; display: block; margin-top: 5px"
                    >
                      {{ convertTo12HourFormat(item.fromTime) }} To
                      {{ convertTo12HourFormat(item.toTime) }}
                    </span>
                    <span class="collaborator-avatar">
                      <span
                        class="d-flex initials"
                        v-for="(employee, employeeIndex) in item.eventParticipants || []"
                        :key="employeeIndex"
                      >
                        <span>{{ employee["users.initials"] }}</span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="dropdown-RTE" @mouseover="showRTEContent" @mouseout="hideRTEContent">
        <img
          src="../../assets/icons/colleps.png"
          alt=""
          class="plus-button"
          id="plus-button"
          v-if="isRTEContentVisible"
        />
        <img
          src="../../assets/icons/addRows.png"
          alt=""
          class="plus-button"
          id="plus-button"
          v-else
        />
        <div class="drowpdown-content-RTE" v-show="isRTEContentVisible">
          <div class="rteRow">
            <!-- Reminder item in the RTE dropdown -->

            <div class="RTE-item">
              <img src="../../assets/icons/Task.png.png" alt="" style="width: 30px" />
              <button
                class="task-btn twentyPxFont"
                @click="openTaskModal(null)"
                data-bs-toggle="modal"
                data-bs-target="#taskModal"
              >
                Task
              </button>
            </div>
            <div class="RTE-item">
              <img src="../../assets/icons/event.png.png" alt="" style="width: 30px" />
              <button
                class="event-btn twentyPxFont"
                type="button"
                @click="openEventModal(null)"
                data-bs-toggle="modal"
                data-bs-target="#eventModal"
              >
                Event
              </button>
            </div>
            <div class="RTE-item">
              <img src="../../assets/icons/reminder.png.png" alt="" style="width: 30px" />
              <button
                class="reminder-btn twentyPxFont"
                @click="openReminderModal(null)"
                data-bs-toggle="modal"
                data-bs-target="#reminderModal"
              >
                Reminder
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DOMPurify from "dompurify";
import CreateEventModal from "../Modal/CreateEventModal.vue";
import taskSidePanel from "../Modal/taskSidePanel.vue";
import CreateReminderModal from "../Modal/CreateReminderModal.vue";
import Header from "../Header.vue";
import Nav from "../Nav.vue";
import axios from "axios";

export default {
  name: "Day",
  components: {
    Header,
    Nav,
    CreateReminderModal,
    taskSidePanel,
    CreateEventModal,
  },
  props: ["year", "month", "day"],
  data() {
    return {
      selectedEventId: null,
      selectedTaskId: null,
      selectedReminderId: null,
      currentView: "day",
      // Use props to receive date parameters from the route
      isRTEContentVisible: false,
      currentDay: "",
      currentMonth: "",
      currentDate: new Date(), // Added currentDate property to store the current date
      hours: [
        "8 am",
        "9 am",
        "10 am",
        "11 am",
        "12 pm",
        "1 pm",
        "2 pm",
        "3 pm",
        "4 pm",
        "5 pm",
        "6 pm",
        "7 pm",
        "8 pm",
        "9 pm",
        "10 pm",
        "11 pm",
        "12 am",
        "1 am",
        "2 am",
        "3 am",
        "4 am",
        "5 am",
        "6 am",
        "7 am",
      ],

      reminderModal: false,
      taskModal: false,
      eventModal: false,
      reminders: [],
      tasks: [],
      events: [],
      currentDateData: [], // Store the fetched data for the current date
      currentDateString: "",
      isLoading: false,
      selectedEmployeeId: this.$LocalUser.user.id,
      employees: [],
    };
  },
  methods: {
    setSelectedEmployee() {
      this.updateDateFromRoute();
    },
    sanitizeUrl(url) {
      const sanitizedUrl = DOMPurify.sanitize(url.startsWith("http") ? url : "//" + url);
      return sanitizedUrl;
    },
    openReminderModal(TargetId) {
      this.selectedReminderId = TargetId;
    },
    openTaskModal(TargetId) {
      this.selectedTaskId = TargetId;
    },
    openEventModal(TargetId) {
      this.selectedEventId = TargetId;
    },

    reverseDateFormat(date) {
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
    convertTo12HourFormat(timeString) {
      if (!timeString) {
        return ""; // Return an empty string or a default value if timeString is null or undefined
      }

      // Split the time string into hours and minutes
      const [hours, minutes] = timeString.split(":");

      // Convert hours to a number
      let hour = parseInt(hours, 10);

      // Determine if it's AM or PM
      const period = hour >= 12 ? "PM" : "AM";

      // Convert hours to 12-hour format
      hour = hour % 12 || 12;

      // Return the formatted time string
      return `${hour.toString().padStart(2, "0")}:${minutes} ${period}`;
    },

    openMeetingLink(meetingLink) {
      // Open the meeting link in a new window
      window.open(meetingLink, "_blank");
    },
    changeView(view) {
      this.currentView = view; // Update the currentView when changing the view
      if (view === "year") {
        // Navigate to the calendar page with the current year
        const currentYear = new Date().getFullYear();
        this.$router.push({ name: "Calendar", params: { year: currentYear } });
      } else if (view === "month") {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1; // Month index starts from 0, so add 1
        this.$router.push({
          name: "Month",
          params: { year: currentYear, month: currentMonth },
        });
      } else if (view === "day") {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1;
        const currentDay = currentDate.getDate();
        this.$router.push({
          name: "Day",
          params: { year: currentYear, month: currentMonth, day: currentDay },
        });
      }
    },
    // Function to show RTE content on mouseover
    showRTEContent() {
      this.isRTEContentVisible = true;
    },
    // Function to hide RTE content on mouseout
    hideRTEContent(event) {
      // Check if the mouse is leaving the icon, the dropdown, or the reminder modal
      if (
        !event.relatedTarget?.closest(".drowpdown-content-RTE") ||
        event.relatedTarget?.closest(".reminder-form") ||
        event.relatedTarget?.closest(".task-form") ||
        event.relatedTarget?.closest(".event-form")
      ) {
        this.isRTEContentVisible = false;
      }
    },

    nextDay() {
      this.currentDate.setDate(this.currentDate.getDate() + 1);
      this.updateDate();
    },
    prevDay() {
      this.currentDate.setDate(this.currentDate.getDate() - 1);
      this.updateDate();
    },
    updateDate() {
      const options = { weekday: "short", day: "numeric", month: "short" }; // Added 'month' to options
      this.currentDay = this.currentDate.toLocaleDateString("en-US", options); // e.g., "14 Thu Mar"

      this.currentMonth = this.currentDate.toLocaleDateString("en-US", {
        month: "long",
        year: "numeric",
      }); // e.g., "March 2024"

      this.localYear = this.currentDate.getFullYear(); // Get full year, e.g., 2024
      this.localMonth = this.currentDate.getMonth() + 1; // Get numeric month (1-12)
      this.localDay = this.currentDate.getDate(); // Get numeric day (1-31)

      this.fetchTasks(); // Fetch tasks
      this.fetchEvents(); // Fetch events
      this.fetchReminders(); // Fetch reminders
    },

    // Function to update the current date using route params
    updateDateFromRoute() {
      // Parse route parameters and set the current date
      const { year, month, day } = this.$route.params;
      this.currentDate = new Date(year, month - 1, day);
      this.updateDate();
      this.fetchTasks();
      this.fetchEvents();
      this.fetchReminders();
    },
    fetchTasks() {
      this.selectedTaskId = null;
      axios
        .get(
          `${this.$apiEndPoint}/tasks/calendar/${this.localYear}/${this.localMonth}/${this.localDay}/${this.selectedEmployeeId}`
        )
        .then((response) => {
          const tasksWithTypes = Array.isArray(response.data)
            ? response.data.map((task) => ({
                ...task,
                type: "tasks",
              }))
            : [];
          this.tasks = tasksWithTypes;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    fetchReminders() {
      this.selectedReminderId = null;
      axios
        .get(
          `${this.$apiEndPoint}/reminders/${this.localYear}/${this.localMonth}/${this.localDay}/${this.selectedEmployeeId}`
        )
        .then((response) => {
          const remindersWithTypes = Array.isArray(response.data)
            ? response.data.map((reminder) => ({
                ...reminder,
                type: "reminders",
              }))
            : [];
          this.reminders = remindersWithTypes;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    fetchEvents() {
      this.selectedEventId = null;
      axios
        .get(
          `${this.$apiEndPoint}/events/${this.localYear}/${this.localMonth}/${this.localDay}/${this.selectedEmployeeId}`
        )
        .then((response) => {
          const eventsWithTypes = Array.isArray(response.data)
            ? response.data.map((event) => ({
                ...event,
                type: "events",
              }))
            : [];
          this.events = eventsWithTypes;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    // Function to toggle the visibility of the Reminder modal
    toggleReminderModal() {
      this.reminderModal = !this.reminderModal;
    },
    toggleTaskModal() {
      this.taskModal = !this.taskModal;
    },
    toggleEventModal() {
      this.eventModal = !this.eventModal;
    },

    // Combine reminders, tasks, and events into a single array
    getItemsForHour(hour, type) {
      const items = this[type].filter((item) => this.isItemForHour(item, hour));
      return items;
    },
    // Check if an item is for the given hour
    isItemForHour(item, hour) {
      const itemStartTime = new Date(item.date + " " + item.fromTime + ":00");
      const hourFormat =
        itemStartTime.getHours() % 12 === 0 ? 12 : itemStartTime.getHours() % 12;
      const itemTime = `${hourFormat} ${itemStartTime.getHours() >= 12 ? "pm" : "am"}`;
      return itemTime === hour;
    },

    getItemClass(item) {
      if (item.type === "reminders") {
        return "reminder-box";
      } else if (item.type === "tasks") {
        return "task-box";
      } else if (item.type === "events") {
        return "event-box";
      } else {
        return "Event-box"; // Default class
      }
    },
    async fetchEmployees() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/employee/getByRoles`);
        if (response.data) {
          this.employees = response.data;
        }
      } catch (error) {
        this.$handleError(error);
      }
    },
    // Dynamically set the class based on item type
  },

  mounted() {
    this.selectedEmployeeId = this.$route.params.selectedEmployeeId;
    this.fetchEmployees();
    this.updateDateFromRoute();
    // Move this line here
  },
};
</script>
<style scoped>
/* Styling for the main day calendar component */

.active {
  border-bottom: 2px solid blue;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 2.5%;
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: #ffffff;
}

.header-left {
  display: flex;
  align-items: center;
}

.spinner {
  border: 5px solid #bcbfc6;
  border-top: 5px solid #000000;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  position: absolute;

  animation: spin 1s linear infinite;
  /* Adjust animation properties as needed */
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.loading-text {
  margin-top: 40px;
}

.dayCalender-Header {
  position: relative;
  width: 100%;
  border-bottom: 1px solid #000000;

  display: flex;
  align-items: center;
}

.dayCalender-DayHead {
  display: flex;
  align-items: center;
  margin-left: 1%;
}

.dayCalender-DayHead img {
  margin-right: 3%;
}
.dayCalender-DayHead h4 {
  margin-top: 4%;
}

.dayCalender-Date {
  display: flex;
  align-items: center;
  margin-left: 10%;
}

.dayCalender-Date img {
  margin: 1% 0;
  cursor: pointer;
}

.dayCalender-CurrentDay {
  white-space: nowrap;
  padding-left: 2%;
  padding-right: 2%;
  width: 150px;
}

.dayCalender-Month {
  white-space: nowrap;
  margin-left: 2%;
}

.dayCalender-view {
  font-display: flex;
  align-items: center;
  margin-left: 50%;
}

.dayCalender-Occasion {
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 8.9%;
  z-index: 999;
  height: 30px;
  background-color: #bcbfc6;
  border-radius: 5px;
}

.dayCalender-Occasion h1 {
  font-size: 1.5rem;
}

/* Sidebar styling with fixed width */
.sidebar {
  display: flex;
  align-items: center;
}

.hours {
  width: 100%;
  height: 100%;

  /* Add this line for the right border */
}

.hour .hour-label {
  width: 3%;
  min-width: 3%;
  font-size: 18px;
  white-space: nowrap;
  text-align: right;
}

.hour {
  display: flex;
  align-items: center;
  padding: 1% 1%;
  border-bottom: 1px solid #ccc;
}
.RTE-Item-Title {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 1%;
  justify-content: flex-start;
  overflow: auto;
  flex-wrap: wrap; /* Allow the content to wrap */
  white-space: pre-wrap; /* Preserve whitespace and wrap text as needed */
}

.RTE-Item-Title div img {
  width: 20px;
  height: 20px;
  margin: 1%;
}
/* Base styles for reminder-box */
.reminder-box {
  width: fit-content;
  height: fit-content;
  display: flex;
  padding: 2%;
  margin: 1%;
  color: #000000;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  background-image: linear-gradient(to right, yellow 4px, transparent 3px);
  background-size: 100% 100%;
  transition: box-shadow 0.3s ease, transform 0.3s ease; /* Transition for smooth effect */
}

/* Base styles for task-box */
.task-box {
  width: fit-content;
  height: fit-content;
  display: flex;
  padding: 1%;
  margin: 1%;
  cursor: pointer;
  font-size: 12px;
  color: #000000;
  font-weight: 500;
  background-image: linear-gradient(to right, purple 4px, transparent 3px);
  background-size: 100% 100%;
  transition: box-shadow 0.3s ease, transform 0.3s ease; /* Transition for smooth effect */
}

/* Base styles for event-box */
.event-box {
  width: fit-content;
  height: fit-content;
  display: flex;
  padding: 1%;
  margin: 1%;
  cursor: pointer;
  font-size: 12px;
  color: #000000;
  font-weight: 500;
  background-image: linear-gradient(to right, #2381f3 4px, transparent 3px);
  background-size: 100% 100%;
  transition: box-shadow 0.3s ease, transform 0.3s ease; /* Transition for smooth effect */
}

.event-box:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect on hover */
  transform: scale(1.05); /* Slightly zoom in */
}
.reminder-box:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect on hover */
  transform: scale(1.05); /* Slightly zoom in */
}
.task-box:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect on hover */
  transform: scale(1.05); /* Slightly zoom in */
}
#tooltip-RTE {
  cursor: pointer;
  position: relative;
}

#tooltip-RTEText h4 {
  color: #000000;
  font-size: 0.9rem;
  display: flex;
  margin: 0 1%;
  font-weight: 900;
}

#tooltip-RTEText p {
  color: #000;
  display: flex;
  font-size: 0.8rem;
  margin: 0 1%;
}

.event-header {
  display: flex;
  width: 100%;
  align-items: center;
}

.event-header img {
  display: flex;
}

.event-header h3 {
  font-size: 1rem;
}

#tooltip-RTEText {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  transform: translateY(-10%);
  background-color: #bcbfc6;
  color: white;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s ease;
  height: auto;
}

#tooltip-RTEText::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
}

#tooltip-RTE:hover #tooltip-RTEText {
  top: 100%;
  visibility: visible;
  opacity: 1;
}

.DayContentRTE {
  display: flex;
  align-items: center;
  flex-direction: row;
  border-right: none !important;
  max-width: 80%;
  margin: 1%;
}

.DayContentRTE div {
  border: 1px solid gold;
  border: none;
  max-width: 25%;
  background-color: goldenrod;
  padding: 1%;
  display: flex;
  align-items: center;
}

.DayContentRTE div img {
  margin: 1%;
}

.DayContentRTE .taskBox {
  margin: 10%;
}

.plus-button {
  width: 50px;
  position: fixed;
  bottom: 3%;
  right: 2%;
  cursor: pointer;
}

.drowpdown-content-RTE {
  position: fixed;
  bottom: 7.5%;
  right: 2.5%;
  background-color: #f8f8f8;
  min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid #ccc;
  border-radius: 10px;
  z-index: 6;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin-right: 30px;
}

.drowpdown-content-RTE button {
  color: #000;
  text-decoration: none;
  transition: background-color 0.3s ease;
  margin: 5px;
}

.RTE-row {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.RTE-item img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.RTE-item {
  display: flex;
  align-items: center;
  margin: 0px;
  /* Adjust as needed */
}

/* Styling for the reminder button */
.reminder-btn {
  appearance: none;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: inline-block;
  padding: 5px 10px;
  background-image: linear-gradient(to right, #aa8937);
  border-radius: 8px;
  color: black;
  font-size: 14px;
  font-weight: 500;

  transition: 0.4s ease-out;
}

/* Hover effect for the reminder button  box-shadow: 3px 3px rgba(0, 0, 0, 0.4); 
&:hover {
  background-color: #f8f8f8;
  border-radius: 10px;
}*/

.task-btn {
  appearance: none;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: inline-block;
  padding: 5px 10px;
  background-image: linear-gradient(to right, #aa8937);
  border-radius: 8px;
  color: black;
  font-size: 14px;
  font-weight: 500;

  transition: 0.4s ease-out;
}

.event-btn {
  appearance: none;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: inline-block;
  padding: 5px 10px;
  background-image: linear-gradient(to right, #aa8937);
  border-radius: 8px;
  color: black;
  font-size: 14px;
  font-weight: 500;

  transition: 0.4s ease-out;
}

.loader {
  position: relative;
  width: 100px;
  height: 100px;
}

.RTE-item:hover {
  background-color: #ccc;
  border-radius: 5px;
}

.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  z-index: 1000;
  /* Adjust the height as needed */
}
.header-center {
  margin-left: 20%;
}
.header-center img {
  width: 5%;
  cursor: pointer;
  margin: 0 2%;
}
.collaborator-avatar {
  position: relative;
  display: flex;
  justify-content: left;
  align-items: left;
  margin-right: 2px;

  /* Adjust margin to control the overlap */
}
.initials {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #4884f7; /* Blue background color */
  color: #ffffff; /* White text color */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 8px;
  cursor: pointer;
  z-index: 1;
  margin-right: -4px; /* Adjust margin to control the overlap */
  position: relative; /* Ensure z-index works correctly */
}
.collaborator-avatar {
  margin-left: 10px;
}
@media (max-width: 767px) {
  .dayCalender-Occasion {
    top: 10%;
    position: sticky;
  }
  .header-center {
    margin-left: 2%;
  }
  .header-center img {
    width: 10%;
  }
  .RTE-Item-Title {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 1%;
    margin-left: 12%;
    justify-content: flex-start;
    overflow: auto;
    flex-wrap: wrap; /* Allow the content to wrap */
    white-space: pre-wrap; /* Preserve whitespace and wrap text as needed */
  }
  .dayCalender-CurrentDay {
    white-space: nowrap;
    padding-left: 2%;
    padding-right: 2%;
    width: 150px;
    font-size: 14px;
  }
  .hour .hour-label h6 {
    font-size: 14px !important;
  }
  .hour {
    display: flex;
    align-items: center;
    padding: 3% 1%;
    border-bottom: 1px solid #ccc;
  }
  .collaborator-avatar {
    margin-left: 10px;
  }
}
</style>
