<template>
  <div>
    <Header />
    <div class="content-container">
      <Nav />
      <div class="containerChild">
        <div class="chat-container">
          <div class="page-container">
            <div class="d-flex justify-content-center align-items-center">
              <button class="arivu-bot-button">
                Arivu Bot <i class="fas fa-comments"></i>
              </button>
            </div>
          </div>
          <!-- Chat Messages -->
          <div class="messages">
            <div
              v-for="(message, index) in messages"
              :key="index"
              :class="['message', message.type]"
            >
              {{ message.text }}
            </div>
          </div>

          <!-- User Input -->
          <div class="input-container">
            <input
              v-model="userInput"
              @keyup.enter="sendQuestion"
              type="text"
              placeholder="Ask your question here..."
            />
            <button @click="sendQuestion">Send</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../Header.vue";
import Nav from "../Nav.vue";

export default {
  name: "ArivuChat",
  components: {
    Header,
    Nav,
  },
  data() {
    return {
      isresponse: true,
      userInput: "", // User's input
      messages: [
        {
          text: " Hi! How can I assist you today?",
          type: "bot",
        },
      ], // Chat messages
      faqData: [
        {
          question: "What is Vue.js?",
          summary:
            "Vue.js is a progressive JavaScript framework used for building user interfaces.",
        },
        {
          question: "What is JavaScript?",
          summary:
            "JavaScript is a programming language used for creating dynamic content on websites.",
        },
        {
          question: "What is the capital of France?",
          summary: "The capital of France is Paris.",
        },
      ],
    };
  },
  methods: {
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$el.querySelector(".messages");
        container.scrollTop = container.scrollHeight;
      });
    },
    sendQuestion() {
      const question = this.userInput.trim();
      if (!question) return;
      if (this.isresponse) {
        this.isresponse = false;
        // Add user's message to chat
        this.messages.push({ text: ` ${question}`, type: "user" });

        // Find an answer from FAQ data
        const faq = this.faqData.find(
          (item) => item.question.toLowerCase() === question.toLowerCase()
        );
        setTimeout(() => {
          if (faq) {
            this.messages.push({
              text: ` ${faq.summary}`,
              type: "bot",
            });
          } else {
            this.messages.push({
              text: " I'm sorry, I don't have an answer for that.",
              type: "bot",
            });
          }

          this.userInput = ""; // Clear input
          this.isresponse = true;
          this.scrollToBottom();
        }, 800); // Delay to mimic bot typing
      }

      // Simulate bot response delay
    },
  },
};
</script>

<style scoped>
.content-container {
  display: flex;
}

.containerChild {
  flex: 1;
  padding: 20px;
}

.chat-container {
  width: 90%;
  height: 100%;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  margin: 0 5%;
  overflow: hidden;
  background-color: #ddd;
}

.messages {
  height: 69vh;
  overflow-y: auto;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.message {
  padding: 8px 12px;
  margin-bottom: 10px;
  border-radius: 6px;
}

.message.user {
  background-color: #eef;
  align-self: flex-end;
  text-align: right;

  word-wrap: break-word; /* Ensure text wraps in the p tag */
  max-width: 100%;
  margin: 5px;
}

.message.bot {
  background-color: #f4f4f4;
  align-self: flex-start;
  word-wrap: break-word; /* Ensure text wraps in the p tag */
  max-width: 100%;
  margin: 5px;
}

.input-container {
  display: flex;
  margin-top: 10px;
}

input[type="text"] {
  flex: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 10px;
}

button {
  padding: 8px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
.arivu-bot-button {
  background-color: #0056b3;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 16px;
}

.arivu-bot-button:hover {
  background-color: #003d80;
}
</style>
