<template>
  <!-- Custom Modal -->
  <div class="modal fade custom-modal" id="taskModal" aria-labelledby="taskModalLabel">
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- Body Section -->
        <div class="modal-body">
          <form @submit.prevent="submitForm" class="border-0">
            <!-- Header Section -->
            <div class="modal-header" style="padding: 5px">
              <div class="col-md-5">
                <input
                  ref="titleInput"
                  class="modal-title"
                  style="border: none; outline: none; background-color: none"
                  v-model="localTask.title"
                  placeholder="Write Task Title"
                  id="taskModalLabel"
                  :class="{ 'is-invalid': titleError }"
                />
                <div v-if="titleError" class="text-danger" style="font-size: 0.875rem">
                  Please enter a title for the subtask.
                </div>

                <small
                  style="background-color: none; font-size: small; cursor: pointer"
                  v-if="localTask.parentTaskId"
                  @click="fetchSubTaskDetails(localTask.parentTaskId)"
                >
                  {{ parentTaskTitle ? truncateText(parentTaskTitle, 15) : " " }}
                </small>
              </div>

              <div
                class="col-md-7 completed-button d-flex justify-content-end"
                v-if="this.selectedTask !== 'Recurring'"
              >
                <!-- Mark as Completed Button -->
                <button
                  v-if="
                    localTask.id &&
                    !isImageActive &&
                    localTask.status === 'Pending' &&
                    (this.localTask.createdById === this.$LocalUser.user.id ||
                      this.localTask.assignedTo === this.$LocalUser.user.id)
                  "
                  type="button"
                  class="btn btn-sm ml-auto btn-outline-success"
                  data-bs-dismiss="modal"
                  @click="markAsCompleted"
                >
                  Mark Completed
                </button>

                <!-- Task Completed Button -->
                <button
                  v-if="
                    localTask.id &&
                    !isImageActive &&
                    localTask.status === 'Completed' &&
                    (this.localTask.createdById === this.$LocalUser.user.id ||
                      this.localTask.assignedTo === this.$LocalUser.user.id)
                  "
                  type="button"
                  class="btn btn-sm ml-auto btn-success text-white"
                  data-bs-dismiss="modal"
                >
                  Completed
                </button>
              </div>

              <div class="col-md-1 d-flex justify-content-end">
                <img
                  src="../../assets/icons/cancel.png"
                  class="closeImg"
                  data-bs-toggle="modal"
                  data-bs-target="#taskModal"
                  aria-label="Close"
                  @click="closeModal()"
                  alt=""
                />
              </div>
            </div>

            <hr style="color: black; margin-top: 0%" />

            <!-- Task Description Input -->
            <div class="form-body">
              <div class="col-md-3">
                <label for="taskDescription" class="form-label">
                  Task Description:
                </label>
              </div>
              <div class="col-md-9">
                <textarea
                  id="taskDescription"
                  v-model="localTask.description"
                  class="form-control"
                  style="font-size: small"
                  placeholder="Enter task description"
                ></textarea>
              </div>
            </div>

            <!-- <div class="form-body">
              <div class="col-md-3">
                <label for="taskDate" class="form-label"> Date: </label>
              </div>
              <div class="col-md-9">
                <input
                  ref="dateInput"
                  type="date"
                  id="taskDate"
                  v-model="localTask.date"
                  class="form-control"
                  :class="{ 'is-invalid': dateError }"
                />
                <div v-if="dateError" class="text-danger">
                  Please select a date for the subtask.
                </div>
              </div>
            </div> -->

            <!-- Priority Dropdown -->
            <div class="form-body">
              <div class="col-md-3">
                <label for="priority" class="form-label"> Priority: </label>
              </div>
              <div class="col-md-9">
                <select id="priority" v-model="localTask.priority" class="form-select">
                  <option value="Low">Low</option>
                  <option value="Medium">Medium</option>
                  <option value="High">High</option>
                </select>
              </div>
            </div>

            <div class="form-body">
              <div class="col-md-3">
                <label for="assignee" class="form-label"> Assignee: </label>
              </div>
              <div class="col-md-9">
                <select id="assignee" v-model="localTask.assignedTo" class="form-select">
                  <option
                    v-for="assignee in assignees"
                    :key="assignee.employeeUserId"
                    :value="assignee.employeeUserId"
                  >
                    {{ assignee.firstName }} {{ assignee.lastName }}
                  </option>
                </select>
              </div>
            </div>

            <!-- File Upload -->
            <div class="form-body">
              <div class="col-md-3">
                <label for="attachment" class="form-label"> Attachment: </label>
              </div>
              <div class="col-md-9">
                <input
                  type="file"
                  id="attachment"
                  class="form-control"
                  ref="taskAttachment"
                  @change="handleFileUpload"
                />
                <span
                  v-if="localTask.awsFileId"
                  style="font-size: 0.875rem; width: 100%; cursor: pointer"
                  @click="
                    $viewFile(
                      localTask['awsFiles.awsFileName'],
                      localTask['awsFiles.fileName'],
                      'taskAttachment',
                      'Task'
                    )
                  "
                  >{{ localTask["awsFiles.fileName"] }}</span
                >
              </div>
            </div>

            <div class="form-body recurring-task">
              <div
                class="header"
                v-if="this.type == 'recurring' || selectedTaskId == null"
              >
                <div>
                  <span class="title">Recurring ?</span>
                </div>
                <div>
                  <select
                    class="dropdown"
                    v-model="TypeOfTask"
                    @change="updateRepeatType"
                  >
                    <option value="None">None</option>
                    <option value="Daily">Daily</option>
                    <option value="Weekly">Weekly</option>
                    <option value="Monthly">Monthly</option>
                  </select>
                </div>
              </div>

              <div class="form-date" v-if="TypeOfTask === 'None'">
                <label
                  for="taskDate"
                  class="form-label"
                  style="margin-right: 10px; white-space: nowrap"
                >
                  Due Date:
                </label>

                <input
                  ref="dateInput"
                  type="date"
                  id="taskDate"
                  v-model="localTask.date"
                  class="form-control"
                  :class="{ 'is-invalid': dateError }"
                  style="width: 50%; max-width: 200px"
                />
              </div>

              <!-- Validation message remains below -->
              <div v-if="dateError" class="text-danger text-end">
                Please select a due date for the task.
              </div>

              <div v-if="TypeOfTask === 'Daily'">
                <div class="date-range">
                  <label for="fromDate">From:</label>
                  <input
                    type="date"
                    id="fromDate"
                    v-model="fromDate"
                    class="date-input"
                  />

                  <label for="toDate">To:</label>
                  <input type="date" id="toDate" v-model="toDate" class="date-input" />
                </div>
              </div>

              <div v-if="TypeOfTask === 'Weekly'">
                <div class="date-range">
                  <label for="fromDate">From:</label>
                  <input
                    type="date"
                    id="fromDate"
                    v-model="fromDate"
                    :min="minDate"
                    class="date-input"
                  />

                  <label for="toDate">To:</label>
                  <input type="date" id="toDate" v-model="toDate" class="date-input" />
                </div>

                <div class="days-container">
                  <span
                    v-for="(day, index) in weekdays"
                    :key="index"
                    class="day"
                    :class="{ active: selectedDays.includes(index) }"
                    @click="toggleDay(day)"
                  >
                    {{ day }}
                  </span>
                </div>
              </div>

              <!-- Monthly Options -->
              <div v-if="TypeOfTask.startsWith('Monthly')" class="monthly-options">
                <div class="date-range">
                  <label for="fromDate">From:</label>
                  <input
                    type="date"
                    id="fromDate"
                    v-model="fromDate"
                    class="date-input"
                  />

                  <label for="toDate">To:</label>
                  <input type="date" id="toDate" v-model="toDate" class="date-input" />
                </div>
                <div class="option">
                  <div class="left-side">
                    <input
                      type="radio"
                      id="repeatOnDates"
                      value="MonthlyDates"
                      @click="toggleType('MonthlyDates')"
                      v-model="repeatType"
                    />
                    <label for="repeatOnDates">Dates</label>
                  </div>
                  <div class="right-side">
                    <input
                      type="text"
                      class="date-input"
                      v-model="repeatDates"
                      placeholder="e.g., 4, 14, 24"
                    />
                  </div>
                </div>
                <div class="option">
                  <div class="left-side">
                    <input
                      type="radio"
                      id="repeatOnWeekday"
                      value="MonthlyWeekDay"
                      @click="toggleType('MonthlyWeekDay')"
                      v-model="repeatType"
                    />
                    <label for="repeatOnWeekday">Weekday</label>
                  </div>
                  <div class="right-side">
                    <select v-model="weekOfMonth">
                      <option value="1">First</option>
                      <option value="2">Second</option>
                      <option value="3">Third</option>
                      <option value="4">Fourth</option>
                      <option value="5">Fifth</option>
                    </select>
                    <select v-model="selectedWeekday" @change="addSelectedDay">
                      <option
                        v-for="(day, index) in weekdays"
                        :key="index"
                        :value="index"
                      >
                        {{ day }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <!-- <button class="clear-button" @click="clearSelection">Clear all</button> -->
            </div>

            <br />

            <!-- Save/Update Button -->
            <div class="save">
              <!-- Update Button -->
              <button
                v-if="
                  localTask.id &&
                  type !== 'recurring' &&
                  (localTask.createdById === $LocalUser.user.id ||
                    localTask.assignedTo === $LocalUser.user.id) &&
                  !isImageActive &&
                  localTask.status !== 'Completed'
                "
                type="button"
                class="btn btn-primary me-2"
                @click="submitTask"
              >
                Update
              </button>

              <button
                v-else-if="
                  localTask.id &&
                  (localTask.createdById === $LocalUser.user.id ||
                    localTask.assignedTo === $LocalUser.user.id) &&
                  !isImageActive &&
                  localTask.status !== 'Completed'
                "
                type="button"
                class="btn btn-primary me-2"
                @click="updateRecurringTask"
              >
                Update
              </button>

              <!-- Save Button -->
              <button
                v-if="
                  !localTask.id &&
                  !isImageActive &&
                  TypeOfTask === 'None' &&
                  (!localTask.status || localTask.status !== 'Completed')
                "
                type="button"
                class="btn btn-primary me-2"
                @click="submitTask()"
              >
                Save
              </button>
              <button
                v-else-if="
                  !localTask.id &&
                  !isImageActive &&
                  TypeOfTask !== 'None' &&
                  (!localTask.status || localTask.status !== 'Completed')
                "
                type="button"
                class="btn btn-primary me-2"
                @click="createRecurringTask()"
              >
                Save
              </button>

              <button
                v-if="isImageActive"
                type="button"
                class="btn btn-primary me-2"
                @click="addSubTask"
              >
                Add Subtask
              </button>

              <button
                v-if="
                  localTask.id &&
                  !isImageActive &&
                  (localTask.createdById === this.$LocalUser.user.id ||
                    localTask.assignedTo === this.$LocalUser.user.id) &&
                  this.TypeOfTask == 'None'
                "
                type="button"
                class="btn btn-danger me-2"
                @click="deleteTask"
              >
                Delete
              </button>

              <!-- Delete Recurring Button (For Recurring Task) -->
              <button
                v-else-if="
                  localTask.id &&
                  !isImageActive &&
                  (localTask.createdById === this.$LocalUser.user.id ||
                    localTask.assignedTo === this.$LocalUser.user.id) &&
                  this.TypeOfTask !== 'None'
                "
                type="button"
                class="btn btn-danger me-2"
                @click="deleteRecurringTask"
              >
                Delete
              </button>

              <!-- Cancel Button -->
              <button
                v-else
                type="button"
                class="btn btn-secondary me-2"
                @click="closeModal"
              >
                Cancel
              </button>
            </div>

            <hr style="color: black; margin-top: 0%" />

            <div
              v-if="this.selectedTask !== 'Recurring'"
              class="form-body subtask-section"
            >
              <!-- Button for Subtask -->
              <button
                v-if="localTask.id"
                type="button"
                @click="toggleDisplay('subtasks')"
                :class="['btn', 'btn-custom']"
                :style="{ backgroundColor: isButtonActive ? 'pink' : 'transparent' }"
              >
                <template v-if="localTask.status === 'Pending'">
                  Add SubTask
                  <!-- Plus Icon, only shown when status is 'Pending' -->
                  <img
                    v-if="
                      this.localTask.createdById === this.$LocalUser.user.id ||
                      this.localTask.assignedTo === this.$LocalUser.user.id
                    "
                    @click.stop="startAddingSubTask"
                    src="../../assets/icons/plusIconSales.png"
                    :style="{
                      backgroundColor: isImageActive ? 'grey' : 'rgba(0, 0, 0, 0.6)',
                      padding: '4px',
                      borderRadius: '3px',
                    }"
                    class="plus-icon"
                    alt="Add SubTask"
                  />
                </template>
                <template v-else> View SubTask </template>
              </button>

              <button
                v-if="localTask.id"
                type="button"
                @click="toggleDisplay('activities')"
                :class="['btn', 'btn-custom']"
                :style="{ backgroundColor: isActivityActive ? 'pink' : 'transparent' }"
                :disabled="isImageActive"
              >
                Activity
                <img
                  src="../../assets/icons/activityIcon.png"
                  alt="Activity"
                  style="width: 15px; height: 15px; margin-bottom: 1.6%"
                />
              </button>
            </div>

            <!-- sub Task card contents -->

            <div
              class="row col-md-12"
              v-if="showSubTasks && localTask.id && this.selectedTask !== 'Recurring'"
            >
              <div class="mb-2 col-md-12">
                <table class="task-table col-md-12">
                  <tbody>
                    <tr
                      v-for="subTask in subtasks"
                      :key="subTask.id"
                      @click="fetchSubTaskDetails(subTask.id)"
                      class="subtask-row"
                    >
                      <!-- Title Column -->
                      <td>
                        <img
                          v-if="subTask.status == 'Completed'"
                          class="tick-icon"
                          src="../../assets/icons/completedTask.png"
                          alt="Completed"
                        />
                        <img
                          v-else
                          class="tick-icon"
                          src="../../assets/icons/T.png"
                          alt="Pending"
                        />
                        {{ subTask.title ? truncateText(subTask.title, 16) : "No Title" }}
                      </td>

                      <!-- Due Date Column -->
                      <td>
                        <img
                          class="calendar-icon"
                          src="../../assets/icons/calandar.png"
                          alt="Calendar"
                        />
                        {{ $formatDateDMY(subTask.date) }}
                      </td>

                      <!-- Priority Column -->
                      <!-- <td>
                        <button
                          v-if="subTask.priority"
                          :class="getPriorityClass(subTask.priority)"
                          class="priority-btn"
                        >
                          {{ subTask.priority }}
                        </button>
                      </td> -->

                      <!-- Assignee Column -->
                      <td>
                        <img
                          class="profile-icon"
                          src="../../assets/icons/profile.png"
                          alt="Profile"
                        />
                        <!-- {{ subTask["users.firstName"] }} {{ subTask["users.lastName"] }} -->
                      </td>
                      <td @click="deletesubTask(subTask.id)">
                        <img
                          class="profile-icon"
                          src="../../assets/icons/redDelete.png"
                          alt="Profile"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div
              class="row col-md-12"
              v-if="showActivities && localTask.id && this.selectedTask !== 'Recurring'"
            >
              <table class="activity-table">
                <tbody>
                  <tr v-for="activity in activities" :key="activity.id">
                    <!-- Profile Column -->
                    <td class="profile-cell">
                      <img
                        class="profiles-icon"
                        src="../../assets/icons/profile.png"
                        alt="Profile"
                      />
                    </td>

                    <!-- Comment Column -->
                    <td>
                      <textarea
                        class="activity-comment-textarea"
                        :value="activity.comment"
                        readonly
                      ></textarea>
                    </td>
                    <td>
                      {{ $formatDateDMY(activity.created_at)
                      }}<span style="display: block">{{
                        formatTo12Hour(activity.updated_at)
                      }}</span>
                    </td>

                    <!-- Time Column -->
                    <!-- <td>{{ formatTo12Hour(activity.updated_at) }}</td> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import confetti from "canvas-confetti";

export default {
  props: {
    selectedTaskId: {
      type: Number,
      required: true,
    },

    selectedTask: {
      type: {
        type: String,
        required: true,
      },
    },
  },

  data() {
    return {
      localTask: {
        id: null,
        title: "",
        description: "",
        assignedTo: null,
        date: null,
        priority: "",
        parentTaskId: null, // Set the parentId to the selectedTaskId prop
        status: null,
        parentTaskTitle: null,
        awsFileId: null,
        fileName: null,
        createdById: null,
      },
      TypeOfTask: "None",
      repeatType: "None", // Default dropdown value
      weekdays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"], // Weekdays only
      selectedDays: [], // Pre-selected weekdays
      monthlyOption: "dates", // Default for monthly (dates or weekday)
      repeatDates: "", // Stores input for dates
      weekOfMonth: null, // Default for weekday option
      selectedWeekday: "Monday", // Default selected weekday
      fromDate: "",
      toDate: "",
      type: "",
      selectedUserId: null,
      activeButton: null,
      originalTaskData: null,
      isButtonActive: true, // Make the button active by default
      isImageActive: false,
      isActivityActive: false,
      isDisplayingSubTasks: false,
      showSubTasks: true,
      showActivities: false,
      parentTaskTitle: "",
      awsFileName: "",
      assignees: [],
      subtasks: [],
      activities: [],
      searchString: "",
    };
  },

  computed: {
    minDate() {
      const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format
      return today;
    },
  },

  mounted() {
    this.fetchAssignees();

    //by default when modal mounted its fetching default assignees
    if (this.selectedTaskId && this.selectedTask == "Recurring") {
      this.fetchRecurring();
    } else if (this.selectedTaskId) {
      this.fetchAssignees();
      console.log();
    }
  },

  watch: {
    selectedTaskId(newValue) {
      if (newValue !== null) {
        console.log("The new val is:", newValue);

        // Check if selectedTask exists and is "Recurring"
        if (this.selectedTask === "Recurring") {
          this.fetchRecurring();
          this.fetchAssignees();
        } else {
          this.fetchTaskDetails();
          this.TypeOfTask = "None";
          this.fetchAssignees();
        }
      } else {
        this.localTask.id = newValue;
        this.localTask = {};
        this.isImageActive = false;
        this.closeModal();
      }
    },

    // selectedTask(newTask) {
    //   if (newTask === "Recurring") {
    //     console.log("recieved new val is:", newTask);

    //     this.fetchRecurring();
    //   }
    // },
    TypeOfTask(newVal) {
      if (
        newVal === "MonthlyDates" ||
        newVal === "MonthlyWeekDay" ||
        newVal === "Weekly"
      ) {
        this.repeatType = newVal;
      }
    },

    repeatDates(newVal) {
      if (typeof newVal === "string" && newVal.trim() !== "") {
        this.selectedDays = newVal
          .split(",")
          .map((day) => day.trim())
          .filter((day) => !isNaN(day) && day !== "")
          .map(Number);
      } else {
        this.selectedDays = [];
      }
    },
  },

  methods: {
    updateRepeatType() {
      if (this.TypeOfTask === "Daily") {
        this.repeatType = "Daily";
        this.selectedDays = []; // Reset days if switching from Weekly
        this.weekOfMonth = null;
      } else if (this.TypeOfTask === "Weekly") {
        this.repeatType = "Weekly";
      } else if (this.TypeOfTask === "Monthly") {
        this.repeatType = "Monthly";
      } else {
        this.repeatType = ""; // Reset for "None"
      }
    },
    triggerConfetti() {
      confetti({
        particleCount: 500,
        spread: 300,
        origin: { y: 0.6 },
      });
    },

    async fetchRecurring() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/tasks/getRecurringTaskById/${this.selectedTaskId}`
        );

        if (!response.data) {
          console.error("Error: API response is null or undefined");
          return;
        }

        console.log("Fetching Recurring Task:", response.data);

        this.localTask = { ...response.data };

        this.weekOfMonth = null;
        this.repeatType = response.data.interval || ""; // Ensure repeatType is set
        this.TypeOfTask = response.data.interval || ""; // Keep this if required
        this.fromDate = response.data.startDate || "";
        this.toDate = response.data.endDate || "";

        // Reset fields to prevent conflicts
        this.selectedDays = [];
        this.repeatDates = "";
        this.selectedWeekday = "";
        this.type = "recurring";

        if (response.data.interval === "Weekly") {
          if (typeof response.data.day === "string") {
            this.selectedDays = response.data.day.split(",").map(Number);
          } else if (Array.isArray(response.data.day)) {
            this.selectedDays = response.data.day.map(Number);
          } else {
            this.selectedDays = [];
          }
        }

        console.log("Selected Days After Fetch:", this.selectedDays);

        if (response.data.interval === "MonthlyWeekDay") {
          this.weekOfMonth = response.data.weekOfMonth || null;
          this.selectedWeekday = response.data.day || "";
          this.repeatType = "MonthlyWeekDay"; // Ensure the correct value is set
          this.TypeOfTask = "Monthly";
          this.repeatDates = "";
        }

        if (response.data.interval === "MonthlyDates") {
          this.repeatDates = response.data.day || "";
          this.repeatType = "MonthlyDates"; // Ensure the correct value is set
          this.TypeOfTask = "Monthly";
          this.selectedWeekday = "";
          this.weekOfMonth = null;
        }

        await this.fetchActivity(this.selectedTaskId);
        this.isImageActive = false;
        console.log("Fetched Task:", this.localTask);
      } catch (error) {
        console.error(
          "Error fetching recurring task:",
          error.response?.data || error.message
        );
      }
    },

    addSelectedDay() {
      // Ensure selectedDays is always an array
      if (!Array.isArray(this.selectedDays)) {
        this.selectedDays = [];
      }

      if (
        this.selectedWeekday !== null &&
        !this.selectedDays.includes(this.selectedWeekday)
      ) {
        this.selectedDays.push(this.selectedWeekday); // Add index to selectedDays if not already included
      }
    },

    async createRecurringTask() {
      const requestBody = {
        title: this.localTask.title,
        description: this.localTask.description,
        assignedTo: this.localTask.assignedTo,
        status: this.localTask.status,
        priority: this.localTask.priority,
        parentTaskId: this.localTask.parentTaskId || 0,
        interval: this.repeatType, // Ensure it takes the correct type
        day: this.repeatType === "Daily" ? [] : this.selectedDays, // No days for daily
        weekOfMonth: this.repeatType === "Monthly" ? this.weekOfMonth : null,
        startDate: this.fromDate,
        endDate: this.toDate,
      };

      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/tasks/createRecurring`,
          requestBody
        );

        console.log("Recurring task created successfully:", response.data);
        this.$emit("submit-Recurring", response.data);
        this.resetFormData();
        this.triggerConfetti();
        this.closeModal();
      } catch (error) {
        this.$handleError(error);
      }
    },
    // Function to reset the form data
    resetFormData() {
      console.log("clearing form body");

      this.localTask = {
        title: "",
        description: "",
        assignedTo: null,
        status: "",
        priority: "",
        parentTaskId: null,
      };

      this.TypeOfTask = "";
      this.repeatType = "";
      this.selectedDays = [];
      this.weekOfMonth = null;
      this.fromDate = null;
      this.toDate = null;
    },

    async updateRecurringTask() {
      if (!this.selectedTaskId) {
        console.error("Error: selectedTaskId is missing.");
        return;
      }

      if (this.TypeOfTask === "Weekly") {
        this.repeatType = "Weekly";
        this.selectedDays = this.selectedDays.length ? this.selectedDays : []; // Ensure it's always an array
        this.weekOfMonth = null;
        this.selectedWeekday = "";
        this.repeatDates = "";
      } else if (this.TypeOfTask === "Monthly") {
        if (this.repeatType === "MonthlyDates") {
          this.selectedWeekday = "";
          this.weekOfMonth = null;
        } else if (this.repeatType === "MonthlyWeekDay") {
          this.repeatDates = "";
        }
      }

      const requestBody = {
        title: this.localTask.title,
        description: this.localTask.description,
        assignedTo: this.localTask.assignedTo,
        status: this.localTask.status,
        priority: this.localTask.priority,
        interval: this.repeatType,
        day:
          this.repeatType === "Weekly"
            ? this.selectedDays
            : this.repeatType === "MonthlyWeekDay"
            ? this.selectedWeekday
            : this.repeatDates,
        weekOfMonth: this.repeatType === "MonthlyWeekDay" ? this.weekOfMonth : null,
        startDate: this.fromDate,
        endDate: this.toDate,
      };

      console.log("Updating recurring task with request body:", requestBody);

      try {
        const response = await axios.put(
          `${this.$apiEndPoint}/tasks/updateRecurring/${this.selectedTaskId}`,
          requestBody
        );

        console.log("Recurring task updated successfully:", response.data);
        this.$toast("Recurring Task updated", "success");
        this.$emit("update-recurring", { ...requestBody, id: this.selectedTaskId });
        this.closeModal();
      } catch (error) {
        console.error(
          "Error updating recurring task:",
          error.response?.data || error.message
        );
      }
    },

    toggleDay(day) {
      if (!Array.isArray(this.selectedDays)) {
        this.selectedDays = [];
      }

      const dayIndex = this.weekdays.indexOf(day); // Convert name to index

      if (this.selectedDays.includes(dayIndex)) {
        this.selectedDays = this.selectedDays.filter((index) => index !== dayIndex);
      } else {
        this.selectedDays.push(dayIndex);
      }
    },

    toggleType(type) {
      this.repeatType = type;

      if (type === "MonthlyDates") {
        this.TypeOfTask = "Monthly";
        this.selectedWeekday = ""; // Clear weekday selection
        this.weekOfMonth = null;
      } else if (type === "MonthlyWeekDay") {
        this.TypeOfTask = "Monthly";
        this.repeatDates = ""; // Clear date selection
      }

      console.log("Repeat type changed to:", this.repeatType);
    },

    clearSelection() {
      this.selectedDays = [];
    },

    formatTo12Hour(datetime) {
      const time = datetime.split(" ")[1]; // Extract time part
      const [hours, minutes, seconds] = time.split(":");
      const period = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12; // Convert 0 to 12 for midnight
      return `${formattedHours}:${minutes}:${seconds} ${period}`;
    },

    async submitTask() {
      // Validate title
      if (!this.localTask.title) {
        this.$refs.titleInput.focus();

        return;
      }

      // Validate date
      if (!this.localTask.date) {
        this.$refs.dateInput.focus();

        return;
      }

      // Proceed with task saving if validation passes
      try {
        await this.submitForm();
      } catch (error) {
        console.error("Error saving task:", error);
        this.$toast("Failed to save task", "error");
      }
    },

    handleFileUpload() {
      const file = this.$refs.taskAttachment.files[0];
      console.log(file);
      if (file) {
        axios
          .post(`${this.$apiEndPoint}/company/putFileSignedURL`, {
            fileName: file.name,
            fileSize: file.size,
            contentType: file.type,
            fileCategory: "taskAttachment",
            moduleName: "Task",
          })
          .then((response) => {
            const signedUrl = response.data.s3URL;
            this.localTask.awsFileId = response.data.id; // Update awsFileId
            this.localTask.fileName = file.name; // Update fileName
            console.log(this.localTask);
            console.log(response.data);
            const xhr = new XMLHttpRequest();
            xhr.open("PUT", signedUrl, true);
            xhr.setRequestHeader("Content-Type", file.type);

            xhr.onreadystatechange = () => {
              if (xhr.readyState === 4 && xhr.status === 200) {
                console.log("File uploaded successfully");
              } else if (xhr.readyState === 4) {
                console.error("Error uploading file to S3:", xhr.responseText);
              }
            };
            xhr.send(file);
          })
          .catch((error) => {
            this.$handleError(error);
          });
      }
    },

    resetSubTaskForm() {
      console.log(
        `Clearing subtask form fields for Parent Task ID: ${this.localTask.parentTaskId}`
      );

      // Store the current task title as the parentTaskTitle before clearing
      this.parentTaskTitle = this.localTask.title;
      console.log("Stored parentTaskTitle:", this.parentTaskTitle);

      this.isAddingSubTask = true;

      // Reset all fields except for parentTaskId
      this.localTask.title = "";
      this.localTask.description = "";
      this.localTask.assignedTo = null;
      this.localTask.date = null;
      this.localTask.priority = "";
      this.localTask.status = null;
      this.localTask.awsFileId = null;
      this.localTask.fileName = null;

      // Retain parentTaskId and parentTaskTitle
      this.localTask.parentTaskId = this.localTask.id; // Retain parent task ID

      // Ensure parentTaskTitle is retained, not overwritten
      console.log("Subtask form reset with parentTaskId and parentTaskTitle:");
      console.log("Parent Task ID:", this.localTask.parentTaskId);
      console.log("Parent Task Title:", this.parentTaskTitle);
    },

    startAddingSubTask() {
      if (!this.isImageActive) {
        // Store original task data before resetting the form
        this.originalTaskData = { ...this.localTask };
        console.log("original task data:", this.originalTaskData);
      }
      this.isImageActive = !this.isImageActive; // Show Save Subtask button
      this.resetSubTaskForm(); // Reset the subtask form
    },

    toggleDisplay(type) {
      this.activeButton = type;
      if (type === "subtasks") {
        this.showSubTasks = true;
        this.showActivities = false;
        this.isButtonActive = true;
        this.isImageActive = false;
        this.isActivityActive = false;
      } else if (type === "activities") {
        this.showSubTasks = false;
        this.showActivities = true;
        this.isButtonActive = false;
        this.isActivityActive = true;
        this.isImageActive = false;
      }
    },

    displaySubTasks() {
      this.isDisplayingSubTasks = true;
      console.log("Fetched SubTAsks:", this.subtasks);
    },

    startAddingActivity() {
      this.isAddingActivity = true; // Show Save Subtask button
      console.log("Fetched Task Activity:", this.activities);
    },

    truncateText(text, length) {
      if (text && text.length > length) {
        return text.substring(0, length) + "...";
      }
      return text;
    },

    async addSubTask() {
      try {
        // Validate title field
        if (!this.localTask.title || this.localTask.title.trim() === "") {
          console.error("Title is required.");

          this.$refs.titleInput.focus(); // Focus on the title input field for the user to correct it

          return;
        }

        // Validate date field
        if (!this.localTask.date) {
          console.error("Date is required.");
          this.$refs.dateInput.focus(); // Focus on the date input field for the user to correct it
          return;
        }

        // Prepare subtask data for the API
        const subtaskData = {
          title: this.localTask.title.trim(),
          parentTaskId: this.localTask.parentTaskId, // Assuming the parent task ID is available
          description: this.localTask.description || "",
          assignedTo: this.localTask.assignedTo || null,
          date: this.localTask.date,
          priority: this.localTask.priority,
          status: this.localTask.status || null,
          awsFileId: this.localTask.awsFileId || null,
        };

        // Make the API call to add the subtask
        const response = await axios.post(`${this.$apiEndPoint}/tasks`, subtaskData);
        const newSubtask = response.data;

        console.log("Subtask added successfully:", newSubtask);

        // Emit event to the parent component with the newly added subtask
        this.$emit("subtaskAdded", newSubtask);
        await this.fetchSubTaskDetails(this.localTask.parentTaskId);

        this.isImageActive = false; // Optionally reset the image active state or modal state
      } catch (error) {
        console.error(
          "Error adding subtask:",
          error.response ? error.response.data : error.message
        );
      }
    },

    deleteSubtask() {
      console.log(`Marking subtask with ID: ${this.localTask.id} as deleted`);

      // Set the deleted flag to true instead of deleting it
      this.localTask.deleted = true;

      // Emit an event to notify the parent component about the deletion
      this.$emit("subtask-deleted", this.localTask.id);
      this.closeModal(); // Optionally close the modal after marking as deleted
    },

    async deletesubTask(taskId) {
      try {
        const response = await axios.delete(`${this.$apiEndPoint}/tasks/${taskId}`);
        console.log("Task deleted:", response.data);

        this.$toast("Subtask deleted", "success");
        this.fetchTaskDetails();
        this.$emit("subtask-deleted", taskId);
      } catch (error) {
        console.error("Error deleting task:", error);
      }
    },

    getPriorityClass(priority) {
      if (!priority) return ""; // Default case for missing or invalid priority
      priority = String(priority); // Ensure priority is a string
      switch (priority) {
        case "High":
          return "priority-high";
        case "Medium":
          return "priority-medium";
        case "Low":
          return "priority-low";
        default:
          return "priority-none"; // Optionally handle unexpected values
      }
    },

    getPriorityLabel(priority) {
      if (!priority) return "None"; // Default case for missing or invalid priority
      priority = String(priority); // Ensure priority is a string
      switch (priority) {
        case "1":
          return "High";
        case "2":
          return "Medium";
        case "3":
          return "Low";
        default:
          return "None"; // Handle unexpected values
      }
    },

    async markAsCompleted() {
      const previousStatus = this.localTask.status; // Store the previous status

      try {
        // Toggle the task status
        if (this.localTask.status === "Pending") {
          this.localTask.status = "Completed";
        } else if (this.localTask.status === "Completed") {
          this.localTask.status = "Pending";
        }

        // Update task on the server
        await axios.put(
          `${this.$apiEndPoint}/tasks/updateTask/${this.localTask.id}`,
          this.localTask
        );

        // Emit the task ID to the parent for UI update
        this.$emit("task-updated", this.localTask.id);

        if (this.localTask.status === "Completed") {
          this.triggerConfetti();
        }
      } catch (error) {
        this.$handleError(error);
        this.localTask.status = previousStatus;
      }
    },

    async fetchSubTaskDetails(taskId) {
      console.log(`Fetching details for task ID: ${taskId}`); // Debug log
      try {
        const response = await axios.get(`${this.$apiEndPoint}/tasks/${taskId}`);
        const taskDetails = response.data;

        // Update localTask with the fetched task details
        this.localTask = {
          id: taskDetails.id,
          title: taskDetails.title,
          description: taskDetails.description,
          assignedTo: taskDetails.assignedTo,
          date: taskDetails.date,
          priority: taskDetails.priority,
          parentTaskId: taskDetails.parentTaskId,
          status: taskDetails.status,
          awsFileId: taskDetails.awsField,
          fileName: taskDetails.fileName,
        };

        console.log("the local task status is set to:", this.localTask.status);

        // Set parentTaskTitle from the fetched data, if available
        this.parentTaskTitle = taskDetails.parentTaskTitle || ""; // Default to empty string if undefined
        this.isImageActive = false;

        // Update subtasks (if relevant)
        this.subtasks = taskDetails.subtasks || [];
        console.log("Fetched Task Details:", taskDetails);

        await this.fetchActivity(taskId);
      } catch (error) {
        console.error("Error fetching task details:", error);
      }
    },

    async fetchActivity(taskId) {
      console.log(`Fetched activity of ID: ${taskId}`);
      try {
        const response = await axios.get(`${this.$apiEndPoint}/tasks/comments/${taskId}`);
        this.activities = response.data; // Store the fetched comments in the activity array
        console.log("Activity fetched:", this.activity); // Optional: log the fetched data
      } catch (error) {
        console.error("Error fetching activity:", error);
      }
    },

    async fetchTaskDetails() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/tasks/${this.selectedTaskId}`
        );

        // Assign the main task details to localTask
        this.localTask = {
          ...response.data,
          // Set parentTaskId to selectedTaskId
        };

        this.type = "";
        // Fetch the sub-tasks if they exist in the response
        if (response.data.subtasks) {
          this.subtasks = response.data.subtasks; // Assign the subtasks to the array
        } else {
          this.subtasks = []; // Set an empty array if no subtasks exist
        }

        this.parentTaskTitle = response.data.parentTaskTitle; // Set the parent task title
        await this.fetchActivity(this.selectedTaskId);
        this.isImageActive = false;
        console.log("Fetched Task:", this.localTask);
        console.log("Fetched Subtasks:", this.subtasks);
        console.log("fetched activity:", this.activities);
      } catch (error) {
        this.$handleError(error);
      }
    },

    async deleteTask() {
      try {
        console.log(`Deleting task with ID: ${this.localTask.id}`);
        await axios.delete(`${this.$apiEndPoint}/tasks/${this.localTask.id}`);
        this.$emit("task-deleted", this.localTask.id);
        this.closeModal();
      } catch (error) {
        this.$handleError(error);
      }
    },

    async deleteRecurringTask() {
      try {
        console.log(`Deleting task with ID: ${this.localTask.id}`);
        await axios.delete(
          `${this.$apiEndPoint}/tasks/deleteRecurring/${this.localTask.id}`
        );
        this.$emit("RecurringTask-deleted", this.localTask.id);
        this.closeModal();
      } catch (error) {
        this.$handleError(error);
      }
    },

    async fetchAssignees() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/employee/getByRoles`);
        this.assignees = response.data.map((employee) => ({
          employeeId: employee.employeeId,
          employeeUserId: employee.employeeUserId,
          firstName: employee["employees.firstName"],
          lastName: employee["employees.lastName"],
        }));

        console.log("Employee data:", this.employees);
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    },

    async submitForm() {
      try {
        let response;
        if (this.localTask.id) {
          response = await axios.put(
            `${this.$apiEndPoint}/tasks/updateTask/${this.localTask.id}`,
            this.localTask
          );
          this.$emit("updateTaskById", this.localTask.id);
        } else {
          response = await axios.post(`${this.$apiEndPoint}/tasks/`, this.localTask);
          this.$emit("submit-form", response.data);
          this.triggerConfetti();
        }

        console.log("Task saved successfully:", response.data);

        this.resetForm();
        this.closeModal();
      } catch (error) {
        this.$handleError(error);
      }
    },

    resetForm() {
      this.localTask = {
        id: null,
        title: "",
        description: "",
        assignedTo: null,
        date: null,
        priority: "",
        parentTaskId: null,
        status: null,
        awsFileName: null,
      };

      // Reset file input field
      if (this.$refs.taskAttachment) {
        this.$refs.taskAttachment.value = null;
      }
    },

    closeModal() {
      const modalElement = document.getElementById("taskModal");
      if (modalElement) {
        modalElement.style.display = "none";
        modalElement.classList.remove("show");
        document.body.classList.remove("modal-open");
        const backdrop = document.querySelector(".modal-backdrop");
        if (backdrop) {
          backdrop.remove();
        }
        this.localTask.id = null;
        this.TypeOfTask = "None";
      }
    },
  },
};
</script>

<style scoped>
.form-date {
  display: flex;
  align-items: center;
  padding-top: 10px;
  justify-content: space-between;
  margin-bottom: 0px;
  width: 100%;
}
.recurring-task {
  display: flex;
  flex-direction: column;
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.header {
  display: flex;
  font-size: small;
  gap: 240px;
  align-items: center;
}

.dropdown {
  background: #f4f4f4;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 0.875rem;
}

.date-range {
  display: flex;
  gap: 20px;
  margin: 10px 0px 0px 0px;
  align-items: center;
}

.date-input {
  padding: 5px;
  font-size: 14px;
}

.days-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: 16px 0;
}

.day {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e0e0e0;
  border-radius: 40%;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.day.active {
  background-color: #ffc107;
  color: white;
  border-color: #ffc107;
}

.day:hover {
  background-color: #ffc107;
}

.clear-button {
  background: none;
  border: none;
  color: #007bff;
  font-size: 0.875rem;
  cursor: pointer;
  align-self: flex-end;
}

/* Monthly Options Styling */
.monthly-options {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 20px;
}

.option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.monthly-options div {
  display: flex;
  align-items: center;
  gap: 18px;
  font-size: 0.875rem;
}

.monthly-options label {
  cursor: pointer;
}

.date-input {
  background: #f4f4f4;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 0.875rem;
  width: 150px;
}

.monthly-options select {
  background: #f4f4f4;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 0.875rem;
}

.activity-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 10px;
}

.activity-table th,
.activity-table td {
  border: 1px solid #ccc;
  text-align: right;
  border: none;
  padding: 5px;
}

.profile-cell {
  text-align: center;
  border: none;
}

.profiles-icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.activity-comment-textarea {
  width: 100%; /* Fill the table cell */
  font-size: 10px;
  resize: none; /* Disable resizing */
  overflow: auto; /* Allow scrolling if the text is too long */
  border: 1px solid #ccc; /* Optional: Match table cell borders */
  border-radius: 4px; /* Optional: Rounded corners */
  border: none;
  padding: 5px; /* Add padding for readability */
}

label {
  font-size: small;
}
.save {
  display: flex;
  justify-content: space-around;
  margin: -20px 0px 20px 0px;
}

.save button {
  font-size: small;
  height: fit-content;
}

.modal-header {
  border: none;
  width: 95%;
}
.form-body {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
}

.form-body .col-md-3 {
  flex: 0 0 25%; /* Ensures labels take up 25% of the width */
  max-width: 25%;
  text-align: left; /* Aligns labels to the left */
  padding-left: 10px; /* Adds some padding from the left */
  font-weight: bold; /* Optional: Make labels stand out */
}

.form-body .col-md-9 {
  flex: 1; /* Makes the input field take up the remaining space */
}

textarea.form-control,
input.form-control,
select.form-select {
  width: 100%; /* Ensures the input fields span the available width */
  padding: 8px;
  font-size: 14px;
}

.btn-custom:disabled {
  opacity: 0.5;
  /* Makes the button look disabled */
  cursor: not-allowed;
}

.collaborator-container {
  position: relative;
  display: flex;
  align-items: end;
}

.collaborator-initials {
  font-size: 8px;
  background-color: #4884f7;
  border-radius: 50%;
  color: #fcfcfc;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-left: -8px;
  /* Adjust this to control overlap */
  border: 2px solid #fff;
  /* Optional: adds a white border around each badge */
}

.btn-custom:hover {
  background-color: #ff7cb2;
  border: 1px solid black;
  transform: scale(1.05);
  /* Slightly enlarge the button on hover */
  color: rgb(26, 25, 25);
  /* Change text color on hover */
}

.plus-icon {
  height: 15px;
  width: 15px;
  transition: background-color 0.3s ease;
}

.btn:disabled {
  background-color: #cccccc;
  /* Light grey */
  cursor: not-allowed;
}

.btn-custom {
  border: 1px solid black;
  /* Remove border */
  padding: 8px;
  /* Both buttons will have equal width */
  height: 30px;
  font-size: 11px;
  font-weight: bolder;
  background-color: inherit;
  display: flex;
  align-items: center;
  border-color: rgb(114, 114, 114);
  gap: 8px;
  border-radius: 6px;
  transition: background-color 0.3s ease, transform 0.4s ease;
  text-transform: uppercase;
}

.btn-custom img {
  width: 20px;
  height: 19px;
}

.task-card button {
  border: none;
  color: white;
  font-size: 10px;
  padding: 6px 6px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.priority-low {
  background-color: lightpink;
}

.priority-medium {
  background-color: rgb(255, 106, 0);
}

.priority-high {
  background-color: rgb(228, 56, 56);
}

.btn-custom:active {
  transform: scale(0.98);
  /* Give a pressed effect */
}

.subtask-icon {
  width: 12px;
  height: 10px;
  margin-top: none;
  padding-left: 3px;
}

.attachment-icon {
  width: 12px;
  height: 10px;
  margin-bottom: none;
  padding-left: 3px;
}

.task-card:hover {
  background-color: rgb(255, 255, 255);
  /* Slightly darker grey on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  /* Elevate the shadow on hover */
  transform: translateY(-5px);
  /* Slight upward movement on hover */
}

.cards {
  width: 100%;
  padding: 10px 10px;
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  /* Allows wrapping to the next line */
  background-color: #f8f8f8;
  justify-content: flex-start;
  align-items: flex-start;
  /* Ensures all items stretch to the same height */
}

.task-card {
  background-color: white;
  border-radius: 10px;
  padding: 10px 15px 4px;
  margin-left: 8px;
  margin-top: 1%;
  margin-right: 0px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  flex-basis: calc(50% - 20px);
  /* Ensure 2 cards per row */
  box-sizing: border-box;
  /* Include padding and border in width */
}

.task-title {
  margin: 0;
  font-weight: bold;
  font-size: 12px;
  height: 20px;
}

.task-description {
  margin: 0;
  font-size: 10px;
  height: 20px;
}

.task-label {
  font-size: 12px;
  margin-left: 10px;
}

.task-assignee {
  font-size: 10px;
  font-weight: bold;
  margin-left: 10px;
}

.task-date {
  font-size: 10px;
  font-weight: bold;
  margin: 0 5px 0 0;
}

.profile-icon,
.attachment-icon,
.subtask-icon {
  width: 15px;
  height: 15px;
}

.tick-icon {
  width: 15px;
  height: 15px;
  margin-left: 13px;
  margin-top: 4px;
}

.icon-text {
  font-size: 10px;
  padding-left: 2px;
}
.completed-button button {
  font-size: 8px;
  width: 50%;
}

.subtask-section {
  display: flex;
  justify-content: space-between;
}

.subtask-section button {
  font-size: 9px;
}
/* General Responsive Adjustments */
@media (max-width: 768px) {
  .form-body {
    display: ruby-text;
    align-items: center;
    margin-bottom: 35px;
  }

  .subtask-section {
    display: flex;
    justify-content: space-between;
  }

  .subtask-section button {
    font-size: 8px;
  }

  .save {
    display: flex;
    justify-content: space-around;
    margin: -50px 0px 10px 0px;
  }

  label {
    font-size: small;
  }
  .custom-modal {
    width: 90%; /* Fit within mobile screen */
    margin: 0 auto; /* Center align the modal */
    border-radius: 8px; /* Add rounded corners for better UI */
  }

  .custom-modal .modal-content {
    height: auto; /* Allow content to determine height */
    padding: 10px; /* Reduce padding for compact view */
    max-height: 85%; /* Avoid vertical overflow */
    overflow-y: auto; /* Enable scrolling if content overflows */
    width: 65%;
  }

  .modal-header {
    padding: 10px; /* Reduce padding */
    font-size: 16px; /* Adjust font size */
    width: 100%;
  }

  .modal-body {
    padding: 10px; /* Reduce padding */
  }

  .modal-body form {
    display: flex;
    flex-direction: column; /* Stack form fields vertically */
    gap: 10px; /* Add spacing between elements */
  }

  .modal-body input {
    font-size: small;
  }
  .modal-body select {
    width: 100%; /* Full width for inputs and buttons */
    font-size: 14px; /* Adjust font size for readability */
  }

  .btn-custom {
    padding: 12px; /* Increase padding for touch-friendly buttons */
    font-size: 16px; /* Ensure button text is legible */
  }
}

@media (max-width: 480px) {
  .form-body {
    display: ruby-text;
    align-items: center;
    margin-bottom: 35px;
  }
  .subtask-section {
    display: flex;
    justify-content: space-between;
  }

  .subtask-section button {
    font-size: 8px;
    padding: 4px 5px 4px 5px;
  }

  label {
    font-size: small;
  }

  .completed-button button {
    font-size: 8px;
    width: 100%;
  }

  .custom-modal {
    width: 95%; /* Narrower width for very small devices */
    margin: 0 auto;
  }

  .custom-modal .modal-content {
    margin-top: 3%;
    height: auto;
    padding: 8px; /* Compact padding */
    max-height: 100%; /* Prevent overflow */
    width: 100%; /* Narrower width for very small devices */
  }

  .modal-body select {
    font-size: 12px; /* Smaller font size for compact screens */
  }

  .modal-body input {
    width: 100%;
    font-size: 10px;
  }

  .modal-header {
    font-size: 14px; /* Reduce header font size */
    width: 100%;
  }

  .btn-custom {
    font-size: 14px; /* Adjust button font size */
    padding: 10px; /* Compact padding for small screens */
  }

  .days-container {
    display: flex;
    justify-content: space-between;
    gap: 0px;
    margin: 16px 0;
  }

  .header {
    display: flex;
    font-size: small;
    gap: 125px;
    align-items: center;
  }

  .day {
    width: 40px;
    height: 30px;
    display: flex;
    border-radius: 30%;
    font-size: 12px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }

  .date-range {
    display: grid;
    gap: 10px;
    margin-bottom: 10px;
    align-items: center;
  }

  .monthly-options .date-range {
    display: grid;
    gap: 16px;
    margin-top: 20px;
  }
}

.closeImg {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

/* Responsive Modal Width */
.custom-modal {
  width: 120%;
}

/* Slide-in Transition */
.custom-modal .modal-dialog {
  position: fixed;
  top: 0;
  right: 1%;
  /* Start off-screen */
  height: 100%;
  transition: right 0.5s ease-in-out;
}

.custom-modal .modal-content {
  scrollbar-color: grey;
  margin-top: 7%;
  height: 91%;
  overflow-y: auto;
  word-wrap: break-word;
}

.custom-modal .modal-content::-webkit-scrollbar {
  width: 6px;
}

.custom-modal .modal-content::-webkit-scrollbar-thumb {
  background-color: #acacac;
  border-radius: 10px;
}

.modal-body input {
  width: 100%;
  font-size: 12px;
}

.modal-body select {
  width: 100%;
  font-size: 12px;
}

/* Button Styling */
button.btn-primary {
  background-color: #007bff;
  border: none;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button.btn-primary:hover {
  background-color: #0056b3;
}

button:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Subtle Backdrop */
.modal-backdrop.show {
  opacity: 0.5;
  background-color: rgba(0, 0, 0, 0.5);
  display: block;
}

.task-table {
  width: 100%;
  border-collapse: collapse;
}

.task-table th,
.task-table td {
  padding: 10px;
  text-align: left;
  font-size: 12px;
  border-bottom: 1px solid #ddd;
}

.task-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}
.task-table td {
  padding-left: 0px;
  padding-right: 0px;
}

.subtask-row {
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.subtask-row:hover {
  background-color: #f9f9f9;
}

.profile-icon,
.calendar-icon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  vertical-align: middle;
}

.tick-icon {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  vertical-align: sub;
}

.priority-btn {
  font-size: 8px;
  padding: 4px 6px;
  color: whitesmoke;
  border-radius: 6px;
  border: none;
  width: 60px;
  line-height: 1;
}
</style>
