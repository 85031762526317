<template>
  <Header />
  <div class="content-Container">
    <Nav />
    <div class="containerChild overflow-y-hidden">
      <div class="p-2 MailPage">
        <div class="section p-2 mailleftmenu WEB">
          <div class="section-title mainHeadingUnderline">Mailbox</div>
          <ul class="folder-list p-2">
            <li
              @click="showFolder('inbox')"
              :class="{ 'selected-folder': selectedFolder === 'inbox' }"
            >
              <img src="../assets/icons/Inbox.png" alt="" />
              <span>Inbox</span>
            </li>
            <li
              @click="showFolder('sent')"
              :class="{ 'selected-folder': selectedFolder === 'sent' }"
            >
              <img src="../assets/icons/mailSent.png" alt="" />
              <span>Sent</span>
            </li>
            <li
              @click="showFolder('archived')"
              :class="{ 'selected-folder': selectedFolder === 'archived' }"
            >
              <img src="../assets/icons/inboxArchive.png" alt="" />
              <span>Archived</span>
            </li>
            <li
              @click="showFolder('favorite')"
              :class="{ 'selected-folder': selectedFolder === 'favorite' }"
            >
              <img src="../assets/icons/favourite.png" alt="" />
              <span>Favorite</span>
            </li>
            <li @click="showData('compose')">
              <img src="../assets/icons/composButton.png" alt="" style="width: 100px" />
            </li>
          </ul>
        </div>
        <div class="section p-2 mailleftmenu MOBILE">
          <div class="d-flex justify-content-between align-items-center">
            <!-- Dropdown menu -->
            <div class="dropdown">
              <button
                class="btn custom-dropdown-toggle folder-select"
                type="button"
                id="folderDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span class="folder-label">{{ getFolderLabel(selectedFolder) }}</span>
                <span class="dropdown-icon">
                  <img
                    src="../assets/icons/blackUparrow-1.png"
                    alt=""
                    style="width: 15px; height: 12px; margin-left: 10px; cursor: pointer"
                  />
                </span>
              </button>
              <ul class="dropdown-menu" aria-labelledby="folderDropdown">
                <li>
                  <a class="dropdown-item" @click="changeFolder('inbox')">Inbox</a>
                </li>
                <li>
                  <a class="dropdown-item" @click="changeFolder('sent')">Sent</a>
                </li>
                <li>
                  <a class="dropdown-item" @click="changeFolder('archived')">Archived</a>
                </li>
                <li>
                  <a class="dropdown-item" @click="changeFolder('favorite')">Favorite</a>
                </li>
              </ul>
            </div>

            <!-- Compose button -->
            <div @click="showData('compose')" class="compose-btn">
              <img
                src="../assets/icons/composButton.png"
                alt="Compose"
                class="compose-icon"
              />
            </div>
          </div>
        </div>

        <!-- Second Section: Display Data -->
        <div class="section mailcentermenu WEB">
          <div class="section-title p-2">
            <!-- Input field with overlapping search icon -->
            <div style="position: relative; margin: 8px 0px">
              <input
                v-model="searchString"
                @input="searchEmails"
                type="text"
                class="search-input ms-2"
                style="
                  width: calc(100% - 20px);
                  border-radius: 10px;
                  border: 1px solid #fff;
                  padding: 8px 10px 8px 32px;
                "
                placeholder="Search..."
              />
              <img
                src="../assets/icons/search.png"
                alt="Search"
                style="
                  position: absolute;
                  top: 50%;
                  left: 15px; /* adjusted position */
                  transform: translateY(-50%);
                  width: 20px;
                  height: 20px;
                  z-index: 1; /* ensure the icon stays above the input */
                "
              />
            </div>
            <!-- End of input field with overlapping search icon -->
          </div>
          <div class="Inboxdata-list" style="width: 100%">
            <div v-if="emails.length === 0">
              <div class="text-center" style="margin-top: 50%">
                <img src="../assets/icons/NoMails.png" alt="No Mails" />
              </div>
            </div>
            <div v-else>
              <div v-for="(email, index) in emails" :key="email.id">
                <section
                  class="MailLine d-flex justify-content-between"
                  :style="{
                    backgroundColor:
                      selectedData === email.id
                        ? '#ffffff'
                        : email.readStatus === 0
                        ? '#D9D9D9'
                        : '#ECECEC',
                    padding: '14px 12px',
                    width: '100%',
                  }"
                >
                  <div
                    class="d-flex align-items-center"
                    @click="showData(email)"
                    style="width: 80%"
                  >
                    <img
                      v-if="email['employees.signedUrl'] !== null"
                      :src="email['employees.signedUrl']"
                      alt="Search"
                      style="
                        width: 35px;
                        height: 35px;
                        margin-right: 10px;
                        border-radius: 50%;

                        object-fit: cover;
                      "
                    />
                    <img
                      v-else
                      src="../assets/icons/profile.png"
                      alt="Search"
                      style="
                        width: 35px;
                        height: 35px;
                        margin-right: 10px;
                        display: inline-block;
                        object-fit: cover;
                        object-position: center;
                        image-rendering: auto;
                      "
                    />
                    <span
                      style="
                        font-size: 12px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 75%;
                      "
                      class="align-items-center"
                    >
                      <span style="font-weight: 600; font-size: 14px">{{
                        email["employees.firstName"]
                      }}</span>
                      <br />
                      <span :title="email['emailContents.subject']">{{
                        email["emailContents.subject"]
                      }}</span>
                    </span>
                  </div>
                  <div class="d-flex flex-column align-items-end" style="width: 20%">
                    <span v-if="this.selectedFolder !== 'sent'">
                      <img
                        v-if="email.favourite !== 0"
                        src="../assets/icons/starFilled.png"
                        alt=""
                        style="
                          width: 15px;
                          height: 15px;
                          margin-right: 5px;
                          cursor: pointer;
                        "
                        @click="favourite(email.id, index)"
                      />
                      <img
                        v-else
                        id="faviorite"
                        @click="favourite(email.id, index)"
                        src="../assets/icons/yellowUnfavourite.png"
                        alt=""
                        style="
                          width: 15px;
                          height: 15px;
                          cursor: pointer;
                          margin-right: 5px;
                        "
                      />
                      <img
                        src="../assets/icons/3blackDots.png"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        style="width: 15px; height: 15px; cursor: pointer"
                        alt=""
                      />
                      <div class="dropdown-menu p-0" aria-labelledby="dropdownMenuButton">
                        <a
                          v-if="selectedFolder === 'archived'"
                          class="dropdown-item"
                          href="#"
                          @click="archive(email.id, index)"
                          >Unarchive</a
                        >
                        <a
                          v-else
                          class="dropdown-item"
                          href="#"
                          @click="archive(email.id, index)"
                          >Archive</a
                        >
                      </div>
                    </span>
                    <span
                      style="
                        font-size: 10px;
                        margin-top: 5px;
                        margin-right: 8px;
                        cursor: pointer;
                      "
                      >{{ formatDate(email.created_at) }}</span
                    >
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
        <div class="section mailcentermenu MOBILE" v-if="Mobileview">
          <div class="section-title">
            <!-- Input field with overlapping search icon -->
            <div style="position: relative; margin: 8px 0px">
              <input
                v-model="searchString"
                @input="searchEmails"
                type="text"
                class="search-input ms-2"
                style="
                  width: calc(100% - 20px);
                  border-radius: 10px;
                  border: 1px solid #fff;
                  padding: 8px 10px 8px 32px;
                "
                placeholder="Search..."
              />
              <img
                src="../assets/icons/search.png"
                alt="Search"
                style="
                  position: absolute;
                  top: 50%;
                  left: 15px; /* adjusted position */
                  transform: translateY(-50%);
                  width: 20px;
                  height: 20px;
                  z-index: 1; /* ensure the icon stays above the input */
                "
              />
            </div>
            <!-- End of input field with overlapping search icon -->
          </div>
          <div class="Inboxdata-list" style="width: 100%">
            <div v-if="emails.length === 0">
              <div class="text-center" style="margin-top: 50%">
                <img src="../assets/icons/NoMails.png" alt="No Mails" />
              </div>
            </div>
            <div v-else>
              <div v-for="(email, index) in emails" :key="email.id">
                <section
                  class="MailLine d-flex justify-content-between"
                  :style="{
                    backgroundColor:
                      selectedData === email.id
                        ? '#ffffff'
                        : email.readStatus === 0
                        ? '#D9D9D9'
                        : '#ECECEC',
                    padding: '14px 12px',
                    width: '100%',
                  }"
                >
                  <div
                    class="d-flex align-items-center"
                    @click="showData(email)"
                    style="width: 80%"
                  >
                    <img
                      v-if="email['employees.signedUrl'] !== null"
                      :src="email['employees.signedUrl']"
                      alt="Search"
                      style="
                        width: 35px;
                        height: 35px;
                        margin-right: 10px;
                        border-radius: 50%;
                        display: inline-block;
                        object-fit: cover;
                        object-position: center;
                        image-rendering: auto;
                      "
                    />
                    <img
                      v-else
                      src="../assets/icons/profile.png"
                      alt="Search"
                      style="width: 35px; height: 35px; margin-right: 10px"
                    />
                    <span
                      style="
                        font-size: 12px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      "
                      class="align-items-center"
                    >
                      <span style="font-weight: 600; font-size: 14px">{{
                        email["employees.firstName"]
                      }}</span>
                      <br />
                      <span>{{ email["emailContents.subject"] }}</span>
                    </span>
                  </div>
                  <div class="d-flex flex-column align-items-end" style="width: 20%">
                    <span v-if="this.selectedFolder !== 'sent'">
                      <img
                        v-if="email.favourite !== 0"
                        src="../assets/icons/starFilled.png"
                        alt=""
                        style="
                          width: 15px;
                          height: 15px;
                          margin-right: 5px;
                          cursor: pointer;
                        "
                        @click="favourite(email.id, index)"
                      />
                      <img
                        v-else
                        id="faviorite"
                        @click="favourite(email.id, index)"
                        src="../assets/icons/yellowUnfavourite.png"
                        alt=""
                        style="
                          width: 15px;
                          height: 15px;
                          cursor: pointer;
                          margin-right: 5px;
                        "
                      />
                      <img
                        src="../assets/icons/3blackDots.png"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        style="width: 15px; height: 15px; cursor: pointer"
                        alt=""
                      />
                      <div class="dropdown-menu p-0" aria-labelledby="dropdownMenuButton">
                        <a
                          v-if="selectedFolder === 'archived'"
                          class="dropdown-item"
                          href="#"
                          @click="archive(email.id, index)"
                          >Unarchive</a
                        >
                        <a
                          v-else
                          class="dropdown-item"
                          href="#"
                          @click="archive(email.id, index)"
                          >Archive</a
                        >
                      </div>
                    </span>
                    <span
                      style="
                        font-size: 10px;
                        margin-top: 5px;
                        margin-right: 8px;
                        cursor: pointer;
                      "
                      >{{ formatDate(email.created_at) }}</span
                    >
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>

        <!-- Third Section: Details of Selected Item -->
        <div class="section p-3 mailrightmenu WEB">
          <div class="details" style="height: 100%">
            <div
              v-if="selectedData === null"
              class="d-flex justify-content-center align-items-center"
              style="margin-top: 30%"
            >
              <img src="../assets/icons/DefaultInboxIcon.png" alt="" />
            </div>

            <div v-if="selectedData === 'compose'" style="height: 100%; overflow-y: auto">
              <form>
                <div
                  class="container d-flex justify-content-between p-1"
                  style="background-color: #b1c5df; border-radius: 5px"
                >
                  <h5 class="modal-title" style="font-size: 16px" id="emailModalLabel">
                    New Mail
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    @click="toggleCompose"
                    aria-label="Close"
                  ></button>
                </div>

                <div class="mb-3 row mt-2">
                  <label for="toField" class="col-sm-2 col-form-label">To:</label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      class="form-control inputBg"
                      id="toField"
                      v-model="emailTo"
                      placeholder="Recipient's email address"
                      @blur="validateEmail"
                    />
                    <small v-if="emailError" class="text-danger">{{ emailError }}</small>
                  </div>
                </div>
                <div class="mb-3 row">
                  <label for="ccField" class="col-sm-2 col-form-label">CC:</label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      class="form-control inputBg"
                      id="ccField"
                      placeholder="CC email addresses"
                      v-model="emailCC"
                    />
                  </div>
                </div>
                <div class="mb-3 row">
                  <label for="subjectField" class="col-sm-2 col-form-label"
                    >Subject:
                  </label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      class="form-control inputBg"
                      id="subjectField"
                      placeholder="Email subject"
                      v-model="emailSubject"
                    />
                  </div>
                </div>

                <div class="mb-3 row">
                  <label for="bodyField" class="col-sm-2 col-form-label">Body:</label>
                  <div class="col-sm-10">
                    <textarea
                      class="form-control inputBg"
                      id="bodyField"
                      style="min-height: 250px"
                      placeholder="Email body"
                      v-model="emailBody"
                    ></textarea>
                  </div>
                </div>

                <div class="mb-2 row">
                  <div class="col-sm-12">
                    <div class="d-flex align-items-center">
                      <input
                        type="file"
                        @change="handleFileUpload"
                        multiple
                        accept=".pdf,.doc,.docx"
                        style="display: none"
                        ref="fileInput"
                      />
                      <div class="attached-files ml-3">
                        <span
                          v-for="(file, index) in attachedFiles"
                          :key="index"
                          class="mr-2 justify-content-between"
                        >
                          <span
                            style="
                              background-color: #f8f8f8;
                              font-weight: 500;
                              padding: 10px;
                              margin: 5px 5px;
                              display: flex;
                            "
                          >
                            <!-- Display PDF image if the file type is PDF -->
                            <div v-if="file.type === 'pdf'">
                              <img
                                src="../assets/icons/pdfImage.png"
                                alt=""
                                style="width: 50px; height: 50px"
                              />
                            </div>
                            <!-- Display file name and size -->
                            <div
                              style="
                                display: flex;
                                flex-direction: column;
                                margin-left: 10px;
                              "
                            >
                              <span>{{ file.name }}</span>
                              <span style="font-size: 10px">{{
                                formatFileSize(file.size)
                              }}</span>
                            </div>
                            <!-- Remove file button -->
                            <div>
                              <span
                                @click="removeFile(index)"
                                class="removeFile ms-5"
                                style="cursor: pointer"
                                >&#10005;</span
                              >
                            </div>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mb-1" v-if="this.signatureshow">
                  <div class="signature-card d-flex flex-column">
                    <h6>Regards,</h6>
                    <span>{{ $LocalUser.user.fullname }}</span>
                    <!-- Display fullname -->
                    <span>{{ $LocalUser.user.email }}</span>
                    <!-- Display email -->
                    <span>{{ $LocalUser.user.mobileNumber }}</span>
                    <!-- Display employee ID -->
                  </div>
                </div>
                <div
                  class="d-flex position-fixed justify-content-between mb-1"
                  style="
                    background-color: #ffffff;
                    bottom: 10px;
                    padding: 4px 8px;
                    cursor: pointer;
                    width: 55%;
                  "
                >
                  <div
                    style="background-color: #f2f2f2; cursor: pointer; height: 30px"
                    class="bottomLineImage"
                  >
                    <img
                      src="../assets/icons/attachment.png"
                      alt=""
                      @click="$refs.fileInput.click()"
                    />

                    <img
                      src="../assets/icons/inboxSignature.png"
                      alt=""
                      @click="signaturePrasent()"
                    />
                  </div>
                  <div style="cursor: pointer">
                    <img
                      src="../assets/icons/mailSendbutton.png"
                      alt=""
                      style="width: 80px; height: 30px"
                      @click="sendEmail()"
                    />
                  </div>
                </div>
              </form>
            </div>

            <div v-if="selectedData !== 'compose' && selectedData !== null">
              <!-- Display email details -->
              <div class="email-details-section">
                <div class="modal-body">
                  <div class="icon-container modal-title d-flex justify-content-end">
                    <img
                      v-if="emailDetails.archive === 0"
                      src="../assets/icons/import.png"
                      alt=""
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Archive"
                      @click="SingleArchive(emailDetails.id)"
                    />
                    <img
                      v-if="emailDetails.archive === 1"
                      src="../assets/icons/export.png"
                      alt=""
                      data-toggle="tooltip"
                      data-placement="top"
                      title="UnArchive"
                      @click="SingleArchive(emailDetails.id, emailDetails.archive)"
                    />

                    <img
                      src="../assets/icons/cancel.png"
                      alt=""
                      @click="goBack()"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="close"
                    />
                  </div>
                  <hr />
                  <div class="d-flex align-items-start justify-content-between mb-4">
                    <div class="d-flex align-items-center">
                      <div>
                        <img
                          v-if="emailDetails['employees.signedUrl'] !== null"
                          :src="emailDetails['employees.signedUrl']"
                          style="
                            width: 45px;
                            height: 45px;
                            border-radius: 50%;
                            display: inline-block;
                            object-fit: cover;
                            object-position: center;
                            image-rendering: auto;
                          "
                          alt=""
                        />
                        <img
                          v-else
                          src="../assets/icons/starIcon.png"
                          alt=""
                          style="width: 45px; height: 45px"
                        />
                      </div>
                      <div class="d-flex flex-column align-items-start ms-2">
                        <p class="m-0 p-0 d-flex aline-items-center">
                          <strong>{{ emailDetails["employees.firstName"] }}</strong>
                          &lt;<span style="font-size: 12px" class="p-1">
                            {{ emailDetails["employees.employeeEmailId"] }} </span
                          >&gt;
                        </p>
                        <p class="m-0 p-0" style="font-size: 12px; max-width: 350px">
                          <strong>to:</strong> {{ emailDetails.email }} <br />
                          <strong v-if="cc">cc:</strong> {{ cc }}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div>
                        <p style="font-size: 10px">
                          {{ formatDate(emailDetails.created_at) }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex" style="margin-left: 22px">
                    <h5 class="modal-title ms-4">
                      {{ emailDetails["emailContents.subject"] }}
                    </h5>
                  </div>
                  <div
                    style="font-size: 12px; padding-left: 22px"
                    class="ms-4 mt-3"
                    v-html="emailDetails['emailContents.body']"
                  />

                  <!-- Loop through attachments -->
                  <div
                    style="padding-left: 22px"
                    v-if="emailDetails.attachedFiles && emailDetails.attachedFiles.length"
                    class="mt-4 ms-4 w-50"
                  >
                    <h6>Attachments:</h6>
                    <div
                      v-for="attachment in emailDetails.attachedFiles"
                      :key="attachment.id"
                      class="mr-2 justify-content-between"
                    >
                      <span
                        style="
                          background-color: #f8f8f8;
                          font-weight: 500;
                          padding: 10px;
                          margin: 5px 5px;
                          display: flex;

                          border-radius: 5px;
                          border: 1px solid #2381f3;
                        "
                      >
                        <!-- Display PDF image if the file type is PDF -->
                        <div v-if="attachment.contentType === 'application/pdf' || 'pdf'">
                          <img
                            src="../assets/icons/pdfImage.png"
                            alt=""
                            style="width: 50px; height: 50px"
                          />
                        </div>
                        <a
                          @click="
                            $viewFile(
                              attachment.awsFileName,
                              attachment.fileName,
                              'otherEmails',
                              'company'
                            )
                          "
                          target="_blank"
                          style="
                            color: inherit; /* Inherit the color from the parent */
                            text-decoration: none;
                          "
                        >
                          <!-- Display file name and size -->
                          <div
                            style="
                              display: flex;
                              flex-direction: column;
                              margin-left: 10px;
                            "
                          >
                            <span>{{ attachment.fileName }}</span>
                            <span style="font-size: 10px"
                              >{{ fileSizeInKB(attachment.fileSize) }}Mb</span
                            >
                          </div>
                        </a>
                        <!-- Download button aligned to the right -->
                        <div style="margin-left: auto" class="d-flex flex-column">
                          <img
                            @click="
                              $downloadFile(
                                attachment.awsFileName,
                                attachment.fileName,
                                'otherEmails',
                                'company'
                              )
                            "
                            src="../assets/icons/import.png"
                            alt=""
                            style="width: 15px; height: 15px; cursor: pointer"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Downold"
                          />
                        </div>
                      </span>
                    </div>
                  </div>
                </div>

                <div class="mb-1 mt-4 ms-2" style="padding-left: 22px">
                  <div class="signature-card d-flex flex-column ms-3">
                    <h6>Regards,</h6>
                    <span class="text-muted"
                      >{{ emailDetails["employees.firstName"] }}
                      {{ emailDetails["employees.lastName"] }}</span
                    >
                    <span class="text-muted">{{
                      emailDetails["employees.employeeEmailId"]
                    }}</span>
                    <span class="text-muted"
                      >{{ emailDetails["employees.mobileNumber"] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section p-3 mailrightmenu MOBILE" v-if="!Mobileview">
          <div class="details" style="height: 100%">
            <div
              v-if="selectedData === null"
              class="d-flex justify-content-center align-items-center"
              style="margin-top: 30%"
            >
              <img src="../assets/icons/DefaultInboxIcon.png" alt="" />
            </div>

            <div
              v-if="selectedData === 'compose'"
              style="height: 100%; width: 100%; overflow-y: auto"
            >
              <form>
                <div
                  class="container d-flex justify-content-between p-1"
                  style="background-color: #b1c5df; border-radius: 5px"
                >
                  <h5 class="modal-title" style="font-size: 16px" id="emailModalLabel">
                    New Mail
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    @click="toggleCompose"
                    aria-label="Close"
                  ></button>
                </div>

                <div class="mb-3 row mt-2">
                  <label for="toField" class="col-sm-2 col-form-label">To:</label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      class="form-control inputBg"
                      id="toField"
                      v-model="emailTo"
                      placeholder="Recipient's email address"
                      @blur="validateEmail"
                    />
                    <small v-if="emailError" class="text-danger">{{ emailError }}</small>
                  </div>
                </div>
                <div class="mb-3 row">
                  <label for="ccField" class="col-sm-2 col-form-label">CC:</label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      class="form-control inputBg"
                      id="ccField"
                      placeholder="CC email addresses"
                      v-model="emailCC"
                    />
                  </div>
                </div>
                <div class="mb-3 row">
                  <label for="subjectField" class="col-sm-2 col-form-label"
                    >Subject:
                  </label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      class="form-control inputBg"
                      id="subjectField"
                      placeholder="Email subject"
                      v-model="emailSubject"
                    />
                  </div>
                </div>

                <div class="mb-3 row">
                  <label for="bodyField" class="col-sm-2 col-form-label">Body:</label>
                  <div class="col-sm-10">
                    <textarea
                      class="form-control inputBg"
                      id="bodyField"
                      style="min-height: 250px"
                      placeholder="Email body"
                      v-model="emailBody"
                    ></textarea>
                  </div>
                </div>

                <div class="mb-2 row">
                  <div class="col-sm-12">
                    <div class="d-flex align-items-center">
                      <input
                        type="file"
                        @change="handleFileUpload"
                        multiple
                        accept=".pdf,.doc,.docx"
                        style="display: none"
                        ref="fileInput"
                      />
                      <div class="attached-files ml-3">
                        <span
                          v-for="(file, index) in attachedFiles"
                          :key="index"
                          class="mr-2 justify-content-between"
                        >
                          <span
                            style="
                              background-color: #f8f8f8;
                              font-weight: 500;
                              padding: 10px;
                              margin: 5px 5px;
                              display: flex;
                            "
                          >
                            <!-- Display PDF image if the file type is PDF -->
                            <div v-if="file.type === 'pdf'">
                              <img
                                src="../assets/icons/pdfImage.png"
                                alt=""
                                style="width: 50px; height: 50px"
                              />
                            </div>
                            <!-- Display file name and size -->
                            <div
                              style="
                                display: flex;
                                flex-direction: column;
                                margin-left: 10px;
                              "
                            >
                              <span>{{ file.name }}</span>
                              <span style="font-size: 10px">{{
                                formatFileSize(file.size)
                              }}</span>
                            </div>
                            <!-- Remove file button -->
                            <div>
                              <span
                                @click="removeFile(index)"
                                class="removeFile ms-5"
                                style="cursor: pointer"
                                >&#10005;</span
                              >
                            </div>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mb-1" v-if="this.signatureshow">
                  <div class="signature-card d-flex flex-column">
                    <h6>Regards,</h6>
                    <span>{{ $LocalUser.user.fullname }}</span>
                    <!-- Display fullname -->
                    <span>{{ $LocalUser.user.email }}</span>
                    <!-- Display email -->
                    <span>{{ $LocalUser.user.mobileNumber }}</span>
                    <!-- Display employee ID -->
                  </div>
                </div>
                <div
                  class="d-flex position-fixed justify-content-between mb-1"
                  style="
                    background-color: #ffffff;
                    bottom: 10px;
                    padding: 4px 8px;
                    cursor: pointer;
                    width: 85%;
                  "
                >
                  <div
                    style="background-color: #f2f2f2; cursor: pointer; height: 30px"
                    class="bottomLineImage"
                  >
                    <img
                      src="../assets/icons/attachment.png"
                      alt=""
                      @click="$refs.fileInput.click()"
                    />

                    <img
                      src="../assets/icons/inboxSignature.png"
                      alt=""
                      @click="signaturePrasent()"
                    />
                  </div>
                  <div style="cursor: pointer" class="d-flex justify-content-end">
                    <img
                      src="../assets/icons/mailSendbutton.png"
                      alt=""
                      style="width: 80px; height: 30px"
                      @click="sendEmail()"
                    />
                  </div>
                </div>
              </form>
            </div>

            <div v-if="selectedData !== 'compose' && selectedData !== null">
              <!-- Display email details -->
              <div class="email-details-section">
                <div class="modal-body">
                  <div class="icon-container modal-title d-flex justify-content-end">
                    <img
                      src="../assets/icons/cancel.png"
                      alt=""
                      @click="goBack()"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="close"
                    />
                  </div>
                  <hr />
                  <div class="d-flex align-items-start justify-content-between mb-4">
                    <div class="d-flex align-items-center">
                      <div>
                        <img
                          v-if="emailDetails['employees.signedUrl'] !== null"
                          :src="emailDetails['employees.signedUrl']"
                          style="
                            width: 35px;
                            height: 35px;
                            border-radius: 50%;
                            display: inline-block;
                            object-fit: cover;
                            object-position: center;
                            image-rendering: auto;
                          "
                          alt=""
                        />
                        <img
                          v-else
                          src="../assets/icons/starIcon.png"
                          alt=""
                          style="width: 45px; height: 45px"
                        />
                      </div>
                      <div class="d-flex flex-column align-items-start ms-2">
                        <p class="m-0 p-0 d-flex aline-items-center">
                          <strong>{{ emailDetails["employees.firstName"] }}</strong>
                          &lt;<span style="font-size: 12px" class="p-1">
                            {{ emailDetails["employees.employeeEmailId"] }} </span
                          >&gt;
                        </p>
                        <p class="m-0 p-0" style="font-size: 12px; max-width: 350px">
                          <strong>to:</strong> {{ emailDetails.email }}<br />
                          <strong v-if="cc">cc:</strong> {{ cc }}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div>
                        <p style="font-size: 10px">
                          {{ formatDate(emailDetails.created_at) }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex" style="margin-left: 22px">
                    <h5 class="modal-title ms-4" style="font-size: 14px">
                      {{ emailDetails["emailContents.subject"] }}
                    </h5>
                  </div>
                  <div
                    style="font-size: 12px; padding-left: 22px"
                    class="ms-4 mt-3"
                    v-html="emailDetails['emailContents.body']"
                  />

                  <!-- Loop through attachments -->
                  <div
                    style="padding-left: 22px"
                    v-if="emailDetails.attachedFiles && emailDetails.attachedFiles.length"
                    class="mt-4 ms-4 w-50"
                  >
                    <h6>Attachments:</h6>
                    <div
                      v-for="attachment in emailDetails.attachedFiles"
                      :key="attachment.id"
                      class="mr-2 justify-content-between"
                    >
                      <span
                        style="
                          background-color: #f8f8f8;
                          font-weight: 500;
                          padding: 10px;
                          margin: 5px 5px;
                          display: flex;

                          border-radius: 5px;
                          border: 1px solid #2381f3;
                        "
                      >
                        <!-- Display PDF image if the file type is PDF -->
                        <div v-if="attachment.contentType === 'application/pdf' || 'pdf'">
                          <img
                            src="../assets/icons/pdfImage.png"
                            alt=""
                            style="width: 50px; height: 50px"
                          />
                        </div>
                        <a
                          @click="
                            $viewFile(
                              attachment.awsFileName,
                              attachment.fileName,
                              'otherEmails',
                              'company'
                            )
                          "
                          target="_blank"
                          style="
                            color: inherit; /* Inherit the color from the parent */
                            text-decoration: none;
                          "
                        >
                          <!-- Display file name and size -->
                          <div
                            style="
                              display: flex;
                              flex-direction: column;
                              margin-left: 10px;
                            "
                          >
                            <span>{{ attachment.fileName }}</span>
                            <span style="font-size: 10px"
                              >{{ fileSizeInKB(attachment.fileSize) }}Mb</span
                            >
                          </div>
                        </a>
                        <!-- Download button aligned to the right -->
                        <div style="margin-left: auto" class="d-flex flex-column">
                          <img
                            @click="
                              $downloadFile(
                                attachment.awsFileName,
                                attachment.fileName,
                                'otherEmails',
                                'company'
                              )
                            "
                            src="../assets/icons/import.png"
                            alt=""
                            style="width: 15px; height: 15px; cursor: pointer"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Downold"
                          />
                        </div>
                      </span>
                    </div>
                  </div>
                </div>

                <div class="mb-1 mt-4 ms-2" style="padding-left: 22px">
                  <div class="signature-card d-flex flex-column ms-3">
                    <h6>Regards,</h6>
                    <span class="text-muted"
                      >{{ emailDetails["employees.firstName"] }}
                      {{ emailDetails["employees.lastName"] }}</span
                    >
                    <span class="text-muted">{{
                      emailDetails["employees.employeeEmailId"]
                    }}</span>
                    <span class="text-muted"
                      >{{ emailDetails["employees.mobileNumber"] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Header from "./Header.vue";
import Nav from "./Nav.vue";
export default {
  name: "UserInbox",
  components: {
    Header,
    Nav,
  },
  data() {
    return {
      Mobileview: true,
      searchString: "",
      fileData: null,
      signatureshow: false,
      selectedFolder: "inbox",
      selectedData: null,
      emailTo: "",
      emailError: "",
      emailCC: [],
      emailSubject: "",
      emailBody: "",
      attachedFiles: [],
      attachmentIds: [],
      emailDetails: {},
      ccMails: [],
      emails: [],
    };
  },
  computed: {
    cc() {
      // Join the ccMails array elements with a comma and space
      return this.ccMails.join(", ");
    },
  },
  mounted() {
    this.selectedFolder = "inbox";

    this.fetchEmails();
  },
  methods: {
    changetoMobile() {
      this.Mobileview = !this.Mobileview; // Toggle the value of Mobileview
    },
    validateEmail() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(this.emailTo)) {
        this.emailError = "Please enter a valid email address.";
        this.emailTo = ""; // Clear the invalid input
      } else if (this.emailTo.includes(",")) {
        this.emailError = "Only one email address is allowed.";
        this.emailTo = ""; // Clear the invalid input
      } else {
        this.emailError = ""; // Clear the error if valid
      }
    },

    searchEmails() {
      let searchData = {
        searchString: this.searchString,
      };

      let apiEndpoint = "";
      switch (this.selectedFolder) {
        case "inbox":
          apiEndpoint = `${this.$apiEndPoint}/inbox/searchMyMails/unarchived`;
          break;
        case "sent":
          apiEndpoint = `${this.$apiEndPoint}/inbox/searchsentMails`;
          break;
        case "archived":
          apiEndpoint = `${this.$apiEndPoint}/inbox/searchMyMails/archived`;
          break;
        case "favorite":
          apiEndpoint = `${this.$apiEndPoint}/inbox/searchMyMails/unarchived/favourite`;
          break;
      }
      axios
        .post(apiEndpoint, searchData)
        .then((response) => {
          this.emails = response.data;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    showFolder(folder) {
      this.selectedData = null;
      this.selectedFolder = folder;
      this.fetchEmails();
    },
    getFolderLabel(folder) {
      const folderLabels = {
        inbox: "Inbox",
        sent: "Sent",
        archived: "Archived",
        favorite: "Favorite",
      };
      return folderLabels[folder] || "Select Folder";
    },
    changeFolder(folder) {
      this.Mobileview = true;
      this.selectedFolder = folder;
      this.showFolder(folder);
    },
    fetchEmails() {
      let apiEndpoint = "";
      switch (this.selectedFolder) {
        case "inbox":
          apiEndpoint = `${this.$apiEndPoint}/inbox/getMyMails`;
          break;
        case "sent":
          apiEndpoint = `${this.$apiEndPoint}/inbox/sentMails`;
          break;
        case "archived":
          apiEndpoint = `${this.$apiEndPoint}/inbox/getMyMails/archived`;
          break;
        case "favorite":
          apiEndpoint = `${this.$apiEndPoint}/inbox/getMyFavourite`;
          break;
      }
      axios
        .get(apiEndpoint)
        .then((response) => {
          this.emails = response.data;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    goBack() {
      this.selectedData = null;
      this.changetoMobile();
    },
    async favourite(emailId, index) {
      // Toggle the favorite status
      this.emails[index].favourite = this.emails[index].favourite === 1 ? 0 : 1;

      try {
        // Make the API call to update the favorite status
        await axios.put(`${this.$apiEndPoint}/inbox/favouriteEmails`, {
          id: [emailId],
          favourite: this.emails[index].favourite, // Send the updated favorite status to the API
        });

        this.fetchEmails();

        // Handle the response data as needed
      } catch (error) {
        this.$handleError(error);
        // Handle the error appropriately
      }
    },

    fileSizeInKB(fileSize) {
      return (fileSize / 1024000).toFixed(2);
    },
    fetchEmailDetails(emailId) {
      axios
        .get(`${this.$apiEndPoint}/inbox/${emailId}`)
        .then((response) => {
          this.emailDetails = response.data;
          this.ccMails = response.data.ccMails;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    showData(data) {
      this.changetoMobile();
      if (typeof data === "string") {
        // Handle string input (compose)
        this.selectedData = data;
      } else {
        // Handle email ID input
        this.selectedData = data.id;
        this.Reademail(data.id);
        this.fetchEmailDetails(data.emailContentId);
      }
    },
    async Reademail(emailId) {
      try {
        await axios.put(`${this.$apiEndPoint}/inbox/readUnread`, {
          id: [emailId],
        });

        this.fetchEmails();
      } catch (error) {
        this.$handleError(error);
      }
    },
    formatDate(dateString) {
      // Format date string to only display month and date
      const date = new Date(dateString);
      const month = date.toLocaleString("default", { month: "short" });
      const day = date.getDate();
      return `${month} ${day}`;
    },
    signaturePrasent() {
      this.signatureshow = !this.signatureshow;
    },
    redirectToGoogleDrive() {
      window.open("https://drive.google.com/", "_blank");
    },

    removeFile(index) {
      this.attachedFiles.splice(index, 1);
    },
    formatFileSize(size) {
      if (size === 0) return "0 Bytes";
      const k = 1024;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      const i = Math.floor(Math.log(size) / Math.log(k));
      return parseFloat((size / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
    },
    handleFileUpload(event) {
      const files = event.target.files;
      const maxFileSize = 15 * 1024 * 1024; // 15 MB

      // Calculate total size of existing files
      let totalSize = this.attachedFiles.reduce((sum, file) => sum + file.size, 0);

      for (const file of files) {
        // Check if adding this file would exceed the total size limit
        if (totalSize + file.size > maxFileSize) {
          alert("Total attachment size should not exceed 15 MB.");
          continue;
        }
        this.$globalState.isLoading = true;

        // Update total size
        totalSize += file.size;

        // Prepare payload for obtaining signed URL
        const payload = {
          fileName: file.name,
          fileSize: file.size,
          contentType: file.type,
          moduleName: "company",
          fileCategory: "otherEmails",
          entityName: "company",
        };

        axios
          .post(`${this.$apiEndPoint}/company/putFileSignedURL`, payload)
          .then((response) => {
            const signedUrl = response.data.s3URL;

            const fileId = response.data.id; // Get the ID from the response
            const fileName = response.data.fileName; // Get the filename from the response

            // Use XMLHttpRequest to upload file to signed URL
            const xhr = new XMLHttpRequest();
            xhr.open("PUT", signedUrl, true);
            xhr.setRequestHeader("Content-Type", file.type);

            xhr.onreadystatechange = () => {
              if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                  this.$globalState.isLoading = false;
                  // Push the file object with ID and filename into the attachedFiles array
                  this.attachedFiles.push({
                    name: fileName,
                    size: file.size,
                    id: fileId,
                    type: file.type.split("/")[1], // Assuming file.type is in the format "image/png", "application/pdf", etc. We split it to get the second part.
                  });
                }
              }
            };
            xhr.send(file);
          })
          .catch(() => {
            this.$globalState.isLoading = false;
            this.$toast("Error obtaining signed URL for file upload", "error");
          });
      }
    },
    async sendEmail() {
      try {
        // Ensure emailCC is a string and then split it by commas
        const ccEmails =
          typeof this.emailCC === "string"
            ? this.emailCC.split(",").map((email) => email.trim())
            : [];
        const toEmails = Array.isArray(this.emailTo) ? this.emailTo : [this.emailTo];
        const requestBody = {
          to: toEmails,
          cc: ccEmails,
          subject: this.emailSubject,
          body: this.emailBody,
          attachmentIds: this.attachedFiles.map((file) => file.id),
        };

        this.fetchEmails();
        // Make a POST request to send the email
        await axios.post(`${this.$apiEndPoint}/inbox/`, requestBody);
        this.$toast(`Mail Sent successfully.`, "success");
        // Clear the form fields after sending the email
        this.emailTo = "";
        this.emailCC = [];
        this.emailSubject = "";
        this.emailBody = "";
        this.attachedFiles = [];

        // Close the compose section after sending the email
        this.selectedData = null;
      } catch (error) {
        this.$handleError(error);

        // Handle errors
      }
    },

    resetCompose() {
      this.emailTo = "";
      this.emailCC = [];
      this.emailSubject = "";
      this.emailBody = "";
      this.attachedFiles = [];
    },
    toggleCompose() {
      this.selectedData = null;
      this.Mobileview = !this.Mobileview;
    },

    async SingleArchive(emailId, emailDetailsArchive) {
      emailDetailsArchive = emailDetailsArchive === 1 ? 0 : 1;
      try {
        await axios.put(`${this.$apiEndPoint}/inbox/archiveEmails`, {
          id: [emailId],
        });
        this.emailDetails.archive = emailDetailsArchive;
        this.fetchEmails();
      } catch (error) {
        this.$handleError(error);
      }
    },
    async archive(emailId, index) {
      this.emails.splice(index, 1);
      try {
        await axios.put(`${this.$apiEndPoint}/inbox/archiveEmails`, {
          id: [emailId],
        });
        this.fetchEmails();
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
};
</script>

<style scoped>
.selected-folder {
  background-color: #edeae9;
  border-radius: 10px;
  font-weight: 600;
}

.icon-container img {
  width: 20px;
  height: 20px;
  margin: 0 5px;
  cursor: pointer;
}

.Inboxdata-list {
  height: 88%;
  border-radius: 0 0 20px 20px;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Define the scrollbar track */
.Inboxdata-list::-webkit-scrollbar {
  width: 5px;
  /* Set the width of the scrollbar */
}

/* Define the scrollbar thumb */
.Inboxdata-list::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Set the color of the scrollbar thumb */
  border-radius: 4px;
  /* Set the border radius of the thumb */
  width: 2px;
}

/* Define the scrollbar track when mouse hovers over it */
.Inboxdata-list::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* Change color on hover */
}

.bottomLineImage {
  background-color: #f2f2f2;
  padding: 5px;
  border-radius: 5px;
}

.bottomLineImage img {
  margin: 0 5px;
  width: 20px;
}

.inputBg {
  border: none;
  border-bottom: 2px solid #e9ecf0;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
  /* Remove default outline */
  box-shadow: none;
  /* Add a custom focus shadow */
}

label {
  font-weight: 500;
  color: black;
  font-size: 14px;
}

.folder-list li {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  cursor: pointer;
}

.folder-list li:not(:last-child):hover {
  background-color: #edeae9;
  border-radius: 5px;
}

.folder-list li img {
  margin: 8px 20px;
  width: 25px;
  /* Adjust size as needed */
  height: auto;
  background-color: none;
}

.email-details-section {
  margin-left: auto;
  margin-right: auto;
  padding: 5px;
}

.attachment-card {
  margin-top: 10px;
}

.Inboxdata-list div section:hover {
  background-color: #000;
  /* Change this color to your desired hover background color */
  cursor: pointer;
}

.MailPage {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
}
.mailleftmenu {
  width: 15%;
}
.mailcentermenu {
  width: 25%;
  height: 100%;
  border-radius: 20px;
  background-color: #f5f5f5;
  border: 2px solid #e9ecf0;
}
.mailrightmenu {
  width: 60%;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .MailPage {
    flex-direction: column;
  }

  .mailcentermenu {
    width: 100%;
    padding: 0;
    height: 93.5%;
  }

  .Inboxdata-list {
    height: 90%;
    padding: 5px 0px;
    border-radius: 0 0 20px 20px;
  }
  /* General styling for the mobile section */

  /* Styling for the folder dropdown */
  .folder-select {
    padding: 5px 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #fff;
    min-width: 120px;
  }

  /* Styling for the compose button */
  .compose-btn {
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: absolute;
    right: 2%;
  }

  .compose-icon {
    width: 80px; /* Adjust width as per your design */
    height: auto;
  }
  .mailrightmenu {
    width: 100%;
  }

  .custom-dropdown-toggle {
    background-color: #f8f9fa; /* Light grey background */
    border: 1px solid #ced4da; /* Subtle border */
    color: #495057; /* Dark text color */
    border-radius: 5px; /* Slightly rounded corners */
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 12px; /* Add padding for better spacing */
    min-width: 150px; /* Minimum width for consistent size */
  }

  /* Styling for the folder label */
  .folder-label {
    flex-grow: 1; /* Push the label to take maximum space */
    font-size: 14px;
    font-weight: 500;
    color: #495057;
  }

  /* Styling for the dropdown icon */
  .dropdown-icon {
    margin-left: 8px; /* Add space between the label and the icon */
    font-size: 12px;
    color: #6c757d; /* Slightly lighter color for the icon */
  }

  /* Hover and focus effects for the button */
  .custom-dropdown-toggle:hover,
  .custom-dropdown-toggle:focus {
    background-color: #e9ecef; /* Light hover effect */
    color: #343a40;
  }

  /* Dropdown menu styling */
  .dropdown-menu {
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15); /* Subtle shadow */
  }
}
</style>
