import router from '../routers';
import axios from 'axios';
import UserObject from './UserObject';

export function setAuthHeader(token) {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
}

function getBrowserName() {
  const userAgent = navigator.userAgent;
  if (userAgent.includes("Chrome")) return "Chrome";
  if (userAgent.includes("Firefox")) return "Firefox";
  if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) return "Safari";
  if (userAgent.includes("Edge")) return "Edge";
  if (userAgent.includes("MSIE") || userAgent.includes("Trident/")) return "Internet Explorer";
  return "Unknown";
}

export async function logout() {
  try {
    const browserName = getBrowserName();

    const payload = {
      userId: UserObject.user.id,
      visitorId: UserObject.user?.visitorId || "Unknown",
      browserName,
    };

    console.log("Logout Request Payload:", payload);

    const response = await axios.post(`${this.$apiEndPoint}/users/removeLoggedInDevice`, payload);

    console.log("Logout response:", response.data);

    localStorage.clear();
    router.push({ name: "Login" });
  } catch (error) {
    console.error("Logout failed:", error.response?.data || error);
    
    // const status = error.response?.status;
    // const message = error.response?.data?.message;

    localStorage.clear();
    router.push({ name: "Login" });
    // if (message === "Device not found") {
    //   localStorage.clear();
    //   router.push({ name: "Login" });
    //   return;
    // }

    // if (status === 401 && message === "User is not Authorized!") {
    //   console.warn("Session expired. Logging out again.");
    //   localStorage.clear();
    //   router.push({ name: "Login" });
    //   return;
    // }
  }
}


export function isExecutive(team) {
  const roles = UserObject?.employeeRoles || []; // Default to empty array

  if (!Array.isArray(roles)) {
    console.warn("employeeRoles is not an array:", roles);
    return false;
  }

  return roles.some((role) => role.team === team && role.role === 'Executive');
}

export function isManager(team) {
  const roles = UserObject?.employeeRoles || [];

  if (!Array.isArray(roles)) {
    console.warn("employeeRoles is not an array:", roles);
    return false;
  }

  return roles.some((role) => role.team === team && role.role === 'Manager');
}

export function isTeam(team) {
  const roles = UserObject?.employeeRoles || [];

  if (!Array.isArray(roles)) {
    console.warn("employeeRoles is not an array:", roles);
    return false;
  }

  return roles.some((role) => role.team === team);
}



