<template>
  <div class="modal">
    <div class="stock-report">
      <table class="report-table">
        <caption class="d-none">
          Financial Year Stock Report
        </caption>
        <thead>
          <tr>
            <th>Particular</th>
            <th>Inward Qty</th>
            <th>Outward Qty</th>
            <th>Stock Consumption</th>
            <th>Closing Stock</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Opening Stock</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
          </tr>
          <tr v-for="(row, index) in stock" :key="index">
            <td>{{ row.month }}</td>
            <td>{{ row.inwardQuantity }}</td>
            <td>{{ row.outwardQuantity }}</td>
            <td>{{ row.stockConsumption }}</td>
            <td>{{ row.closingStock }}</td>
          </tr>
          <!-- Add more rows as needed -->
          <tr>
            <td>Grand Total</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import jsPDF from "jspdf";
import "jspdf-autotable";

export default {
  props: {
    stock: {
      type: Array,
      required: true,
    },
    storeName: {
      type: String,
      required: true,
    },
    productName: {
      type: String,
      required: true,
    },
    fromYear: {
      type: String,
      required: true,
    },
    toYear: {
      type: String,
      required: true,
    },
    fromMonth: {
      type: String,
      required: true,
    },
    toMonth: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      stockData: [],
    };
  },
  methods: {
    generatePDF() {
      const doc = new jsPDF();

      doc.setFontSize(18);
      doc.text(`Store Name: ${this.storeName}`, 14, 22);

      // Add Company Name and Financial Year
      doc.setFontSize(12);
      doc.text(`Product: ${this.productName}`, 14, 32);
      doc.text(
        `Financial Year: ${this.fromMonth}-${this.fromYear} to ${this.toMonth}-${this.toYear}`,
        14,
        40
      );

      // Add Table
      const tableData = this.stockData.map((item) => [
        item.particular,
        item.inwardQty,
        item.outwardQty,
        item.stockConsumption,
        item.closingStock,
      ]);
      doc.autoTable({
        head: [
          [
            "Particular",
            "Inward Qty",
            "Outward Qty",
            "Stock Consumption",
            "Closing Stock",
          ],
        ],
        body: tableData,
        startY: 50,
      });
      doc.save("Financial-Year-Stock-Report.pdf");
    },
  },
};
</script>

<style scoped>
.stock-report {
  padding: 20px;
  overflow-x: auto; /* For horizontal scrolling on smaller screens */
}

.report-table {
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

.report-table th {
  background-color: #ddd;
  padding: 10px;
  text-align: left;
  border: 1px solid #ccc;
}

.report-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ccc;
}

.report-table tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}

.report-table tbody tr:nth-child(even) {
  background-color: #fff;
}

.report-table tbody tr:last-child {
  background-color: #e0e0e0;
  font-weight: bold;
}
</style>
