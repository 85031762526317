<template>
  <div class="container mt-4">
    <!-- <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#ticketModal">Open Ticket</button> -->

    <!-- Modal -->
    <div
      class="modal fade"
      id="ticketModal"
      tabindex="-1"
      aria-labelledby="ticketModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title">
              Ticket ID - <small>TC-0009 Unable to create an Estimate</small>
            </h6>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="button-row">
              <div>
                <button
                  class="tab-button"
                  :class="{ active: activeTab === 'General Info' }"
                  @click="setActive('General Info')"
                >
                  General Info
                </button>
              </div>
              <div>
                <button
                  class="tab-button"
                  :class="{ active: activeTab === 'Conversation' }"
                  @click="setActive('Conversation')"
                >
                  Conversation
                </button>
              </div>
              <div>
                <button
                  class="tab-button"
                  :class="{ active: activeTab === 'Activity' }"
                  @click="setActive('Activity')"
                >
                  Activity
                </button>
              </div>
            </div>

            <div class="row">
              <!-- Customer Information -->
              <div class="col-md-6">
                <div class="customer-info">
                  <h6>Customer Information</h6>
                  <hr style="border-bottom: 1px black; margin: 5px 0px 15px 0px" />
                  <div class="customer-details">
                    <div class="row">
                      <label for="companyName">Company Name:</label>
                      <input
                        type="text"
                        id="companyName"
                        value="Aantarika Design Studio"
                        readonly
                      />
                    </div>

                    <div class="row">
                      <label for="companyId">Company ID:</label>
                      <input type="text" id="companyId" value="CAM-021-0001" readonly />
                    </div>

                    <div class="row">
                      <label for="employeeName">Employee Name:</label>
                      <input type="text" id="employeeName" value="Mamatha B" readonly />
                    </div>

                    <div class="row">
                      <label for="employeeId">Employee ID:</label>
                      <input type="text" id="employeeId" value="EMP-0021-0001" readonly />
                    </div>

                    <div class="row">
                      <label for="contactNo">Contact No:</label>
                      <input type="text" id="contactNo" value="8965867584" readonly />
                    </div>

                    <div class="row">
                      <label for="email">Email ID:</label>
                      <input
                        type="email"
                        id="email"
                        value="Mamatha@aantarika.com"
                        readonly
                      />
                    </div>

                    <div class="row">
                      <label for="moduleName">Module Name:</label>
                      <input type="text" id="moduleName" value="Procurement" readonly />
                    </div>

                    <div class="row">
                      <label for="ticketDate">Ticket Created at:</label>
                      <input type="text" id="ticketDate" value="12/02/2025" readonly />
                    </div>

                    <div class="row">
                      <label for="severity">Severity:</label>
                      <input
                        type="text"
                        id="severity"
                        value="Critical"
                        class="severity-badge"
                        readonly
                      />
                    </div>

                    <div class="row">
                      <label for="assignee">Assignee:</label>
                      <input type="text" id="assignee" value="Unassigned" readonly />
                    </div>
                  </div>
                </div>
              </div>

              <!-- Issue Details -->
              <div class="col-md-6">
                <div class="issue-details">
                  <h6>Issue Details</h6>
                  <p><strong>Issue Title:</strong></p>
                  <input style="font-size: small" class="form-control" rows="3" />

                  <p><strong>Description:</strong></p>
                  <textarea
                    style="font-size: small"
                    class="form-control"
                    rows="3"
                  ></textarea>
                </div>

                <div class="attachments">
                  <h6>Attachments</h6>
                  <div class="attachment-section">
                    <div class="pdf-file">
                      <img src="../../assets/icons/pdfIcon.png" alt="PDF" width="40" />
                      <p>SO#00038</p>
                      <p>Size: 850kb</p>
                    </div>
                    <div class="pdf-file">
                      <img src="../../assets/icons/pdfIcon.png" alt="PDF" width="40" />
                      <p>SO#00038</p>
                      <p>Size: 850kb</p>
                    </div>
                    <div class="pdf-file">
                      <img src="../../assets/icons/pdfIcon.png" alt="PDF" width="40" />
                      <p>SO#00038</p>
                      <p>Size: 850kb</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Attachments -->
          </div>

          <div class="modal-footer">
            <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
            <button type="button" class="btn btn-primary">Save Changes</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeTab: "General Info", // Default active tab
    };
  },
  methods: {
    setActive(tab) {
      this.activeTab = tab;
    },
  },
};
</script>

<style scoped>
.modal-content {
  margin-top: 70px;
  font-size: small;
  background-color: #f9f9f9;
}
.modal-body {
  max-height: 450px;
  overflow-y: auto;

  /* Thin scrollbar for WebKit browsers (Chrome, Edge, Safari) */
  scrollbar-width: none; /* Firefox */
  scrollbar-color: #888 #f0f0f0; /* Firefox */
}

/* WebKit browsers (Chrome, Edge, Safari) */
.modal-body::-webkit-scrollbar {
  width: 6px; /* Scrollbar thickness */
}

.modal-body::-webkit-scrollbar-track {
  background: #f0f0f0; /* Track background */
  border-radius: 10px; /* Rounded track */
}

.modal-body::-webkit-scrollbar-thumb {
  background-color: #888; /* Scrollbar color */
  border-radius: 10px; /* Rounded scrollbar */
  border: 2px solid #f0f0f0; /* Creates spacing between thumb and track */
}

.modal-body::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker thumb on hover */
}

.customer-details .row {
  display: flex;
  align-items: center; /* Vertical alignment */
  gap: 15px; /* Spacing between label and input */
  margin-bottom: 10px; /* Space between rows */
}

.customer-details label {
  width: 150px; /* Fixed width for labels */
  text-align: left; /* Align text to the right for cleaner look */
  opacity: 0.7;
}

.customer-details input {
  flex: 1; /* Input takes remaining space */
  font-size: smaller;
  padding: 6px 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  background-color: #fff;
}

.severity-badge {
  background-color: red;
  color: #fff;
  text-align: center;
  border: none;
  padding: 6px 10px;
  border-radius: 6px;
}

.customer-info,
.issue-details {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 15px;
}

.issue-details p {
  margin-bottom: 0px;
  padding: 5px;
}
.severity-badge {
  background-color: red;
  color: #fff;
  border-radius: 20px;
  padding: 5px 15px;
  display: inline-block;
}
.attachment-section {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  background-color: #fff;
  max-height: 250px;
  overflow-y: auto;
  padding: 10px;

  /* Scrollbar Styling */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #aaa #f1f1f1; /* Scrollbar color */
}

/* For WebKit Browsers (Chrome, Safari) */
.attachment-section::-webkit-scrollbar {
  width: 5px; /* Thin scrollbar width */
}

.attachment-section::-webkit-scrollbar-thumb {
  background-color: #aaa; /* Scrollbar thumb color */
  border-radius: 10px;
}

.attachment-section::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Scrollbar track color */
}

.attachments .pdf-file {
  display: flex;
  align-items: center; /* Aligns content vertically */
  gap: 8px; /* Adds spacing between image and text */
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  width: auto; /* Allows cards to adjust dynamically */
  min-width: 180px; /* Ensures minimum width for content */
}

.modal-footer {
  padding: 15px;
}

.button-row {
  display: flex;
  gap: 10px;
  padding-bottom: 10px;
}

.tab-button {
  background-color: transparent;
  border: none;
  color: #000;
  cursor: pointer;
  transition: background-color 0.3s, border 0.3s;
  border-radius: 20px;
}

.tab-button.active {
  background-color: #3b82f6; /* Active color */
  color: #fff;
  border: 2px solid #3b82f6;
}

.tab-button:hover {
  background-color: #e6f0ff; /* Soft hover effect */
  border-radius: 20px;
}
</style>
