<template>
  <div>
    <Header />
    <div class="content-Container">
      <Nav />
      <div class="containerChild">
        <h5 class="title">Subscription Management</h5>

        <div class="buttons-section">
          <button
            @click="buttonType('plans')"
            :class="{ active: activeButton === 'plans' }"
          >
            Plans
          </button>

          <button
            @click="buttonType('subscription')"
            :class="{ active: activeButton === 'subscription' }"
          >
            Current Subscription
          </button>

          <button
            @click="buttonType('Transaction')"
            :class="{ active: activeButton === 'Transaction' }"
          >
            Transaction History
          </button>

          <button
            @click="buttonType('invoices')"
            :class="{ active: activeButton === 'invoices' }"
          >
            Invoice
          </button>
        </div>

        <div v-if="this.selectedButtonType == 'plans'">
          <div>
            <h5 class="sub-title">Choose the Right Plan for Your Needs</h5>
          </div>
          <div class="pricing-container">
            <div
              v-for="(plan, index) in plans"
              :key="index"
              class="single-pricing-table"
              @mouseenter="hoveredPlan = index"
              @mouseleave="hoveredPlan = null"
              :class="{ hovered: hoveredPlan === index }"
            >
              <div style="padding: 20px 20px 0px 20px">
                <div class="d-flex justify-content-between">
                  <p class="pricing-header">{{ plan.name }}</p>
                  <p class="pricing_dec" v-if="plan.name == 'Quarterly'">Most Popular</p>
                  <p class="pricing_dec" v-if="plan.name == 'Yearly'">Best Value</p>
                </div>

                <div class="price text-start">
                  ₹ {{ plan.price }}/month <br />
                  <p v-if="plan.name == 'Monthly'">Billed Monthly</p>
                  <p v-if="plan.name == 'Quarterly'">
                    ₹ {{ plan.amount }} Billed Quarterly
                  </p>
                  <p v-if="plan.name == 'Yearly'">₹ {{ plan.amount }} Billed Yearly</p>
                </div>
              </div>
              <!-- Bottom section with blue background -->
              <div class="pricing-footer" @click="showAmountPopup(plan)">
                <p class="default-btn">Subscribe Now</p>
              </div>
            </div>
          </div>
        </div>

        <div v-if="selectedButtonType == 'subscription'" style="padding: 20px">
          <div class="table-container">
            <table class="w-full border-collapse border rounded-lg shadow-md">
              <thead class="bg-gray-100 text-gray-700">
                <tr>
                  <th class="text-left">Sl. No.</th>
                  <th class="text-left">Plan Name</th>
                  <th class="text-center">No. of Licenses</th>
                  <!-- <th class="text-left">Amount</th> -->
                  <th class="text-left">Purchase Date</th>
                  <th class="text-left">Renewal Date</th>
                  <th class="text-center">Payment Status</th>
                  <th class="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(plan, index) in ActiveSubscriptionData"
                  :key="index"
                  class="border-b"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ plan.planType }}</td>
                  <td class="text-center">{{ plan.noOfLicense }}</td>
                  <!-- <td>{{ plan.amount }}</td> -->
                  <td>{{ $formatDateDMY(plan.licenseFromDate) }}</td>
                  <td>{{ $formatDateDMY(plan.licenseToDate) }}</td>
                  <td>
                    <!-- <span
                      :class="{
                        'status-processing': plan.status === 'Processing',
                        'status-successful': status === 'Successful',
                        'status-failed': plan.status === 'Failed',
                      }"
                    >
                      Successful
                    </span> -->
                    <span class="status-successful"> Successful </span>
                  </td>

                  <td>
                    <button
                      class="px-4 py-2 text-white rounded"
                      :class="{
                        'bg-gray-300 cursor-not-allowed': plan.status !== 'Successful',
                        'bg-blue-500 hover:bg-blue-600': plan.status === 'Successful',
                      }"
                      :disabled="plan.status !== 'Successful'"
                    >
                      Extend
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div v-if="selectedButtonType == 'Transaction'" style="padding: 20px">
          <div class="table-container">
            <table class="w-full border-collapse border rounded-lg shadow-md">
              <thead class="bg-gray-100 text-gray-700">
                <tr>
                  <th class="text-left">Sl. No.</th>
                  <th class="text-left">Transaction ID</th>
                  <th class="text-center">Plan Type</th>
                  <th class="text-left">Amount</th>
                  <th class="text-left">Order No</th>
                  <th class="text-left">Subscription No</th>
                  <th class="text-center">Payment Status</th>
                  <th class="text-center">Date</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(plan, index) in transactionHistory"
                  :key="index"
                  class="border-b"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ plan.transactionId }}</td>
                  <td class="text-center">{{ plan.planType }}</td>
                  <td>{{ plan.amount }}</td>
                  <td>{{ plan.orderNo }}</td>

                  <td>{{ plan.subscriptionNo }}</td>
                  <td>
                    <span
                      :class="{
                        'status-processing': plan.status === 'Processing',
                        'status-successful': plan.status === 'Success',
                        'status-failed': plan.status === 'Failed',
                      }"
                    >
                      Successful
                    </span>
                  </td>
                  <td>{{ plan.date }}</td>
                  <!-- <td>
                    <button
                      class="px-4 py-2 text-white rounded"
                      :class="{
                        'bg-gray-300 cursor-not-allowed': plan.status !== 'Successful',
                        'bg-blue-500 hover:bg-blue-600': plan.status === 'Successful',
                      }"
                      :disabled="plan.status !== 'Successful'"
                    >
                      Extend
                    </button>
                  </td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div v-if="this.selectedButtonType == 'invoices'"></div>

        <transition name="fade">
          <div v-if="showPopup" class="popup">
            <div class="popup-content">
              <div class="header">
                <h3>Billing Summary</h3>
                <button class="close-btn" @click="closeModal()">✖</button>
              </div>

              <hr />

              <div class="plan-details">
                <p><strong>Plan:</strong> {{ selectedPlan.name }}</p>
                <p>
                  <strong>Start Date:</strong>
                  <span>
                    <input
                      class="form-control"
                      type="date"
                      v-model="licenseFromDate"
                      @change="updateRenewalDate"
                    />
                  </span>
                </p>
                <p>
                  <strong>Renewal Date:</strong>
                  <span>
                    <input
                      class="form-control"
                      type="date"
                      v-model="licenseToDate"
                      readonly
                    />
                  </span>
                </p>

                <div class="license-counter">
                  <span><strong>No. of Licences:</strong></span>
                  <div class="values">
                    <button @click="decreaseLicense">-</button>
                    <span>{{ noOfLicense }}</span>
                    <button @click="increaseLicense">+</button>
                  </div>
                </div>
              </div>

              <div class="billing-card">
                <div class="billing-row">
                  <span class="label" style="font-size: small">No. of License</span>
                  <span class="value">{{ noOfLicense }}</span>
                </div>
                <div class="billing-row">
                  <span class="label" style="font-size: small">Cost per License</span>
                  <span class="value">₹ {{ this.planAmount }}</span>
                </div>
                <div class="billing-row">
                  <span class="label" style="font-size: small">GST</span>
                  <span class="value">₹ 00</span>
                </div>
                <div class="billing-row total-row">
                  <span class="label">Total Amount</span>
                  <span class="value">₹ {{ this.totalAmount }}</span>
                </div>
              </div>

              <!-- <div class="auto-renewal">
                <input type="checkbox" v-model="autoRenewal" />
                <label>Auto Renewal</label>
              </div> -->

              <div class="button-group">
                <button class="cancel-btn" @click="showPopup = false">Cancel</button>

                <button
                  class="proceed-btn"
                  :class="{ disabled: isButtonDisabled }"
                  :disabled="isButtonDisabled"
                  @click="handlePayment"
                >
                  Proceed to Payment
                </button>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../Header.vue";
import axios from "axios";
import Nav from "../Nav.vue";
/* global Razorpay */
export default {
  name: "subscriptionPage",
  components: {
    Header,
    Nav,
  },
  data() {
    return {
      API_BASE_URL: this.$apiEndPoint,
      noOfLicense: "0",
      showPopup: "",
      selectedPlan: {}, // Make sure this starts as an object
      ActiveSubscriptionData: [],
      transactionHistory: [],
      planAmount: "",
      totalAmount: 0,
      razorpayLoaded: false,
      count: "",
      selectedButtonType: "plans",
      licenseFromDate: "",
      licenseToDate: "",
      activeButton: "plans",
      plans: [
        { name: "Monthly", price: 849, amount: 849 },
        { name: "Quarterly", price: 850, amount: 2550 },
        { name: "Yearly", price: 699, amount: 8400 },
      ],
    };
  },

  computed: {
    isButtonDisabled() {
      return !this.licenseFromDate || this.noOfLicense <= 0;
    },
  },

  mounted() {
    this.loadRazorpayScript();
    this.fetchActiveSubscriptions();
    this.fetchTransactionHistory();
    const today = new Date().toISOString().split("T")[0];
    this.licenseFromDate = today;
    this.updateRenewalDate(); // Auto-calculate the renewal date
  },
  methods: {
    increaseLicense() {
      this.noOfLicense = Number(this.noOfLicense) + 1; // Ensure it's a number before adding
      console.log("The number of licenses is now increasing to:", this.noOfLicense);
      this.totalAmount = this.planAmount * this.noOfLicense;
    },

    decreaseLicense() {
      if (this.noOfLicense > 0) {
        this.noOfLicense = Number(this.noOfLicense) - 1; // Ensure subtraction works correctly
        console.log("The number of licenses is now decreasing to:", this.noOfLicense);
        this.totalAmount = this.planAmount * this.noOfLicense;
      }
    },

    getCurrentDate() {
      const today = new Date();
      return today.toISOString().split("T")[0];
    },

    updateRenewalDate() {
      if (!this.licenseFromDate) return;

      const startDate = new Date(this.licenseFromDate);

      if (this.selectedPlan.name === "Monthly") {
        startDate.setMonth(startDate.getMonth() + 1);
      } else if (this.selectedPlan.name === "Quarterly") {
        startDate.setMonth(startDate.getMonth() + 3);
      } else if (this.selectedPlan.name === "Yearly") {
        startDate.setFullYear(startDate.getFullYear() + 1);
      }

      this.licenseToDate = startDate.toISOString().split("T")[0];
    },

    buttonType(type) {
      this.selectedButtonType = type;
      this.activeButton = type;

      if (this.selectedButtonType == "subscription") {
        this.fetchActiveSubscriptions();
      } else if (this.selectedButtonType == "Transaction") {
        this.fetchTransactionHistory();
      } else if (this.selectedButtonType == "Invoice") {
        this.fetchTransactionHistory();
      }
    },

    closeModal() {
      this.showPopup = false;
      this.noOfLicense = 0;
      this.totalAmount = 0;
      this.licenseFromDate = "";
      this.licenseToDate = "";
    },

    async loadRazorpayScript() {
      if (this.razorpayLoaded) return;
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => (this.razorpayLoaded = true);
      document.body.appendChild(script);
    },

    showAmountPopup(plan) {
      console.log("the selected plan is ", plan);
      this.selectedPlan = plan;
      this.planAmount = plan.amount;
      console.log("plan amount is", this.planAmount);
      this.licenseFromDate = this.getCurrentDate(); // Default to today's date
      this.updateRenewalDate(); // Auto-calculate renewal date
      this.showPopup = true;
      // this.totalAmount = this.planAmount * this.noOfLicense;
      this.showPopup = true;
    },

    showAmount(plan) {
      this.totalAmount = plan.amount * this.noOfLicense;
    },

    async handlePayment() {
      this.showPopup = false;
      if (!this.razorpayLoaded) return alert("Razorpay failed to load.");

      const TotalAmount = this.totalAmount * 100;

      const orderId = await this.createOrder(TotalAmount);

      if (!orderId) return;

      const options = {
        key: "rzp_test_E0YH2Wdii0duFT",
        amount: this.TotalAmount,
        currency: "INR",
        name: "PTG Technologies PVT LTD",
        description: `Subscription Payment - ${this.selectedPlan.name}`,
        order_id: orderId,
        handler: (response) => this.verifyPayment(response, orderId),
        prefill: {
          name: "Customer",
          email: "customer@example.com",
          contact: "9999999999",
        },
        theme: { color: "#007bff" },
      };
      new Razorpay(options).open();
    },

    async createOrder(amount) {
      try {
        const requestBody = {
          amount: amount,
          currency: "INR",
          receipt: "order_" + Date.now(),
          planType: this.selectedPlan.name.toLowerCase(),
        };

        console.log("The body data is now:", requestBody);

        const response = await fetch(`${this.API_BASE_URL}/subscription/orders`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.$LocalUser.accessToken}`, // Add your token here
          },
          body: JSON.stringify(requestBody),
        });

        const data = await response.json();
        if (!response.ok) throw new Error(data.message || "Order creation failed");

        return data.id;
      } catch (error) {
        console.log(error);
        return null;
      }
    },

    async verifyPayment(response, orderId) {
      try {
        console.log("Raw Razorpay response:", response);
        console.log("Order ID:", orderId);
        console.log("Total Amount:", this.totalAmount);
        console.log("No of License:", this.noOfLicense);
        console.log("Company ID:", this?.$LocalUser?.user?.companyId);
        console.log("Selected Plan:", this.selectedPlan?.name);

        const requestBody = {
          orderId,
          transactionId: response?.razorpay_payment_id,
          signature: response?.razorpay_signature,
          amount: this.totalAmount,
          noOfLicense: this.noOfLicense,
          companyId: this.$LocalUser?.user?.companyId,
          planType: this.selectedPlan?.name?.toLowerCase(),
          licenseFromDate: this.licenseFromDate,
          licenseToDate: this.licenseToDate,
        };

        console.log("The verify payment request body is:", requestBody);

        const verifyResponse = await fetch(
          `${this.API_BASE_URL}/subscription/verifyPayment`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.$LocalUser.accessToken}`, // Add your token here
            },
            body: JSON.stringify(requestBody),
          }
        );

        const data = await verifyResponse.json();
        if (!verifyResponse.ok)
          throw new Error(data.message || "Payment verification failed");

        alert("Payment Successful!");
      } catch (error) {
        console.error("Error in verifyPayment:", error);
        alert("Payment verification failed. Contact support.");
      }
    },

    async fetchActiveSubscriptions() {
      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/subscription/getSubscriptions/Active`,
          {}
        );
        this.ActiveSubscriptionData = response.data;
        console.log("the ActiveSubscriptionData data is:", this.ActiveSubscriptionData);
      } catch (error) {
        console.error("Error fetching subscriptions:", error);
        this.$toast("Failed to fetch subscriptions", "error");
      }
    },

    async fetchTransactionHistory() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/subscription/getTransactionHistory`,
          {}
        );
        this.transactionHistory = response.data;
        console.log("the fetchTransactionHistory data is:", this.transactionHistory);
      } catch (error) {
        console.error("Error fetching subscriptions:", error);
        this.$toast("Failed to fetch subscriptions", "error");
      }
    },

    // async verifyPayment(response, orderId) {
    //   try {
    //     const verifyResponse = await fetch(
    //       `${this.API_BASE_URL}/subscription/verifyPayment`,
    //       {
    //         method: "PUT",
    //         headers: { "Content-Type": "application/json" },
    //         body: JSON.stringify({
    //           orderId,
    //           transactionId: response.razorpay_payment_id,
    //           signature: response.razorpay_signature,
    //           amount: this.totalAmount,
    //           noOfLicense: this.noOfLicense,
    //           companyId: this.$LocalUser.user.companyId,
    //           planType: this.selectedPlan.name.toLowerCase(),
    //           licenseFromDate: "2025-02-01",
    //           licenseToDate: "2025-04-30",
    //         }),
    //       }
    //     );
    //     const data = await verifyResponse.json();
    //     if (!verifyResponse.ok)
    //       throw new Error(data.message || "Payment verification failed");
    //     alert("Payment Successful!");
    //   } catch (error) {
    //     alert("Payment verification failed. Contact support.");
    //   }
    // },

    // async createOrder(amount) {
    //   try {
    //     const response = await fetch(`${this.API_BASE_URL}/subscription/orders`, {
    //       method: "POST",
    //       headers: { "Content-Type": "application/json" },
    //       body: JSON.stringify({
    //         amount: amount,
    //         currency: "INR",
    //         receipt: "order_" + Date.now(),
    //         planType: this.selectedPlan.name.toLowerCase(),
    //       }),
    //     });

    //     console.log("the body data is now:", this.body);

    //     const data = await response.json();
    //     if (!response.ok) throw new Error(data.message || "Order creation failed");
    //     return data.id;
    //   } catch (error) {
    //     // alert("Order creation failed. Try again.") ;
    //     console.log(error);
    //     return null;
    //   }
    // },
  },
};
</script>

<style scoped>
.proceed-btn.disabled {
  background-color: transparent; /* Greyed out */
  color: #a0a0a0; /* Dimmed text */
  cursor: not-allowed;
  filter: blur(1px); /* Adds blur effect */
  pointer-events: none; /* Ensures no interaction */
}

.form-control {
  display: block;
  width: 100%;
  padding: 4px;
  font-size: small;
  font-weight: 400;
  line-height: 1;
  color: var(--bs-body-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15sease-in-out, box-shadow 0.15sease-in-out;
}
.table-container {
  max-height: 400px; /* Set max height for scroll */
  overflow-y: auto; /* Enable vertical scrolling */
  border: 1px solid #ddd;
  border-top: none;
  border-radius: 8px;
  background: white;
}

/* Webkit Browsers (Chrome, Safari) */
.table-container::-webkit-scrollbar {
  width: 6px; /* Thin scrollbar */
  height: 6px; /* Thin horizontal scrollbar */
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #b3b3b3; /* Scrollbar color */
  border-radius: 10px; /* Rounded edges */
}

.table-container::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Track color */
}

table {
  width: 100%;
  border-collapse: collapse;
}

thead {
  position: sticky;
  top: 0;
  background-color: #dfdfdf;
  color: #555;
  font-size: 14px;
  text-align: center;
  z-index: 10;
}

th,
td {
  padding: 12px;
  border-bottom: 1px solid #e0e0e0;
  text-align: center;
  font-size: 12px;
}

tbody tr:hover {
  background-color: #f5f5f5;
}

/* Sticky Header Fix */
thead th {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

/* Payment Status Styles */
.status-processing {
  background-color: #fff3cd; /* Light Yellow */
  color: #856404; /* Dark Yellow */
  padding: 4px 8px;
  border-radius: 10px;
}

.status-successful {
  background-color: #d4edda; /* Light Green */
  color: #155724; /* Dark Green */
  padding: 4px 8px;
  border-radius: 10px;
}

.status-failed {
  background-color: #f8d7da; /* Light Red */
  color: #721c24; /* Dark Red */
  padding: 4px 8px;
  border-radius: 10px;
}

/* Buttons */
.btn {
  padding: 6px 12px;
  border-radius: 4px;
  color: white;
  cursor: pointer;
}

.btn-disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.btn-active {
  background-color: #007bff;
}

.btn-active:hover {
  background-color: #0056b3;
}

/* Center align specific columns */
td:nth-child(3),
td:nth-child(5),
td:nth-child(6),
td:nth-child(7),
td:nth-child(8) {
  text-align: center;
}

td:nth-child(1),
td:nth-child(4) {
  text-align: center;
}

td:nth-child(1) {
  text-align: center;
}

/* Button Styling */
button {
  padding: 8px 16px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 6px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  border: none;
}

/* Extend button */
.btn-extend {
  background-color: #007bff;
  color: white;
}

.btn-extend:hover {
  background-color: #0056b3;
}

/* Disabled button */
.btn-disabled {
  background-color: #d6d6d6;
  color: #8a8a8a;
  cursor: not-allowed;
}

body,
.content-Container {
  margin: 0;
  padding: 0;
}

.buttons-section {
  display: flex;
  align-items: center;
  padding: 20px;
  gap: 10px; /* Adds spacing between buttons */
  background-color: whitesmoke;
}

.buttons-section button {
  background-color: transparent; /* Default state with no background */
  color: #007bff; /* Blue text */
  min-width: 10%;
  padding: 6px 10px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease;
}

.buttons-section button.active {
  background-color: #007bff !important; /* Solid blue for active */
  color: #fff !important; /* White text */
  transform: scale(1.05); /* Slight zoom effect */
}

.buttons-section button:hover {
  background-color: rgba(0, 123, 255, 0.1); /* Light blue hover effect */
  transform: scale(1.05);
}

.buttons-section button:active {
  background-color: #0056b3;
  transform: scale(0.95); /* Press effect */
}

.billing-card {
  background: #f9f9f9;
  border-radius: 8px;
  padding: 15px;
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.billing-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
}

.billing-row:last-child {
  border-bottom: none;
}

.label {
  font-size: 12px;
  color: #555;
}

.value {
  font-size: 12px;
  font-weight: bold;
  color: #222;
}

.total-row .label,
.total-row .value {
  font-size: 12px;
  font-weight: bold;
  color: #000;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-btn {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.plan-details p {
  margin: 5px 0;
  display: grid;
}

.plan-details {
  display: flex;
  justify-content: space-between;
}

.license-counter {
  display: grid;
  justify-items: center;
  gap: 10px;
}

.license-counter button {
  padding: 2px 8px;
  border: 1px solid #ccc;
  cursor: pointer;
}

.billing-table {
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
}

.billing-table td {
  padding: 5px;
  border-bottom: 1px solid #ddd;
}

.total-row td {
  font-weight: bold;
}

.auto-renewal {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 10px 0;
}

.button-group {
  display: flex;
  justify-content: space-between;
}

.proceed-btn {
  background: #0057ff;
  color: white;
  padding: 8px 15px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.cancel-btn {
  background: #ccc;
  color: black;
  padding: 8px 15px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}
.header h3 {
  font-size: 18px;
  font-weight: bold;
  color: #222; /* Darker for better readability */
}

.close-btn {
  font-size: 20px;
  color: #666;
}

.close-btn:hover {
  color: #ff3b3b; /* Red on hover */
}

.plan-details p {
  font-size: 15px;
  color: #444;
}

.license-counter span {
  font-size: 15px;
  color: #333;
  padding: 0px 8px;
}

.billing-table {
  font-size: 14px;
  color: #333;
}

.billing-table td {
  color: #444;
}

.total-row td {
  font-size: 16px;
  color: #000;
}

.auto-renewal {
  font-size: 14px;
  color: #555;
}

.proceed-btn {
  font-size: 14px;
  background: #007bff;
  color: white;
}

.proceed-btn:hover {
  background: #0056d2;
}

.cancel-btn {
  font-size: 14px;
  background: #ccc;
  color: black;
}

.cancel-btn:hover {
  background: #b3b3b3;
}
body,
.main-container {
  margin: 0;
  padding: 10px;
  background-color: rgb(175, 174, 174);
  height: 97vh;
}
.container {
  max-width: 100%;
  text-align: left;
  animation: fadeIn 0.8s ease-in-out;
  height: inherit;
  background: #ffffff;
  border-radius: 6px;
}

.title {
  padding: 15px;
}
.sub-title {
  padding: 20px;
}

.label {
  font-size: 16px;
  font-weight: bold;
}
.input {
  width: 100px;
  padding: 8px;
  margin: 10px;
}
.pricing-container {
  display: flex;
  justify-content: center;
  gap: 150px;
  flex-wrap: wrap;
  padding: 0px 20px 20px 20px;
}
.single-pricing-table {
  font-size: 14px;
  border: 2px solid #ddd;
  border-radius: 0px 0px 20px 20px;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
  width: 350px;
  height: 180px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Blue footer section */
.pricing-footer {
  background-color: #007bff;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 20px 20px;
  color: #ffffff;
}

.default-btn {
  border: none;
  padding: 10px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  transition: 0.3s;
}

.single-pricing-table.hovered {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  border-color: #007bff;
}
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 45%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  background: white;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.confirm-btn {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  transition: background 0.3s;
}
.confirm-btn:hover {
  background-color: #0056b3;
}
.cancel-btn {
  margin-left: 10px;
  background-color: #dc3545;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  transition: background 0.3s;
}
.cancel-btn:hover {
  background-color: #a71d2a;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.pricing-footer:hover {
  background-color: #0056b3;
  color: #ffffff;
}
.pricing_dec {
  font-size: 10px;
  display: flex;
  align-items: center;
  color: #084f5b;
  background-color: #d0f9c3;
  padding: 6px;
  border-radius: 5px;
}
.pricing-header {
  padding-top: 5px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
