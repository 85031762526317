<template>
  <div v-if="show" class="modal-overlay">
    <div class="modal-content">
      <h2 class="modal-title">Maximum Device Limit Reached</h2>
      <div class="alert-box">
        You have reached the maximum limit of 3 active devices. Please log out from one of
        the existing devices to continue.
      </div>

      <div class="device-list">
        <div v-for="(device, index) in devices" :key="index" class="device-item">
          <div class="device-info">
            <strong>{{ device.browserName }}</strong>
            <div class="device-details">• {{ device.os }}</div>
          </div>
          <button class="logout-btn" @click="logoutUser(device.visitorId)">
            Log Out
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    show: Boolean,
    devices: Array,
  },
  data() {
    return {
      localDevices: [...this.devices], // Copy devices to local data
    };
  },
  watch: {
    // Update localDevices whenever the devices prop changes
    devices(newDevices) {
      this.localDevices = [...newDevices];
    },
  },
  methods: {
    async logoutUser(visitorId) {
      const device = this.localDevices.find((d) => d.visitorId === visitorId);

      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/users/removeLoggedInDevice`,
          {
            userId: device.userId,
            visitorId: device.visitorId,
            browserName: device.browserName,
            ipAddress: device.ipAddress,
            os: device.os,
          }
        );

        console.log("The logout response is", response);

        if (response.status === 200) {
          this.$toast(`Device ${device.browserName} logged out successfully.`, "success");
          this.localDevices = this.localDevices.filter((d) => d.visitorId !== visitorId);

          // Emit event to close modal
          this.$emit("close-modal");
        } else {
          this.$toast(`Failed to log out device ${device.browserName}.`, "error");
        }
      } catch (error) {
        console.error(`Error logging out device ${device.browserName}:`, error);
        this.$toast(
          `Error logging out device ${device.browserName}. Please try again.`,
          "error"
        );
      }
    },
  },
};
</script>

<style scoped>
/* Modal Styling */
.modal-content {
  background: cornsilk;
  padding: 25px;
  border-radius: 16px;
  box-shadow: 0 4px 62px rgba(0, 0, 0, 2); /* Enhanced shadow for depth */
  width: 100%;
  max-width: 600px;
  text-align: left;
  border: 1px solid #e0e0e0; /* Light border for definition */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Dark overlay for focus */
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(35px); /* Optional: Smooth blur effect */
}

/* Title */
.modal-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
  border-bottom: 2px solid #000;
  padding-bottom: 5px;
}

/* Alert Box */
.alert-box {
  background-color: #fff3cd;
  color: #856404;
  padding: 12px 16px;
  border: 1px solid #ffeeba;
  border-radius: 6px;
  margin-bottom: 20px;
  font-size: 14px;
}

/* Device List */
.device-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.device-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  border-top: 1px solid #ddd;
}

.device-info {
  display: flex;
  flex-direction: column;
}

.device-info strong {
  font-weight: 600;
}

.device-details {
  color: #777;
  font-size: 13px;
  margin-top: 3px;
}

/* Logout Button */
.logout-btn {
  background-color: #e0e0e0;
  color: #333;
  border: none;
  border-radius: 20px;
  padding: 6px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 14px;
  font-weight: 600;
}

.logout-btn:hover {
  background-color: #d6d6d6;
}
</style>
