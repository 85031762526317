<template>
  <div ref="pdfContent" class="pdf-container">
    <!-- Header Section -->
    <div class="header">
      <div class="company-info">
        <!-- <h2>Notes</h2> -->
        <p><strong>Antarika Craftsmen Pvt Ltd</strong></p>
        <p>546, 8th cross road, 3rd phase J.P.Nagar, Bengaluru, Karnataka - 560078</p>
        <p>📞 8743581493</p>
      </div>
      <img src="../../assets/icons/CompanyLogo.png.jpg" alt="Company Logo" class="logo" />
    </div>

    <hr class="divider" />

    <!-- Collaborator Details -->
    <div class="user-info">
      <img
        src="../../assets/icons/profileIconBefore.png"
        alt="User Avatar"
        class="user-avatar"
      />
      <div>
        <p class="user-name" style="margin-bottom: 0px">
          {{ notesData["employees.firstName"] }}
        </p>
        <p class="user-role">
          {{ $formatDateDMY(notesData.created_at) }}
        </p>
      </div>
    </div>
    <!-- Note Title -->
    <h6 style="font-size: 14px" class="note-title">NoteTitle: {{ notesData.header }}</h6>

    <p class="note-description">{{ notesData.description }}</p>

    <!-- Dates Section -->
    <div class="dates">
      <!-- <p><strong>Created At:</strong> </p> -->
      <!-- <p><strong>Last Updated:</strong> {{ notesData.updated_at }}</p> -->
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export default {
  props: {
    notesData: Object,
  },
  methods: {
    async generatePDF() {
      const pdfContent = this.$refs.pdfContent;
      const canvas = await html2canvas(pdfContent, { scale: 2 });

      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(imgData, "PNG", 10, 10, 190, 0);
      pdf.save(this.notesData.header || "Meeting_Notes.pdf");
    },
  },
};
</script>

<style scoped>
/* General PDF container */
.pdf-container {
  background: #ffffff;
  padding: 20px;
  width: 650px;
  font-family: "Arial", sans-serif;
  color: #333;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Header Section */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.company-info {
  max-width: 70%;
}

.logo {
  height: 40%;
  width: 40%;
}

/* Divider */
.divider {
  border: none;
  height: 2px;
  background: #ddd;
  margin: 15px 0;
}

/* Note Title */
.note-title {
  font-size: 20px;
  font-weight: bold;
  color: #444;
  margin-bottom: 10px;
}

/* User Information */
.user-info {
  display: flex;
  margin-bottom: 10px;
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-name {
  font-weight: bold;
}

.user-role {
  font-size: 14px;
  color: #777;
}

.date {
  font-size: 12px;
  color: #888;
}

/* Description */
.note-description {
  font-size: 14px;
  margin-top: 15px;
  line-height: 1.5;
}

/* Project Details */
.project-section {
  background: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
  margin-top: 15px;
}

.project-section h4 {
  font-size: 16px;
  color: #333;
  margin-bottom: 5px;
}

.project-section ul {
  padding-left: 20px;
  list-style-type: none;
}

.project-section li {
  font-size: 14px;
  margin-bottom: 5px;
}

/* Dates Section */
.dates {
  margin-top: 15px;
  font-size: 14px;
}

.dates p {
  margin: 5px 0;
}
</style>
