<template>
  <Header />
  <div class="content-Container">
    <Nav />
    <div class="containerChild">
      <div class="header-section">
        <div class="header">
          <h3>All Tickets</h3>
          <!-- <h3 v-if="this.$route.params == 'Opened'">Opened Tickets</h3>
          <h3 v-if="this.$route.params == 'InProgress'">In Progress Tickets</h3> -->
        </div>
        <div class="search-container">
          <div class="icon-wrapper">
            <img class="icons" src="../../assets/icons/filter.png" />
          </div>
          <div class="search">
            <img class="icons" src="../../assets/icons/search.png" />
            <input type="search" placeholder="Search tickets..." />
          </div>
        </div>
      </div>

      <div class="table-container">
        <table>
          <thead>
            <tr>
              <th><input type="checkbox" /></th>
              <th>Ticket ID</th>
              <th>Issue Title</th>
              <th>Severity</th>
              <th>Status</th>
              <th>Assignee</th>
              <th>Created Date</th>
              <th>Last Updated at</th>
            </tr>
          </thead>
          <tbody>
            <tr data-bs-toggle="modal" data-bs-target="#ticketModal">
              <td><input type="checkbox" /></td>
              <td>TC-00009</td>
              <td>Couldn't able to create an estimate...</td>
              <td><span class="badge critical">Critical</span></td>
              <td><span class="badge open">Open</span></td>
              <td>
                <img
                  src="../../assets/icons/shareWith.png"
                  alt="User"
                  class="user-icon"
                />
                Unassigned
              </td>
              <td>12/02/2025, 10:25am</td>
              <td>-</td>
            </tr>
            <tr>
              <td><input type="checkbox" /></td>
              <td>TC-00009</td>
              <td>Couldn't able to create an estimate...</td>
              <td><span class="badge critical">Critical</span></td>
              <td><span class="badge open">Open</span></td>
              <td>
                <img
                  src="../../assets/icons/shareWith.png"
                  alt="User"
                  class="user-icon"
                />
                Unassigned
              </td>
              <td>12/02/2025, 10:25am</td>
              <td>-</td>
            </tr>
            <tr>
              <td><input type="checkbox" /></td>
              <td>TC-00009</td>
              <td>Couldn't able to create an estimate...</td>
              <td><span class="badge critical">Critical</span></td>
              <td><span class="badge open">Open</span></td>
              <td>
                <img
                  src="../../assets/icons/shareWith.png"
                  alt="User"
                  class="user-icon"
                />
                Unassigned
              </td>
              <td>12/02/2025, 10:25am</td>
              <td>-</td>
            </tr>
            <tr>
              <td><input type="checkbox" /></td>
              <td>TC-00009</td>
              <td>Couldn't able to create an estimate...</td>
              <td><span class="badge critical">Critical</span></td>
              <td><span class="badge open">Open</span></td>
              <td>
                <img
                  src="../../assets/icons/shareWith.png"
                  alt="User"
                  class="user-icon"
                />
                Unassigned
              </td>
              <td>12/02/2025, 10:25am</td>
              <td>-</td>
            </tr>
            <tr>
              <td><input type="checkbox" /></td>
              <td>TC-00009</td>
              <td>Couldn't able to create an estimate...</td>
              <td><span class="badge critical">Critical</span></td>
              <td><span class="badge open">Open</span></td>
              <td>
                <img
                  src="../../assets/icons/shareWith.png"
                  alt="User"
                  class="user-icon"
                />
                Unassigned
              </td>
              <td>12/02/2025, 10:25am</td>
              <td>-</td>
            </tr>
            <tr>
              <td><input type="checkbox" /></td>
              <td>TC-00009</td>
              <td>Couldn't able to create an estimate...</td>
              <td><span class="badge critical">Critical</span></td>
              <td><span class="badge open">Open</span></td>
              <td>
                <img
                  src="../../assets/icons/shareWith.png"
                  alt="User"
                  class="user-icon"
                />
                Unassigned
              </td>
              <td>12/02/2025, 10:25am</td>
              <td>-</td>
            </tr>
            <tr>
              <td><input type="checkbox" /></td>
              <td>TC-00009</td>
              <td>Couldn't able to create an estimate...</td>
              <td><span class="badge critical">Critical</span></td>
              <td><span class="badge open">Open</span></td>
              <td>
                <img
                  src="../../assets/icons/shareWith.png"
                  alt="User"
                  class="user-icon"
                />
                Unassigned
              </td>
              <td>12/02/2025, 10:25am</td>
              <td>-</td>
            </tr>
            <tr>
              <td><input type="checkbox" /></td>
              <td>TC-00009</td>
              <td>Couldn't able to create an estimate...</td>
              <td><span class="badge critical">Critical</span></td>
              <td><span class="badge open">Open</span></td>
              <td>
                <img
                  src="../../assets/icons/shareWith.png"
                  alt="User"
                  class="user-icon"
                />
                Unassigned
              </td>
              <td>12/02/2025, 10:25am</td>
              <td>-</td>
            </tr>
            <tr>
              <td><input type="checkbox" /></td>
              <td>TC-00009</td>
              <td>Couldn't able to create an estimate...</td>
              <td><span class="badge critical">Critical</span></td>
              <td><span class="badge open">Open</span></td>
              <td>
                <img
                  src="../../assets/icons/shareWith.png"
                  alt="User"
                  class="user-icon"
                />
                Unassigned
              </td>
              <td>12/02/2025, 10:25am</td>
              <td>-</td>
            </tr>
            <tr>
              <td><input type="checkbox" /></td>
              <td>TC-00009</td>
              <td>Couldn't able to create an estimate...</td>
              <td><span class="badge critical">Critical</span></td>
              <td><span class="badge open">Open</span></td>
              <td>
                <img
                  src="../../assets/icons/shareWith.png"
                  alt="User"
                  class="user-icon"
                />
                Unassigned
              </td>
              <td>12/02/2025, 10:25am</td>
              <td>-</td>
            </tr>
            <tr>
              <td><input type="checkbox" /></td>
              <td>TC-00009</td>
              <td>Couldn't able to create an estimate...</td>
              <td><span class="badge critical">Critical</span></td>
              <td><span class="badge open">Open</span></td>
              <td>
                <img
                  src="../../assets/icons/shareWith.png"
                  alt="User"
                  class="user-icon"
                />
                Unassigned
              </td>
              <td>12/02/2025, 10:25am</td>
              <td>-</td>
            </tr>
            <tr>
              <td><input type="checkbox" /></td>
              <td>TC-00009</td>
              <td>Couldn't able to create an estimate...</td>
              <td><span class="badge critical">Critical</span></td>
              <td><span class="badge open">Open</span></td>
              <td>
                <img
                  src="../../assets/icons/shareWith.png"
                  alt="User"
                  class="user-icon"
                />
                Unassigned
              </td>
              <td>12/02/2025, 10:25am</td>
              <td>-</td>
            </tr>
            <tr>
              <td><input type="checkbox" /></td>
              <td>TC-00008</td>
              <td>Couldn't able to create an estimate...</td>
              <td><span class="badge high">High</span></td>
              <td><span class="badge open">Open</span></td>
              <td>
                <img
                  src="../../assets/icons/profileIconBefore.png"
                  alt="User"
                  class="user-icon"
                />
                Unassigned
              </td>
              <td>12/02/2025, 10:25am</td>
              <td>12/02/2025, 10:25am</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="footer-container">
        <div class="footer-contents">
          <span>
            <img class="pagination-icon" src="../../assets/icons/leftPagenation.png" />
          </span>
          <span>
            <p>2</p>
          </span>
          <span>
            <img class="pagination-icon" src="../../assets/icons/rightPagenation.png" />
          </span>
        </div>
        <div class="container-counts"></div>
      </div>
    </div>
  </div>
  <customerTicketModal />
</template>

<script>
import Header from "../Header.vue";
import Nav from "../Nav.vue";
import customerTicketModal from "../../components/Modal/customerTicketModal.vue";

export default {
  components: {
    Header,
    Nav,
    customerTicketModal,
  },
  data() {
    return {};
  },

  methds: {},
};
</script>

<style scoped>
body,
.content-Container {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.header-section {
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Ensures content wraps on smaller screens */
}

.header h3 {
  margin: 0;
  font-size: 22px;
}

.search-container {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap; /* Ensures icons and search bar adjust on smaller screens */
}

.search {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 20px;
  border: 1px solid rgb(108, 108, 108);
  padding: 5px 15px;
  width: 250px;
  height: 35px;
  max-width: 100%; /* Ensures the search bar scales properly */
}

.icon-wrapper {
  background-color: #e3e3e3;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.icon-wrapper:hover {
  background-color: #d1d1d1;
}

.icons {
  height: 18px;
  width: 18px;
}

input[type="search"] {
  border: none;
  outline: none;
  background-color: transparent;
  padding: 5px 10px;
  font-size: 14px;
  width: 100%;
  color: #333;
}

input[type="search"]::placeholder {
  color: #aaa;
}

.table-container {
  width: 100%;
  overflow-y: auto; /* Enables vertical scrolling */
  min-height: 500px;
  max-height: 450px; /* Set a maximum height for scrollable content */
  margin-top: 20px;
  border-radius: 12px;
  padding: 0px 10px 10px 10px;
  background-color: #fff;
}

/* Webkit Browsers (Chrome, Safari) */
.table-container::-webkit-scrollbar {
  width: 2px; /* Thin scrollbar */
  height: 6px; /* Thin horizontal scrollbar */
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #b3b3b3; /* Scrollbar color */
  border-radius: 10px; /* Rounded edges */
}

.table-container::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Track color */
}

table {
  width: 100%;
  border-collapse: collapse;
}

thead {
  position: sticky;
  top: 0;
  background-color: #eaeaea; /* Important for sticky headers */
  z-index: 1; /* Ensures it stays above the tbody content */
}

th {
  background-color: #eaeaea; /* Reinforce background to avoid visual gaps */
}

th,
td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  white-space: nowrap; /* Prevents text from breaking */
  font-size: small;
}

tbody tr:hover {
  background-color: #f1f1f1;
}

.user-icon {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px dashed;
  padding: 1px;
  border-color: grey;
  margin-right: 5px;
  vertical-align: middle;
}

.pagination-icon {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  padding: 1px;
  vertical-align: middle;
}

.badge {
  display: inline-block;
  border-radius: 15px;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.critical {
  background-color: #e53935;
  font-size: small;
  font-weight: 300;
  min-width: 100%;
}
.high {
  background-color: #f39c12;
  font-size: small;
  font-weight: 300;
  min-width: 100%;
}
.medium {
  background-color: #f1c40f;
  font-size: small;
  font-weight: 300;
  min-width: 100%;
}
.low {
  background-color: #4caf50;
  font-size: small;
  font-weight: 300;
  min-width: 100%;
}

.open {
  background-color: #2196f3;
  font-size: small;
  font-weight: 300;
  min-width: 100%;
}
.in-progress {
  background-color: #ff9800;
  font-size: small;
  font-weight: 300;
  min-width: 100%;
}
.on-hold {
  background-color: #757575;
  font-size: small;
  font-weight: 300;
  min-width: 100%;
}
.escalated {
  background-color: #8e44ad;
  font-size: small;
  font-weight: 300;
  min-width: 100%;
}
.resolved {
  background-color: #4caf50;
  font-size: small;
  font-weight: 300;
  min-width: 100%;
}
.closed {
  background-color: #212121;
  font-size: small;
  font-weight: 300;
  min-width: 100%;
}
.reopened {
  background-color: #ff5722;
  font-size: small;
  font-weight: 300;
  min-width: 100%;
}

.footer-container {
  position: relative; /* Stays at the bottom of the content */
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: inherit;
  font-size: small;
  z-index: 1000;
  padding: 5px 20px; /* Optional for spacing */
}

.footer-contents {
  display: flex;
}

/* Responsive Design */
@media (max-width: 600px) {
  .header-section {
    flex-direction: column;
    gap: 10px;
  }

  .search-container {
    width: 100%;
    justify-content: space-between; /* Align icons to the edges */
  }

  .search {
    width: 100%;
  }

  .icon-wrapper {
    height: 30px;
    width: 30px;
  }
}
</style>
