<template>
  <div>
    <Header />
    <div class="content-container">
      <Nav />
      <div class="containerChild">
        <div class="faq-accordion">
          <div
            v-for="(faq, index) in faqs"
            :key="index"
            class="faq-item"
            @click="toggleAnswer(index)"
          >
            <!-- Question -->
            <div class="faq-question">
              <h6>{{ faq.question }}</h6>
              <span :class="['icon', { expanded: activeIndex === index }]">
                {{ activeIndex === index ? "\u2212" : "\u002B" }}
              </span>
            </div>

            <!-- Answer -->
            <transition name="slide-fade">
              <div v-if="activeIndex === index" class="faq-answer">
                <p>{{ faq.answer }}</p>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../Header.vue";
import Nav from "../Nav.vue";
export default {
  name: "FaqAccordion",
  components: {
    Header,
    Nav,
  },
  data() {
    return {
      activeIndex: null, // Keeps track of the currently active FAQ
      faqs: [
        {
          question: "What is the role of an interior designer?",
          answer:
            "An interior designer enhances the functionality, safety, and aesthetics of interior spaces.",
        },
        {
          question: "How does color impact interior design?",
          answer:
            "Colors influence mood, perception of space, and overall ambiance in a room.",
        },
        {
          question: "What are the trending materials in modern interiors?",
          answer:
            "Sustainable materials like bamboo, reclaimed wood, and natural stone are popular.",
        },
        {
          question: "What is space planning?",
          answer:
            "Space planning involves organizing furniture and decor to optimize functionality and flow.",
        },
        {
          question: "Why is lighting important in interior design?",
          answer:
            "Lighting sets the mood, highlights features, and makes spaces functional and welcoming.",
        },
        {
          question: "What are the principles of interior design?",
          answer:
            "Key principles include balance, rhythm, harmony, proportion, and emphasis.",
        },
        {
          question: "How does minimalism impact interior design?",
          answer:
            "Minimalism focuses on simplicity, functionality, and uncluttered spaces.",
        },
        {
          question: "What are some small space design tips?",
          answer:
            "Use multifunctional furniture, mirrors to reflect light, and light colors to make the space feel larger.",
        },
        {
          question: "What is the difference between modern and contemporary design?",
          answer:
            "Modern design has a specific period aesthetic, while contemporary design evolves with current trends.",
        },
        {
          question: "How do textures influence interior design?",
          answer:
            "Textures add depth, contrast, and a tactile element to interior spaces.",
        },
        {
          question: "What is biophilic design?",
          answer:
            "Biophilic design incorporates natural elements to create a connection with nature indoors.",
        },
        {
          question: "What are some common furniture arrangement mistakes?",
          answer:
            "Blocking windows, overcrowding, and not considering traffic flow are common errors.",
        },
        {
          question: "How do I choose the right rug size?",
          answer:
            "Rugs should anchor the furniture; ideally, all front legs of seating should rest on the rug.",
        },
        {
          question: "What are accent walls?",
          answer:
            "Accent walls are highlighted walls in a room using color, texture, or pattern to draw attention.",
        },
        {
          question: "How do I mix patterns in a room?",
          answer:
            "Combine patterns of different scales and colors that complement each other.",
        },
        {
          question: "What are some budget-friendly design ideas?",
          answer:
            "Repurposing furniture, DIY projects, and using removable wallpaper are cost-effective options.",
        },
        {
          question: "What is an open floor plan?",
          answer: "An open floor plan removes walls to create one large, shared space.",
        },
        {
          question: "How do I decorate a rental space?",
          answer:
            "Use temporary changes like removable decals, rugs, and versatile furniture.",
        },
        {
          question: "What is the impact of ceiling height on design?",
          answer:
            "High ceilings make spaces feel larger, while low ceilings feel cozier.",
        },
        {
          question: "What is industrial interior design?",
          answer:
            "Industrial design features exposed elements like pipes, brick walls, and metal finishes.",
        },
        {
          question: "How can I add storage to small spaces?",
          answer:
            "Use vertical storage, under-bed drawers, and multi-functional furniture.",
        },
        {
          question: "What are smart home design trends?",
          answer:
            "Integration of smart lighting, thermostats, and security systems are popular trends.",
        },
        {
          question: "How do I choose artwork for a room?",
          answer:
            "Match the art style and colors with the room’s overall design and size.",
        },
        {
          question: "What are popular kitchen layouts?",
          answer: "L-shaped, U-shaped, galley, and open kitchen layouts are common.",
        },
        {
          question: "How do I create a cozy living room?",
          answer:
            "Layered lighting, soft textures, and warm colors can create a cozy ambiance.",
        },
        {
          question: "What is Scandinavian design?",
          answer:
            "Scandinavian design emphasizes minimalism, functionality, and neutral tones.",
        },
        {
          question: "How do I select curtains or blinds?",
          answer: "Consider light control, privacy needs, and the room’s design style.",
        },
        {
          question: "What is transitional interior design?",
          answer: "It blends traditional and modern styles for a balanced look.",
        },
        {
          question: "How do I choose a focal point for a room?",
          answer:
            "Use standout features like a fireplace, artwork, or a large window as the focus.",
        },
        {
          question: "What are eco-friendly interior design tips?",
          answer:
            "Use sustainable materials, energy-efficient lighting, and upcycled furniture.",
        },
        {
          question: "What is the role of a mood board in design?",
          answer:
            "A mood board helps visualize design ideas and concepts before implementation.",
        },
        {
          question: "How do I design a functional home office?",
          answer:
            "Ensure ergonomic furniture, proper lighting, and an organized workspace.",
        },
        {
          question: "What is eclectic design?",
          answer: "Eclectic design mixes styles, textures, and colors in a cohesive way.",
        },
        {
          question: "How do I create contrast in a room?",
          answer:
            "Combine light and dark colors, different textures, and materials to create contrast.",
        },
        {
          question: "What are popular wall decor ideas?",
          answer:
            "Gallery walls, statement mirrors, and textured panels are trending ideas.",
        },
        {
          question: "How do I design a kid-friendly space?",
          answer: "Use durable materials, rounded furniture edges, and vibrant colors.",
        },
        {
          question: "What is mid-century modern design?",
          answer:
            "It features clean lines, organic shapes, and a blend of natural and synthetic materials.",
        },
        {
          question: "How do I maximize natural light?",
          answer:
            "Use light curtains, mirrors, and light-colored walls to reflect natural light.",
        },
        {
          question: "What are the benefits of modular furniture?",
          answer: "Modular furniture is versatile, space-saving, and customizable.",
        },
        {
          question: "How do I incorporate greenery in design?",
          answer:
            "Use indoor plants, green walls, and biophilic patterns to add natural elements.",
        },
      ],
    };
  },
  methods: {
    toggleAnswer(index) {
      this.activeIndex = this.activeIndex === index ? null : index; // Toggle the active state
    },
  },
};
</script>

<style scoped>
.faq-accordion {
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow-y: auto;

  padding: 10px;
}

.faq-item {
  border-bottom: 1px solid #ddd;
  padding: 20px;
  cursor: pointer;
  transition: background 0.3s, transform 0.2s;
}

.faq-item:hover {
  background: #f1faff;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 123, 255, 0.2);
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px !important;
  font-weight: 600;
  color: #222;
}

.faq-answer {
  margin-top: 15px;
  padding: 10px;
  background: #fafafa;
  border-left: 4px solid #007bff;
  font-size: 14px !important;
  color: #555;
  line-height: 1.6;
  border-radius: 5px;
}

.faq-item:last-child {
  border-bottom: none;
}

.icon {
  font-size: 22px;
  font-weight: bold;
  color: #007bff;
  transition: transform 0.2s ease-in-out;
}

.icon.expanded {
  transform: rotate(180deg);
}

/* Transition Effect */
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
</style>
