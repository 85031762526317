<template>
  <div>
    <CreateProjectModal @fetchData="fetchData" :showSignOff="true" />
    <CreateDesigProjectModal
      :ProcurementprojectId="selectedProjectId"
      @fetchData="fetchData"
    />
    <Header />
    <div class="content-Container">
      <Nav />
      <div class="containerChild overflow-y-scroll">
        <div class="FormatTable" v-if="showUnclaimedList">
          <div class="row headerOfTable d-flex">
            <div class="col-md-12 d-flex align-content-center">
              <div class="col-md-6 d-flex">
                <div class="col-md-3 text-nowrap active-SubHeading">
                  <h5 v-if="statusName === 'New' && !newProjects">New Projects</h5>
                  <h5 v-else-if="statusName === 'In Progress'">Active Projects</h5>
                  <h5 v-else>{{ statusName }} Projects</h5>
                </div>

                <div class="col-md-2">
                  <img
                    v-if="statusName === 'New'"
                    src="../../assets/icons/blueAddicon.png"
                    alt=""
                    data-bs-toggle="modal"
                    data-bs-target="#CreateProjectModal"
                    data-bs-whatever="@mdo"
                    id="addicon"
                    style="cursor: pointer"
                  />
                </div>
              </div>
              <div class="col-md-6 d-flex align-items-center">
                <div class="col-md-3 ImportExport" style="visibility: hidden">
                  <div class="row">
                    <div class="col-md-4 tableTextEvenRow">Import</div>
                    <div class="col-md-3 tableTextEvenRow">
                      <img src="../../assets/icons/import.png" alt="" id="ImportIcon" />
                    </div>
                  </div>
                </div>
                <div class="col-md-3 ImportExport" style="visibility: hidden">
                  <div class="row">
                    <div class="col-md-4 tableTextEvenRow">Export</div>
                    <div class="col-md-3 tableTextEvenRow">
                      <img src="../../assets/icons/export.png" alt="" id="ImportIcon" />
                    </div>
                  </div>
                </div>

                <div class="col-md-1" style="visibility: hidden">
                  <img
                    src="../../assets/icons/archive.png"
                    alt=""
                    id="ArchiveIcon"
                    @click="handleArchiveClick"
                  />
                </div>
                <div class="col-md-4 searchboxOfTable">
                  <div class="search-box">
                    <img
                      src="../../assets/icons/search.png"
                      alt="Search Icon"
                      id="SerchIconOfTable"
                    />
                    <input
                      type="text"
                      name="search"
                      id=""
                      v-model="searchString"
                      @input="searchProcurementProjects"
                    />
                  </div>
                  <span>
                    <img
                      src="../../assets/icons/filter.png"
                      alt="Search Icon"
                      id="FilterIconOfTable"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="table-wrapper">
            <table class="tableFormat">
              <caption class="d-none">
                Table
              </caption>
              <thead>
                <tr>
                  <th>Project Name</th>

                  <th>Customer Name</th>
                  <th v-if="statusName === 'New'">Customer ID</th>
                  <th>Contact No</th>
                  <th v-if="statusName === 'New'">Email ID</th>
                  <th>Property Address</th>
                  <th>Sales Person</th>
                  <th>Designer</th>
                  <th v-if="statusName === 'In Progress' || statusName === 'Completed'">
                    Assignee
                  </th>
                  <th v-if="statusName === 'New'">Action</th>

                  <th v-if="statusName === 'In Progress' || statusName === 'Completed'">
                    Start Date
                  </th>
                  <th v-if="statusName === 'Completed'">End Date</th>
                  <th v-if="statusName === 'In Progress' || statusName === 'Completed'">
                    Status
                  </th>

                  <th
                    class="text-center"
                    v-if="statusName === 'In Progress' || statusName === 'Completed'"
                  >
                    Sign off Document
                  </th>
                </tr>
              </thead>

              <tbody>
                <template v-if="cardLoading">
                  <TableSkeletonLoader :columns="10" />
                </template>
                <template v-else>
                  <tr v-for="(data, index) in displayedData" :key="index">
                    <td @click="navigateToProjectProfile(data)">
                      {{ data["projects.projectName"] }}
                    </td>
                    <td class="ellipsis" :title="data['customers.name']">
                      {{ data["customers.name"] }}
                    </td>
                    <td v-if="statusName === 'New'">
                      {{ data["customers.customerNumber"] }}
                    </td>
                    <td>{{ data["customers.contactNo"] }}</td>
                    <td v-if="statusName === 'New'">{{ data["customers.email"] }}</td>
                    <td class="ellipsis" :title="data['addresses.address']">
                      {{ data["addresses.address"] }}{{ data["addresses.city"] }}
                    </td>
                    <td>
                      {{ data["projects.salesPerson.firstName"] }}
                      {{ data["projects.salesPerson.lastName"] }}
                    </td>
                    <td>
                      {{ data["projects.designers.firstName"] }}
                      {{ data["projects.designers.lastName"] }}
                    </td>

                    <td v-if="statusName === 'In Progress' || statusName === 'Completed'">
                      {{ data["assignee.firstName"] }}
                      {{ data["assignee.lastName"] }}
                    </td>
                    <td v-if="statusName === 'New'">
                      <button
                        @click="acceptProject(data.id)"
                        type="btn"
                        class="btn btn-sm btn-outline-success"
                        data-bs-toggle="modal"
                        data-bs-target="#createProjectModal"
                        style="cursor: pointer"
                      >
                        Accept
                      </button>
                    </td>

                    <td v-if="statusName === 'In Progress' || statusName === 'Completed'">
                      {{ this.$formatDateDMY(data.startDate) }}
                    </td>
                    <td v-if="statusName === 'Completed'">
                      {{ this.$formatDateDMY(data.endDate) }}
                    </td>
                    <td
                      v-if="statusName === 'In Progress' || statusName === 'Completed'"
                      style="cursor: pointer"
                    >
                      <div
                        class="d-flex align-content-center justify-content-left text-nowrap progressImage"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          v-if="data.status === 'Completed'"
                          src="../../assets/icons/completed.png"
                          alt="Completed"
                        />
                        <img
                          v-else-if="data.status === 'On Hold'"
                          src="../../assets/icons/onHold.png"
                          alt="On Hold"
                        />
                        <img
                          v-else-if="data.status === 'In Progress'"
                          src="../../assets/icons/inProgress.png"
                          alt="In Progress"
                        />

                        <h6
                          class="bodyText mt-1"
                          id="dropdownMenuButton"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {{ data.status }}
                        </h6>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          <button
                            class="dropdown-item"
                            @click="changeStatusofProject(data.id, 'In Progress', index)"
                          >
                            In Progress
                          </button>
                          <button
                            class="dropdown-item"
                            @click="changeStatusofProject(data.id, 'Completed', index)"
                          >
                            Completed
                          </button>
                        </div>
                      </div>
                    </td>

                    <td
                      class="text-center"
                      v-if="statusName === 'In Progress' || statusName === 'Completed'"
                    >
                      <img
                        class="ms-3"
                        src="../../assets/icons/signOffDocumentFolder.png"
                        alt=""
                        style="width: 17px; height: 17px"
                        id="dropdownSignOffDocumentButton"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      />
                      <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownSignOffDocumentButton"
                        stle="box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);"
                      >
                        <div
                          v-for="(folder, index) in data.signOffDocuments"
                          :key="index"
                          class="d-flex align-items-center justify-content-between"
                          style="padding: 5px 15px"
                        >
                          <!-- Text section with ellipsis -->
                          <div
                            class="text-start mt-1"
                            style="
                              font-size: 14px;
                              flex: 1;
                              overflow: hidden;
                              text-overflow: ellipsis;
                              white-space: nowrap;
                            "
                          >
                            {{ folder["files.awsFiles.fileName"] }}
                          </div>

                          <!-- Icons section -->
                          <div class="text-end ms-3 d-flex">
                            <img
                              src="../../assets/icons/pinkDownold.png"
                              alt="Download"
                              style="width: 17px; height: 17px; cursor: pointer"
                              @click="
                                $downloadFile(
                                  folder['files.awsFiles.awsFileName'],
                                  folder['files.awsFiles.fileName'],
                                  'signOffDocument',
                                  'design'
                                )
                              "
                            />
                            <img
                              class="ms-2"
                              src="../../assets/icons/view.png"
                              alt="View"
                              style="width: 17px; height: 17px; cursor: pointer"
                              @click="
                                $viewFile(
                                  folder['files.awsFiles.awsFileName'],
                                  folder['files.awsFiles.fileName'],
                                  'signOffDocument',
                                  'design'
                                )
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>

          <div class="paginationOfTable">
            <div class="pageControlsTable">
              <img
                src="../../assets/icons/blueLeftarrow.png"
                @click="changePage(-1)"
                :disabled="currentPage === 1"
                alt=""
              />
              <div class="page-numbers">
                <span
                  v-for="pageNumber in pagination"
                  :key="pageNumber"
                  :class="{
                    'page-number': true,
                    'current-page': pageNumber === currentPage,
                  }"
                  @click="goToPage(pageNumber)"
                  >{{ pageNumber }}</span
                >
              </div>

              <img
                src="../../assets/icons/blueRightarrow.png"
                @click="changePage(1)"
                :disabled="currentPage === pagination.length"
                alt=""
              />
            </div>

            <div class="entries-info">
              <span class="entries-text">Entries: {{ displayedData.length }} Rows</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreateProjectModal from "../Modal/CreateProjectModal.vue";
import axios from "axios";
import Header from "../Header.vue";
import Nav from "../Nav.vue";
import CreateDesigProjectModal from "../Modal/CreateDesignProjectModal.vue";
export default {
  name: "OnSiteTableByProjectStatus",
  components: {
    Header,
    Nav,
    CreateDesigProjectModal,
    CreateProjectModal,
  },
  data() {
    return {
      cardLoading: true,
      selectedEmployeeId: null,
      searchString: "",
      procurementprojectDropdownStates: {},
      tabledropdownEdits: false,
      showClaimedList: false,
      selectAll: false,
      TableData: [],
      ArchiveData: [],
      selectedProcurementProjects: [],
      pageSize: 50,
      currentPage: 1,
      showUnclaimedList: true,
      showArchiveList: false,
      selectedEmployees: [],
      searchResults: [],
      procurementprojectTypePage: true,
      dropdownOpen: false,
      statusDropdownOpen: false,
      selectedProcurementProjectId: null,
      statusName: "",
      selectedProjectId: null, // New data property for the selected project ID
    };
  },
  computed: {
    hasPreviousPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      return this.currentPage < Math.ceil(this.TableData.length / this.pageSize);
    },
    hasArchivePreviousPage() {
      return this.currentPage > 1;
    },
    hasArchiveNextPage() {
      return this.currentPage < Math.ceil(this.ArchiveData.length / this.pageSize);
    },

    pagination() {
      const pageCount = Math.ceil(this.TableData.length / this.pageSize);
      const maxVisiblePages = 5;
      let pages = [];

      if (pageCount <= maxVisiblePages) {
        pages = Array.from({ length: pageCount }, (_, index) => index + 1);
      } else {
        const startPage = Math.max(2, this.currentPage - Math.floor(maxVisiblePages / 2));
        const endPage = Math.min(
          pageCount - 1,
          this.currentPage + Math.floor(maxVisiblePages / 2)
        );

        pages = [1];

        if (startPage > 2) {
          pages.push("...");
        }

        pages.push(
          ...Array.from(
            { length: endPage - startPage + 1 },
            (_, index) => startPage + index
          )
        );

        if (endPage < pageCount - 1) {
          pages.push("...");
        }

        pages.push(pageCount);
      }

      return pages;
    },

    paginationArchive() {
      const pageCount = Math.ceil(this.ArchiveData.length / this.pageSize);
      const maxVisiblePages = 5;
      let pages = [];

      if (pageCount <= maxVisiblePages) {
        pages = Array.from({ length: pageCount }, (_, index) => index + 1);
      } else {
        const startPage = Math.max(2, this.currentPage - Math.floor(maxVisiblePages / 2));
        const endPage = Math.min(
          pageCount - 1,
          this.currentPage + Math.floor(maxVisiblePages / 2)
        );

        pages = [1];

        if (startPage > 2) {
          pages.push("...");
        }

        pages.push(
          ...Array.from(
            { length: endPage - startPage + 1 },
            (_, index) => startPage + index
          )
        );

        if (endPage < pageCount - 1) {
          pages.push("...");
        }

        pages.push(pageCount);
      }

      return pages;
    },
    displayedData() {
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this.TableData.slice(startIndex, endIndex);
    },
    displayedArchiveData() {
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this.ArchiveData.slice(startIndex, endIndex);
    },
  },
  mounted() {
    this.statusName = this.$route.params.statusName;
    this.selectedEmployeeId = this.$route.params.selectedEmployeeId;
    this.fetchData();
  },
  methods: {
    async changeStatusofProject(ProjectId, Status, index) {
      this.TableData[index].status = Status;
      try {
        await axios.put(
          `${this.$apiEndPoint}/procurementProject/updateProject/${ProjectId}`,
          {
            status: Status,
          }
        );

        // Assuming you have a method to fetch source after status change
        this.fetchData();
      } catch (error) {
        this.$handleError(error);
      }
    },

    acceptProject(projectId) {
      this.selectedProjectId = projectId;
    },
    navigateToProjectProfile(data) {
      // Navigate to ProjectProfile page with project ID as parameter
      if (this.statusName !== "New" && this.statusName !== "Assigned") {
        this.$router.push({
          name: "ProcurementProjectProfile",
          params: {
            Id: data.id,
          },
        });
      } else {
        // Log a message for when navigation doesn't happen
        this.$toast(
          "Navigation is not allowed for 'New' or 'Assigned' statuses.",
          "error"
        );
      }
    },
    SigleProcurementProjectThingstd(procurementproject) {
      this.selectedProcurementProject = procurementproject;
      this.procurementprojectDropdownStates[procurementproject.id] = !this
        .procurementprojectDropdownStates[procurementproject.id];
    },
    toggletabledrowpdown() {
      this.tabledropdownEdits = !this.tabledropdownEdits;
    },
    openModal(procurementprojectId) {
      this.selectedProcurementProjectId = procurementprojectId;
    },
    handleArchiveClick() {
      this.fetchArchiveData();
      this.showUnclaimedList = !this.showUnclaimedList;
      this.showArchiveList = !this.showArchiveList;
    },
    async fetchData() {
      this.cardLoading = true;
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/procurementProject/${this.statusName}/${this.selectedEmployeeId}`
        );
        this.TableData = response.data;
        this.cardLoading = false;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchArchiveData() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/procurementProject/${this.statusName}/${this.selectedEmployeeId}/archived`
        );
        this.ArchiveData = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    //select and unselect checkbox function
    selectAllRows() {
      if (this.selectAll) {
        this.selectedProcurementProjects = this.displayedData.map(
          (customer) => customer.id
        );
      } else {
        this.selectedProcurementProjects = [];
      }
    },
    selectAllArchiveRows() {
      if (this.selectAll) {
        this.selectedProcurementProjects = this.displayedArchiveData.map(
          (customer) => customer.id
        );
      } else {
        this.selectedProcurementProjects = [];
      }
    },

    // Method to delete the selected procurementproject
    async DeleteProcurementProject(data) {
      const result = await this.$showAlert(
        "",
        "Are you sure you want to delete this project?",
        "warning",
        "Yes, delete it",
        "Cancel"
      );

      // If the user clicked Cancel, don't proceed
      if (!result.isConfirmed) return;
      axios
        .delete(`${this.$apiEndPoint}/customer/deleteCustomer`, {
          data: { id: [data.id] },
        })
        // Pass an array with a single ID to match the API's expected format
        .then(() => {
          this.fetchData();
          this.fetchArchiveData();
          this.procurementprojectDropdownStates[data.id] = false; // Hide dropdown after action
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    ArchiveProcurementProject(data) {
      axios
        .put(`${this.$apiEndPoint}/customer/archive`, { id: [data.id] })
        // Pass an array with a single ID to match the API's expected format
        .then(() => {
          this.fetchData();
          this.fetchArchiveData();
          this.procurementprojectDropdownStates[data.id] = false; // Hide dropdown after action
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    searchArchiveProcurementProjects() {
      let searchData = {
        searchString: this.searchString,
      };

      axios
        .post(
          `${this.$apiEndPoint}/procurementProject/${this.statusName}/archived`,
          searchData
        )

        .then((response) => {
          this.ArchiveData = response.data; // Ensure it's an array or provide default

          const startIndex = (this.currentPage - 1) * this.pageSize;
          this.displayedData = this.ArchiveData.slice(
            startIndex,
            startIndex + this.pageSize
          );
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    //for Serch api for Unclaimprocurementprojects
    searchProcurementProjects() {
      let searchData = {
        searchString: this.searchString,
      };

      axios
        .post(`${this.$apiEndPoint}/procurementProject/${this.statusName}`, searchData)

        .then((response) => {
          this.TableData = response.data; // Ensure it's an array or provide default

          const startIndex = (this.currentPage - 1) * this.pageSize;
          this.displayedData = this.TableData.slice(
            startIndex,
            startIndex + this.pageSize
          );
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    //for all selected procurementproject Unclaim and delete api

    ArchiveProcurementProjects() {
      if (this.selectedProcurementProjects.length === 0) {
        return;
      }

      const payload = {
        id: this.selectedProcurementProjects,
      };

      axios
        .put(`${this.$apiEndPoint}/customers/archive`, payload)
        .then(() => {
          this.selectedProcurementProjects = [];
          this.fetchData();
          this.fetchArchiveData();
          this.tabledropdownEdits = false;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    async deleteProcurementProject() {
      if (this.selectedProcurementProjects.length === 0) {
        return;
      }
      const result = await this.$showAlert(
        "",
        "Are you sure you want to delete this project?",
        "warning",
        "Yes, delete it",
        "Cancel"
      );

      // If the user clicked Cancel, don't proceed
      if (!result.isConfirmed) return;
      const payload = {
        id: this.selectedProcurementProjects,
      };

      axios
        .delete(`${this.$apiEndPoint}/customer/deleteCustomer`, { data: payload })
        .then(() => {
          this.selectedProcurementProjects = [];
          this.fetchData();
          this.fetchArchiveData();
          this.tabledropdownEdits = false;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    //footer pagination
    goToPage(pageNumber) {
      this.currentPage = pageNumber;
    },
    changePage(delta) {
      this.currentPage += delta;
    },
    //featch all Unclaim procurementproject Data

    formatDate(dateString) {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear().toString();
      return `${day}/${month}/${year}`;
    },
    createEstimate(selectedProcurementProject) {
      this.$router.push({
        name: "CreateEstimate",
        params: {
          Clientname: selectedProcurementProject.name,
          PropertyId: selectedProcurementProject.propertyId,
          ContactNo: selectedProcurementProject.contactNo,
          Email: selectedProcurementProject.email,
          procurementprojectId: selectedProcurementProject.id,
          address: selectedProcurementProject.address,
          propertyName: selectedProcurementProject["property.name"],
        },
      });
    },

    toggleStatusDropdown() {
      this.statusDropdownOpen = !this.statusDropdownOpen;
    },
    changeStatus(Id, status) {
      this.selectedStatus = status;

      this.statusDropdownOpen = false;
      this.dropdownOpen = true;
    },
  },
};
</script>

<style scoped>
.FormatTable {
  height: calc(100% - 74px);
  width: calc(100% - 74px);
  table-layout: fixed;
  position: fixed;
  display: flex;
  flex-direction: column;
}

.tableFormat:nth-child(2) {
  cursor: pointer;
}

.headerOfTable {
  width: calc(100% - 74px);
  padding: 0.5% 1%;
}

.table-wrapper {
  margin-left: 1%;
  margin-right: 1%;
  height: calc(97% - 74px);
  width: calc(100% - 24px);
  overflow-x: auto;
  overflow-y: auto;
  padding-bottom: 20px;
}

.tableFormat {
  width: 100%;
  border-collapse: collapse;
}

.tableFormat thead {
  background-color: #bcbfc6;
  position: sticky;
  z-index: 99;
  top: 0;
}

.tableFormat thead tr th {
  background-color: #bcbfc6;
}
.tableFormat th,
.tableFormat td {
  padding: 8px 16px;
  text-align: left;
  white-space: nowrap; /* Prevent text wrapping */
}

#blackThreeDots {
  width: 15px;
  height: 14px;
  margin-left: 30%;
  position: relative;
  cursor: pointer;
  margin-bottom: 5px;
}

.tableHeaderCheckbox,
.bodyCheckbox {
  width: 200px; /* Adjust width as needed */
}

.tableHeaderHeading {
  min-width: 150px; /* Minimum width to ensure readability */
  text-align: center; /* Center align headers */
}

.bodyText {
  text-align: center; /* Center align body text */
}

.custom-checkbox input[type="checkbox"] {
  margin-right: 5px;
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
}
.table-wrapper .tableFormat tbody tr:not(:first-child):nth-child(even) {
  background-color: #f8f8f8;
}
.ImportExport {
  visibility: hidden;
}

#addicon {
  width: 25px;
  height: 25px;
}

#ImportIcon {
  width: 20px;
  cursor: pointer;
}

#ArchiveIcon {
  width: 15px;
  cursor: pointer;
}

#SerchIconOfTable {
  width: 6%;
  margin-right: 5%;
}

.search-box {
  border-bottom: 2px solid #000;
  padding-bottom: 3px;
  display: flex;
  align-items: center;
}

.searchboxOfTable {
  width: 100%;
  display: flex;
  align-items: center;
}
.tableFormat tbody img {
  width: 12px;
  height: 12px;
  margin: 7px 5px 2px 0;
}

input[type="text"] {
  border: none;
  outline: none;
  font-size: 16px;
  width: 100%;
  text-align: left;
}
.progressImage img {
  width: 10px;
  height: 10px;
}
#FilterIconOfTable {
  width: 20px;
  height: 15px;
  margin-left: 10%;
}

@media screen and (max-width: 768px) {
  .tableFormat th,
  .tableFormat td {
    font-size: 12px; /* Adjust font size for smaller screens */
  }

  .tableHeaderCheckbox,
  .bodyCheckbox {
    width: 150px; /* Adjust width for smaller screens */
  }

  .table-wrapper {
    max-height: 90%;
    /* Adjust as needed */
    overflow-y: auto;
  }

  .headerOfTable {
    width: 100%;
    overflow: scroll;
  }

  #ArchiveIcon {
    width: 30%;
  }

  .ImportExport {
    display: none;
  }

  .paginationOfTable {
    bottom: 0.5%;
  }
}
</style>
