import Home from "./components/Home.vue";
import SignUp from "./components/SignUp.vue";
import EmployeeSignUp from "./components/EmployeeSignUp.vue";
import Login from "./components/Login.vue";
import UpdatePassword from "./components/UpdatePassword.vue";
import Calendar from "./components/Calendar/Calendar.vue";
import Month from "./components/Calendar/Month.vue";
import Day from "./components/Calendar/Day.vue";
import Template from "./components/Template.vue";
import UserInbox from "./components/UserInbox.vue";
import Mytask from "./components/Task/mytask.vue";
import taskSidePanel from "./components/Modal/taskSidePanel.vue";
import EmployeeList from "./components/EmployeeRegistration/EmployeeList.vue";
import AccessKeys from "./components/AccessKeys/AccessKeys.vue";
import Settings from "./components/Sales/Settings.vue";

//All Module
import MyWorkHub from "./components/HR/MyWorkHub.vue";
import MailSentModal from "./components/Modal/MailSentModal.vue";
import CompanyDocuments from "./components/CompanyDocuments";
import GlobalDashboard from "./components/GlobalDashboard.vue";
import AddAddressesModal from "./components/Modal/AddAddressesModal.vue";
import CreateProjectModal from "./components/Modal/CreateProjectModal.vue";
import TableDetailsModal from "./components/Modal/TableDetailsModal.vue";
import TableSkeletonLoader from "./components/TableSkeletonLoader.vue";
import ShareWithProjectModal from "./components/Modal/ShareWithProjectModal.vue";
import CreateReminderModal from "./components/Modal/CreateReminderModal.vue";
import CreateTaskModal from "./components/Modal/CreateTaskModal.vue";
import CreateEventModal from "./components/Modal/CreateEventModal.vue";
import MyTaskModal from "./components/Modal/MyTaskModal.vue";
import CreateEmployeeModal from "./components/Modal/CreateEmployeeModal.vue";
import EmployeeProfileModal from "./components/Modal/EmployeeProfileModal.vue";
import EmployeeProfile from "./components/EmployeeProfile.vue";
import ProjectActivityModal from "./components/Modal/ProjectActivityModal.vue";
import AccessDenied from "./components/AccessDenied.vue";
import NotificationModal from "./components/Modal/NotificationModal.vue";
import CreateTicket from "./components/Help/CreateTicket.vue";
import TicketList from "./components/Help/TicketList.vue";
import TicketById from "./components/Help/TicketById.vue";
import FaqAccordion from "./components/Help/FaqAccordion.vue";
import ArivuChat from "./components/Help/ArivuChat.vue"
import PaymentPage from "./components/Payment/PaymentPage.vue";
import subscriptionPage from "./components/Payment/subscriptionPage.vue";
import EmployeePerformance from "./components/HR/EmployeePerformance.vue";

//Sales Module
import SalesDashboard from "./components/Sales/SalesDashboard.vue";
import SalesSource from "./components/Sales/SalesSource.vue";
import integration from "./components/Sales/integration.vue";
import NewNoteModal from "./components/Modal/NewNoteModal.vue";
import SourceCreate from "./components/Modal/SourceCreate.vue";
import SourceById from "./components/Sales/Source/SourceById.vue";
import SalesTarget from "./components/Sales/SalesTarget.vue";
import SalesTargetModal from "./components/Modal/SalesTargetModal";
import MyLead from "./components/Sales/Lead/MyLead.vue";
import CreateLeadModal from "./components/Modal/CreateLeadModal";
import LeadDetailsModal from "./components/Modal/LeadDetailsModal.vue";
import BulkCreateLeadModal from "./components/Modal/BulkCreateLeadModal.vue";
import LeadFilterModal from "./components/Modal/LeadFilterModal.vue";
import EstimateList from "./components/Sales/Estimate/EstimateList.vue";
import CreateEstimate from "./components/Sales/Estimate/CreateEstimate.vue";
import FinalEstimate from "./components/Sales/Estimate/FinalEstimate.vue";
import EstimateApproval from "./components/Sales/Estimate/EstimateApproval.vue";
import PrintEstimate from "./components/Sales/PrintEstimate.vue";
import CreateSalesOrder from "./components/Sales/SalesOrder/CreateSalesOrder.vue";
import SalesOrderList from "./components/Sales/SalesOrder/SalesOrderList.vue";
import CustomerCreateModal from "./components/Modal/CustomerCreateModal.vue";
import CustomerDetailsModal from "./components/Modal/CustomerDetailsModal.vue";
import Customer from "./components/Sales/Customer.vue";
import ProjectListByStatus from "./components/Sales/ProjectListByStatus.vue";
import TotalSales from './components/Sales/TotalSales.vue'
import UploadEstimateModal from "./components/Modal/UploadEstimateModal.vue";

// Design Module
import DesignDashboard from "./components/Design/DesignDashboard.vue";
import DesignProjectProfile from "./components/Design/DesignProjectProfile.vue";
import DesignProjectTable from "./components/Design/DesignProjectTable.vue";
import DesignProjectTableByStatus from "./components/Design/DesignProjectTableByStatus.vue";
import CreateDesignProjectModal from "./components/Modal/CreateDesignProjectModal.vue";
import DesignProjectListBoard from "./components/Design/DesignProjectListBoard.vue";
import DeleteDesignProjectModal from "./components/Modal/DeleteDesignProjectModal.vue";
import AddDesignActivityModal from "./components/Modal/AddDesignActivityModal.vue";
import DesignProjectInfoModal from "./components/Modal/DesignProjectInfoModal";
import UpdateDesignProjectModal from "./components/Modal/UpdateDesignProjectModal.vue";
import signOffApproval from "./components/Design/signOffApproval";
import PibShareModal from "./components/Modal/PibShareModal.vue";
import sharepib from "./components/Design/sharepib.vue";
import CommentModal from "./components/Modal/CommentModal.vue";
import HistoryModal from "./components/Modal/HistoryModal.vue";

//Onsite Module
import OnsiteDashboard from "./components/Onsite/OnsiteDashboard";
import OnSiteTableByProjectStatus from "./components/Onsite/OnSiteTableByProjectStatus.vue";
import OnSiteProjectProfile from "./components/Onsite/OnSiteProjectProfile";
import CreateContractor from "./components/Onsite/Contractor/CreateContractor.vue";
import ContractorsList from "./components/Onsite/Contractor/ContractorsList.vue";
import UpdateContractor from "./components/Onsite/Contractor/UpdateContractor.vue";
import AddOnSiteCategoryModal from "./components/Modal/AddOnSiteCategoryModal.vue";
import OnsiteContractorDetailsModal from "./components/Modal/OnsiteContractorDetailsModal.vue";
import PettyCashOnsite from "./components/Onsite/PettyCashOnsite.vue";
import AddOnSitePettyCashModal from "./components/Modal/AddOnSitePettyCashModal.vue";
import OnsiteEntriesModal from "./components/Modal/OnsiteEntriesModal";
import OnSiteOutwordEntriesModal from "./components/Modal/OnSiteOutwordEntriesModal.vue";
import OnSiteStockConsumptionModal from "./components/Modal/OnSiteStockConsumptionModal.vue";
import OnSiteGrnDetailsModal from "./components/Modal/OnSiteGrnDetailsModal.vue";
import InwardEntriesDetailsModal from "./components/Modal/InwardEntriesDetailsModal.vue";
import PettyCashReportModal from "./components/Modal/PettyCashReportModal.vue";
import CreateScopeOfWork from "./components/Modal/CreateScopeOfWork.vue";
import AddQuotationModal from "./components/Modal/AddQuotationModal.vue";
import AddContractorModal from "./components/Modal/AddContractorModal.vue";
import AddMaterialModal from "./components/Modal/AddMaterialModal.vue";

//Procurement Module
import ProcurementDashboard from "./components/Procurement/ProcurementDashboard.vue";
import ProcurementVendorList from "./components/Procurement/ProcurementVendor/ProcurementVendorList.vue";
import ProcurementVendorDetails from "./components/Procurement/ProcurementVendor/ProcurementVendorDetails.vue";
import PurchaseRequestList from "./components/Procurement/PurchaseRequest/PurchaseRequestList.vue";
import PurchaseOrderList from "./components/Procurement/PurchaseOrder/PurchaseOrderList.vue";
import PurchaseInvoiceList from "./components/Procurement/PurchaseInvoice/PurchaseInvoiceList.vue";
import AddTicket from "./components/Procurement/procurementTickets/addTicket.vue";
import PurchaseRequestDetails from "./components/Procurement/PurchaseRequest/PurchaseRequestDetails.vue";
import ProcurementProjectProfile from "./components/Procurement/ProcurementProjectProfile.vue";
import ProcurementProductList from "./components/Modal/ProcurementProductList.vue";
import PRDetailsModal from "./components/Modal/PRDetailsModal.vue";
import PODetailsModal from "./components/Modal/PODetailsModal.vue";
import ProcurementProjectTableByStatus from "./components/Procurement/ProcurementProjectTableByStatus.vue";
import CreatePurchaseOrder from "./components/Procurement/PurchaseOrder/CreatePurchaseOrder.vue";
import ProcurementDebitNotes from "./components/Procurement/ProcurementDebitNotes.vue";
import ProductMaster from "./components/Procurement/ProductMaster.vue";
import ProcurementTransitList from "./components/Procurement/procurementTickets/ProcurementTransitList.vue";
import CreateDebitNote from "./components/Procurement/CreateDebitNote.vue";
import PurchaseReport from "./components/Procurement/PurchaseReport.vue";
import ProcurementTicketDetails from "./components/Modal/ProcurementTicketDetails.vue";
import DebitNoteDetailsModal from "./components/Modal/DebitNoteDetailsModal.vue";
import UpdatePIModal from "./components/Modal/UpdatePIModal.vue";
import CreatePurchaseInvoice from "./components/Modal/CreatePurchaseInvoice.vue";

//Inventory Module
import InventoryDashboard from "./components/Inventory/InventoryDashboard.vue";
import GoodsRecievedNote from "./components/Inventory/GoodsRecievedNote.vue";
import Stores from "./components/Inventory/Stores.vue";
import GrnForm from "./components/Inventory/GrnForm.vue";
import StoresInwardOutwardEntry from "./components/Inventory/StoresInwardOutwardEntry.vue";
import StockLedger from "./components/Inventory/StoreLedger.vue";
import factoryStoreReport from "./components/Inventory/factoryStoreReport.vue";
import Report from "./components/Inventory/Report.vue";
import StoreItemsPdf from "./components/Modal/StoreItemsPdf.vue";
import financialYearStock from "./components/Modal/financialYearStock.vue";
import MasterLedger from "./components/Inventory/MasterLedger.vue";
import MasterLedgerProductReport from "./components/Inventory/MasterLedgerProductReport.vue";

// Notes Module
import Notes from "./components/Notes/Notes.vue";
import NotesModal from "./components/Modal/NotesModal.vue";
import ViewFolder from "./components/Notes/ViewFolder.vue";
import NotesPdfModal from "./components/Modal/NotesPdfModal.vue"

// Customer Ticket module
import AllTickets from "./components/CustomerTicket/AllTickets.vue";
import customerTicketModal from './components/Modal/customerTicketModal.vue'


import Attendance from "./components/HR/Attendance.vue";

import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    name: "Home",
    component: Home,
    path: "/:home?",
    meta: { title: "Home" },
  },
  {
    name: "SignUp",
    component: SignUp,
    path: "/sign-up",
    meta: { title: "Signup" },
  },
  {
    name: "EmployeeSignUp",
    component: EmployeeSignUp,
    path: "/EmployeeSignUp/:uuidToken",
    meta: { title: "SignUp" },
  },
  {
    name: "Login",
    component: Login,
    path: "/login",
    meta: { title: "Login" },
  },
  {
    name: "UpdatePassword",
    component: UpdatePassword,
    path: "/updatePassword/:UUID",
    meta: { title: "UpdatePassword" },
  },
  {
    name: "Calendar",
    component: Calendar,
    path: "/Calendar",
    meta: {
      title: "Calendar",
      teams: [
        "Admin",
        "Sales",
        "Design",
        "Procurement",
        "Onsite",
        "Inventory",
        "HR",
        "Accounts",
      ],
    },
  },
  {
    name: "Month",
    component: Month,
    path: "/Calendar/:year/:month/:selectedEmployeeId",
    meta: { title: "Calendar" },
    props: true,
  },
  {
    name: "Day",
    component: Day,
    meta: { title: "Calendar" },
    path: "/Calendar/:year/:month/:day/:selectedEmployeeId",
    props: true, // This allows route parameters to be passed as props
  },
  {
    name: "Template",
    component: Template,
    path: "/template",
    meta: { title: "Template" },
  },
  {
    name: "UserInbox",
    component: UserInbox,
    path: "/UserInbox",
    meta: {
      title: "Mail Box",
      teams: [
        "Admin",
        "Sales",
        "Design",
        "Procurement",
        "Onsite",
        "Inventory",
        "HR",
        "Accounts",
      ],
    },
  },
  {
    name: "Task",
    component: Mytask,
    path: "/myTask",
    meta: {
      title: "Task",
      teams: [
        "Admin",
        "Sales",
        "Design",
        "Procurement",
        "Onsite",
        "Inventory",
        "HR",
        "Accounts",
      ],
    },
  },

  {
    name: "taskSidePanel",
    component: taskSidePanel,
    path: "/taskSidepanel",
    mata: { title: "TaskSidePanel" },
  },

  {
    name: "EmployeeList",
    component: EmployeeList,
    path: "/employeeList",
    meta: { title: "Employee List", teams: ["Admin", "HR"] },
  },

  {
    name: "AccessKey",
    component: AccessKeys,
    path: "/AccessKey",
    meta: { title: "AccessKey" },
  },
  {
    name: "Settings",
    component: Settings,
    path: "/Settings",
    meta: { title: "Settings", teams: ["Admin", "Sales", "Onsite", "HR"] },
  },
  {
    name: "EmployeePerformance",
    component: EmployeePerformance,
    path: "/EmployeePerformance",
    meta: { title: "Performance Page" },
  },
  

  //All Module
  {
    name: "MyWorkHub",
    component: MyWorkHub,
    path: "/MyWorkHub",
    meta: { title: "MyWork Hub" },
  },
  {
    name: "MailSentModal",
    component: MailSentModal,
    path: "/MailSentModal",
    meta: { title: "MailSentModal" },
  },
  {
    name: "CompanyDocuments",
    component: CompanyDocuments,
    path: "/CompanyDocuments/:moduleName",
    meta: { title: "CompanyDocuments" },
  },
  {
    name: "GlobalDashboard",
    component: GlobalDashboard,
    path: "/GlobalDashboard/:DashboardType",
    meta: { title: "GlobalDashboard" },
  },
  {
    name: "AddAddressesModal",
    component: AddAddressesModal,
    path: "/AddAddressesModal",
    meta: { title: "AddAddressesModal" },
    props: true,
  },
  {
    name: "CreateProjectModal",
    component: CreateProjectModal,
    path: "/CreateProjectModal",
    meta: { title: "CreateProjectModal" },
    props: true,
  },
  {
    name: "TableDetailsModal",
    component: TableDetailsModal,
    path: "/TableDetailsModal",
    meta: { title: "TableDetailsModal" },
    props: true,
  },
  {
    name: "TableSkeletonLoader",
    component: TableSkeletonLoader,
    path: "/TableSkeletonLoader",
    meta: { title: "TableSkeletonLoader" },
  },
  {
    name: "ShareWithProjectModal",
    component: ShareWithProjectModal,
    path: "/ShareWithProjectModal",
    meta: { title: "ShareWithProjectModal" },
  },
  {
    name: "CreateReminderModal",
    component: CreateReminderModal,
    path: "/CreateReminderModal",
    meta: { title: "CreateReminderModal" },
  },
  {
    name: "CreateTaskModal",
    component: CreateTaskModal,
    path: "/CreateTaskModal",
    meta: { title: "CreateTaskModal" },
  },
  {
    name: "CreateEventModal",
    component: CreateEventModal,
    path: "/CreateEventModal",
    meta: { title: "CreateEventModal" },
  },
  {
    name: "MyTaskModal",
    component: MyTaskModal,
    path: "/MyTaskModal",
    meta: { title: "Tasks" },
  },
  {
    name: "CreateEmployeeModal",
    component: CreateEmployeeModal,
    path: "/CreateEmployeeModal",
    meta: { title: "CreateEmployeeModal" },
  },

  {
    name: "EmployeeProfileModal",
    component: EmployeeProfileModal,
    path: "/EmployeeProfileModal",
    meta: { title: "EmployeeProfileModal" },
  },
  {
    name: "EmployeeProfile",
    component: EmployeeProfile,
    path: "/EmployeeProfile/:employeeId",
    meta: { title: "EmployeeProfile" },
  },
  {
    name: "ProjectActivityModal",
    component: ProjectActivityModal,
    path: "/ProjectActivityModal",
    meta: { title: "ProjectActivityModal" },
  },
  
    {
   
      name: 'AccessDenied',
      path: '/AccessDenied',
      component: AccessDenied,
      meta: { title: "AccessDenied" },
    },
    {
    
      name: 'NotificationModal',
      path: '/NotificationModal',
      component: NotificationModal,
      meta: { title: "NotificationModal" },
    },
    {
      name: 'TicketList',
      path: '/TicketList',
      component: TicketList,
      meta: { title: "TicketList" },
    },
    {
      name: 'TicketById',
      path: '/TicketById/:Id',
      component: TicketById,
      meta: { title: "Ticket" },
    },
    {
      name: 'FaqAccordion',
      path: '/FaqAccordion',
      component: FaqAccordion,
      meta: { title: "FaqAccordion" },
      
    },
    {
      name: 'ArivuChat',
      path: '/ArivuChat',
      component: ArivuChat,
      meta: { title: "Arivu Chat" },
      
    },
    
  {
    path: "/NotificationModal",
    name: "NotificationModal",
    component: NotificationModal,
    meta: { title: "NotificationModal" },
  },

  //payment module
  {
    path: "/PaymentPage",
    name: "PaymentPage",
    component: PaymentPage,
    meta: { title: "Payment Page" },
    
  },

  {
    path: "/subscriptionPage",
    name: "subscriptionPage",
    component: subscriptionPage,
    meta: { title: "subscriptionPage" },
    
  },

  //Sales Module
  {
    name: "SalesDashboard",
    component: SalesDashboard,
    path: "/SalesDashboard",
    meta: { title: "Sales Dashboard" },
  },
  {
    name: "SalesSource",
    component: SalesSource,
    path: "/SalesSource",
    meta: { title: "Source", teams: ["Admin", "Sales"] },
  },
  {
    name: "integration",
    component: integration,
    path: "/integration",
    meta: { title: "integration" },
  },
  {
    name: "NewNoteModal",
    component: NewNoteModal,
    path: "/NewNoteModal",
    meta: { title: "NewNoteModal" },
  },
  {
    name: "SourceCreate",
    component: SourceCreate,
    path: "/SourceCreate",
    meta: { title: "SourceCreate" },
  },
  {
    name: "SourceById",
    component: SourceById,
    path: "/SourceById/:Id",
    meta: { title: "SourceById" },
  },
  {
    name: "SalesTarget",
    component: SalesTarget,
    path: "/SalesTarget",
    meta: { title: "Sales Target", teams: ["Admin", "Sales"] },
  },
  {
    name: "SalesTargetModal",
    component: SalesTargetModal,
    path: "/SalesTargetModal",
    meta: { title: "SalesTargetModal" },
  },
  {
    name: "MyLead",
    component: MyLead,
    path: "/MyLead",
    meta: { title: "My Lead", teams: ["Admin", "Sales"] },
  },
  {
    name: "CreateLeadModal",
    component: CreateLeadModal,
    path: "/CreateLeadModal",
    meta: { title: "CreateLeadModal" },
  },
  {
    name: "LeadDetailsModal",
    component: LeadDetailsModal,
    path: "/LeadDetailsModal",
    meta: { title: "LeadDetailsModal" },
  },
  {
    name: "BulkCreateLeadModal",
    component: BulkCreateLeadModal,
    path: "/BulkCreateLeadModal",
    meta: { title: "BulkCreateLeadModal" },
  },
  {
    name: "LeadFilterModal",
    component: LeadFilterModal,
    path: "/LeadFilterModal",
    meta: { title: "LeadFilterModal" },
    props: true,
  },

  {
    name: "EstimateList",
    component: EstimateList,
    path: "/EstimateList",
    meta: { title: "Estimate List", teams: ["Admin", "Sales"] },
  },
  {
    name: "CreateEstimate",
    component: CreateEstimate,
    path: "/CreateEstimate",
    meta: { title: "Create Estimate", teams: ["Admin", "Sales"] },
  },
  {
    name: "FinalEstimate",
    component: FinalEstimate,
    path: "/FinalEstimate/:estimateIndex",
    meta: { title: "FinalEstimates" },
  },
  {
    name: "EstimateApproval",
    component: EstimateApproval,
    path: "/EstimateApproval/:UUID",
    meta: { title: "EstimateApproval" },
  },
  {
    name: "PrintEstimate",
    component: PrintEstimate,
    path: "/PrintEstimate",
    meta: { title: "PrintEstimate" },
  },
  {
    name: "CreateSalesOrder",
    component: CreateSalesOrder,
    path: "/CreateSalesOrder/:SalesOrderId",
    meta: { title: "Create Sales Order" },
  },
  {
    name: "SalesOrderList",
    component: SalesOrderList,
    path: "/SalesOrderList",
    meta: { title: "Sales Order List", teams: ["Admin", "Sales"] },
  },
  {
    name: "CustomerCreateModal",
    component: CustomerCreateModal,
    path: "/CustomerCreateModal",
    meta: { title: "CustomerCreateModal" },
  },
  {
    name: "CustomerDetailsModal",
    component: CustomerDetailsModal,
    path: "/CustomerDetailsModal",
    meta: { title: "CustomerDetailsModal" },
  },
  {
    name: "Customer",
    component: Customer,
    path: "/Customer",
    meta: { title: "Customer", teams: ["Admin", "Sales"] },
  },
  {
    name: "ProjectListByStatus",
    component: ProjectListByStatus,
    path: "/ProjectListByStatus/:statusName/:selectedEmployeeId",
    meta: { title: "ProjectList" },
    props: true,
  },
  {
    name: "TotalSales",
    component: TotalSales,
    path: "/TotalSales",
    meta: { title: "Total Sales", teams: ["Admin", "Procurement"] },
    props: true,
  },
  {
    name: "UploadEstimateModal",
    component: UploadEstimateModal,
    path: "/UploadEstimateModal",
    meta: { title: "UploadEstimateModal" },
    props: true,
  },
  {
    name: "CreateTicket",
    component: CreateTicket,
    path: "/CreateTicket",
    meta: { title: "CreateTicket" },
    props: true,
  },


  


  // Design Module
  {
    name: "DesignDashboard",
    component: DesignDashboard,
    path: "/DesignDashboard",
    meta: { title: "Design Dashboard" },
  },

  {
    name: "DesignProjectProfile",
    component: DesignProjectProfile,
    path: "/DesignProjectProfile/:Id",
    meta: { title: "ProjectProfile" },
    props: true,
  },

  {
    name: "DesignProjectTable",
    component: DesignProjectTable,
    path: "/designProjectTable/:stageName/:selectedEmployeeId",
    meta: { title: "DesignProjectTable" },
    props: true,
  },
  {
    name: "DesignProjectTableByStatus",
    component: DesignProjectTableByStatus,
    path: "/DesignProjectTableByStatus/:statusName/:selectedEmployeeId",
    meta: { title: "DesignProjectTableByStatus" },
    props: true,
  },
  {
    name: "CreateDesignProjectModal",
    component: CreateDesignProjectModal,
    path: "/CreateDesignProjectModal",
    meta: { title: "CreateProject" },
  },
  {
    name: "DesignProjectListBoard",
    component: DesignProjectListBoard,
    path: "/DesignProjectListBoard",
    meta: { title: "Design Project List Board", teams: ["Admin", "Design"] },
  },

  {
    name: "DeleteDesignProjectModal",
    component: DeleteDesignProjectModal,
    path: "/DeleteDesignProjectModal",
    meta: { title: "DeleteDesignProjectModal" },
  },
  {
    name: "AddDesignActivityModal",
    component: AddDesignActivityModal,
    path: "/AddDesignActivityModal",
    meta: { title: "AddDesignActivityModal" },
  },

  {
    name: "DesignProjectInfoModal",
    component: DesignProjectInfoModal,
    path: "/DesignProjectInfoModal",
    meta: { title: "DesignProjectInfoModal" },
  },
  {
    name: "UpdateDesignProjectModal",
    component: UpdateDesignProjectModal,
    path: "/UpdateDesignProjectModal",
    meta: { title: "UpdateDesignProjectModal" },
  },
  {
    name: "signOffApproval",
    component: signOffApproval,
    path: "/signOffApproval/:UUID",
    meta: { title: "SignOffApproval" },
  },
  {
    name: "PibShareModal",
    component: PibShareModal,
    path: "/PibShareModal",
    meta: { title: "PibShareModal" },
    props: true,
  },
  {
    name: "sharepib",
    component: sharepib,
    path: "/sharepib/:uuidToken", // Capture UUID token in path
    meta: { title: "Arivu - Project InfoBox" },
    props: (route) => ({
      uuidToken: route.params.uuidToken,
      accessToken: route.query.accessToken, // Capture access token from query string
    }),
  },
  {
    name: "CommentModal",
    component: CommentModal,
    path: "/CommentModal",
    meta: { title: "CommentModal" },
    props: true,
  },
  {
    name: "HistoryModal",
    component: HistoryModal,
    path: "/HistoryModal",
    meta: { title: "HistoryModal" },
    props: true,
  },

  //Onsite Module

  {
    name: "OnsiteDashboard",
    component: OnsiteDashboard,
    path: "/OnsiteDashboard",
    meta: { title: "OnsiteDashboard" },
  },

  {
    name: "OnSiteTableByProjectStatus",
    component: OnSiteTableByProjectStatus,
    path: "/OnSiteTableByProjectStatus/:statusName",
    meta: { title: "OnSiteTableByProjectStatus" },
  },
  {
    name: "OnSiteProjectProfile",
    component: OnSiteProjectProfile,
    path: "/OnSiteProjectProfile/:Id",
    meta: { title: "OnSiteProjectProfile" },
    props: true,
  },
  {
    name: "CreateContractor",
    component: CreateContractor,
    path: "/CreateContractor",
    meta: { title: "Create Contractor", teams: ["Admin", "Onsite"] },
  },

  {
    name: "ContractorsList",
    component: ContractorsList,
    path: "/ContractorsList",
    meta: { title: "Contractors List", teams: ["Admin", "Onsite"] },
  },
  {
    name: "UpdateContractor",
    component: UpdateContractor,
    path: "/UpdateContractor/:contractorId",
    meta: { title: "UpdateContractor" },
  },
  {
    name: "AddOnSiteCategoryModal",
    component: AddOnSiteCategoryModal,
    path: "/AddOnSiteCategoryModal",
    meta: { title: "AddOnSiteCategoryModal" },
  },
  {
    name: "OnsiteContractorDetailsModal",
    component: OnsiteContractorDetailsModal,
    path: "/OnsiteContractorDetailsModal",
    meta: { title: "OnsiteContractorDetailsModal" },
  },
  {
    name: "PettyCashOnsite",
    component: PettyCashOnsite,
    path: "/PettyCashOnsite",
    meta: { title: "Pettycash Onsite", teams: ["Admin", "Onsite"] },
  },
  {
    name: "AddOnSitePettyCashModal",
    component: AddOnSitePettyCashModal,
    path: "/AddOnSitePettyCashModal",
    meta: { title: "AddOnSitePettyCashModal" },
  },
  {
    name: "OnsiteEntriesModal",
    component: OnsiteEntriesModal,
    path: "/OnsiteEntriesModal",
    meta: { title: "OnsiteEntriesModal" },
  },
  {
    name: "OnSiteOutwordEntriesModal",
    component: OnSiteOutwordEntriesModal,
    path: "/OnSiteOutwordEntriesModal",
    meta: { title: "OnSiteOutwordEntriesModal" },
  },
  {
    name: "OnSiteStockConsumptionModal",
    component: OnSiteStockConsumptionModal,
    path: "/OnSiteStockConsumptionModal",
    meta: { title: "OnSiteStockConsumptionModal" },
  },

  {
    name: "OnSiteGrnDetailsModal",
    component: OnSiteGrnDetailsModal,
    path: "/OnSiteGrnDetailsModal",
    meta: { title: "OnSiteGrnDetailsModal" },
  },

  {
    name: "InwardEntriesDetailsModal",
    component: InwardEntriesDetailsModal,
    path: "/InwardEntriesDetailsModal",
    meta: { title: "InwardEntriesDetailsModal" },
  },

  {
    name: "PettyCashReportModal",
    component: PettyCashReportModal,
    path: "/PettyCashReportModal",
    meta: { title: "PettyCashReportModal" },
  },
  {
    name: "CreateScopeOfWork",
    component: CreateScopeOfWork,
    path: "/CreateScopeOfWork",
    meta: { title: "CreateScopeOfWork" },
    props: true,
  },

  {
    name: "AddQuotationModal",
    component: AddQuotationModal,
    path: "/AddQuotationModal",
    meta: { title: "AddQuotationModal" },
  },

  {
    name: "AddContractorModal",
    component: AddContractorModal,
    path: "/AddContractorModal",
    meta: { title: "AddContractorModal" },
  },
  {
    name: "AddMaterialModal",
    component: AddMaterialModal,
    path: "/AddMaterialModal",
    meta: { title: "AddMaterialModal" },
  },

  //Procurement Module
  {
    name: "ProcurementDashboard",
    component: ProcurementDashboard,
    path: "/ProcurementDashboard",
    meta: { title: "Procurement Dashboard" },
  },
  {
    name: "ProcurementVendorList",
    component: ProcurementVendorList,
    path: "/ProcurementVendorList",
    meta: { title: "Vendor List", teams: ["Admin", "Procurement"] },
  },
  {
    name: "ProcurementVendorDetails",
    component: ProcurementVendorDetails,
    path: "/ProcurementVendorDetails/:vendorId?",
    meta: { title: "Vendor Details" },
  },
  {
    name: "PurchaseRequestList",
    component: PurchaseRequestList,
    path: "/PurchaseRequestList/:moduleName",
    meta: { title: "PurchaseRequestList"},
  },
  {
    name: "PurchaseOrderList",
    component: PurchaseOrderList,
    path: "/PurchaseOrderList/:moduleName",
    meta: { title: "PurchaseOrderList"  },
  },
  {
    name: "PurchaseInvoiceList",
    component: PurchaseInvoiceList,
    path: "/PurchaseInvoiceList",
    meta: { title: "Purchase Invoice List", teams: ["Admin", "Procurement"] },
  },
  {
    name: "AddTicket",
    component: AddTicket,
    path: "/AddTicket/:TicketId?",
    meta: { title: "AddTicket" },
  },
  {
    name: "PurchaseRequestDetails",
    component: PurchaseRequestDetails,
    path: "/PurchaseRequestDetails/:moduleName/:POId?",
    meta: { title: "PurchaseRequestDetails" },
  },
  {
    name: "ProcurementProjectProfile",
    component: ProcurementProjectProfile,
    path: "/procurementProjectProfile/:Id",
    meta: { title: "ProcurementProjectProfile" },
  },
  {
    name: "ProcurementProductList",
    component: ProcurementProductList,
    path: "/ProcurementProductList",
    meta: {
      title: "Procurement Product List",
      teams: ["Admin", "Procurement"],
    },
  },
  {
    name: "PRDetailsModal",
    component: PRDetailsModal,
    path: "/PRDetailsModal",
    meta: { title: "PRDetailsModal" },
  },
  {
    name: "PODetailsModal",
    component: PODetailsModal,
    path: "/PODetailsModal",
    meta: { title: "PODetailsModal" },
  },
  {
    name: "ProcurementProjectTableByStatus",
    component: ProcurementProjectTableByStatus,
    path: "/ProcurementProjectTableByStatus/:statusName/:selectedEmployeeId",
    meta: { title: "ProcurementProjectTableByStatus" },
    props: true,
  },
  {
    name: "CreatePurchaseOrder",
    component: CreatePurchaseOrder,
    path: "/CreatePurchaseOrder/:POId?",
    meta: { title: "Create Purchase Order", teams: ["Admin", "Procurement"] },
    props: true,
  },

  {
    name: "ProcurementDebitNotes",
    component: ProcurementDebitNotes,
    path: "/ProcurementDebitNotes",
    meta: { title: "Procurement Debit Notes", teams: ["Admin", "Procurement"] },
    props: true,
  },
  {
    name: "ProductMaster",
    component: ProductMaster,
    path: "/ProductMaster",
    meta: { title: "Product Master", teams: ["Admin", "Procurement"] },
    props: true,
  },
  {
    name: "ProcurementTransitList",
    component: ProcurementTransitList,
    path: "/ProcurementTransitList",
    meta: {
      title: "Procurement Transit List",
      teams: ["Admin", "Procurement"],
    },
    props: true,
  },

  {
    name: "CreateDebitNote",
    component: CreateDebitNote,
    path: "/CreateDebitNote",
    meta: { title: "Create Debitnote", teams: ["Admin", "Procurement"] },
    props: true,
  },
  {
    name: "PurchaseReport",
    component: PurchaseReport,
    path: "/PurchaseReport",
    meta: { title: "Purchase Report", teams: ["Admin", "Procurement"] },
    props: true,
  },
  {
    name: "ProcurementTicketDetails",
    component: ProcurementTicketDetails,
    path: "/ProcurementTicketDetails",
    meta: { title: "Procurement Ticket Details" },
    props: true,
  },
  {
    name: "DebitNoteDetailsModal",
    component: DebitNoteDetailsModal,
    path: "/DebitNoteDetailsModal",
    meta: { title: "DebitNoteDetailsModal" },
    props: true,
  },
  {
    name: "UpdatePIModal",
    component: UpdatePIModal,
    path: "/UpdatePIModal",
    meta: { title: "UpdatePIModal" },
    props: true,
  },

  {
    name: "CreatePurchaseInvoice",
    component: CreatePurchaseInvoice,
    path: "/CreatePurchaseInvoice",
    meta: { title: "CreatePurchaseInvoice" },
  },

  //Inventory Module
  {
    name: "InventoryDashboard",
    component: InventoryDashboard,
    path: "/InventoryDashboard",
    meta: { title: "InventoryDashboard" },
  },
  {
    name: "GoodsRecievedNote",
    component: GoodsRecievedNote,
    path: "/GoodsRecievedNote",
    meta: { title: "GoodsRecievedNote" },
  },

  {
    name: "GrnForm",
    component: GrnForm,
    path: "/GrnForm/:Id?",
    meta: { title: "Grn Form" },
    props: true,
  },

  {
    name: "Stores",
    component: Stores,
    path: "/stores",
    meta: { title: "Stores", teams: ["Admin", "Inventory"] },
  },

  {
    name: "StoresInwardOutwardEntry",
    component: StoresInwardOutwardEntry,
    path: "/StoresInwardOutwardEntry/:Id",
    meta: { title: "StoresInwardOutwardEntry" },
    props: true,
  },

  {
    name: "StockLedger",
    component: StockLedger,
    path: "/StockLedger",
    meta: { title: "Store Ledger", teams: ["Admin", "Inventory"] },
  },

  {
    name: "factoryStoreReport",
    component: factoryStoreReport,
    path: "/factoryStoreReport/:id/:storeName",
    meta: { title: "factoryStoreReport" },
  },

  {
    name: "MasterLedger",
    component: MasterLedger,
    path: "/MasterLedger",
    meta: { title: "MasterLedger" },
  },

  {
    name: "MasterLedgerProductReport", // Fixed typo
    component: MasterLedgerProductReport,
    path: "/MasterLedgerProductReport/:productId?", // Correct path structure
    meta: { title: "MasterLedgerProductReport" },
    props: true, // This ensures that the route parameter is passed as a prop to the component
  },

  {
    name: "Report",
    component: Report,
    path: "/Report/:storeId/:productId/:storeName/:productName",
    meta: { title: "Report" },
  },
  {
    name: "StoreItemsPdf",
    component: StoreItemsPdf,
    path: "/StoreItemsPdf",
    meta: { title: "StoreItemsPdf" },
  },
  {
    name: "financialYearStock",
    component: financialYearStock,
    path: "/financialYearStock",
    meta: { title: "financialYearStock" },
  },

  {
    name: "Attendance",
    component: Attendance,
    path: "/Attendance",
    meta: { title: "Attendance" },
  },
  {
    name: "TableSkeletonLoader",
    component: TableSkeletonLoader,
    path: "/TableSkeletonLoader",
    meta: { title: "TableSkeletonLoader" },
  },

  //Notes Module
  {
    name: "Notes",
    component: Notes,
    path: "/Notes",
    meta: { title: "Notes" },
  },

  {
    name: "NotesModal",
    component: NotesModal,
    path: "/NotesModal",
    meta: { title: "NotesModal" },
  },

  {
    name: "ViewFolder",
    component: ViewFolder,
    path: "/ViewFolder/:folderName/:Id",
    meta: { title: "ViewFolder" },
  },
  {
    name: "NotesPdfModal",
    component: NotesPdfModal,
    path: "/NotesPdfModal",
    meta: { title: "NotesPdfModal" },
  },

  //Customer Ticket 
  {
    name: "AllTickets",
    component: AllTickets,
    path:"/AllTickets",
    mata: {title: "AllTickets"},
  },

  {
    name: "customerTicketModal",
    component: customerTicketModal,
    path:"/customerTicketModal",
    mata: {title: "customerTicketModal"},
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "Default Title";
  next();


});

export default router;
