import Swal from 'sweetalert2';

export function showAlert(title, text, icon = 'info', confirmButtonText = 'OK', cancelButtonText = null, inputType = null) {
  return Swal.fire({
    title: title,
    html: inputType === "date" 
      ? `${text}<br><input type="date" id="custom-date-picker" class="swal2-input">` 
      : text,
    icon: icon,
    showCancelButton: !!cancelButtonText,
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText || '',
    preConfirm: () => {
      if (inputType === "date") {
        const dateValue = document.getElementById("custom-date-picker").value;
        if (!dateValue) {
          Swal.showValidationMessage("Please select a date.");
          return false;
        }
        return { value: dateValue };
      }
    }
  });
}
