<template>
  <div>
    <Header />
    <div class="content-Container">
      <Nav />
      <div class="containerChild">
        <div class="d-flex align-items-center justify-content-between px-3 pt-2 mt-0">
          <div class="d-flex align-items-center gap-3">
            <h5 style="font-weight: bold; padding: 18px">Stock Ledger</h5>
          </div>

          <div class="search-container">
            <span>
              <img src="../../assets/icons/search.png" class="search-img" alt="" />
            </span>
            <input
              type="text"
              class="search"
              placeholder="Search"
              style="width: 200px; border: none; border-radius: 0; font-size: small"
              v-model="searchString"
              @input="searchStores"
            />
          </div>
        </div>

        <div class="table-structure">
          <table class="custom-table">
            <caption class="d-none">
              Store Ledger
            </caption>
            <thead>
              <tr>
                <th>Sl/No.</th>
                <th>Store Name</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(store, index) in stores"
                :key="index"
                @click="goToStoreDetails(store.id, store.storeName)"
              >
                <td>{{ index + 1 }}</td>
                <td>{{ store.storeName }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Header from "../Header.vue";
import Nav from "../Nav.vue";

export default {
  data() {
    return {
      stores: [],
      searchString: "",
    };
  },

  mounted() {
    this.fetchStores();
  },

  components: {
    Header,
    Nav,
  },
  methods: {
    goToStoreDetails(storeId, storeName) {
      this.$router.push({
        name: "factoryStoreReport",
        params: { id: storeId, storeName: storeName },
      });
    },

    async fetchStores() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/store/getStores`);
        this.stores = response.data;
        console.log("fetched stores", this.stores);
      } catch (error) {
        console.error("Error fetching stores:", error);
      }
    },

    async searchStores() {
      try {
        const response = await axios.post(`${this.$apiEndPoint}/store/searchStores`, {
          searchString: this.searchString,
        });
        this.stores = response.data;
        console.log("Fetched stores:", this.stores);
      } catch (error) {
        console.error("Error fetching stores:", error);
      }
    },
  },
};
</script>

<style scoped>
.custom-table {
  width: 50%;
  border-collapse: collapse;
  border-radius: 10px;
}

.custom-table th,
.custom-table td {
  border-bottom: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.custom-table th {
  background-color: #bcbfc6; /* Header background color */
  padding: 8px;
  color: #212529;
  font-size: large;
  position: sticky; /* Makes the header sticky */
  top: 0; /* Sticks the header to the top of the table container */
  z-index: 2; /* Ensures the header stays above other content */
}

.custom-table td {
  font-size: 14px;
}

.custom-table tbody tr:nth-child(odd) {
  background-color: #ffff;
}

.custom-table tbody tr:nth-child(even) {
  background-color: #ece9e9;
}

.table-structure {
  padding: 0 1% 1% 2%;
  max-height: 540px; /* Limit the height for scrolling */
  overflow-y: auto; /* Adds vertical scrolling */
  overflow-x: hidden; /* Prevents horizontal scrolling if not needed */
}

.container-bg {
  background-color: #fcfcfc;
}

.add-icon {
  width: 6%;
  height: 6%;
  margin-top: 2%;
}

.search-img {
  width: 15px;
  height: 15px;
}

.search:focus {
  outline: none;
  border: none;
  border-bottom: 1px solid #141414;
  font-size: small;
}

.search-container {
  border-bottom: 2px solid black;
  display: flex;
  align-items: center;
}
</style>
