import axios from 'axios';
import { showToast } from "./toast";
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';


export async function downloadFile(AwsFileName, FileName, FileCategory, ModuleName, apiEndPoint) {
  showToast("Processing your request, please wait...", "info" );
  const payload = {
    awsFileName: AwsFileName,
    fileCategory: FileCategory,
    moduleName: ModuleName,
    fileName: FileName,
  };

  try {
    const response = await axios.post(`${apiEndPoint}/company/getFileSignedURL/`, payload);
    const fileUrl = Object.values(response.data)[0];

    if (fileUrl) {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", fileUrl);
      xhr.responseType = "blob";
      xhr.onload = function () {
        if (xhr.status === 200) {
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(xhr.response);
          link.download = FileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      };
      xhr.send();
    }
  } catch (error) {
    showToast("An error occurred. Please try again later.", "error");
    console.error("Error downloading file:", error);
  }
}


export async function viewFile(AwsFileName, FileName, FileCategory, ModuleName, apiEndPoint) {
  const payload = {
    awsFileName: AwsFileName,
    fileCategory: FileCategory,
    moduleName: ModuleName,
    fileName: FileName,
  };

  try {
    const response = await axios.post(`${apiEndPoint}/company/getFileSignedURL/`, payload);
    const fileUrl = Object.values(response.data)[0];

    if (fileUrl) {
      if (Capacitor.isNativePlatform()) {
        // Open in external browser for mobile apps
        await Browser.open({ url: fileUrl });
      } else {
        // Open in new tab for browsers
        window.open(fileUrl, "_blank");
      }
    }
  } catch (error) {
    console.error("Error viewing file:", error); 
  }
}

export function convertImageToBase64(imageUrl) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", imageUrl, true);
    xhr.responseType = "blob";
    xhr.onload = function () {
      if (xhr.status === 200) {
        const reader = new FileReader();
        reader.readAsDataURL(xhr.response);
        reader.onloadend = () => resolve(reader.result);
      } else {
        reject(new Error("Error fetching image"));
      }
    };
    xhr.onerror = () => reject(new Error("Network error"));
    xhr.send();
  });
}
