import { handleGlobalError } from '../util/errorHandler';
import { setAuthHeader, isExecutive, isManager,isTeam, logout } from '../util/authService';
import formatToIndianCurrency from '../util/currencyFormatter';
import { showToast } from '../util/toast';
import ApexCharts from 'apexcharts';
import { downloadFile, viewFile ,convertImageToBase64 } from '../util/fileUtils';
import { fetchCompanyData, fetchCompanyLogo } from '../util/companyService';
import formatDateDMY from '../util/formatDateDMY';

// Set authentication header
if (localStorage.jwtToken) {
  setAuthHeader(localStorage.jwtToken);
} else {
  setAuthHeader(false);
}

export default {
  install(app) {
    app.config.globalProperties.$toast = showToast;
    app.config.globalProperties.$apexcharts = ApexCharts;
    app.config.globalProperties.$handleError = (error) => handleGlobalError(error, showToast);
    app.config.globalProperties.$isExecutive = isExecutive;
    app.config.globalProperties.$isManager = isManager;
    app.config.globalProperties.$isTeam = isTeam;
    app.config.globalProperties.$formatToIndianCurrency = formatToIndianCurrency;
    app.config.globalProperties.$LocalUser = require('../util/UserObject').default;
    app.config.globalProperties.$logout = logout;
    app.config.globalProperties.$apiEndPoint = process.env.VUE_APP_BACKEND_URL;
    app.config.globalProperties.$formatDateDMY = formatDateDMY;

    // Register file handling methods
    app.config.globalProperties.$downloadFile = function (AwsFileName, FileName, FileCategory, ModuleName) {
      return downloadFile(AwsFileName, FileName, FileCategory, ModuleName, app.config.globalProperties.$apiEndPoint);
    };

    app.config.globalProperties.$viewFile = function (AwsFileName, FileName, FileCategory, ModuleName) {
      return viewFile(AwsFileName, FileName, FileCategory, ModuleName, app.config.globalProperties.$apiEndPoint);
    };

    app.config.globalProperties.$convertImageToBase64 = convertImageToBase64;

    // Fetch Company Data and Logo
    async function initializeCompanyData() {
      const user = app.config.globalProperties.$LocalUser;
      if (!user) return;

      const companyId = user.user.companyId;

      try {
        const companyData = await fetchCompanyData(app.config.globalProperties.$apiEndPoint, companyId);
        app.config.globalProperties.$CompanyData = companyData;

        const logoUrl = await fetchCompanyLogo(app.config.globalProperties.$apiEndPoint, companyId);
        if (logoUrl) {
          const base64Logo = await convertImageToBase64(logoUrl);
          app.config.globalProperties.$CompanyLogoUrl = base64Logo;
        }
      } catch (error) {
        console.error("Error initializing company data or logo:", error);
      }
    }

    // Initialize company data on app startup
    initializeCompanyData();
  },
};
