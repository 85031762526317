<template>
  <Header />
  <div class="content-Container">
    <Nav />
    <div class="containerChild">
      <div class="container-fluid my-2 col-md-12 showAddPurchaseInvoice">
        <div class="row mb-2 col-md-12 position-relative showAddPurchaseInvoiceHeader">
          <div class="col-md-11">
            <h4 v-if="this.TicketId">Update Ticket</h4>
            <h4 v-else>Add New Ticket</h4>
          </div>
        </div>

        <div class="row mx-1 mt-3">
          <div class="col-md-6">
            <div class="row mb-3">
              <label for="transportationDate" class="col-lg-5 col-form-label">
                Transportation Date:
              </label>
              <div class="col-lg-7">
                <input
                  type="date"
                  class="form-control inputBg w-100"
                  id="transportationDate"
                  v-model="ticketData.transportationDate"
                />
              </div>
            </div>
            <div class="row mb-3">
              <label for="documentType" class="col-lg-5 col-form-label">
                Document Type:
              </label>
              <div class="col-lg-7">
                <select
                  class="form-control form-select no-border inputBg"
                  id="category"
                  v-model="ticketData.documentType"
                >
                  <option>Tax Invoice</option>

                  <option>Delivery Challan</option>
                </select>
              </div>
            </div>
            <div class="row mb-3">
              <label for="documentNumber" class="col-lg-5 col-form-label">
                Document Number:
              </label>
              <div class="col-lg-7">
                <input
                  type="text"
                  class="form-control inputBg"
                  id="documentNumber"
                  v-model="ticketData.documentNumber"
                />
              </div>
            </div>
            <div class="row mb-3">
              <label for="noOfLabours" class="col-lg-5 col-form-label">
                No of Labours:
              </label>
              <div class="col-lg-7">
                <input
                  type="number"
                  class="form-control inputBg"
                  id="noOfLabours"
                  v-model="ticketData.noOfLabours"
                />
              </div>
            </div>
            <div class="row mb-3">
              <label for="totalAmount" class="col-lg-5 col-form-label">
                Total Amount:
              </label>
              <div class="col-lg-7">
                <input
                  type="number"
                  class="form-control inputBg"
                  id="totalAmount"
                  v-model="ticketData.totalAmount"
                  @input="userUpdatedTotal = true"
                />
              </div>
            </div>
            <div class="row mb-3">
              <label for="paidBy" class="col-lg-5 col-form-label"> Paid By: </label>
              <div class="col-lg-7">
                <select
                  v-model="ticketData.paidBy"
                  id="assignTo"
                  class="form-control form-select inputBg"
                >
                  <option value="" disabled>Select Payer</option>
                  <option
                    v-for="employee in employees"
                    :key="employee.employeeId"
                    :value="employee.employeeId"
                  >
                    {{ employee["employees.firstName"] }}
                    {{ employee["employees.lastName"] }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row mb-3">
              <label for="fromStoreId" class="col-lg-5 col-form-label">
                From Store:
              </label>
              <div class="col-lg-7">
                <select
                  class="form-control inputBg"
                  id="fromStoreId"
                  v-model="ticketData.fromStoreId"
                  @change="FillFromAddressDetails()"
                >
                  <option
                    v-for="address in Addresses"
                    :key="address.id"
                    :value="address.id"
                  >
                    {{ address.storeName }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row mb-3">
              <label for="fromAddressId" class="col-lg-5 col-form-label">
                Shipping From Store Address:
              </label>
              <div class="col-lg-7">
                <div class="card-body">
                  <div
                    class="form-control border-black"
                    style="height: 130px; max-height: auto"
                  >
                    {{ this.fromStore.name }}<br />
                    {{ this.fromStore.address }}<span v-if="this.fromStore.city">,</span>
                    {{ this.fromStore.city }}<br />
                    {{ this.fromStore.state }}<span v-if="this.fromStore.pincode">-</span
                    >{{ this.fromStore.pincode }}<br />
                    {{ this.fromStore.contactNo }}<br />
                    {{ this.fromStore.email }}<br />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="row mb-3">
              <label for="productDescription" class="col-lg-5 col-form-label">
                Product Description:
              </label>
              <div class="col-lg-7">
                <input
                  type="text"
                  class="form-control inputBg"
                  id="productDescription"
                  v-model="ticketData.productDescription"
                />
              </div>
            </div>
            <div class="row mb-3">
              <label for="vehicleNumber" class="col-lg-5 col-form-label">
                Vehicle Number:
              </label>
              <div class="col-lg-7">
                <input
                  type="text"
                  class="form-control inputBg"
                  id="vehicleNumber"
                  v-model="ticketData.vehicleNumber"
                />
              </div>
            </div>
            <div class="row mb-3">
              <label for="transportationAmount" class="col-lg-5 col-form-label">
                Transportation Amount:
              </label>
              <div class="col-lg-7">
                <input
                  type="number"
                  class="form-control inputBg"
                  id="transportationAmount"
                  v-model="ticketData.transportationAmount"
                />
              </div>
            </div>
            <div class="row mb-3">
              <label for="labourAmount" class="col-lg-5 col-form-label">
                Labour Amount:
              </label>
              <div class="col-lg-7">
                <input
                  type="number"
                  class="form-control inputBg"
                  id="labourAmount"
                  v-model="ticketData.labourAmount"
                />
              </div>
            </div>
            <div class="row mb-3">
              <label for="status" class="col-lg-5 col-form-label">
                Payment Status:
              </label>
              <div class="col-lg-7">
                <select
                  class="form-control form-select inputBg"
                  id="status"
                  v-model="ticketData.status"
                >
                  <option value="Payment Pending">Payment Pending</option>
                  <option value="Payment Done">Payment Done</option>
                </select>
              </div>
            </div>
            <div class="row mb-3">
              <label for="awsFileId" class="col-lg-5 col-form-label"> Receipt: </label>
              <div class="col-lg-7">
                <input
                  type="file"
                  class="form-control inputBg"
                  id="awsFileId"
                  @change="handleFileUpload"
                />
                <span
                  @click="
                    $viewFile(
                      ticketData['awsFiles.awsFileName'],
                      ticketData['awsFiles.fileName'],
                      'procurementTransit',
                      'procurement'
                    )
                  "
                  style="
                    font-size: 12px;
                    font-weight: 600;
                    margin-top: 2px;
                    cursor: pointer;
                  "
                  v-if="this.fileName"
                  >{{ this.fileName }}</span
                >
              </div>
            </div>
            <div class="row mb-3">
              <label for="toStoreId" class="col-lg-5 col-form-label"> To Store: </label>
              <div class="col-lg-7">
                <select
                  class="form-control inputBg"
                  id="toStoreId"
                  v-model="ticketData.toStoreId"
                  @change="FillToAddressDetails()"
                >
                  <option
                    v-for="address in Addresses"
                    :key="address.id"
                    :value="address.id"
                  >
                    {{ address.storeName }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row mb-3">
              <label for="toAddressId" class="col-lg-5 col-form-label">
                Shipping To Store Address:
              </label>
              <div class="col-lg-7">
                <div class="card-body">
                  <div
                    class="form-control border-black"
                    style="height: 130px; max-height: auto"
                  >
                    {{ this.ToStore.name }}<br />
                    {{ this.ToStore.address }}<span v-if="this.ToStore.city">,</span>
                    {{ this.ToStore.city }}<br />
                    {{ this.ToStore.state }}<span v-if="this.ToStore.pincode">-</span
                    >{{ this.ToStore.pincode }}<br />
                    {{ this.ToStore.contactNo }}<br />
                    {{ this.ToStore.email }}<br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style="background-color: #f8f8f8; position: absolute; bottom: 5px; width: 100%"
          class="row"
        >
          <div class="col-md-5"></div>
          <div class="col-md-1 text-center">
            <button
              v-if="this.TicketId"
              class="btn btn-sm btn-primary"
              @click="UpdateTicket"
            >
              Update
            </button>
            <button v-else class="btn btn-sm btn-primary" @click="submitTicket">
              Submit
            </button>
          </div>
          <div class="col-md-1 text-center">
            <button class="btn btn-sm btn-secondary" @click="cancelTicket">Cancel</button>
          </div>
          <div class="col-md-5"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../../Header.vue";
import Nav from "../../Nav.vue";
import axios from "axios";

export default {
  name: "AddTicket",
  components: {
    Header,
    Nav,
  },
  data() {
    return {
      userUpdatedTotal: false,
      fileName: "",
      TicketId: null,
      ToStore: {},
      fromStore: {},
      Addresses: [],
      employees: [], // Store fetched employees
      ticketData: {
        transportationDate: "",
        documentType: "",
        documentNumber: "",
        productDescription: "",
        vehicleNumber: "",
        transportationAmount: 0,
        noOfLabours: 0,
        labourAmount: 0,
        totalAmount: 0,
        status: "",
        awsFileId: null,
        fromStoreId: "",
        toStoreId: "",
        fromAddressId: "",
        toAddressId: "",
        paidBy: "",
      },
    };
  },
  mounted() {
    this.fetchEmployees();
    this.fetchAddress();
    this.TicketId = this.$route.params.TicketId;
    if (this.TicketId) {
      this.fetchAddress();
    }
  },
  watch: {
    // Watch for changes in 'fromStoreId' and 'toStoreId'
    "ticketData.fromStoreId": function (newStoreId) {
      if (newStoreId) {
        this.FillFromAddressDetails(newStoreId); // Call FillFromAddressDetails with the storeId
      }
    },
    "ticketData.toStoreId": function (newStoreId) {
      if (newStoreId) {
        this.FillToAddressDetails(newStoreId); // Call FillToAddressDetails with the storeId
      }
    },
    "ticketData.transportationAmount": function () {
      this.updateTotalAmount();
    },
    "ticketData.labourAmount": function () {
      this.updateTotalAmount();
    },
  },
  methods: {
    updateTotalAmount() {
      if (!this.userUpdatedTotal) {
        // Only update totalAmount if the user hasn't overridden it
        this.ticketData.totalAmount =
          Number(this.ticketData.transportationAmount) +
          Number(this.ticketData.labourAmount);
      }
    },
    async fetchTicketDetails(TicketId) {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/procurementTransit/getById/${TicketId}`
        );
        this.ticketData = response.data;
        this.fileName = response.data["awsFiles.fileName"];
        this.FillToAddressDetails();
        this.FillFromAddressDetails();
      } catch (error) {
        this.$handleError(error);
      }
    },
    async FillToAddressDetails() {
      const Address = this.Addresses.find(
        (address) => this.ticketData.toStoreId === address.id
      );
      console.log(this.ticketData.toStoreId);
      console.log(this.Addresses);
      if (Address) {
        try {
          const response = await axios.get(
            `${this.$apiEndPoint}/procurementAddress/${Address.addressId}`
          );
          this.ToStore = response.data;
          this.ticketData.toAddressId = response.data.id;
        } catch (error) {
          this.$handleError(error);
        }
      } else {
        this.$toast("Address not found for the provided ID", "error");
      }
    },
    async FillFromAddressDetails() {
      const Address = this.Addresses.find(
        (address) => this.ticketData.fromStoreId === address.id
      );
      if (Address) {
        try {
          const response = await axios.get(
            `${this.$apiEndPoint}/procurementAddress/${Address.addressId}`
          );
          this.fromStore = response.data;
          this.ticketData.fromAddressId = response.data.id;
        } catch (error) {
          this.$handleError(error);
        }
      } else {
        this.$toast("Address not found for the provided ID", "error");
      }
    },

    async fetchAddress() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/store/getStores`);
        this.Addresses = response.data;
        if (this.TicketId) {
          this.fetchTicketDetails(this.TicketId);
        }
      } catch (error) {
        this.$handleError(error);
      }
    },
    fetchEmployees() {
      axios
        .get(`${this.$apiEndPoint}/employee/myTeam/Procurement`)
        .then((response) => {
          this.employees = response.data;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    async UpdateTicket() {
      try {
        await axios.put(
          `${this.$apiEndPoint}/procurementTransit/${this.TicketId}`,
          this.ticketData
        );
        this.$toast("Ticket submitted successfully", "success");
        this.cancelTicket();
      } catch (error) {
        this.$handleError(error);
      }
    },
    async submitTicket() {
      try {
        await axios.post(`${this.$apiEndPoint}/procurementTransit`, this.ticketData);
        this.$toast("Ticket submitted successfully", "success");
        this.cancelTicket();
      } catch (error) {
        this.$handleError(error);
      }
    },
    handleFileUpload(event) {
      this.$globalState.isLoading = true;
      const files = event.target.files;

      if (files.length === 0) {
        this.$toast("No files selected for upload.", "error");
        return;
      }

      const file = files[0]; // Assuming only one file is uploaded
      const fileCategory = "procurementTransit";

      const payload = {
        fileName: file.name,
        fileSize: file.size,
        contentType: file.type,
        moduleName: "procurement",
        fileCategory: fileCategory,
      };

      axios
        .post(`${this.$apiEndPoint}/company/putFileSignedURL`, payload)
        .then((response) => {
          const signedUrl = response.data.s3URL;
          this.ticketData.awsFileId = response.data.id;

          const xhr = new XMLHttpRequest();
          xhr.open("PUT", signedUrl, true);
          xhr.setRequestHeader("Content-Type", file.type);

          xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
              if (xhr.status === 200) {
                this.$globalState.isLoading = false;

                this.$toast("File uploaded successfully to S3", "success");
              } else {
                this.$globalState.isLoading = false;
                this.$toast("Error uploading file to S3", "error");
              }
            }
          };

          xhr.send(file);
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    resetForm() {
      this.ticketData = {
        transportationDate: "",
        documentType: "",
        documentNumber: "",
        productDescription: "",
        vehicleNumber: "",
        transportationAmount: 0,
        noOfLabours: 0,
        labourAmount: 0,
        totalAmount: 0,
        status: "",
        awsFileId: null,
        fromStoreId: "",
        toStoreId: "",
        fromAddressId: "",
        toAddressId: "",
        paidBy: "",
      };
    },
    cancelTicket() {
      this.$router.go(-1);
      this.resetForm();
    },
  },
};
</script>
<style scoped>
.showAddPurchaseInvoiceHeader h4 {
  font-weight: 500;
  text-decoration: underline;
  font-size: 20px;
}

.showAddPurchaseInvoiceHeader img {
  width: 5%;
  margin-left: 90%;
  cursor: pointer;
  background-color: black;
}

.inputBg {
  background-color: transparent;
  border: 1px solid black;
  box-shadow: none;
}

.FileUpload {
  border: 1px solid black;
  border-radius: 10px;
  height: 30%;
}

.FileUpload button {
  padding: 4% 15%;
  background-color: #bcbfc6;
  border-radius: 10px;
  outline: none;
  border: none;
}

.addUploadSaveButton button {
  padding: 4% 15%;
  background-color: #6e92f7;
  border-radius: 10px;
  outline: none;
  border: none;
}
input:focus,
textarea:focus,
select:focus {
  outline: none; /* Remove default outline */
  box-shadow: none; /* Add a custom focus shadow */
}
</style>
