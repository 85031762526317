<template>
  <div class="dashboardcard-container">
    <div
      class="card dashboardcard"
      :class="{ skeleton: cardLoading }"
      @click="goToOnSiteTableByProjectStatus('New')"
    >
      <template v-if="!cardLoading">
        <div class="d-flex justify-content-between flex-row p-1 m-2">
          <span v-if="finalStatusCounts[0]" style="font-size: 24px; font-weight: bold">
            {{ finalStatusCounts[0].count }}
          </span>
          <span>
            <img src="../../assets/icons/newProjects.png" alt="" />
          </span>
        </div>
        <div class="ms-2 p-1">
          <span id="textColorOfCard">New Projects</span>
        </div>
      </template>
    </div>

    <div
      class="card dashboardcard"
      :class="{ skeleton: cardLoading }"
      @click="goToOnSiteTableByProjectStatus('In Progress')"
    >
      <template v-if="!cardLoading">
        <div class="d-flex justify-content-between flex-row p-1 m-2">
          <span v-if="finalStatusCounts[1]" style="font-size: 24px; font-weight: bold">
            {{ finalStatusCounts[1].count }}
          </span>
          <span>
            <img src="../../assets/icons/salesTarget.png" alt="" />
          </span>
        </div>
        <div class="ms-2 p-1">
          <span id="textColorOfCard">Active Projects</span>
        </div>
      </template>
    </div>

    <div
      class="card dashboardcard"
      @click="goToOnSiteTableByProjectStatus('Completed')"
      :class="{ skeleton: cardLoading }"
    >
      <template v-if="!cardLoading">
        <div class="d-flex justify-content-between flex-row p-1 m-2">
          <span v-if="finalStatusCounts[2]" style="font-size: 24px; font-weight: bold">
            {{ finalStatusCounts[2].count }}
          </span>
          <span>
            <img src="../../assets/icons/completedProjects.png" alt="" />
          </span>
        </div>

        <div class="ms-2 p-1">
          <span id="textColorOfCard">Completed Projects</span>
        </div>
      </template>
    </div>

    <div class="card dashboardcard" :class="{ skeleton: cardLoading }">
      <template v-if="!cardLoading">
        <a href="" style="text-decoration: none; color: inherit">
          <div class="d-flex justify-content-between flex-row" style="padding: 2px 10px">
            <span id="textColorOfCard" class="mt-2">Purchase Request</span>
            <span>
              <img src="../../assets/icons/salesTarget.png" alt="" />
            </span>
          </div>
          <div style="padding: 5px 10px">
            <div class="ms-1">
              <div class="col flex-container">
                <span id="textColorOfCardHeading1">Pending</span>
                <span id="textColorOfCardHeading2">Approved</span>
                <span id="textColorOfCardHeading3" class="text-danger">Rejected</span>
              </div>
            </div>
            <div class="ms-2">
              <div class="col flex-container">
                <span id="textColorOfCardContent1" v-if="finalPrStatusCounts[0]">{{
                  finalPrStatusCounts[0].count
                }}</span>
                <span id="textColorOfCardContent2" v-if="finalPrStatusCounts[1]">{{
                  finalPrStatusCounts[1].count
                }}</span>
                <span id="textColorOfCardContent3" v-if="finalPrStatusCounts[2]">{{
                  finalPrStatusCounts[2].count
                }}</span>
              </div>
            </div>
          </div>
        </a>
      </template>
    </div>
  </div>
  <div class="section WhiteHeadSection">
    <div class="container-fluid contentOfWhiteHeadSection">
      <div class="contentOfWhiteHeadSection2 contentOfWhiteHeadSectiondiv">
        <div
          class="LeadChart card p-2"
          style="flex: 1"
          :class="{ skeleton: cardLoading }"
        >
          <template v-if="!cardLoading">
            <div class="col-md-12">
              <h6 class="ms-2">
                Quotations Needing Approval(<span class="text-danger">{{
                  this.OnSiteQuotations.length
                }}</span
                >)
              </h6>
            </div>

            <div class="col-md-12 p-1" style="border-radius: 20px">
              <div
                class="table-responsive"
                style="height: 290px; overflow-y: auto; padding: 0 5px"
              >
                <table class="table p-2">
                  <caption class="d-none">
                    Table
                  </caption>
                  <thead class="position-sticky z-1 top-0">
                    <tr>
                      <th class="text-start">Project Name</th>
                      <th></th>
                      <th class="text-center">No. Of Approvels</th>
                    </tr>
                  </thead>
                  <tbody
                    class="text-nowrap bodyText"
                    v-for="Quotation in OnSiteQuotations"
                    :key="Quotation.id"
                  >
                    <!-- Use v-for to iterate over your data and create rows -->
                    <tr @click="navigateToProjectProfile(Quotation)">
                      <td class="text-start">
                        {{ Quotation["onSiteProjects.projects.projectName"] }}
                      </td>
                      <td style="visibility: hidden">
                        {{ Quotation["employees.firstName"] }}
                      </td>

                      <td class="text-center">
                        <span class="d-flex justify-content-center">
                          <span class="ms-5">
                            {{ Quotation.pendingQuotations }}
                          </span>
                          <img
                            class="ms-5"
                            v-if="$isManager('Onsite')"
                            src="../../assets/icons/sentAproval.png"
                            alt=""
                            style="width: 17px; height: 17px"
                          />
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </template>
        </div>

        <div
          class="LeadChart card p-2"
          style="flex: 1"
          :class="{ skeleton: cardLoading }"
        >
          <template v-if="!cardLoading">
            <div class="col-md-12">
              <h6 class="ms-2">
                PR Approval (<span class="text-danger">{{
                  this.OnSitePurchaseRequests.length
                }}</span
                >)
              </h6>
            </div>

            <div class="col-md-12 p-1" style="border-radius: 20px">
              <div
                class="table-responsive"
                style="height: 290px; overflow-y: auto; padding: 0 5px"
              >
                <table class="table">
                  <caption class="d-none">
                    Table
                  </caption>
                  <thead class="position-sticky z-1 top-0">
                    <tr>
                      <th class="text-start">Project Name</th>
                      <th class="text-start">PR Number</th>
                      <th>Date</th>
                      <th v-if="$isManager('Onsite')">Requested by</th>
                      <th class="ms-4">Action</th>
                    </tr>
                  </thead>
                  <tbody class="text-nowrap bodyText">
                    <!-- Use v-for to iterate over your data and create rows -->
                    <tr
                      v-for="PurchaseRequest in OnSitePurchaseRequests"
                      :key="PurchaseRequest.id"
                    >
                      <td class="text-start">
                        {{ PurchaseRequest["projects.projectName"] }}
                      </td>
                      <td>
                        {{ PurchaseRequest.prNumber }}
                      </td>
                      <td>
                        {{ PurchaseRequest.date }}
                      </td>
                      <td v-if="$isManager('Onsite')">
                        {{ PurchaseRequest["employees.firstName"] }}
                      </td>
                      <td>
                        <span v-if="$isExecutive('Onsite')">
                          {{ PurchaseRequest.prStatus }}
                        </span>
                        <span v-else>
                          <img
                            src="../../assets/icons/view.png"
                            alt=""
                            data-bs-toggle="modal"
                            data-bs-target="#PRDetailsModal"
                            @click="openModal(PurchaseRequest.id)"
                            id="approve"
                          />

                          <img
                            src="../../assets/icons/approve.png"
                            alt=""
                            id="approve"
                            @click="openApprovalModal(PurchaseRequest.id)"
                          />
                          <img
                            src="../../assets/icons/reject.png"
                            alt=""
                            id="approve"
                            @click="openRejectModal(PurchaseRequest.id)"
                          />
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="container-fluid contentOfWhiteHeadSection">
      <div class="contentOfWhiteHeadSection2 contentOfWhiteHeadSection2div">
        <div class="otherChart" style="flex: 1">
          <section class="card" style="padding: 1%" :class="{ skeleton: cardLoading }">
            <template v-if="!cardLoading">
              <div class="d-flex flex-column">
                <p class="p-1 m-0" style="font-size: 14px; border-bottom: 1px solid #000">
                  Recent Completed Projects
                </p>
                <div
                  class="d-flex align-items-center"
                  style="padding: 12px 0"
                  v-for="project in lastThreeProjects"
                  :key="project.id"
                >
                  <div class="ms-2">
                    <img
                      src="../../assets/icons/customerIcon.png"
                      alt=""
                      style="width: 38px; height: 38px"
                    />
                  </div>
                  <div class="d-flex flex-column align-items-start ms-3">
                    <p
                      class="m-0 p-0 text-nowrap"
                      style="font-size: 14px; text-overflow: ellipsis"
                    >
                      {{ project["projects.projectName"] }}
                    </p>
                    <p
                      class="m-0 mt-1 p-0 d-flex justify-content-between"
                      style="font-size: 10px; width: 200px"
                    >
                      <span style="font-size: 10px">
                        {{ $formatDateDMY(project.projectStartDate) }}</span
                      >
                    </p>
                  </div>
                </div>
              </div>
            </template>
          </section>
          <section class="card" style="padding: 1%" :class="{ skeleton: cardLoading }">
            <template v-if="!cardLoading">
              <div class="d-flex flex-column">
                <p class="p-1 m-0" style="font-size: 14px; border-bottom: 1px solid #000">
                  Recent GRN
                </p>
                <div
                  class="d-flex align-items-center"
                  style="padding: 12px 0"
                  v-for="(row, index) in grnList"
                  :key="index"
                >
                  <div class="d-flex flex-column align-items-start ms-3">
                    <p
                      class="m-0 p-0 text-nowrap"
                      style="font-size: 14px; text-overflow: ellipsis"
                    >
                      {{ row["onSiteProjects.projects.projectName"] }}
                    </p>
                    <p
                      class="m-0 mt-2 p-0 d-flex justify-content-between text-muted"
                      style="font-size: 10px; width: 250px"
                    >
                      <span style="font-size: 10px">
                        {{ row.grnNumber }} &nbsp; &nbsp; &nbsp;
                        {{ $formatDateDMY(row.receivedDate) }}</span
                      >

                      <span style="font-size: 10px">
                        <img
                          src="../../assets/icons/view.png"
                          alt=""
                          id="approve"
                          @click="opengrnList(row.id)"
                          data-bs-toggle="modal"
                          data-bs-target="#OnSiteGrnDetailsModal"
                        />
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </template>
          </section>
        </div>

        <div
          class="LeadChart card p-0"
          style="flex: 1"
          :class="{ skeleton: cardLoading }"
        >
          <template v-if="!cardLoading"
            ><section style="padding: 1%">
              <div class="d-flex flex-column">
                <p class="p-1 m-0" style="font-size: 14px; border-bottom: 1px solid #000">
                  Project Progress Status
                </p>
                <div
                  class="d-flex align-items-center"
                  style="padding: 10px 5px"
                  v-for="(project, index) in lastThreeProjectsStatus"
                  :key="project.id"
                >
                  <div class="ms-2"></div>
                  <div
                    class="d-flex flex-column align-items-start ms-2 w-100"
                    style="font-size: 14px"
                  >
                    <p class="m-0 p-0">{{ project["projects.projectName"] }}</p>

                    <div class="progress-bar-container">
                      <div
                        class="progress-bar"
                        :style="{
                          width: project.completedPercentage + '%',
                          backgroundColor: progressBarColors[index],
                        }"
                      ></div>
                    </div>
                    <span
                      class="d-flex justify-content-between w-100"
                      style="font-size: 10px"
                    >
                      <p class="d-flex p-0">
                        {{ project.completedPercentage }}%
                        <strong class="ms-2" style="font-size: 10px">Completed</strong>
                      </p>
                      <p class="p-0 text-right">
                        {{ project.completedDuration }}/{{ project.totalPlannedDuration }}
                      </p>
                    </span>
                  </div>
                </div>
              </div>
            </section>
          </template>
        </div>
      </div>
    </div>
    <div class="container-fluid contentOfWhiteHeadSection">
      <div class="contentOfWhiteHeadSection2 contentOfWhiteHeadSection2div">
        <div style="flex: 1">
          <div
            class="d-flex flex-column LeadChart2 card p-2"
            :class="{ skeleton: cardLoading }"
          >
            <template v-if="!cardLoading">
              <div class="row w-100 ms-1">
                <p class="subHeadingHeader p-1 m-0">Today’s Task</p>
                <hr />
              </div>
              <div class="card card2 p-2 m-2" v-for="task in todayTasks" :key="task.id">
                <div class="d-flex justify-content-between align-items-center">
                  <section class="d-flex align-items-center">
                    <img
                      src="../../assets/icons/taskCompleted.png"
                      alt="Task Completed"
                      style="width: 17px; height: 17px"
                    />
                    <span
                      :title="task.title"
                      class="ms-2 ellipsis"
                      style="font-size: 14px"
                      >{{ task.title }}</span
                    >
                  </section>
                  <section class="d-flex align-items-center">
                    <span class="d-flex align-items-center" style="padding: 0 10px">
                      <img
                        src="../../assets/icons/subtask.png"
                        alt="Subtask"
                        style="width: 15px; height: 15px"
                      />
                      <sup style="font-size: 10px; font-weight: 600" class="ms-1">
                        {{ task.subtaskCnt }}
                      </sup>
                    </span>
                    <span style="padding: 0 5px">
                      <button
                        style="
                          width: 70px;
                          height: 25px;
                          font-size: 10px;
                          border: none;
                          border-radius: 5px;
                        "
                      >
                        {{ task.priority }}
                      </button>
                    </span>
                    <span style="padding: 0 5px">
                      <img
                        src="../../assets/icons/threeDot.jpg"
                        alt="More options"
                        style="width: 15px; height: 13px"
                      />
                    </span>
                  </section>
                </div>

                <div class="mt-3 d-flex justify-content-between">
                  <section class="d-flex align-items-center">
                    <img
                      src="../../assets/icons/black_Calendar.png"
                      alt="Calendar"
                      style="width: 15px; height: 15px"
                    />
                    <span class="mt-2 ms-2 text-muted" style="font-size: 10px">
                      {{ task.date }}
                    </span>
                  </section>
                  <section>
                    <span>
                      <img
                        src="../../assets/icons/profileBlack.png"
                        alt="Profile"
                        style="width: 20px; height: 20px"
                      />
                      <img
                        src="../../assets/icons/profileBlack.png"
                        alt="Profile"
                        style="width: 20px; height: 20px"
                      />
                      <img
                        src="../../assets/icons/profileBlack.png"
                        alt="Profile"
                        style="width: 20px; height: 20px"
                      />
                    </span>
                  </section>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div style="flex: 1">
          <div
            class="d-flex flex-column LeadChart2 card p-2"
            :class="{ skeleton: cardLoading }"
          >
            <template v-if="!cardLoading">
              <div class="row w-100 ms-1">
                <p class="subHeadingHeader p-1 m-0">Today’s Schedules</p>
                <hr />
              </div>

              <div
                class="card card3 p-2 m-2 border-0 blueactive-SubHeading2 justify-content-between"
                v-for="event in todayEvents"
                :key="event.id"
              >
                <div class="m-1">
                  <p style="font-size: 14px; font-weight: 600" class="p-0 mb-1">
                    {{ event.title }}
                  </p>
                  <p style="font-size: 10px" v-if="event.description !== null">
                    {{ event.description }}
                  </p>
                </div>
                <div
                  class="mb-1 m-1"
                  style="font-size: 12px"
                  v-if="event.meetingLink !== null"
                >
                  <span>
                    <a :href="'//' + event.meetingLink" target="_blank">{{
                      event.meetingLink
                    }}</a>
                  </span>
                </div>
                <div class="d-flex justify-content-between mt-1">
                  <section style="font-size: 12px">
                    <p class="p-0 m-1 d-flex align-items-center">
                      <img
                        src="../../assets/icons/black_Calendar.png"
                        alt="Calendar"
                        class="mt-1"
                        style="width: 15px; height: 15px"
                      />
                      <span class="mt-2 ms-2 text-muted" style="font-size: 10px">
                        {{ convertTo12HourFormat(event.fromTime) }} To
                        {{ convertTo12HourFormat(event.toTime) }}
                      </span>
                    </p>
                    <p class="p-0 m-1 d-flex align-items-center">
                      <img
                        src="../../assets/icons/black_Calendar.png"
                        alt="Calendar"
                        class="mt-1"
                        style="width: 15px; height: 15px"
                      />
                      <span class="mt-2 ms-2 text-muted" style="font-size: 10px">
                        {{ "2024-05-16" }}
                      </span>
                    </p>
                  </section>
                  <section class="d-flex align-items-center">
                    <img
                      src="../../assets/icons/profileBlack.png"
                      alt="Profile"
                      style="width: 20px; height: 20px"
                    />
                    <img
                      src="../../assets/icons/profileBlack.png"
                      alt="Profile"
                      style="width: 20px; height: 20px"
                    />
                    <img
                      src="../../assets/icons/profileBlack.png"
                      alt="Profile"
                      style="width: 20px; height: 20px"
                    />
                  </section>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <!-- Content for section 2 -->
  </div>

  <transition name="fade">
    <div v-if="showModal" class="modal-overlay" @click="openApprovalModal"></div>
  </transition>
  <transition name="slide">
    <div class="modal show d-block" v-if="showModal" style="z-index: 9999999">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <p class="modal-title">
              Are you sure you want to approve this Purchase Request ?
            </p>
          </div>

          <div class="modal-footer d-flex justify-content-center">
            <button type="button" class="btn btn-sm btn-primary" @click="approveDocument">
              Yes
            </button>
            <button
              type="button"
              class="btn btn-sm btn-secondary"
              @click="showModal = false"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>

  <div class="modal show d-block" v-if="showRejectionModal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <p class="modal-title text-center">
            Are you sure you want to reject this Purchase Request document?
          </p>
        </div>

        <div class="modal-footer d-flex justify-content-center">
          <button type="button" class="btn btn-sm btn-primary" @click="rejectDocument">
            Yes
          </button>
          <button
            type="button"
            class="btn btn-sm btn-secondary"
            @click="showRejectionModal = false"
          >
            No
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "OnsiteDashboard",
  components: {},

  props: {
    SelectedEmployeeId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      cardLoading: true,
      showRejectionModal: false,
      showModal: false,
      selectedPRId: null,
      finalPrStatusCounts: {},
      OnSitePurchaseRequests: [],
      finalStatusCounts: [],
      TargetData: {},
      Data: {},
      Target: {},
      todayTasks: [],
      todayEvents: [],
      currentDay: "",
      formattedDate: "",
      fullName: "",
      greeting: "",
      selectedSourceId: "All",
      selectedEmployeeId: this.SelectedEmployeeId,
      ShowTargetSetter: false,
      employees: [],
      lastThreeProjectsStatus: [],
      lastThreeProjects: [],
      OnSiteQuotations: [],
      grnList: [],
    };
  },
  computed: {
    isOnsiteExecutive() {
      const roles = this.$LocalUser.employeeRoles;
      return roles.some((role) => role.team === "Onsite" && role.role === "Executive");
    },
    progressBarColors() {
      // Define an array of colors
      const colors = ["yellow", "purple", "darkblue"];
      // Return an array of colors corresponding to each project
      return this.lastThreeProjectsStatus.map(
        (project, index) => colors[index % colors.length]
      );
    },
  },

  mounted() {
    this.selectedEmployeeId = this.SelectedEmployeeId;

    this.setCurrentDate();

    this.fetchProgressOfProject();
  },

  watch: {
    SelectedEmployeeId(newVal) {
      this.selectedEmployeeId = newVal;

      this.fetchProgressOfProject();
    },
  },

  methods: {
    openModal(SelectedPRId) {
      this.$emit("PR-selected", SelectedPRId);
    },
    async rejectDocument() {
      try {
        const response = await axios.put(
          `${this.$apiEndPoint}/purchaseRequest/${this.selectedPRId}`,
          {
            prStatus: "Rejected",
          }
        );

        if (response.status === 200) {
          this.fetchOnSitePurchaseRequests();
          // Handle successful response
          this.$toast("Document Rejected successfully", "success");
        }
        this.showRejectionModal = false;
      } catch (error) {
        this.$handleError(error);
      }
      // Handle rejection logic here, including sending rejection remarks if needed
    },
    async approveDocument() {
      try {
        const response = await axios.put(
          `${this.$apiEndPoint}/purchaseRequest/PrStatus/${this.selectedPRId}`,
          {
            prStatus: "Approved",
          }
        );

        if (response.status === 200) {
          this.fetchOnSitePurchaseRequests();
          this.$toast("Document approved successfully", "success");
        }
        this.showModal = false;
      } catch (error) {
        this.$handleError(error);
      }
    },
    openApprovalModal(Id) {
      this.selectedPRId = Id;
      this.showModal = true;
    },
    openRejectModal(Id) {
      this.selectedPRId = Id;
      this.showRejectionModal = true;
    },
    navigateToProjectProfile(data) {
      // Navigate to ProjectProfile page with project ID as parameter
      this.$router.push({
        name: "OnSiteProjectProfile",
        params: {
          Id: data.onSiteProjectId,
          activeTab: "contractor",
        },
      });
    },
    opengrnList(selectedGrnId) {
      this.$emit("GRN-selected", selectedGrnId);
      console.log(this.selectedGrnId);
    },
    handleDownloadGRN(selectedGrnId) {
      this.$emit("GRN-download", selectedGrnId);
      this.selectedGrnId = selectedGrnId;
      // Ensure the modal is fully loaded before triggering download
    },

    async fetchgrn() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/OnSiteGrn/recentGrn/${this.selectedEmployeeId}`
        );
        this.grnList = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },

    goToOnSiteTableByProjectStatus(statusName) {
      if (statusName === "New" && this.isOnsiteExecutive) {
        return; // Exit function early if Design Executive has access
      }
      if (statusName === "New") {
        this.selectedEmployeeId = "All";
      }
      this.$router.push({
        name: "OnSiteTableByProjectStatus",
        params: { statusName: statusName },
      });
    },
    formatNumber(amount) {
      // Check if amount is a valid number
      if (typeof amount !== "number" || isNaN(amount)) {
        return ""; // or any default value you prefer
      }
      return amount.toLocaleString("en-IN");
    },

    convertTo12HourFormat(timeString) {
      // Split the time string into hours and minutes
      const [hours, minutes] = timeString.split(":");

      // Convert hours to a number
      let hour = parseInt(hours, 10);

      // Determine if it's AM or PM
      const period = hour >= 12 ? "PM" : "AM";

      // Convert hours to 12-hour format
      hour = hour % 12 || 12;

      // Return the formatted time string
      return `${hour.toString().padStart(2, "0")}:${minutes} ${period}`;
    },
    async fetchTodayTasks() {
      try {
        if (this.selectedEmployeeId !== "All") {
          const response = await axios.get(
            `${this.$apiEndPoint}/tasks/dashboardTasks/${this.selectedEmployeeId}`
          );
          this.todayTasks = response.data;
        } else {
          this.todayTasks = []; // Set to empty if 'All' is selected, or handle accordingly
        }
      } catch (error) {
        this.$handleError(error);
      }
    },

    async fetchTodayReminders() {
      try {
        if (this.selectedEmployeeId !== "All") {
          const response = await axios.get(
            `${this.$apiEndPoint}/reminders/dashboardReminder/${this.selectedEmployeeId}`
          );
          this.todayReminders = response.data;
        } else {
          this.todayReminders = [];
        }
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchTodayEvents() {
      try {
        if (this.selectedEmployeeId !== "All") {
          const response = await axios.get(
            `${this.$apiEndPoint}/events/dashboardEvents/${this.selectedEmployeeId}`
          );
          this.todayEvents = response.data;
        } else {
          this.todayEvents = [];
        }
      } catch (error) {
        this.$handleError(error);
      }
    },

    isSalesManager() {
      return this.$LocalUser.employeeRoles.some(
        (role) => role.team === "Onsite" && role.role === "Manager"
      );
    },

    setSelectedEmployee() {},

    async fetchEmployees() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/employee/myTeam/Sales`);
        this.employees = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },

    async fetchOnSitePurchaseRequests() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/purchaseRequest/prForApproval/Onsite/${this.selectedEmployeeId}`
        );
        this.OnSitePurchaseRequests = response.data.getPr;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchOnSiteQuotations() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/onSiteQuotation/quotationsForApproval/${this.selectedEmployeeId}`
        );
        this.OnSiteQuotations = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchProgressOfProject() {
      this.cardLoading = true;
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/onSiteTimeline/projectStatus/${this.selectedEmployeeId}`
        );
        this.lastThreeProjectsStatus = response.data;
        this.fetchLastThreeProject();
        this.fetchOnSiteQuotations();
        this.fetchOnSitePurchaseRequests();
        this.fetchTodayTasks();
        this.fetchTodayEvents();
        this.fetchTodayReminders();
        this.fetchEmployees();
        this.fetchgrn();
        this.cardLoading = false;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchLastThreeProject() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/onSiteProject/statusCount/${this.selectedEmployeeId}`
        );
        this.finalStatusCounts = response.data.finalStatusCounts;
        this.lastThreeProjects = response.data.lastFourProjects;
        this.finalPrStatusCounts = response.data.finalPrStatusCounts;
      } catch (error) {
        this.$handleError(error);
      }
    },

    getGreeting() {
      const currentTime = new Date();
      const currentHour = currentTime.getHours();

      if (currentHour < 12) {
        return "Good Morning";
      } else if (currentHour < 17) {
        return "Good Afternoon";
      } else {
        return "Good Evening";
      }
    },
    setCurrentDate() {
      const date = new Date();
      this.currentDay = new Intl.DateTimeFormat("en-US", { weekday: "long" }).format(
        date
      );
      const options = { day: "numeric", month: "long", year: "numeric" };
      this.formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
    },
    getTotalLeads() {
      return Object.values(this.leadCounts).reduce((acc, val) => acc + val, 0);
    },
  },
};
</script>

<style scoped>
.progress-bar-container {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  margin: 5px 0px;
}

.progress-bar {
  height: 10px;
}
.table-responsive thead th {
  background-color: #ccc;
  font-size: 12px;
  font-weight: 600;
  padding: 4px 6px;
}
.table-responsive tbody td {
  font-size: 12px;

  padding: 4px 6px;
}

.blueactive-SubHeading2 {
  font-size: 14px;
  color: #000000;
  font-weight: 500;
  /* Set left 10% background color here */
  background-image: linear-gradient(to right, #2381f3 4px, transparent 3px);
  background-size: 100% 100%;
  /* Ensure gradient fills entire width */
  /* You can adjust other styles as needed */
}

.btn-priority-low {
  background-color: #f4e8fb;
  color: #ba69e2;
}

.btn-priority-medium {
  background-color: #ecebec;
  color: #7e7882;
}

.btn-priority-high {
  background-color: #feabab;
  color: #fd0000;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
  /* Remove default outline */
  box-shadow: none;
  /* Add a custom focus shadow */
}

label {
  font-weight: 500;
  color: black;
}

.transaction-details {
  position: fixed;
  display: block;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  width: 400px;
  min-height: 250px;
  height: auto;
  background-color: #f4f4f4;
  color: #000000;
  border: 1px solid #b1c5df;
  border-radius: 10px;
}

#blackThreeDOtDashBoard {
  width: 15px;
  height: 13px;
  position: relative;
  cursor: pointer;
  display: flex;
}

.sales-dashboard {
  margin: 0.5% 2%;
  box-sizing: border-box;
}

.section-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
  position: relative;

  /* Relative positioning for the wrapper */
}

.contentOfWhiteHeadSection {
  position: relative;
  width: 100%;
  background-color: #f4f4f4 !important;
}

.contentOfWhiteHeadSection2 {
  display: flex;
  position: relative;
  gap: 3%;
  padding: 0 2%;
  margin-bottom: 1%;
}
.welcome {
  font-size: 30px;
  font-weight: bold;
  white-space: nowrap;
}

.LeadChart {
  max-height: 350px;
  min-height: 350px;
  background-color: #ffffff;
  border-radius: 5px;
}

.LeadChart2 {
  max-height: 350px;
  min-height: 350px;
  background-color: #ffffff;
  border-radius: 5px;
  overflow-y: auto;
}

.otherChart {
  max-height: 350px;
  display: flex;
  justify-content: space-between;
}

.otherChart section {
  max-height: 350px;
  min-height: 350px;
  width: 300px;
  background-color: #ffffff;
  overflow-y: auto;

  border-radius: 5px;
}

.otherChart section::-webkit-scrollbar {
  display: none;
}

.lead-count-chart {
  display: flex;
  flex-direction: column;

  margin-top: 20px;
}

.chart-bar {
  margin-right: 10px;
  text-align: center;
  display: flex;
}

.bar-label {
  font-size: 12px;
  margin-bottom: 5px;
  width: 150px;
  text-align: left;
}

.DashboardSection2 {
  background-color: #000000;
}

.card2 {
  width: 400px;
  height: 100px;
  background-color: #ffffff;
}

.card3 {
  width: 400px;
  min-height: 200px;
  max-height: fit-content;
  background-color: #ffffff;
  border-radius: 0 5px 5px 0;
  overflow-y: auto;
}

.card3::-webkit-scrollbar {
  display: none;
}
#approve {
  width: 15px;
  margin-right: 10px;
  cursor: pointer;
}

#textColorOfCard {
  color: rgb(4, 4, 4);
  font-weight: 500;
}
.flex-container {
  display: flex;
  justify-content: space-between; /* Distributes space between flex items */
  align-items: center;
  padding-top: 10px;
  /* padding-right: 30px; */
}
#textColorOfCardHeading1,
#textColorOfCardHeading2,
#textColorOfCardHeading3 {
  color: #bcbfc6;
  font-size: 10px;
}
#textColorOfCardContent1 {
  color: #bcbfc6;
  font-size: 10px;
  padding-left: 15px;
}
#textColorOfCardContent2 {
  color: #bcbfc6;
  font-size: 10px;
}
#textColorOfCardContent3 {
  color: #bcbfc6;
  font-size: 10px;
  padding-right: 20px;
}

@media (max-width: 1200px) {
  .contentOfWhiteHeadSection2 {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-top: 5%;
  }
  .LeadChart,
  .otherChart {
    margin-top: 1%;
  }
  .cardValue {
    font-size: 14px;
  }
  #textColorOfCard {
    font-size: 12px;
  }
}
/* Mobile View Adjustments */
@media (max-width: 768px) {
  .blackHeadSection {
    height: auto;
    padding: 10px;
  }

  .middleHeadSection {
    display: none;
  }

  .LeadChart,
  .LeadChart2,
  .otherChart section {
    min-height: auto;
    max-height: auto;
  }

  .section-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    position: relative;
    padding: 10px;
    overflow-y: scroll !important;
  }

  .contentOfWhiteHeadSection,
  .contentOfWhiteHeadSection2 {
    padding: 10px;
  }

  .otherChart {
    flex-direction: column;
    justify-content: space-around;
  }

  .otherChart section {
    width: 100%;
    margin-bottom: 10px;
  }

  .LeadChart {
    margin-bottom: 10px;
  }

  .lead-count-chart {
    margin-top: 10px;
  }

  .table-responsive thead th {
    font-size: 12px;
    padding: 4px;
  }

  .welcome {
    font-size: 18px;
  }

  .progress-bar-container {
    margin: 5px 0;
  }

  .bar-label {
    width: auto;
  }

  .contentOfWhiteHeadSectiondiv,
  .contentOfWhiteHeadSection2div {
    display: flex;
    flex-direction: column;
    height: auto;
  }
  .content-container,
  .container-child,
  .section-wrapper {
    flex-direction: column;
  }

  .black-head-section,
  .middle-head-section,
  .white-head-section {
    height: auto;
    overflow-y: auto;
  }

  .lead-chart,
  .other-chart,
  .lead-chart2 {
    margin-top: 10px;
  }
  .WhiteHeadSection {
    display: none;
  }
}
/*

.bar {
  width: 50px;
  height: 20px;
  background-color: #007bff; 
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}*/
</style>
