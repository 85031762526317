<template>
  <!-- New Integration Modal -->
  <div
    class="modal fade"
    id="integrationModal"
    tabindex="-1"
    aria-labelledby="integrationModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <!-- Larger modal to prevent overflow -->
      <div class="modal-content p-1">
        <div class="modal-header">
          <h5 class="modal-title" id="integrationModalLabel">New Integration</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form class="container" @submit.prevent="createMetasource()">
            <!-- Platform -->
            <div class="row mb-3 align-items-center">
              <label class="col-md-4 col-form-label">Platform</label>
              <div class="col-md-8">
                <select v-model="integrationType" class="form-select">
                  <option value="Meta">Meta Ads</option>
                  <option value="Google">Google Ads</option>
                </select>
              </div>
            </div>

            <!-- Source Name -->
            <div class="row mb-3 align-items-center">
              <label class="col-md-4 col-form-label">Source Name *</label>
              <div class="col-md-8">
                <input type="text" v-model="name" class="form-control" value="Meta Ads" />
              </div>
            </div>

            <!-- Meta App ID -->
            <div class="row mb-3 align-items-center">
              <label class="col-md-4 col-form-label">Meta App ID *</label>
              <div class="col-md-8">
                <input type="text" v-model="metaAdsAppId" class="form-control" />
              </div>
            </div>

            <div class="row mb-3 align-items-center">
              <label class="col-md-4 col-form-label">Meta Adds Name *</label>
              <div class="col-md-8">
                <input type="text" v-model="metaAdsName" class="form-control" />
              </div>
            </div>

            <div class="row mb-3 align-items-center">
              <label class="col-md-4 col-form-label">Meta Adds Mobile *</label>
              <div class="col-md-8">
                <input type="tel" v-model="metaAdsMobile" class="form-control" />
              </div>
            </div>

            <div class="row mb-3 align-items-center">
              <label class="col-md-4 col-form-label">Meta Adds E-mail *</label>
              <div class="col-md-8">
                <input type="email" v-model="metaAdsEmail" class="form-control" />
              </div>
            </div>

            <!-- Meta App Secret -->
            <div class="row mb-3 align-items-center">
              <label class="col-md-4 col-form-label">Meta App Secret *</label>
              <div class="col-md-8">
                <input type="text" v-model="metaAdsAppSecret" class="form-control" />
              </div>
            </div>

            <!-- Meta Verify Token -->
            <div class="row mb-3 align-items-center">
              <label class="col-md-4 col-form-label">Meta Verify Token *</label>
              <div class="col-md-8">
                <input type="text" v-model="metaAdsVerifyToken" class="form-control" />
              </div>
            </div>

            <!-- Meta Access Token -->
            <div class="row mb-3 align-items-center">
              <label class="col-md-4 col-form-label">Meta Access Token *</label>
              <div class="col-md-8">
                <input type="text" v-model="metaAdsAccessToken" class="form-control" />
              </div>
            </div>

            <!-- Buttons -->
            <div class="row mt-4">
              <div
                class="col-md-12"
                style="
                  display: flex;
                  gap: 100px;
                  align-items: center;
                  justify-content: center;
                "
              >
                <div>
                  <button type="submit" class="btn btn-primary">Create</button>
                </div>
                <div>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      name: "",
      status: "",
      metaAdsName: "",
      metaAdsMobile: "",
      metaAdsEmail: "",
      integrationType: "",
      metaAdsAccessToken: "",
      metaAdsAppSecret: "",
      metaAdsAppId: "",
      metaAdsVerifyToken: "",
      integrationTypes: ["Meta Ads", "Google Ads"],
    };
  },

  methods: {
    async createMetasource() {
      try {
        const requestBody = {
          name: this.name,
          status: "In Progress",
          metaAdsName: this.metaAdsName,
          metaAdsMobile: this.metaAdsEmail,
          metaAdsEmail: this.metaAdsMobile,
          integrationType: this.integrationType,
          metaAdsAccessToken: this.metaAdsAccessToken,
          metaAdsAppSecret: this.metaAdsAppSecret,
          metaAdsAppId: this.metaAdsAppId,
          metaAdsVerifyToken: this.metaAdsVerifyToken,
        };

        // Log the request body data
        console.log("Request body data:", requestBody);
        // Making the API request
        const response = await axios.post(`${this.$apiEndPoint}/source`, requestBody);
        console.log("the sending api body is:", this.requestBody);

        this.$emit("metasourceCreated", response.data);
        this.formEmpty();
        // Optionally, you can log the response
        console.log("Meta source created:", response.data);
      } catch (error) {
        console.error("Error creating metasource:", error);
      }
    },

    formEmpty() {
      this.name = "";
      this.status = "";
      this.metaAdsName = "";
      this.metaAdsMobile = "";
      this.metaAdsEmail = "";
      this.integrationType = "";
      this.metaAdsAccessToken = "";
      this.metaAdsAppSecret = "";
      this.metaAdsAppId = "";
      this.metaAdsVerifyToken = "";
      this.integrationTypes = null;
    },
  },
};
</script>

<style scoped>
.modal-content,
input {
  font-size: 14px;
}

.modal-content {
  margin-top: 5%;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border: none;
}
</style>
