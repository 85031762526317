<template>
  <div>
    <!-- Header Section -->
    <Header />
    <div class="content-container">
      <Nav />
      <div class="containerChild overflow-hidden">
        <div class="ticket-header">
          <h5>Raised Ticket Overview</h5>
          <button
            type="button"
            class="btn-close"
            @click="closePage"
            aria-label="Close"
          ></button>
        </div>
        <div class="row">
          <div class="col-md-9">
            <!-- Ticket Form -->
            <div class="ticket-form p-1">
              <h6>Contact Info</h6>
              <section class="row gap-3">
                <!-- Department Name -->
                <div class="col-md-4 form-group">
                  <label for="department">Company Name </label>

                  <p>
                    {{ form["company.companyName"] }}
                  </p>
                </div>

                <!-- Issue Date -->
                <div class="col-md-3 form-group">
                  <label for="issue-date">Company ID</label>
                  <p>
                    {{ form["employees.firstName"] }} {{ form["employees.lastName"] }}
                  </p>
                </div>

                <!-- Severity -->
                <div class="form-group col-md-4">
                  <label for="severity">Employee Name </label>
                  <p>
                    {{ form["employees.firstName"] }} {{ form["employees.lastName"] }}
                  </p>
                </div>
              </section>
              <section class="row gap-3">
                <!-- Department Name -->
                <div class="col-md-4 form-group">
                  <label for="department">Employee ID </label>

                  <p>
                    {{ form["employees.mobileNumber"] }}
                  </p>
                </div>

                <!-- Issue Date -->
                <div class="col-md-3 form-group">
                  <label for="issue-date">Contact No.</label>
                  <p>
                    {{ form["employees.mobileNumber"] }}
                  </p>
                </div>

                <!-- Severity -->
                <div class="form-group col-md-4">
                  <label for="severity">Email ID </label>
                  <p>
                    {{ form["employees.employeeEmailId"] }}
                  </p>
                </div>
              </section>
              <h6>Issue Details</h6>
              <!-- Issue Title -->
              <div class="form-group">
                <label for="issue-title">Issue Title</label>
                <input
                  type="text"
                  id="issue-title"
                  v-model="form.title"
                  placeholder="Enter issue title"
                  class="form-control"
                  readonly
                />
              </div>

              <!-- Issue Description -->
              <div class="form-group">
                <label for="issue-description">Issue Description </label>
                <textarea
                  id="issue-description"
                  v-model="form.description"
                  placeholder="Enter issue description"
                  class="form-control"
                  rows="4"
                  readonly
                ></textarea>
              </div>

              <section class="row gap-3">
                <!-- Department Name -->
                <div class="col-md-4 form-group">
                  <label for="department">Department Name </label>

                  <p>{{ form.moduleName }}</p>
                </div>

                <!-- Issue Date -->
                <div class="col-md-3 form-group">
                  <label for="issue-date">Issue Date </label>
                  <p>{{ form.raisedDate }}</p>
                </div>

                <!-- Severity -->
                <div class="form-group col-md-4">
                  <label for="severity">Severity </label>

                  <p>{{ form.priority }}</p>
                </div>
              </section>

              <!-- Attachment -->
              <div class="form-group mt-2 attatchmentlabel d-flex align-items-center">
                <label for="attachment" class="me-3">Attachment</label>
              </div>

              <!-- Uploaded Files List -->
              <div class="uploaded-files mt-3" v-if="uploadedFiles.length">
                <h6 class="text-muted">Uploaded Files:</h6>
                <ul>
                  <li
                    v-for="(file, index) in uploadedFiles"
                    :key="index"
                    class="uploaded-file p-1"
                  >
                    <span
                      class="text-black"
                      style="cursor: pointer"
                      @click="
                        $viewFile(
                          file.awsFileName,
                          file.name,
                          'customerSupportTicketAttachment',
                          'customerSupportTicket'
                        )
                      "
                      >{{ file.name }}</span
                    >
                    <button
                      class="remove-btn"
                      @click="
                        $downloadFile(
                          file.awsFileName,
                          file.name,
                          'customerSupportTicketAttachment',
                          'customerSupportTicket'
                        )
                      "
                      title="Remove this file"
                    >
                      <img
                        src="../../assets/icons/download.png"
                        alt=""
                        srcset=""
                        style="width: 25px; height: 25px"
                      />
                    </button>
                  </li>
                </ul>
              </div>

              <div class="mt-3">
                <section class="d-flex justify-content-between">
                  <h6>Issue Resolution Summary</h6>
                  <h6>Resoled Date :{{ form.resolvedDate }}</h6>
                </section>
                <div class="form-group">
                  <label for="issue-description">Comment </label>
                  <textarea
                    id="issue-description"
                    v-model="form.resolutionSummary"
                    placeholder="Enter issue description"
                    class="form-control"
                    rows="4"
                  ></textarea>
                </div>
                <div class="uploaded-files mt-3" v-if="ResolveduploadedFiles.length">
                  <h6 class="text-muted">Resolve Uploaded Files:</h6>
                  <ul>
                    <li
                      v-for="(file, index) in ResolveduploadedFiles"
                      :key="index"
                      class="uploaded-file p-1"
                    >
                      <span
                        class="text-black"
                        style="cursor: pointer"
                        @click="
                          $viewFile(
                            file.awsFileName,
                            file.name,
                            'customerSupportTicketAttachment',
                            'customerSupportTicket'
                          )
                        "
                        >{{ file.name }}</span
                      >
                      <button
                        class="remove-btn"
                        @click="
                          $downloadFile(
                            file.awsFileName,
                            file.name,
                            'customerSupportTicketAttachment',
                            'customerSupportTicket'
                          )
                        "
                        title="Remove this file"
                      >
                        <img
                          src="../../assets/icons/download.png"
                          alt=""
                          srcset=""
                          style="width: 25px; height: 25px"
                        />
                      </button>
                    </li>
                  </ul>
                </div>
              </div>

              <!-- Buttons -->
            </div>
          </div>
          <div class="col-md-3 p-1 overflow-hidden">
            <h6>Comments</h6>

            <!-- Comments and Add Comment Container -->
            <div class="comments-container">
              <!-- Comments History Section -->
              <div class="comments-history" v-if="History.length">
                <div v-for="comment in History" :key="comment.id" class="parent-comment">
                  <div class="comment-header">
                    <div class="comment-avatar-container">
                      <span
                        class="comment-avatar"
                        :style="{
                          backgroundColor: getColor(comment['employees.firstName']),
                        }"
                      >
                        {{ comment.initials }}
                      </span>
                    </div>
                    <div class="comment-information">
                      <div class="comment-info">
                        <strong class="comment-author">
                          {{ comment["employees.firstName"] }}
                          {{ comment["employees.lastName"] }}
                        </strong>
                        <small class="comment-date">{{ comment.date }}</small>
                      </div>
                    </div>
                  </div>

                  <!-- Show comment text with line breaks -->
                  <pre class="comment-text">{{ comment.comment }}</pre>
                  <p
                    v-if="comment['awsFiles.awsFileName']"
                    style="cursor: pointer"
                    class="comment-text cummentdocument badge text-black"
                    @click="
                      $viewFile(
                        comment['awsFiles.awsFileName'],
                        comment['awsFiles.fileName'],
                        'customerSupportTicketAttachment',
                        'customerSupportTicket'
                      )
                    "
                  >
                    {{ comment["awsFiles.fileName"] }}
                  </p>
                </div>
              </div>
              <div v-else>
                <p>No comments available.</p>
              </div>

              <!-- Add Comment Section -->
            </div>
            <div class="add-comment">
              <textarea
                v-model="newComment"
                class="form-control"
                rows="3"
                placeholder="Write your comment here..."
              ></textarea>

              <div class="comment-actions mt-2 d-flex align-items-center">
                <div class="file-upload me-2">
                  <input
                    type="file"
                    id="attachment"
                    class="form-control form-control-sm d-none"
                    @change="handleFileUpload"
                    ref="fileInput"
                  />
                  <img
                    src="../../assets/icons/attachement.png"
                    alt="Attachment"
                    @click="triggerFileInput"
                    style="width: 15px; height: 15px; cursor: pointer"
                  />
                </div>
                <small class="file-name ms-2 text-muted" v-if="selectedFileName">
                  {{ selectedFileName }}
                </small>
                <button
                  class="btn btn-primary btn-sm ms-auto"
                  @click="saveComment"
                  :disabled="!newComment"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../Header.vue";
import Nav from "../Nav.vue";
import axios from "axios";

export default {
  name: "TicketById",
  components: {
    Header,
    Nav,
  },
  data() {
    return {
      Id: null,
      form: {},
      uploadedFiles: [],
      ResolveduploadedFiles: [],
      uploadedFileIds: [],
      History: [],
      newComment: "", // New comment text
      attachmentId: null, // ID of the uploaded attachment
      selectedFileName: "",
    };
  },
  mounted() {
    this.Id = this.$route.params.Id;
    this.fetchById();
    this.fetchHistory();
  },
  methods: {
    async saveComment() {
      const payload = {
        ticketId: this.Id, // Replace with the actual ticket ID
        comment: this.newComment,
        attachmentId: this.attachmentId || null,
      };
      console.log(payload);
      try {
        await axios.post(`${this.$apiEndPoint}/ticketComment`, payload);
        this.newComment = "";
        this.attachmentId = null;
        this.fetchHistory();
      } catch (error) {
        this.$handleError(error);
      }
    },
    getColor(name) {
      const colors = [
        "#FF5733",
        "#33FF57",
        "#3357FF",
        "#FF33A1",
        "#F0A500",
        "#9400D3",
        "#20B2AA",
        "#FF6347",
        "#8A2BE2",
        "#5F9EA0",
      ];
      const hash = [...name].reduce((acc, char) => acc + char.charCodeAt(0), 0);
      return colors[hash % colors.length];
    },
    triggerFileInput() {
      this.$refs.fileInput.click(); // Programmatically trigger the file input click
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const contentType = file.type || "application/octet-stream";

        const payload = {
          fileName: file.name,
          fileSize: file.size,
          contentType: contentType,
          moduleName: "customerSupportTicket",
          fileCategory: "customerSupportTicketAttachment",
        };

        axios
          .post(`${this.$apiEndPoint}/company/putFileSignedURL`, payload)
          .then((response) => {
            const signedUrl = response.data.s3URL;
            const fileId = response.data.id;

            const xhr = new XMLHttpRequest();
            xhr.open("PUT", signedUrl, true);
            xhr.setRequestHeader("Content-Type", contentType);

            xhr.onload = () => {
              if (xhr.status === 200) {
                this.attachmentId = fileId;
                this.selectedFileName = file.name; // Update selected file name
                this.$toast("File uploaded successfully", "success");
              } else {
                this.$toast("Error uploading file", "error");
              }
            };

            xhr.onerror = () => {
              this.$toast("Error uploading file", "error");
            };

            xhr.send(file);
          })
          .catch((error) => {
            this.$handleError(error);
          });
      }
    },

    formatDate(dateString) {
      const date = new Date(dateString);
      const now = new Date();
      const diff = now - date;

      const seconds = Math.floor(diff / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      const months = Math.floor(days / 30);
      const years = Math.floor(months / 12);

      if (seconds < 60) return "just now";
      if (minutes < 60) return this.pluralize(minutes, "m");
      if (hours < 24) return this.pluralize(hours, "h");
      if (days < 30) return this.pluralize(days, "d");
      if (months < 12) return this.pluralize(months, "month");

      return this.pluralize(years, "year");
    },
    pluralize(value, unit) {
      return `${value}${unit}${value > 1 ? "s" : ""}`;
    },

    closePage() {
      this.$router.go(-1);
    },
    fetchHistory() {
      axios
        .get(`${this.$apiEndPoint}/ticketComment/getByTicketId/${this.Id}`)
        .then((response) => {
          this.History = response.data;
        })
        .catch((error) => this.$handleError("Error fetching comments:", error));
    },
    async fetchById() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/ticket/getTicketById/${this.Id}`
        );
        console.log(response.data);
        this.form = response.data;
        console.log(this.form);
        this.uploadedFiles = this.form.raisedAttachments.map((attachment) => {
          return {
            id: attachment.id,
            ticketId: attachment.ticketId,
            awsFileId: attachment["awsFiles.id"],
            awsFileName: attachment["awsFiles.awsFileName"],
            name: attachment["awsFiles.fileName"], // Extract fileName
          };
        });
        this.ResolveduploadedFiles = this.form.resolutionAttachments.map((attachment) => {
          return {
            id: attachment.id,
            ticketId: attachment.ticketId,
            awsFileId: attachment["awsFiles.id"],
            awsFileName: attachment["awsFiles.awsFileName"],
            name: attachment["awsFiles.fileName"], // Extract fileName
          };
        });
      } catch (error) {
        this.$handleError(error);
      }
    },

    addMoreFiles() {
      document.getElementById("attachment").click();
    },
    removeFile(index) {
      this.uploadedFiles.splice(index, 1);
    },
    async submitTicket() {
      try {
        const payload = {
          title: this.form.issueTitle,
          description: this.form.issueDescription,
          moduleName: this.form.moduleName,
          raisedDate: this.form.raisedDate,
          priority: this.form.priority,
          raisedByEntityId: this.$LocalUser.user.employeeId,
          raisedByEntityName: "Employee",
          attachments: this.uploadedFileIds,
        };
        console.log(payload);
        const response = await axios.post(`${this.$apiEndPoint}/ticket`, payload);
        const ticketId = response.data.id;

        await this.UploadFilesToFolder(ticketId);

        this.$toast("Ticket submitted successfully", "success");
      } catch (error) {
        this.$handleError(error);
      }
    },
    async UploadFilesToFolder(ticketId) {
      try {
        const requestBody = {
          ticketId,
          type: "resolution",
          awsFileId: this.uploadedFileIds,
        };
        console.log(requestBody);
        await axios.post(`${this.$apiEndPoint}/ticketAttachment`, requestBody);

        this.uploadedFileIds = [];
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
};
</script>

<style scoped>
.ticket-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #f8f9fa;
  padding: 1rem 2rem;
}

.close-button {
  background-color: transparent;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  color: red;
}

.ticket-form {
  width: 96%;
  margin: 0.5rem 2rem;
  min-height: 60%;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.form-group {
  margin-bottom: 1rem;
  font-size: 14px;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 300;
  font-size: 12px;
}

.mandatory {
  color: red;
}

.attachment-input {
  display: flex;
  align-items: center;
}

.uploaded-files {
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.uploaded-files ul {
  list-style: none;
  padding: 0;
}

.uploaded-file {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.remove-btn {
  background-color: transparent;
  border: none;
  color: red;
  font-size: 14px;
  cursor: pointer;
}

.form-actions {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f8f9fa; /* Optional: Background for better visibility */
  padding: 1rem 2rem;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #ddd; /* Optional: Top border for separation */
}
.btn {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
}

.btn-secondary {
  background-color: #6c757d;
  color: #fff;
}
.attatchmentlabel.form-group {
  margin-bottom: 1.5rem;
}

.attatchmentlabel .attachment-input {
  gap: 10px; /* Adds spacing between elements */
}

.attatchmentlabel .add-files-btn {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px dashed #ccc;
  cursor: pointer;
  text-align: center;
  font-size: 18px;
  color: #666;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s, border-color 0.3s;
}

.attatchmentlabel .add-files-btn:hover {
  background-color: #f5f5f5;
  border-color: #999;
}

.attatchmentlabel label {
  font-size: 14px;
  font-weight: bold;
}
input:focus,
textarea:focus,
select:focus {
  outline: none; /* Remove default outline */
  box-shadow: none; /* Add a custom focus shadow */
}

.comment-body {
  max-height: 300px;
  overflow-y: auto;
}

.parent-comment {
  font-size: 12px;
  margin-bottom: 5px;
  padding-bottom: 5px;
  width: 96%;
  overflow-x: hidden;
}
.comment-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 12px;
  text-align: center;
  line-height: 30px;
  color: #fff;
}

.comment-header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.comment-info {
  display: flex;
  flex-direction: column;
}

.comment-author {
  font-weight: bold;
}

.comment-date {
  font-size: 12px;
  color: #aaa;
}

/* Display text with line breaks and preformatted content */
.comment-text {
  white-space: pre-wrap; /* Preserve line breaks and spaces */
  font-size: 13px;
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 4px;
  font-family: "Inter", sans-serif;
  margin-left: 25px;
}
.comment-avatar-container {
  display: flex;
  align-items: center;
}
.cummentdocument {
  transform: scale(1.1);
}
.comments-container {
  display: flex;
  flex-direction: column;
  max-height: 60vh;
  max-width: 95%;
  overflow-x: hidden;

  padding: 10px 10px 100px 10px;
  border-radius: 5px;
  background-color: #fff;
}

.comments-history {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 5px;
}

.add-comment {
  border-radius: 5px;
  padding: 10px;
  background-color: #f9f9f9;
  position: absolute;
  bottom: 1%;
  width: 23%;
}

.comment-actions {
  display: flex;
  align-items: center;
}

.file-upload {
  flex: 0 0 auto;
}

.file-upload input[type="file"] {
  width: auto;
  font-size: 0.875rem;
}

.file-name {
  font-size: 0.75rem;
}

.btn-sm {
  padding: 2px 8px;
  font-size: 0.75rem;
}
@media (max-width: 768px) {
  .ticket-header h5 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }

  .close-button {
    align-self: flex-end;
  }

  .ticket-form {
    margin: 0rem 0rem;
    width: 98%;
    padding: 0 1rem;
  }

  .form-group label {
    font-size: 12px;
  }

  .btn {
    font-size: 14px;
    padding: 0.4rem 0.8rem;
  }

  .form-actions {
    padding: 0.5rem;
  }

  .uploaded-file {
    font-size: 14px;
  }

  .add-files-btn {
    width: 24px;
    height: 24px;
    font-size: 16px;
  }
}
</style>
