<template>
  <ProcurementProductList @fetchData="fetchData" :selectedTargetId="SelectedTargetId" />
  <div>
    <Header />

    <div class="content-Container">
      <Nav />
      <div class="containerChild">
        <div class="FormatTable" v-if="showUnclaimedList">
          <div class="row headerOfTable d-flex">
            <div class="col-md-12 d-flex align-content-center">
              <div class="col-md-6 d-flex align-content-center" style="margin-left: 0%">
                <div class="col-md-2 mt-2 text-nowrap">
                  <h5 class="mainHeading">Product Master</h5>
                </div>
                <div class="col-md-2" style="visibility: hidden">
                  <img
                    src="../../assets/icons/addRows.png"
                    alt=""
                    class="btn"
                    data-bs-toggle="modal"
                    data-bs-target="#CreateEmployee"
                    data-bs-whatever="@mdo"
                    id="addicon"
                    @click="openCreateEmployeeModal(null)"
                  />
                </div>
              </div>
              <div class="col-md-6 d-flex align-items-center">
                <div class="col-md-3 ImportExport" style="visibility: hidden">
                  <div class="row">
                    <div class="col-md-4 tableTextEvenRow">Import</div>
                    <div class="col-md-3 tableTextEvenRow">
                      <img src="../../assets/icons/import.png" alt="" id="ImportIcon" />
                    </div>
                  </div>
                </div>
                <div class="col-md-3 ImportExport" style="visibility: hidden">
                  <div class="row">
                    <div class="col-md-4 tableTextEvenRow">Export</div>
                    <div class="col-md-3 tableTextEvenRow">
                      <img src="../../assets/icons/export.png" alt="" id="ImportIcon" />
                    </div>
                  </div>
                </div>

                <div class="col-md-1" style="visibility: hidden">
                  <img
                    src="../../assets/icons/archive.png"
                    alt=""
                    id="ArchiveIcon"
                    @click="handleArchiveClick"
                  />
                </div>
                <div class="col-md-4 searchboxOfTable">
                  <div class="search-box">
                    <img
                      src="../../assets/icons/search.png"
                      alt="Search Icon"
                      id="SerchIconOfTable"
                    />
                    <input
                      type="text"
                      name="search"
                      id=""
                      v-model="searchString"
                      @input="searchProducts"
                    />
                  </div>
                  <span>
                    <img
                      src="../../assets/icons/filter.png"
                      alt="Search Icon"
                      id="FilterIconOfTable"
                    />
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-12 d-flex align-items-center">
              <div class="col-md-6 d-flex align-items-center">
                <!-- Download Template Button -->
                <span>
                  <button
                    class="btn btn-sm btn-outline-success"
                    style="font-size: 10px"
                    @click="downloadTemplate"
                  >
                    Download Template
                  </button>
                </span>

                <!-- Import Section 1 -->
                <span
                  class="d-flex align-items-center tableTextEvenRow p-2"
                  style="border-radius: 5px; margin: 0 20px"
                >
                  <label
                    class="tableTextEvenRow import-label bodyText"
                    style="cursor: pointer; margin-right: 5px"
                  >
                    Import
                    <input type="file" style="display: none" @change="handleFileUpload" />
                  </label>
                  <img
                    src="../../assets/icons/import.png"
                    alt="Import Icon"
                    style="width: 20px; height: 20px"
                  />
                </span>

                <!-- Import Section 2 (if needed, otherwise can be removed) -->
                <span
                  class="d-flex align-items-center tableTextEvenRow p-2 d-none"
                  style="border-radius: 5px; margin: 0 20px"
                >
                  <label
                    class="tableTextEvenRow import-label bodyText"
                    style="cursor: pointer; margin-right: 5px"
                  >
                    Export
                    <input type="file" style="display: none" />
                  </label>
                  <img
                    src="../../assets/icons/import.png"
                    alt="Import Icon"
                    style="width: 20px; height: 20px"
                  />
                </span>

                <!-- Add Material Button -->
                <span style="margin: 0 20px">
                  <button
                    type="button"
                    class="btn btn-sm btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#ProcurementProductList"
                    @click="openModal(null)"
                  >
                    + Add Product
                  </button>
                </span>
              </div>
            </div>
          </div>

          <div class="table-wrapper">
            <table class="tableFormat mb-3">
              <caption class="d-none">
                Table
              </caption>
              <thead>
                <tr>
                  <th class="tableHeaderHeading text-left">Product Name</th>
                  <th class="tableHeaderHeading text-left">Description</th>
                  <th class="tableHeaderHeading text-center">HSN Code</th>
                  <th class="tableHeaderHeading text-left">Artical No.</th>
                  <th class="tableHeaderHeading text-right">Category</th>
                  <th class="tableHeaderHeading text-center">Discount(%)</th>
                  <th class="tableHeaderHeading text-center">GST(%)</th>
                  <th class="tableHeaderHeading text-right">Unit Price</th>
                  <th class="tableHeaderHeading text-right">Status</th>
                  <th class="tableHeaderHeading text-right">Action</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="cardLoading">
                  <TableSkeletonLoader :columns="10" />
                </template>
                <template v-else>
                  <tr
                    v-for="(data, index) in displayedData"
                    :key="data.id"
                    class="bodyText"
                  >
                    <td class="text-left">
                      <span style="margin-right: 20px">
                        <img
                          v-if="data.signedUrl"
                          :src="
                            data.signedUrl
                              ? data.signedUrl
                              : '../../assets/icons/profile.png'
                          "
                          style="
                            width: 40px;
                            height: 40px;
                            object-fit: cover;
                            image-rendering: auto;
                          "
                          alt=""
                        />
                        <img
                          v-else
                          src="../../assets/icons/imageNotfound.png"
                          style="
                            width: 40px;
                            height: 40px;
                            object-fit: cover;
                            image-rendering: auto;
                          "
                          alt=""
                        />
                      </span>

                      {{ data.productName }}
                    </td>
                    <td class="text-left">{{ data.description }}</td>
                    <td class="text-center">{{ data.HSNCode }}</td>
                    <td class="text-left">{{ data.articleNo }}</td>
                    <td class="text-right">{{ data.category }}</td>
                    <td class="text-center">
                      {{ data.discount ? data.discount + "%" : "N/A" }}
                    </td>
                    <td class="text-center">{{ data.GST ? data.GST + "%" : "N/A" }}</td>
                    <td class="text-right">
                      {{ $formatToIndianCurrency(data.unitPrice) }}
                    </td>
                    <td class="text-right">
                      <span>
                        <img
                          v-if="data.status === 'Active'"
                          src="../../assets/icons/completed.png"
                          alt="Active"
                          style="width: 15px; height: 15px; margin-bottom: 5px"
                        />
                        <img
                          v-else
                          src="../../assets/icons/onHold.png"
                          alt="Inactive"
                          style="width: 15px; height: 15px; margin-bottom: 5px"
                        />
                      </span>
                      <span
                        class="ms-2"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        >{{ data.status }}</span
                      >
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <button
                          class="dropdown-item"
                          @click="changeStatus(data.id, index, 'Active')"
                        >
                          Active
                        </button>
                        <button
                          class="dropdown-item"
                          @click="changeStatus(data.id, index, 'Discontinue')"
                        >
                          Discontinue
                        </button>
                      </div>
                    </td>
                    <td class="text-right">
                      <div class="ActionImage">
                        <span @click="openModal(data.id)">
                          <img
                            src="../../assets/icons/yellowEdit.png"
                            data-toggle="tooltip"
                            title="Edit"
                            data-bs-toggle="modal"
                            data-bs-target="#ProcurementProductList"
                            alt=""
                          />
                        </span>
                        <span @click="confirmDelete(data, index)">
                          <img
                            src="../../assets/icons/redDelete.png"
                            data-toggle="tooltip"
                            title="Delete"
                            alt=""
                          />
                        </span>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <div class="paginationOfTable">
            <div class="pageControlsTable">
              <img
                src="../../assets/icons/blueLeftarrow.png"
                @click="goToPreviousPage"
                :disabled="!hasPreviousPage"
                alt=""
              />
              <div class="page-numbers">
                <span
                  v-for="pageNumber in pagination"
                  :key="pageNumber"
                  :class="{
                    'page-number': true,
                    'current-page': pageNumber === currentPage,
                  }"
                  @click="goToPage(pageNumber)"
                  >{{ pageNumber }}</span
                >
              </div>

              <img
                src="../../assets/icons/blueRightarrow.png"
                @click="goToNextPage"
                :disabled="!hasNextPage"
                alt=""
              />
            </div>

            <div class="entries-info">
              <span class="entries-text">Entries: {{ displayedData.length }} Rows</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="showBulkUploadModal">
    <div class="modal mt-4" role="dialog" style="display: block">
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
        style="min-width: 80%; overflow: auto"
      >
        <div
          class="modal-content"
          style="
            background-color: white;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
          "
        >
          <div class="modal-header">
            <h5 class="modal-title">Bulk Upload Products</h5>
            <img
              src="../../assets/icons/cancel.png"
              alt=""
              style="
                width: 20px;
                height: 20px;
                position: absolute;
                right: 10px;
                top: 2.5%;
                cursor: pointer;
              "
              @click="closeModal()"
            />
          </div>
          <div class="modal-body p-0" style="max-height: 320px; overflow: auto">
            <table
              style="max-height: 320px; overflow: auto"
              class="table table-responsive"
              v-if="data.productList.length"
            >
              <caption class="d-none">
                Table
              </caption>
              <thead style="margin-top: 0 !important">
                <tr>
                  <th v-for="(value, key) in data.productList[0]" :key="key">
                    {{ key }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(product, index) in data.productList" :key="index">
                  <td v-for="(value, key) in product" :key="key">
                    <span v-if="!value" style="color: red">Please fill this field</span>
                    {{ value }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer justify-content-center">
            <button
              type="button"
              class="btn btn-sm btn-primary confirmationButton"
              :disabled="loading"
              @click="approveBulkProducts"
            >
              Continue Upload
            </button>
            <button type="button" class="btn btn-sm- btn-secondary" @click="closeModal()">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProcurementProductList from "../Modal/ProcurementProductList.vue";
import Header from "../Header.vue";
import Nav from "../Nav.vue";
import axios from "axios";
import * as XLSX from "xlsx";
let pageFlag = true;
export default {
  name: "ProductMaster",
  components: {
    Header,
    Nav,
    ProcurementProductList,
  },
  data() {
    return {
      cardLoading: true,
      totalCount: null,
      totalArchiveCount: null,
      showBulkUploadModal: false,
      SelectedTargetId: null,
      EmployeeProfile: null,
      searchString: "",
      pageSize: 50,
      currentPage: 1,
      showUnclaimedList: true,
      error: null,
      TableData: [],
      ArchiveData: [],
      selectedTeamId: null,
      employeeToDelete: null,
      selectedEmployeeId: null,
      data: {
        productList: [],
      },
    };
  },

  computed: {
    hasPreviousPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      return this.currentPage < Math.ceil(this.totalCount / this.pageSize);
    },
    hasArchivePreviousPage() {
      return this.currentPage > 1;
    },
    hasArchiveNextPage() {
      return this.currentPage < Math.ceil(this.totalArchiveCount / this.pageSize);
    },

    pagination() {
      const pageCount = Math.ceil(this.totalCount / this.pageSize);
      const maxVisiblePages = 5;
      let pages = [];

      if (pageCount <= maxVisiblePages) {
        pages = Array.from({ length: pageCount }, (_, index) => index + 1);
      } else {
        const startPage = Math.max(2, this.currentPage - Math.floor(maxVisiblePages / 2));
        const endPage = Math.min(
          pageCount - 1,
          this.currentPage + Math.floor(maxVisiblePages / 2)
        );

        pages = [1];

        if (startPage > 2) {
          pages.push("...");
        }

        pages.push(
          ...Array.from(
            { length: endPage - startPage + 1 },
            (_, index) => startPage + index
          )
        );

        if (endPage < pageCount - 1) {
          pages.push("...");
        }

        pages.push(pageCount);
      }

      return pages;
    },

    paginationArchive() {
      const pageCount = Math.ceil(this.totalArchiveCount / this.pageSize);
      const maxVisiblePages = 5;
      let pages = [];

      if (pageCount <= maxVisiblePages) {
        pages = Array.from({ length: pageCount }, (_, index) => index + 1);
      } else {
        const startPage = Math.max(2, this.currentPage - Math.floor(maxVisiblePages / 2));
        const endPage = Math.min(
          pageCount - 1,
          this.currentPage + Math.floor(maxVisiblePages / 2)
        );

        pages = [1];

        if (startPage > 2) {
          pages.push("...");
        }

        pages.push(
          ...Array.from(
            { length: endPage - startPage + 1 },
            (_, index) => startPage + index
          )
        );

        if (endPage < pageCount - 1) {
          pages.push("...");
        }

        pages.push(pageCount);
      }

      return pages;
    },
    displayedData() {
      return this.TableData;
    },
    displayedArchiveData() {
      return this.ArchiveData;
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async changeStatus(sourceId, index, status) {
      this.TableData[index].status = status;
      try {
        await axios.put(`${this.$apiEndPoint}/productList/${sourceId}`, {
          status: status,
        });
        // Assuming you have a metShod to fetch source after status change
        this.fetchData();
      } catch (error) {
        this.$handleError(error);
      }
    },
    handleFileUpload(event) {
      // Get the first file from the file input
      const file = event.target.files[0];
      this.$globalState.isLoading = true;

      if (file) {
        const reader = new FileReader(); // Create a new FileReader instance
        reader.readAsArrayBuffer(file); // Read the file as an ArrayBuffer

        // Set up the onload event to handle the file once it's read
        reader.onload = async (e) => {
          try {
            const data = e.target.result; // Get the raw binary data
            const formattedData = this.processWorkbook(data); // Process the workbook data
            this.showUnclaimedList = false; // Hide the unclaimed list in the UI
            this.showBulkUploadModal = true; // Show the bulk upload modal
            this.data = { productList: formattedData }; // Store the processed data
            this.$globalState.isLoading = false;
            // Optionally send the processed data to the server
            // axios.post('/your-endpoint', this.data);
          } catch (error) {
            this.$handleError(error);
          }
        };
      }
    },

    processWorkbook(data) {
      // Read the binary data into a workbook object
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0]; // Get the first sheet name
      const worksheet = workbook.Sheets[sheetName]; // Get the worksheet
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 }); // Convert sheet to JSON
      return this.formatData(jsonData); // Format the JSON data
    },

    formatData(jsonData) {
      const dateColumnHeader = "date"; // Define the date column header
      const headers = jsonData[0]; // Get the headers from the first row

      // Map through each row to create an array of objects
      return jsonData.slice(1).map((row) => {
        const product = {}; // Initialize an object for the product data
        headers.forEach((key, index) => {
          const cell = row[index]; // Get the cell data
          product[key] = this.formatCellValue(key, cell, dateColumnHeader); // Format the cell value
        });
        return product; // Return the formatted product object
      });
    },

    formatCellValue(key, cell, dateColumnHeader) {
      // Check if the cell corresponds to the date column
      if (key === dateColumnHeader && cell !== undefined) {
        const excelDate = Number(cell); // Convert the cell to a number
        const date = new Date((excelDate - 25569) * 86400 * 1000); // Convert to a Date object
        return this.convertToDDMMYYYY(date); // Format the date to DD/MM/YYYY
      }
      return cell !== undefined ? cell : ""; // Return cell value or empty string if undefined
    },

    async approveBulkProducts() {
      const btn = document.querySelector(".confirmationButton");
      btn.classList.add("confirmationButton--loading");
      try {
        this.loading = true;

        // Format the data to match backend expectations
        const backendData = {
          productList: this.data.productList.map((product) => ({
            productName: product?.ProductName || null,
            description: product?.Description || null,
            HSNCode: product?.HSNCode || null,
            articleNo: product?.ArticleNo || null,
            category: product?.Category || null,
            unitType: product?.UnitType || null,
            unitPrice: product?.UnitPrice || null,
            discount: product?.Discount || null,
            GST: product?.GST || null,
          })),
        };
        const response = await axios.post(
          `${this.$apiEndPoint}/productList/bulkCreate/`,
          backendData
        );

        btn.classList.remove("confirmationButton--loading");
        if (response.status === 201) {
          this.showBulkUploadModal = false;
          this.showUnclaimedList = true;
          this.fetchData();
          this.data.productList = [];
          this.loading = false;
        } else {
          this.$toast("Failed to create bulk products", "error");
          this.loading = false;
        }
      } catch (error) {
        this.$handleError(error);
        this.loading = false;
        this.data.productList = [];
        this.showBulkUploadModal = false;
        this.showUnclaimedList = true;
        btn.classList.remove("confirmationButton--loading");
      }
    },

    closeModal() {
      // Close modal logic
      this.showBulkUploadModal = false;
      this.showUnclaimedList = true;
    },
    openModal(TargetId) {
      this.SelectedTargetId = TargetId;
    },
    downloadTemplate() {
      const link = document.createElement("a");
      link.href = "/Product_Master_Import_Template.csv"; // Path to the file in the public folder
      link.setAttribute("download", "Product_Master_Import_Template.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    async ReInviteEmployee(employeeEmailId) {
      try {
        await axios.post(`${this.$apiEndPoint}/employee/mailInvitation`, {
          employeeEmailId: employeeEmailId, // Pass the employee's email as part of the request body
        });
        this.$toast("Invitation sent successfully:", "success");
      } catch (error) {
        this.$handleError(error);
      }
    },
    goToEmployeeProfile(employee) {
      const encodedId = btoa(employee.id.toString());
      this.$router.push({
        name: "EmployeeProfile",
        params: {
          employeeId: encodedId,
        },
      });
    },

    openCreateEmployeeModal(employeeId) {
      // Set the selected employee ID
      this.selectedEmployeeId = employeeId;
      // Show the modal or perform other actions
      // Example: this.showModal = true;
    },

    /*  findEmployeeRole(employeeRoles, type) {
    const role = employeeRoles.find(role => role.team && type === 'team');
    const team = employeeRoles.find(role => role.role && type === 'role');
    return type === 'team' ? (role ? role.team : '') : (team ? team.role : '');
  }, */
    getAllTeams(employeeRoles) {
      if (!employeeRoles || employeeRoles.length === 0) return "";
      return employeeRoles.map((role) => role.team).join(", ");
    },
    getAllRoles(employeeRoles) {
      if (!employeeRoles || employeeRoles.length === 0) return "";
      return employeeRoles.map((role) => role.role).join(", ");
    },
    goToPreviousPage() {
      if (this.hasPreviousPage) {
        this.changePage(-1); // Go to the previous page
      }
    },
    goToNextPage() {
      if (this.hasNextPage) {
        this.changePage(1); // Go to the next page
      }
    },
    goToArchivePreviousPage() {
      if (this.hasArchivePreviousPage) {
        this.changePage(-1); // Go to the previous page
      }
    },
    goToArchiveNextPage() {
      if (this.hasArchiveNextPage) {
        this.changePage(1); // Go to the next page
      }
    },
    async fetchData() {
      this.cardLoading = true;
      let searchData = {
        searchString: this.searchString,
      };
      const hasSearchData = searchData.searchString;
      if (hasSearchData && pageFlag === true) {
        this.currentPage = 1;
        pageFlag = false;
      }
      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/productList/${this.currentPage}`,
          searchData
        );
        this.totalCount = response.data.count;
        this.TableData = response.data.rows;
        this.cardLoading = false;
        // Loop through the products to fetch the signed URLs
        for (let product of this.TableData) {
          if (product["awsFiles.awsFileName"] && product["awsFiles.fileName"]) {
            const signedUrl = await this.fetchInfoImage(
              product["awsFiles.awsFileName"],
              product["awsFiles.fileName"]
            );
            if (signedUrl) {
              // Add the signed URL to the product object
              product.signedUrl = signedUrl;
            }
          }
        }
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchInfoImage(AwsFileName, FileName) {
      const PayLoad = {
        awsFileName: AwsFileName,
        fileCategory: "procurementProduct",
        moduleName: "procurement",
        fileName: FileName,
      };

      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/company/getFileSignedURL/`,
          PayLoad
        );
        return Object.values(response.data)[0];
      } catch (error) {
        return null;
      }
    },

    async searchProducts() {
      pageFlag = true;
      this.fetchData();
    },

    confirmDelete(employeeToDelete, index) {
      const employeeId = employeeToDelete.id;

      // Remove the employee from the displayedData array
      this.displayedData.splice(index, 1);
      axios
        .delete(`${this.$apiEndPoint}/productList/${employeeId}`)
        .then(() => {
          this.$toast("Product deleted successfully", "success");

          this.fetchData(); // Refresh data after deletion
          this.showModal = false; // Hide the modal
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    //footer pagination
    goToPage(pageNumber) {
      this.selectAll = false;
      this.currentPage = pageNumber;
      this.fetchData();

      this.selectedCustomers = [];
    },
    changePage(delta) {
      this.selectAll = false;
      this.lastPages = this.currentPage;
      this.currentPage += delta;
      this.fetchData();

      this.selectedCustomers = [];
    },
    formatDate(dateString) {
      if (!dateString) return "";
      const isYMDFormat = dateString.includes("-");
      if (isYMDFormat) {
        const [year, month, day] = dateString.split("-");
        return `${day}-${month}-${year}`; // Format as dd-mm-yyyy
      } else {
        // Handle other formats (e.g., dd/mm/yyyy, mm/dd/yyyy)
        // Try to parse the date string to a Date object
        const parsedDate = new Date(dateString);
        if (isNaN(parsedDate.getTime())) {
          return dateString;
        } else {
          // Format the parsed date as dd-mm-yyyy
          const day = parsedDate.getDate().toString().padStart(2, "0");
          const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0");
          const year = parsedDate.getFullYear();
          return `${day}-${month}-${year}`;
        }
      }
    },
  },
};
</script>

<style scoped>
.tableHeaderHeading {
  font-size: 14px;
}
.ActionImage img {
  padding: 5px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.ActionImage img:hover {
  background-color: #ccc;
  border-radius: 50%;
}

.status:hover {
  outline: 1px solid #ccc;
  padding: 1px;
  cursor: pointer;
}

.FormatTable {
  width: 100%;
  height: 100%;
}

.headerOfTable {
  width: 96%;
  padding: 0.5% 1%;
}

#addicon {
  width: 50px;
  height: 40px;
  margin: 5px 0 0 15px;
  outline: none;
}

#ImportIcon {
  width: 100%;
}

#ArchiveIcon {
  width: 30%;
}

#SerchIconOfTable {
  width: 6%;
  margin-right: 5%;
}

.search-box {
  border-bottom: 2px solid #000;
  padding-bottom: 3px;
  display: flex;
  align-items: center;
}

.searchboxOfTable {
  width: 100%;
  display: flex;
  align-items: center;
}

#FilterIconOfTable {
  width: 20%;
  height: 5%;
  margin-left: 10%;
}

input[type="text"] {
  border: none;
  outline: none;
  font-size: 16px;
  width: 100%;
}

.table-wrapper {
  max-height: 71vh;
  overflow-y: auto;
  margin: 0% 1%;
}

/* Table styling */
.tableFormat {
  white-space: nowrap;
  max-height: 100%;
  width: 100%;
  overflow: scroll;
}

.tableFormat thead,
.tableFormat tbody {
  white-space: nowrap;
}

/* Sticky header */

.tableFormat thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

/* Scrollable body */
.tableFormat tbody {
  overflow: scroll;
}
/*
.tableFormat tbody  tr td{
 border: 1px solid #ccc;
}*/

.table-wrapper .tableFormat tbody tr:not(:first-child):nth-child(even) {
  background-color: #f8f8f8;
}

.dropdown-menu {
  z-index: 9999;
}

.tableFormat tbody tr td {
  padding: 0px 8px;
  height: auto;
}

.tableFormat thead tr th {
  background-color: #bcbfc6;
  padding: 5px 8px;
  height: auto;
}

.tableFormat tbody tr {
  height: 92px;
}

#blackThreeDots {
  width: 20px;
  height: 20px;
  margin-left: 30%;
  padding-bottom: 10%;
  position: relative;
  cursor: pointer;
}

#checkBox2 {
  width: 16px;
  height: 17px;
}

.page-numbers span {
  margin: 5px;
  cursor: pointer;

  /* Adjust color for page numbers */
}

.BoardHeader {
  display: flex;
  justify-content: space-between;
  /* Ensure items are spaced apart */
  align-items: center;
  /* Center items vertically */
  white-space: nowrap;
  /* Prevent wrapping */
}

.bolderHeadings {
  font-size: 16px;

  color: #000000;
  font-weight: bolder;
}

.tabledropdownEdits {
  position: absolute;
  display: inline-block;
  background-color: #ffffff;
  width: 150px;
  height: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin: 10px 20px;
  z-index: 9000;
  top: 60%;
}

.tabledropdownEdits p {
  padding: 4px 8px;
  text-decoration: none;
  display: flex;
  cursor: pointer;
  color: #000000;
}

.SigleProductThings {
  position: absolute;
  display: inline-block;
  background-color: #ffffff;
  width: 150px;
  height: 240px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin: 10px 20px;
  z-index: 1000;
  top: 60%;
}

.SigleProductThings p {
  padding: 4px 8px;
  text-decoration: none;
  display: flex;
  cursor: pointer;
  color: #000000;
}

@media (max-width: 768px) {
  #addicon {
    width: 25px;
    height: 25px;
    margin-left: 10px;
  }

  .table-wrapper {
    max-height: 90%;
    /* Adjust as needed */
    overflow-y: auto;
  }

  .headerOfTable {
    width: 100%;
    overflow: scroll;
  }

  #ArchiveIcon {
    width: 30%;
  }

  .ImportExport {
    display: none;
  }
  .paginationOfTable {
    bottom: 0.5%;
  }
}
</style>
