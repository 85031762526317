<template>
  <div class="container">
    <h2 class="title">Choose the Right Plan for Your Needs</h2>

    <!-- <label class="label">Number of Licenses</label> -->
    <!-- <input
      type="number"
      v-model.number="noOfLicense"
      min="1"
      class="input"
      @input="showAmount(this.selectedPlan)"
    /> -->

    <div class="pricing-container">
      <div
        v-for="(plan, index) in plans"
        :key="index"
        class="single-pricing-table"
        @mouseenter="hoveredPlan = index"
        @mouseleave="hoveredPlan = null"
        :class="{ hovered: hoveredPlan === index }"
      >
        <div style="padding: 20px 20px 0px 20px">
          <div class="d-flex justify-content-between">
            <p class="pricing-header">{{ plan.name }}</p>
            <p class="pricing_dec" v-if="plan.name == 'Quarterly'">Most Popular</p>
            <p class="pricing_dec" v-if="plan.name == 'Yearly'">Best Value</p>
          </div>

          <div class="price text-start">
            ₹ {{ plan.price }}/month <br />
            <p v-if="plan.name == 'Monthly'">Billed Monthly</p>
            <p v-if="plan.name == 'Quarterly'">₹ {{ plan.amount }} Billed Quarterly</p>
            <p v-if="plan.name == 'Yearly'">₹ {{ plan.amount }} Billed Yearly</p>
          </div>
        </div>
        <!-- Bottom section with blue background -->
        <div class="pricing-footer">
          <p class="default-btn" @click="showAmountPopup(plan)">Subscribe Now</p>
        </div>
      </div>
    </div>

    <transition name="fade">
      <div v-if="showPopup" class="popup">
        <div class="popup-content">
          <div class="header">
            <h3>Billing Summary</h3>
            <button class="close-btn" @click="closeModal()">✖</button>
          </div>

          <hr />

          <div class="plan-details">
            <p><strong>Plan:</strong> {{ selectedPlan.name }}</p>
            <p>
              <strong>Start Date:</strong>
              <span>
                <input
                  class="form-control"
                  type="date"
                  v-model="licenseFromDate"
                  @change="updateRenewalDate"
                />
              </span>
            </p>
            <p>
              <strong>Renewal Date:</strong>
              <span>
                <input
                  class="form-control"
                  type="date"
                  v-model="licenseToDate"
                  readonly
                />
              </span>
            </p>

            <div class="license-counter">
              <span><strong>No. of Licences:</strong></span>
              <div class="values">
                <button @click="decreaseLicense">-</button>
                <span>{{ noOfLicense }}</span>
                <button @click="increaseLicense">+</button>
              </div>
            </div>
          </div>

          <div class="billing-card">
            <div class="billing-row">
              <span class="label" style="font-size: small">No. of License</span>
              <span class="value">{{ noOfLicense }}</span>
            </div>
            <div class="billing-row">
              <span class="label" style="font-size: small">Cost per License</span>
              <span class="value">₹ {{ this.planAmount }}</span>
            </div>
            <div class="billing-row">
              <span class="label" style="font-size: small">VAT</span>
              <span class="value">₹ 00</span>
            </div>
            <div class="billing-row total-row">
              <span class="label">Total Amount</span>
              <span class="value">₹ {{ this.totalAmount }}</span>
            </div>
          </div>

          <div class="auto-renewal">
            <input type="checkbox" v-model="autoRenewal" />
            <label>Auto Renewal</label>
          </div>

          <div class="button-group">
            <button class="cancel-btn" @click="closeModal()">Cancel</button>

            <button class="proceed-btn" @click="handlePayment">Proceed to Payment</button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
/* global Razorpay */
export default {
  data() {
    return {
      API_BASE_URL: this.$apiEndPoint,
      noOfLicense: "0",
      showPopup: false,
      selectedPlan: {},
      planAmount: "",
      totalAmount: 0,
      razorpayLoaded: false,
      count: "",
      licenseFromDate: "",
      licenseToDate: "",
      plans: [
        { name: "Monthly", price: 999, amount: 999 },
        { name: "Quarterly", price: 850, amount: 2550 },
        { name: "Yearly", price: 700, amount: 8400 },
      ],
    };
  },
  mounted() {
    this.loadRazorpayScript();
    console.log(this.$LocalUser.user);
    console.log(this.$apiEndPoint);
  },
  methods: {
    increaseLicense() {
      this.noOfLicense = Number(this.noOfLicense) + 1; // Ensure it's a number before adding
      console.log("The number of licenses is now increasing to:", this.noOfLicense);
      this.totalAmount = this.planAmount * this.noOfLicense;
    },

    decreaseLicense() {
      if (this.noOfLicense > 0) {
        this.noOfLicense = Number(this.noOfLicense) - 1; // Ensure subtraction works correctly
        console.log("The number of licenses is now decreasing to:", this.noOfLicense);
        this.totalAmount = this.planAmount * this.noOfLicense;
      }
    },

    async loadRazorpayScript() {
      if (this.razorpayLoaded) return;
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => (this.razorpayLoaded = true);
      document.body.appendChild(script);
    },

    updateRenewalDate() {
      if (!this.licenseFromDate || !this.selectedPlan.name) return;

      const startDateObj = new Date(this.licenseFromDate);

      if (this.selectedPlan.name.toLowerCase() === "monthly") {
        startDateObj.setMonth(startDateObj.getMonth() + 1);
      } else if (this.selectedPlan.name.toLowerCase() === "quarterly") {
        startDateObj.setMonth(startDateObj.getMonth() + 3);
      } else if (this.selectedPlan.name.toLowerCase() === "yearly") {
        startDateObj.setFullYear(startDateObj.getFullYear() + 1);
      }

      this.licenseToDate = startDateObj.toISOString().split("T")[0];
    },

    closeModal() {
      this.showPopup = false;
      this.noOfLicense = 0;
      this.totalAmount = 0;
      this.licenseFromDate = "";
      this.licenseToDate = "";
    },

    showAmountPopup(plan) {
      console.log("the selected plan is ", plan);
      this.selectedPlan = plan;
      this.planAmount = plan.amount;
      console.log("plan amount is", this.planAmount);
      // this.totalAmount = this.planAmount * this.noOfLicense;
      this.showPopup = true;
    },

    showAmount(plan) {
      this.totalAmount = plan.amount * this.noOfLicense;
    },

    async handlePayment() {
      this.showPopup = false;
      if (!this.razorpayLoaded) return alert("Razorpay failed to load.");

      const orderId = await this.createOrder(this.totalAmount);
      if (!orderId) return;

      const options = {
        key: "rzp_test_E0YH2Wdii0duFT",
        amount: this.totalAmount,
        currency: "INR",
        name: "PTG Technologies",
        description: `Subscription Payment - ${this.selectedPlan.name}`,
        order_id: orderId,
        handler: (response) => this.verifyPayment(response, orderId),
        prefill: {
          name: "Customer",
          email: "customer@example.com",
          contact: "9999999999",
        },
        theme: { color: "#007bff" },
      };
      new Razorpay(options).open();
    },

    async createOrder(amount) {
      try {
        const response = await fetch(`${this.API_BASE_URL}/subscription/orders`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.$LocalUser.accessToken}`, // Add your token here
          },
          body: JSON.stringify({
            amount: amount,
            currency: "INR",
            receipt: "order_" + Date.now(),
            planType: this.selectedPlan.name.toLowerCase(),
          }),
        });
        const data = await response.json();
        if (!response.ok) throw new Error(data.message || "Order creation failed");
        return data.id;
      } catch (error) {
        // alert("Order creation failed. Try again.") ;
        console.log(error);
        return null;
      }
    },

    async verifyPayment(response, orderId) {
      try {
        const verifyResponse = await fetch(
          `${this.API_BASE_URL}/subscription/verifyPayment`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.$LocalUser.accessToken}`, // Add your token here
            },
            body: JSON.stringify({
              orderId,
              transactionId: response.razorpay_payment_id,
              signature: response.razorpay_signature,
              amount: this.totalAmount,
              noOfLicense: this.noOfLicense,
              companyId: this.$LocalUser.user.companyId,
              planType: this.selectedPlan.name.toLowerCase(),
            }),
          }
        );
        const data = await verifyResponse.json();
        if (!verifyResponse.ok)
          throw new Error(data.message || "Payment verification failed");
        alert("Payment Successful!");
      } catch (error) {
        alert("Payment verification failed. Contact support.");
      }
    },
  },
};
</script>

<style scoped>
.form-control {
  display: block;
  width: 100%;
  padding: 4px;
  font-size: small;
  font-weight: 400;
  line-height: 1;
  color: var(--bs-body-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15sease-in-out, box-shadow 0.15sease-in-out;
}

.billing-card {
  background: #f9f9f9;
  border-radius: 8px;
  padding: 15px;
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.billing-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
}

.billing-row:last-child {
  border-bottom: none;
}

.label {
  font-size: 15px;
  color: #555;
}

.value {
  font-size: 15px;
  font-weight: bold;
  color: #222;
}

.total-row .label,
.total-row .value {
  font-size: 16px;
  font-weight: bold;
  color: #000;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-btn {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.plan-details p {
  margin: 5px 0;
  display: grid;
}

.plan-details {
  display: flex;
  justify-content: space-between;
}

.license-counter {
  display: grid;
  justify-items: center;
  gap: 10px;
}

.license-counter button {
  padding: 2px 8px;
  border: 1px solid #ccc;
  cursor: pointer;
}

.billing-table {
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
}

.billing-table td {
  padding: 5px;
  border-bottom: 1px solid #ddd;
}

.total-row td {
  font-weight: bold;
}

.auto-renewal {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 10px 0;
}

.button-group {
  display: flex;
  justify-content: space-between;
}

.proceed-btn {
  background: #0057ff;
  color: white;
  padding: 8px 15px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.cancel-btn {
  background: #ccc;
  color: black;
  padding: 8px 15px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}
.header h3 {
  font-size: 18px;
  font-weight: bold;
  color: #222; /* Darker for better readability */
}

.close-btn {
  font-size: 15px;
  color: #666;
}

.close-btn:hover {
  color: #ff3b3b; /* Red on hover */
}

.plan-details p {
  font-size: 15px;
  color: #444;
}

.license-counter span {
  font-size: 15px;
  color: #333;
  padding: 0px 8px;
}

.billing-table {
  font-size: 14px;
  color: #333;
}

.billing-table td {
  color: #444;
}

.total-row td {
  font-size: 16px;
  color: #000;
}

.auto-renewal {
  font-size: 14px;
  color: #555;
}

.proceed-btn {
  font-size: 14px;
  background: #007bff;
  color: white;
}

.proceed-btn:hover {
  background: #0056d2;
}

.cancel-btn {
  font-size: 14px;
  background: #ccc;
  color: black;
}

.cancel-btn:hover {
  background: #b3b3b3;
}

.container {
  max-width: 100%;

  max-width: 100%;
  text-align: center;
  animation: fadeIn 0.8s ease-in-out;
  height: 100vh;
  background: #ffffff;
  padding: 2% 5%;
}
.title {
  font-size: 28px;
  margin-bottom: 20px;
}
.label {
  font-size: 16px;
  font-weight: bold;
}
.input {
  width: 100px;
  padding: 8px;
  margin: 10px;
}
.pricing-container {
  display: flex;
  justify-content: center;
  gap: 120px;
  flex-wrap: wrap;
}
.single-pricing-table {
  border: 2px solid #ddd;
  border-radius: 0px 0px 20px 20px;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
  width: 350px;
  height: 180px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Blue footer section */
.pricing-footer {
  background-color: #007bff;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 20px 20px;
  color: #ffffff;
}

.default-btn {
  border: none;
  padding: 10px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  transition: 0.3s;
}

.single-pricing-table.hovered {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  border-color: #007bff;
}
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.confirm-btn {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  transition: background 0.3s;
}
.confirm-btn:hover {
  background-color: #0056b3;
}
.cancel-btn {
  margin-left: 10px;
  background-color: #dc3545;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  transition: background 0.3s;
}
.cancel-btn:hover {
  background-color: #a71d2a;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.pricing-footer:hover {
  background-color: gray;
  color: #ffffff;
}
.pricing_dec {
  font-size: 10px;
  display: flex;
  align-items: center;
  color: #084f5b;
  background-color: #d0f9c3;
  padding: 6px;
  border-radius: 5px;
}
.pricing-header {
  padding-top: 5px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
