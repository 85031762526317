<template>
  <div class="HeaderofApp">
    <div class="header blackBgColor">
      <div class="div1">
        <img
          src="../assets/icons/black_burgerIcon.png"
          alt=""
          @click="expandNavContent"
          id="burgerMenu"
        />
        <a href="/home" style="text-decoration: none">
          <img
            src="../assets/icons/ArivuLogo.png"
            alt=""
            style="width: 28px; height: 28px"
            class="arivuLogo WEB"
          />
        </a>
        <a href="/home" style="text-decoration: none">
          <h3 style="font-family: 'Athena', sans-serif" class="NameOfTheAppliction">
            arivu
          </h3>
        </a>
      </div>

      <div class="div2">
        <div class="searchBar">
          <img
            class="searchIcon"
            src="../assets/icons/whiteSearch.png"
            alt="searchIcon"
          />
          <input
            type="text"
            ref="searchBarInput"
            placeholder="Search...."
            v-model="searchQuery"
            @input="filterRoutes"
          />
          <div v-if="filteredRoutes.length" class="search-results">
            <ul>
              <li
                v-for="route in filteredRoutes"
                :key="route.name"
                @click="navigateToRoute(route.path)"
              >
                {{ route.meta.title }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="div3">
        <div class="notification-icon-wrapper position-relative ms-5">
          <span
            v-if="count > 0"
            class="notification-count badge bg-danger text-white position-absolute"
            title="New Notifications"
          >
            <span class="countSpan"> {{ count }}</span>
          </span>
          <img
            @click="MarkAsSeen()"
            src="../assets/icons/notificationHeader.png"
            alt="Notifications"
            data-bs-toggle="offcanvas"
            data-bs-target="#NotificationModal"
            style="cursor: pointer"
            class="headernotificationicon"
          />
        </div>
        <img
          class="WEB headerSettingIcon"
          src="../assets/icons/black_Setting.png"
          @click="SettingPage"
          style="cursor: pointer"
          v-if="showSalesSettings"
          alt="Setting"
        />

        <div class="dropdown-Menu" v-if="showEmployeeSettings">
          <!-- Dropdown menu items -->
          <!-- Insert your user information in this section -->
          <div class="menuItems WEB">
            <a id="menuItemHeader" href="/EmployeeList">
              <img
                src="../assets/icons/addEmployee.png"
                alt="Menu"
                style="cursor: pointer"
                class="headerEmployeeIcon"
            /></a>
          </div>
        </div>
        <div class="">
          <span class="dot" @click="toggleDropdown">
            <img
              v-if="EmployeeprofileImage !== null"
              :src="EmployeeprofileImage"
              alt="Profile"
              class="EmployeeprofileImage"
            />
            <h5 v-else id="initials">{{ initials }}</h5>
          </span>

          <!-- Dropdown content -->
          <div v-if="isDropdownOpen" class="dropdown-content">
            <!-- Display user profile information -->
            <div class="user-profile d-flex align-items-center">
              <!-- Display user profile photo -->
              <div class="d-flex flex-column align-items-center justify-content-center">
                <img
                  v-if="EmployeeprofileImage"
                  :src="EmployeeprofileImage"
                  alt="Profile Photo"
                  class="profile-photo"
                />
                <img
                  v-else
                  class="profile-photo"
                  src="../assets/icons/profile.png"
                  alt="Profile Photo"
                />
              </div>

              <!-- Display user name, email, and department -->
              <div class="user-info">
                <p style="font-weight: 500; white-space: nowrap; font-size: 14px">
                  {{ fullName }}
                </p>
                <p class="" style="font-size: 10px">
                  {{ this.$LocalUser.user.employeeNumber }}
                </p>
                <p class="" style="font-size: 10px">
                  {{ this.$LocalUser.user.companyName }}
                </p>
                <!-- Display user roles in the specified format -->
                <!--  -->
              </div>
            </div>

            <!-- Dropdown options (Profile, Settings, Logout) -->
            <div class="d-flex align-items-center p-1" style="background-color: #f8f8f8">
              <span class="ms-4">
                <img
                  src="../assets/icons/designation.png"
                  alt=""
                  style="width: 20px; height: 20px; border-radius: 50%"
                  class="ProfileCarddesignationIcon"
              /></span>

              <a style="font-size: 12px; font-weight: 500">
                <span
                  v-if="
                    userRoles.some(
                      (role) => role.team === 'Admin' && role.role === 'Manager'
                    )
                  "
                >
                  Business Owner
                </span>
                <span
                  v-else-if="
                    userRoles.some(
                      (role) => role.team === 'Admin' && role.role === 'Executive'
                    )
                  "
                >
                  Tool Admin
                </span>
                <span
                  v-else
                  v-for="role in userRoles.slice(0, 1)"
                  :key="role.team"
                  class="d-flex flex-column"
                >
                  <span>
                    {{ role.team }}
                    <span style="font-size: 8px" class="ms-1">({{ role.role }})</span>
                  </span>
                </span>
              </a>
            </div>
            <div class="d-flex align-items-center p-1" style="background-color: #f8f8f8">
              <span class="ms-4">
                <img
                  src="../assets/icons/profileIconBefore.png"
                  alt=""
                  style="width: 20px; height: 20px"
              /></span>

              <a
                style="font-size: 12px; font-weight: 500"
                class="mt-1"
                @click="goToEmployeeProfile"
                >Profile</a
              >
            </div>
            <div class="d-flex align-items-center p-1" style="background-color: #f8f8f8">
              <span class="ms-4">
                <img
                  src="../assets/icons/setting.png"
                  alt=""
                  style="width: 20px; height: 20px"
                  class="profileCardSettingIcon"
              /></span>

              <a
                style="font-size: 12px; font-weight: 500"
                class="mt-1"
                @click="SettingPage"
                >Settings</a
              >
            </div>
            <div
              class="d-flex align-items-center p-1"
              style="background-color: #f8f8f8"
              v-if="this.companies.length > 1"
            >
              <span class="ms-4">
                <img
                  src="../assets/icons/domain-switch.png"
                  alt=""
                  style="width: 20px; height: 20px"
                  class="domainSwitchIcon"
              /></span>

              <a
                style="font-size: 12px; font-weight: 500"
                class="mt-1"
                @click="openSwitchModal"
                >Switch Organization
              </a>
            </div>
            <div
              class="d-flex justify-content-center align-items-center p-2 mt-2"
              style="background-color: #f8f8f8"
            >
              <button
                class="btn btn-danger d-flex align-items-center gap-3 px-4 py-2"
                style="opacity: 1"
                @click="logout"
              >
                <img
                  src="../assets/icons/signOut.png"
                  alt="Logout"
                  style="width: 20px; height: 20px"
                  class="profilecardSignoutIcon"
                />
                <span style="font-size: 14px; font-weight: 500">Logout</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <NotificationModal :projectId="DesignProjectId" @setProjectId="setProjectId" />
    <div v-if="isSwitchModalOpen" class="switch-modal">
      <div class="modal-content">
        <h6 class="modal-title">Organization</h6>
        <hr />
        <div
          v-for="company in companies"
          :key="company['company.id']"
          class="company-item"
          @click="switchCompany(company['company.id'])"
          :class="{
            'selected-company': company['company.id'] === this.$LocalUser.user.companyId,
          }"
        >
          <label class="company-name" :title="company['company.companyName']">
            <span v-if="company['company.id'] === this.$LocalUser.user.companyId">
              <img
                src="../assets/icons/checkmark.png"
                alt=""
                srcset=""
                style="width: 20px; height: 20px"
              />
            </span>
            <span v-else>
              <img
                src="../assets/icons/checkmark.png"
                alt=""
                srcset=""
                style="width: 20px; height: 20px; visibility: hidden"
              />
            </span>
            {{ company["company.companyName"] }}
          </label>
          <span v-if="company.defaultCompany === 1" class="primary-badge"> Primary </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Badge } from "@capawesome/capacitor-badge";
import setAuthHeader from "../util/setAuthHeader";
import axios from "axios";
import NotificationModal from "./Modal/NotificationModal.vue";

export default {
  name: "DesktopHeader",
  components: {
    NotificationModal,
  },
  data() {
    return {
      isDropdownOpen: false,
      count: 0,
      DesignProjectId: null,
      showEmployeeSettings: false,
      showSalesSettings: false,
      EmployeeprofileImage: null,
      initials: "",
      fullName: "",
      userEmail: "",
      userRoles: [],
      searchQuery: "",
      filteredRoutes: [], // Stores the filtered route list
      selectedCompany: null,
      isSwitchModalOpen: false,
      companies: [],
      currentCompany: null, // Default company
    };
  },
  computed: {
    // Filter user roles based on the employeeId
    /*    userRoles() {
         return this.employeeRoles.filter(role => role.team.toLowerCase() === this.user.team.toLowerCase());
       } */
    routes() {
      return this.$router.options.routes;
    },
  },
  watch: {
    // Add this watcher
    "$window.width"() {
      const navContent = document.querySelector(".nav-content");
      if (navContent) {
        if (window.innerWidth <= 1025) {
          navContent.style.width = "0px";
        } else {
          navContent.style.width = this.navContentWidth;
        }
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.closeDropdown);

    let user = localStorage.getItem("user-info");
    let employeeRoles = localStorage.getItem("user-info");
    const { granted } = Badge.requestPermissions();
    if (!granted) {
      console.warn("Badge permission denied");
    }
    if (user) {
      try {
        const DepartmentData = JSON.parse(employeeRoles);
        const userData = JSON.parse(user);
        const userObject = userData.user;
        this.userRoles = DepartmentData.employeeRoles;
        this.initials = userObject.initials;
        this.fullName = `${userObject.firstName} ${userObject.lastName}`;
        this.userEmail = userObject.email;
        // const employeeId = userObject.employeeId;

        this.userId = userObject.id; // Set fullName in component's data
        this.fetchEmployeeProfile(userObject.id);
        this.fetchNotification();
        this.featchCompanyList();

        if (this.userRoles) {
          // Check if the user is a Manager in the Sales or Admin team
          this.showSalesSettings = this.userRoles.some(
            (role) =>
              (role.team === "Sales" ||
                role.team === "HR" ||
                role.team === "Onsite" ||
                role.team === "Admin") &&
              role.role === "Manager"
          );
          this.showEmployeeSettings = this.userRoles.some(
            (role) => role.team === "Admin"
          );
        }
      } catch (error) {
        this.$handleError(error);
      }
    } else {
      this.$router.push({ name: "Login" });
    }
  },
  methods: {
    openSwitchModal() {
      this.isSwitchModalOpen = true;
    },
    closeSwitchModal() {
      this.isSwitchModalOpen = false;
    },

    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    closeDropdown(event) {
      // Close dropdown when clicking outside
      if (!this.$el.contains(event.target)) {
        this.isDropdownOpen = false;
        this.isSwitchModalOpen = false;
      }
    },
    MarkAsSeen() {
      this.DesignProjectId = Math.floor(Math.random() * 1000) + 1;
      this.count = 0;
      try {
        // Call the API to update the status
        const response = axios.put(`${this.$apiEndPoint}/notification/markAsSeen`);
        if (response.data) {
          this.fetchNotification();
        }
      } catch (error) {
        console.error("Error marking as read:", error);
      }
    },
    setProjectId() {
      this.DesignProjectId = null;
      console.log(this.DesignProjectId);
    },
    switchCompany(companyId) {
      console.log(companyId);
      axios
        .post(`${this.$apiEndPoint}/users/switchOrganization/${companyId}`, {
          visitorId: this.$LocalUser.user.visitorId,
        })
        .then((response) => {
          // Clear existing localStorage
          localStorage.clear();
          console.log(response.data);
          localStorage.setItem("user-info", JSON.stringify(response.data));
          localStorage.setItem("jwtToken", response.data.accessToken);
          setAuthHeader(response.data.accessToken);
          this.closeSwitchModal();
          // Reload to apply changes
          this.$router.push({ name: "Home" });
          window.location.reload();
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    featchCompanyList() {
      if (!this.$LocalUser || !this.$LocalUser.user) {
        console.warn("User is not logged in or LocalUser is not defined.");
        return; // Exit early if user is not logged in
      }
      axios
        .get(`${this.$apiEndPoint}/company/getUserCompany`)
        .then((response) => {
          this.companies = response.data;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    fetchNotification() {
      if (!this.$LocalUser?.user) {
        console.warn("User is not logged in or LocalUser is not defined.");
        return; // Exit early if user is not logged in
      }

      const url = `${this.$apiEndPoint}/notification/unreadCounts`;

      axios
        .get(url)
        .then((response) => {
          console.log(response.data);
          this.count = response.data; // Update total notifications count
          Badge.set({ count: this.count });
        })
        .catch((error) => {
          console.error("Error fetching notifications:", error);
        });
    },

    ParamRoutes() {
      // Define a global team array
      const allTeams = [
        "Inventory",
        "Admin",
        "Sales",
        "Onsite",
        "Design",
        "Procurement",
        "Accounts",
        "HR",
      ];

      const routes = [
        {
          path: "/PurchaseRequestList/All",
          meta: {
            title: "Purchase Request List (Procurement)",
            teams: ["Procurement", "Admin"],
          },
        },
        {
          path: "/PurchaseRequestList/Onsite",
          meta: { title: "Purchase Request List (Onsite)", teams: ["Onsite", "Admin"] },
        },
        {
          path: "/PurchaseRequestList/Inventory",
          meta: {
            title: "Purchase Request List (Inventory)",
            teams: ["Inventory", "Admin"],
          },
        },
        {
          path: "/CompanyDocuments/Sales",
          meta: { title: "Sales Documents", teams: ["Sales", "Admin"] },
        },
        {
          path: "/CompanyDocuments/Design",
          meta: { title: "Design Documents", teams: ["Design", "Admin"] },
        },
        {
          path: "/CompanyDocuments/Procurement",
          meta: { title: "Procurement Documents", teams: ["Procurement", "Admin"] },
        },
        {
          path: "/CompanyDocuments/Onsite",
          meta: { title: "Onsite Documents", teams: ["Onsite", "Admin"] },
        },
        {
          path: "/PurchaseOrderList/Procurement",
          meta: {
            title: "Purchase Order List (Procurement)",
            teams: ["Procurement", "Admin"],
          },
        },
        {
          path: "/PurchaseOrderList/Inventory",
          meta: {
            title: "Purchase Order List (Inventory)",
            teams: ["Inventory", "Admin"],
          },
        },
        {
          path: "/PurchaseRequestDetails/Onsite",
          meta: { title: "Create Purchase Request (Onsite)", teams: ["Onsite", "Admin"] },
        },
        {
          path: "/PurchaseRequestDetails/Procurement",
          meta: {
            title: "Create Purchase Request (Procurement)",
            teams: ["Procurement", "Admin"],
          },
        },
        {
          path: "/PurchaseRequestDetails/Inventory",
          meta: {
            title: "Create Purchase Request (Inventory)",
            teams: ["Inventory", "Admin"],
          },
        },
        {
          path: `/EmployeeProfile/${btoa(this.$LocalUser.user.employeeId.toString())}`,
          meta: { title: "Profile", teams: allTeams }, // Using global team array
        },
        {
          path: "/TicketList",
          meta: { title: "TicketList", teams: allTeams },
        },
        {
          path: "/CreateTicket",
          meta: { title: "Create Ticket", teams: allTeams },
        },
      ];

      return routes;
    },

    // generateTitle(routeTeams, userTeams) {
    //   const matchedTeams = routeTeams.filter((team) => userTeams.includes(team));
    //   return `(${matchedTeams.join(", ")})`;
    // },

    filterRoutes() {
      if (this.searchQuery.trim()) {
        const RoutesArray = [...this.routes, ...this.ParamRoutes()];

        // Filter routes based on user roles (teams) and search query
        this.filteredRoutes = RoutesArray.filter((route) => {
          const hasAccess = route.meta?.teams?.some((team) =>
            this.userRoles.some((userRole) => userRole.team === team)
          );

          const matchesSearch = route.meta?.title
            ?.toLowerCase()
            .includes(this.searchQuery.toLowerCase());

          return hasAccess && matchesSearch;
        });
      } else {
        // Clear the filteredRoutes array if searchQuery is empty
        this.filteredRoutes = [];
      }
    },

    navigateToRoute(path) {
      // Navigate to the selected route
      this.$router.push(path);
      this.searchQuery = ""; // Clear search input
      this.filteredRoutes = []; // Clear results
    },

    SettingPage() {
      if (this.showSalesSettings) {
        this.$router.push({ name: "Settings" });
      }
    },
    goToEmployeeProfile() {
      const user = localStorage.getItem("user-info");
      if (user) {
        try {
          const userData = JSON.parse(user);
          const userObject = userData.user;
          const encodedId = btoa(userObject.employeeId.toString());
          // Navigate to EmployeeProfile route with emplId parameter
          this.$router.push({
            name: "EmployeeProfile",
            params: { employeeId: encodedId },
          });
        } catch (error) {
          this.$handleError(error);
        }
      } else {
        this.$router.push({ name: "Login" });
      }
    },
    expandNavContent() {
      const navContent = document.querySelector(".nav-content");

      if (window.matchMedia("(max-width: 1025px)").matches) {
        const isOpen = navContent && navContent.style.width === "250px";
        if (navContent) {
          navContent.style.width = isOpen ? "0px" : "250px";
          navContent.style.transition = "0.3s ease";
        }

        if (!isOpen) {
          document.addEventListener("click", this.handleOutsideClick.bind(this));
        } else {
          document.removeEventListener("click", this.handleOutsideClick.bind(this));
        }
      }
    },

    handleOutsideClick(event) {
      const navContent = document.querySelector(".nav-content");

      if (
        navContent &&
        !navContent.contains(event.target) &&
        event.target.id !== "burgerMenu"
      ) {
        navContent.style.width = "0px";
        navContent.style.transition = "0.3s ease";
        document.removeEventListener("click", this.handleOutsideClick.bind(this));
      }
    },

    fetchEmployeeProfile(employeeuserId) {
      const PayLoad = {
        entityId: employeeuserId,
        fileCategory: "profile",
        moduleName: "company",
      };

      // Make API request to fetch employee profile photo URL
      axios
        .post(`${this.$apiEndPoint}/company/getFileSignedURL/`, PayLoad)
        .then((response) => {
          this.EmployeeprofileImage = Object.values(response.data)[0];
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    logout() {
      this.$logout();
    },
  },
  beforeUnmount() {
    document.removeEventListener("click", this.closeDropdown);
    document.removeEventListener("click", this.closeSwitchModal);
  },
};
</script>

<style scoped>
.searchBar {
  position: relative;
}
.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 5px;
  background: #ffffff;
  border: 1px solid #ddd;
  max-height: 200px;
  z-index: 10;
  border-radius: 10px;
  color: #1e2125;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden; /* Prevent content from spilling out */
}

.search-results ul {
  max-height: 180px;
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 5px;
  margin: 0;
  list-style-type: none;
}

.search-results li {
  padding: 8px;
  cursor: pointer;
  list-style-type: square;
}
.search-results li:hover {
  background: #f0f0f0;
  color: #000000;
}
.NameOfTheAppliction {
  font-weight: 100 !important;
}

.user-info {
  background-color: #ffffff;
}

a {
  cursor: pointer;
}

.HeaderofApp {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.header {
  height: 50px;
  display: flex;
  width: 100%;
  z-index: 1;
  position: relative;
}

.header .div1 {
  width: 25%;
  height: 100%;
  display: flex;
  align-items: center;
}

.header .div1 h3 {
  color: #ffffff;

  text-align: left;
  margin-left: 1px;
}
.arivuLogo {
  margin: 0 10px;
}
.header .div2 {
  width: 55%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.div2 .searchBar {
  width: 65%;
  height: 65%;
  border-radius: 25px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 2px solid #ffffff;
}

.div2 .searchIcon {
  margin: 20px 10px;
  width: 20px;
  height: 20px;
}

.div2 .searchBar input {
  width: 80%;
  height: 90%;
  text-align: left;
  background-color: transparent;
  border-radius: 25px;
  color: #ffffff;
  border: none;
  padding-left: 20px;
  font-size: 1.2rem;
  font-family: "Inter", sans-serif;
}

.div2 .searchBar input:focus {
  border: none;
  outline: none;
}

.header .div3 {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: right;
  position: absolute;
  right: 0.8%;
  gap: 15px;
}

.div3 img {
  width: 30px;
  height: 30px;
}

.dropdown-Menu {
  position: relative;
  display: inline-block;
  z-index: 9999;
}

.menu-item .icon {
  border: none;
  outline: none;
}

/* Change color of dropdown links on hover */
.dropdown-content-Menu #menuItemHeader:hover {
  background-color: #396e76;
  border-radius: 10px;
  color: black;
}

/* Show the dropdown menu on hover */
.dropdown-Menu:hover .dropdown-content-Menu {
  display: block;
  flex-direction: row;
  position: absolute;
}

.menuItems {
  display: flex;
  flex-direction: column;
}

.menu-row {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.menu-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 10px;
}

.plusRegister {
  width: 35px;
  height: 35px;
}

.menu-item a {
  color: white;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  text-decoration: none;
  margin-top: 5px;
}

.menu-item a:hover {
  border-radius: 10px;
  color: black;
}

.dropdown-content a {
  color: #000000;
  padding: 6px 12px;
  text-decoration: none;
  display: block;
  gap: 10px;
  margin-left: 10px;
}
.dropdown-content a:hover,
.dropdown-content a span:hover {
  background-color: #1e2125;
  border-radius: 5px;
  color: white;
}

.user-profile {
  display: flex;
  align-items: flex-start;

  /* Align items to the top of the container */
  justify-content: space-around;

  padding: 10px;
}

.profile-photo {
  width: 70px !important;
  height: 70px !important;
  border-radius: 50%;
  margin-right: 2px;
  background-color: #f0f0f0; /* Fallback background color */
  display: inline-block;
  object-fit: cover;
  object-position: center;
  image-rendering: auto;
}

/* Optional: Placeholder styling for cases where no image is provided */

.user-info p {
  margin: 0;
  font-size: 14px;
  background-color: #ffffff;
}
.EmployeeprofileImage {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 1px;
  margin-bottom: 8px;
  background-color: #b1c5df;
  display: inline-block;
  object-fit: cover;
  object-position: center;
  image-rendering: auto;
}
.dot {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 8px;
}
#initials {
  height: 30px;
  width: 30px;
  background-color: #2381f3;
  color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 17px;
}

.notification-icon-wrapper {
  display: inline-block;
  position: relative;
}
@keyframes fluctuate {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-1px);
  } /* Moves up */
  100% {
    transform: translateY(0);
  } /* Back to normal */
}

.notification-count {
  position: absolute;
  top: -6px;
  right: -9px;
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 50%;
  min-width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: nowrap;

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  /* Animation */
}
.notification-count {
  animation: fluctuate 0.6s infinite ease-in-out;
}

.dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
  z-index: 999;
}

.dropdown-content {
  position: absolute;
  right: 0;
  background-color: white;
  min-width: 250px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 10;
  border-radius: 0px 15px 0px 0px;
  padding: 10px;
  margin-top: 16px;
}
.switch-modal {
  position: absolute;
  right: 263px;
  background-color: white;
  min-width: 250px;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.22); /* Deeper and softer shadow */

  z-index: 10;
  padding: 15px;
  margin-top: 12px;
  transition: all 0.3s ease-in-out; /* Smooth appearance transition */
}
.modal-content {
  display: flex;
  flex-direction: column;
}
.companyNameLine {
  cursor: pointer;
  background-color: #f8f8f8;
  border-radius: 5px;
  padding: 5px;
  font-size: 12px;
}
.companyNameLine:hover {
  cursor: pointer;
  background-color: #ffffff;
}
.modal-content {
  background: #fff;
  padding: 5px;
  border-radius: 8px;
  width: 320px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease-in-out;
}

.modal-title {
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  margin-bottom: 2px;
}

.company-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  margin: 5px;
  cursor: pointer;
  border-radius: 6px;
  transition: background 0.3s ease-in-out;
}

.company-item:hover {
  background: #f5f5f5;
  cursor: pointer;
}

.company-name {
  font-size: 12px;
  color: #333;
  font-weight: 500;
  max-width: 200px; /* Adjust width based on your layout */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block; /* Ensures truncation works */
  cursor: pointer;
}

.primary-badge {
  font-size: 10px;
  color: #fff;
  background: #007bff;
  padding: 2px 6px;
  border-radius: 4px;
}
.selected-company {
  background-color: #f4f4f4; /* Dark gray background */
  color: #000000; /* Change text color to white for contrast */
  padding: 5px;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .WEB {
    display: none !important; /* Hide web content on mobile */
  }
  .MOBILE {
    display: block !important; /* Show mobile content */
  }

  .nav-content {
    width: 0px;
  }

  .header .div1 h3 {
    margin-top: 5%;
  }

  #burgerMenu {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: left;
  }
  .content-Container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    text-align: left;
    font-family: "Inter", sans-serif;
    position: relative;
    background-color: #ffffff;
  }
  body {
    background-color: #ffffff !important;
  }

  .containerChild {
    max-height: 100%;
    overflow-y: scroll;
    width: 100%;
    margin: 0px;
  }

  .header .div2 {
    width: 60%;

    align-items: center;
    display: none;
  }
  .div2 .searchBar {
    margin-left: 40%;
    border-radius: 5px;
    border: none;
  }

  .searchIcon {
    display: none;
  }
  .div2 .searchBar input {
    width: 100%;
    height: 90%;
    text-align: left;
    margin-left: 5px;
    padding: 1%;
  }

  .MOBILE .tableFormat th:nth-child(1) {
    border-radius: 5px 0px 0px 5px; /* Applies rounded corners to the first column header */
  }

  .MOBILE .tableFormat th:last-child {
    border-radius: 0px 5px 5px 0px; /* Applies rounded corners to the last column header */
  }
  .header .div3 {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 0;
  }
  .paginationOfTable {
    display: flex;

    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 2%;
    padding: 0% 0;
    z-index: 999;
    font-size: 12px;
    border-radius: 0;
    background-color: #ffffff;
    width: calc(100%);
  }
  .EmployeeprofileImage {
    margin-right: 5px;
  }
  .dropdown-content {
    right: 20%;
    border-radius: 0px 0px 0px 0px;
  }
  .switch-modal {
    right: 30px;
    top: 55%;
  }
}
</style>
