<template>
  <div>
    <!-- Offcanvas Modal -->
    <div
      class="offcanvas offcanvas-end"
      tabindex="-1"
      id="ProjectActivityModal"
      aria-labelledby="ProjectActivityModalLabel"
      style="
        height: calc(100% - 62px) !important;
        margin: 48px 0px 12px 0px !important;
        border-radius: 10px 0px 0px 10px;
      "
    >
      <div
        class="offcanvas-header"
        style="border-bottom: 2px solid #000000; padding: 8px"
      >
        <img
          src="../../assets/icons/activityIcon.png"
          alt="Activity"
          class="me-2"
          style="width: 20px; height: 20px"
        />
        <h6 class="offcanvas-title" id="ProjectActivityModalLabel">Activities</h6>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body">
        <div class="mt-1">
          <!-- Use flexbox or grid for better alignment -->
          <div class="activity-list">
            <!-- Display Activities -->
            <div v-for="activity in activities" :key="activity.id" class="activity-card">
              <div class="activity-header">
                <small class="text-muted">{{ activity.date }}</small>
                <small class="mb-0">{{ activity.time }}</small>
              </div>
              <p class="activity-content">
                <span>{{ activity.employeeFullName }}</span>
                {{ activity.text }}
              </p>
            </div>
          </div>

          <!-- View More / View Less Buttons -->
          <div class="mt-3 text-center">
            <button
              v-if="activities.length > 0 && currentPage > 1"
              class="btn btn-sm btn-secondary ms-2"
              @click="viewLess"
            >
              View Less
            </button>
            <button
              v-if="activities.length > 0 && activities.length < count"
              class="btn btn-sm btn-primary ms-2"
              @click="viewMore"
            >
              View More
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ProjectActivityModal",
  props: {
    projectId: {
      type: String,
      required: true,
    },
    team: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      ProjectId: this.projectId,
      currentPage: 1, // Tracks the current page for pagination
      activities: [], // Stores the activities data
      count: 0, // Total count of activities
    };
  },
  watch: {
    // Watcher for currentPage to fetch data when it changes
    projectId: {
      immediate: true, // Trigger immediately on component load
      handler() {
        this.currentPage = 1; // Reset pagination when projectId changes
        this.fetchActivities();
      },
    },
  },
  methods: {
    fetchActivities() {
      console.log(this.currentPage);
      console.log(this.team);
      console.log(this.projectId);

      const url = `${this.$apiEndPoint}/activity/getActivity/${this.ProjectId}/${this.team}/${this.currentPage}`;
      const params = {};

      axios
        .get(url, { params })
        .then((response) => {
          //   this.activities = response.data.activity;
          this.count = response.data.count; // Store the total count
          this.activities = response.data.activity.map((activity) => ({
            ...activity,
            employeeFullName: `${activity["employees.firstName"]} ${activity["employees.lastName"]}`,
          }));
          this.$emit("setProjectId");
          // Append only the new activities, no need for mapping
          //   if (page === 1) {
          //     this.activities = fetchedActivities; // Overwrite on first page
          //   } else {
          //     this.activities = [...this.activities, ...fetchedActivities]; // Append for subsequent pages
          //   }
        })
        .catch((error) => {
          console.error("Error fetching activities:", error);
        });
    },

    viewMore() {
      // Increment current page for "View More"
      this.currentPage++;
      this.fetchActivities();
    },
    viewLess() {
      // Decrement current page for "View Less"
      this.currentPage = Math.max(1, this.currentPage - 1);
      this.fetchActivities();
    },
  },
  mounted() {
    this.fetchActivities(); // Fetch data when the component is mounted
  },
};
</script>

<style scoped>
.offcanvas-title {
  font-size: 17px;
}
.offcanvas-body {
  overflow-y: auto;
  max-height: 100%;
}

.activity-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.activity-card {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 0px 8px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 13px;
}

.activity-header {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #666;
  margin-top: 2px;
}

.activity-content {
  font-size: 14px;
  font-weight: 500;
  margin-top: 8px;
  word-break: normal; /* Prevents breaking words */
  white-space: normal; /* Ensures text wraps naturally */
  overflow-wrap: anywhere; /* Allows breaking at spaces or hyphens when necessary */
}

.employee-name {
  font-weight: bold;
}

.text-muted {
  font-size: 12px;
}

.btn-sm {
  font-size: 13px;
  padding: 5px 10px;
}
</style>
