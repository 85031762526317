<template>
  <div class="EmployeesignupGroup">
    <div class="row d-flex align-items-center">
      <div class="col-md-7 col-sm-7 col-lg-7">
        <div class="loginImg d-flex justify-content-center">
          <img src="../assets/icons/loginImage.png" alt="" />
        </div>
      </div>
      <div class="col-md-5 col-sm-5 col-lg-5" v-if="loginPage">
        <div class="login align-items-center">
          <div class="logo d-flex justify-content-center">
            <h1 class="toolNameFontFamily">arivu</h1>
          </div>

          <div class="Employeesignup" @keydown="handleKeydown">
            <span class="input-wrapper mb-2">
              <label :for="authMethod === 'otp' ? 'email' : 'phone'"
                >&nbsp;{{ authMethod === "otp" ? "Email ID" : "Email ID/Phone No" }}&nbsp;
              </label>
              <input type="text" v-model="email" class="EmailOrPhone" />
              <p v-if="email" style="color: red" class="validation-message">
                {{ this.emailValidationMessage }}
              </p>
            </span>
            <div class="radio-group mt-4">
              <div>
                <label>
                  <input
                    type="radio"
                    v-model="authMethod"
                    value="password"
                    class="passwordRadio"
                  />
                  Password
                </label>
                <label>
                  <input type="radio" v-model="authMethod" value="otp" class="otpRadio" />
                  OTP
                </label>
              </div>
              <label
                v-if="authMethod === 'otp'"
                class="d-flex justify-content-end text-end"
                style="color: #4884f7; cursor: pointer"
                @click="generateOTP"
                :style="{
                  pointerEvents: generatingOTP || countdownTimer < 0 ? 'none' : 'auto',
                }"
              >
                {{
                  generatingOTP
                    ? `Resend OTP in ${countdownTimer} seconds`
                    : otpCount === 0
                    ? "Generate OTP"
                    : "Resend OTP"
                }}
              </label>
            </div>
            <span class="input-wrapper" v-if="authMethod === 'password'">
              <label for="password-input">&nbsp;Password&nbsp;</label>

              <input
                id="password-input"
                :type="showPassword ? 'text' : 'password'"
                v-model="password"
                @click="clearValidationMessage"
                class="password-input"
              />
              <img
                :src="
                  showPassword
                    ? require('@/assets/icons/eye-open.jpg')
                    : require('@/assets/icons/eye-closed.jpg')
                "
                alt="Toggle Password Visibility"
                class="toggle-password-icon"
                @click="togglePasswordVisibility"
              />

              <p
                class="d-flex justify-content-end forgetPasswordText"
                style="color: #4884f7; cursor: pointer"
                @click="forgetPassword"
              >
                Forgot Password?
              </p>
            </span>

            <span class="input-wrapper" v-if="authMethod === 'otp'">
              <label for="">&nbsp;OTP&nbsp;</label>
              <input v-model="otp" class="otp-input" type="tel" pattern="[0-9]*" />
            </span>
            <p style="color: red" class="validation-message mt-2">
              {{ otpValidationMessage }}
            </p>
            <button
              type="button"
              class="confirmationButton SignInBtn"
              id="EmployeeSignButton"
              @click="login"
              :disabled="loading"
            >
              <span class="confirmationButton__text">Sign In</span>
            </button>

            <div class="signUp d-flex justify-content-center align-items-center">
              Create new Account? &nbsp;
              <a href="/sign-up" style="text-decoration: none">Sign Up</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-5 col-sm-5 col-lg-5" v-if="forgetPasswordPage">
        <div class="login forgetPassword align-items-center">
          <div class="logo d-flex justify-content-center">
            <h1 class="toolNameFontFamily">arivu</h1>
          </div>
          <div class="Employeesignup">
            <span> Enter your email ID to receive a link to reset the password. </span>
            <span class="input-wrapper forgetPassword d-flex mt-3">
              <label for="">&nbsp;Email ID&nbsp;</label>
              <input
                type="email"
                v-model="forgetPasswordEmail"
                class="forgetemail-input"
                style="border-right: none; border-radius: 5px 0 0 5px"
              />

              <button
                type="button"
                class="confirmationButton forgetSubmitBtn"
                :disabled="loading || !isValidEmail(this.forgetPasswordEmail)"
                @click="sendResetLink"
                style="
                  height: 47px !important;
                  border-radius: 0px 5px 5px 0px;

                  border-right: 1px solid #000;
                  border-top: 1px solid #000;
                  border-bottom: 1px solid #000;
                "
              >
                <span class="confirmationButton__text text-white">Send</span>
              </button>
            </span>
            <small
              v-if="this.forgetPasswordEmail && !isValidEmail(this.forgetPasswordEmail)"
              class="text-danger mt-2"
              >Please enter a valid email address.</small
            >

            <p
              class="d-flex justify-content-right mt-3"
              style="color: #4884f7; cursor: pointer"
              @click="log"
            >
              Back to login
            </p>
          </div>
        </div>
      </div>
    </div>
    <DeviceLimitModal
      :show="show403Modal"
      :devices="deviceData"
      @close="show403Modal = false"
      @close-modal="show403Modal = false"
    />
  </div>
</template>

<script>
import setAuthHeader from "../util/setAuthHeader";
import axios from "axios";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import DeviceLimitModal from "./Modal/deviceLimitModal.vue";

export default {
  name: "Login",
  components: {
    DeviceLimitModal,
  },
  data() {
    return {
      otpCount: 0,
      loading: false,
      email: this.$route.params.email || "",
      password: "",
      otp: "",
      authMethod: "password", // Default authentication method is password
      loginPage: true,
      forgetPasswordPage: false,
      forgetPasswordEmail: "",
      generatingOTP: false,
      countdownTimer: 30,
      otpGenerated: false,
      timer: null,
      emailValidationMessage: "",
      otpValidationMessage: "",
      showPassword: false,
      deviceData: [],
      show403Modal: false,
    };
  },
  watch: {
    email(newVal) {
      this.email = newVal;
      if (!this.email) {
        this.emailValidationMessage = ""; // Don't show an error for empty fields
        return;
      }

      if (!this.isValidEmail(this.email) && !this.isValidPhoneNumber(this.email)) {
        this.emailValidationMessage =
          this.authMethod === "password"
            ? "Please enter a valid Email or Phone Number"
            : "Please enter valid Email";
      } else {
        this.emailValidationMessage = "";
      }
    },
  },

  mounted() {
    let user = localStorage.getItem("user-info");
    if (user) {
      this.$router.push({ name: "Home" });
    } else {
      this.$router.push({ name: "Login" });
    }
  },
  methods: {
    async getDeviceId() {
      let deviceData = localStorage.getItem("deviceData");

      if (!deviceData) {
        const fp = await FingerprintJS.load();
        const result = await fp.get({ extendedResult: true });

        // Fetch IP address using an external API
        const ipResponse = await fetch("https://api.ipify.org?format=json");
        const ipData = await ipResponse.json();

        const deviceInfo = {
          visitorId: result.visitorId,
          ipAddress: ipData.ip || "N/A", // Correct IP retrieval
          browserName: this.getBrowserName(), // Enhanced browser detection
          os: this.getOSName(), // Enhanced OS detection
          platform: navigator.platform,
        };

        localStorage.setItem("deviceData", JSON.stringify(deviceInfo)); // Store full data
        return deviceInfo;
      }

      return JSON.parse(deviceData);
    },

    // Helper function to detect browser
    getBrowserName() {
      if (navigator.userAgentData) {
        const brands = navigator.userAgentData.brands.map((brand) => brand.brand);
        if (brands.includes("Google Chrome")) return "Chrome";
        if (brands.includes("Microsoft Edge")) return "Edge";
        if (brands.includes("Opera")) return "Opera";
        if (brands.includes("Brave")) return "Brave";
      }

      // Fallback for older browsers
      const userAgent = navigator.userAgent;
      if (userAgent.includes("Firefox")) return "Firefox";
      if (userAgent.includes("Edg")) return "Edge";
      if (userAgent.includes("OPR") || userAgent.includes("Opera")) return "Opera";
      if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) return "Safari";
      if (userAgent.includes("Chrome")) return "Chrome";

      return "Unknown Browser";
    },
    // Helper function to detect OS
    getOSName() {
      const platform = navigator.platform.toLowerCase();
      if (platform.includes("win")) return "Windows";
      if (platform.includes("mac")) return "MacOS";
      if (platform.includes("linux")) return "Linux";
      if (platform.includes("iphone") || platform.includes("ipad")) return "iOS";
      if (platform.includes("android")) return "Android";
      return "Unknown OS";
    },

    async login() {
      const btn = document.querySelector(".confirmationButton");
      btn.classList.add("confirmationButton--loading");

      this.loading = true;
      const deviceInfo = await this.getDeviceId(); // Get device details

      const trimmedEmail = this.email.trim();
      const trimmedPassword = this.password.trim();
      const trimmedOtp = this.otp;

      const credentials = {
        email: trimmedEmail,
        password: trimmedPassword,
        otp: trimmedOtp,
        visitorId: deviceInfo.visitorId,
        ipAddress: deviceInfo.ipAddress,
        browserName: deviceInfo.browserName,
        os: deviceInfo.os,
        platform: deviceInfo.platform,
      };

      //  console.log("The login credentials are:", credentials);

      try {
        if (!this.authMethod) {
          alert("Please select an authentication method.");
          return;
        }

        const url =
          this.authMethod === "otp"
            ? `${this.$apiEndPoint}/users/validateOTP`
            : `${this.$apiEndPoint}/users/login`;

        // Handle Password-based login logic
        const response = await axios.post(url, credentials);
        btn.classList.remove("confirmationButton--loading");

        if (
          response.status === 200 &&
          response.data.user &&
          response.data.employeeRoles
        ) {
          this.$toast("Login successful.", "success");
          localStorage.setItem("user-info", JSON.stringify(response.data));
          localStorage.setItem("jwtToken", response.data.accessToken);
          setAuthHeader(response.data.accessToken);
          this.$router.push({ name: "Home" });

          window.location.reload();
        } else {
          this.$toast("Login failed. Please contact Arivu team.", "error");
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          console.log(
            "403 Error Data (Caught in Catch Block):",
            error.response.data.message
          );
          this.deviceData = error.response.data.message; // Ensure correct data mapping

          this.show403Modal = true;
        } else {
          this.$handleError(error); // Handle other unexpected errors
        }
        // this.$handleError(error);
        btn.classList.remove("confirmationButton--loading");
      } finally {
        this.email = "";
        this.password = "";
        this.otp = "";
        this.loading = false; // Reset loading state after login request completes
      }
    },

    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    isValidPhoneNumber(phone) {
      if (!phone) return true; // Allow empty values (no error)
      const phonePattern = /^[6-9]\d{9}$/; // Ensures a valid 10-digit Indian mobile number
      return phonePattern.test(phone);
    },
    handleKeydown(event) {
      if (event.key === "Enter") {
        this.login();
      }
    },
    log() {
      this.loginPage = true;
      this.forgetPasswordPage = false;
    },

    forgetPassword() {
      this.loginPage = false;
      this.forgetPasswordPage = true;
    },

    //Forget password
    sendResetLink() {
      const btn = document.querySelector(".confirmationButton");
      btn.classList.add("confirmationButton--loading");
      this.loading = true;
      // Check if email is not empty
      if (this.forgetPasswordEmail.trim() !== "") {
        // Call your emailSend API with the entered email
        axios
          .post(`${this.$apiEndPoint}/users/passwordResetInvitation`, {
            email: this.forgetPasswordEmail,
          })
          .then(() => {
            btn.classList.remove("confirmationButton--loading");
            this.loading = false;
            // Handle success response here
            this.$toast("Reset link sent successfully!.", "success");

            this.forgetPasswordEmail = "";
          })
          .catch((error) => {
            // Handle error response here
            this.$handleError(error);
            btn.classList.remove("confirmationButton--loading");
            this.loading = false;
          });
      } else {
        // Handle case where email field is empty
        alert("Please enter your email.");
        this.loading = false;
      }
    },

    clearValidationMessage() {
      if (!this.email) {
        this.emailValidationMessage = ""; // Don't show an error for empty fields
        return;
      }

      if (!this.isValidEmail(this.email) && !this.isValidPhoneNumber(this.email)) {
        this.emailValidationMessage =
          this.authMethod === "password"
            ? "Please enter a valid Email or Phone Number"
            : "Please enter valid email";
      } else {
        this.emailValidationMessage = "";
      }
    },
    clearOtpValidationMessage() {
      this.otpValidationMessage = "";
    },
    async generateOTP() {
      console.log(this.otpCount);
      if (this.generatingOTP) return;

      if (!this.email.trim()) {
        this.$toast("Please enter your email ID.", "error");
        //  this.emailValidationMessage = "Please enter your email ID.";
        return;
      } else {
        this.emailValidationMessage = "";
      }

      this.generatingOTP = true;
      this.otpGenerated = false;
      this.countdownTimer = 30;

      this.timer = setInterval(() => {
        if (this.countdownTimer > 0) {
          this.countdownTimer--;
        } else {
          clearInterval(this.timer);
          this.generatingOTP = false;
        }
      }, 1000);

      try {
        await axios.post(`${this.$apiEndPoint}/users/generateOTP/${this.otpCount}`, {
          email: this.email,
        });

        // Increment OTP count and reset after 3 attempts
        if (this.otpCount >= 2) {
          this.otpCount = 0; // Reset to 0 after 3rd attempt
        } else {
          this.otpCount++;
        }

        this.$toast("OTP sent successfully! Check your email", "warning");
        this.otpGenerated = true;
      } catch (error) {
        this.$handleError(error);
      }
    },

    beforeDestroy() {
      // Clear the timer when the component is destroyed
      clearInterval(this.timer);
    },
    // async login() {
    //   const btn = document.querySelector(".confirmationButton");
    //   btn.classList.add("confirmationButton--loading");

    //   this.loading = true;

    //   const trimmedEmail = this.email.trim();
    //   const trimmedPassword = this.password.trim();
    //   const trimmedOtp = this.otp;

    //   const credentials = {
    //     email: trimmedEmail,
    //     password: trimmedPassword,
    //     otp: trimmedOtp,
    //   };

    //   try {
    //     if (!this.authMethod) {
    //       alert("Please select an authentication method.");
    //       return;
    //     }

    //     const url =
    //       this.authMethod === "otp"
    //         ? `${this.$apiEndPoint}/users/validateOTP`
    //         : `${this.$apiEndPoint}/users/login`;
    //     // Handle Password-based login logic
    //     const response = await axios.post(url, credentials);
    //     btn.classList.remove("confirmationButton--loading");

    //     if (
    //       response.status === 200 &&
    //       response.data.user &&
    //       response.data.employeeRoles
    //     ) {
    //       this.$toast("Login successful.", "success");
    //       localStorage.setItem("user-info", JSON.stringify(response.data));
    //       localStorage.setItem("jwtToken", response.data.accessToken);
    //       setAuthHeader(response.data.accessToken);
    //       this.$router.push({ name: "Home" });
    //       window.location.reload();
    //     } else {
    //       this.$toast("Login failed. Please contact Arivu team.", "error");
    //     }
    //   } catch (error) {
    //     this.$handleError(error);
    //     btn.classList.remove("confirmationButton--loading");
    //   } finally {
    //     this.email = "";
    //     this.password = "";
    //     this.otp = "";
    //     this.loading = false; // Reset loading state after login request completes
    //   }
    // },

    beforeUnmount() {
      clearInterval(this.timer);
    },
  },
};
</script>

<style scoped>
.loginImg img {
  max-width: 80%;
  max-height: 80%;
}

.logo h1 {
  margin-bottom: 8%;
}

.radio-group {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
  display: flex;
  justify-content: space-between;
}

.radio-group label {
  margin-right: 20px;
  margin-bottom: 20px;
}

.EmployeesignupGroup {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
}

#EmployeeSignupHeading {
  margin-top: 5%;
  font-size: 2rem;
}

.Employeesignup {
  display: flex;
  margin-top: 2%;
  flex-direction: column;
}

.Employeesignup p {
  color: #fff;
}

#EmployeeSignButton {
  margin: 10% 30%;
  margin-top: 10%;
  padding: 5px;
  background-color: #4884f7;
  color: #fff;
  font-size: 1rem;
  border-radius: 5px;
  outline: none;
  border: none;
  cursor: pointer;
}

.signUp {
  margin-top: -5%;
}

.forgetPassword {
  margin-top: -40%;
}

.EmployeeSignUpFooter a {
  color: #4884f7;
  cursor: pointer;
}

.input-wrapper {
  min-width: 400px;
  height: 40px;
  position: relative;
}

/* .input-wrapper p {
  margin-left: 55%;
  margin-top: 2%;
} */

.input-wrapper input {
  padding: 5% 2%;
  height: 100%;
  width: 100%;
  /* z-index: 99; */
  border: 1.5px solid black;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease, background-color 0.3s ease;
  background-color: #ffffff;
  font-weight: bolder;
  /* Set input text color */
  font-size: 1rem;
}

.forgetButton img {
  padding: 18%;
  width: 130%;
  height: 200%;
  background-color: #a2bff6;
  border: 1.5px solid #000000;
  border-left: none;
  border-radius: 0 5px 5px 0;
  z-index: 1;
  cursor: pointer;
}

.input-wrapper label {
  white-space: nowrap;
  text-align: left;
  /* Adjusted to align left */
  position: absolute;
  margin-top: -13px;
  /* Adjusted to overlap with input when empty */
  left: 10px;
  pointer-events: none;
  z-index: 1;
  font-size: 1rem;
  transition: all 0.4s ease;
  color: #000000;
  font-weight: 500;
  background-color: #ffffff;
}

.input-wrapper input:focus {
  border-color: #4884f7;
}

.input-wrapper input:focus ~ label,
.input-wrapper input:not(:placeholder-shown) ~ label {
  margin-top: -20px;
  /* Adjusted to move label up */
  left: 10px;
  font-size: 16px;
  background-color: #084f5b;
}

.input-wrapper input:not(:focus):not(:placeholder-shown) ~ label {
  font-size: 1.1rem;
  margin-top: -10px;
  /* Adjusted to overlap with input when empty */
  left: 10px;
  background-color: #084f5b;
}

.password-wrapper {
  position: relative;
}

.toggle-password-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 20px; /* Adjust as needed */
}
.validation-message {
  font-size: 12px;
}
@media (max-width: 768px) {
  .login {
    margin-top: -30%;
  }

  .loginImg img {
    display: none;
  }

  .EmployeesignupGroup h2 {
    margin-bottom: 10%;
  }

  #EmployeeSignupHeading {
    margin-top: -40%;
    font-size: 1.5rem;
  }

  .input-wrapper {
    min-width: 320px;
    height: 40px;
    position: relative;
  }

  .forgetPassword {
    margin-left: 3%;
  }

  .Employeesignup span {
    margin-left: 3%;
  }

  .forgetButton img {
    padding: 18%;
    width: 130%;
    height: 168%;
    background-color: #a2bff6;
    border: 1.5px solid #000000;
    border-left: none;
    border-radius: 0 5px 5px 0;
    z-index: 1;
    cursor: pointer;
  }
}
</style>
