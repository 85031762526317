<template>
  <SalesTargetModal
    :selectedEmployeeId="selectedEmployeeId"
    :selectedTargetId="selectedTargetId"
    @fetchData="setSelectedFinancialYear"
  />
  <Header />
  <div class="content-Container">
    <Nav />
    <div class="containerChild">
      <div class="salesTargetPage p-2">
        <div class="row" style="border-bottom: 5px solid #bcbfc6">
          <p class="mainHeading d-flex ms-4 p-1 mt-2 mb-2">Sales Target</p>
        </div>

        <div class="FormatTable">
          <div class="row headerOfTable">
            <div class="col-md-6 d-flex">
              <div class="col-md-5 d-flex flex-column mb-3" v-if="showExecative">
                <label for="input1" class="form-label text-muted" style="font-size: 12px"
                  >Executive Name</label
                >
                <select
                  style="
                    font-size: 12px;
                    height: 30px;
                    cursor: pointer;
                    :focus {
                      outline: none;
                      box-shadow: none;
                    }
                  "
                  class="form-select"
                  v-model="selectedEmployeeId"
                  @change="setSelectedEmployee"
                >
                  <option value="All" selected>All Team Members</option>
                  <option
                    v-for="employee in employees"
                    :key="employee.employeeId"
                    :value="employee.employeeId"
                  >
                    {{ employee["employees.firstName"] }}
                    {{ employee["employees.lastName"] }}
                  </option>
                </select>
              </div>
              <div class="col-md-5 ms-3 d-flex flex-column mb-3">
                <label for="input2" class="form-label text-muted" style="font-size: 12px"
                  >Financial Year</label
                >
                <select
                  style="
                    font-size: 12px;
                    height: 30px;
                    cursor: pointer;
                    :focus {
                      outline: none;
                      box-shadow: none;
                    }
                  "
                  class="form-select"
                  v-model="selectedFinancialYear"
                  @change="setSelectedFinancialYear"
                >
                  <option
                    v-for="year in financialYears"
                    :key="year.fromYear"
                    :value="year"
                    :selected="year.fromYear === selectedFinancialYear.fromYear"
                  >
                    FY Apr {{ year.fromYear }} - Mar {{ year.toYear }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-6 d-flex align-items-center">
              <div class="col-md-3">
                <div class="row" style="cursor: pointer" v-if="showExecative">
                  <img
                    @click="openModal(null)"
                    src="../../assets/icons/addRows.png"
                    alt=""
                    style="width: 60px; height: 35px"
                    data-bs-toggle="modal"
                    data-bs-target="#targetSetterModal"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="table-wrapper">
            <table class="">
              <caption class="d-none">
                Table
              </caption>
              <thead>
                <tr>
                  <th class="tableHeaderHeading" v-if="selectedEmployeeId === 'All'">
                    Executive Name
                  </th>
                  <th class="tableHeaderHeading d-none d-md-table-cell">Start From</th>
                  <th class="tableHeaderHeading d-none d-md-table-cell">End To</th>
                  <th class="text-end">Target Value</th>
                  <th class="text-end">Achieved Value</th>
                  <th class="text-center d-none d-md-table-cell">Last Modified Date</th>
                  <th class="text-center d-none d-md-table-cell">Status</th>
                  <th class="tableHeaderHeading ps-1 text-center" v-if="showExecative">
                    Action
                  </th>
                  </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in TableData" :key="index">
                  <td v-if="selectedEmployeeId === 'All'">
                    {{ item["employees.firstName"] }} {{ item["employees.lastName"] }}
                  </td>
                  <td class="d-none d-md-table-cell">
                    {{ formatMonthYear(item.fromMonth, item.fromYear) }}
                  </td>
                  <td class="d-none d-md-table-cell">{{ formatMonthYear(item.toMonth, item.toYear) }}</td>
                  <td class="text-end">
                    {{ $formatToIndianCurrency(item.targetAmount) }}
                  </td>
                  <td class="text-end">
                    {{ $formatToIndianCurrency(item.totalSalesAmount) }}
                  </td>
                  <td class="text-center d-none d-md-table-cell">{{ formatDate(item.updated_at) }}</td>
                  <td class="text-center d-none d-md-table-cell">
                    <span v-if="item.status === 'Achieved'" class="text-success">
                      {{ item.status }}
                    </span>
                    <span v-if="item.status === 'Not Met'" class="text-danger">
                      {{ item.status }}
                    </span>
                    <span v-if="item.status === 'Not Started'" class="text-warning">
                      {{ item.status }}
                    </span>
                    <span v-if="item.status === 'In Progress'" class="text-black">
                      {{ item.status }}
                    </span>
                  </td>
                  <td class="salesTargetAction text-center" v-if="showExecative">
                    <img
                      @click="openModal(item.id)"
                      src="../../assets/icons/yellowEdit.png"
                      alt=""
                      class="action-image"
                      data-bs-toggle="modal"
                      data-bs-target="#targetSetterModal"
                    />
                    <img
                      src="../../assets/icons/redDelete.png"
                      alt=""
                      class="action-image"
                      @click="deleteTarget(item.id, index)"
                    />
                    <img
                      class="d-md-none action-image"
                      src="../../assets/icons/info.png"
                      alt=""
                      srcset=""
                      data-bs-target="#tableDetailModal"
                      data-bs-toggle="modal"
                      @click="showDetails(item)"
                    />
                  </td>
                  
                </tr>
              </tbody>
              <tfoot class="web">
                <tr style="font-weight: 600">
                  <td v-if="selectedEmployeeId === 'All'"></td>
                  <td class="text-start" style="font-weight: 600">Total Value</td>
                  <td></td>
                  <td class="text-end" style="font-weight: 600">
                    &nbsp;&nbsp; {{ $formatToIndianCurrency(this.TotalTargetvalue) }}
                  </td>
                  <td class="text-end text-success" style="font-weight: 600">
                    {{ $formatToIndianCurrency(this.Totalachivevalue) }}
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tfoot>
              <tfoot class="mob">
                <tr style="font-weight: 600">
                  <td v-if="selectedEmployeeId === 'All'"></td>
                  <td class="text-end" style="font-weight: 600">
                    &nbsp;&nbsp; {{ $formatToIndianCurrency(this.TotalTargetvalue) }}
                  </td>
                  <td class="text-end text-success" style="font-weight: 600">
                    {{ $formatToIndianCurrency(this.Totalachivevalue) }}
                  </td>
                  
                  <td class="text-start" style="font-weight: 600">Total Value</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <TableDetailsModal :details="details" />
</template>

<script>
import SalesTargetModal from "../Modal/SalesTargetModal";
import axios from "axios";
import Header from "../Header.vue";
import Nav from "../Nav.vue";
import TableDetailsModal from "../Modal/TableDetailsModal.vue";
export default {
  name: "SalesTarget",
  components: {
    Header,
    Nav,
    SalesTargetModal,
    TableDetailsModal,
  },
  data() {
    const currentMonth = new Date().getMonth() + 1; // Months are 0-indexed, so add 1
    const currentYear = new Date().getFullYear();

    const currentFinancialYear =
      currentMonth >= 4
        ? { fromYear: currentYear, toYear: currentYear + 1 }
        : { fromYear: currentYear - 1, toYear: currentYear };
    const companyRegisteredYear = parseInt(
      this.$LocalUser.user.companyRegisteredYear,
      10
    );
    const financialYears = [];

    // Generate financial years starting from the company registered year
    for (let year = companyRegisteredYear; year <= currentYear + 5; year++) {
      financialYears.push({
        fromYear: year,
        toYear: year + 1,
      });
    }
    return {
      details: [],
      showExecative: false,
      selectedEmployeeId: this.$LocalUser.user.employeeId,
      employees: [],
      TableData: [],
      TargetData: {},
      TotalTargetvalue: "",
      Totalachivevalue: "",
      selectedTargetId: null,
      userRoles: this.$LocalUser.employeeRoles,
      selectedFinancialYear: currentFinancialYear,
      financialYears: financialYears,
      isLoading: false, // Loader state
    };
  },
  mounted() {
    this.fetchEmployees();
    this.setSelectedFinancialYear();
    this.fetchTargetData();

    this.showExecative = this.userRoles.some(
      (role) => role.team === "Sales" && role.role === "Manager"
    );
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // getMonth() returns month from 0-11
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
    showDetails(rowData) {
      this.details=[
      { label: "Target Amount", value: rowData.targetAmount ? this.$formatToIndianCurrency(rowData.targetAmount) : "N/A" },
      { label: "Achieved Value", value: rowData.totalSalesAmount ? this.$formatToIndianCurrency(rowData.totalSalesAmount) : "N/A" },
      { label: "Last Modified Date", value: rowData.updated_at ? this.formatDate(rowData.updated_at) : "N/A" },
      { label: "Status", value: rowData.status || "N/A" },
      { label: "From Date", value: rowData.toMonth && rowData.toYear ? this.formatMonthYear(rowData.fromMonth, rowData.fromYear) : "N/A" },
      { label: "To Date", value: rowData.toMonth && rowData.toYear ? this.formatMonthYear(rowData.toMonth, rowData.toYear) : "N/A" }, 
      ]
    },
    openModal(TargetId) {
      this.selectedTargetId = TargetId;
    },
    formatMonthYear(month, year) {
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return `${monthNames[month - 1]} ${year}`;
    },
    async deleteTarget(TargetId, Index) {
      const result = await this.$showAlert(
        "",
        "Are you sure you want to delete this entry?",
        "warning",
        "Yes, delete it",
        "Cancel"
      );

      // If the user clicked Cancel, don't proceed
      if (!result.isConfirmed) return;

      // Proceed with deletion if confirmed
      this.TableData.splice(Index, 1);

      try {
        await axios.delete(`${this.$apiEndPoint}/salesTarget/${TargetId}`);
        this.$toast(`Target deleted successfully.`, "success");
        this.setSelectedFinancialYear();
      } catch (error) {
        this.$handleError(error);
      }
    },

    async setSelectedFinancialYear() {
      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/salesTarget/${this.selectedEmployeeId}`,
          this.selectedFinancialYear
        );
        this.TableData = response.data.getValue;
        this.TotalTargetvalue = response.data.targetValue;
        this.Totalachivevalue = response.data.achievedValue;
      } catch (error) {
        this.$handleError(error);
      }
    },

    async fetchTargetData() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/targetSet/${this.selectedEmployeeId}`
        );
        this.TargetData = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchData() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/salesTarget/${this.selectedEmployeeId}`
        );
        this.TableData = response.data.getValue;
        this.TotalTargetvalue = response.data.targetValue;
        this.Totalachivevalue = response.data.achievedValue;
      } catch (error) {
        this.$handleError(error);
      }
    },

    setSelectedEmployee() {
      this.setSelectedFinancialYear();
    },
    async fetchEmployees() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/employee/myTeam/Sales`);
        this.employees = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
};
</script>

<style scoped>
.salesTargetPage {
  height: calc(100% - 74px);
  width: calc(100% - 74px);
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  position: fixed;
}

.salesTargetAction img {
  padding: 5px;
  width: 30px; /* Reduce size of images */
  height: 30px; /* Set height explicitly */

  cursor: pointer;
}
.salesTargetAction img:hover {
  border-radius: 50%;
  background-color: #ccc;
}

.tableFormat:nth-child(2) {
  cursor: pointer;
}

.btn-priority-low {
  background-color: #f4e8fb;
  color: #ba69e2;
}

.btn-priority-medium {
  background-color: #ecebec;
  color: #7e7882;
}

.btn-priority-high {
  background-color: #feabab;
  color: #fd0000;
}

.btn-priority-Normal {
  background-color: #eaf3f1;
  color: #4b9b86;
}

#LeadFollowDate {
  color: rgb(107, 60, 60);
}

.FormatTable {
  width: 100%;
  height: 100%;
}

.headerOfTable {
  width: 96%;
  padding: 0.5% 0.5%;
}

#addicon {
  width: 30px;
}

#ImportIcon {
  width: 20px;
  cursor: pointer;
}

#ArchiveIcon {
  width: 15px;
  cursor: pointer;
}

#SerchIconOfTable {
  width: 6%;
  margin-right: 5%;
}

.search-box {
  border-bottom: 2px solid #000;
  padding-bottom: 3px;
  display: flex;
  align-items: center;
}

.searchboxOfTable {
  width: 100%;
  display: flex;
  align-items: center;
}

#FilterIconOfTable {
  width: 20%;
  height: 5%;
  margin-left: 10%;
}

input[type="text"] {
  border: none;
  outline: none;
  font-size: 16px;
  width: 100%;
  text-align: left;
}

.table-wrapper {
  max-height: 76%; /* Adjust the height based on your requirements */
  overflow-y: auto;
  border: 1px solid #ddd;
}

table {
  width: 100%;
  border-collapse: collapse;
}

thead,
tfoot {
  background-color: #f9f9f9;
  position: sticky;
  z-index: 1;
}

thead {
  top: 0;
}

tfoot {
  bottom: 0;
}

th,
td {
  font-size: 14px;
  border: 1px solid #ddd;
  padding: 7px 8px;
  text-align: left;
  background-color: #ffffff;
}
th {
  background-color: #bcbfc6;
}
@media screen and (max-width: 400px) {
  .text-center{
    height: 57px;
  }
}
@media screen and (max-width: 768px) {
  .tableFormat th,
  .tableFormat td {
    font-size: 12px; /* Adjust font size for smaller screens */
  }

  #addicon {
    width: 100%;
  }

  #ArchiveIcon {
    width: 30%;
  }

  .ImportExport {
    display: none;
  }

  .paginationOfTable {
    bottom: 0.5%;
  }
  .salesTargetPage {
    height: calc(100% - 74px);
    width: calc(100% - 0px);
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    position: fixed;
  }
  .web{
    display: none;
  }
  .align-items-center{
    position: absolute;
    width: 100px;
    right: -45px;
    top: 95px;
  }
  
}
@media screen and (min-width: 768px) {
  .mob{
    display: none;
  }
}
</style>
