<template>
  <Header />
  <div class="content-Container">
    <Nav />
    <div class="containerChild">
      <div class="OnSiteProjectHeader">
        <div class="OnSiteProjectComponentHeader">
          <div class="DesignProjectComponentHeader p-2">
            <img
              src="../../assets/icons/black_OnSite.png"
              alt=""
              id="DesignProjectHeaderImage"
            />
            <div class="DesignProjectHeader">
              <section class="DesignProjectHeaderRow1">
                <div class="d-flex">
                  <span class="text-nowrap ms-1 WEB">{{ this.ProjectName }}</span>
                  <span
                    class="text-nowrap ms-1 MOBILE"
                    style="
                      font-size: 14px;
                      width: 150px;
                      max-width: 150px; /* Adjust width as necessary */
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                    :title="this.ProjectName"
                    >{{ this.ProjectName }}</span
                  >
                  <span class="ms-2 ImgOfStatus">
                    <img
                      src="../../assets/icons/blackDownarrow.png"
                      alt=""
                      @click="toggleProjectNameDropDown"
                      style="position: relative"
                    />
                    <transition name="fade">
                      <div
                        v-if="showProjectNameDropDown"
                        class="modal-overlay"
                        @click="toggleProjectNameDropDown"
                      ></div>
                    </transition>
                    <transition name="slide">
                      <div v-if="showProjectNameDropDown">
                        <!-- Dropdown content -->
                        <div class="showProjectNameDropDown p-1">
                          <ol>
                            <li
                              data-bs-toggle="modal"
                              data-bs-target="#shareProjectModal"
                            >
                              Assignee
                            </li>

                            <select
                              style="border: none; outline: none"
                              v-model="ProjectStatus"
                              @change="ChangeProjectStatus(ProjectStatus)"
                            >
                              <option>In Progress</option>

                              <option>Completed</option>
                            </select>
                          </ol>
                        </div>
                      </div>
                    </transition>
                  </span>

                  <span
                    class="ms-3 ImgOfStatus"
                    style="display: flex; align-self: flex-start; padding-left: 20%"
                  >
                    <span>
                      <img
                        v-if="this.ProjectStatus === 'Completed'"
                        src="../../assets/icons/completed.png"
                        alt="Completed"
                        style="width: 15px; height: 15px"
                      />
                      <img
                        v-else-if="this.ProjectStatus === 'On Hold'"
                        src="../../assets/icons/onHold.png"
                        alt="On Hold"
                        style="width: 15px; height: 15px"
                      />
                      <img
                        v-else-if="this.ProjectStatus === 'In Progress'"
                        src="../../assets/icons/inProgress.png"
                        alt="In Progress"
                        style="width: 15px; height: 15px"
                      />
                    </span>
                    <span
                      class="ms-2 mt-1 WEB"
                      style="white-space: nowrap; font-size: 14px"
                      >{{ this.ProjectStatus }}</span
                    >
                  </span>
                </div>
                <div class="DesignProjectHeaderUsers">
                  <div class="collaborators-container">
                    <div
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      v-for="(collaborator, index) in collaborators.slice(0, 3)"
                      :key="index"
                      class="collaborator-avatar"
                    >
                      <img
                        class="initials UserProfilePicture"
                        v-if="collaborator['employees.signedUrl'] !== null"
                        :src="collaborator['employees.signedUrl']"
                        alt=""
                      />
                      <span class="initials" v-else>{{ collaborator.initials }}</span>
                    </div>

                    <span v-if="collaborators.length > 3" class="additional-count ms-1"
                      >+{{ collaborators.length - 3 }}</span
                    >
                    <div
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                      Style=" z-index:999!important;"
                    >
                      <div
                        v-for="(employee, employeeIndex) in collaborators"
                        :key="employeeIndex"
                        class="d-flex p-2 text-start"
                        Style=" z-index:99999!important;"
                      >
                        <span class="d-flex ms-2 text-nowrap text-start">
                          {{ employee["employees.firstName"] }}
                          {{ employee["employees.lastName"] }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <button
                    @click="OpenProjectActivityModal()"
                    type="button"
                    class="btn btn-custom ms-5 WEB"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#ProjectActivityModal"
                  >
                    <img src="../../assets/icons/activityIcon.png" alt="Activity" />
                    Activity
                  </button>
                </div>
              </section>
              <div class="WEB DesignProjectHeaderRow2">
                <section class="OnsiteProjectHeaderRow2">
                  <h6
                    @click="setActiveTab('scopeOfWork')"
                    :class="{
                      'active-SubHeading': activeTab === 'scopeOfWork',
                      'inactive-SubHeading': activeTab !== 'scopeOfWork',
                    }"
                  >
                    Scope Of Work
                  </h6>
                  <h6
                    @click="setActiveTab('timeline')"
                    :class="{
                      'active-SubHeading': activeTab === 'timeline',
                      'inactive-SubHeading': activeTab !== 'timeline',
                    }"
                  >
                    Timeline
                  </h6>

                  <h6
                    @click="setActiveTab('purchaseRequest')"
                    :class="{
                      'active-SubHeading': activeTab === 'purchaseRequest',
                      'inactive-SubHeading': activeTab !== 'purchaseRequest',
                    }"
                  >
                    Purchase Request
                  </h6>
                  <h6
                    @click="setActiveTab('purchaseOrders')"
                    :class="{
                      'active-SubHeading': activeTab === 'purchaseOrders',
                      'inactive-SubHeading': activeTab !== 'purchaseOrders',
                    }"
                  >
                    Purchase Orders
                  </h6>

                  <h6
                    @click="setActiveTab('grn')"
                    :class="{
                      'active-SubHeading': activeTab === 'grn',
                      'inactive-SubHeading': activeTab !== 'grn',
                    }"
                  >
                    GRN
                  </h6>
                  <h6
                    @click="setActiveTab('stockEntry')"
                    :class="{
                      'active-SubHeading': activeTab === 'stockEntry',
                      'inactive-SubHeading': activeTab !== 'stockEntry',
                    }"
                  >
                    Stock Entry
                  </h6>
                  <h6
                    @click="setActiveTab('contractor')"
                    :class="{
                      'active-SubHeading': activeTab === 'contractor',
                      'inactive-SubHeading': activeTab !== 'contractor',
                    }"
                  >
                    Quotations
                  </h6>
                  <h6
                    @click="setActiveTab('files')"
                    :class="{
                      'active-SubHeading': activeTab === 'files',
                      'inactive-SubHeading': activeTab !== 'files',
                    }"
                  >
                    Files
                  </h6>
                </section>
              </div>
              <div class="DesignProjectHeaderRow3 d-md-none">
                <select
                  class="mt-1 ms-1"
                  v-model="activeTab"
                  style="border-radius: 5px"
                  @change="setActiveTab(activeTab)"
                >
                  <option value="scopeOfWork">Scope Of Work</option>
                  <option value="timeline">Timeline</option>
                  <option value="purchaseRequest">Purchase Request</option>
                  <option value="purchaseOrders">Purchase Orders</option>
                  <option value="grn">GRN</option>
                  <option value="stockEntry">Stock Entry</option>
                  <option value="contractor">Quotations</option>
                  <option value="files">Files</option>
                </select>
                <button
                  @click="OpenProjectActivityModal()"
                  type="button"
                  class="btn btn-custom ms-5 mt-1"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#ProjectActivityModal"
                >
                  <img src="../../assets/icons/activityIcon.png" alt="Activity" />
                  Activity
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="headerbelowcontent p-1">
          <div v-if="activeTab === 'scopeOfWork'" class="scope-of-work-container">
            <div class="content-wrapper WEB">
              <div class="scopeOfWOrkHeader mb-3">
                <div>
                  <button
                    class="btn btn-sm btn-secondary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Sales Order
                  </button>
                  <div
                    class="dropdown-menu justify-content-around"
                    aria-labelledby="dropdownMenuButton"
                    style="width: fit-content"
                  >
                    <a
                      v-for="order in SalesOrderArray"
                      :key="order.id"
                      class="d-flex justify-content-around mt-2 align-items-center dropdown-item"
                    >
                      {{ order.salesOrderNumber }}
                      <img
                        @click="OpensalesOrder(order)"
                        src="../../assets/icons/view.png"
                        alt=""
                        data-toggle="tooltip"
                        data-placement="top-start"
                        title="view"
                        style="width: 20px; height: 20px; cursor: pointer"
                        class="ms-5"
                      />

                      <button
                        v-if="order.sowStatus === 0"
                        class="btn btn-sm text-light ms-2"
                        style="background-color: purple"
                        @click="convertToScopeOfWork(order.id)"
                      >
                        Convert
                      </button>
                      <button
                        v-else
                        class="btn btn-sm text-light ms-2"
                        style="background-color: blue"
                        disabled
                      >
                        Converted
                      </button>
                    </a>
                  </div>
                </div>
                <div>
                  <button
                    class="btn btn-sm text-light"
                    style="background-color: blue; margin-right: 25px"
                    data-bs-toggle="modal"
                    data-bs-target="#CreateScopeOfWorkModal"
                  >
                    + Add
                  </button>
                  <button
                    class="btn btn-sm text-light"
                    style="background-color: purple; margin-right: 25px"
                    @click="GenerateTimeLine()"
                  >
                    Generate Timeline
                  </button>
                </div>
              </div>

              <div class="table-responsive ScopeOfWorkTable mb-2">
                <table class="tableOfEstimate">
                  <caption class="d-none">
                    Scope of Work Table
                  </caption>
                  <thead class="subHeaderHeadingBgColor">
                    <tr>
                      <th>SL.No</th>
                      <th>Work Description</th>
                      <th>Assignee</th>
                      <th>Planned Start Date</th>
                      <th>Planned End Date</th>
                      <th>Duration</th>
                    </tr>
                  </thead>

                  <tbody>
                    <div v-for="(item, index) in items" :key="index">
                      <span class="header-input-cell d-flex align-content-center">
                        <img
                          src="../../assets/icons/addImagePib.png"
                          alt=""
                          srcset=""
                          style="width: 18px; height: 18px; cursor: pointer"
                          class="mt-2 ms-2"
                          data-bs-toggle="modal"
                          data-bs-target="#CreateScopeOfWorkModal"
                          @click="CreatewithHeader(item.header)"
                        />

                        <input
                          type="text"
                          class="text-start w-100 text-primary"
                          v-model="item.header"
                          placeholder="Header Name"
                          readOnly
                        />
                      </span>
                      <div v-for="(row, rowIndex) in item.rows" :key="rowIndex">
                        <tr class="row-content SalesOrderListItems">
                          <td>
                            <span>
                              <img
                                @click="validateAndAddChildRow(item, row)"
                                src="../../assets/icons/stockAdd.png"
                                alt="task pending"
                                class="AddChildRowIcon"
                              />
                            </span>
                          </td>
                          <td class="activity-cell text-start">
                            <input
                              type="text"
                              v-model="row.activity"
                              class="form-control"
                              ref="activityInput"
                              @blur="createScopeOfWork(item.header, row, null)"
                            />
                          </td>

                          <td class="d-flex">
                            <span>
                              <img
                                v-if="!row.assignTo"
                                src="../../assets/icons/whiteProfile.png"
                                style="width: 20px; height: 20px; cursor: pointer"
                                alt=""
                              />
                              <img
                                v-else
                                :src="getEmployeeName(row.assignTo)"
                                alt=""
                                style="
                                  width: 20px;
                                  height: 20px;
                                  border-radius: 50%;
                                  cursor: pointer;
                                  background-color: aliceblue;
                                "
                                class="UserProfilePicture"
                              />
                            </span>

                            <select
                              v-model="row.assignTo"
                              class="form-control form-select ms-2 text-start"
                              @blur="createScopeOfWork(item.header, row, null)"
                              style="padding: 2px 10px"
                            >
                              <option
                                v-for="employee in collaborators"
                                :key="employee.employeeId"
                                :value="employee.employeeId"
                              >
                                {{ employee["employees.firstName"] }}
                                {{ employee["employees.lastName"] }}
                              </option>
                            </select>
                          </td>
                          <td>
                            <input
                              type="date"
                              v-model="row.plannedStartDate"
                              class="form-control"
                              @blur="createScopeOfWork(item.header, row, null)"
                            />
                          </td>
                          <td>
                            <input
                              type="date"
                              v-model="row.plannedEndDate"
                              class="form-control"
                              @blur="createScopeOfWork(item.header, row, null)"
                            />
                          </td>
                          <td>
                            <span>
                              <input
                                type="number"
                                v-model="row.plannedDuration"
                                class="form-control text-capitalize"
                                placeholder="= days ="
                                @blur="createScopeOfWork(item.header, row, null)"
                              />
                            </span>
                            <span
                              @click="deleteRow(item, row, rowIndex)"
                              v-if="
                                $isManager('Onsite') ||
                                row.createdById === this.$LocalUser.user.employeeId
                              "
                            >
                              <img
                                src="../../assets/icons/redDelete.png"
                                data-toggle="tooltip"
                                data-placement="top-start"
                                title="Delete"
                                class="delete-icon"
                                alt=""
                              />
                            </span>
                          </td>
                        </tr>

                        <div v-if="row.lineitems && row.lineitems.length > 0">
                          <tr
                            class="childRow row-content"
                            v-for="(child, childIndex) in row.lineitems"
                            :key="childIndex"
                          >
                            <td>
                              <img
                                src="../../assets/icons/ScopeOfWorkSubtask.png"
                                alt="task pending"
                                class="ChildRowIcon"
                              />
                            </td>
                            <td class="activity-cell text-start">
                              <input
                                type="text"
                                v-model="child.activity"
                                class="form-control text-muted"
                                :ref="`activityInput_${row.id}_${childIndex}`"
                                @blur="createScopeOfWork(item.header, child, row.id)"
                              />
                            </td>

                            <td class="d-flex">
                              <span>
                                <img
                                  v-if="!child.assignTo"
                                  src="../../assets/icons/whiteProfile.png"
                                  style="width: 20px; height: 20px; cursor: pointer"
                                  alt=""
                                />
                                <img
                                  v-else
                                  :src="getEmployeeName(child.assignTo)"
                                  style="
                                    width: 20px;
                                    height: 20px;
                                    border-radius: 50%;
                                    cursor: pointer;
                                    background-color: aliceblue;
                                  "
                                  alt=""
                                  class="UserProfilePicture"
                                />
                              </span>

                              <select
                                v-model="child.assignTo"
                                class="form-select text-start ms-2"
                                @blur="createScopeOfWork(item.header, child, row.id)"
                                style="padding: 2px 10px"
                              >
                                <option
                                  v-for="employee in collaborators"
                                  :key="employee.employeeId"
                                  :value="employee.employeeId"
                                >
                                  {{ employee["employees.firstName"] }}
                                  {{ employee["employees.lastName"] }}
                                </option>
                              </select>
                            </td>
                            <td>
                              <input
                                type="date"
                                v-model="child.plannedStartDate"
                                class="form-control"
                                @blur="createScopeOfWork(item.header, child, row.id)"
                              />
                            </td>
                            <td>
                              <input
                                type="date"
                                v-model="child.plannedEndDate"
                                class="form-control"
                                @blur="createScopeOfWork(item.header, child, row.id)"
                              />
                            </td>
                            <td>
                              <span>
                                <input
                                  type="number"
                                  v-model="child.plannedDuration"
                                  class="form-control"
                                  placeholder="= days ="
                                  @blur="createScopeOfWork(item.header, child, row.id)"
                                />
                              </span>
                              <span
                                @click="deleteChildRow(row, child, childIndex)"
                                v-if="
                                  $isManager('Onsite') ||
                                  child.createdById === this.$LocalUser.user.employeeId
                                "
                              >
                                <img
                                  src="../../assets/icons/redDelete.png"
                                  data-toggle="tooltip"
                                  data-placement="top-start"
                                  title="Delete"
                                  class="delete-icon"
                                  alt=""
                                />
                              </span>
                            </td>
                          </tr>
                        </div>
                      </div>
                    </div>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="content-wrapper MOBILE">
              <div class="scopeOfWOrkHeader mb-3">
                <div sttyle="font-size:10px">
                  <button
                    class="btn btn-sm btn-secondary small-btn dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Convert SO
                  </button>
                  <div
                    class="dropdown-menu justify-content-around"
                    aria-labelledby="dropdownMenuButton"
                    style="width: fit-content"
                  >
                    <a
                      v-for="order in SalesOrderArray"
                      :key="order.id"
                      class="d-flex justify-content-around mt-2 align-items-center dropdown-item"
                    >
                      {{ order.salesOrderNumber }}
                      <img
                        @click="OpensalesOrder(order)"
                        src="../../assets/icons/view.png"
                        alt=""
                        data-toggle="tooltip"
                        data-placement="top-start"
                        title="view"
                        style="width: 20px; height: 20px; cursor: pointer"
                        class="ms-5"
                      />

                      <button
                        v-if="order.sowStatus === 0"
                        class="btn small-btn btn-sm text-light ms-2"
                        style="background-color: purple"
                        @click="convertToScopeOfWork(order.id)"
                      >
                        Convert
                      </button>
                      <button
                        v-else
                        class="btn small-btn btn-sm text-light ms-2"
                        style="background-color: blue"
                        disabled
                      >
                        Converted
                      </button>
                    </a>
                  </div>
                </div>
                <div>
                  <button
                    class="btn btn-sm text-light small-btn"
                    style="background-color: blue"
                    data-bs-toggle="modal"
                    data-bs-target="#CreateScopeOfWorkModal"
                    @click="openScopeOfWorkModal(null)"
                  >
                    + Add
                  </button>
                  &nbsp; &nbsp; &nbsp;
                  <button
                    class="btn btn-sm small-btn text-light"
                    style="background-color: purple"
                    @click="GenerateTimeLine()"
                  >
                    Generate Timeline
                  </button>
                </div>
              </div>

              <div class="ScopeOfWorkTable mb-2">
                <div
                  v-for="(item, index) in items"
                  :key="index"
                  class="mobile-scope-container"
                >
                  <div class="header-section d-flex align-content-center">
                    <img
                      src="../../assets/icons/addImagePib.png"
                      alt=""
                      srcset=""
                      style="width: 18px; height: 18px; cursor: pointer"
                      class="mt-2 ms-2"
                      data-bs-toggle="modal"
                      data-bs-target="#CreateScopeOfWorkModal"
                      @click="CreatewithHeader(item.header)"
                    />
                    <input
                      type="text"
                      class="text-left w-100 text-primary header-input"
                      v-model="item.header"
                      placeholder="Header Name"
                      readOnly
                    />
                  </div>

                  <div v-for="(row, rowIndex) in item.rows" :key="rowIndex">
                    <div class="activity-card">
                      <div class="card-header d-flex justify-content-between">
                        <span class="activity-title">
                          <span>
                            <img
                              src="../../assets/icons/addImagePib.png"
                              style="width: 20px; height: 20px"
                              @click="openScopeOfWorkModal(row.id)"
                              data-bs-toggle="modal"
                              data-bs-target="#CreateScopeOfWorkModal"
                              alt="add Image"
                            />
                          </span>
                          {{ row.activity }}</span
                        >
                        <span class="d-flex align-items-center" style="padding: 0 10px">
                          <img
                            src="../../assets/icons/subtask.png"
                            alt="Subtask"
                            style="width: 15px; height: 15px"
                          />
                          <sup style="font-size: 10px; font-weight: 600" class="ms-1">
                            {{ row.lineitems.length }}
                          </sup>
                          <img
                            class="ms-3"
                            src="../../assets/icons/3blackDots.png"
                            alt=""
                            style="width: 17px; height: 17px"
                            id="dropdownMenuButton"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            v-if="$isManager('Onsite')"
                          />
                          <div
                            class="dropdown-menu dropdown-menu-center ActionImage"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <span>
                              <img
                                src="../../assets/icons/info.png"
                                alt=""
                                srcset=""
                                data-toggle="tooltip"
                                data-placement="top-start"
                                title="Info"
                                data-bs-target="#tableDetailModal"
                                data-bs-toggle="modal"
                                @click="showTimeLineDetails(row)"
                              />
                            </span>

                            <span>
                              <img
                                src="../../assets/icons/yellowEdit.png"
                                data-toggle="tooltip"
                                data-placement="top-start"
                                title="Edit"
                                alt=""
                                @click="EditScopeOfWorkModal(row.id)"
                                data-bs-toggle="modal"
                                data-bs-target="#CreateScopeOfWorkModal"
                              />
                            </span>
                            <span
                              @click="deleteRow(item, row, rowIndex)"
                              v-if="
                                $isManager('Onsite') ||
                                row.createdById === this.$LocalUser.user.employeeId
                              "
                            >
                              <img
                                src="../../assets/icons/redDelete.png"
                                data-toggle="tooltip"
                                data-placement="top-start"
                                title="Delete"
                                alt=""
                              />
                            </span>
                          </div>
                        </span>
                      </div>

                      <div class="card-details">
                        <div class="detail-item">
                          <label>Assignee</label>
                          <span>
                            {{ row.assignee }}
                          </span>
                        </div>
                        <div class="detail-item">
                          <label>Start Date</label>

                          <span>
                            <input
                              type="date"
                              v-model="row.plannedStartDate"
                              class="form-control"
                              style="font-size: 10px"
                              @blur="createScopeOfWork(item.header, row, null)"
                          /></span>
                        </div>
                        <div class="detail-item">
                          <label>End Date</label>
                          <span>
                            <input
                              style="font-size: 10px"
                              type="date"
                              v-model="row.plannedEndDate"
                              class="form-control"
                              @blur="createScopeOfWork(item.header, row, null)"
                          /></span>
                        </div>
                        <div class="detail-item">
                          <label>Duration(days)</label>
                          <span style="white-space: nowrap !important">
                            <input
                              type="number"
                              v-model="row.plannedDuration"
                              class="form-control w-50"
                              placeholder="= days ="
                              @blur="createScopeOfWork(item.header, row, null)"
                              :readonly="!$isManager('Onsite')"
                            />
                          </span>
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="row.lineitems && row.lineitems.length > 0"
                      class="sub-activities"
                    >
                      <div
                        v-for="(child, childIndex) in row.lineitems"
                        :key="childIndex"
                        class="activity-card sub-activity"
                      >
                        <div class="card-header d-flex justify-content-between">
                          <span class="activity-title align-item-center">
                            <span>
                              <img
                                src="../../assets/icons/blackGreaterthenarrow.png"
                                alt="task pending"
                                style="width: 14px; height: 14px"
                              />
                            </span>
                            {{ child.activity }}</span
                          >
                          <span class="d-flex align-items-center" style="padding: 0 10px">
                            <img
                              class="ms-3"
                              src="../../assets/icons/3blackDots.png"
                              alt=""
                              style="width: 17px; height: 17px"
                              id="dropdownMenuButton"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            />
                            <div
                              class="dropdown-menu dropdown-menu-center ActionImage"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <span>
                                <img
                                  src="../../assets/icons/info.png"
                                  alt=""
                                  srcset=""
                                  data-toggle="tooltip"
                                  data-placement="top-start"
                                  title="Info"
                                  data-bs-target="#tableDetailModal"
                                  data-bs-toggle="modal"
                                  @click="showTimeLineDetails(child)"
                                />
                              </span>

                              <span
                                data-bs-toggle="modal"
                                data-bs-target="#AddonSitePettyCash"
                              >
                                <img
                                  src="../../assets/icons/yellowEdit.png"
                                  data-toggle="tooltip"
                                  data-placement="top-start"
                                  title="Edit"
                                  alt=""
                                  data-bs-toggle="modal"
                                  data-bs-target="#CreateScopeOfWorkModal"
                                  @click="EditchildScopeOfWorkModal(row.id, child.id)"
                                />
                              </span>
                              <span
                                @click="deleteChildRow(row, child, childIndex)"
                                v-if="
                                  $isManager('Onsite') ||
                                  child.createdById === this.$LocalUser.user.employeeId
                                "
                              >
                                <img
                                  src="../../assets/icons/redDelete.png"
                                  data-toggle="tooltip"
                                  data-placement="top-start"
                                  title="Delete"
                                  alt=""
                                />
                              </span>
                            </div>
                          </span>
                        </div>

                        <div class="card-details">
                          <div class="detail-item">
                            <label>Assignee</label>

                            <span> {{ child.assignee }}</span>
                          </div>
                          <div class="detail-item">
                            <label>Start Date</label>
                            <span>
                              <input
                                type="date"
                                v-model="child.plannedStartDate"
                                class="form-control"
                                @blur="createScopeOfWork(item.header, child, row.id)"
                            /></span>
                          </div>
                          <div class="detail-item">
                            <label>End Date</label>
                            <span>
                              <input
                                type="date"
                                v-model="child.plannedEndDate"
                                class="form-control"
                                @blur="createScopeOfWork(item.header, child, row.id)"
                            /></span>
                          </div>
                          <div class="detail-item">
                            <label>Duration(days)</label>
                            <span>
                              <input
                                type="number"
                                v-model="child.plannedDuration"
                                class="form-control"
                                placeholder="= days ="
                                @blur="createScopeOfWork(item.header, child, row.id)"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="activeTab === 'timeline'" class="scope-of-work-container">
            <div class="DesignProjectTimeLineWrapper">
              <div class="d-flex justify-content-end HeadingOfCreateGRN">
                <button
                  class="btn btn-sm"
                  :class="{ 'active-view': showTabularView }"
                  @click="switchToTabularView"
                >
                  Tabular view
                </button>
                <button
                  class="btn btn-sm WEB"
                  :class="{ 'active-view': showGraphicalView }"
                  @click="switchToGraphicalView"
                >
                  Graphical view
                </button>
              </div>

              <div class="DesignProjectLinePage">
                <section v-if="showTabularView" class="table-responsive WEB">
                  <table class="DesignProjectTimeLine files-table">
                    <caption class="d-none">
                      Project TimeLine Page
                    </caption>
                    <thead>
                      <tr>
                        <th>S/N</th>

                        <th>Activity Name</th>
                        <th>Assignee</th>
                        <th>Planned Start date</th>
                        <th>Planned End Date</th>
                        <th>Actual Start Date</th>
                        <th>Actual End Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(row, index) in timelineRows" :key="index">
                        <td>{{ index + 1 }}</td>
                        <td class="text-nowrap text-left">
                          <span class="mb-1">
                            <img
                              src="../../assets/icons/taskCompleted.png"
                              alt="task pending"
                              v-if="row.actualEndDate"
                              style="width: 17px; height: 17px"
                            />
                            <img
                              v-else
                              src="../../assets/icons/taskPending.png"
                              alt="task completed"
                              style="width: 17px; height: 17px"
                            />
                          </span>
                          <span
                            v-if="row.actualEndDate > row.plannedEndDate"
                            class="ms-1 text-danger"
                          >
                            {{ row.activity }}
                          </span>

                          <span v-else class="ms-1">
                            {{ row.activity }}
                          </span>
                        </td>

                        <td>
                          {{ row["employees.firstName"] }}
                          {{ row["employees.lastName"] }}
                        </td>
                        <td>
                          {{ formatDate(row.plannedStartDate) }}
                        </td>
                        <td>
                          {{ formatDate(row.plannedEndDate) }}
                        </td>

                        <td>
                          <input
                            class="w-75"
                            type="date"
                            v-model="row.actualStartDate"
                            @blur="handleRowUpdate(row)"
                          />
                        </td>
                        <td>
                          <input
                            class="w-75"
                            type="date"
                            v-model="row.actualEndDate"
                            @blur="handleRowUpdate(row)"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </section>
                <section v-if="showTabularView" class="table-responsive MOBILE">
                  <div v-for="(row, rowIndex) in timelineRows" :key="rowIndex">
                    <div class="activity-card">
                      <div class="card-header d-flex justify-content-between">
                        <span class="activity-title"> {{ row.activity }}</span>
                        <span class="d-flex align-items-center" style="padding: 0 10px">
                          <span class="me-2" style="font-size: 10px">{{
                            row["employees.firstName"]
                          }}</span>
                          <img
                            class="ms-3"
                            src="../../assets/icons/3blackDots.png"
                            alt=""
                            style="width: 17px; height: 17px"
                            id="dropdownMenuButton"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          />
                          <div
                            class="dropdown-menu dropdown-menu-center ActionImage"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <span>
                              <img
                                src="../../assets/icons/info.png"
                                alt=""
                                srcset=""
                                data-toggle="tooltip"
                                data-placement="top-start"
                                title="Info"
                                data-bs-target="#tableDetailModal"
                                data-bs-toggle="modal"
                                @click="showTimeLineDetailss(row)"
                              />
                            </span>

                            <span>
                              <img
                                src="../../assets/icons/yellowEdit.png"
                                data-toggle="tooltip"
                                data-placement="top-start"
                                title="Edit"
                                alt=""
                                @click="EditTimeScopeOfWorkModal(row.id)"
                                data-bs-toggle="modal"
                                data-bs-target="#CreateScopeOfWorkModal"
                              />
                            </span>
                          </div>
                        </span>
                      </div>

                      <div class="card-details">
                        <div class="detail-item">
                          <label>P. Start</label>
                          <span> {{ formatDate(row.plannedStartDate) }}</span>
                        </div>
                        <div class="detail-item">
                          <label>P. End </label>
                          <span>{{ formatDate(row.plannedEndDate) }}</span>
                        </div>
                        <div class="detail-item">
                          <label>A. Start</label>
                          <span>
                            <input
                              type="date"
                              v-model="row.actualStartDate"
                              class="form-control"
                              style="font-size: 10px"
                              @change="handleRowUpdate(row)"
                            />
                          </span>
                        </div>
                        <div class="detail-item">
                          <label>A. End</label>
                          <span
                            ><input
                              type="date"
                              v-model="row.actualEndDate"
                              class="form-control"
                              style="font-size: 10px"
                              @change="handleRowUpdate(row)"
                          /></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section v-if="showGraphicalView" class="table-responsive WEB">
                  <div id="chart">
                    <apexchart
                      type="rangeBar"
                      :height="chartHeight"
                      :options="chartOptions"
                      :series="JSON.parse(JSON.stringify(series))"
                    >
                    </apexchart>
                  </div>
                </section>
              </div>
            </div>
          </div>
          <div v-if="activeTab === 'files'" class="scope-of-work-container">
            <div class="WEB container-fluid">
              <div class="d-flex justify-content-between">
                <div>
                  <img
                    src="../../assets/icons/leftmove.png"
                    alt=""
                    class="mb-1"
                    @click="goToParentFolder('null')"
                    style="width: 25px; height: 25px; padding: 5px; cursor: pointer"
                  />
                  <span
                    class="ms-2"
                    style="font-size: 16px; cursor: pointer"
                    @click="goToParentFolder('0')"
                    >All Files
                  </span>
                  <span v-for="(headName, index) in HeaderName" :key="index">
                    /
                    <span
                      @click="goToHeaderFolder(headName.id, headName)"
                      style="cursor: pointer"
                    >
                      {{ headName.folderName }}</span
                    >
                  </span>
                </div>
                <div class="d-flex FilesPageHeader">
                  <section class="p-1" style="cursor: pointer">
                    <input
                      type="file"
                      @change="handleFileUpload"
                      multiple
                      accept=".pdf,.doc,.docx"
                      style="display: none"
                      ref="fileInput"
                    />
                    <img
                      src="../../assets/icons/upload.png"
                      alt=""
                      @click="$refs.fileInput.click()"
                    />
                    <span @click="$refs.fileInput.click()">Upload</span>
                  </section>
                  <section
                    style="cursor: pointer"
                    class="p-1 ms-2"
                    @click="createFolder"
                    v-if="ParentFolderName !== 'Sign Off Document'"
                  >
                    <img src="../../assets/icons/add.png" alt="" />
                    <span>Create Folder</span>
                  </section>
                </div>
              </div>

              <div class="table-responsive" style="height: 69vh; overflow-y: auto">
                <table class="files-table FolderTable">
                  <caption class="d-none">
                    Folder Table
                  </caption>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Type</th>
                      <th>Created By</th>
                      <th>Last Modified</th>
                      <th>File Size</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="(folder, index) in Folders" :key="index">
                      <td v-if="!folder.isNew">
                        <span
                          v-if="folder.folderName"
                          @click="goToFolder(folder.id, folder)"
                        >
                          <img src="../../assets/icons/folder.png" alt="" />
                          <span style="cursor: pointer" class="ms-2">{{
                            folder.folderName
                          }}</span>
                        </span>

                        <a
                          v-else
                          @click="
                            $viewFile(
                              folder['awsFiles.awsFileName'],
                              folder['awsFiles.fileName'],
                              'files',
                              'onSite'
                            )
                          "
                        >
                          <img
                            src="../../assets/icons/imageIcon.png"
                            alt=""
                            style="width: 20px; height: 20px"
                            v-if="folder['awsFiles.contentType'] === 'image/jpeg'"
                          />
                          <img src="../../assets/icons/PDF.png" alt="" v-else />
                          {{ folder["awsFiles.fileName"] }}
                        </a>
                      </td>
                      <td v-else>
                        <img src="../../assets/icons/folder.png" alt="" />
                        <input
                          type="text"
                          v-model="folder.folderName"
                          @blur="saveFolder(folder, index)"
                          class="ms-2"
                          style="border: 1px solid #000000"
                        />
                      </td>
                      <td>
                        <span v-if="folder.folderName">{{ folder.contentType }}</span>
                        <span v-else>{{ folder["awsFiles.contentType"] }}</span>
                      </td>

                      <td v-if="folder.folderName !== 'Sign Off Document'">
                        {{ folder["employees.firstName"]
                        }}{{ folder["employees.lastName"] }}
                      </td>
                      <td v-else>System</td>
                      <td>{{ folder.updated_at }}</td>
                      <td>-</td>
                      <td>
                        <img
                          v-if="folder.folderName !== 'Sign Off Document'"
                          src="../../assets/icons/3blackDots.png"
                          alt=""
                          style="height: 17px; cursor: pointer"
                          id="dropdownMenuButton"
                          data-bs-toggle="dropdown"
                        />
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          <a
                            v-if="folder.folderName"
                            class="dropdown-item"
                            href="#"
                            @click="Rename(folder)"
                            >Rename</a
                          >

                          <a
                            class="dropdown-item"
                            href="#"
                            @click="deleteFolder(folder, index)"
                            >Delete</a
                          >
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="container-fluid mt-2 MOBILE">
              <div class="d-flex flex-column justify-content-between">
                <div class="d-flex FilesPageHeader justify-content-end">
                  <section class="p-1" style="cursor: pointer">
                    <input
                      type="file"
                      @change="handleFileUpload"
                      multiple
                      accept=".pdf,.doc,.docx"
                      style="display: none"
                      ref="fileInput"
                    />
                    <img
                      src="../../assets/icons/upload.png"
                      alt=""
                      @click="$refs.fileInput.click()"
                    />
                    <span @click="$refs.fileInput.click()">Upload</span>
                  </section>
                  <section
                    style="cursor: pointer"
                    class="p-1 ms-2"
                    @click="createFolder"
                    v-if="ParentFolderName !== 'Sign Off Document'"
                  >
                    <img src="../../assets/icons/add.png" alt="" />
                    <span>Create Folder</span>
                  </section>
                </div>
                <div>
                  <img
                    src="../../assets/icons/leftmove.png"
                    alt=""
                    class="mb-1"
                    @click="goToParentFolder('null')"
                    style="width: 20px; height: 20px; padding: 5px; cursor: pointer"
                  />
                  <span
                    class="ms-2"
                    style="font-size: 12px; cursor: pointer"
                    @click="goToParentFolder('0')"
                    >All Files
                  </span>
                  <span v-for="(headName, index) in HeaderName" :key="index">
                    /
                    <span
                      @click="goToHeaderFolder(headName.id, headName)"
                      style="font-size: 12px; cursor: pointer"
                    >
                      {{ headName.folderName }}</span
                    >
                  </span>
                </div>
              </div>

              <div class="table-wrapper">
                <table class="files-table FolderTable">
                  <caption class="d-none">
                    Every Team Folder Structure
                  </caption>
                  <thead>
                    <tr>
                      <th>Name</th>

                      <th class="text-end">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="(folder, index) in Folders" :key="index">
                      <td v-if="!folder.isNew">
                        <a
                          style="cursor: pointer"
                          v-if="folder['awsFiles.awsFileName']"
                          @click="
                            $viewFile(
                              folder['awsFiles.awsFileName'],
                              folder['awsFiles.fileName'],
                              'files',
                              'onSite'
                            )
                          "
                          target="_blank"
                        >
                          <img
                            src="../../assets/icons/imageIcon.png"
                            alt=""
                            style="width: 20px; height: 20px"
                            v-if="folder['awsFiles.contentType'] === 'image/jpeg'"
                          />
                          <img
                            src="../../assets/icons/dwg.jpeg"
                            alt=""
                            style="width: 20px; height: 20px"
                            v-if="folder['awsFiles.contentType'] === 'application/acad'"
                          />
                          <img
                            src="../../assets/icons/skp.jpeg"
                            alt=""
                            style="width: 20px; height: 20px"
                            v-if="
                              folder['awsFiles.contentType'] ===
                              'application/vnd.sketchup.skp'
                            "
                          />
                          <img
                            src="../../assets/icons/PDF.png"
                            v-if="folder['awsFiles.contentType'] === 'application/pdf'"
                            alt=""
                          />

                          {{ folder["awsFiles.fileName"] }}
                        </a>
                        <span
                          v-else
                          @click="goToFolder(folder.id, folder)"
                          style="cursor: pointer"
                        >
                          <img src="../../assets/icons/folder.png" alt="" />
                          {{ folder.folderName }}
                        </span>
                      </td>
                      <td v-else>
                        <input
                          type="text"
                          v-model="folder.folderName"
                          placeholder="New Folder"
                          @blur="saveFolder(folder, index)"
                        />
                      </td>

                      <td class="text-end">
                        <div class="ActionImage">
                          <span>
                            <img
                              src="../../assets/icons/info.png"
                              alt=""
                              srcset=""
                              data-toggle="tooltip"
                              data-placement="top-start"
                              title="Info"
                              data-bs-target="#tableDetailModal"
                              data-bs-toggle="modal"
                              @click="showDetails(folder)"
                            />
                          </span>
                          <span>
                            <img
                              src="../../assets/icons/3blackDots.png"
                              alt="Options"
                              style="height: 25px; width: 25px; cursor: pointer"
                              id="dropdownMenuButton"
                              data-bs-toggle="dropdown"
                            />
                            <div
                              class="dropdown-menu"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <a
                                class="dropdown-item"
                                v-if="folder.folderName"
                                href="#"
                                @click="Rename(folder)"
                                >Rename</a
                              >
                              <a
                                class="dropdown-item"
                                href="#"
                                @click="deleteFolder(folder, index)"
                                >Delete</a
                              >
                            </div>
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div v-if="activeTab === 'purchaseRequest'">
            <div class="DesignProjectLinePage mt-2">
              <div class="d-flex justify-content-end mb-3">
                <button
                  class="btn btn-sm text-light"
                  style="background-color: purple; margin-right: 30px"
                  @click="goToPurchaseCreate('Onsite')"
                >
                  Purchase Request +
                </button>
              </div>
              <section class="table-responsive WEB">
                <table class="DesignProjectTimeLine files-table">
                  <caption class="d-none">
                    Time Line Table
                  </caption>
                  <thead>
                    <tr>
                      <th>S/N</th>
                      <th>PR Number</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th>Created By</th>
                      <th class="text-center" style="padding-left: 10%">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, index) in PurchaseRequests" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td class="text-nowrap text-left">
                        {{ row.prNumber }}
                      </td>

                      <td>
                        {{ row.date }}
                      </td>
                      <td :class="getStatusClass(row.prStatus)">
                        {{ row.prStatus }}
                      </td>

                      <td>
                        {{ row["employees.firstName"] }} {{ row["employees.lastName"] }}
                      </td>

                      <td class="text-end">
                        <div class="ActionImage">
                          <span
                            v-if="row.prStatus === 'Draft'"
                            @click="gotoPRDetails(row.id)"
                          >
                            <img
                              src="../../assets/icons/yellowEdit.png"
                              data-toggle="tooltip"
                              data-placement="top-start"
                              title="Edit"
                              alt=""
                            />
                          </span>
                          <span
                            @click="openModal(row.id)"
                            data-bs-toggle="modal"
                            data-bs-target="#PRDetailsModal"
                          >
                            <img
                              src="../../assets/icons/view.png"
                              data-toggle="tooltip"
                              data-placement="top-start"
                              title="Edit"
                              alt=""
                            />
                          </span>
                          <span @click="handleDownload(row.id)">
                            <img
                              src="../../assets/icons/pinkDownold.png"
                              data-toggle="tooltip"
                              data-placement="top-start"
                              title="Delete"
                              alt=""
                            />
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </section>
              <section class="table-responsive MOBILE">
                <table class="DesignProjectTimeLine files-table">
                  <caption class="d-none">
                    Table Format
                  </caption>
                  <thead>
                    <tr>
                      <th>PR No.</th>
                      <th>Status</th>
                      <th class="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in PurchaseRequests" :key="index">
                      <td class="ellipsis">{{ data.prNumber }}</td>
                      <td :class="getStatusClass(data.prStatus)">
                        {{ data.prStatus }}
                      </td>

                      <td class="text-end">
                        <img
                          v-if="data.prStatus === 'Draft'"
                          @click="gotoPRDetails(data.id)"
                          src="../../assets/icons/yellowEdit.png"
                          data-toggle="tooltip"
                          data-placement="top-start"
                          title="Edit"
                          alt=""
                          style="width: 17px; height: 17px; cursor: pointer"
                        />

                        <img
                          class="ms-3"
                          src="../../assets/icons/view.png"
                          alt=""
                          style="width: 17px; height: 17px; cursor: pointer"
                          @click="openModal(data.id)"
                          data-bs-toggle="modal"
                          data-bs-target="#PRDetailsModal"
                        />
                        <img
                          class="ms-3"
                          src="../../assets/icons/pinkDownold.png"
                          alt=""
                          style="width: 17px; height: 17px; cursor: pointer"
                          @click="handleDownload(data.id)"
                        />
                        <img
                          class="ms-3"
                          src="../../assets/icons/info.png"
                          alt=""
                          srcset=""
                          data-bs-target="#tableDetailModal"
                          data-bs-toggle="modal"
                          style="width: 17px; height: 17px; cursor: pointer"
                          @click="showPRDetails(data)"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </section>
            </div>
          </div>
          <div v-if="activeTab === 'purchaseOrders'">
            <div class="DesignProjectLinePage mt-2">
              <section class="table-responsive WEB">
                <table class="DesignProjectTimeLine files-table">
                  <caption class="d-none">
                    Table
                  </caption>
                  <thead class="subHeaderHeadingBgColor">
                    <tr>
                      <th>S/N</th>
                      <th>PO Number</th>
                      <th>Reference</th>
                      <th>Date</th>
                      <th>Vendor Name</th>
                      <th>Status</th>
                      <th class="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, index) in PurchaseOrders" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td class="text-nowrap text-left">
                        {{ row.poNumber }}
                      </td>
                      <td v-if="row['purchaseRequests.prNumber']">
                        {{ row["purchaseRequests.prNumber"] }}
                      </td>
                      <td v-else>Direct PO</td>
                      <td>
                        {{ formatDate(row.date) }}
                      </td>
                      <td>{{ row["vendors.companyName"] }}</td>
                      <td>{{ row.poStatus }}</td>

                      <td class="text-center">
                        <div class="ActionImage">
                          <span>
                            <img
                              src="../../assets/icons/info.png"
                              alt=""
                              srcset=""
                              data-bs-target="#tableDetailModal"
                              data-bs-toggle="modal"
                              @click="showPODetails(row)"
                            />
                          </span>
                          <span
                            @click="openPOModal(row.id)"
                            data-bs-toggle="modal"
                            data-bs-target="#PODetailsModal"
                          >
                            <img
                              src="../../assets/icons/view.png"
                              data-toggle="tooltip"
                              data-placement="top-start"
                              title="Edit"
                              alt=""
                            />
                          </span>
                          <span>
                            <img
                              @click="handleDownloadPO(row.id)"
                              src="../../assets/icons/pinkDownold.png"
                              data-toggle="tooltip"
                              data-placement="top-start"
                              title="downold"
                              alt=""
                            />
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </section>
              <section class="table-responsive MOBILE">
                <table class="DesignProjectTimeLine files-table">
                  <caption class="d-none">
                    Table
                  </caption>
                  <thead class="subHeaderHeadingBgColor">
                    <tr>
                      <th>PO Number</th>
                      <th>Status</th>
                      <th class="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, index) in PurchaseOrders" :key="index">
                      <td class="text-nowrap text-left ellipsis">
                        {{ row.poNumber }}
                      </td>

                      <td class="ellipsis">{{ row.poStatus }}</td>

                      <td class="text-center">
                        <div class="ActionImage">
                          <span>
                            <img
                              src="../../assets/icons/info.png"
                              alt=""
                              srcset=""
                              data-bs-target="#tableDetailModal"
                              data-bs-toggle="modal"
                              @click="showPODetails(row)"
                            />
                          </span>
                          <span
                            @click="openPOModal(row.id)"
                            data-bs-toggle="modal"
                            data-bs-target="#PODetailsModal"
                          >
                            <img
                              src="../../assets/icons/view.png"
                              data-toggle="tooltip"
                              data-placement="top-start"
                              title="Edit"
                              alt=""
                            />
                          </span>
                          <span>
                            <img
                              @click="handleDownloadPO(row.id)"
                              src="../../assets/icons/pinkDownold.png"
                              data-toggle="tooltip"
                              data-placement="top-start"
                              title="downold"
                              alt=""
                            />
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </section>
            </div>
          </div>
          <div v-if="activeTab === 'purchaseInvoice'">
            <div class="d-flex justify-content-end" style="margin: 0.5% 2.9%">
              <button
                @click="openModalPI(null)"
                class="btn btn-sm btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#CreatePurchaseInvoice"
              >
                + Add Purchase Invoice
              </button>
            </div>
            <div class="DesignProjectLinePage mt-2">
              <section class="table-responsive">
                <table class="DesignProjectTimeLine files-table">
                  <caption class="d-none">
                    Table
                  </caption>
                  <thead class="subHeaderHeadingBgColor">
                    <tr>
                      <th>S/N</th>
                      <th>Invoice Date</th>
                      <th>Document No.</th>
                      <th>Invoice Amount</th>
                      <th>Reference</th>
                      <th>Company Name</th>
                      <th>Status</th>
                      <th class="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, index) in PurchaseInvoice" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td>
                        {{ row.date }}
                      </td>
                      <td class="text-nowrap text-left">
                        {{ row.documentNumber }}
                      </td>
                      <td>{{ row.amount }}</td>
                      <td>{{ row["purchaseOrders.poNumber"] }}</td>

                      <td>{{ row["vendors.companyName"] }}</td>

                      <td>{{ row.invoiceStatus }}</td>

                      <td class="text-center">
                        <div class="ActionImage">
                          <span @click="openModalPI(row.id)">
                            <img
                              src="../../assets/icons/yellowEdit.png"
                              data-toggle="tooltip"
                              data-placement="top-start"
                              title="attchment"
                              data-bs-toggle="modal"
                              data-bs-target="#CreatePurchaseInvoice"
                              alt=""
                            />
                          </span>
                          <span
                            @click="
                              $viewFile(
                                row['awsFiles.awsFileName'],
                                row['awsFiles.fileName'],
                                'purchaseInvoice',
                                'procurement'
                              )
                            "
                          >
                            <img
                              src="../../assets/icons/view.png"
                              data-toggle="tooltip"
                              data-placement="top-start"
                              title="attchment"
                              alt=""
                            />
                          </span>
                          <span>
                            <img
                              @click="
                                $downloadFile(
                                  row['awsFiles.awsFileName'],
                                  row['awsFiles.fileName'],
                                  'purchaseInvoice',
                                  'procurement'
                                )
                              "
                              src="../../assets/icons/pinkDownold.png"
                              data-toggle="tooltip"
                              data-placement="top-start"
                              title="Delete"
                              alt=""
                            />
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </section>
            </div>
          </div>
          <div v-if="activeTab === 'grn'">
            <div
              class="d-flex justify-content-end"
              style="margin: 0.5% 2.9%"
              v-if="grnListview"
            >
              <button @click="opengrnList(null)" class="btn btn-sm btn-primary">
                + Add GRN
              </button>
            </div>
            <div
              class="d-flex justify-content-between HeadingOfCreateGRN"
              style="margin: 0.5% 1%"
              v-else
            >
              <span
                class="SubheadingUnderLine"
                style="display: flex; align-items: center; margin-left: 2px"
              >
                New Goods Received Note
              </span>
              <button class="btn btn-sm btn-close" @click="opengrnList(null)"></button>
            </div>

            <div class="DesignProjectLinePage mt-2" v-if="grnListview">
              <section class="table-responsive WEB">
                <table class="DesignProjectTimeLine files-table">
                  <caption class="d-none">
                    Table
                  </caption>
                  <thead class="subHeaderHeadingBgColor">
                    <tr>
                      <th>S/N</th>
                      <th>GRN No.</th>
                      <th>Received Date</th>
                      <th>Vendor Name</th>
                      <th class="text-end">Reference</th>
                      <th class="text-end">Document Type</th>

                      <th class="text-end">Document No</th>

                      <th class="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, index) in grnList" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td class="text-nowrap text-left">
                        {{ row.grnNumber }}
                      </td>
                      <td>{{ formatDate(row.receivedDate) }}</td>
                      <td>
                        {{ row["purchaseOrders.vendors.companyName"] }}
                      </td>
                      <td class="text-end">
                        {{ row["purchaseOrders.poNumber"] }}
                      </td>
                      <td class="text-end">{{ row.documentType }}</td>
                      <td class="text-end">
                        <span v-if="row['awsFiles.awsFileName']">
                          <img
                            v-if="row.documentType === 'Tax Invoice'"
                            @click="
                              $viewFile(
                                row['awsFiles.awsFileName'],
                                row['awsFiles.fileName'],
                                'purchaseInvoice',
                                'procurement'
                              )
                            "
                            src="../../assets/icons/blueAttachemnet.png"
                            alt="Completed"
                            style="width: 15px; height: 15px; cursor: pointer"
                          />
                          <img
                            v-else
                            @click="
                              $viewFile(
                                row['awsFiles.awsFileName'],
                                row['awsFiles.fileName'],
                                'deliveryChallen',
                                'procurement'
                              )
                            "
                            src="../../assets/icons/blueAttachemnet.png"
                            alt="Completed"
                            style="width: 15px; height: 15px; cursor: pointer"
                          />
                        </span>
                        {{ row.documentNo }}
                      </td>

                      <td class="text-center">
                        <div class="ActionImage">
                          <span>
                            <img
                              src="../../assets/icons/info.png"
                              alt=""
                              srcset=""
                              data-bs-target="#tableDetailModal"
                              data-bs-toggle="modal"
                              @click="showGRNDetails(row)"
                            />
                          </span>
                          <span
                            @click="openGRNModal(row.id)"
                            data-bs-toggle="modal"
                            data-bs-target="#OnSiteGrnDetailsModal"
                          >
                            <img
                              src="../../assets/icons/view.png"
                              data-toggle="tooltip"
                              data-placement="top-start"
                              title="view"
                              alt=""
                            />
                          </span>
                          <span @click="opengrnList(row.id)">
                            <img
                              src="../../assets/icons/yellowEdit.png"
                              data-toggle="tooltip"
                              data-placement="top-start"
                              title="Edit"
                              alt=""
                            />
                          </span>
                          <span @click="handleDownloadGRN(row.id)">
                            <img
                              src="../../assets/icons/pinkDownold.png"
                              data-toggle="tooltip"
                              data-placement="top-start"
                              title="downold"
                              alt=""
                            />
                          </span>

                          <span>
                            <img
                              @click="DeleteGrn(row.id, index)"
                              src="../../assets/icons/redDelete.png"
                              data-toggle="tooltip"
                              data-placement="top-start"
                              title="Delete"
                              alt=""
                            />
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </section>
              <section class="table-responsive MOBILE">
                <table class="DesignProjectTimeLine files-table">
                  <caption class="d-none">
                    Table
                  </caption>
                  <thead class="subHeaderHeadingBgColor">
                    <tr>
                      <th>GRN No.</th>

                      <th class="text-end">Reference</th>

                      <th class="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, index) in grnList" :key="index">
                      <td class="WEB">{{ index + 1 }}</td>
                      <td class="text-nowrap text-left">
                        {{ row.grnNumber }}
                      </td>

                      <td class="text-end">
                        {{ row["purchaseOrders.poNumber"] }}
                      </td>

                      <td class="text-center">
                        <div
                          class="d-flex align-content-center justify-content-center text-nowrap"
                        >
                          <img
                            class="ms-3"
                            src="../../assets/icons/3blackDots.png"
                            alt=""
                            style="width: 17px; height: 17px"
                            id="dropdownMenuButton"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          />
                          <div
                            class="dropdown-menu dropdown-menu-center ActionImage"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <span>
                              <img
                                src="../../assets/icons/info.png"
                                alt=""
                                srcset=""
                                data-bs-target="#tableDetailModal"
                                data-bs-toggle="modal"
                                @click="showGRNDetails(row)"
                              />
                            </span>
                            <span
                              @click="openGRNModal(row.id)"
                              data-bs-toggle="modal"
                              data-bs-target="#OnSiteGrnDetailsModal"
                            >
                              <img
                                src="../../assets/icons/view.png"
                                data-toggle="tooltip"
                                data-placement="top-start"
                                title="view"
                                alt=""
                              />
                            </span>
                            <span @click="opengrnList(row.id)">
                              <img
                                src="../../assets/icons/yellowEdit.png"
                                data-toggle="tooltip"
                                data-placement="top-start"
                                title="Edit"
                                alt=""
                              />
                            </span>
                            <span @click="handleDownloadGRN(row.id)">
                              <img
                                src="../../assets/icons/pinkDownold.png"
                                data-toggle="tooltip"
                                data-placement="top-start"
                                title="downold"
                                alt=""
                              />
                            </span>

                            <span>
                              <img
                                @click="DeleteGrn(row.id, index)"
                                src="../../assets/icons/redDelete.png"
                                data-toggle="tooltip"
                                data-placement="top-start"
                                title="Delete"
                                alt=""
                              />
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </section>
            </div>
            <div v-else>
              <div class="WEB">
                <div class="CreateGrnPage">
                  <section
                    style="
                      background-color: #f8f8f8;
                      border-radius: 5px;
                      padding: 20px;
                      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                    "
                  >
                    <form>
                      <div class="row mb-3">
                        <div class="form-group col-md-4">
                          <label for="reference">PO Number</label>
                          <select
                            class="form-control form-select no-border"
                            id="category"
                            v-model="grn.poId"
                            @change="showReferenceDetails()"
                          >
                            <option
                              v-for="PurchaseOrder in ApprovePurchaseorders"
                              :key="PurchaseOrder.id"
                              :value="PurchaseOrder.id"
                            >
                              {{ PurchaseOrder.poNumber }}
                            </option>
                          </select>
                        </div>
                        <div class="form-group col-md-4">
                          <label for="orderDate">Order Date</label>

                          <p class="form-control h-50">{{ formatDate(grn.POdate) }}</p>
                        </div>
                        <div class="form-group col-md-4">
                          <label for="vendorName">Company Name</label>
                          <input
                            type="text"
                            class="form-control"
                            id="vendorName"
                            placeholder="Company Name"
                            v-model="grn.vendorName"
                          />
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="form-group col-md-4">
                          <label for="vendorContact">Vendor Contact No.</label>
                          <input
                            type="text"
                            class="form-control"
                            id="vendorContact"
                            v-model="grn.vendorContactNo"
                            placeholder="Vendor Contact No."
                          />
                        </div>
                        <div class="form-group col-md-4">
                          <label for="deliveryDocType">Delivery Document Type</label>
                          <select
                            class="form-control form-select no-border"
                            id="category"
                            v-model="grn.documentType"
                          >
                            <option>Tax Invoice</option>

                            <option>Delivery Challan</option>
                          </select>
                        </div>
                        <div class="form-group col-md-4">
                          <label for="documentNo">Document No.</label>
                          <input
                            type="text"
                            class="form-control"
                            id="documentNo"
                            placeholder="Document No."
                            v-model="grn.documentNo"
                          />
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="form-group col-md-4">
                          <label for="receivedDate">Received Date</label>
                          <input
                            type="date"
                            class="form-control"
                            id="receivedDate"
                            v-model="grn.receivedDate"
                          />
                        </div>
                        <div class="form-group col-md-4">
                          <label for="receiverName">Receiver Name</label>
                          <input
                            type="text"
                            class="form-control"
                            id="receiverName"
                            placeholder="Receiver Name"
                            v-model="grn.receiverName"
                          />
                        </div>
                        <div class="form-group col-md-4">
                          <label for="receiverContact">Receiver Contact No.</label>
                          <input
                            type="text"
                            class="form-control"
                            id="receiverContact"
                            placeholder="Receiver Contact No."
                            v-model="grn.receiverContactNo"
                          />
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="form-group col-md-4">
                          <label for="uploadFile">Upload File</label> <br />
                          <span
                            v-if="
                              this.selectedGrnId && grn.documentType === 'Tax Invoice'
                            "
                          >
                          </span>
                          <input
                            v-else
                            type="file"
                            class="form-control"
                            id="onSiteGrn"
                            name="onSiteGrn"
                            @change="handleFileUploadGrnFile"
                          />
                          <strong
                            class="ms-1"
                            style="cursor: pointer"
                            v-if="grn.documentType === 'Delivery Challan'"
                            @click="
                              $viewFile(
                                grn['awsFiles.awsFileName'],
                                grn['awsFiles.fileName'],
                                'deliveryChallen',
                                'procurement'
                              )
                            "
                          >
                            {{ this.grn.fileName }}
                          </strong>
                          <strong
                            style="cursor: pointer"
                            class="ms-1"
                            v-if="grn.documentType === 'Tax Invoice'"
                            @click="
                              $viewFile(
                                grn['awsFiles.awsFileName'],
                                grn['awsFiles.fileName'],
                                'purchaseInvoice',
                                'procurement'
                              )
                            "
                          >
                            {{ this.grn.fileName }}
                          </strong>
                        </div>
                        <div
                          class="form-group col-md-4"
                          v-if="grn.documentType === 'Tax Invoice'"
                        >
                          <label for="amount">Invoice Amount.</label>
                          <input
                            type="text"
                            class="form-control"
                            id="amount"
                            placeholder="Amount."
                            v-model="grn.amount"
                          />
                        </div>
                        <div
                          class="form-group col-md-4"
                          v-if="grn.documentType === 'Tax Invoice'"
                        >
                          <label for="date">Invoice Date.</label>
                          <input
                            type="date"
                            class="form-control"
                            id="date"
                            placeholder="Document No."
                            v-model="grn.date"
                          />
                        </div>
                      </div>
                    </form>
                  </section>
                  <section>
                    <section class="GrnTable">
                      <table>
                        <caption class="d-none">
                          Table
                        </caption>
                        <thead>
                          <tr>
                            <th>Product Name (Description)</th>
                            <th>Order Qty</th>
                            <th>Received Qty</th>
                            <th>Damaged Qty</th>
                            <th>Missing Qty</th>
                            <th>Remark</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, index) in lineItems" :key="item.id">
                            <td>
                              {{ item["productLists.productName"] }} ({{
                                item["productLists.description"]
                              }})
                            </td>
                            <td>
                              <input
                                type="checkbox"
                                v-model="item.checked"
                                @change="updateQuantities(index)"
                              />
                              {{ item.quantity }}
                            </td>
                            <td>
                              <input
                                type="number"
                                v-model="item.receivedQty"
                                :disabled="item.checked"
                                @input="validateInput(index, 'receivedQty')"
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                v-model="item.damagedQty"
                                :disabled="item.checked"
                                @input="validateInput(index, 'damagedQty')"
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                v-model="item.missingQty"
                                :disabled="item.checked"
                                @input="validateInput(index, 'missingQty')"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                v-model="item.remark"
                                :disabled="item.checked"
                              />
                            </td>
                          </tr>
                          <!-- Add more rows as needed -->
                        </tbody>
                      </table>
                    </section>
                    <textarea
                      class="form-control mb-3"
                      rows="4"
                      placeholder="Additional Remarks"
                      v-model="grn.comments"
                    ></textarea>
                  </section>
                </div>

                <div class="footerPuchaseRequest">
                  <button
                    v-if="!selectedGrnId"
                    class="confirmationButton GlobalCreateButton btn btn-sm btn-primary"
                    @click="saveAsGrnDraft"
                    :disabled="loading"
                  >
                    <span class="confirmationButton__text">Save</span>
                  </button>
                  <button
                    v-else
                    class="confirmationButton GlobalCreateButton btn btn-sm btn-primary"
                    @click="updateGrnDraft"
                    :disabled="loading"
                  >
                    <span class="confirmationButton__text">Update</span>
                  </button>
                  <button
                    class="save-button btn btn-sm btn-secondary"
                    @click="opengrnList(null)"
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <div class="MOBILE">
                <div class="CreateGrnPage">
                  <section
                    style="
                      background-color: #f8f8f8;
                      border-radius: 5px;
                      padding: 20px;
                      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                    "
                  >
                    <form>
                      <div class="row mb-3">
                        <div class="form-group col-md-4">
                          <label for="reference">PO Number</label>
                          <select
                            class="form-control form-select no-border"
                            id="category"
                            v-model="grn.poId"
                            @change="showReferenceDetails()"
                          >
                            <option
                              v-for="PurchaseOrder in ApprovePurchaseorders"
                              :key="PurchaseOrder.id"
                              :value="PurchaseOrder.id"
                            >
                              {{ PurchaseOrder.poNumber }}
                            </option>
                          </select>
                        </div>
                        <div class="form-group col-md-4">
                          <label for="orderDate">Order Date</label>
                          <input
                            type="date"
                            class="form-control"
                            id="orderDate"
                            v-model="grn.POdate"
                          />
                        </div>
                        <div class="form-group col-md-4">
                          <label for="vendorName">Company Name</label>
                          <input
                            type="text"
                            class="form-control"
                            id="vendorName"
                            placeholder="Company Name"
                            v-model="grn.vendorName"
                          />
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="form-group col-md-4">
                          <label for="vendorContact">Vendor Contact No.</label>
                          <input
                            type="text"
                            class="form-control"
                            id="vendorContact"
                            v-model="grn.vendorContactNo"
                            placeholder="Vendor Contact No."
                          />
                        </div>
                        <div class="form-group col-md-4">
                          <label for="deliveryDocType">Delivery Document Type</label>
                          <select
                            class="form-control form-select no-border"
                            id="category"
                            v-model="grn.documentType"
                          >
                            <option>Tax Invoice</option>

                            <option>Delivery Challan</option>
                          </select>
                        </div>
                        <div class="form-group col-md-4">
                          <label for="documentNo">Document No.</label>
                          <input
                            type="text"
                            class="form-control"
                            id="documentNo"
                            placeholder="Document No."
                            v-model="grn.documentNo"
                          />
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="form-group col-md-4">
                          <label for="receivedDate">Received Date</label>
                          <input
                            type="date"
                            class="form-control"
                            id="receivedDate"
                            v-model="grn.receivedDate"
                          />
                        </div>
                        <div class="form-group col-md-4">
                          <label for="receiverName">Receiver Name</label>
                          <input
                            type="text"
                            class="form-control"
                            id="receiverName"
                            placeholder="Receiver Name"
                            v-model="grn.receiverName"
                          />
                        </div>
                        <div class="form-group col-md-4">
                          <label for="receiverContact">Receiver Contact No.</label>
                          <input
                            type="text"
                            class="form-control"
                            id="receiverContact"
                            placeholder="Receiver Contact No."
                            v-model="grn.receiverContactNo"
                          />
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="form-group col-md-4">
                          <label for="uploadFile">Upload File</label> <br />
                          <span
                            v-if="
                              this.selectedGrnId && grn.documentType === 'Tax Invoice'
                            "
                          >
                          </span>
                          <input
                            v-else
                            type="file"
                            class="form-control"
                            id="onSiteGrnn"
                            name="onSiteGrn"
                            @change="handleMobileFileUploadGrnFile"
                          />
                          <strong
                            class="ms-1"
                            style="cursor: pointer"
                            v-if="grn.documentType === 'Delivery Challan'"
                            @click="
                              $viewFile(
                                grn['awsFiles.awsFileName'],
                                grn['awsFiles.fileName'],
                                'deliveryChallen',
                                'procurement'
                              )
                            "
                          >
                            {{ this.grn.fileName }}
                          </strong>
                          <strong
                            style="cursor: pointer"
                            class="ms-1"
                            v-if="grn.documentType === 'Tax Invoice'"
                            @click="
                              $viewFile(
                                grn['awsFiles.awsFileName'],
                                grn['awsFiles.fileName'],
                                'purchaseInvoice',
                                'procurement'
                              )
                            "
                          >
                            {{ this.grn.fileName }}
                          </strong>
                        </div>
                        <div
                          class="form-group col-md-4"
                          v-if="grn.documentType === 'Tax Invoice'"
                        >
                          <label for="amount">Invoice Amount.</label>
                          <input
                            type="text"
                            class="form-control"
                            id="amount"
                            placeholder="Amount."
                            v-model="grn.amount"
                          />
                        </div>
                        <div
                          class="form-group col-md-4"
                          v-if="grn.documentType === 'Tax Invoice'"
                        >
                          <label for="date">Invoice Date.</label>
                          <input
                            type="date"
                            class="form-control"
                            id="date"
                            placeholder="Document No."
                            v-model="grn.date"
                          />
                        </div>
                      </div>
                    </form>
                  </section>
                  <div v-for="(row, rowIndex) in lineItems" :key="rowIndex" class="mt-3">
                    <div class="activity-card">
                      <div class="card-header d-flex justify-content-between">
                        <span class="activity-title">
                          {{ row["productLists.productName"] }}</span
                        >
                        <span class="d-flex align-items-center" style="padding: 0 10px">
                          <img
                            class="ms-3"
                            src="../../assets/icons/3blackDots.png"
                            alt=""
                            style="width: 17px; height: 17px"
                            id="dropdownMenuButton"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          />
                          <div
                            class="dropdown-menu dropdown-menu-center ActionImage"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <span>
                              <img
                                src="../../assets/icons/info.png"
                                alt=""
                                srcset=""
                                data-toggle="tooltip"
                                data-placement="top-start"
                                title="Info"
                                data-bs-target="#tableDetailModal"
                                data-bs-toggle="modal"
                                @click="showTimeLineDetails(row)"
                              />
                            </span>

                            <span>
                              <img
                                src="../../assets/icons/yellowEdit.png"
                                data-toggle="tooltip"
                                data-placement="top-start"
                                title="Edit"
                                alt=""
                                @click="editRow(rowIndex)"
                                data-bs-toggle="modal"
                                data-bs-target="#addProductModal"
                              />
                            </span>
                          </div>
                        </span>
                      </div>

                      <div class="card-details">
                        <div class="detail-item">
                          <label>Order</label>
                          <span>{{ row.quantity }}</span>
                        </div>
                        <div class="detail-item">
                          <label>Received</label>
                          <span>{{ row.receivedQty }}</span>
                        </div>
                        <div class="detail-item">
                          <label>Damaged</label>
                          <span>{{ row.damagedQty }}</span>
                        </div>
                        <div class="detail-item">
                          <label>Missing</label>
                          <span>{{ row.missingQty }} </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <textarea
                    class="form-control mb-3"
                    style="min-height: 100px"
                    rows="4"
                    placeholder="Additional Remarks"
                    v-model="grn.comments"
                  ></textarea>
                </div>

                <div class="footerPuchaseRequest">
                  <button
                    v-if="!selectedGrnId"
                    class="confirmationButton GlobalCreateButton btn btn-sm btn-primary"
                    @click="saveAsGrnDraft"
                    :disabled="loading"
                  >
                    <span class="confirmationButton__text">Save</span>
                  </button>
                  <button
                    v-else
                    class="confirmationButton GlobalCreateButton btn btn-sm btn-primary"
                    @click="updateGrnDraft"
                    :disabled="loading"
                  >
                    <span class="confirmationButton__text">Update</span>
                  </button>
                  <button
                    class="save-button btn btn-sm btn-secondary"
                    @click="opengrnList(null)"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="activeTab === 'stockEntry'">
            <div class="DesignProjectTimeLineWrapper" v-if="AllEntryPage">
              <div class="d-flex stockEntryview HeadingOfCreateGRN">
                <button
                  class="btn btn-sm d-flex"
                  :class="{ 'blackactive-view': showInwardEntry }"
                  @click="switchToTable('showInwardEntry')"
                >
                  Inward <span class="WEB"> Entry</span>
                </button>

                <button
                  class="btn btn-sm d-flex"
                  :class="{ 'blackactive-view': showStockConsumption }"
                  @click="switchToTable('showStockConsumption')"
                >
                  Stock <span class="WEB">Consumption</span>

                  <span class="ms-2 WEB">
                    <img
                      @click="openStockConsumptionModal('null')"
                      data-bs-toggle="modal"
                      data-bs-target="#OnSiteStockConsumptionModal"
                      src="../../assets/icons/stockAdd.png"
                      alt="task pending"
                      style="width: 17px; height: 17px"
                    />
                  </span>
                  <span class="ms-2 MOBILE">
                    <img
                      @click="openStokEntryPage()"
                      src="../../assets/icons/stockAdd.png"
                      alt="task pending"
                      style="width: 17px; height: 17px"
                    />
                  </span>
                </button>
                <button
                  class="btn btn-sm d-flex"
                  :class="{ 'blackactive-view': showOutwardEntry }"
                  @click="switchToTable('showOutwardEntry')"
                >
                  <span class="d-flex"> Outward <span class="WEB"> Entry</span></span>

                  <span class="ms-2 WEB">
                    <img
                      data-bs-toggle="modal"
                      data-bs-target="#OnsiteEntriesModal"
                      src="../../assets/icons/stockAdd.png"
                      alt="task pending"
                      style="width: 17px; height: 17px"
                    />
                  </span>
                  <span class="ms-2 MOBILE">
                    <img
                      @click="openOutWardEntryPage()"
                      src="../../assets/icons/stockAdd.png"
                      alt="task pending"
                      style="width: 17px; height: 17px"
                    />
                  </span>
                </button>
                <button
                  class="btn btn-sm d-flex"
                  :class="{ 'blackactive-view': showInventoryEntry }"
                  @click="switchToTable('showInventoryEntry')"
                >
                  Ledger
                </button>
              </div>

              <div class="DesignProjectLinePage">
                <section v-if="showInwardEntry" class="table-responsive WEB">
                  <table class="DesignProjectTimeLine files-table">
                    <caption class="d-none">
                      Table
                    </caption>
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Date</th>
                        <th>From Store</th>
                        <th>Voucher Number</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(row, index) in InwardEntries" :key="index">
                        <td>{{ index + 1 }}</td>
                        <td>
                          {{ formatDate(row.date) }}
                        </td>
                        <td>{{ row["fromStores.storeName"] }}</td>

                        <td>
                          {{ row.voucherNo }}
                        </td>
                        <td>
                          <button
                            @click="openInwordEntryModal(row.id)"
                            data-bs-toggle="modal"
                            data-bs-target="#OnSiteOutwordEntriesModal"
                            v-if="row.status === 'Sent'"
                            type="button"
                            class="btn btn-sm btn-outline-primary p-1"
                            style="cursor: pointer; letter-spacing: 2px"
                          >
                            Accept
                          </button>
                          <button
                            v-else
                            type="button"
                            class="btn btn-sm text-success p-1"
                            style="cursor: pointer; letter-spacing: 2px"
                          >
                            Received
                          </button>
                        </td>

                        <td>
                          <div class="ActionImage" v-if="row.status === 'Accepted'">
                            <span>
                              <img
                                src="../../assets/icons/view.png"
                                data-toggle="tooltip"
                                data-placement="top-start"
                                title="view"
                                @click="openInwordEntryModal(row.id)"
                                data-bs-toggle="modal"
                                data-bs-target="#InwardEntriesDetailsModal"
                                alt=""
                              />
                            </span>
                            <span>
                              <img
                                src="../../assets/icons/pinkDownold.png"
                                data-toggle="tooltip"
                                data-placement="top-start"
                                title="downold"
                                @click="handleDownloadInwardEntry(row.id)"
                                alt=""
                              />
                            </span>
                            <span>
                              <img
                                @click="openInwordEntryModal(row.id)"
                                data-bs-toggle="modal"
                                data-bs-target="#OnSiteOutwordEntriesModal"
                                src="../../assets/icons/yellowEdit.png"
                                data-toggle="tooltip"
                                data-placement="top-start"
                                title="Edit"
                                alt=""
                              />
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </section>
                <section v-if="showInwardEntry" class="table-responsive MOBILE">
                  <table class="DesignProjectTimeLine files-table">
                    <caption class="d-none">
                      Table
                    </caption>
                    <thead>
                      <tr>
                        <th>From Store</th>

                        <th>Status</th>
                        <th class="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(row, index) in InwardEntries" :key="index">
                        <td class="ellipsis">{{ row["fromStores.storeName"] }}</td>
                        <td>
                          <button
                            @click="openInwordEntryPageModal(row)"
                            v-if="row.status === 'Sent'"
                            type="button"
                            class="btn btn-sm btn-outline-primary p-1"
                            style="cursor: pointer; letter-spacing: 2px"
                          >
                            Accept
                          </button>
                          <button
                            v-else
                            type="button"
                            class="btn btn-sm text-success p-1"
                            style="cursor: pointer; letter-spacing: 2px"
                          >
                            Received
                          </button>
                        </td>
                        <td>
                          <div
                            class="d-flex align-content-center justify-content-center text-nowrap"
                          >
                            <img
                              class="ms-3"
                              src="../../assets/icons/3blackDots.png"
                              alt=""
                              style="width: 17px; height: 17px"
                              id="dropdownMenuButton"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            />
                            <div
                              class="dropdown-menu dropdown-menu-center ActionImage"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <span>
                                <img
                                  src="../../assets/icons/info.png"
                                  alt=""
                                  data-toggle="tooltip"
                                  data-placement="top-start"
                                  title="Info"
                                  data-bs-target="#tableDetailModal"
                                  data-bs-toggle="modal"
                                  @click="showInwardDetails(row)"
                                />
                              </span>
                              <span>
                                <img
                                  src="../../assets/icons/view.png"
                                  data-toggle="tooltip"
                                  data-placement="top-start"
                                  title="view"
                                  @click="openInwordEntryModal(row.id)"
                                  data-bs-toggle="modal"
                                  data-bs-target="#InwardEntriesDetailsModal"
                                  alt=""
                                />
                              </span>
                              <span>
                                <img
                                  src="../../assets/icons/pinkDownold.png"
                                  data-toggle="tooltip"
                                  data-placement="top-start"
                                  title="download"
                                  @click="handleDownloadInwardEntry(row.id)"
                                  alt=""
                                />
                              </span>
                              <span v-show="row.status === 'Accepted'">
                                <img
                                  @click="openInwordEntryPageModal(row)"
                                  src="../../assets/icons/yellowEdit.png"
                                  data-toggle="tooltip"
                                  data-placement="top-start"
                                  title="Edit"
                                  alt=""
                                />
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </section>
                <section v-if="showStockConsumption" class="table-responsive WEB">
                  <table class="DesignProjectTimeLine files-table">
                    <caption class="d-none">
                      Table
                    </caption>
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Date</th>

                        <th>Voucher Number</th>

                        <th class="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(row, index) in StocksList" :key="index">
                        <td>{{ index + 1 }}</td>

                        <td>{{ this.$formatDateDMY(row.date) }}</td>
                        <td>{{ row.stockNumber }}</td>

                        <td class="text-center">
                          <div class="ActionImage">
                            <span>
                              <img
                                src="../../assets/icons/view.png"
                                data-toggle="tooltip"
                                data-placement="top-start"
                                title="view"
                                @click="openStockConsumptionModal(row.stockNumber)"
                                data-bs-toggle="modal"
                                data-bs-target="#StockConsumptionDetailsModal"
                                alt=""
                              />
                            </span>
                            <span>
                              <img
                                src="../../assets/icons/pinkDownold.png"
                                data-toggle="tooltip"
                                data-placement="top-start"
                                title="Edit"
                                @click="handleDownloadStockConsumption(row.stockNumber)"
                                alt=""
                              />
                            </span>
                            <span @click="openStockConsumptionModal(row.stockNumber)">
                              <img
                                data-bs-toggle="modal"
                                data-bs-target="#OnSiteStockConsumptionModal"
                                src="../../assets/icons/yellowEdit.png"
                                data-toggle="tooltip"
                                data-placement="top-start"
                                title="Delete"
                                alt=""
                              />
                            </span>
                            <span>
                              <img
                                @click="DeleteStockItems(row.stockNumber, index)"
                                src="../../assets/icons/redDelete.png"
                                data-toggle="tooltip"
                                data-placement="top-start"
                                title="Delete"
                                alt=""
                              />
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </section>
                <section v-if="showStockConsumption" class="table-responsive MOBILE">
                  <table class="DesignProjectTimeLine files-table">
                    <caption class="d-none">
                      Table
                    </caption>
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Date</th>
                        <th>Voucher Number</th>

                        <th class="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(row, index) in StocksList" :key="index">
                        <td>{{ index + 1 }}</td>

                        <td>{{ row.stockNumber }}</td>
                        <td>{{ this.$formatDateDMY(row.date) }}</td>
                        <td class="text-center">
                          <div
                            class="d-flex align-content-center justify-content-center text-nowrap"
                          >
                            <img
                              class="ms-3"
                              src="../../assets/icons/3blackDots.png"
                              alt=""
                              style="width: 17px; height: 17px"
                              id="dropdownMenuButton"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            />
                            <div
                              class="dropdown-menu dropdown-menu-center ActionImage"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <span>
                                <img
                                  src="../../assets/icons/info.png"
                                  alt=""
                                  srcset=""
                                  data-toggle="tooltip"
                                  data-placement="top-start"
                                  title="Info"
                                  data-bs-target="#tableDetailModal"
                                  data-bs-toggle="modal"
                                  @click="showStockDetails(row)"
                                />
                              </span>
                              <span>
                                <img
                                  src="../../assets/icons/view.png"
                                  data-toggle="tooltip"
                                  data-placement="top-start"
                                  title="view"
                                  @click="openStockConsumptionModal(row.stockNumber)"
                                  data-bs-toggle="modal"
                                  data-bs-target="#StockConsumptionDetailsModal"
                                  alt=""
                                />
                              </span>
                              <span>
                                <img
                                  src="../../assets/icons/pinkDownold.png"
                                  data-toggle="tooltip"
                                  data-placement="top-start"
                                  title="Edit"
                                  @click="handleDownloadStockConsumption(row.stockNumber)"
                                  alt=""
                                />
                              </span>
                              <span @click="openStokEntryPage(row.stockNumber)">
                                <img
                                  src="../../assets/icons/yellowEdit.png"
                                  data-toggle="tooltip"
                                  data-placement="top-start"
                                  title="Delete"
                                  alt=""
                                />
                              </span>
                              <span>
                                <img
                                  @click="DeleteStockItems(row.stockNumber, index)"
                                  src="../../assets/icons/redDelete.png"
                                  data-toggle="tooltip"
                                  data-placement="top-start"
                                  title="Delete"
                                  alt=""
                                />
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </section>
                <section v-if="showOutwardEntry" class="table-responsive WEB">
                  <table class="DesignProjectTimeLine files-table">
                    <caption class="d-none">
                      Table
                    </caption>
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Date</th>
                        <th>To Store</th>
                        <th>Voucher Number</th>
                        <th class="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(row, index) in OutwardEntries" :key="index">
                        <td>{{ index + 1 }}</td>

                        <td>{{ formatDate(row.date) }}</td>
                        <td>
                          {{ row["toStores.storeName"] }}
                        </td>
                        <td>
                          {{ row.voucherNo }}
                        </td>

                        <td class="text-center">
                          <div class="ActionImage">
                            <span>
                              <img
                                src="../../assets/icons/view.png"
                                data-toggle="tooltip"
                                data-placement="top-start"
                                title="view"
                                @click="openOutwardEntriesModal(row.id)"
                                data-bs-toggle="modal"
                                data-bs-target="#OutwardEntriesDetailsModal"
                                alt=""
                              />
                            </span>
                            <span>
                              <img
                                src="../../assets/icons/pinkDownold.png"
                                data-toggle="tooltip"
                                data-placement="top-start"
                                title="download"
                                @click="handleDownloadOutwardEntries(row.id)"
                                alt=""
                              />
                            </span>
                            <span>
                              <img
                                @click="DeleteOutWardEntry(row.id, index)"
                                v-if="row.status === 'Sent'"
                                src="../../assets/icons/redDelete.png"
                                data-toggle="tooltip"
                                data-placement="top-start"
                                title="Delete"
                                alt=""
                              />
                              <img
                                v-else
                                src="../../assets/icons/greyDelete.png"
                                data-toggle="tooltip"
                                data-placement="top-start"
                                title="The Entry Already Accepted"
                                style="cursor: not-allowed"
                                alt=""
                              />
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </section>
                <section v-if="showOutwardEntry" class="table-responsive MOBILE">
                  <table class="DesignProjectTimeLine files-table">
                    <caption class="d-none">
                      Table
                    </caption>
                    <thead>
                      <tr>
                        <th>S/N</th>

                        <th>To Store</th>
                        <th>Date</th>
                        <th class="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(row, index) in OutwardEntries" :key="index">
                        <td>{{ index + 1 }}</td>

                        <td class="ellipsis">
                          {{ row["toStores.storeName"] }}
                        </td>
                        <td>{{ formatDate(row.date) }}</td>
                        <td>
                          <div
                            class="d-flex align-content-center justify-content-center text-nowrap"
                          >
                            <img
                              class="ms-3"
                              src="../../assets/icons/3blackDots.png"
                              alt=""
                              style="width: 17px; height: 17px"
                              id="dropdownMenuButton"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            />
                            <div
                              class="dropdown-menu dropdown-menu-center ActionImage"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <span>
                                <img
                                  src="../../assets/icons/info.png"
                                  alt=""
                                  srcset=""
                                  data-toggle="tooltip"
                                  data-placement="top-start"
                                  title="Info"
                                  data-bs-target="#tableDetailModal"
                                  data-bs-toggle="modal"
                                  @click="showOutwardDetails(row)"
                                />
                              </span>
                              <span>
                                <img
                                  src="../../assets/icons/view.png"
                                  data-toggle="tooltip"
                                  data-placement="top-start"
                                  title="view"
                                  @click="openOutwardEntriesModal(row.id)"
                                  data-bs-toggle="modal"
                                  data-bs-target="#OutwardEntriesDetailsModal"
                                  alt=""
                                />
                              </span>
                              <span>
                                <img
                                  src="../../assets/icons/pinkDownold.png"
                                  data-toggle="tooltip"
                                  data-placement="top-start"
                                  title="download"
                                  @click="handleDownloadOutwardEntries(row.id)"
                                  alt=""
                                />
                              </span>
                              <span>
                                <img
                                  @click="DeleteOutWardEntry(row.id, index)"
                                  v-if="row.status === 'Sent'"
                                  src="../../assets/icons/redDelete.png"
                                  data-toggle="tooltip"
                                  data-placement="top-start"
                                  title="Delete"
                                  alt=""
                                />
                                <img
                                  v-else
                                  src="../../assets/icons/greyDelete.png"
                                  data-toggle="tooltip"
                                  data-placement="top-start"
                                  title="The Entry Already Accepted"
                                  style="cursor: not-allowed"
                                  alt=""
                                />
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </section>
                <section v-if="showInventoryEntry" class="table-responsive WEB">
                  <table class="DesignProjectTimeLine files-table">
                    <caption class="d-none">
                      Table
                    </caption>
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Product Name</th>
                        <th class="text-center">Unit type</th>
                        <th class="text-end">Inward Qty</th>
                        <th class="text-end">Stock Consumption</th>
                        <th class="text-end">Outward Qty</th>
                        <th class="text-end">Available Qty</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(row, index) in InventryItems" :key="index">
                        <td>
                          {{ index + 1 }}
                        </td>

                        <td>{{ row["products.productName"] }}</td>
                        <td class="text-center">
                          {{ row["products.unitType"] }}
                        </td>

                        <td class="text-end">{{ row.inwardQuantity }}</td>
                        <td class="text-end">{{ row.stockConsumption }}</td>
                        <td class="text-end">{{ row.outwardQuantity }}</td>
                        <td class="text-end">{{ row.availableQuantity }}</td>
                      </tr>
                    </tbody>
                  </table>
                </section>
                <section v-if="showInventoryEntry" class="table-responsive MOBILE">
                  <table class="DesignProjectTimeLine files-table">
                    <caption class="d-none">
                      Table
                    </caption>
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Product Name</th>

                        <th class="text-end">Available Qty</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(row, index) in InventryItems" :key="index">
                        <td class="ActionImage">
                          {{ index + 1 }}
                          <span>
                            <img
                              src="../../assets/icons/info.png"
                              alt=""
                              srcset=""
                              data-toggle="tooltip"
                              data-placement="top-start"
                              title="Info"
                              data-bs-target="#tableDetailModal"
                              data-bs-toggle="modal"
                              @click="showLedgerDetails(row)"
                            />
                          </span>
                        </td>

                        <td>{{ row["products.productName"] }}</td>

                        <td class="text-end">{{ row.availableQuantity }}</td>
                      </tr>
                    </tbody>
                  </table>
                </section>
              </div>
            </div>
            <div class="DesignProjectTimeLineWrapper" v-if="InwardEntryPage">
              <div
                class="d-flex justify-content-between HeadingOfCreateGRN"
                style="margin: 0.5% 1%"
              >
                <span
                  style="
                    display: flex;
                    align-items: center;
                    margin-left: 2px;
                    font-size: 14px;
                  "
                >
                  Accept Material from {{ this.Inwardform["fromStores.storeName"] }}
                </span>
                <button class="btn btn-sm btn-close" @click="closeInwardPage()"></button>
              </div>
              <div
                v-for="(row, rowIndex) in Inwardform.Lineitems"
                :key="rowIndex"
                class="mt-3"
              >
                <div class="activity-card">
                  <div class="card-header d-flex justify-content-between">
                    <span class="activity-title"> {{ row["products.productName"] }}</span>
                    <span class="d-flex align-items-center" style="padding: 0 10px">
                      <div
                        class="dropdown-menu dropdown-menu-center ActionImage"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <span>
                          <img
                            src="../../assets/icons/info.png"
                            alt=""
                            srcset=""
                            data-toggle="tooltip"
                            data-placement="top-start"
                            title="Info"
                            data-bs-target="#tableDetailModal"
                            data-bs-toggle="modal"
                            @click="showTimeLineDetails(row)"
                          />
                        </span>

                        <span>
                          <img
                            src="../../assets/icons/yellowEdit.png"
                            data-toggle="tooltip"
                            data-placement="top-start"
                            title="Edit"
                            alt=""
                            @click="editRow(rowIndex)"
                            data-bs-toggle="modal"
                            data-bs-target="#addProductModal"
                          />
                        </span>
                      </div>
                    </span>
                  </div>

                  <div class="card-details">
                    <div class="detail-item">
                      <label>Sent</label>
                      <span>{{ row.sentQuantity }}</span>
                    </div>
                    <div class="detail-item">
                      <label>Received</label>
                      <span>
                        <input
                          class="form-control"
                          type="number"
                          v-model="row.receivedQuantity"
                      /></span>
                    </div>
                    <div class="detail-item">
                      <label>Damaged</label>
                      <span>
                        <input
                          class="form-control"
                          type="number"
                          v-model="row.damagedQuantity"
                        />
                      </span>
                    </div>
                    <div class="detail-item">
                      <label>Missing</label>
                      <span>
                        <input
                          class="form-control"
                          type="number"
                          v-model="row.missingQuantity"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="footerPuchaseRequest">
                <button
                  type="button"
                  class="btn btn-sm btn-primary"
                  @click="CreateInWordEntry()"
                >
                  Save
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-secondary"
                  @click="closeInwardPage()"
                >
                  Cancel
                </button>
              </div>
            </div>
            <div class="DesignProjectTimeLineWrapper" v-if="StockEntryPage">
              <div
                class="d-flex justify-content-between HeadingOfCreateGRN"
                style="margin: 0.5% 1%"
              >
                <span
                  style="
                    display: flex;
                    align-items: center;
                    margin-left: 2px;
                    font-size: 14px;
                  "
                >
                  New Stock Consumption Voucher
                </span>
                <button
                  class="btn btn-sm btn-close"
                  @click="closeStockEntryPage()"
                ></button>
              </div>
              <div>
                <div class="d-flex justify-content-between" style="margin: 0.5% 1%">
                  <div>
                    <label for="date" class="col-md-4 col-sm-4 col-form-label"
                      >Date:</label
                    >
                    <div class="col-md-8 col-sm-8" style="font-size: 12px !important">
                      <input
                        type="date"
                        class="form-control border-black w-100"
                        id="date"
                        v-model="this.form.date"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      class="col-md-4 col-sm-4 col-form-label"
                      style="visibility: hidden"
                      >Date:</label
                    >
                    <button
                      class="btn btn-sm btn-primary justify-content-end p-2"
                      @click="addRow"
                    >
                      Add
                    </button>
                  </div>
                </div>
                <div v-for="(row, index) in stocks" :key="index" class="mt-3">
                  <div class="activity-card">
                    <div class="card-header d-flex justify-content-between">
                      <span class="activity-title">
                        <input
                          class="w-100 form-control"
                          list="products"
                          v-model="row.productName"
                          @focus="activeRow = index"
                          @input="searchProduct(row.productName, index)"
                          @change="fillProductDetails(index)"
                          :ref="'productNameInput' + index"
                        />
                        <ul
                          v-if="row.filteredProducts.length > 0 && activeRow === index"
                          class="dropdown-menu show"
                        >
                          <li
                            v-for="product in row.filteredProducts"
                            :key="product.id"
                            @click="selectProduct(product, index)"
                            class="p-2"
                          >
                            {{ product["products.productName"] }} ({{
                              product.availableQuantity
                            }})
                          </li>
                        </ul></span
                      >
                      <span class="d-flex align-items-center" style="padding: 0 10px">
                        <div
                          class="dropdown-menu dropdown-menu-center ActionImage"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <span>
                            <img
                              src="../../assets/icons/info.png"
                              alt=""
                              srcset=""
                              data-toggle="tooltip"
                              data-placement="top-start"
                              title="Info"
                              data-bs-target="#tableDetailModal"
                              data-bs-toggle="modal"
                              @click="showTimeLineDetails(row)"
                            />
                          </span>

                          <span>
                            <img
                              src="../../assets/icons/yellowEdit.png"
                              data-toggle="tooltip"
                              data-placement="top-start"
                              title="Edit"
                              alt=""
                              @click="editRow(rowIndex)"
                              data-bs-toggle="modal"
                              data-bs-target="#addProductModal"
                            />
                          </span>
                          <span> </span>
                        </div>
                      </span>
                    </div>

                    <div class="card-details">
                      <div class="detail-item">
                        <label>Product</label>
                        <span>
                          <input
                            type="text"
                            class="form-control"
                            v-model="row.productName"
                        /></span>
                      </div>
                      <div class="detail-item">
                        <label>Quantity</label>
                        <span>
                          <input
                            type="number"
                            class="form-control"
                            v-model.number="row.quantity"
                        /></span>
                      </div>
                      <div class="detail-item">
                        <label>Unit Type</label>
                        <span>
                          <input type="text" class="form-control" v-model="row.unitType"
                        /></span>
                      </div>

                      <div class="detail-item">
                        <label>Action</label>
                        <span>
                          <img
                            style="width: 13px; height: 13px; cursor: pointer"
                            :src="require('../../assets/icons/redDelete.png')"
                            alt="Delete"
                            @click="removeRow(index)"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="footerPuchaseRequest">
                    <button
                      v-if="!this.StockNumber"
                      type="button"
                      class="btn btn-primary"
                      @click="CreateStockConsumption"
                    >
                      Save
                    </button>
                    <button
                      v-else
                      type="button"
                      class="btn btn-primary"
                      @click="updateStock"
                    >
                      Update
                    </button>
                    <button
                      type="button"
                      class="btn btn-secondary"
                      @click="closeStockEntryPage"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="DesignProjectTimeLineWrapper" v-if="OutWardEntryPage">
              <div
                class="d-flex justify-content-between HeadingOfCreateGRN"
                style="margin: 0.5% 1%"
              >
                <span
                  style="
                    display: flex;
                    align-items: center;
                    margin-left: 2px;
                    font-size: 14px;
                  "
                >
                  New Outward Entry Voucher
                </span>
                <button
                  class="btn btn-sm btn-close"
                  @click="closeOutWardEntryPage()"
                ></button>
              </div>
              <div>
                <div class="d-flex justify-content-between" style="margin: 0.5% 1%">
                  <div>
                    <label for="date" class="col-form-label">Date:</label>

                    <div class="col-md-8 col-sm-8" style="font-size: 12px !important">
                      <input
                        type="date"
                        class="form-control"
                        v-model="outwardform.date"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      class="col-md-4 col-sm-4 col-form-label"
                      style="visibility: hidden"
                      >Date:</label
                    >
                    <button
                      class="btn btn-sm btn-primary justify-content-end p-2"
                      @click="addRows"
                    >
                      Add
                    </button>
                  </div>
                </div>
                <div class="d-flex justify-content-between" style="margin: 0.5% 1%">
                  <div>
                    <label for="projectName" class="col-form-label"
                      >To Store: <span class="text-danger">*</span></label
                    >
                    <div class="col-md-8 col-sm-8" style="font-size: 12px !important">
                      <select
                        class="form-control form-select"
                        id="category"
                        v-model="outwardform.toStore"
                      >
                        <option
                          v-for="Project in Projects"
                          :key="Project.id"
                          :value="Project.id"
                        >
                          {{ Project.storeName }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div v-for="(row, index) in OutWardEntriesRows" :key="index" class="mt-3">
                  <div class="activity-card">
                    <div class="card-header d-flex justify-content-between">
                      <span class="activity-title">
                        <input
                          class="w-100 form-control"
                          list="products"
                          v-model="row.productName"
                          @focus="activeRow = index"
                          @input="searchProducts(row.productName, index)"
                          @change="fillProductDetailss(index)"
                          :ref="'productNameInput' + index"
                        />
                        <ul
                          v-if="row.filteredProducts.length > 0 && activeRow === index"
                          class="dropdown-menu show"
                        >
                          <li
                            v-for="product in row.filteredProducts"
                            :key="product.id"
                            @click="selectProducts(product, index)"
                            class="p-2"
                          >
                            {{ product["products.productName"] }} ({{
                              product.availableQuantity
                            }})
                          </li>
                        </ul></span
                      >
                      <span class="d-flex align-items-center" style="padding: 0 10px">
                        <div
                          class="dropdown-menu dropdown-menu-center ActionImage"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <span>
                            <img
                              src="../../assets/icons/info.png"
                              alt=""
                              srcset=""
                              data-toggle="tooltip"
                              data-placement="top-start"
                              title="Info"
                              data-bs-target="#tableDetailModal"
                              data-bs-toggle="modal"
                              @click="showTimeLineDetails(row)"
                            />
                          </span>

                          <span>
                            <img
                              src="../../assets/icons/yellowEdit.png"
                              data-toggle="tooltip"
                              data-placement="top-start"
                              title="Edit"
                              alt=""
                              @click="editRow(rowIndex)"
                              data-bs-toggle="modal"
                              data-bs-target="#addProductModal"
                            />
                          </span>
                          <span> </span>
                        </div>
                      </span>
                    </div>

                    <div class="card-details">
                      <div class="detail-item">
                        <label>Product</label>
                        <span>
                          <input
                            type="text"
                            class="form-control"
                            v-model="row.productName"
                        /></span>
                      </div>
                      <div class="detail-item">
                        <label>Quantity</label>
                        <span>
                          <input
                            type="number"
                            class="form-control"
                            v-model.number="row.sentQuantity"
                        /></span>
                      </div>
                      <div class="detail-item">
                        <label>Unit Type</label>
                        <span>
                          <input type="text" class="form-control" v-model="row.unitType"
                        /></span>
                      </div>

                      <div class="detail-item">
                        <label>Action</label>
                        <span>
                          <img
                            style="width: 13px; height: 13px; cursor: pointer"
                            :src="require('../../assets/icons/redDelete.png')"
                            alt="Delete"
                            @click="removeRows(index)"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="footerPuchaseRequest">
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="CreateOutWordEntry"
                    >
                      Save
                    </button>

                    <button
                      type="button"
                      class="btn btn-secondary"
                      @click="closeOutWardEntryPage"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="activeTab === 'contractor'">
            <div class="DesignProjectTimeLineWrapper">
              <div class="d-flex stockEntryview HeadingOfCreateGRN">
                <button
                  class="btn btn-sm d-flex btn-primary text-light bg-primary"
                  @click="openAddQuotationModal('null')"
                  data-bs-toggle="modal"
                  data-bs-target="#AddQuotation"
                >
                  <span class="d-flex"> + Add <span class="WEB"> Quotation</span></span>
                </button>
                <button
                  class="btn btn-sm d-flex"
                  :class="{ 'blackactive-view': showPendingQuotations }"
                  @click="switchToQuotationTable('showPendingQuotations')"
                >
                  <span class="d-flex">
                    Pending <span class="WEB"> Quotations</span></span
                  >
                </button>
                <button
                  class="btn btn-sm d-flex"
                  :class="{ 'blackactive-view': showApprovedQuotations }"
                  @click="switchToQuotationTable('showApprovedQuotations')"
                >
                  <span class="d-flex">
                    Approved <span class="WEB"> Quotations</span></span
                  >
                </button>
                <button
                  class="btn btn-sm d-flex"
                  :class="{ 'blackactive-view': showRejectedQuotations }"
                  @click="switchToQuotationTable('showRejectedQuotations')"
                >
                  Rejected <span class="WEB"> Quotations</span>
                </button>
              </div>

              <div class="DesignProjectLinePage WEB">
                <section class="table-responsive">
                  <table class="DesignProjectTimeLine files-table">
                    <caption class="d-none">
                      Table
                    </caption>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Contractor Name</th>
                        <th>Category</th>
                        <th>Work Description</th>
                        <th>Created By</th>
                        <th class="text-center">Quotation Value</th>
                        <th class="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(row, index) in Quatations" :key="index">
                        <td>
                          {{ formatDate(row.date) }}
                        </td>
                        <td>{{ row["onSiteContractors.name"] }}</td>
                        <td>
                          {{ row["onSiteCategory.categoryName"] }}
                        </td>

                        <td>
                          {{ row.remark }}
                        </td>
                        <td>
                          {{ row["employees.firstName"] }}
                        </td>
                        <td class="text-end">
                          {{ $formatToIndianCurrency(row.quotationValue) }}
                        </td>

                        <td class="text-center">
                          <div
                            class="d-flex align-content-center justify-content-center text-nowrap"
                          >
                            <img
                              class="ms-3"
                              src="../../assets/icons/3blackDots.png"
                              alt=""
                              style="width: 17px; height: 17px"
                              id="dropdownMenuButton"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            />
                            <div
                              class="dropdown-menu dropdown-menu-center ActionImage"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <span>
                                <img
                                  src="../../assets/icons/info.png"
                                  alt=""
                                  srcset=""
                                  data-toggle="tooltip"
                                  data-placement="top-start"
                                  title="Info"
                                  data-bs-target="#tableDetailModal"
                                  data-bs-toggle="modal"
                                  @click="showQuotationDetails(row)"
                                />
                              </span>
                              <span v-if="this.currentTable === 'Pending'">
                                <img
                                  src="../../assets/icons/approve.png"
                                  data-toggle="tooltip"
                                  data-placement="top-start"
                                  title="Approve"
                                  @click="openApprovalModal(row.id)"
                                  alt=""
                                />
                              </span>
                              <span v-if="this.currentTable === 'Pending'">
                                <img
                                  src="../../assets/icons/reject.png"
                                  data-toggle="tooltip"
                                  data-placement="top-start"
                                  title="Reject"
                                  @click="openRejectModal(row.id)"
                                  alt=""
                                />
                              </span>
                              <span>
                                <img
                                  v-if="row['awsFiles.awsFileName']"
                                  src="../../assets/icons/view.png"
                                  alt=""
                                  data-toggle="tooltip"
                                  data-placement="top-start"
                                  title="view"
                                  @click="
                                    $viewFile(
                                      row['awsFiles.awsFileName'],
                                      row['awsFiles.fileName'],
                                      'onSiteQuotation',
                                      'onSite'
                                    )
                                  "
                                />
                              </span>
                              <span>
                                <img
                                  v-if="row['awsFiles.awsFileName']"
                                  src="../../assets/icons/pinkDownold.png"
                                  alt=""
                                  data-toggle="tooltip"
                                  data-placement="top-start"
                                  title="downlod"
                                  @click="
                                    $downloadFile(
                                      row['awsFiles.awsFileName'],
                                      row['awsFiles.fileName'],
                                      'onSiteQuotation',
                                      'onSite'
                                    )
                                  "
                                />
                              </span>
                              <span v-if="this.currentTable === 'Pending'">
                                <img
                                  src="../../assets/icons/yellowEdit.png"
                                  alt=""
                                  @click="openAddQuotationModal(row.id)"
                                  data-bs-toggle="modal"
                                  data-bs-target="#AddQuotation"
                                  data-toggle="tooltip"
                                  data-placement="top-start"
                                  title="Edit"
                                />
                              </span>
                              <span v-if="this.currentTable === 'Pending'">
                                <img
                                  src="../../assets/icons/redDelete.png"
                                  @click="DeleteQuotation(row.id, index)"
                                  data-toggle="tooltip"
                                  data-placement="top-start"
                                  title="Edit"
                                  alt=""
                                />
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </section>
              </div>
              <div class="DesignProjectLinePage MOBILE">
                <section class="table-responsive">
                  <table class="DesignProjectTimeLine files-table">
                    <caption class="d-none">
                      Quotation List
                    </caption>
                    <thead>
                      <tr>
                        <th class="ellipsis">Contractor</th>
                        <th class="text-center">Amount</th>
                        <th class="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(row, index) in Quatations" :key="index">
                        <td class="ellipsis">
                          {{ row["onSiteContractors.name"] }}
                        </td>
                        <td class="text-end">
                          {{ $formatToIndianCurrency(row.quotationValue) }}
                        </td>
                        <td class="text-center">
                          <div
                            class="d-flex align-content-center justify-content-center text-nowrap"
                          >
                            <img
                              class="ms-3"
                              src="../../assets/icons/3blackDots.png"
                              alt=""
                              style="width: 17px; height: 17px"
                              id="dropdownMenuButton"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            />
                            <div
                              class="dropdown-menu dropdown-menu-center ActionImage"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <span>
                                <img
                                  src="../../assets/icons/info.png"
                                  alt=""
                                  srcset=""
                                  data-toggle="tooltip"
                                  data-placement="top-start"
                                  title="Info"
                                  data-bs-target="#tableDetailModal"
                                  data-bs-toggle="modal"
                                  @click="showQuotationDetails(row)"
                                />
                              </span>
                              <span v-if="this.currentTable === 'Pending'">
                                <img
                                  src="../../assets/icons/approve.png"
                                  data-toggle="tooltip"
                                  data-placement="top-start"
                                  title="Approve"
                                  @click="openApprovalModal(row.id)"
                                  alt=""
                                />
                              </span>
                              <span v-if="this.currentTable === 'Pending'">
                                <img
                                  src="../../assets/icons/reject.png"
                                  data-toggle="tooltip"
                                  data-placement="top-start"
                                  title="Reject"
                                  @click="openRejectModal(row.id)"
                                  alt=""
                                />
                              </span>
                              <span>
                                <img
                                  v-if="row['awsFiles.awsFileName']"
                                  src="../../assets/icons/view.png"
                                  alt=""
                                  data-toggle="tooltip"
                                  data-placement="top-start"
                                  title="view"
                                  @click="
                                    $viewFile(
                                      row['awsFiles.awsFileName'],
                                      row['awsFiles.fileName'],
                                      'onSiteQuotation',
                                      'onSite'
                                    )
                                  "
                                />
                              </span>
                              <span>
                                <img
                                  v-if="row['awsFiles.awsFileName']"
                                  src="../../assets/icons/pinkDownold.png"
                                  alt=""
                                  data-toggle="tooltip"
                                  data-placement="top-start"
                                  title="downlod"
                                  @click="
                                    $downloadFile(
                                      row['awsFiles.awsFileName'],
                                      row['awsFiles.fileName'],
                                      'onSiteQuotation',
                                      'onSite'
                                    )
                                  "
                                />
                              </span>
                              <span v-if="this.currentTable === 'Pending'">
                                <img
                                  src="../../assets/icons/yellowEdit.png"
                                  alt=""
                                  @click="openAddQuotationModal(row.id)"
                                  data-bs-toggle="modal"
                                  data-bs-target="#AddQuotation"
                                  data-toggle="tooltip"
                                  data-placement="top-start"
                                  title="Edit"
                                />
                              </span>
                              <span v-if="this.currentTable === 'Pending'">
                                <img
                                  src="../../assets/icons/redDelete.png"
                                  @click="DeleteQuotation(row.id, index)"
                                  data-toggle="tooltip"
                                  data-placement="top-start"
                                  title="Edit"
                                  alt=""
                                />
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </section>
              </div>
            </div>
          </div>
          <transition name="fade">
            <div v-if="showModal" class="modal-overlay" @click="openApprovalModal"></div>
          </transition>
          <transition name="slide">
            <div class="modal show d-block" v-if="showModal" style="z-index: 9999999">
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <p class="modal-title">
                      Are you sure you want to approve this Quotation file?
                    </p>
                  </div>

                  <div class="modal-footer d-flex justify-content-center">
                    <button
                      type="button"
                      class="btn btn-sm btn-primary"
                      @click="approveDocument"
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      class="btn btn-sm btn-secondary"
                      @click="showModal = false"
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </transition>

          <div
            class="modal show d-block"
            v-if="showRejectionModal"
            tabindex="-1"
            role="dialog"
          >
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <p class="modal-title">
                    Are you sure you want to reject this Quotation file?
                  </p>
                </div>

                <div class="modal-footer d-flex justify-content-center">
                  <button
                    type="button"
                    class="btn btn-sm btn-primary"
                    @click="rejectDocument"
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    class="btn btn-sm btn-secondary"
                    @click="showRejectionModal = false"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <CreateScopeOfWork
    :onSiteProjectId="this.Id"
    :projectId="this.mainProjectId"
    :timePage="this.timePage"
    :ScopeOfWorkParentId="this.ScopeOfWorkParentId"
    :ScopeOfWorkRowId="this.ScopeOfWorkRowId"
    :scopeOfWorkHeadername="this.scopeOfWorkHeadername"
    @fetchscopeOfWork="fetchscopeOfWork()"
  />
  <OnSiteStockConsumptionModal
    :FromStore="StoreId"
    :CompanyprojectId="this.mainProjectId"
    :StockNumber="stockNumber"
    @fetchStockConsumptionEntries="fetchStockConsumptionEntries()"
  />

  <AddQuotationModal
    :projectId="Id"
    :contractorId="ContractorId"
    :quotationId="QuotationId"
    @fetchQuotations="fetchQuotationByStatus(this.currentTable)"
  />
  <ShareWithprojectModal :onSiteprojectId="Id" />
  <AddContractorModal :projectId="Id" @featchData="fetchdisplayedData()" />
  <OnsiteContractorDetailsModal :contractorId="ContractorId" />
  <PRDetailsModal :PurchaseRequestId="selectedPRId" ref="PRDetailsModal" />
  <PODetailsModal :PurchaseOrderId="selectedPoId" ref="PODetailsModal" />
  <OnSiteGrnDetailsModal :GRNId="selectedGrnId" ref="OnSiteGrnDetailsModal" />
  <InwardEntriesDetailsModal
    :InwardEntryId="InwardEntryModalId"
    ref="InwardEntriesDetailsModal"
  />
  <StockConsumptionDetailsModal
    :StockNumber="stockNumber"
    ref="StockConsumptionDetailsModal"
  />
  <OutwardEntriesDetailsModal
    :StockNumber="OutwardEntryId"
    ref="OutwardEntriesDetailsModal"
  />

  <CreatePurchaseInvoice
    :customerId="CustomerId"
    :SelectedPIId="selectedPIId"
    @fetchpurchaseInvoice="fetchpurchaseInvoice()"
  />
  <OnsiteEntriesModal
    :FromStore="StoreId"
    :CompanyprojectId="this.mainProjectId"
    @fetchOutwardEntries="fetchOutwardEntries()"
  />
  <OnSiteOutwordEntriesModal
    :InwardEntryId="InwardEntryModalId"
    :FromStore="StoreId"
    @fetchInwardEntries="fetchInwardEntries()"
  />
  <TableDetailsModal :details="details" />

  <div class="modal fade" id="addProductModal" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Update Product Row</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            @click="resetProductModal"
          ></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="handleModalSubmit">
            <div class="mb-3">
              <label class="form-label">Product Name</label>
              <div class="d-flex align-items-center">
                <input
                  v-model="modalData.productName"
                  class="form-control border-black w-100"
                  readOnly
                />
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label">Description</label>
              <input
                type="text"
                v-model="modalData.description"
                class="form-control border-black w-100"
                readOnly
              />
            </div>
            <div class="mb-3">
              <label class="form-label">Order Qty</label>
              <input
                type="text"
                v-model="modalData.quantity"
                class="form-control border-black w-100"
                @change="updateQuantities(this.SelectedRowIndex)"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">Received Quantity</label>
              <input
                type="number"
                v-model="modalData.receivedQty"
                :disabled="modalData.checked"
                class="form-control border-black w-100"
                @input="validateInput(this.SelectedRowIndex, 'receivedQty')"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">Damaged Quantity</label>
              <input
                type="number"
                v-model="modalData.damagedQty"
                :disabled="modalData.checked"
                @input="validateInput(this.SelectedRowIndex, 'damagedQty')"
                class="form-control border-black w-100"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">Missing Quantity</label>
              <input
                type="number"
                v-model="modalData.missingQty"
                :disabled="modalData.checked"
                @input="validateInput(this.SelectedRowIndex, 'missingQty')"
                class="form-control border-black w-100"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">Remark</label>
              <input
                type="text"
                v-model="modalData.remark"
                class="form-control border-black w-100"
              />
            </div>
          </form>
        </div>
        <div class="modal-footer d-flex justify-content-between">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            @click="resetProductModal"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="UpdateModalProduct()"
            v-if="SelectedRowId"
            data-bs-dismiss="modal"
          >
            Update
          </button>
          <button
            type="button"
            class="btn btn-sm btn-primary"
            @click="addModalProduct"
            :disabled="!modalData.productName"
            v-else
          >
            Add
          </button>
        </div>
      </div>
    </div>
  </div>
  <ProjectActivityModal
    :projectId="DesignProjectId"
    :team="TeamName"
    @setProjectId="setProjectId"
  />
</template>

<script>
import ProjectActivityModal from "../Modal/ProjectActivityModal.vue";
import TableDetailsModal from "../Modal/TableDetailsModal.vue";
import CreateScopeOfWork from "../Modal/CreateScopeOfWork.vue";
import OutwardEntriesDetailsModal from "../Modal/OutwardEntriesDetailsModal.vue";
import StockConsumptionDetailsModal from "../Modal/StockConsumptionDetailsModal.vue";
import InwardEntriesDetailsModal from "../Modal/InwardEntriesDetailsModal.vue";
import OnSiteGrnDetailsModal from "../Modal/OnSiteGrnDetailsModal.vue";
import OnSiteStockConsumptionModal from "../Modal/OnSiteStockConsumptionModal.vue";
import OnSiteOutwordEntriesModal from "../Modal/OnSiteOutwordEntriesModal.vue";
import OnsiteEntriesModal from "../Modal/OnsiteEntriesModal.vue";
import CreatePurchaseInvoice from "../Modal/CreatePurchaseInvoice.vue";
import PRDetailsModal from "../Modal/PRDetailsModal.vue";
import PODetailsModal from "../Modal/PODetailsModal.vue";
import AddQuotationModal from "../Modal/AddQuotationModal";
import OnsiteContractorDetailsModal from "../Modal/OnsiteContractorDetailsModal";
import ShareWithprojectModal from "../Modal/ShareWithProjectModal.vue";
import AddContractorModal from "../Modal/AddContractorModal.vue";
import axios from "axios";
import Header from "../Header.vue";
import Nav from "../Nav.vue";
import VueApexCharts from "vue3-apexcharts";

export default {
  name: "OnSiteProjectProfile",
  components: {
    Header,
    Nav,
    apexchart: VueApexCharts,
    AddContractorModal,
    ShareWithprojectModal,
    PRDetailsModal,
    AddQuotationModal,
    OnsiteContractorDetailsModal,
    CreatePurchaseInvoice,
    OnsiteEntriesModal,
    OnSiteOutwordEntriesModal,
    OnSiteStockConsumptionModal,
    PODetailsModal,
    OnSiteGrnDetailsModal,
    InwardEntriesDetailsModal,
    StockConsumptionDetailsModal,
    OutwardEntriesDetailsModal,
    CreateScopeOfWork,
    TableDetailsModal,
    ProjectActivityModal,
  },
  data() {
    return {
      scopeOfWorkHeadername: null,
      TeamName: "Onsite",
      DesignProjectId: this.$route.params.Id,
      Products: [],
      Projects: [],
      OutWardEntriesRows: [
        {
          productName: "",
          sentQuantity: 0,
          unitType: "",
          productId: "",
          filteredProducts: [],
          rowProducts: [],
        },
      ],
      outwardform: {
        date: new Date().toISOString().slice(0, 10),
        fromStore: this.StoreId,
        toStore: null,
        status: "Sent",
        Rows: [],
      },
      InwardEntrieStatus: null,
      Inwardform: {
        date: new Date().toISOString().slice(0, 10),
        fromStore: null,
        toStore: null,
        status: "Accepted",
      },
      InwardEntriesById: [
        {
          productId: "",
          sentQuantity: 0,
          receivedQuantity: 0,
          damagedQuantity: 0,
          missingQuantity: 0,
          remark: "",
        },
      ],
      AllEntryPage: true,
      InwardEntryPage: false,
      StockEntryPage: false,
      OutWardEntryPage: false,
      timePage: false,
      SelectedRowIndex: null,
      SelectedRowId: false,
      modalData: {
        productName: "",
        description: "",
        quantity: null,
        receivedQty: null,
        damagedQty: null,
        missingQty: null,
        remark: "",
        checked: false,
      },
      ScopeOfWorkParentId: null,
      ScopeOfWorkRowId: null,
      details: [],
      selectedPIId: null,
      showRejectionModal: false,
      showModal: false,
      selectedQuotationId: null,
      currentTable: "Pending",
      showPendingQuotations: true,
      showRejectedQuotations: false,
      showApprovedQuotations: false,
      Quatations: [],
      OutwardEntryId: null,
      userRoles: this.$LocalUser.employeeRoles,
      showExecative: false,
      Employees: [],
      mainProjectId: null,
      PurchaseInvoiceFileUrl: null,
      selectedPoId: null,
      selectedGrnId: null,
      InventryItems: [],
      stockNumber: null,

      StocksList: [],
      InwardEntryModalId: null,
      StoreId: null,
      stocks: [
        {
          id: null,
          stockNumber: this.StockNumber,
          productName: "",
          quantity: 0,
          unitType: "",
          storeId: this.StoreId,
          projectId: this.mainProjectId,
          date: new Date().toISOString().slice(0, 10),
          filteredProducts: [],
          rowProducts: [],
        },
      ],
      form: {
        date: new Date().toISOString().slice(0, 10),
      },
      showInwardEntry: true,
      showStockConsumption: false,
      showOutwardEntry: false,
      showInventoryEntry: false,
      selectedPRId: null,
      showProjectNameDropDown: false,
      oldHeader: null, // To store the old header value
      grn: {
        poId: null,
        awsFileId: null,
        documentType: "Tax Invoice",
        documentNo: "",
        receivedDate: null,
        receiverName: "",
        receiverContactNo: "",
        comments: "",
        fileName: "",
        piId: null,
      },
      lineItems: [],
      PurchaseOrderLineitems: {},
      grnList: [],
      PurchaseOrders: [],
      ApprovePurchaseorders: [],
      PurchaseRequests: [],
      PurchaseInvoice: [],
      CustomerId: null,
      ParentFolderId: "0",
      ParentFolderName: "Root",
      Folders: [],
      attachedFiles: [],
      awsFileId: [],
      BackFolderId: "null",
      HeaderName: [],
      QuotationId: null,
      ContractorId: "",
      ContractorName: "",
      ContractorCategoryName: "",
      Quotations: [],
      ContractorList: true,
      grnListview: true,
      displayedData: [],
      scopeOfWorkData: {},
      SalesOrderArray: [],
      categories: [],
      contractors: [],
      items: [],
      currentTimelineId: null,
      timelineRows: [],
      InwardEntries: [],
      OutwardEntries: [],
      showTabularView: true,
      showGraphicalView: false,
      Id: this.$route.params.Id,
      ProjectName: "",
      ProjectStatus: "",
      activeTab: "scopeOfWork",
      collaborators: [],

      series: [],
      chartOptions: {
        chart: {
          type: "rangeBar",
        },

        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "15px",
          },
        },
        xaxis: {
          type: "datetime",
          position: "top",
          horizontalAlign: "left",
          tickAmount: 6, // Number of ticks for better readability
        },

        stroke: {
          width: 10, // Adjust stroke width
        },
        fill: {
          type: "solid",
          opacity: 0.6,
        },
        legend: {
          position: "top",
          horizontalAlign: "center",
        },
        colors: ["#0000FF", "#008000", "#FF0000"], // Blue, Green, Red
      },
    };
  },
  mounted() {
    this.Id = this.$route.params.Id;
    this.featchProjectProfileData();
    this.fetchProjects();
    this.fetchProductList();
    this.showExecative = this.userRoles.some(
      (role) => role.team === "Onsite" && role.role === "Manager"
    );
    if (this.$route.params.activeTab) {
      this.setActiveTab(this.$route.params.activeTab);
    } else {
      this.setActiveTab("scopeOfWork");
    }
  },

  methods: {
    gotoPRDetails(Id) {
      this.$router.push({
        name: "PurchaseRequestDetails",
        params: { POId: Id, moduleName: "Onsite" },
      });
    },
    OpenProjectActivityModal() {
      this.DesignProjectId = this.Id;
    },
    setProjectId() {
      this.DesignProjectId = null;
      console.log(this.DesignProjectId);
    },
    fetchProductList() {
      axios
        .post(`${this.$apiEndPoint}/productList/All`, {
          searchString: "",
        })
        .then((response) => {
          this.Products = response.data;
        })
        .catch((error) => {
          this.$handleError("Error fetching product list:", error);
        });
    },
    fillProductDetailss(index) {
      const product = this.Products.find(
        (p) => p.productName === this.OutWardEntriesRows[index].productName
      );
      if (product) {
        this.OutWardEntriesRows[index].unitType = product.unitType;
        this.OutWardEntriesRows[index].productId = product.id;
      }
    },
    selectProducts(product, index) {
      this.OutWardEntriesRows[index].productName = product["products.productName"];
      this.OutWardEntriesRows[index].productId = product["products.id"];
      this.OutWardEntriesRows[index].unitType = product["products.unitType"] || "";
      this.OutWardEntriesRows[index].filteredProducts = [];
    },
    searchProducts(searchString, index) {
      if (searchString.length > 0) {
        axios
          .post(`${this.$apiEndPoint}/inwardOutwardEntries/getProducts/${this.StoreId}`, {
            searchString,
          })
          .then((response) => {
            this.OutWardEntriesRows[index].filteredProducts = response.data;
            this.OutWardEntriesRows[index].rowProducts = response.data;
          })
          .catch((error) => {
            this.$handleError("Error searching products:", error);
          });
      } else {
        this.OutWardEntriesRows[index].filteredProducts = [];
      }
    },

    removeRows(index) {
      this.OutWardEntriesRows.splice(index, 1);
    },

    CreateOutWordEntry() {
      this.outwardform.Rows = [...this.OutWardEntriesRows];
      console.log(this.outwardform);
      axios
        .post(`${this.$apiEndPoint}/inwardOutwardEntries`, this.outwardform)
        .then(() => {
          this.$toast("Entry created successfully.", "success");
          this.$emit("fetchOutwardEntries");
          this.resetForm();
        })
        .catch((error) => {
          this.$handleError("Error creating entry:", error);
        });
    },
    resetForm() {
      this.outwardform = {
        date: new Date().toISOString().slice(0, 10),
        fromStore: this.outwardform.fromStore,
        toStore: null,
        status: "Sent",
        Rows: [],
      };
      this.OutWardEntriesRows = [
        {
          productName: "",
          sentQuantity: 0,
          unitType: "",
          productId: null,
          filteredProducts: [],
        },
      ];
    },
    addRows() {
      const lastRow = this.OutWardEntriesRows[this.OutWardEntriesRows.length - 1];
      if (lastRow && lastRow.productName.trim() !== "") {
        this.OutWardEntriesRows.push({
          productName: "",
          sentQuantity: 0,
          unitType: "",
          productId: "",
          filteredProducts: [],
        });
      }
    },
    resetStocks() {
      this.stocks = [
        {
          id: null,
          stockNumber: this.StockNumber,
          productName: "",
          quantity: 0,
          unitType: "",
          projectId: this.CompanyprojectId,
          storeId: this.StoreId,
          date: new Date().toISOString().slice(0, 10),
          filteredProducts: [],
        },
      ];
    },
    resetProductModal() {
      this.SelectedRowId = false;
      this.modalData = {
        productName: "",
        description: "",
        quantity: null,
        receivedQty: null,
        damagedQty: null,
        missingQty: null,
        remark: "",
      };
    },
    CreateInWordEntry() {
      let dataToSend = {
        date: this.Inwardform.date,
        fromStore: this.Inwardform.fromStore,
        toStore: this.Inwardform.toStore,
        status: "Accepted",
        Rows: this.InwardEntriesById.map((item) => ({
          id: item.id,
          productId: item.productId,
          sentQuantity: item.sentQuantity,
          receivedQuantity: item.receivedQuantity,
          damagedQuantity: item.damagedQuantity,
          missingQuantity: item.missingQuantity,
          remark: item.remark,
        })),
      };
      if (this.InwardEntrieStatus === "Sent") {
        axios
          .put(
            `${this.$apiEndPoint}/inwardOutwardEntries/acceptInward/${this.InwardEntryModalId}`,
            dataToSend
          )
          .then(() => {
            this.AllEntryPage = true;
            this.fetchInwardEntries();
            this.Inwardform = {};
          })
          .catch((error) => {
            this.$handleError(error);
          });
      } else {
        axios
          .put(
            `${this.$apiEndPoint}/inwardOutwardEntries/acceptInward/${this.InwardEntryModalId}`,
            dataToSend
          )
          .then(() => {
            this.Inwardform = {};
            this.AllEntryPage = true;
            this.fetchInwardEntries();
          })
          .catch((error) => {
            this.$handleError(error);
          });
      }
    },
    CreateStockConsumption() {
      this.form.stocks = [...this.stocks];
      console.log(this.form);
      axios
        .post(`${this.$apiEndPoint}/stockConsumption`, this.form)
        .then(() => {
          this.$toast("Stock consumption created successfully.", "success");
          this.form.stocks = [];
          this.fetchStockConsumptionEntries();
          this.closeStockEntryPage();
        })
        .catch((error) => {
          this.$handleError("Error creating stock consumption:", error);
        });
    },
    updateStock() {
      console.log(this.stocks);
      axios
        .put(`${this.$apiEndPoint}/stockConsumption`, { stocks: this.stocks })
        .then(() => {
          this.fetchStockConsumptionEntries();
          this.$toast("Stock consumption Updated successfully.", "success");

          this.closeStockEntryPage();
        })
        .catch((error) => {
          this.$handleError("Error updating stock:", error);
        });
    },
    async fetchStockEntriesById(entryId) {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/stockConsumption/${entryId}`
        );
        this.form = response.data;
        this.stocks = response.data.productList.map((item) => ({
          ...item,
          filteredProducts: [],
        }));
      } catch (error) {
        this.$handleError("Error fetching stock entry:", error);
      }
    },
    async removeRow(index) {
      const result = await this.$showAlert(
        "",
        "Are you sure you want to delete this entry?",
        "warning",
        "Yes, delete it",
        "Cancel"
      );

      // If the user clicked Cancel, don't proceed
      if (!result.isConfirmed) return;

      const stockId = this.stocks[index].id;
      this.stocks.splice(index, 1);
      if (stockId) {
        axios
          .delete(`${this.$apiEndPoint}/stockConsumption/deleteLineitem/${stockId}`)
          .then(() => {
            this.$toast("Entry deleted successfully ", "success");
          })
          .catch((error) => {
            this.$handleError("Error deleting line item:", error);
          });
      }
    },
    searchProduct(searchString, index) {
      if (searchString.length > 0) {
        axios
          .post(`${this.$apiEndPoint}/inwardOutwardEntries/getProducts/${this.StoreId}`, {
            searchString,
          })
          .then((response) => {
            this.stocks[index].filteredProducts = response.data;
            this.stocks[index].rowProducts = response.data;
          })
          .catch((error) => {
            this.$handleError("Error searching products:", error);
          });
      } else {
        this.stocks[index].filteredProducts = [];
      }
    },
    fillProductDetails(index) {
      const product = this.stocks[index].filteredProducts.find(
        (p) => p["products.productName"] === this.stocks[index].productName
      );
      if (product) {
        this.stocks[index].unitType = product["products.unitType"];
        this.stocks[index].productId = product["products.id"];
      }
    },
    selectProduct(product, index) {
      this.stocks[index].productName = product["products.productName"];
      this.stocks[index].productId = product["products.id"];
      this.stocks[index].unitType = product["products.unitType"] || "";

      this.stocks[index].filteredProducts = [];
    },
    addRow() {
      const lastRow = this.stocks[this.stocks.length - 1];
      if (lastRow.productName.trim() !== "") {
        this.stocks.push({
          id: null,
          stockNumber: this.StockNumber,
          productName: "",
          quantity: 0,
          unitType: "",
          projectId: this.mainProjectId,
          storeId: this.StoreId,
          date: new Date().toISOString().slice(0, 10),
          filteredProducts: [],
        });
      }
    },
    closeInwardPage() {
      this.InwardEntryPage = false;
      this.AllEntryPage = true;
    },
    closeStockEntryPage() {
      this.StockEntryPage = false;
      this.AllEntryPage = true;
      this.resetStocks();
    },
    closeOutWardEntryPage() {
      this.fetchProjects();
      this.OutWardEntryPage = false;
      this.AllEntryPage = true;
      this.resetForm();
    },
    fetchProjects() {
      axios
        .get(`${this.$apiEndPoint}/store/getStores`)
        .then((response) => {
          this.Projects = response.data;
        })
        .catch((error) => {
          this.$handleError("Error fetching projects:", error);
        });
    },
    openStokEntryPage(Id) {
      this.StockEntryPage = true;
      this.AllEntryPage = false;
      this.StockNumber = Id;
      if (this.StockNumber) {
        this.fetchStockEntriesById(this.StockNumber);
      }
    },
    openOutWardEntryPage() {
      this.OutWardEntryPage = true;
      this.AllEntryPage = false;
    },
    openInwordEntryPageModal(row) {
      this.InwardEntryModalId = row.id;
      this.InwardEntryPage = true;
      this.AllEntryPage = false;
      console.log(row);
      this.featchEntriesById(row.id);
    },
    async featchEntriesById(InwardEntryId) {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/inwardOutwardEntries/${InwardEntryId}`
        );
        this.Inwardform = response.data;
        this.InwardEntrieStatus = response.data.status;
        this.InwardEntriesById = response.data.Lineitems;
      } catch (error) {
        this.$handleError(error);
      }
    },
    editRow(index) {
      this.SelectedRowIndex = index;
      this.SelectedRowId = true;
      console.log(this.lineItems[index]);
      console.log(this.modalData);
      this.modalData.productName = this.lineItems[index]["productLists.productName"];
      this.modalData.description = this.lineItems[index]["productLists.description"];
      this.modalData.quantity = this.lineItems[index].quantity;
      this.modalData.receivedQty = this.lineItems[index].receivedQty;
      this.modalData.damagedQty = this.lineItems[index].damagedQty;
      this.modalData.missingQty = this.lineItems[index].missingQty;
      this.modalData.remark = this.lineItems[index].remark;
    },
    UpdateModalProduct() {
      this.lineItems[this.SelectedRowIndex][
        "productLists.productName"
      ] = this.modalData.productName;
      this.lineItems[this.SelectedRowIndex][
        "productLists.description"
      ] = this.modalData.description;
      this.lineItems[this.SelectedRowIndex].quantity = this.modalData.quantity;
      this.lineItems[this.SelectedRowIndex].receivedQty = this.modalData.receivedQty;
      this.lineItems[this.SelectedRowIndex].damagedQty = this.modalData.damagedQty;
      this.lineItems[this.SelectedRowIndex].missingQty = this.modalData.missingQty;
      this.lineItems[this.SelectedRowIndex].remark = this.modalData.remark;
      this.$toast("Row Updated Successfully", "success");
      this.resetProductModal();
    },
    openScopeOfWorkModal(Id) {
      this.timePage = false;
      this.ScopeOfWorkRowId = null;
      this.ScopeOfWorkParentId = Id;
      console.log(this.ScopeOfWorkRowId);
      console.log(this.ScopeOfWorkParentId);
    },
    EditchildScopeOfWorkModal(parentId, ChildId) {
      this.timePage = false;
      this.ScopeOfWorkRowId = ChildId;
      this.ScopeOfWorkParentId = parentId;
    },
    EditTimeScopeOfWorkModal(Id) {
      this.timePage = true;
      this.ScopeOfWorkRowId = Id;
      console.log(this.timePage);
      console.log(this.ScopeOfWorkRowId);
      console.log(this.ScopeOfWorkParentId);
    },
    EditScopeOfWorkModal(Id) {
      this.timePage = false;
      this.ScopeOfWorkRowId = Id;
      console.log(this.ScopeOfWorkRowId);
      console.log(this.ScopeOfWorkParentId);
    },
    showTimeLineDetails(rowData) {
      this.details = [
        { label: "Activity Name", value: rowData.activity },
        { label: "Assignee", value: rowData.assignee },
        { label: "Planned Start", value: this.$formatDateDMY(rowData.plannedStartDate) },
        { label: "Planned End ", value: this.$formatDateDMY(rowData.plannedEndDate) },
        { label: "Actual Start", value: this.$formatDateDMY(rowData.actualStartDate) },
        { label: "Actual End ", value: this.$formatDateDMY(rowData.actualEndDate) },

        // Add more fields as necessary
      ];
    },
    showTimeLineDetailss(rowData) {
      this.details = [
        { label: "Activity Name", value: rowData.activity },
        { label: "Assignee", value: rowData["employees.firstName"] },
        { label: "Planned Start", value: this.$formatDateDMY(rowData.plannedStartDate) },
        { label: "Planned End ", value: this.$formatDateDMY(rowData.plannedEndDate) },
        { label: "Actual Start", value: this.$formatDateDMY(rowData.actualStartDate) },
        { label: "Actual End ", value: this.$formatDateDMY(rowData.actualEndDate) },

        // Add more fields as necessary
      ];
    },
    showStockDetails(rowData) {
      // Map row data to label-value pairs
      this.details = [
        { label: "Date", value: this.$formatDateDMY(rowData.date) },
        { label: "Voucher Number", value: rowData.stockNumber },

        // Add more fields as necessary
      ];
    },
    showPRDetails(rowData) {
      // Map row data to label-value pairs
      this.details = [
        { label: "PR Number", value: rowData.prNumber },
        { label: "Date", value: rowData.date },
        { label: "Project Name", value: rowData["projects.projectName"] },
        { label: "Requested By", value: rowData["employees.firstName"] },
        { label: "Department", value: rowData.department },
        { label: "Status", value: rowData.prStatus },

        // Add more fields as necessary
      ];
    },
    showQuotationDetails(rowData) {
      // Initialize the details array with always-present fields
      this.details = [
        { label: "Date", value: this.$formatDateDMY(rowData.date) },
        { label: "Project Name", value: this.ProjectName },
        { label: "Contractor Name", value: rowData["onSiteContractors.name"] },
        { label: "Category", value: rowData["onSiteCategory.categoryName"] },
        { label: "Created By", value: rowData["employees.firstName"] },
        {
          label: "Quotation",
          value: this.$formatToIndianCurrency(rowData.quotationValue),
        },
        { label: "Status", value: rowData.status },
        { label: "Remark", value: rowData.remark },
      ];

      // Conditionally add the "Cash In" field
    },
    showDetails(rowData) {
      // Initialize the details array with data derived from the row
      this.details = [
        { label: "Name", value: rowData.folderName || rowData["awsFiles.fileName"] },
        {
          label: "Created By",
          value: rowData["employees.firstName"] + " " + rowData["employees.lastName"],
        },
        {
          label: "Type",
          value: rowData.folderName
            ? rowData.contentType
            : rowData["awsFiles.contentType"],
        },
        { label: "Last Modified", value: rowData.updated_at },
        // { label: "File Size", value: "-" }, // Add logic for size if available
      ];
    },
    showInwardDetails(rowData) {
      // Initialize the details array with data derived from the row
      this.details = [
        { label: "Date", value: this.$formatDateDMY(rowData.date) },
        { label: "From Store", value: rowData["fromStores.storeName"] },
        { label: "Voucher Number", value: rowData.voucherNo },
        { label: "Status", value: rowData.status },
      ];
    },

    showOutwardDetails(rowData) {
      this.details = [
        { label: "Date", value: this.$formatDateDMY(rowData.date) },
        { label: "To Store", value: rowData["toStores.storeName"] },
        { label: "Voucher Number", value: rowData.voucherNo },
        { label: "Status", value: rowData.status },
      ];
    },
    showLedgerDetails(rowData) {
      this.details = [
        { label: "Product", value: rowData["products.productName"] },
        { label: "Unit Type", value: rowData["products.unitType"] },
        { label: "Inward Qty", value: rowData.inwardQuantity },
        { label: "Stock Consumption", value: rowData.stockConsumption },
        { label: "Outward Qty", value: rowData.outwardQuantity },
        { label: "Available Qty", value: rowData.availableQuantity },
      ];
    },
    showPODetails(rowData) {
      this.details = [
        { label: "PO Number", value: rowData.poNumber },
        { label: "Date", value: this.formatDate(rowData.date) },
        { label: "Vendor Name", value: rowData["vendors.companyName"] },
        { label: "Status", value: rowData.poStatus },
      ];

      if (rowData["purchaseRequests.prNumber"]) {
        this.details.push({
          label: "Reference",
          value: rowData["purchaseRequests.prNumber"],
        });
      } else {
        this.details.push({ label: "Reference", value: "Direct PO" });
      }
    },
    showGRNDetails(rowData) {
      this.details = [
        { label: "GRN No", value: rowData.grnNumber },
        { label: "Received Date", value: this.formatDate(rowData.receivedDate) },
        { label: "Vendor Name", value: rowData["purchaseOrders.vendors.companyName"] },
        { label: "Reference", value: rowData["purchaseOrders.poNumber"] },
        { label: "Document Type", value: rowData.documentType },
        { label: "Document No", value: rowData.documentNo },
      ];
    },
    getStatusClass(status) {
      switch (status) {
        case "Delivered":
          return "deliveredText";
        case "Semi Ordered":
          return "semi-deliveredText";
        case "Ordered":
          return "orderedText";
        case "Rejected":
          return "rejectedText";
        case "Pending":
          return "pendingText";
        case "Approved":
          return "approvedText";
        default:
          return "";
      }
    },
    async convertToScopeOfWork(orderId) {
      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/onSiteScopeOfWork/createSow/${orderId}`
        );

        if (response.data) {
          this.fetchscopeOfWork();
          this.$toast("Scope of work successfully", "success");
        }
      } catch (error) {
        // Handle error response
        this.$handleError(error);
      }
    },
    async rejectDocument() {
      try {
        const response = await axios.put(
          `${this.$apiEndPoint}/OnSiteQuotation/${this.selectedQuotationId}`,
          {
            status: "Rejected",
          }
        );

        if (response.status === 200) {
          this.fetchQuotationByStatus(this.currentTable);
          this.$toast("Document Rejected successfully", "success");
        }
        this.showRejectionModal = false;
      } catch (error) {
        // Handle error response
        this.$handleError(error);
      }
      // Handle rejection logic here, including sending rejection remarks if needed
    },
    async approveDocument() {
      try {
        const response = await axios.put(
          `${this.$apiEndPoint}/OnSiteQuotation/${this.selectedQuotationId}`,
          {
            status: "approved",
          }
        );

        if (response.status === 200) {
          this.fetchQuotationByStatus(this.currentTable);

          this.$toast("Document approved successfully", "success");
        }
        this.showModal = false;
      } catch (error) {
        // Handle error response
        this.$handleError(error);
      }
    },

    openApprovalModal(Id) {
      this.selectedQuotationId = Id;
      this.showModal = true;
    },
    openRejectModal(Id) {
      this.selectedQuotationId = Id;
      this.showRejectionModal = true;
    },
    goToPurchaseCreate(moduleName) {
      this.$router.push({
        name: "PurchaseRequestDetails",
        params: {
          moduleName: moduleName,
          ProjectId: this.mainProjectId,
          Projectname: this.ProjectName,
          Id: this.Id,
        },
      });
    },
    async GenerateTimeLine() {
      // Use SweetAlert for confirmation
      const result = await this.$showAlert(
        "",
        "Are you sure you want to generate timeline?",
        "warning",
        "Yes, generate it",
        "Cancel"
      );

      // If the user clicked Cancel, don't proceed
      if (!result.isConfirmed) {
        this.$toast("Timeline generation canceled.", "info");
        return;
      }

      // If the user confirmed, proceed with the API call
      try {
        await axios.post(`${this.$apiEndPoint}/onSiteScopeOfWork/${this.mainProjectId}`);
        this.$toast("Timeline generated successfully.", "success");
      } catch (error) {
        this.$handleError(error);
      }
    },

    async featchSalesOrder() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/salesOrder/soForSow/${this.mainProjectId}`
        );
        this.SalesOrderArray = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchscopeOfWork() {
      this.ScopeOfWorkRowId = null;
      this.ScopeOfWorkParentId = null;
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/onSiteScopeOfWork/${this.mainProjectId}`
        );
        this.scopeOfWorkData = response.data;
        if (this.scopeOfWorkData) {
          this.items = this.scopeOfWorkData.map((item) => ({
            header: item.header,
            rows: item.Rows.map((row) => ({
              ...row,
              lineitems: row.lineitems || [],
            })),
          }));
        }
      } catch (error) {
        this.$handleError(error);
      }
    },
    validateAndAddChildRow(item, row) {
      if (this.isActivityEmpty(row)) return;

      if (this.hasIncompleteLineItems(row)) return;

      this.addNewChildRow(row);
    },

    isActivityEmpty(row) {
      if (!row.activity) {
        alert("Please fill in the work activity before adding a new row.");
        this.focusActivityInput(row.id);
        return true;
      }
      return false;
    },

    hasIncompleteLineItems(row) {
      if (row.lineitems && row.lineitems.length > 0) {
        const incompleteIndex = row.lineitems.findIndex((item) => !item.activity);
        if (incompleteIndex !== -1) {
          this.focusIncompleteLineItem(row.id, incompleteIndex);
          return true;
        }
      }
      return false;
    },

    focusActivityInput(rowId) {
      this.$nextTick(() => {
        const input = this.$refs[`activityInput_${rowId}`];
        if (input && input.length > 0) {
          input[0].focus();
        }
      });
    },

    focusIncompleteLineItem(rowId, index) {
      this.$nextTick(() => {
        const refName = `activityInput_${rowId}_${index}`;
        const firstIncompleteInput = this.$refs[refName];
        if (firstIncompleteInput && firstIncompleteInput.length > 0) {
          firstIncompleteInput[0].focus();
        }
      });
    },
    CreatewithHeader(HeaderName) {
      this.scopeOfWorkHeadername = HeaderName;
      console.log(HeaderName);
    },
    addNewChildRow(row) {
      row.lineitems.push({
        parentId: row.id,
        activity: null,
        plannedStartDate: null,
        plannedEndDate: null,
        assignTo: null,
        plannedDuration: null,
      });
    },

    async deleteRow(item, row, rowIndex) {
      const result = await this.$showAlert(
        "",
        "Are you sure you want to delete this activity?",
        "warning",
        "Yes, delete it",
        "Cancel"
      );

      // If the user clicked Cancel, don't proceed
      if (!result.isConfirmed) return;
      const rows = item.rows;

      // Remove the row from the array
      rows.splice(rowIndex, 1);

      try {
        await axios.delete(`${this.$apiEndPoint}/onSiteScopeOfWork/${row.id}`);
        this.fetchscopeOfWork(); // Refresh data after deletion
      } catch (error) {
        this.$handleError(error);
      }
    },

    async deleteChildRow(row, child, childIndex) {
      const result = await this.$showAlert(
        "",
        "Are you sure you want to delete this activity?",
        "warning",
        "Yes, delete it",
        "Cancel"
      );

      // If the user clicked Cancel, don't proceed
      if (!result.isConfirmed) return;
      row.lineitems.splice(childIndex, 1);

      try {
        await axios.delete(`${this.$apiEndPoint}/onSiteScopeOfWork/${child.id}`);
        this.fetchscopeOfWork(); // Refresh data after deletion
      } catch (error) {
        this.$handleError(error);
      }
    },

    async createScopeOfWork(header, rowData, ParentRowId) {
      if (!rowData.activity) {
        return;
      }

      const payload = {
        header: header,
        Rows: [
          {
            parentId: ParentRowId,
            projectId: this.mainProjectId,
            activity: rowData.activity,
            plannedStartDate: rowData.plannedStartDate,
            plannedEndDate: rowData.plannedEndDate,
            assignTo: rowData.assignTo,
            plannedDuration: rowData.plannedDuration,
          },
        ],
      };

      if (rowData.id) {
        const Updatepayload = {
          header: header,
          onSiteProjectId: this.Id,
          categoryContractorId: rowData.onSiteContractorId,
          activity: rowData.activity,
          plannedStartDate: rowData.plannedStartDate,
          plannedEndDate: rowData.plannedEndDate,
          assignTo: rowData.assignTo,
          plannedDuration: rowData.plannedDuration,
        };
        try {
          await axios.put(
            `${this.$apiEndPoint}/onSiteScopeOfWork/${rowData.id}`,
            Updatepayload
          );
          this.fetchscopeOfWork();
          this.$toast("Row Updated Successfully ", "success");
        } catch (error) {
          this.$handleError(error);
        }
      } else {
        try {
          await axios.post(`${this.$apiEndPoint}/onSiteScopeOfWork/`, payload);
          this.fetchscopeOfWork();
        } catch (error) {
          this.$handleError(error);
        }
      }
    },

    getEmployeeName(employeeId) {
      const employee = this.Employees.find((emp) => emp.employeeId === employeeId);
      return employee ? employee["employees.signedUrl"] : "";
    },

    openPOModal(Id) {
      this.selectedPoId = Id;
    },
    openGRNModal(Id) {
      this.selectedGrnId = Id;
    },

    handleDownloadGRN(id) {
      this.selectedGrnId = id;
      // Ensure the modal is fully loaded before triggering download
      setTimeout(() => {
        this.$refs.OnSiteGrnDetailsModal.downloadPDF();
      }, 1000); // Adjust the timeout as needed
    },

    handleDownloadInwardEntry(id) {
      this.InwardEntryModalId = id;
      // Ensure the modal is fully loaded before triggering download
      setTimeout(() => {
        this.$refs.InwardEntriesDetailsModal.downloadPDF();
      }, 1000); // Adjust the timeout as needed
    },

    handleDownloadPO(id) {
      this.selectedPoId = id;
      // Ensure the modal is fully loaded before triggering download
      setTimeout(() => {
        this.$refs.PODetailsModal.downloadPDF();
      }, 1000); // Adjust the timeout as needed
    },

    openStockConsumptionModal(stockNumberId) {
      this.stockNumber = stockNumberId;
    },
    openOutwardEntriesModal(outwardEntryId) {
      this.OutwardEntryId = outwardEntryId;
    },
    handleDownloadOutwardEntries(id) {
      this.OutwardEntryId = id;
      // Ensure the modal is fully loaded before triggering download
      setTimeout(() => {
        this.$refs.OutwardEntriesDetailsModal.downloadPDF();
      }, 1000); // Adjust the timeout as needed
    },

    handleDownloadStockConsumption(id) {
      this.stockNumber = id;
      // Ensure the modal is fully loaded before triggering download
      setTimeout(() => {
        this.$refs.StockConsumptionDetailsModal.downloadPDF();
      }, 1000); // Adjust the timeout as needed
    },
    openInwordEntryModal(InwardEntryId) {
      this.InwardEntryModalId = InwardEntryId;
    },

    async fetchStoreId(ProjectId) {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/store/${ProjectId}/Project`
        );
        this.StoreId = response.data.id;
        this.stocks.forEach((stock) => (stock.storeId = this.StoreId));
        this.outwardform.fromStore = this.StoreId;
      } catch (error) {
        this.$handleError(error);
      }
    },
    handleDownload(id) {
      this.selectedPRId = id;
      // Ensure the modal is fully loaded before triggering download
      setTimeout(() => {
        this.$refs.PRDetailsModal.downloadPDF();
      }, 1000); // Adjust the timeout as needed
    },
    openModalPI(PIId) {
      this.selectedPIId = PIId;
    },
    openModal(TargetId) {
      this.selectedPRId = TargetId;
    },
    async ChangeProjectStatus(Status) {
      const payload = {
        status: Status,
      };
      try {
        await axios.put(
          `${this.$apiEndPoint}/onSiteProject/updateProject/${this.Id}`,
          payload
        );
        this.featchProjectProfileData();
      } catch (error) {
        this.$handleError(error);
      }
    },
    toggleProjectNameDropDown() {
      this.showProjectNameDropDown = !this.showProjectNameDropDown;
    },
    async handleMobileFileUploadGrnFile() {
      this.$globalState.isLoading = true;
      const file = document.getElementById("onSiteGrnn").files[0]; // Get the selected file
      console.log(file);
      if (file) {
        this.grn.awsFileId = null;
        let fileCategory;

        // Ensure this.grn.documentType is properly defined and compared
        if (this.grn.documentType === "Tax Invoice") {
          fileCategory = "purchaseInvoice";
        } else {
          fileCategory = "deliveryChallen";
        }

        try {
          // Make API call to get signed URL for file upload
          const response = await axios.post(
            `${this.$apiEndPoint}/company/putFileSignedURL`,
            {
              fileName: file.name,
              fileSize: file.size,
              contentType: file.type,
              fileCategory: fileCategory,
              moduleName: "procurement",
            }
          );

          const signedUrl = response.data.s3URL;
          this.grn.awsFileId = response.data.id;
          const xhr = new XMLHttpRequest();
          xhr.open("PUT", signedUrl, true);
          xhr.setRequestHeader("Content-Type", file.type);

          xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
              if (xhr.status === 200) {
                this.$globalState.isLoading = false;
                this.$toast("File uploaded successfully", "success");
                // Handle successful upload
              } else {
                this.$handleError("Error uploading file to S3:", xhr.responseText);
                this.$globalState.isLoading = false;
                // Handle upload error
              }
            }
          };

          // Send the file as binary data
          xhr.send(file);
        } catch (error) {
          this.$handleError(error);
        }
      }
      this.$globalState.isLoading = false;
    },
    async handleFileUploadGrnFile() {
      this.$globalState.isLoading = true;
      const file = document.getElementById("onSiteGrn").files[0]; // Get the selected file
      console.log(file);
      if (file) {
        this.grn.awsFileId = null;
        let fileCategory;

        // Ensure this.grn.documentType is properly defined and compared
        if (this.grn.documentType === "Tax Invoice") {
          fileCategory = "purchaseInvoice";
        } else {
          fileCategory = "deliveryChallen";
        }

        try {
          // Make API call to get signed URL for file upload
          const response = await axios.post(
            `${this.$apiEndPoint}/company/putFileSignedURL`,
            {
              fileName: file.name,
              fileSize: file.size,
              contentType: file.type,
              fileCategory: fileCategory,
              moduleName: "procurement",
            }
          );

          const signedUrl = response.data.s3URL;
          this.grn.awsFileId = response.data.id;
          const xhr = new XMLHttpRequest();
          xhr.open("PUT", signedUrl, true);
          xhr.setRequestHeader("Content-Type", file.type);

          xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
              if (xhr.status === 200) {
                this.$globalState.isLoading = false;
                this.$toast("File uploaded successfully", "success");
                // Handle successful upload
              } else {
                this.$handleError("Error uploading file to S3:", xhr.responseText);
                this.$globalState.isLoading = false;
                // Handle upload error
              }
            }
          };

          // Send the file as binary data
          xhr.send(file);
        } catch (error) {
          this.$handleError(error);
        }
      }
      this.$globalState.isLoading = false;
    },

    updateGrnDraft() {
      const btn = document.querySelector(".confirmationButton");
      btn.classList.add("confirmationButton--loading");
      // Construct the data object to send
      let dataToSend = {
        projectId: this.mainProjectId || " ",
        onSiteProjectId: this.Id,
        poId: this.grn.poId,
        awsFileId: this.grn.awsFileId,
        documentType: this.grn.documentType,
        documentNo: this.grn.documentNo,
        receivedDate: this.grn.receivedDate,
        receiverName: this.grn.receiverName,
        receiverContactNo: this.grn.receiverContactNo,
        comments: this.grn.comments,
        amount: this.grn.amount,
        date: this.grn.receivedDate,
        piId: this.grn.piId,
        lineItems: this.lineItems.map((item) => ({
          id: item.id,
          productId: item.productId,
          orderQuantity: item.quantity,
          receivedQuantity: item.receivedQty,
          damagedQuantity: item.damagedQty,
          missingQuantity: item.missingQty,
          remark: item.remark,
        })),
      };
      console.log(dataToSend);

      axios
        .put(`${this.$apiEndPoint}/OnSiteGrn/${this.selectedGrnId}`, dataToSend)
        .then(() => {
          btn.classList.remove("confirmationButton--loading");
          this.$toast(` GRN Update successfully.`, "success");
          this.opengrnList(null);
          this.grn = {};
          this.lineItems = [];

          // Handle success
        })
        .catch((error) => {
          btn.classList.remove("confirmationButton--loading");
          this.$handleError(error);
        });
    },

    saveAsGrnDraft() {
      const btn = document.querySelector(".confirmationButton");
      btn.classList.add("confirmationButton--loading");
      // Construct the data object to send
      let dataToSend = {
        onSiteProjectId: this.Id,
        awsFileId: this.grn.awsFileId || null,
        projectId: this.mainProjectId || " ",
        poId: this.grn.poId || null,
        documentType: this.grn.documentType,
        documentNo: this.grn.documentNo || " ",
        receivedDate: this.grn.receivedDate || null,
        receiverName: this.grn.receiverName || " ",
        receiverContactNo: this.grn.receiverContactNo || " ",
        comments: this.grn.comments || "",
        amount: this.grn.amount,
        date: this.grn.receivedDate,
        piId: this.grn.piId,
        lineItems: this.lineItems.map((item) => ({
          productId: item.productId,
          orderQuantity: item.quantity || 0,
          receivedQuantity: item.receivedQty || 0,
          damagedQuantity: item.damagedQty || 0,
          missingQuantity: item.missingQty || 0,
          remark: item.remark || "",
        })),
      };
      console.log(dataToSend);
      axios
        .post(`${this.$apiEndPoint}/OnSiteGrn`, dataToSend)
        .then(() => {
          btn.classList.remove("confirmationButton--loading");
          this.$toast(`GRN created successfully.`, "success");
          this.opengrnList(null);
          this.grn = {};
          this.lineItems = [];
        })
        .catch((error) => {
          btn.classList.remove("confirmationButton--loading");
          this.$handleError(error);
        });
    },

    updateQuantities(index) {
      if (this.lineItems[index].checked) {
        this.lineItems[index].receivedQty = this.lineItems[index].quantity;
        this.lineItems[index].damagedQty = 0;
        this.lineItems[index].missingQty = 0;
        this.lineItems[index].remark = "";
      } else {
        // Ensure receivedQty does not exceed order quantity
        if (this.lineItems[index].receivedQty > this.lineItems[index].quantity) {
          this.lineItems[index].receivedQty = this.lineItems[index].quantity;
        }

        // Ensure the sum of received, damaged, and missing quantities equals the order quantity
        let totalQty = this.lineItems[index].quantity;
        let currentTotal =
          this.lineItems[index].receivedQty +
          this.lineItems[index].damagedQty +
          this.lineItems[index].missingQty;

        if (currentTotal > totalQty) {
          // Adjust damagedQty and missingQty if the current total exceeds the order quantity
          this.lineItems[index].damagedQty = 0;
          this.lineItems[index].missingQty = 0;
          currentTotal = this.lineItems[index].receivedQty;

          if (currentTotal > totalQty) {
            this.lineItems[index].receivedQty = totalQty;
          }
        }

        if (currentTotal < totalQty) {
          // Distribute the remaining quantity to receivedQty
          this.lineItems[index].receivedQty =
            totalQty -
            this.lineItems[index].damagedQty -
            this.lineItems[index].missingQty;
        }
      }
    },
    validateInput(index, field) {
      let item = this.lineItems[index];
      let totalQty = item.quantity;
      let receivedQty = item.receivedQty;
      let damagedQty = item.damagedQty;
      let missingQty = item.missingQty;

      // Ensure receivedQty does not exceed order quantity
      if (field === "receivedQty" && receivedQty > totalQty) {
        item.receivedQty = totalQty;
        receivedQty = totalQty;
      }

      // Ensure the sum of received, damaged, and missing quantities does not exceed the order quantity
      if (receivedQty + damagedQty + missingQty > totalQty) {
        let excess = receivedQty + damagedQty + missingQty - totalQty;

        if (field === "receivedQty") {
          let adjustment = Math.min(excess, item.receivedQty);
          item.receivedQty -= adjustment;
          excess -= adjustment;
        }
        if (field === "damagedQty" && excess > 0) {
          let adjustment = Math.min(excess, item.damagedQty);
          item.damagedQty -= adjustment;
          excess -= adjustment;
        }
        if (field === "missingQty" && excess > 0) {
          let adjustment = Math.min(excess, item.missingQty);
          item.missingQty -= adjustment;
          // excess -= adjustment; // No need for a further adjustment since it's no longer used
        }
      }
    },
    showReferenceDetails() {
      const PoId = this.ApprovePurchaseorders.find(
        (purchaseOrder) => purchaseOrder.id === this.grn.poId
      );
      this.featchPurchaseOrderById(PoId.id);
      if (PoId) {
        this.grn.POdate = PoId.date;
        this.grn.vendorName = PoId["vendors.companyName"];
        this.grn.vendorContactNo = PoId["vendors.primaryContactNo"];
      } else {
        this.grn.POdate = PoId.statusDate;
        this.grn.vendorName = PoId.vendorId;
        this.grn.vendorContactNo = PoId.vendorId;
      }
    },
    async featchPurchaseOrderById(PoId) {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/purchaseOrder/${PoId}`);
        this.lineItems = response.data.Lineitems;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async goToParentFolder(FolderId) {
      if (FolderId === "0") {
        // Changed to string comparison
        this.HeaderName = [];
        this.ParentFolderId = FolderId;

        this.fetchFolderOrFiles();
      } else if (FolderId === "null" && this.HeaderName.length > 1) {
        // Changed to string comparison
        const FolderIds = this.HeaderName[this.HeaderName.length - 2].id;
        this.HeaderName.splice(-1, 1);
        this.ParentFolderId = FolderIds;

        this.fetchFolderOrFiles();
      } else {
        this.HeaderName = [];
        this.FolderId = 0;
        this.ParentFolderId = FolderId;
        this.fetchFolderOrFiles();
      }
    },

    goToFolder(FolderId, Folder) {
      this.Folders = [];
      this.HeaderName.push(Folder);
      this.ParentFolderId = FolderId;
      this.ParentFolderName = Folder.folderName;

      this.fetchFolderOrFiles();
    },
    goToHeaderFolder(FolderId, Folder) {
      // Check if the folder is already present in HeaderName
      const folderIndex = this.HeaderName.indexOf(Folder);

      if (folderIndex === -1) {
        // Folder not present, add to the end
        this.HeaderName.push(Folder);
      } else {
        // Folder is present, remove subsequent folders
        this.HeaderName = this.HeaderName.slice(0, folderIndex + 1);
      }

      // Update the ParentFolderId
      this.ParentFolderId = FolderId;

      // Fetch the contents of the folder
      this.fetchFolderOrFiles();
    },

    async fetchFolderOrFiles() {
      if (this.ParentFolderName === "Sign Off Document") {
        try {
          const response = await axios.get(`${this.$apiEndPoint}/onSiteFiles/${this.Id}`);
          this.Folders = response.data;
        } catch (error) {
          this.$handleError(error);
        }
      } else {
        try {
          const response = await axios.get(
            `${this.$apiEndPoint}/folder/onSite/${this.Id}/onSiteProject/${this.ParentFolderId}`
          );
          this.Folders = response.data;
        } catch (error) {
          this.$handleError(error);
        }
      }
    },
    createFolder() {
      const newFolder = {
        isNew: true,
        folderName: "",
        createdBy: "Current User",
        lastModified: new Date().toLocaleDateString(),
      };
      this.Folders.push(newFolder);
    },
    async deleteFolder(Folder, index) {
      let folderOrFileName =
        Folder.folderName || Folder["awsFiles.fileName"] || "this item";

      const result = await this.$showAlert(
        "",
        `Do you want to delete this folder: <strong>${folderOrFileName}</strong> ?`,
        "warning",
        "Yes, delete it",
        "Cancel"
      );

      // If the user clicked Cancel, don't proceed
      if (!result.isConfirmed) return;
      this.Folders.splice(index, 1);

      try {
        const apiUrl = Folder.folderName
          ? `${this.$apiEndPoint}/folder/deleteFolder`
          : `${this.$apiEndPoint}/files/deleteFolder`;

        await axios.delete(apiUrl, {
          data: { id: [Folder.id] },
        });

        this.fetchFolderOrFiles();
      } catch (error) {
        this.$handleError(error);
      }
    },

    Rename(folder) {
      folder.isNew = true;
    },

    async saveFolder(folder, index) {
      if (folder.folderName.trim() === "") {
        alert("Folder name cannot be empty");
        return;
      }

      try {
        if (!folder.id) {
          await axios.post(`${this.$apiEndPoint}/folder/`, {
            parentFolderId: this.ParentFolderId,
            folderName: folder.folderName,
            moduleName: "onSite",
            entityId: this.Id,
            entityName: "onSiteProject",
          });

          this.fetchFolderOrFiles();
          this.Folders[index] = { ...folder, isNew: false }; // ✅ Vue 3 way
        } else {
          await axios.put(`${this.$apiEndPoint}/folder/${folder.id}`, {
            folderName: folder.folderName,
          });

          this.fetchFolderOrFiles();
        }
      } catch (error) {
        this.$handleError(error);
      }
    },

    handleFileUpload(event) {
      const files = event.target.files;
      this.$globalState.isLoading = true;
      let fileCategory = "files"; // Default file category

      this.uploadedFileIds = []; // Clear or initialize the file IDs array

      const uploadPromises = []; // Array to hold promises for each file upload

      for (const file of files) {
        const payload = {
          fileName: file.name,
          fileSize: file.size,
          contentType: file.type,
          moduleName: "onSite",
          fileCategory: fileCategory,
        };

        const uploadPromise = axios
          .post(`${this.$apiEndPoint}/company/putFileSignedURL`, payload)
          .then((response) => {
            const signedUrl = response.data.s3URL;

            const fileId = response.data.id; // Get the file ID from the response

            // Use XMLHttpRequest to upload the file to the signed URL
            return new Promise((resolve, reject) => {
              const xhr = new XMLHttpRequest();
              xhr.open("PUT", signedUrl, true);
              xhr.setRequestHeader("Content-Type", file.type);

              xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                  if (xhr.status === 200) {
                    this.$toast("File uploaded successfully", "success");
                    this.$globalState.isLoading = false;
                    // Collect the file ID in the uploadedFileIds array
                    this.uploadedFileIds.push(fileId);
                    resolve(fileId); // Resolve the promise with fileId
                  } else {
                    this.$toast(`Error uploading file: ${xhr.statusText}`, "error");
                    this.$globalState.isLoading = false;
                    reject(new Error(`Error uploading file: ${xhr.statusText}`)); // Reject on error
                  }
                }
              };
              xhr.send(file); // Send the file to the signed URL
            });
          })
          .catch((error) => {
            this.$handleError(error);
          });

        uploadPromises.push(uploadPromise); // Push the file upload promise into the array
      }

      // Once all file uploads are completed, proceed to upload the files to the folder
      Promise.all(uploadPromises)
        .then(() => {
          this.UploadFilesToFolder(); // Call the UploadFilesToFolder method
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    async UploadFilesToFolder() {
      try {
        const requestBody = {
          folderId: this.ParentFolderId, // Folder ID
          moduleName: "onSite", // Module name
          entityId: this.Id, // Entity ID
          entityName: "onSiteProject", // Entity name
          awsFileId: this.uploadedFileIds, // Pass the array of file IDs
        };
        await axios.post(`${this.$apiEndPoint}/files`, requestBody);
        this.fetchFolderOrFiles();

        this.uploadedFileIds = []; // Clear uploadedFileIds array after upload
      } catch (error) {
        this.$handleError(error);
      }
    },

    openAddQuotationModal(QuotationIds) {
      this.QuotationId = QuotationIds;
    },
    async opengrnList(Id) {
      this.grn = {};
      this.lineItems = [];

      this.selectedGrnId = Id;
      if (Id === "null") {
        console.log("create New one");
      } else {
        this.featchGrnById(Id);
      }
      this.grnListview = !this.grnListview;
      this.fetchgrn();
    },
    async featchGrnById(Id) {
      if (Id != null) {
        try {
          const response = await axios.get(
            `${this.$apiEndPoint}/OnSiteGrn/getById/${Id}`
          );
          this.grn = response.data;

          this.grn.fileName = response.data["awsFiles.fileName"];
          this.grn.POdate = response.data["purchaseOrders.date"];
          this.grn.vendorName = response.data["purchaseOrders.vendors.companyName"];
          this.grn.vendorContactNo =
            response.data["purchaseOrders.vendors.primaryContactNo"];
          // Adding virtual properties to each line item
          this.lineItems = response.data.lineItems.map((item) => ({
            ...item,
            quantity: item.orderQuantity, // Virtual property for order quantity
            receivedQty: item.receivedQuantity, // Virtual property for received quantity
            damagedQty: item.damagedQuantity, // Virtual property for damaged quantity
            missingQty: item.missingQuantity, // Virtual property for missing quantity
            remark: item.remark, // Virtual property for remark
          }));

          if (response.data.documentType === "Tax Invoice") {
            this.grn.date = response.data["purchaseInvoices.date"];
            this.grn.amount = response.data["purchaseInvoices.amount"];
            this.grn.piId = response.data.piId;
          }
        } catch (error) {
          this.$handleError(error);
        }
      }
    },

    async fetchgrn() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/OnSiteGrn/${this.Id}`);
        this.grnList = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async openQuationList(ContractorId) {
      this.ContractorName = "";
      this.ContractorCategoryName = "";
      this.ContractorList = !this.ContractorList;

      if (ContractorId !== null) {
        const selectedContractor = this.displayedData.find(
          (contractor) => contractor.onSiteContractorId === ContractorId
        );
        if (selectedContractor) {
          this.ContractorId = selectedContractor.onSiteContractorId;
          this.ContractorName = selectedContractor["onSiteContractor.name"];
          this.ContractorCategoryName = selectedContractor["onSiteCategory.categoryName"];
        } else {
          this.ContractorNumber = "";
        }
        this.featchQuotations(ContractorId);
      }
    },
    async featchQuotations(ContractorId) {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/onSiteQuotation/byProjectId/${this.Id}/${ContractorId}`
        );
        this.Quotations = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchdisplayedData() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/onSiteCategoryContractor/${this.Id}`
        );
        this.displayedData = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },

    async fetchContractor(categoryId) {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/onSiteCategoryContractor/${this.Id}/${categoryId}`
        );
        this.contractors = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchCategories() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/onSiteCategoryContractor/${this.Id}`
        );
        this.categories = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },

    storeOldHeader(index) {
      this.oldHeader = this.items[index].header;
    },
    handleHeaderChange(index, newHeader) {
      const oldHeader = this.oldHeader;

      if (oldHeader) {
        const apiUrl = `${this.$apiEndPoint}/onSiteScopeOfWork/`;
        const requestData = {
          oldHeader: oldHeader,
          newHeader: newHeader,
        };
        axios
          .put(apiUrl, requestData)
          .then(() => {
            this.fetchscopeOfWork();
          })
          .catch((error) => {
            this.$handleError(error);
          });
      }
    },

    openAddActivityModal(timelineId) {
      this.currentTimelineId = timelineId;
    },

    async handleRowUpdate(row) {
      try {
        await this.updateRowData(row);
      } catch (error) {
        this.$handleError(error);
      }
    },
    async updateRowData(row) {
      try {
        await axios.put(`${this.$apiEndPoint}/onSiteScopeOfWork/${row.id}`, {
          actualStartDate: row.actualStartDate,
          actualEndDate: row.actualEndDate,
        });
        this.fetchTimeLineData();
      } catch (error) {
        this.$handleError(error);
      }
    },
    switchToQuotationTable(showTable) {
      this.showPendingQuotations = false;
      this.showRejectedQuotations = false;
      this.showApprovedQuotations = false;

      // Dynamically set the correct table to true
      this[showTable] = true;

      if (showTable === "showPendingQuotations") {
        this.currentTable = "Pending";
        this.fetchQuotationByStatus("Pending");
      } else if (showTable === "showApprovedQuotations") {
        this.currentTable = "Approved";
        this.fetchQuotationByStatus("Approved");
      } else if (showTable === "showRejectedQuotations") {
        this.currentTable = "Rejected";
        this.fetchQuotationByStatus("Rejected");
      }
    },
    async DeleteQuotation(QuotationIds, index) {
      const result = await this.$showAlert(
        "",
        "Are you sure you want to delete this quotation?",
        "warning",
        "Yes, delete it",
        "Cancel"
      );

      // If the user clicked Cancel, don't proceed
      if (!result.isConfirmed) return;
      this.Quotations.splice(index, 1);
      try {
        await axios.delete(`${this.$apiEndPoint}/OnSiteQuotation/${QuotationIds}`);
        this.fetchQuotationByStatus(this.currentTable);
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchQuotationByStatus(Status) {
      this.Quatations = [];
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/onSiteQuotation/statusOnSiteId/${Status}/${this.Id}`
        );
        this.Quatations = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    switchToTable(showTable) {
      this.showInwardEntry = false;
      this.showStockConsumption = false;
      this.showOutwardEntry = false;
      this.showInventoryEntry = false;

      // Dynamically set the correct table to true
      this[showTable] = true;

      if (showTable === "showInwardEntry") {
        this.fetchInwardEntries();
      } else if (showTable === "showOutwardEntry") {
        this.fetchOutwardEntries();
      } else if (showTable === "showStockConsumption") {
        this.fetchStockConsumptionEntries();
      } else if (showTable === "showInventoryEntry") {
        this.fetchInventryItems();
      }
    },
    switchToTabularView() {
      this.showTabularView = true;
      this.showGraphicalView = false;
    },
    switchToGraphicalView() {
      this.showTabularView = false;
      this.showGraphicalView = true;
      this.adjustChartHeight();
    },
    async featchProjectProfileData() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/onSiteProject/${this.Id}`);
        this.ProjectName = response.data["projects.projectName"];
        this.ProjectStatus = response.data.status;
        this.CustomerId = response.data.customerId;
        this.collaborators = response.data.collaborators;
        this.mainProjectId = response.data.projectId;
        this.featchSalesOrder();
        this.fetchscopeOfWork();
        this.fetchStoreId(response.data.projectId);
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchEmployees() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/employee/myTeam/Onsite`);
        this.Employees = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    setActiveTab(tab) {
      this.activeTab = tab;
      if (this.activeTab === "scopeOfWork") {
        this.fetchscopeOfWork();
        this.fetchEmployees();
      } else if (this.activeTab === "timeline") {
        this.fetchTimeLineData();
      } else if (this.activeTab === "contractor") {
        this.fetchQuotationByStatus(this.currentTable);
        this.fetchdisplayedData();
      } else if (this.activeTab === "files") {
        this.fetchFolderOrFiles();
      } else if (this.activeTab === "purchaseRequest") {
        this.fetchpurchaseRequest();
      } else if (this.activeTab === "purchaseOrders") {
        this.fetchpurchaseOrder();
      } else if (this.activeTab === "purchaseInvoice") {
        this.fetchpurchaseOrder();
        this.fetchpurchaseInvoice();
      } else if (this.activeTab === "grn") {
        this.fetchgrn();
        this.fetchApprovedpurchaseOrder();
      } else if (this.activeTab === "stockEntry") {
        this.fetchInwardEntries();
      }
    },
    formatDate(dateStr) {
      if (!dateStr) return "";
      const date = new Date(dateStr);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    },
    async fetchpurchaseRequest() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/purchaseRequest/prByProjectId/unarchive/Onsite/${this.mainProjectId}`
        );
        this.PurchaseRequests = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchpurchaseOrder() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/purchaseOrder/poByShippingId/${this.CustomerId}/`
        );
        this.PurchaseOrders = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchApprovedpurchaseOrder() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/purchaseOrder/poByShippingId/${this.CustomerId}/createGrn`
        );
        this.ApprovePurchaseorders = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },

    async fetchpurchaseInvoice() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/purchaseInvoice/piByShippingId/${this.CustomerId}`
        );
        this.PurchaseInvoice = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },

    async fetchInwardEntries() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/inwardOutwardEntries/inwardEntries/${this.StoreId}`
        );
        this.InwardEntries = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },

    async fetchStockConsumptionEntries() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/stockConsumption/byStoreId/${this.StoreId}`
        );
        this.StocksList = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchInventryItems() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/onSiteInventry/${this.StoreId}`
        );
        this.InventryItems = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },

    async fetchOutwardEntries() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/inwardOutwardEntries/outwardEntries/${this.StoreId}`
        );
        this.OutwardEntries = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },

    async fetchTimeLineData() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/onSiteScopeOfWork/timelineByProjectId/${this.mainProjectId}`
        );
        this.timelineRows = response.data;

        if (this.timelineRows.length !== 0) {
          const plannedData = [];
          const actualData = [];
          const delayData = [];

          this.timelineRows.forEach((row) => {
            const plannedStart = new Date(row.plannedStartDate).getTime();
            const plannedEnd = new Date(row.plannedEndDate).getTime();

            const actualStart = row.actualStartDate
              ? new Date(row.actualStartDate).getTime()
              : null;
            const actualEnd = row.actualEndDate
              ? new Date(row.actualEndDate).getTime()
              : null;

            plannedData.push({
              x: row.activity,
              y: [plannedStart, plannedEnd],
            });

            // Only push to actualData if both actualStartDate and actualEndDate are provided
            if (actualStart !== null && actualEnd !== null) {
              actualData.push({
                x: row.activity,
                y: [actualStart, actualEnd],
              });

              // Check for delay only if plannedEnd is less than actualEnd
              if (plannedEnd < actualEnd) {
                delayData.push({
                  x: row.activity,
                  y: [plannedEnd, actualEnd],
                });
              }
            }
          });

          this.series = [
            {
              name: "Planned",
              data: plannedData,
              color: "#0000FF", // Blue
            },
            {
              name: "Actual",
              data: actualData,
              color: "#008000", // Green
            },
            {
              name: "Delay",
              data: delayData,
              color: "#FF0000", // Red
            },
          ];
        }
      } catch (error) {
        this.$handleError(error);
      }
    },

    adjustChartHeight() {
      this.chartHeight = this.timelineRows.length * 50 + 100; // Adjust 30 and 100 based on your preference
    },
    async DeleteTimeLines(timeLineid, index) {
      const result = await this.$showAlert(
        "",
        "Are you sure you want to delete this activity?",
        "warning",
        "Yes, delete it",
        "Cancel"
      );

      // If the user clicked Cancel, don't proceed
      if (!result.isConfirmed) return;
      this.timelineRows.splice(index, 1);

      try {
        await axios.delete(`${this.$apiEndPoint}/onSiteTimeline/${timeLineid}`);
        this.fetchTimeLineData();
      } catch (error) {
        this.$handleError(error);
      }
    },
    async DeleteStockItems(timeLineid, index) {
      const result = await this.$showAlert(
        "",
        "Are you sure you want to delete this entry?",
        "warning",
        "Yes, delete it",
        "Cancel"
      );

      // If the user clicked Cancel, don't proceed
      if (!result.isConfirmed) return;
      this.StocksList.splice(index, 1);
      try {
        await axios.delete(`${this.$apiEndPoint}/stockConsumption/${timeLineid}`);
        this.fetchStockConsumptionEntries();
      } catch (error) {
        this.$handleError(error);
      }
    },
    async DeleteOutWardEntry(timeLineid, index) {
      const result = await this.$showAlert(
        "",
        "Are you sure you want to delete this entry?",
        "warning",
        "Yes, delete it",
        "Cancel"
      );

      // If the user clicked Cancel, don't proceed
      if (!result.isConfirmed) return;
      this.OutwardEntries.splice(index, 1);

      try {
        await axios.delete(`${this.$apiEndPoint}/inwardOutwardEntries/${timeLineid}`);
        this.fetchStockConsumptionEntries();
      } catch (error) {
        this.$handleError(error);
      }
    },

    async DeleteGrn(timeLineid, index) {
      const result = await this.$showAlert(
        "",
        "Are you sure you want to delete this GRN?",
        "warning",
        "Yes, delete it",
        "Cancel"
      );

      // If the user clicked Cancel, don't proceed
      if (!result.isConfirmed) return;
      this.grnList.splice(index, 1);

      try {
        await axios.delete(`${this.$apiEndPoint}/OnSiteGrn/${timeLineid}`);
        this.fetchgrn();
      } catch (error) {
        this.$handleError(error);
      }
    },

    OpensalesOrder(salesOrder) {
      this.$router.push({
        name: "CreateSalesOrder",
        params: {
          SalesOrderNumber: salesOrder.salesOrderNumber,
          SalesOrderId: salesOrder.id,
        },
      });
    },
  },
};
</script>

<style scoped>
.dropdown-item:focus,
.dropdown-item:hover {
  background-color: transparent;
  /* Keep the background transparent on hover/focus */
  color: black;
  /* Keep the text color */
}

.OnSiteProjectHeader {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.OnSiteProjectComponentHeader {
  position: sticky;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
  top: 0;
  width: 100%;
  background-color: #ffffff;
}

.OnSiteProjectHeaderRow1 {
  display: flex;
  align-items: center;
}

.mainHeading {
  flex: content;
}

#OnSiteProjectHeaderImage {
  width: 45px;
  height: 45px;
}

.OnsiteProjectHeaderRow2 {
  display: flex !important;
  justify-content: space-between;
}

.DesignProjectComponentHeader {
  display: flex;
  align-items: center;
  padding: 0.5% 0;
  position: sticky;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
  z-index: 99999;
  top: 0;
  background-color: #ffffff;
}

.DesignProjectComponent .DesignProjectComponentHeader::-webkit-scrollbar {
  display: none;
}

#DesignProjectHeaderImage {
  width: 50px;
  height: 50px;
  margin-left: 0.5%;
  display: flex;
}

.DesignProjectHeader {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.DesignProjectHeader .DesignProjectHeaderRow1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 1%;
  margin-bottom: 10px;
}

.DesignProjectHeader .DesignProjectHeaderRow1 input {
  display: inline-block;
  max-width: fit-content;
  padding: 1%;
  border: none;
  outline: none;
  color: black;
  font-size: 18px;
  flex-grow: 1;
  /* Allows the input to grow to fit the content */
  margin-right: 1%;
  /* Adds space between the input and the next icon */
}

.DesignProjectHeader .DesignProjectHeaderRow1 input:hover {
  outline: 1px solid #ccc;
  border-radius: 5px;
}

.DesignProjectHeader .DesignProjectHeaderRow1 .ImgOfStatus img {
  display: inline-block;
  margin: 0 2%;
  width: 20px;
  cursor: pointer;
}

.DesignProjectHeader .DesignProjectHeaderRow2 h6 {
  margin: 0 3%;
  white-space: nowrap;
  cursor: pointer;
  font-size: 14px;
}

.inactive-SubHeading {
  font-size: 14px;

  color: #868686;
  font-weight: 500;
}

.ProjectName:hover {
  outline: 1px solid #ccc;
}

.active-SubHeading {
  font-size: 14px;
  text-decoration: underline;
  color: #000000;
  font-weight: 500;
}

.DesignProjectHeaderUsers {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  right: 2%;
  margin-top: 2%;
}

.DesignProjectHeaderUsers button {
  padding: 2px 4px;
  margin: 0 30px;
}

#BellImage {
  margin-right: 40%;
}

.collaborators-container {
  display: flex;
  align-items: center;
  position: relative;
}

.collaborator-avatar {
  position: relative;
  display: flex;
  justify-content: left;
  align-items: left;
  margin-right: -5px;

  /* Adjust margin to control the overlap */
}

/* Style for initials */
.initials {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #4884f7;
  /* Blue background color */
  color: #ffffff;
  /* White text color */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
  z-index: 1;
  margin-right: -4px;
  /* Adjust margin to control the overlap */
  position: relative;
  /* Ensure z-index works correctly */
  border: 2px solid white;
}

/* Style for profile images */
.profile-image {
  width: 40px;
  /* Adjust size as needed */
  height: 40px;
  /* Adjust size as needed */
  /* Make the image circular */
  position: relative;
  z-index: 1;
  /* Ensure profile images are above initials */
}

/* Styles for employee name dropdown */
.employee-name-dropdown {
  position: absolute;
  top: 60px;
  /* Adjust distance from avatars */
  left: 0;
  background-color: #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 5px;
  border-radius: 5px;
}

/* Styles for individual employee names */
.employee-name {
  padding: 5px;
  cursor: pointer;
  color: #000000;
}

.DesignProjectTimeLineWrapper button {
  border-radius: 5px;
  padding: 0.5% 1%;
  margin: 1% 2%;

  display: block;
}

.files-table {
  width: 100%;
  margin-top: 10px;
  border-collapse: collapse;
}

.files-table th,
.files-table td {
  text-align: left;
  padding: 6px;
  border-bottom: 1px solid #ddd;
}

.files-table th {
  background-color: #bcbfc6;
}

.files-table tr:hover {
  background-color: #f5f5f5;
}

.DesignProjectTimeLine {
  width: 96%;
  margin: 0 2%;
  border-collapse: collapse;
}

.DesignProjectTimeLine th,
.DesignProjectEstimate td {
  padding: 4px 8px;
  text-align: left;
  font-size: 2;
  white-space: nowrap;
}

.DesignProjectTimeLine td {
  padding: 4px 8px;
  text-align: left;
  font-size: 14px;
}

.files-table th:first-child {
  border-radius: 5px 0 0 5px;
}

.files-table th:last-child {
  border-radius: 0px 5px 5px 0px;
}

.DesignProjectTimeLine thead,
.DesignProjectEstimate thead {
  background-color: #bcbfc6;
  border-radius: 10px;
  top: 0;
  position: sticky;
  color: #333;
  font-size: 14px;
}

.DesignProjectTimeLine tbody td input {
  padding: 1% 1%;
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  text-align: left;
}

.active-view {
  background-color: #007bff;
  color: white;
}

.DesignProjectTimeLineWrapper .d-flex button {
  margin-right: 10px;
  font-size: 12px;
}

.DesignProjectTimeLineWrapper .d-flex button.active-view {
  background-color: purple;
  color: white;
}

.DesignProjectTimeLineWrapper .d-flex button.blackactive-view {
  background-color: #333;
  color: white;
}

.stockEntryview button {
  background-color: #f8f8f8;
  font-weight: 500;
}

.table-responsive {
  height: 69vh;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.ScopeOfWorkTable {
  height: 67vh;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.tableOfEstimate thead tr th {
  background-color: #bcbfc6;
  position: sticky;
  top: 0;
  z-index: 1;
}

.tableOfEstimate tbody {
  overflow: scroll;
}

.tableOfEstimate tbody::-webkit-scrollbar {
  display: none;
}

.tableOfEstimate thead tr {
  font-size: 14px;
}

.tableOfEstimate tbody tr {
  height: 40px;
  font-size: 14px;
}

.tableOfEstimate tbody tr,
.tableOfEstimate thead tr {
  display: flex;
}

.tableOfEstimate thead tr th,
.tableOfEstimate tbody tr td {
  min-width: 16%;
  max-width: 16%;
  padding: 6px;
  text-align: left;
}

.tableOfEstimate thead tr th:last-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #bcbfc6;
}

.tableOfEstimate tbody tr td:last-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.tableOfEstimate thead th:nth-child(1),
.tableOfEstimate tbody tr td:nth-child(1) {
  min-width: 60px;
  max-width: 60px;
  padding-top: 6px;
  font-size: 14px;
}

.tableOfEstimate thead th:nth-child(2),
.tableOfEstimate tbody tr td:nth-child(2) {
  min-width: 32%;
  max-width: 32%;
  text-align: center;
}

.tableOfEstimate tbody tr td input {
  background-color: transparent;
  outline: none;
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.itemBlock {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}

.ItemHeaderOfThings p {
  position: relative;
  margin: 0 1%;
  color: #000000;
  font-weight: bold;
}

.itemBlock::-webkit-scrollbar {
  display: none;
}

.itemBlock span button {
  background: transparent;
  display: flex;
  padding: 0.5%;
  font-size: 10px;
  border-radius: 10px;
  color: #ffbd59;
  outline: none;
  border: none;
}

#itemRowEstimate {
  border-bottom: 1px solid #edeae9;
  margin: 3px;
}

.itemBlock #HeaderOfBillItem {
  background-color: transparent;
  width: 100%;
  border: none;
  outline: none;
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  padding: 3px;
  text-align: center;
}

#itemAdding {
  display: flex;
  background-color: #084f5b;
  margin-left: 1%;
  padding: 0.5% 1%;
  border-radius: 10px;
  color: whitesmoke;
  cursor: pointer;
  border: 1px solid #ffaa23;
}

#itemrowAdding {
  display: flex;
  color: #000000;
  margin-left: 1%;
  font-size: 12px;
  padding: 0.5% 1%;
  border-radius: 10px;
  white-space: nowrap;
  cursor: pointer;
  width: 10%;
  background-color: transparent;
  border: none;
  outline: none;
}

.toggleWidththings,
.toggleHeightthings {
  position: fixed;
  background-color: #ffffff;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin: 10px 20px;
  letter-spacing: 2px;
}

.toggleWidththings p,
.toggleHeightthings p {
  padding: 4px 8px;
  text-decoration: none;
  display: flex;
  cursor: pointer;
  color: #000000;
  font-weight: 100;
}

/* Responsive table styles */
.tableOfEstimate {
  width: 100%;
  border-collapse: collapse;
}

.tableOfEstimate th,
.tableOfEstimate td {
  padding: 4px 8px;
}

.ItemHeaderOfThings {
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
  margin-top: 5px;
  font-size: 16px;

  white-space: nowrap;
}

.ItemHeaderOfThings input {
  flex: 1;
  margin-right: 10px;
}

.dropdownToggle {
  cursor: pointer;
}

.EstimateBill {
  margin-top: 20px;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
  /* Remove default outline */
  box-shadow: none;
  /* Add a custom focus shadow */
}

.CreateGrnPage {
  padding: 1% 3%;
  width: 100%;
  height: 70vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.table-container {
  margin-top: 20px;
  padding: 0px 20px;
}

.small-wrapper {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  height: 65vh;
  overflow-y: scroll;
}

.small-table {
  width: 100%;
  margin-bottom: 20px;
  table-layout: fixed;
}

.table-header-heading th {
  background-color: #f2f2f2;
  text-align: center;
  position: sticky;
  top: 0;
}

.table-header-heading th:nth-child(1) {
  width: 5%;
}

.table-header-heading th:nth-child(2),
.table-header-heading th:nth-child(4),
.table-header-heading th:nth-child(5),
.table-header-heading th:nth-child(6) {
  width: 15%;
}

.table-header-heading th:nth-child(3) {
  width: 20%;
}

tbody td {
  padding: 5px;
  margin: 0;

  text-align: left;
}

.table-header-heading td:nth-child(1) {
  text-align: center;
}

input {
  border: none;
  padding: 5px;
}

.add-item {
  margin: 10px 0px;
  float: left;
  width: 100px;
}

.footerPuchaseRequest .btn {
  margin: 5px;
}

.input-bg {
  background-color: #fff;
}

.activity {
  width: 20%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.quantity-input {
  width: 100%;
}

.GrnTable {
  margin-top: 20px;
  width: 100%;
  overflow-x: auto;
  background-color: #f8f8ff;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.GrnTable table {
  width: 100%;
  border-collapse: collapse;
}

.GrnTable th,
.GrnTable td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.GrnTable th {
  background-color: #f2f2f2;
}

.showProjectNameDropDown {
  position: fixed;
  top: 14%;
  border-radius: 5px;
  background-color: #f5f5f5;
  z-index: 999999;
  border: 1px solid #333;
  width: 130px;
  height: 90px;
}

.showProjectNameDropDown ol {
  list-style-type: none;
  padding: 2% 5%;
}

.showProjectNameDropDown ol li {
  color: #333;
  margin: 5% 2%;
  text-align: left;
  white-space: nowrap;
  cursor: pointer;
  position: relative;
  padding: 1%;
}

.showProjectNameDropDown ol li:hover {
  background-color: #868686;
  color: white;
  border-radius: 5px;
}

.FilesPageHeader section {
  background-color: #f5f5f5;
  border-radius: 5px;
  text-align: center;
  padding: 0 10px;
  font-size: 14px;
  letter-spacing: 1px;
}

.FilesPageHeader section:hover {
  background-color: #f8f8f8;
}

.FilesPageHeader section img {
  margin: 0 5px;
  width: 15px;
  height: 15px;
}

.scope-of-work-container {
  height: 100%;
  overflow: hidden;
}

.content-wrapper {
  height: 100%;
  padding: 1rem 0.25rem;
}

.subHeaderHeadingBgColor {
  background-color: #ccc;
  position: sticky;
  top: 0;
  z-index: 1;
}

.FolderTable thead th {
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 0.45rem;
  text-align: left;
}

.content-wrapper .tableOfEstimate tbody {
  display: block;
  overflow-y: auto;
}

.content-wrapper .tableOfEstimate tr {
  display: flex;
  width: 100%;
}

.content-wrapper .tableOfEstimate td,
.content-wrapper .tableOfEstimate th {
  flex: 1;
  padding: 0.75rem;
  text-align: left;
}

.header-input-cell {
  text-align: center;
  width: 100%;
}

.AddChildRowIcon {
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin-top: 10px;
}

.ChildRowIcon {
  width: 15px;
  height: 15px;
  cursor: pointer;
  margin-top: 10px;
}

.delete-icon {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.childRow {
  background-color: #f9f9f9;
}

a {
  color: inherit !important;
  /* or specify your desired color */
  text-decoration: none !important;
  /* remove underline if needed */
}

a:active,
a:focus {
  color: inherit !important;
  /* keep the same color on click/focus */
  outline: none !important;
  /* remove focus outline */
}

.footerPuchaseRequest {
  width: calc(100%);
  border-radius: 0px 0px 15px 15px;
  margin-top: 20px;
  position: fixed;
  bottom: 15px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scopeOfWOrkHeader {
  display: flex;
  justify-content: space-between;
}

.row-content {
  justify-content: center;
  border-bottom: 1px solid #dee2e6;
}

.row-content td {
  flex: 1;
  padding: 0.5rem;
  text-align: left;
  align-items: center;
}

.activity-cell {
  flex: 2;
  word-break: break-word;
  width: 200px;
}

.DesignProjectHeader .DesignProjectHeaderRow2 {
  display: flex !important;
  align-items: center;
  width: 100%;
}

.btn-custom {
  width: fit-content;
  height: 30px;
  font-size: 12px;
  font-weight: bold;
  background-color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  border: 1px solid #b1c5df;
  letter-spacing: 1px;
  gap: 8px;
  border-radius: 6px;
}

.btn-custom img {
  width: 18px;
  height: 18px;
}
.btn-custom:hover {
  background-color: purple;
  color: white;
}
.form-group label {
  font-size: 14px;
}

.FolderTable tbody {
  overflow-y: auto;
  max-height: calc(100vh - 200px);
}

.ActionImage img {
  padding: 5px;
  width: 30px;
  height: 30px;
  margin: 0 5px;
  cursor: pointer;
}

.ActionImage img:hover {
  background-color: #ccc;
  border-radius: 50%;
}
@media screen and (max-width: 768px) {
  .stockEntryview button {
    background-color: #f8f8f8;
    font-weight: 500;
    padding: 4px 8px;
  }

  .files-table th,
  .files-table td {
    text-align: left;
    padding: 6px;
    border-bottom: 1px solid #ddd;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
  }

  .row-content {
    justify-content: center;
    border-bottom: 1px solid #dee2e6;
  }

  .row-content td {
    flex: 1;
    padding: 0.5rem;
    text-align: left;
    align-items: center;
    min-width: max-content !important;
  }

  .activity-cell {
    flex: 2;
    word-break: break-word;
    width: 200px;
  }

  .ActionImage img {
    padding: 5px;
    width: 30px;
    height: 30px;
    margin: 0 5px;
    cursor: pointer;
  }

  .showProjectNameDropDown {
    position: fixed;
    top: 10%;
    border-radius: 5px;
    background-color: #f5f5f5;
    z-index: 999;
    border: 1px solid #333;
    width: 130px;
    height: 90px;
  }

  .DesignProjectHeaderUsers {
    right: 2%;
  }

  .DesignProjectComponentHeader {
    overflow-x: hidden;
  }

  .DesignProjectHeaderUsers button {
    padding: 2px 4px;
    margin: 0 30px;
    margin-bottom: 10px;
    white-space: nowrap;
  }

  .DesignProjectHeaderUsers .collaborator-avatar {
    margin-right: 0;
    flex-direction: row !important;
  }

  .DesignProjectHeaderUsers .additional-count {
    margin-left: 5px;
  }

  .table-responsive {
    height: 70vh;
    width: 100%;
    overflow-y: scroll;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive th,
  .table-responsive td {
    white-space: nowrap;
  }

  select {
    width: 100% !important;
  }

  .itemBlock {
    display: block;
    width: 100%;
  }

  #itemrowAdding {
    width: 100%;
  }

  #DesignProjectHeaderImage {
    width: 35px;
    height: 35px;
    margin-left: 0.5%;
    display: flex;
  }

  .DesignProjectHeader .DesignProjectHeaderRow3 {
    display: flex;
    flex-direction: row;

    width: max-content;
    justify-content: space-between;
  }
  .btn-custom {
    position: absolute;
    right: 2%;
  }
  .DesignProjectHeaderUsers {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0px;
    margin-left: 30%;
  }

  .dropdown-menu {
    z-index: 999;
  }

  .small-btn {
    font-size: 11px;
    /* Smaller font size */
    padding: 6px 8px;
    /* Reduced padding */
    line-height: 1;
    /* Tighter spacing */
  }

  .OnsiteProjectHeaderRow2 {
    display: none !important;
  }

  .mobile-scope-container {
    padding: 10px 0px;
  }

  .header-section {
    margin-bottom: 15px;
  }

  .header-input {
    font-size: 16px;
    font-weight: 600;
    padding: 8px;
    border-radius: 4px;
  }

  .activity-card {
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 12px;
    padding: 12px;
    background-color: #f8f8f8;
  }

  .sub-activity {
    margin-left: 0px;
    border-left: 3px solid #e0e0e0;
    background: #fff;
  }

  .card-header {
    border-bottom: 1px solid #eee;
    padding-bottom: 8px;
    margin-bottom: 8px;
  }

  .activity-title {
    font-size: 14px;
    font-weight: 500;
    color: #333;
  }

  .card-details {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
  }

  .detail-item {
    display: flex;
    flex-direction: column;
  }

  .detail-item label {
    font-size: 10px;
    color: #666;
    margin-bottom: 2px;
  }

  .detail-item span {
    font-size: 10px;
    color: #333;
  }

  .detail-item input {
    font-size: 10px;
  }

  .sub-activities {
    margin-top: 8px;
    padding-left: 0px;
  }

  .ScopeOfWorkTable {
    height: 75vh;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .form-group {
    font-size: 12px !important;

    margin-bottom: 10px;
  }

  .form-group label {
    margin-bottom: 5px;
  }

  .HeadingOfCreateGRN {
    padding: 10px 0px;
  }

  .border-black {
    border: 1px solid #000000;
  }

  .DesignProjectTimeLineWrapper {
    width: 100%;
  }

  /*. */
}

@media screen and (max-width: 300px) {
  .card-details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
  }
}
</style>
