<template>
  <div v-if="isLoading" class="loading-overlay">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <p class="text-white mt-2">Uploading file, please wait...</p>
  </div>
</template>

<script>
import { globalState } from "../plugins/Store";

export default {
  name: "LoadingSpinner",
  computed: {
    isLoading() {
      return globalState.isLoading;
    },
  },
};
</script>

<style scoped>
.loading-overlay {
  position: fixed; /* Ensure it covers the entire viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent background */

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2000; /* Higher than Bootstrap's modal (1050) */
} /* HTML: <div class="loader"></div> */
/* HTML: <div class="loader"></div> */
</style>
