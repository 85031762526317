<template>
  <div>
    <Header />
    <div class="content-Container">
      <Nav />
      <div class="containerChild">
        <div class="FormatTable" v-if="showUnclaimedList">
          <div class="row headerOfTable d-flex">
            <div class="col-md-12 d-flex justify-content-between align-content-center">
              <div class="col-md-6 d-flex">
                <div
                  class="col-md-3 text-nowrap d-flex align-content-center active-SubHeading ms-2"
                >
                  <h5 class="me-3">Tickets</h5>
                  <a href="/CreateTicket" class="plus-icon">
                    <img
                      src="../../assets/icons/blueAddicon.png"
                      alt=""
                      id="addicon"
                      style="cursor: pointer; margin-top: 2px"
                    />
                  </a>
                </div>

                <div class="col-md-2"></div>
              </div>
              <div class="col-md-6 d-flex align-items-center">
                <div class="col-md-3 ImportExport" style="visibility: hidden">
                  <div class="row">
                    <div class="col-md-4 tableTextEvenRow">Import</div>
                    <div class="col-md-3 tableTextEvenRow">
                      <img src="../../assets/icons/import.png" alt="" id="ImportIcon" />
                    </div>
                  </div>
                </div>
                <div class="col-md-3 ImportExport" style="visibility: hidden">
                  <div class="row">
                    <div class="col-md-4 tableTextEvenRow">Export</div>
                    <div class="col-md-3 tableTextEvenRow">
                      <img src="../../assets/icons/export.png" alt="" id="ImportIcon" />
                    </div>
                  </div>
                </div>

                <div class="col-md-1" style="visibility: hidden">
                  <img
                    src="../../assets/icons/archive.png"
                    alt=""
                    id="ArchiveIcon"
                    @click="handleArchiveClick"
                  />
                </div>
                <div class="col-md-4 searchboxOfTable w-100">
                  <div class="search-box">
                    <img
                      src="../../assets/icons/search.png"
                      alt="Search Icon"
                      id="SerchIconOfTable"
                    />
                    <input
                      class="border-0 w-100"
                      style="outline: 0"
                      type="text"
                      name="search"
                      id=""
                      v-model="searchString"
                      @input="searchTickets"
                    />
                  </div>
                  <span>
                    <img
                      src="../../assets/icons/filter.png"
                      alt="Search Icon"
                      class="FilterIconOfTable"
                      style="cursor: pointer"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="table-wrapper">
            <table class="tableFormat">
              <caption class="d-none">
                Table by Status
              </caption>
              <thead>
                <tr>
                  <th>Issue Date</th>
                  <th>Issue Title</th>
                  <th>Description</th>
                  <th>Raised By</th>
                  <th>Module Name</th>
                  <th>Severity</th>
                  <th>Status</th>
                </tr>
              </thead>

              <tbody>
                <template v-if="cardLoading">
                  <TableSkeletonLoader :columns="7" />
                </template>
                <template v-else>
                  <tr v-for="(data, index) in displayedData" :key="index">
                    <td @click="goToTicketById(data.id)">
                      {{ this.$formatDateDMY(data.raisedDate) }}
                    </td>

                    <td :title="data.title" class="ellipsis">
                      {{ data.title }}
                    </td>
                    <td :title="data.description" class="ellipsis">
                      {{ data.description }}
                    </td>
                    <td>
                      {{ data["employees.firstName"] }} {{ data["employees.lastName"] }}
                      <!-- Assuming raisedByEntityName exists instead of ID -->
                    </td>
                    <td>
                      {{ data.moduleName }}
                    </td>

                    <td>
                      <span :class="getPriorityClass(data.priority)" class="badge">
                        {{ data.priority }}
                      </span>
                    </td>

                    <td>
                      <span :class="getStatusClass(data.status)" class="badge">
                        {{ data.status }}
                      </span>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <div class="paginationOfTable">
            <div class="pageControlsTable">
              <img
                src="../../assets/icons/blueLeftarrow.png"
                @click="changePage(-1)"
                :disabled="currentPage === 1"
                alt="Prev"
              />
              <div class="page-numbers">
                <span
                  v-for="pageNumber in pagination"
                  :key="pageNumber"
                  :class="{
                    'page-number': true,
                    'current-page': pageNumber === currentPage,
                  }"
                  @click="goToPage(pageNumber)"
                  >{{ pageNumber }}</span
                >
              </div>

              <img
                src="../../assets/icons/blueRightarrow.png"
                @click="changePage(1)"
                :disabled="currentPage === pagination.length"
                alt="Next"
              />
            </div>

            <div class="entries-info">
              <span class="entries-text"
                >Entries: {{ displayedData ? displayedData.length : 0 }} Rows</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Header from "../Header.vue";
import Nav from "../Nav.vue";

let pageFlag = true;

export default {
  name: "TicketList",
  components: {
    Header,
    Nav,
  },
  data() {
    return {
      cardLoading: true,
      TicketId: null,
      totalCount: 0,
      totalArchiveCount: 0,
      searchString: "",
      ticketDropdownStates: {},
      tabledropdownEdits: false,
      showClaimedList: false,
      selectAll: false,
      TableData: [],
      ArchiveData: [],
      selectedTickets: [],
      pageSize: 50,
      currentPage: 1,
      showUnclaimedList: true,
      showArchiveList: false,
      selectedEmployees: [],
      searchResults: [],
      ticketTypePage: true,
      dropdownOpen: false,
      statusDropdownOpen: false,
      selectedPRId: null,
    };
  },
  computed: {
    hasPreviousPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      return this.currentPage < Math.ceil(this.totalCount / this.pageSize);
    },
    hasArchivePreviousPage() {
      return this.currentPage > 1;
    },
    hasArchiveNextPage() {
      return this.currentPage < Math.ceil(this.totalArchiveCount / this.pageSize);
    },

    pagination() {
      const pageCount = Math.ceil(this.totalCount / this.pageSize);
      const maxVisiblePages = 5;
      let pages = [];

      if (pageCount <= maxVisiblePages) {
        pages = Array.from({ length: pageCount }, (_, index) => index + 1);
      } else {
        const startPage = Math.max(2, this.currentPage - Math.floor(maxVisiblePages / 2));
        const endPage = Math.min(
          pageCount - 1,
          this.currentPage + Math.floor(maxVisiblePages / 2)
        );

        pages = [1];

        if (startPage > 2) {
          pages.push("...");
        }

        pages.push(
          ...Array.from(
            { length: endPage - startPage + 1 },
            (_, index) => startPage + index
          )
        );

        if (endPage < pageCount - 1) {
          pages.push("...");
        }

        pages.push(pageCount);
      }

      return pages;
    },

    paginationArchive() {
      const pageCount = Math.ceil(this.totalArchiveCount / this.pageSize);
      const maxVisiblePages = 5;
      let pages = [];

      if (pageCount <= maxVisiblePages) {
        pages = Array.from({ length: pageCount }, (_, index) => index + 1);
      } else {
        const startPage = Math.max(2, this.currentPage - Math.floor(maxVisiblePages / 2));
        const endPage = Math.min(
          pageCount - 1,
          this.currentPage + Math.floor(maxVisiblePages / 2)
        );

        pages = [1];

        if (startPage > 2) {
          pages.push("...");
        }

        pages.push(
          ...Array.from(
            { length: endPage - startPage + 1 },
            (_, index) => startPage + index
          )
        );

        if (endPage < pageCount - 1) {
          pages.push("...");
        }

        pages.push(pageCount);
      }

      return pages;
    },
    displayedData() {
      return this.TableData;
    },
    displayedArchiveData() {
      return this.ArchiveData;
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    goToTicketById(Id) {
      this.$router.push({ name: "TicketById", params: { Id: Id } });
    },

    getPriorityClass(priority) {
      switch (priority.toLowerCase()) {
        case "high":
          return "badge-high";
        case "low":
          return "badge-low";
        case "medium":
          return "badge-medium";
        case "critical":
          return "badge-critical";
        default:
          return "";
      }
    },

    // Method to get status class based on the value
    getStatusClass(status) {
      switch (status.toLowerCase()) {
        case "new":
          return "badge-new";
        case "in progress":
          return "badge-in-progress";
        case "complete":
          return "badge-complete";
        default:
          return "";
      }
    },
    async fetchData() {
      this.cardLoading = true;
      let searchData = {
        searchString: this.searchString,
      };
      const hasSearchData = searchData.searchString;
      if (hasSearchData && pageFlag === true) {
        this.currentPage = 1;
        pageFlag = false;
      }
      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/ticket/getTicket/${this.currentPage}`,
          searchData
        );
        console.log(response.data);
        this.totalCount = response.data.count;
        this.TableData = response.data.row;
        this.cardLoading = false;
      } catch (error) {
        this.$handleError(error);
      }
    },
    handleDownload(id) {
      this.selectedPRId = id;
      // Ensure the modal is fully loaded before triggering download
      setTimeout(() => {
        this.$refs.PRDetailsModal.downloadPDF();
      }, 1000); // Adjust the timeout as needed
    },
    openModal(ticketId) {
      this.TicketId = ticketId;
    },
    exportToCSV() {
      // Combine displayedData and displayedArchiveData based on your current view
      const allData = this.showArchiveList ? this.ArchiveData : this.TableData;

      // Extract only the fields you want to include in the CSV
      const filteredData = allData.map((data) => ({
        Saturation: data.salutation,
        Name: data.name,
        "Customer ID": data.purchaseRequestNumber,
        "Contact No": data.contactNo,
        "Email ID": data.email,
        "Property Address": data["addresses.address"],
      }));

      // Convert filtered data to CSV format
      const csvContent = this.convertToCSV(filteredData);

      // Trigger download
      this.downloadCSV(csvContent);
    },

    convertToCSV(data) {
      const headers = Object.keys(data[0]).join(",") + "\n";
      const csvData = data.map((item) => Object.values(item).join(",")).join("\n");
      return headers + csvData;
    },
    goToPurchaseCreate() {
      this.$router.push({
        name: "AddTicket",
      });
    },

    downloadCSV(csvContent) {
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "table_data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    goToPreviousPage() {
      if (this.hasPreviousPage) {
        this.changePage(-1); // Go to the previous page
      }
    },
    goToNextPage() {
      if (this.hasNextPage) {
        this.changePage(1); // Go to the next page
      }
    },
    goToArchivePreviousPage() {
      if (this.hasArchivePreviousPage) {
        this.changePage(-1); // Go to the previous page
      }
    },
    goToArchiveNextPage() {
      if (this.hasArchiveNextPage) {
        this.changePage(1); // Go to the next page
      }
    },
    //footer pagination
    changePage(delta) {
      this.selectAll = false;
      this.lastPages = this.currentPage;
      this.currentPage += delta;
      this.fetchData();
      this.fetchArchiveData();
      this.selectedTickets = [];
    },
    //footer pagination
    goToPage(pageNumber) {
      this.selectAll = false;
      this.currentPage = pageNumber;

      this.fetchData();
      this.fetchArchiveData();
      this.selectedTickets = [];
    },
    //featch all Unclaim ticket Data
    SigleTicketThingstd(ticket) {
      this.selectedTicket = ticket;
      this.ticketDropdownStates[ticket.id] = !this.ticketDropdownStates[ticket.id];
    },
    toggletabledrowpdown() {
      this.tabledropdownEdits = !this.tabledropdownEdits;
    },

    handleArchiveClick() {
      this.fetchArchiveData();
      this.showUnclaimedList = !this.showUnclaimedList;
      this.showArchiveList = !this.showArchiveList;
    },

    async fetchArchiveData() {
      this.cardLoading = true;
      let searchData = {
        searchString: this.searchString,
      };
      const hasSearchData = searchData.searchString;

      if (hasSearchData && pageFlag === true) {
        this.currentPage = 1;
        pageFlag = false;
      }
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/procurementTransit/${this.currentPage}/archived`,
          searchData
        );
        this.totalArchiveCount = response.data.count;
        this.ArchiveData = response.data.rows;
        this.cardLoading = false;
      } catch (error) {
        this.$handleError(error);
      }
    },
    //select and unselect checkbox function
    selectAllRows() {
      if (this.selectAll) {
        this.selectedTickets = this.displayedData.map(
          (purchaseRequest) => purchaseRequest.id
        );
      } else {
        this.selectedTickets = [];
      }
    },
    selectAllArchiveRows() {
      if (this.selectAll) {
        this.selectedTickets = this.displayedArchiveData.map(
          (purchaseRequest) => purchaseRequest.id
        );
      } else {
        this.selectedTickets = [];
      }
    },

    ArchiveTicket(data, index) {
      this.TableData.splice(index, 1);
      this.ArchiveData.splice(index, 1);

      axios
        .put(`${this.$apiEndPoint}/procurementTransit/archive`, { id: [data.id] })
        // Pass an array with a single ID to match the API's expected format
        .then(() => {
          this.fetchData();
          this.fetchArchiveData();
          this.ticketDropdownStates[data.id] = false; // Hide dropdown after action
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    searchArchiveTickets() {
      pageFlag = true;
      this.fetchArchiveData();
    },
    //for Serch api for Unclaimtickets
    searchTickets() {
      pageFlag = true;
      this.fetchData();
    },

    //for all selected ticket Unclaim and delete api
    ArchiveTickets(action) {
      if (this.selectedTickets.length === 0) {
        return;
      }

      if (action === "Archive") {
        this.TableData = this.TableData.filter(
          (purchaseRequest) => !this.selectedTickets.includes(purchaseRequest.id)
        );
        this.selectAll = false;
      } else if (action === "UnArchive") {
        this.ArchiveData = this.ArchiveData.filter(
          (purchaseRequest) => !this.selectedTickets.includes(purchaseRequest.id)
        );
        this.selectAll = false;
      }
      const payload = {
        id: this.selectedTickets,
      };

      axios
        .put(`${this.$apiEndPoint}/procurementTransit/archive`, payload)
        .then(() => {
          this.selectedTickets = [];
          this.fetchData();
          this.fetchArchiveData();
          this.selectAll = false;
          this.tabledropdownEdits = false;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    //featch all Unclaim ticket Data

    formatDate(dateString) {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear().toString();
      return `${day}/${month}/${year}`;
    },
  },
};
</script>

<style scoped>
.FormatTable {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.tableFormat:nth-child(2) {
  cursor: pointer;
}

.headerOfTable {
  width: 96%;
  padding: 0.5% 1%;
}

.table-wrapper {
  max-width: 99%;
  margin: 0% 1%;
  padding: 0 0.5%;
  height: 87%;
  overflow-x: scroll;
  overflow-y: scroll;
  z-index: 99;
}

.tableFormat {
  width: 100%;
  border-collapse: collapse;
}

.tableFormat thead {
  background-color: #bcbfc6;
  position: sticky;
  z-index: 99;
  top: 0;
}

.tableFormat thead tr th {
  background-color: #bcbfc6;
}
.tableFormat th,
.tableFormat td {
  padding: 8px 18px;
  text-align: left;
  white-space: nowrap; /* Prevent text wrapping */
}

#blackThreeDots {
  width: 15px;
  height: 14px;
  margin-left: 30%;
  position: relative;
  cursor: pointer;
}

.tableHeaderCheckbox,
.bodyCheckbox {
  width: 200px; /* Adjust width as needed */
}

.tableHeaderHeading {
  min-width: 150px; /* Minimum width to ensure readability */
  text-align: center; /* Center align headers */
}

.bodyText {
  text-align: center; /* Center align body text */
}

.custom-checkbox input[type="checkbox"] {
  margin-right: 5px;
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); /* Enhanced shadow */
  transition: box-shadow 0.3s ease; /* Smooth transition for hover effect */
}
.dropdown-menu:hover {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.15); /* Stronger shadow on hover */
  cursor: pointer;
}
.table-wrapper .tableFormat tbody tr:not(:first-child):nth-child(even) {
  background-color: #f8f8f8;
}
.ImportExport {
  visibility: hidden;
}

#addicon {
  width: 20px;
  height: 20px;
}

#ImportIcon {
  width: 20px;
  cursor: pointer;
}

#ArchiveIcon {
  width: 15px;
  cursor: pointer;
}

#SerchIconOfTable {
  width: 6%;
  margin-right: 5%;
}

.search-box {
  border-bottom: 2px solid #000;
  padding-bottom: 3px;
  display: flex;
  align-items: center;
}

.searchboxOfTable {
  width: 100%;
  display: flex;
  align-items: center;
}

.FilterIconOfTable {
  width: 20px;
  height: 15px;
  margin-left: 10%;
}

/* General badge style */
.badge {
  display: inline-block;
  padding: 4px 6px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
  text-align: center;
  color: white;
  text-transform: capitalize;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Priority styles */
.badge-high {
  background-color: red;
}

.badge-medium {
  background-color: orange;
}

.badge-low {
  background-color: green;
}

.badge-critical {
  background-color: darkred;
}

/* Status styles */
.badge-new {
  background-color: #007bff; /* Blue */
}

.badge-in-progress {
  background-color: #ffc107; /* Yellow */
}

.badge-complete {
  background-color: #28a745; /* Green */
}

/* Hover effect */
.badge:hover {
  transform: scale(1.1);
}

/* Tooltip style */
.badge[data-tooltip]:hover::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
  background-color: #333;
  color: white;
  border-radius: 5px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0.8;
  z-index: 10;
}

@media screen and (max-width: 768px) {
  .tableFormat th,
  .tableFormat td {
    font-size: 12px; /* Adjust font size for smaller screens */
  }

  .tableHeaderCheckbox,
  .bodyCheckbox {
    width: 150px; /* Adjust width for smaller screens */
  }
  #addicon {
    width: 100%;
  }

  .table-wrapper {
    max-height: 90%;
    /* Adjust as needed */
    overflow-y: auto;
  }

  .headerOfTable {
    width: 100%;
    overflow: scroll;
  }

  #ArchiveIcon {
    width: 30%;
  }

  .ImportExport {
    display: none;
  }

  .paginationOfTable {
    bottom: 0.5%;
  }
}
</style>
