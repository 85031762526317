<template>
  <div
    class="modal fade"
    id="viewNote"
    tabindex="-1"
    aria-labelledby="customModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered custom-modal-dialog">
      <div class="modal-content custom-modal">
        <div class="modal-header">
          <div>
            <input
              v-if="NotesData.createdBy === this.$LocalUser.user.employeeId"
              class="modal-title"
              id="customModalLabel"
              style="width: 300px; border: none"
              v-model="NotesData.header"
            />
            <h6 v-else class="modal-title" id="customModalLabel">
              {{ NotesData.header }}
            </h6>
          </div>

          <div class="ms-auto d-flex align-items-center gap-3">
            <img
              v-if="NotesData.isStared == 'Yes'"
              @click="markUnFav()"
              class="star-icon"
              src="../../assets/icons/yellowFavourite.png"
            />
            <img
              v-else
              @click="markFav()"
              class="star-icon"
              src="../../assets/icons/yellowUnfavourite.png"
            />
            <div
              class="collaborator-container"
              v-if="NotesData.collaborators && NotesData.collaborators.length > 0"
            >
              <span v-if="NotesData.collaborators.length > 3" class="collaborator-count">
                +{{ NotesData.collaborators.length - 3 }}
              </span>
              <span
                v-for="(data, index) in NotesData.collaborators.slice(0, 3)"
                :key="index"
                class="collaborator-initials"
                :style="{ zIndex: index }"
              >
                {{ data.initials }}
              </span>
            </div>

            <div class="btn-group">
              <button
                type="button"
                class="btn btn-primary"
                data-bs-toggle="dropdown"
                v-if="NotesData.createdBy === this.$LocalUser.user.employeeId"
                @click="fetchCollaborators()"
                style="
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  border-radius: 30px;
                  height: 30px;
                  padding: 8px 16px;
                  font-size: 16px;
                "
              >
                <i class="bi bi-share-fill"></i> Share
              </button>

              <!-- Dropdown Content -->
              <div
                class="dropdown-menu p-3"
                style="
                  width: 357px;
                  border-radius: 8px;
                  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
                  max-height: 450px;
                  overflow-y: auto;
                "
                @click.stop
              >
                <h6 style="font-size: 12px">Share Note With</h6>

                <!-- Select Employees -->
                <div
                  class="form-control d-flex flex-wrap align-items-center"
                  style="min-height: 38px; cursor: text; border-radius: 6px"
                >
                  <span
                    v-for="employee in selectedEmployees"
                    :key="employee.id"
                    class="badge bg-primary m-1 d-flex align-items-center"
                    style="cursor: pointer"
                  >
                    {{ employee.firstName }} {{ employee.lastName }}
                    <i class="bi bi-x ms-2" @click="removeEmployee(employee.id)"></i>
                  </span>

                  <input
                    type="text"
                    class="border-0 flex-grow-1"
                    placeholder="Type to invite..."
                    v-model="searchString"
                    @input="fetchEmployees"
                    style="outline: none"
                  />
                </div>

                <!-- Dropdown Search Results -->
                <ul
                  style="font-size: small"
                  v-if="employees.length"
                  id="dropdownMenu"
                  class="list-group mt-2"
                >
                  <li
                    v-for="employee in employees"
                    :key="employee.id"
                    class="list-group-item list-group-item-action"
                    @click="selectEmployee(employee)"
                    style="cursor: pointer; font-size: small"
                  >
                    {{ employee.firstName }} {{ employee.lastName }}
                  </li>
                </ul>

                <!-- Invite Button -->
                <button
                  class="btn btn-primary mt-2 w-100"
                  @click.prevent="createCollaborator"
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 8px;
                    border-radius: 8px;
                    padding: 8px;
                    font-size: 14px;
                  "
                >
                  <i class="bi bi-person-plus-fill"></i> Share
                </button>

                <!-- Added Collaborators -->
                <div v-if="invitedEmployees.length" class="mt-3">
                  <h6 style="font-size: 12px">Added Collaborators</h6>
                  <ul class="list-group">
                    <li
                      v-for="employee in invitedEmployees"
                      :key="employee.id"
                      class="list-group-item d-flex justify-content-between align-items-center"
                      style="font-size: 14px"
                    >
                      {{ employee.firstName }} {{ employee.lastName }}
                      <button
                        class="btn btn-danger"
                        style="
                          display: flex;
                          align-items: center;
                          gap: 8px;
                          border-radius: 6px;
                          padding: 4px 8px;
                          font-size: 12px;
                        "
                        @click="removeEmployee(employee.employeeId)"
                      >
                        Remove Access
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="btn-group">
              <button
                type="button"
                class="btn btn-primary"
                data-bs-toggle="dropdown"
                @click="Acknowledged()"
                style="
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  border-radius: 30px;
                  height: 30px;
                  padding: 8px 16px;
                  font-size: 16px;
                "
              >
                <i class="bi bi-check-circle-fill"></i> Acknowledged list
              </button>

              <div
                class="dropdown-menu p-3"
                style="
                  width: 300px;
                  border-radius: 8px;
                  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
                  max-height: 450px;
                  overflow-y: scroll;
                "
              >
                <ul class="list-group" style="font-size: small">
                  <li
                    v-for="employee in AcknowledgedList"
                    :key="employee.id"
                    class="list-group-item"
                    style="cursor: pointer; display: flex; justify-content: space-between"
                  >
                    {{ employee.firstName }} {{ employee.lastName }}
                    <i
                      v-if="employee.acknowledged == 'Yes'"
                      class="bi bi-hand-thumbs-up text-primary"
                    ></i>
                  </li>
                </ul>
              </div>
            </div>

            <button
              class="btn btn-primary"
              v-if="
                NotesData.isAcknowledged === 'No' &&
                NotesData.createdBy !== this.$LocalUser.user.employeeId
              "
              style="
                display: flex;
                align-items: center;
                gap: 8px;
                border-radius: 30px;
                height: 30px;
                padding: 8px 16px;
                font-size: 16px;
              "
              @click="Acknowledge('Yes')"
            >
              Acknowledge
            </button>

            <!-- Show "Acknowledged" button if it has already been acknowledged -->
            <button
              class="btn btn-success"
              v-if="
                NotesData.isAcknowledged === 'Yes' &&
                NotesData.createdBy !== this.$LocalUser.user.employeeId
              "
              style="
                display: flex;
                align-items: center;
                gap: 8px;
                border-radius: 30px;
                height: 30px;
                padding: 8px 16px;
                font-size: 16px;
              "
              @click="Acknowledge('No')"
            >
              Acknowledged
            </button>

            <button class="btn btn-outline-primary btn-sm" @click="downloadPDF">
              Download
            </button>

            <button
              class="btn btn-outline-danger btn-sm"
              @click="checkAndDelete(this.noteId)"
              v-if="NotesData.createdBy === this.$LocalUser.user.employeeId"
            >
              Delete
            </button>
            <!-- <button
              class="btn btn-outline-danger btn-sm"
              @click="checkAndDelete(this.noteId)"
              :disabled="!isDeleteAllowed"
              v-else
            >
              Delete
            </button> -->
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
        </div>

        <div class="modal-body d-flex">
          <!-- Left Section -->
          <div class="col-md-8 d-flex flex-column left-section">
            <textarea
              class="form-control description-area flex-grow-1"
              placeholder=""
              rows="10"
              v-model="NotesData.description"
            ></textarea>

            <div class="buttons">
              <button class="btn btn-primary mt-3 save-btn" @click="UpdateNote">
                Update
              </button>
              <!-- <button class="btn btn-primary mt-3 save-btn">Save</button> -->
            </div>
          </div>

          <div class="col-md-4 right-section">
            <!-- Tab Buttons -->
            <div class="d-flex gap-2 justify-content-between flex-wrap">
              <button
                class="btn btn-outline-primary btn-sm"
                :class="{ active: activeTab === 'attachments' }"
                @click="toggleTab('attachments')"
              >
                Attachments
              </button>
              <button
                class="btn btn-outline-primary btn-sm"
                :class="{ active: activeTab === 'comments' }"
                @click="toggleTab('comments')"
              >
                Comments
              </button>
              <button
                class="btn btn-outline-primary btn-sm"
                :class="{ active: activeTab === 'history' }"
                @click="toggleTab('history')"
              >
                History
              </button>
            </div>

            <div v-show="activeTab === 'attachments'" class="Attachment">
              <div class="attachment-section" style="max-height: 400px">
                <div class="Attachment-card" v-for="data in Attachments" :key="data.id">
                  <div class="card-header">
                    <img
                      class="attachment-icon"
                      src="../../assets/icons/pdfIcon.png"
                      alt="Attachment Icon"
                    />
                  </div>
                  <div
                    class="card-body"
                    @click="
                      $downloadFile(
                        data.awsFileName,
                        data.fileName,
                        'notesAttachment',
                        'notes'
                      )
                    "
                  >
                    <strong class="strong">{{ data.fileName }}</strong>
                    <span>SIZE: {{ data.fileSize }}</span>
                  </div>
                  <div class="card-footer">
                    <img
                      v-if="NotesData.createdBy === this.$LocalUser.user.employeeId"
                      class="delete-icon"
                      src="../../assets/icons/redDelete.png"
                      @click="deleteAttachment(data.id)"
                    />
                  </div>
                </div>
              </div>

              <!-- Plus icon to trigger file upload -->
              <div class="Plus" @click="triggerFileUpload">
                <img class="profile" src="../../assets/icons/plusGreyIcon.png" />
              </div>

              <!-- Hidden File Input -->
              <input
                type="file"
                ref="notesAttachment"
                @change="handleFileUpload"
                style="display: none"
              />
            </div>

            <div v-show="activeTab === 'comments'" class="mt-2 comment-section">
              <!-- Scrollable Comments Section -->
              <div class="comments-container">
                <div v-for="item in comments" :key="item.id" class="parent-comment">
                  <div class="profile-section">
                    <div>
                      <img
                        class="profile"
                        v-if="item['employees.signedUrl' !== null]"
                        :src="item['employees.signedUrl']"
                        alt="Profile Image"
                      />
                      <img
                        class="profile"
                        v-else
                        src="../../assets/icons/whiteProfile.png"
                        alt="Profile Image"
                      />
                    </div>
                    <div class="name">
                      <strong>
                        {{ item["employees.firstName"] }} {{ item["employees.lastName"] }}
                      </strong>
                      <span>{{ $formatDateDMY(item.created_at) }}</span>
                    </div>
                  </div>
                  <p>{{ item.comment }}</p>
                  <div class="reply-section">
                    <button class="reply-button" @click="storeCommentId(item.id)">
                      <span>
                        <small>Reply</small>
                      </span>
                    </button>
                    <div>
                      <img
                        class="delete"
                        @click="deleteNoteComment(item.id)"
                        src="../../assets/icons/redDelete.png"
                      />
                    </div>
                  </div>

                  <!-- Child Comments -->
                  <div
                    v-if="item.childComments && item.childComments.length"
                    class="child-comments"
                  >
                    <div
                      v-for="child in item.childComments"
                      :key="child.id"
                      class="child-comment"
                    >
                      <!-- Child Comment Content -->
                      <div class="profile-section">
                        <div>
                          <img
                            class="profile"
                            v-if="item['employees.signedUrl' !== null]"
                            :src="item['employees.signedUrl']"
                            alt="Profile Image"
                          />
                          <img
                            class="profile"
                            v-else
                            src="../../assets/icons/whiteProfile.png"
                            alt="Profile Image"
                          />
                        </div>
                        <div class="name">
                          <strong>
                            {{ child["employees.firstName"] }}
                            {{ child["employees.lastName"] }}
                          </strong>
                          <span>{{ $formatDateDMY(child.created_at) }}</span>
                        </div>
                      </div>
                      <p>{{ child.comment }}</p>
                      <div class="reply-section">
                        <!-- <button class="reply-button" @click="storeCommentId(item.id)">
                          <span>
                            <small>reply</small>
                          </span>
                        </button> -->
                        <div>
                          <img
                            class="delete"
                            @click="deleteNoteComment(child.id)"
                            src="../../assets/icons/redDelete.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Fixed Reply Box -->
              <div class="reply-box">
                <textarea
                  v-model="comment"
                  :placeholder="
                    parentCommentId ? 'Write your reply...' : 'Write a new comment...'
                  "
                  class="reply-input"
                ></textarea>
                <div class="reply-box-buttons">
                  <button
                    v-if="parentCommentId"
                    @click="cancelReply"
                    class="cancel-reply-button"
                  >
                    Cancel
                  </button>
                  <button
                    @click="parentCommentId ? sendComment() : sendComment()"
                    class="send-button"
                  >
                    {{ parentCommentId ? "Reply" : "Send" }}
                  </button>
                </div>
              </div>
            </div>

            <div v-show="activeTab === 'history'" class="mt-2 history-section">
              <div v-for="item in history" :key="item.id">
                <div class="profile-section">
                  <div>
                    <div>
                      <img
                        class="profile"
                        v-if="item['employees.signedUrl' !== null]"
                        :src="item['employees.signedUrl']"
                        alt="Profile Image"
                      />
                      <img
                        class="profile"
                        v-else
                        src="../../assets/icons/whiteProfile.png"
                        alt="Profile Image"
                      />
                    </div>
                  </div>

                  <div class="name">
                    <strong>{{ item.firstName }} {{ item.lastName }}</strong>
                  </div>
                  <div class="date">Edited {{ $formatDateDMY(item.date) }}</div>
                </div>
                <p>
                  {{ item.description }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <NotesPdfModal ref="pdfModal" v-if="showPdfModal" :notesData="NotesData" />
</template>

<script>
import axios from "axios";
import "bootstrap-icons/font/bootstrap-icons.css";
import NotesPdfModal from "./NotesPdfModal.vue";

export default {
  components: {
    NotesPdfModal,
  },

  props: {
    noteId: {
      type: [String, Number],
      required: false,
    },
    folderId: {
      type: [Number],
      required: false,
    },
  },

  mounted() {
    this.fetchNoteHistory();
  },

  data() {
    return {
      NotesData: {
        id: null,
        header: "",
        description: "",
        folderId: "",
        created_at: "",
        updated_at: "",
        collaborators: [],
        searchString: "",
        awsFileId: null,
        fileName: null,
      },
      showPdfModal: false,
      history: [],
      comments: [],
      Attachments: [],
      employees: [],
      selectedEmployees: [],
      invitedEmployees: [], // Employees after clicking invite
      AcknowledgedList: [],
      activeTab: "attachments",
      parentCommentId: "" || 0,
      comment: "",
      isDeleteAllowed: false,
      showDropdown: false,
    };
  },

  watch: {
    noteId: {
      immediate: true, // Trigger when the component is created
      handler(newValue) {
        if (newValue) {
          this.fetchNotesDetails(newValue); // Corrected the function name
          this.fetchNoteHistory(newValue);
          this.fetchNoteComments(newValue);
          this.fetchNoteAttachments(newValue);
        }
      },
    },
  },

  methods: {
    downloadPDF() {
      this.showPdfModal = true; // Show the modal first
      this.$nextTick(() => {
        // Wait for the modal to be rendered
        if (this.$refs.pdfModal && this.NotesData) {
          this.$refs.pdfModal.generatePDF();
        } else {
          console.error("PDF Modal reference or notesData is missing!");
        }
        this.showPdfModal = false;
      });
    },
    closeNoteModal() {
      document.getElementById("viewNote").classList.remove("show");
      document.getElementById("viewNote").style.display = "none";
      document.body.classList.remove("modal-open");
      const backdrop = document.querySelector(".modal-backdrop");
      if (backdrop) backdrop.remove();
    },

    async deleteAttachment(id) {
      const payload = {
        fileCategory: "notesAttachment",
        moduleName: "notes",
        id: id,
      };
      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/company/deleteFile`,
          payload
        );
        this.fetchNoteAttachments(this.noteId);
        if (response.data && response.data.success) {
          console.log("File deleted successfully");
        } else {
          console.error("Failed to delete file", payload);
        }
      } catch (error) {
        console.error("Error occurred while deleting the file:", error);
      }
    },

    checkAndDelete(noteId) {
      this.deleteNote(noteId);
    },

    async markFav() {
      try {
        await axios.post(`${this.$apiEndPoint}/notes/manageStared/${this.noteId}/Yes`);
        this.fetchNotesDetails(this.noteId);
        this.UpdateNote();
      } catch (error) {
        console.error("Error marking note as favorite:", error);
        this.$toast("Failed to mark as favorite", "error");
      }
    },

    async markUnFav() {
      try {
        await axios.post(`${this.$apiEndPoint}/notes/manageStared/${this.noteId}/No`);
        this.fetchNotesDetails(this.noteId);
        this.UpdateNote();
      } catch (error) {
        console.error("Error marking note as Unfavorite:", error);
        this.$toast("Failed to mark as Unfavorite", "error");
      }
    },

    triggerFileUpload() {
      this.$refs.notesAttachment.click();
    },

    handleFileUpload() {
      const file = this.$refs.notesAttachment.files[0];
      console.log(file);
      if (file) {
        axios
          .post(`${this.$apiEndPoint}/company/putFileSignedURL`, {
            fileName: file.name,
            fileSize: file.size,
            contentType: file.type,
            fileCategory: "notesAttachment",
            moduleName: "notes",
            entityId: this.noteId,
            entityName: "notes",
          })
          .then((response) => {
            const signedUrl = response.data.s3URL;
            this.NotesData.awsFileId = response.data.id; // Update awsFileId
            this.NotesData.fileName = file.name; // Update fileName
            console.log(this.NotesData);
            console.log(response.data);
            const xhr = new XMLHttpRequest();
            xhr.open("PUT", signedUrl, true);
            xhr.setRequestHeader("Content-Type", file.type);

            xhr.onreadystatechange = () => {
              if (xhr.readyState === 4 && xhr.status === 200) {
                console.log("File uploaded successfully");
              } else if (xhr.readyState === 4) {
                console.error("Error uploading file to S3:", xhr.responseText);
              }
            };
            xhr.send(file);
            this.fetchNoteAttachments(this.noteId);
          })
          .catch((error) => {
            this.$handleError(error);
          });
        this.UpdateNote();
      }
    },

    async fetchCollaborators() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/noteCollaborator/getNoteCollaborator/${this.noteId}`
        );
        this.invitedEmployees = response.data; // Load already invited employees
        console.log("Fetched collaborators:", this.invitedEmployees);
      } catch (error) {
        console.error("Error fetching collaborators", error);
      }
    },

    async fetchEmployees() {
      if (this.searchString.trim() === "") {
        this.employees = [];
        return;
      }
      try {
        const response = await axios.post(`${this.$apiEndPoint}/employee/getBySearch`, {
          searchString: this.searchString,
        });
        this.employees = response.data;
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    },

    async Acknowledged() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/noteCollaborator/getNoteCollaborator/${this.noteId}`
        );
        this.AcknowledgedList = response.data;
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    },

    async Acknowledge(acknowledgedValue) {
      try {
        const response = await axios.put(
          `${this.$apiEndPoint}/noteCollaborator/acknowledge/${this.noteId}/${this.$LocalUser.user.employeeId}`,
          {
            acknowledged: acknowledgedValue,
          }
        );
        this.fetchNotesDetails(this.noteId);
        console.log("Success:", response.data);
        return response.data; // Return the response if needed
      } catch (error) {
        console.error("Error:", error.response ? error.response.data : error.message);
        throw error; // Re-throw the error for further handling
      }
    },

    selectEmployee(employee) {
      if (!this.selectedEmployees.some((e) => e.id === employee.id)) {
        this.selectedEmployees.push(employee);
        console.log(this.selectedEmployees);
      }
      this.searchString = "";
      this.employees = [];
      console.log("Selected employees:", this.selectedEmployees);
    },

    async createCollaborator() {
      if (this.selectedEmployees.length === 0) return; // Prevent empty requests

      const selectedEmployeeIds = this.selectedEmployees.map((e) => e.id);

      const payload = {
        employeeId: selectedEmployeeIds,
        notesId: this.noteId,
      };

      console.log("Sending employee IDs:", payload);

      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/noteCollaborator/createCollaborator`,
          payload
        );
        console.log("Collaborator created successfully", response.data);

        this.invitedEmployees = [...this.invitedEmployees, ...this.selectedEmployees];
        this.selectedEmployees = [];
        this.fetchNotesDetails(this.noteId);

        this.UpdateNote();
      } catch (error) {
        console.error("Error creating collaborator", error);
      }
    },
    async removeEmployee(employeeId) {
      try {
        // Remove from backend
        console.log(" selectedEmployees:", this.selectedEmployees);
        await axios.put(
          `${this.$apiEndPoint}/noteCollaborator/removeAccess/${this.noteId}/${employeeId}`
        );

        this.invitedEmployees = this.invitedEmployees.filter((e) => e.id !== employeeId);
        this.selectedEmployees = this.selectedEmployees.filter(
          (e) => e.id !== employeeId
        );

        console.log("Updated invitedEmployees:", this.invitedEmployees);
        console.log("Updated selectedEmployees:", this.selectedEmployees);
        this.fetchNotesDetails(this.noteId);
        this.UpdateNote();
        this.fetchCollaborators();
      } catch (error) {
        console.error("Error removing collaborator", error);
      }
    },

    storeCommentId(id) {
      this.parentCommentId = id;
      console.log("the storing commentid:", this.parentCommentId);
      console.log("the notId is:", this.noteId);
    },

    deleteNote(noteId) {
      this.$emit("delete-note", noteId);
      this.closeNoteModal();
    },

    async deleteNoteComment(id) {
      try {
        console.log("Deleting comment with ID:", id);

        const response = await axios.delete(
          `${this.$apiEndPoint}/noteComment/deleteNoteComment/${id}`
        );
        console.log("API Response:", response);

        this.fetchNoteComments(this.noteId);
        this.$toast("Comment Deleted", "success");
      } catch (error) {
        console.error("Error deleting comment:", error);
      }
    },

    cancelReply() {
      this.parentCommentId = null; // Switch back to new comment mode
      this.replyText = ""; // Clear the text area
    },

    addNewComment() {
      if (this.replyText.trim()) {
        console.log(`Adding new comment: ${this.replyText}`);
        this.replyText = "";
      }
    },

    async fetchNotesDetails(id) {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/notes/getNoteById/${id}`);
        this.NotesData = response.data;
        console.log("Fetched Notes Details:", this.NotesData);
      } catch (error) {
        console.error("Error fetching Notes Details:", error);
      }
    },

    async fetchNoteHistory(id) {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/noteHistory/getByNoteId/${id}`
        );
        this.history = response.data;
        console.log("Fetched Notes history:", this.history);
      } catch (error) {
        console.error("Error fetching Notes history:", error);
      }
    },

    async fetchNoteComments(id) {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/noteComment/getNoteComments/${id}`
        );
        this.comments = response.data;
        console.log("Fetched Notes comments:", this.comments);
      } catch (error) {
        console.error("Error fetching Notes comments:", error);
      }
    },

    async fetchNoteAttachments(id) {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/notesAttachments/${id}`);
        this.Attachments = response.data;
        console.log("Fetched Notes Attachments:", this.Attachments);
      } catch (error) {
        console.error("Error fetching Notes comments:", error);
      }
    },

    async sendComment() {
      try {
        const parentNoteCommentId = this.parentCommentId || 0;

        await axios.post(`${this.$apiEndPoint}/noteComment/createComment`, {
          notesId: this.noteId,
          comment: this.comment,
          parentNoteCommentId: parentNoteCommentId,
        });

        console.log(
          parentNoteCommentId
            ? `Reply added to comment ID ${parentNoteCommentId}:`
            : "New comment added:",
          this.noteId,
          this.comment
        );

        this.$toast(
          parentNoteCommentId
            ? "Reply added successfully"
            : "Comment created successfully",
          "success"
        );

        this.fetchNoteComments(this.noteId);
        this.comment = "";
        this.parentCommentId = null;
      } catch (error) {
        this.$toast("Failed to create a comment", "danger");
        console.error("Failed to create a comment:", error);
      }
    },
    UpdateNote() {
      const updatedNote = {
        id: this.NotesData.id,
        header: this.NotesData.header,
        description: this.NotesData.description,
        folderId: this.NotesData.folderId,
      };

      this.$emit("update-note", updatedNote);
      this.$emit("updating-note", updatedNote);

      setTimeout(() => {
        this.fetchNotesDetails(this.noteId);
      }, 100);
    },

    toggleTab(tabName) {
      this.activeTab = tabName;
      if (this.activeTab == "history") {
        this.fetchNoteHistory(this.noteId);
      } else if (this.activeTab == "comments") {
        this.fetchNoteComments(this.noteId);
      } else if (this.activeTab == "attachments") {
        this.fetchNoteAttachments(this.noteId);
      }
    },
  },
};
</script>

<style scoped>
p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-wrap: break-word;
  max-width: 100%;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.6; /* Dim the button */
}

.collaborator-container {
  position: relative;
  display: flex;
  align-items: center;
}

.collaborator-initials {
  font-size: 12px;
  background-color: #4884f7;
  border-radius: 50%;
  color: #fcfcfc;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-left: -8px;
  border: 2px solid #fff;
}

.collaborator-count {
  color: #333;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 12px;
}

.custom-modal {
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  height: 90%; /* Adjust modal height */
}

.custom-modal-dialog {
  max-width: 80%;
  width: 90%;
  height: 90%;
}

/* Modal Header */
.custom-modal .modal-header {
  border-bottom: 1px solid rgb(78, 78, 78);
  font-size: 18px;
}

.modal-title {
  font-size: 16px;
}

.star-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: transform 0.2s ease;
}

/* Left Section */
.left-section {
  border-right: 1px solid #e6e6e6;
  padding-right: 15px;
  height: 100%; /* Ensure full height for the left section */
}

.description-area {
  resize: none;
  border: none;
}
.buttons {
  gap: 50px;
  width: 100%;
}
/* Right Section */
.right-section {
  height: 100%; /* Ensure the right section fills available height */
  padding-left: 15px;
}

.right-section .d-flex {
  flex-wrap: wrap;
  border: 1px solid gainsboro;
  padding: 6px;
  border-radius: 30px;
  gap: 10px;
}

.right-section button {
  font-size: 12px;
  padding: 6px;
  border: none;
}

.note-area {
  flex-grow: 1; /* Make the note area take available space */
  min-height: 100px;
  resize: none;
  border: none;
}

.right-section .add-btn {
  width: 40px;
  height: 40px;
  font-size: 20px;
  text-align: center;
  border-radius: 50%;
  background-color: #007bff;
  border: none;
  color: white;
  align-self: flex-end;
}

/* Save Button */
.save-btn {
  background-color: #007bff;
  border: none;
  padding: 8px 12px;
  font-size: 14px;
}

/* Small Buttons */
.btn-sm {
  font-size: 12px;
  padding: 5px 10px;
}

.tab-pane {
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  margin-top: 10px;
  width: 80%;
}

.strong {
  display: block;
}
.btn-outline-primary {
  transition: background-color 0.3s, color 0.3s;
  border-radius: 28px;
}

.btn-outline-danger {
  transition: background-color 0.3s, color 0.3s;
  border-radius: 28px;
}

.btn-outline-primary.active {
  background-color: #0d6efd;
  color: white;
  border-color: #0d6efd;
}

.profile-section {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.Attachment-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  justify-content: space-between;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  margin: 10px;
  width: 300px; /* Set a fixed width */
  max-width: 100%; /* Ensure responsiveness */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  word-wrap: break-word; /* Ensure long text wraps */
}

.card-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.card-body .strong {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* Truncate long file names */
  max-width: 90%;
  display: block;
}

.Attachment-card:hover {
  transform: translateY(-5px); /* Slightly lift the card on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Add a stronger shadow on hover */
  border-color: #aaa; /* Change border color slightly */
}

.Attachmnet-section {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 0px;
}

.Attachment {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  max-height: 100%;
  overflow-y: auto;
}

.profile {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid goldenrod;
}
.attachment {
  border: none;
}
.Plus {
  margin-top: 28px;
}
.name {
  display: flex;
  flex-direction: column;
}

.name strong {
  font-size: 14px;
  font-weight: 400;
}

.name span {
  font-size: x-small;
  color: gray;
}

.date {
  margin-left: auto;
  font-size: smaller;
  color: gray;
}

.history-section {
  max-height: 400px;
  overflow-y: auto;
  font-size: 12px;
}

.comment-section {
  max-height: 500px;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative; /* For sticky positioning */
}

.comments-container {
  flex: 1; /* Ensures it fills available space above the reply box */
  overflow-y: auto; /* Allows scrolling within the comments */
  padding: 10px; /* Padding around comments */
  max-height: 300px;
  font-size: 12px;
}
/* For Webkit Browsers (Chrome, Edge, Safari) */
::-webkit-scrollbar {
  width: 2px; /* Thin width */
  height: 2px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3); /* Color of the scrollbar */
  border-radius: 10px; /* Rounded edges */
}

::-webkit-scrollbar-track {
  background: transparent; /* Hide track background */
}

.parent-comment {
  padding-bottom: 8px;
}

.child-comments {
  margin-left: 70px;
  padding-left: 10px;
}

.reply-section {
  margin-left: 40px;
  display: flex;
  gap: 50px;
}

.reply-box {
  position: sticky; /* Fixed at the bottom of the comment section */
  bottom: 0;
  background-color: #fff;
  border-top: 1px solid #ddd;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1;
}

.reply-box-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 40px;
}

.reply-button {
  border-radius: 14px;
}

.reply-input {
  width: 100%;
  min-height: 60px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
}

.cancel-reply-button {
  padding: 5px 10px;
  background-color: #f44336;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-reply-button:hover {
  background-color: #d32f2f;
}

.send-button {
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.send-button:hover {
  background-color: #0056b3;
}

p {
  margin-bottom: 0px;
  font-size: smaller;
  margin-left: 40px;
}

.back-img {
  width: 15px;
  height: 15px;
}

.delete {
  width: 15px;
  height: 15px;
}
</style>
