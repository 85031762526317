<template>
  <div>
    <div
      class="modal fade"
      id="PRDetailsModal"
      tabindex="-1"
      aria-labelledby="PRDetailsModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-fullscreen">
        <div class="modal-content d-flex flex-column">
          <div class="modal-header d-flex justify-content-between">
            <h5
              class="modal-title text-decoration-underline"
              id="PRDetailsModalLabel"
              style="visibility: hidden"
            >
              Purchase Request
            </h5>
            <div class="d-flex align-content-center">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                data-toggle="tooltip"
                data-placement="top-start"
                title="close"
              ></button>
            </div>
          </div>
          <div class="modal-body d-flex flex-column PrDetailsModal" ref="printContent">
            <div class="content-section flex-grow-1">
              <p class="text-center" style="font-size: 18px; font-weight: 600">
                Purchase Request
              </p>
              <section style="margin-top: 20px">
                <div class="row mb-3">
                  <div
                    class="company-details d-flex flex-wrap"
                    style="width: 55%; font-weight: 600; text-align: start"
                  >
                    <strong style="flex-basis: 100%">{{
                      CompanyData.companyName
                    }}</strong>
                    <div style="flex-basis: 100%">
                      {{ CompanyData.address }}, {{ CompanyData.city }}
                    </div>
                    <div style="flex-basis: 100%">
                      {{ CompanyData.state }}-{{ CompanyData.pincode }}
                    </div>
                    <div style="flex-basis: 100%">
                      GST No. :-{{ CompanyData.gstNumber }}
                    </div>

                    <div style="flex-basis: 100%">
                      {{ PurchaseRequest["employees.mobileNumber"] }}
                    </div>
                    <div style="flex-basis: 100%">
                      {{ PurchaseRequest["employees.employeeEmailId"] }}
                    </div>
                  </div>

                  <div class="company-logo">
                    <img
                      v-if="$CompanyLogoUrl"
                      :src="$CompanyLogoUrl"
                      alt="Company Logo"
                      id="companylogoInput"
                    />
                  </div>
                </div>
                <div class="purchase-request-info d-flex flex-wrap">
                  <div class="info-item" style="width: 56%; margin-bottom: 10px">
                    Project Name: {{ PurchaseRequest["projects.projectName"] }}
                  </div>
                  <div class="info-item" style="width: 44%; margin-bottom: 10px">
                    PR Number: {{ PurchaseRequest.prNumber }}
                  </div>
                  <div class="info-item" style="width: 56%; margin-bottom: 10px">
                    Requested By: {{ PurchaseRequest["employees.firstName"] }}
                    {{ PurchaseRequest["employees.lastName"] }}
                  </div>
                  <div class="info-item" style="width: 44%; margin-bottom: 10px">
                    Date: {{ $formatDateDMY(PurchaseRequest.date) }}
                  </div>
                </div>

                <div class="table-responsive">
                  <table class="table table-bordered">
                    <caption class="d-none">
                      Product Details
                    </caption>
                    <thead>
                      <tr>
                        <th>Sl.No</th>
                        <th>Product Name (Description)</th>
                        <th>Quantity</th>
                        <th>Unit Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in lineItems" :key="item.id">
                        <td>{{ index + 1 }}</td>

                        <td>
                          <img
                            v-if="item.awsFileId"
                            :src="
                              item.signedUrl
                                ? item.signedUrl
                                : '../../../assets/icons/profile.png'
                            "
                            style="
                              width: 150px;
                              height: 150px;
                              object-fit: cover;
                              image-rendering: auto;
                            "
                            class="m-3"
                            alt=""
                          />

                          {{ item.productName }} ({{ item.description }})
                        </td>
                        <td style="text-align: right">{{ item.quantity }}</td>
                        <td>{{ item.unitType }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
            </div>
            <div class="footer-section mt-auto">
              <pre
                v-if="PurchaseRequest.comments && PurchaseRequest.comments.trim() !== ''"
                >{{ PurchaseRequest.comments }}</pre
              >

              <table class="w-100">
                <caption class="d-none"></caption>

                <tbody>
                  <tr class="d-none">
                    <th class="text-start">Vendor Signature</th>
                    <th class="text-end">Authority Signature</th>
                  </tr>
                  <tr>
                    <td class="text-start">Vendor Signature</td>
                    <td class="text-end">Authority Signature</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import html2pdf from "html2pdf.js";
import { Filesystem, Directory, Encoding } from "@capacitor/filesystem";
import { Capacitor } from "@capacitor/core";
import { jsPDF } from "jspdf";

export default {
  name: "PRDetailsModal",
  props: {
    PurchaseRequestId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      CompanyLogo: null,
      lineItems: [],
      PurchaseRequest: {},
      CompanyData: {},
    };
  },
  watch: {
    PurchaseRequestId(newVal) {
      this.fetchCompanyData();
      this.fetchPurchaseOrderById(newVal);
    },
  },

  methods: {
    async downloadPDF() {
      const contentToPrint = this.$refs.printContent;

      if (!contentToPrint) {
        console.error("printContent not found");
        return;
      }

      // Save the original styles
      const originalStyles = {
        width: contentToPrint.style.width,
        margin: contentToPrint.style.margin,
        border: contentToPrint.style.border,
        padding: contentToPrint.style.padding,
      };

      // Apply temporary styles
      contentToPrint.style.width = "100%";
      contentToPrint.style.margin = "0";
      contentToPrint.style.border = "none";
      contentToPrint.style.padding = "5%";

      // Wait for styles to apply
      await new Promise((resolve) => setTimeout(resolve, 100));

      const fileName = `Purchase_Request_${this.PurchaseRequest.prNumber}.pdf`;

      const doc = new jsPDF({
        orientation: "p",
        unit: "mm",
        format: "a4",
      });

      doc.html(contentToPrint, {
        callback: async (doc) => {
          if (Capacitor.isNativePlatform()) {
            // Convert PDF to Blob
            const pdfBlob = doc.output("blob");
            const reader = new FileReader();
            reader.readAsDataURL(pdfBlob);
            reader.onloadend = async () => {
              const base64Data = reader.result.split(",")[1]; // Get base64 data

              try {
                await Filesystem.writeFile({
                  path: `PurchaseRequests/${fileName}`,
                  data: base64Data,
                  directory: Directory.Documents, // Save in device Documents folder
                  encoding: Encoding.Base64,
                });

                alert(`PDF saved in Documents folder: ${fileName}`);
              } catch (error) {
                console.error("Error saving file:", error);
                alert("Failed to save PDF.");
              }
            };
          } else {
            // For Web: Directly save the file
            doc.save(fileName);
          }

          // Restore original styles
          Object.assign(contentToPrint.style, originalStyles);
        },
        margin: [5, 0, 5, 0],
        width: 210,
        windowWidth: window.innerWidth,
        autoPaging: "text",
      });
    },
    downloadPDFs() {
      const contentToPrint = this.$refs.printContent;

      // Save the original styles
      const originalWidth = contentToPrint.style.width;
      const originalMargin = contentToPrint.style.margin;
      const originalBorder = contentToPrint.style.border;
      const originalPadding = contentToPrint.style.padding;

      // Temporarily change the styles for PDF generation
      setTimeout(() => {
        contentToPrint.style.width = "100%";
        contentToPrint.style.margin = "0%";
        contentToPrint.style.border = "none";
        contentToPrint.style.padding = "5%";

        const fileName = `Purchase_Request_${this.PurchaseRequest.prNumber}.pdf`;
        html2pdf()
          .from(contentToPrint)
          .set({
            filename: fileName,
            margin: [5, 0, 5, 0],
            scale: 2,
            jsPDF: {
              format: "a4",
              unit: "mm",
              // orientation: "portrait",
            },
            pagebreak: {
              mode: ["avoid-all", "css", "legacy"],
            },
            // html2canvas: {
            //   useCORS: true,
            //   scale: 2,
            //   logging: true,
            //   letterRendering: true,
            //   svgRendering: true,
            // },
          })
          .save()
          .then(() => {
            // Restore the original styles after the PDF is generated
            contentToPrint.style.width = originalWidth;
            contentToPrint.style.margin = originalMargin;
            contentToPrint.style.border = originalBorder;
            contentToPrint.style.padding = originalPadding;
          });
      }, 0);
    },
    async fetchPurchaseOrderById(PurchaseRequestId) {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/purchaseRequest/prById/${PurchaseRequestId}`
        );
        this.lineItems = response.data.lineitems;
        this.PurchaseRequest = response.data;
        for (let row of this.lineItems) {
          this.fetchInfoImage(row["awsFiles.awsFileName"], row["awsFiles.fileName"]).then(
            (signedUrl) => {
              if (signedUrl) {
                row.signedUrl = signedUrl;
              }
            }
          );
        }
      } catch (error) {
        // this.$handleError(error);
        console.log(error);
      }
    },
    async fetchInfoImage(AwsFileName, FileName) {
      const payload = {
        awsFileName: AwsFileName,
        fileCategory: "procurementProduct",
        moduleName: "procurement",
        fileName: FileName,
      };

      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/company/getFileSignedURL/`,
          payload
        );
        const signedUrl = Object.values(response.data)[0];

        // Convert the signed URL to Base64 using the global function
        const base64Image = await this.$convertImageToBase64(signedUrl);

        // Return the Base64 string or store it as needed
        return base64Image;
      } catch (error) {
        this.$handleError("Error fetching signed URL:", error);
        return null;
      }
    },

    fetchCompanyData() {
      const companyId = this.$LocalUser.user.companyId;
      axios
        .get(`${this.$apiEndPoint}/company/${companyId}`)
        .then((response) => {
          this.CompanyData = response.data;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
  },
};
</script>

<style scoped>
.modal-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.modal-fullscreen {
  margin-left: 0%;
  width: 100%;
}
.modal-body {
  width: 60%;
  margin: 0 20%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  border: 1px solid #000000;
}

.table-bordered th {
  background-color: #ccc;
}

pre {
  display: block;
  margin: 10px;
  text-align: left;
  width: 80%;
  min-height: 100px;
  max-height: auto;
  border: 1px solid #000;
  border-radius: 10px;
  padding: 10px;
  word-break: break-all;
  white-space: wrap;
}

#companylogoInput {
  min-height: 80px;
  max-height: 80px;
  object-fit: cover;
  image-rendering: auto;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}

.mt-auto {
  margin-top: auto;
}

.content-section {
  flex-grow: 1; /* This will allow the content section to take up remaining space */
}

.footer-section {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  margin-top: auto; /* Pushes the footer to the bottom */
  width: 100%;
  box-sizing: border-box;
}

.company-logo {
  width: 45%;
  text-align: start;
}
.table-bordered {
  border-collapse: collapse;
}

.table-bordered tbody td,
.table-bordered tbody th {
  border: 1px solid #ccc;
}

@media screen and (max-width: 768px) {
  .modal-content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .modal-fullscreen {
    margin-left: 0%;
    width: 100%;
  }
  .modal-body {
    width: 100%;
    margin: 0%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    border: 1px solid #000000;
  }

  .table-bordered th {
    background-color: #ccc;
  }

  pre {
    display: block;
    margin: 10px;
    text-align: left;
    width: 80%;
    min-height: 100px;
    max-height: auto;
    border: 1px solid #000;
    border-radius: 10px;
    padding: 10px;
    word-break: break-all;
    white-space: wrap;
  }

  .company-logo {
    padding: 10px;
  }
  #companylogoInput {
    height: 80px;
    max-height: 80px;
    width: 90%; /* You can adjust this for responsiveness */
    object-fit: contain; /* Ensures the entire image is visible */
    image-rendering: auto;
    border-radius: 5px;
    display: block; /* Ensures proper rendering as a block-level element */
    margin: 0 auto; /* Centers the image within its container */
  }

  .mt-auto {
    margin-top: auto;
  }

  .content-section {
    flex-grow: 1; /* This will allow the content section to take up remaining space */
  }

  .footer-section {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    margin-top: auto; /* Pushes the footer to the bottom */
    width: 100%;
    box-sizing: border-box;
    border-top: 1px solid #ccc; /* Optional: to create separation from content */
  }
}
</style>
