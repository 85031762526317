import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export const showToast = (message, type) => {
  // Define autoClose duration based on toast type
  const autoCloseDuration = type === "error" ? 5000 : 3000; // Longer duration for errors

  toast(message, {
    theme: "auto",
    type: type,
    autoClose: autoCloseDuration,
    position: "bottom-right",
    transition: "bounce",
    dangerouslyHTMLString: true,
  });
};

// https://vue3-toastify.js-bridge.com/ the Toast source code.