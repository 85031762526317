<template>
  <div
    class="modal fade position-absolute"
    id="HistoryModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="HistoryModal"
    aria-hidden="true"
  >
    <div class="modal-dialog custom-modal-width modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header d-flex justify-content-between">
          <h5 class="modal-title">History</h5>
          <button
            type="button"
            class="btn-close text-primary"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body comment-body">
          <div v-if="History.length">
            <div v-for="comment in History" :key="comment.id" class="parent-comment">
              <div class="comment-header">
                <div class="comment-avatar-container">
                  <span
                    class="comment-avatar"
                    :style="{
                      backgroundColor: getColor(comment['employees.firstName']),
                    }"
                  >
                    {{ comment["employees.initials"] }}
                  </span>
                </div>
                <div class="comment-information">
                  <div class="comment-info">
                    <strong class="comment-author">
                      {{ comment["employees.firstName"] }}
                      {{ comment["employees.lastName"] }}
                    </strong>
                    <small class="comment-date">{{
                      formatDate(comment.updated_at)
                    }}</small>
                  </div>
                </div>
              </div>

              <!-- Show comment text with line breaks -->
              <pre class="comment-text">{{ comment.text }}</pre>
            </div>
          </div>
          <div v-else>
            <p>No history available.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    ProjectInfoBoxId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      History: [],
    };
  },
  watch: {
    ProjectInfoBoxId: {
      immediate: true, // Fetch comments on initial load
      handler(newId) {
        if (newId) {
          this.fetchHistory(newId);
        }
      },
    },
  },
  methods: {
    getColor(name) {
      const colors = [
        "#FF5733",
        "#33FF57",
        "#3357FF",
        "#FF33A1",
        "#F0A500",
        "#9400D3",
        "#20B2AA",
        "#FF6347",
        "#8A2BE2",
        "#5F9EA0",
      ];
      const hash = [...name].reduce((acc, char) => acc + char.charCodeAt(0), 0);
      return colors[hash % colors.length];
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const now = new Date();
      const diff = now - date;

      const seconds = Math.floor(diff / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      const months = Math.floor(days / 30);
      const years = Math.floor(months / 12);

      if (seconds < 60) return "just now";
      if (minutes < 60) return this.pluralize(minutes, "m");
      if (hours < 24) return this.pluralize(hours, "h");
      if (days < 30) return this.pluralize(days, "d");
      if (months < 12) return this.pluralize(months, "month");

      return this.pluralize(years, "year");
    },
    pluralize(value, unit) {
      return `${value}${unit}${value > 1 ? "s" : ""}`;
    },
    fetchHistory(infoBoxAreaId) {
      axios
        .get(`${this.$apiEndPoint}/pibHistory/${infoBoxAreaId}`)
        .then((response) => {
          this.History = response.data;
        })
        .catch((error) => this.$handleError("Error fetching comments:", error));
    },
  },
};
</script>

<style scoped>
.comment-body {
  max-height: 300px;
  overflow-y: auto;
}

.parent-comment {
  font-size: 14px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 15px;
  padding-bottom: 10px;
}
.comment-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 12px;
  text-align: center;
  line-height: 30px;
  color: #fff;
}

.comment-header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.comment-info {
  display: flex;
  flex-direction: column;
}

.comment-author {
  font-weight: bold;
}

.comment-date {
  font-size: 12px;
  color: #aaa;
}

/* Display text with line breaks and preformatted content */
.comment-text {
  white-space: pre-wrap; /* Preserve line breaks and spaces */
  font-size: 13px;
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 4px;
  font-family: "Inter", sans-serif;
  margin-left: 25px;
}
.comment-avatar-container {
  display: flex;
  align-items: center;
}
</style>
