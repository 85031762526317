<template>
  <div>
    <Header />
    <div class="content-Container">
      <Nav />
      <div class="containerChild">
        <div class="notes-header">
          <h5 class="notes-title">Notes</h5>
          <div class="notes-buttons">
            <button data-bs-toggle="modal" data-bs-target="#newNoteModal">
              <a href=""
                ><img class="notes-img" src="../../assets/icons/greyEdit.png"
              /></a>
              New Note
            </button>
            <button data-bs-toggle="modal" data-bs-target="#newFolder">
              <span> <img class="plus-img" src="../../assets/icons/plus.png" /></span>
              Create Folder
            </button>
            <button @click="fetchNotes()">
              <span>
                <img class="notes-img" alt="notes " src="../../assets/icons/myNotes.png"
              /></span>
              My Notes
            </button>
            <button @click="fetchStared()">
              <span>
                <img
                  class="notes-img"
                  src="../../assets/icons/favorites.png"
                  alt="Favorite Icon"
                />
              </span>
              Favorites
            </button>
            <button @click="fetchShared()">
              <span
                ><img
                  class="notes-img"
                  alt="notes"
                  src="../../assets/icons/sharedWithMe.png"
              /></span>
              Shared With Me
            </button>
          </div>
        </div>

        <!-- Cards Section -->
        <div class="cards-container">
          <div
            class="card folder"
            @dragover.prevent
            @dragenter.prevent
            @drop="onDrop(data.id)"
            v-for="data in filteredFolders"
            :key="data.id"
          >
            <div class="folder-header" @click="selectFolder(data.id, data.folderName)">
              <img style="" class="folder-img" src="../../assets/icons/folders.png" />
              <div>
                <h6>{{ data.folderName ? truncateText(data.folderName, 40) : "" }}</h6>
              </div>
            </div>

            <div class="card-footer">
              <div class="task-stats">
                <img
                  class="profile-image"
                  :src="
                    data['employees.signedUrl']
                      ? data['employees.signedUrl']
                      : '../../assets/icons/profile.png'
                  "
                  alt="Profile Picture"
                />
                <div class="profile-details">
                  <span class="profile-name"
                    >{{ data["employees.firstName"] }}
                    {{ data["employees.lastName"] }}</span
                  >
                  <small class="profile-date">{{ data.created_at }}</small>
                </div>

                <div v-if="data.commentsCount" class="stat">
                  <img
                    class="stat-icon"
                    src="../../assets/icons/attachment.png"
                    alt="Comment Icon"
                  />
                  <span>{{ data.commentsCount }}</span>
                </div>
                <div v-else class="stat"></div>
              </div>

              <div class="stat">
                <img
                  data-bs-toggle="modal"
                  data-bs-target="#deleteModal"
                  class="stat-icon"
                  src="../../assets/icons/redDelete.png"
                  alt="Comment Icon"
                  @click="clickedFolder(data.id)"
                />
                <img
                  class="stat-icon"
                  src="../../assets/icons/logout.png"
                  alt="Comment Icon"
                  data-bs-toggle="modal"
                  data-bs-target="#folderModal"
                  @click="clickedFolder(data.id)"
                />
              </div>
            </div>
          </div>

          <div
            class="card"
            @click="selectNote(data.id)"
            @dragstart="startDrag(data)"
            v-for="data in filteredNotes"
            :key="data.id"
          >
            <div class="card-header" draggable="true">
              <h5 class="title">
                {{ data.header ? truncateText(data.header, 20) : "" }}
              </h5>
              <div class="profile-details">
                <small class="profile-date"></small>
              </div>
              <div class="icons">
                <img
                  @click="markUnFav(data.id)"
                  v-if="data.noteStarted === 'yes'"
                  class="profile-icon"
                  src="../../assets/icons/yellowFavourite.png"
                  alt="Star Icon"
                />
                <img
                  v-else
                  @click="markFav(data)"
                  class="profile-icon"
                  src="../../assets/icons/yellowUnfavourite.png"
                  alt="Star Icon"
                />
              </div>
              <div
                class="collaborator-container"
                v-if="data.collabs && data.collabs.length > 0"
              >
                <span v-if="data.collabs.length > 2" class="collaborator-count">
                  +{{ data.collabs.length - 1 }}
                </span>
                <span
                  v-for="(collab, index) in data.collabs.slice(0, 1)"
                  :key="index"
                  class="collaborator-initials"
                  :style="{ zIndex: index }"
                >
                  {{ collab.initials }}
                </span>
              </div>
            </div>
            <div
              class="card-body"
              draggable="true"
              data-bs-toggle="modal"
              data-bs-target="#viewNote"
            >
              <p class="task-description">
                {{ data.description ? truncateText(data.description, 125) : "" }}
              </p>
            </div>
            <div class="card-footer">
              <div class="task-stats">
                <img
                  class="profile-image"
                  :src="
                    data['employees.signedUrl']
                      ? data['employees.signedUrl']
                      : '../../assets/icons/profile.png'
                  "
                  alt="Profile Picture"
                />
                <div class="profile-details">
                  <span class="profile-name"
                    >{{ data["employees.firstName"] }}
                    {{ data["employees.lastName"] }}</span
                  >
                  <small class="profile-date">{{ data.date }}</small>
                </div>
              </div>
              <div class="counts">
                <img
                  class="stat-icon"
                  src="../../assets/icons/commentBlack.png"
                  alt="Comment Icon"
                />
                <span> {{ data.commentsCount }}</span>
              </div>
              <div class="counts">
                <img
                  class="stat-icon"
                  src="../../assets/icons/attachment.png"
                  alt="Comment Icon"
                />
                <span>{{ data.attachmentsCount }}</span>
              </div>
              <div class="counts">
                <img
                  class="stat-icon"
                  src="../../assets/icons/logout.png"
                  alt="Comment Icon"
                  data-bs-toggle="modal"
                  data-bs-target="#folderModal"
                  @click="folderID"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <NewNoteModal
      :folderId="selectedFolderId"
      :clickedFolder="clickedFolderID"
      @save-note="saveNote"
      @save-folder="saveFolder"
      @delete-folder="handleDeleteFolder"
    />
    <ViewNote
      :folderId="selectedFolderId"
      :noteId="selectedNoteId"
      @delete-note="deleteNote"
      @updateNote="handleUpdateNote"
    />
    <MoveFolderModal
      :FolderId="ParentFolderId"
      :NoteId="selectedNoteId"
      :clickedFolder="clickedFolderID"
      :type="type"
      @FolderidNull="handleFolderIdNull"
      @move-note="moveNote"
      @move-folder="moveFolder"
      @save-folder="saveFolder"
      @creating-folder="saveFolder"
    />
  </div>
</template>

<script>
import Nav from "../Nav.vue";
import Header from "../Header.vue";
import NewNoteModal from "../Modal/NewNoteModal.vue";
import ViewNote from "../Modal/ViewNote.vue";
import MoveFolderModal from "../Modal/MoveFolderModal.vue";
import axios from "axios";

export default {
  components: {
    Header,
    Nav,
    NewNoteModal,
    ViewNote,
    MoveFolderModal,
  },
  data() {
    return {
      NotesDetails: [],
      selectedFolderId: "" || 0,
      selectedNoteId: null,
      ParentFolderId: 0,
      clickedFolderID: "",
      type: "",
      currentButton: "",
      draggedNote: null,
    };
  },

  computed: {
    filteredFolders() {
      return this.NotesDetails.filter((item) => item.contentType === "Folder");
    },
    filteredNotes() {
      return this.NotesDetails.filter((item) => item.contentType !== "Folder");
    },
  },

  mounted() {
    this.fetchNotes();
  },

  methods: {
    // Handles dragging
    startDrag(note) {
      this.draggedNote = note;
    },

    // Handles dropping the note into a folder
    async onDrop(folderId) {
      console.log("Dropped into folder:", folderId);
      console.log("Dragged Note:", this.draggedNote);

      if (!this.draggedNote) return;

      try {
        await axios.put(`${this.$apiEndPoint}/notes/updateNote/${this.draggedNote.id}`, {
          folderId,
        });

        this.$toast("Note moved successfully", "success");
        this.fetchNotes();
        this.draggedNote = null;
      } catch (error) {
        console.error("Error moving note:", error);
        this.$toast("Failed to move note", "error");
      }
    },

    async fetchNotes() {
      this.currentButton = "Notes";
      console.log("selected button is:", this.currentButton);

      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/notes/getMyNotes/${this.selectedFolderId}/`
        );
        this.NotesDetails = response.data;
        console.log("Fetched Notes:", this.NotesDetails);
      } catch (error) {
        console.error("Error fetching Notes:", error);
      }
    },

    async fetchStared() {
      this.currentButton = "Stared";
      console.log("selected button is:", this.currentButton);

      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/notes/getMyNotes/0/stared`
        );
        this.NotesDetails = [];
        this.NotesDetails = response.data; // No need to clear array first
        console.log("Fetched Starred Notes:", this.NotesDetails);
      } catch (error) {
        console.error("Error fetching Starred Notes:", error);
      }
    },

    async fetchShared() {
      this.currentButton = "Shared";
      console.log("selected button is:", this.currentButton);

      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/notes/getMyNotes/0/shared`
        );
        this.NotesDetails = [];
        this.NotesDetails = response.data; // No need to clear array first
        console.log("Fetched shared Notes:", this.NotesDetails);
      } catch (error) {
        console.error("Error fetching shared Notes:", error);
      }
    },

    async markFav(noteData) {
      console.log(noteData);

      // Find the note in the NotesDetails array using the ID
      let noteToUpdate = this.NotesDetails.find((note) => note.id === noteData.id);

      // Check if the note is found and update it
      if (noteToUpdate) {
        console.log(noteToUpdate);

        noteToUpdate.noteStarted = "yes"; // Update the note's 'noteStarted' status
      }
      try {
        // Call the API to mark the note as 'favorite'
        await axios.post(`${this.$apiEndPoint}/notes/manageStared/${noteData.id}/Yes`);

        // Show a success message
        this.$toast("Marked As Favourite", "success");
      } catch (error) {
        // Handle error case
        console.error("Error marking note as favorite:", error);
        this.$toast("Failed to mark as favorite", "error");
      }
    },

    // async markUnFav(noteData) {
    //   console.log(noteData);

    //   let noteToUpdate = this.NotesDetails.find((note) => note.id === noteData.id);

    //   if (noteToUpdate) {
    //     console.log(noteToUpdate);

    //     noteToUpdate.noteStarted = "no";
    //   }

    //   try {
    //     await axios.post(`${this.$apiEndPoint}/notes/manageStared/${noteData.id}/No`);

    //     // Show a success message
    //     this.$toast("Unmarked As Favourite", "success");
    //   } catch (error) {
    //     // Handle error case
    //     console.error("Error unmarking note as favorite:", error);
    //     this.$toast("Failed to unmark as favorite", "error");
    //   }
    // },

    async markUnFav(id) {
      try {
        await axios.post(`${this.$apiEndPoint}/notes/manageStared/${id}/No`);
        this.$toast("Marked As Unfavourite", "success");
        if (this.currentButton == "Notes") {
          this.fetchNotes();
        } else if (this.currentButton == "Stared") {
          this.fetchStared();
        } else if (this.currentButton == "Shared") {
          this.fetchShared();
        }
      } catch (error) {
        console.error("Error marking note as Unfavorite:", error);
        this.$toast("Failed to mark as Unfavorite", "error");
      }
    },

    selectFolder(folderId, folderName) {
      this.selectedFolderId = folderId;
      this.$router.push({
        name: "ViewFolder",
        params: {
          Id: folderId,
          folderName: folderName,
        },
      });
    },

    folderID() {
      console.log(this.ParentFolderId);
      this.ParentFolderId = 0;
      this.type = "Note";
      console.log(this.ParentFolderId);
    },

    clickedFolder(id) {
      this.ParentFolderId = 0;
      this.clickedFolderID = id;
      this.type = "Folder";
      console.log("clickedFolder is now from viewfolder page is:", this.clickedFolderID);
    },

    handleFolderIdNull() {
      this.ParentFolderId = null;
      console.log("ParentFolderId is now null:", this.ParentFolderId);
    },

    selectNote(NoteId) {
      this.selectedNoteId = NoteId;
      console.log("Selected Note ID:", this.selectedNoteId);
    },

    async saveNote(noteData) {
      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/notes/createNote`,
          noteData
        );
        const newNote = response.data;
        newNote.contentType = "Note"; // Add contentType
        this.NotesDetails.push(newNote); // Append to the list
        this.$toast("Note Created", "success");
      } catch (error) {
        console.error("Error creating note:", error);
        this.$toast("Failed to create note", "error");
      }
    },

    async saveFolder(folderData) {
      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/noteFolder/createFolder`,
          folderData
        );
        const newFolder = response.data;
        newFolder.contentType = "Folder"; // Add contentType
        this.NotesDetails.push(newFolder); // Append to the list
        this.$toast("Folder Created", "success");

        // const selectedFolderId = newFolder.id; // Adjust based on your API response
        // const folderName = newFolder.folderName; // Adjust based on your API response

        // this.selectFolder(selectedFolderId, folderName);
      } catch (error) {
        console.error("Error creating folder:", error);
        this.$toast("Failed to create folder", "error");
      }
    },

    async handleUpdateNote(updatedNote) {
      try {
        const response = await axios.put(
          `${this.$apiEndPoint}/notes/updateNote/${updatedNote.id}`,
          updatedNote
        );
        console.log("Note updated successfully:", response.data);
        this.$toast("Note Updated successful", "success");
        this.fetchNotes();
      } catch (error) {
        console.error("Error updating the note:", error);
        this.$toast("Failed to update the note", "error");
      }
    },

    async moveNote(updatedNote) {
      try {
        const response = await axios.put(
          `${this.$apiEndPoint}/notes/updateNote/${this.selectedNoteId}`, // Ensure `updatedNote.id` exists
          updatedNote
        );
        console.log("Note moved successfully:", updatedNote);
        console.log("Note moved successfully:", response.data);
        this.$toast("Note moved successfully", "success");
        this.fetchNotes();
      } catch (error) {
        console.error("Error updating the note:", error);
        this.$toast("Failed to update the note", "error");
      }
    },

    async moveFolder(updatedNote) {
      console.log(updatedNote);
      console.log(this.clickedFolderID);

      try {
        const response = await axios.put(
          `${this.$apiEndPoint}/noteFolder/updateFolder/${this.clickedFolderID}`, // Ensure `updatedNote.id` exists
          updatedNote
        );
        console.log("folder moved successfully:", response.data);
        this.$toast("folder moved successfully", "success");
        this.fetchNotes(); // Refresh the notes list after updating
      } catch (error) {
        console.error("Error updating the folder:", error);
        this.$toast("Failed to update the folder", "error");
      }
    },

    handleDeleteFolder(folderId) {
      console.log("Received folderId from child:", folderId);

      axios
        .delete(`${this.$apiEndPoint}/noteFolder/deleteFolder/${folderId}`)
        .then((response) => {
          console.log("Folder deleted:", response);

          const index = this.NotesDetails.findIndex((item) => item.id === folderId);
          if (index !== -1) {
            this.NotesDetails.splice(index, 1);
            this.$toast("Folder deleted", "success");
          } else {
            console.error("Folder not found in NotesDetails.");
          }
        })
        .catch((error) => {
          console.error("Error deleting folder:", error.response?.data || error.message);
          this.$toast("Failed to delete folder", "error");
        });
    },

    deleteNote(id) {
      axios
        .delete(`${this.$apiEndPoint}/notes/deleteNote/${id}`)
        .then((response) => {
          console.log("Note deleted:", response);

          if (Array.isArray(this.NotesDetails)) {
            const index = this.NotesDetails.findIndex((item) => item.id === id);
            if (index !== -1) {
              this.NotesDetails.splice(index, 1);
              this.$toast("Note deleted", "success");
            } else {
              console.error("Note not found in the list");
            }
          } else {
            console.error("NotesDetails is not an array");
          }
        })
        .catch((error) => {
          console.error("Error deleting Note:", error);
        });
    },

    truncateText(text, length) {
      if (text && text.length > length) {
        return text.substring(0, length) + "...";
      }
      return text;
    },
  },
};
</script>

<style scoped>
/* 🔹 Default folder style */
.folder {
  border: 2px solid transparent;
  transition: border 0.3s, box-shadow 0.3s, transform 0.2s;
  position: relative;
}

/* 🔹 Hover effect for folders */
.folder:hover {
  background-color: rgba(0, 123, 255, 0.1);
  transform: scale(3.05);
}

/* 🔹 Highlight when a note or folder is dragged over */
.folder.drag-over {
  border: 2px solid #ff9800;
  background-color: rgba(255, 152, 0, 0.2);
  box-shadow: 0 4px 12px rgba(255, 152, 0, 0.5);
}

/* 🔹 Draggable Note & Folder */
[draggable="true"] {
  cursor: grab;
  transition: transform 0.2s, box-shadow 0.3s;
}

/* 🔹 Dragging effect */
[draggable="true"]:active {
  cursor: grabbing;
  opacity: 0.7;
  transform: scale(1.1);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* 🔹 Bounce Animation on Drop */
@keyframes dropBounce {
  0% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

.item-dropped {
  animation: dropBounce 0.3s ease-out;
}

.content-Container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background-color: rgb(213, 213, 213);
}

.containerChild {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto; /* Allow scrolling for the child content */
}

.notes-header {
  position: sticky;
  top: 0; /* Stick to the top of the container */
  z-index: 100; /* Ensure it stays above other content */
  display: flex;
  justify-content: space-between;
  background-color: white; /* Keep a background for better visibility */
  padding: 30px 10px;
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Four equal-width columns */
  gap: 20px;
  padding: 20px;
  width: 100%;
  justify-content: start;
}

.notes-title {
  font-weight: bold;
  margin-left: 22px; /* Remove extra margin */
}

.notes-buttons {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap; /* Allow wrapping for responsiveness */
}

.notes-buttons button {
  color: #202020;
  background-color: #dfdede;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  padding: 8px 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.notes-buttons button:hover {
  background-color: cornflowerbluse;
  transform: translateY(-2px);
  color: #171717;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

.notes-img {
  height: 22px;
  width: 22px;
}

.folder-img {
  height: 100px;
  width: 100px;
  object-fit: cover;
}

.plus-img {
  height: 22px;
  width: 22px;
  padding: 3px;
}

@media (max-width: 768px) {
  .notes-buttons {
    justify-content: center; /* Center buttons on smaller screens */
  }

  .notes-buttons button {
    font-size: 12px; /* Adjust font size for smaller screens */
    padding: 6px 12px; /* Adjust padding for smaller screens */
  }
}

@media (max-width: 480px) {
  .notes-buttons {
    flex-direction: column; /* Stack buttons vertically on very small screens */
    gap: 8px; /* Adjust gap between buttons */
  }

  .notes-buttons button {
    width: 100%; /* Make buttons full-width */
  }
}

/* General Styles */

.card {
  background-color: #ffffff;
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.card:hover {
  transform: translateY(-6px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

/* Card Header */
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  background-color: lavenderblush;
  padding: 10px 15px;
  border: none;
}

.folder-header {
  height: 150px;
  background-color: rgba(244, 244, 244, 0.845);
  padding: 10px 15px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  display: table-column;
  text-align: center;
  align-items: center;
  border: none;
}

.card-header .title {
  font-size: 16px;
  font-weight: 600;
  color: #2c3e50;
  margin: 0;
}

.icons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.profile-icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.profile-icon:hover {
  transform: scale(1.1);
}

.collaborator-container {
  position: relative;
  display: flex;
  align-items: center;
}

.collaborator-initials {
  font-size: 10px;
  background-color: #4884f7;
  border-radius: 50%;
  color: #fcfcfc;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-left: -8px;
  /* Adjust this to control overlap */
  border: 2px solid #fff;
}
.collaborator-count {
  color: #333;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 12px;
}
/* Card Body */
.card-body {
  padding: 15px;
  flex: 1;
  display: flex;
  min-height: 100px;
  border-bottom: 1px solid #e4e4e4;
  flex-direction: column;
}

.task-description {
  font-size: 14px;
  color: #888;
  margin: 5px 0 0;
  line-height: 1.5;
}

/* Card Footer */
.card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  background-color: transparent;
}

/* Card Footer */
.folder-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  background-color: transparent;
}

.task-stats {
  display: flex;
  align-items: center;
  gap: 15px;
}

.stat {
  display: flex;
  align-items: center;
  gap: 25px;
  font-size: 14px;
  color: #333;
}

.counts {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  color: #333;
}

.stat-icon {
  width: 16px;
  height: 16px;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.stat-icon:hover {
  opacity: 1;
}

/* Profile Section */
.profile-details {
  display: flex;
  align-items: center;
  gap: 10px;
}

.profile-image {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  object-fit: cover;
}

.profile-name {
  font-size: 10px;
  font-weight: 500;
  color: #333;
}

.profile-date {
  font-size: 10px;
  color: #888;
}

/* Responsive Design */
@media (max-width: 768px) {
  .cards-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .cards-container {
    grid-template-columns: 1fr;
  }

  .profile-image {
    height: 40px;
    width: 40px;
  }

  .team-member {
    height: 25px;
    width: 25px;
  }
}
</style>
