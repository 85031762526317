<template>
  <div>
    <Header />
    <div class="content-Container">
      <Nav />
      <div class="containerChild">
        <div class="notes-header">
          <h6 class="notes-title" style="cursor: pointer">
            <!-- <i class="fa-regular fa-circle-left" @click="goBack()"></i> -->
            <img
              @click="goBack()"
              class="homefolder"
              style="width: 25px; height: 25px"
              src="../../assets/icons/homefolder.png"
            />
            <i
              class="fa-solid fa-chevron-right"
              style="padding-left: 10px; margin-bottom: 3px"
            ></i>
            <template v-for="(folder, index) in folderHierarchy" :key="index">
              <span
                @click="fetchFoldersAndNotes(folder.id, folder.name)"
                style="padding: 10px"
              >
                {{ folder.name }}
              </span>
              <span v-if="index < folderHierarchy.length - 1"
                ><i class="fa-solid fa-chevron-right"></i
              ></span>
            </template>
          </h6>

          <div class="notes-buttons">
            <div class="notes-buttons">
              <button href="" data-bs-toggle="modal" data-bs-target="#newNoteModal">
                <a><img class="notes-img" src="../../assets/icons/greyEdit.png" /></a>
                New Note
              </button>
              <button data-bs-toggle="modal" data-bs-target="#newFolder">
                <span> <img class="plus-img" src="../../assets/icons/plus.png" /></span>
                Create Folder
              </button>
              <!-- <button @click="goBack()">
                <img class="close-img" src="../../assets/icons/cancel.png" />
                Close
              </button> -->
            </div>
          </div>
        </div>

        <div class="cards-container">
          <div
            class="card folder"
            v-for="data in filteredFolders"
            @dragover.prevent
            @drop="dropNoteOnFolder(data.id)"
            :key="data.id"
          >
            <div
              class="folder-header"
              @click="fetchFoldersAndNotes(data.id, data.folderName)"
            >
              <img class="folder-img" src="../../assets/icons/folders.png" />
              <h6 class="">
                {{ data.folderName ? truncateText(data.folderName, 20) : "" }}
              </h6>
            </div>

            <div class="card-footer">
              <div class="task-stats">
                <img
                  class="profile-image"
                  :src="
                    data['employees.signedUrl']
                      ? data['employees.signedUrl']
                      : '../../assets/icons/profile.png'
                  "
                  alt="Profile Picture"
                />
                <div class="profile-details">
                  <span class="profile-name"
                    >{{ data["employees.firstName"] }}
                    {{ data["employees.lastName"] }}</span
                  >
                  <small class="profile-date">{{ data.created_at }}</small>
                </div>

                <div v-if="data.commentsCount" class="stat">
                  <img
                    class="stat-icon"
                    src="../../assets/icons/attachment.png"
                    alt="Comment Icon"
                  />
                  <span>{{ data.commentsCount }}</span>
                </div>
                <div v-else class="stat"></div>
              </div>

              <div class="stat">
                <img
                  data-bs-toggle="modal"
                  data-bs-target="#deleteModal"
                  class="stat-icon"
                  src="../../assets/icons/redDelete.png"
                  alt="Comment Icon"
                  @click="clickedFolder(data.id)"
                />
                <img
                  class="stat-icon"
                  src="../../assets/icons/logout.png"
                  alt="Comment Icon"
                  data-bs-toggle="modal"
                  data-bs-target="#folderModal"
                  @click="clickedFolder(data.id)"
                />
              </div>
            </div>
          </div>

          <div
            class="card"
            v-for="data in filteredNotes"
            draggable="true"
            @dragstart="dragStart(data.id)"
            :key="data.id"
          >
            <div class="card-header">
              <h5 class="title">
                {{ data.header ? truncateText(data.header, 10) : "" }}
              </h5>
              <div class="profile-details">
                <small class="profile-date"></small>
              </div>
              <div class="icons">
                <img
                  @click="markUnFav(data.id)"
                  v-if="data.noteStarted === 'yes'"
                  class="profile-icon"
                  src="../../assets/icons/yellowFavourite.png"
                  alt="Star Icon"
                />
                <img
                  v-else
                  @click="markFav(data)"
                  class="profile-icon"
                  src="../../assets/icons/yellowUnfavourite.png"
                  alt="Star Icon"
                />
              </div>
              <div
                class="collaborator-container"
                v-if="data.collabs && data.collabs.length > 0"
              >
                <span
                  v-for="(data, index) in data.collabs"
                  :key="index"
                  class="collaborator-initials"
                  :style="{ zIndex: index }"
                >
                  {{ data.initials }}
                </span>
              </div>
            </div>
            <div
              class="card-body"
              @click="selectedNote(data.id)"
              data-bs-toggle="modal"
              data-bs-target="#viewNote"
            >
              <p class="task-description">
                {{ data.description ? truncateText(data.description, 110) : "" }}
              </p>
            </div>
            <div class="card-footer">
              <div class="task-stats">
                <img
                  class="profile-image"
                  :src="
                    data['employees.signedUrl']
                      ? data['employees.signedUrl']
                      : '../../assets/icons/profile.png'
                  "
                  alt="Profile Picture"
                />
                <div class="profile-details">
                  <span class="profile-name"
                    >{{ data["employees.firstName"] }}
                    {{ data["employees.lastName"] }}</span
                  >
                  <small class="profile-date">{{ data.date }}</small>
                </div>
              </div>
              <div class="counts">
                <img
                  class="stat-icon"
                  src="../../assets/icons/commentBlack.png"
                  alt="Comment Icon"
                />
                <span> {{ data.commentsCount }}</span>
              </div>
              <div class="counts">
                <img
                  class="stat-icon"
                  src="../../assets/icons/attachment.png"
                  alt="Comment Icon"
                />
                <span>{{ data.attachmentsCount }}</span>
              </div>
              <div class="counts">
                <img
                  class="stat-icon"
                  src="../../assets/icons/logout.png"
                  alt="Comment Icon"
                  data-bs-toggle="modal"
                  data-bs-target="#folderModal"
                  @click="selectedNote(data.id)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <NewNoteModal
      :folderId="this.ParentFolderId"
      :clickedFolder="clickedFolderID"
      @saving-note="saveNote"
      @create-folder="createFolder"
      @deleting-folder="handleDeleteFolder"
    />

    <MoveFolderModal
      :clickedFolder="clickedFolderID"
      :FolderId="this.ParentFolderId"
      :NoteId="this.selectedNoteId"
      :type="type"
      @Folderid="handleFolderId"
      @moving-note="movingNote"
      @moving-folder="movingFolder"
      @creating-folder="createFolder"
    />

    <ViewNote
      :folderId="selectedFolderId"
      :noteId="selectedNoteId"
      @delete-note="deleteNote"
      @updating-note="UpdateNote"
    />
  </div>
</template>

<script>
import Nav from "../Nav.vue";
import Header from "../Header.vue";
import axios from "axios";
import ViewNote from "../Modal/ViewNote.vue";
import MoveFolderModal from "../Modal/MoveFolderModal.vue";
import NewNoteModal from "../Modal/NewNoteModal.vue";

export default {
  components: {
    Header,
    Nav,
    ViewNote,
    MoveFolderModal,
    NewNoteModal,
  },
  data() {
    return {
      folderDetails: null,
      Folders: [],
      folderHierarchy: [], // To store the hierarchy of folder names and IDs
      folderName: "",
      ParentFolderId: "",
      selectedNoteId: null,
      selectedFolderId: null,
      clickedFolderID: "",
      type: "",
      draggedNoteId: null,
    };
  },

  computed: {
    filteredFolders() {
      return this.Folders.filter((item) => item.contentType === "Folder");
    },
    filteredNotes() {
      return this.Folders.filter((item) => item.contentType !== "Folder");
    },
  },

  mounted() {
    this.folderId = this.$route.params.Id;
    this.folderName = this.$route.params.folderName;
    this.updateFolderHierarchy(this.folderId, this.folderName);
    this.fetchFoldersAndNotes(this.folderId);
  },

  watch: {
    "$route.params": function (newParams) {
      this.folderId = newParams.Id;
      this.folderName = newParams.folderName;
      this.updateFolderHierarchy(this.folderId, this.folderName);
      this.fetchFoldersAndNotes(this.folderId); // Now it's called with the correct ID
    },
  },

  methods: {
    dragStart(noteId) {
      this.draggedNoteId = noteId;
    },

    // Triggered when note is dropped on a folder
    async dropNoteOnFolder(folderId) {
      if (!this.draggedNoteId) return;

      const updatedNote = { folderId }; // Move note to new folder
      try {
        await axios.put(
          `${this.$apiEndPoint}/notes/updateNote/${this.draggedNoteId}`,
          updatedNote
        );

        this.$toast("Note moved successfully", "success");
        this.fetchFoldersAndNotes(this.folderId);
      } catch (error) {
        console.error("Error moving note:", error);
        this.$toast("Failed to move note", "error");
      } finally {
        this.draggedNoteId = null;
      }
    },

    updateFolderHierarchy(folderId, folderName) {
      // Add the current folder to the hierarchy
      const index = this.folderHierarchy.findIndex((folder) => folder.id === folderId);
      if (index === -1) {
        // If the folder is not already in the hierarchy, add it
        this.folderHierarchy.push({ id: folderId, name: folderName });
      } else {
        // If the folder exists, slice the hierarchy to the current folder
        this.folderHierarchy = this.folderHierarchy.slice(0, index + 1);
      }
    },

    clickedFolder(id) {
      this.ParentFolderId = "";
      this.clickedFolderID = id;
      this.type = "Folder";
      console.log("clickedFolder is now from viewfolder page is:", this.clickedFolderID);
    },

    async markFav(noteData) {
      if (!noteData || !noteData.id) {
        console.error("Invalid note data:", noteData);
        this.$toast("Invalid note data", "error");
        return;
      }

      console.log("Marking as favorite:", noteData);

      let noteToUpdate = this.Folders.find((note) => note.id === noteData.id);

      if (noteToUpdate) {
        console.log("Updating local note:", noteToUpdate);
        noteToUpdate.noteStarted = "yes";
      }

      try {
        await axios.post(`${this.$apiEndPoint}/notes/manageStared/${noteData.id}/Yes`);

        this.$toast("Marked As Favourite", "success");
      } catch (error) {
        console.error(
          "Error marking note as favorite:",
          error.response?.data || error.message
        );
        this.$toast("Failed to mark as favorite", "error");
      }
    },

    async markUnFav(id) {
      try {
        // Call API to unmark as favorite
        await axios.post(`${this.$apiEndPoint}/notes/manageStared/${id}/No`);

        // Find index of the note in the NotesDetails array
        const index = this.Folders.findIndex((note) => note.id === id);

        if (index !== -1) {
          // Update the noteStarted property
          this.Folders[index].noteStarted = "no";
        }

        this.$toast("Unmarked As Favourite", "success");
      } catch (error) {
        console.error("Error marking note as Unfavorite:", error);
        this.$toast("Failed to mark as Unfavorite", "error");
      }
    },

    deleteNote(id) {
      axios
        .delete(`${this.$apiEndPoint}/notes/deleteNote/${id}`)
        .then((response) => {
          console.log("Note deleted:", response);

          if (Array.isArray(this.Folders)) {
            const index = this.Folders.findIndex((item) => item.id === id);
            if (index !== -1) {
              this.Folders.splice(index, 1);
              this.$toast("Note deleted", "success");
            } else {
              console.error("Note not found in the list");
            }
          } else {
            console.error("NotesDetails is not an array");
          }
        })
        .catch((error) => {
          console.error("Error deleting Note:", error);
        });
    },

    async movingFolder(updatedNote) {
      console.log(updatedNote);
      console.log(this.clickedFolderID);

      try {
        const response = await axios.put(
          `${this.$apiEndPoint}/noteFolder/updateFolder/${this.clickedFolderID}`, // Ensure `updatedNote.id` exists
          updatedNote
        );
        console.log("folder moved successfully:", response.data);
        this.$toast("folder moved successfully", "success");
        this.fetchFoldersAndNotes(this.folderId);
      } catch (error) {
        console.error("Error updating the folder:", error);
        this.$toast("Failed to update the folder", "error");
      }
    },

    handleDeleteFolder(folderId) {
      console.log("Received folderId from child:", folderId);

      axios
        .delete(`${this.$apiEndPoint}/noteFolder/deleteFolder/${folderId}`)
        .then((response) => {
          console.log("Folder deleted:", response);

          const index = this.Folders.findIndex((item) => item.id === folderId);
          if (index !== -1) {
            this.Folders.splice(index, 1);
            this.$toast("Folder deleted", "success");
          } else {
            console.error("Folder not found in NotesDetails.");
          }
        })
        .catch((error) => {
          console.error("Error deleting folder:", error.response?.data || error.message);
          this.$toast("Failed to delete folder", "error");
        });
    },

    // goBack() {
    //   window.history.back();
    // },

    handleFolderId() {
      this.ParentFolderId = null;
      this.ParentFolderId = this.folderId;
      console.log("ParentFolderId is now on viewpage is:", this.ParentFolderId);
    },

    selectedNote(id) {
      this.selectedNoteId = id;
      this.type = "Note";
      console.log("Selected Note ID:", this.selectedNoteId);
    },

    async UpdateNote(updatedNote) {
      try {
        const response = await axios.put(
          `${this.$apiEndPoint}/notes/updateNote/${updatedNote.id}`,
          updatedNote
        );
        console.log("Note updated successfully:", response.data);
        this.$toast("Note Updated successful", "success");
        this.fetchFoldersAndNotes(this.folderId);
      } catch (error) {
        console.error("Error updating the note:", error);
        this.$toast("Failed to update the note", "error");
      }
    },

    async movingNote(updatedNote) {
      try {
        const response = await axios.put(
          `${this.$apiEndPoint}/notes/updateNote/${this.selectedNoteId}`,
          updatedNote
        );
        console.log("Note moveing successfully:", response.data);
        this.fetchFoldersAndNotes(this.folderId);
        this.$toast("Note moving successfully", "success");
      } catch (error) {
        console.error("Error updating the note:", error);
        this.$toast("Failed to update the note", "error");
      }
    },

    async saveNote(noteData) {
      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/notes/createNote`,
          noteData
        );
        const newNote = response.data;
        newNote.contentType = "Note";
        this.Folders.push(newNote);
        this.$toast("Note Created", "success");
      } catch (error) {
        console.error("Error creating note:", error);
        this.$toast("Failed to create note", "error");
      }
    },

    async createFolder(folderData) {
      console.log(folderData);

      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/noteFolder/createFolder`,
          folderData
        );
        const newFolder = response.data;
        console.log(response.data);

        newFolder.contentType = "Folder"; // Add contentType
        console.log(newFolder);
        console.log(this.Folders);

        this.Folders.push(newFolder); // Append to the list

        // Extract `id` and `name` from the new folder
        // const folderId = newFolder.id; // Adjust based on your API response
        // const folderName = newFolder.folderName; // Adjust based on your API response

        // Call `selectFolder` with the new folder details
        // this.selectFolder(folderId, folderName);
        console.log(this.Folders);

        this.$toast("Folder Created", "success");
      } catch (error) {
        console.error("Error creating folder:", error);
        this.$toast("Failed to create folder", "error");
      }
    },

    async fetchFoldersAndNotes(id, name) {
      console.log("recieved folder from parent:", this.folderId);
      this.ParentFolderId = id;
      try {
        const response = await axios.get(`${this.$apiEndPoint}/notes/getMyNotes/${id}`);
        this.Folders = response.data;
        this.selectFolder(id, name);
        console.log("Fetched folders and Notes:", this.Folders);
      } catch (error) {
        console.error("Error fetching folders and Notes:", error);
      }
    },

    selectFolder(ParentFolderId, folderName) {
      this.selectedFolderId = ParentFolderId;
      this.$router.push({
        name: "ViewFolder",
        params: {
          Id: ParentFolderId,
          folderName: folderName,
        },
      });
    },

    goBack() {
      this.$router.push({
        name: "Notes",
      });
    },

    cardID(id) {
      this.selectedNoteId = id;
    },

    truncateText(text, length) {
      if (text && text.length > length) {
        return text.substring(0, length) + "...";
      }
      return text;
    },
  },
};
</script>

<style scoped>
/* 🔹 Default folder style */
.folder {
  border: 2px solid transparent;
  transition: border 0.3s, box-shadow 0.3s, transform 0.2s;
  position: relative;
}

/* 🔹 Hover effect for folders */
.folder:hover {
  background-color: rgba(0, 123, 255, 0.1);
  transform: scale(3.05);
}

/* 🔹 Highlight when a note or folder is dragged over */
.folder.drag-over {
  border: 2px solid #ff9800;
  background-color: rgba(255, 152, 0, 0.2);
  box-shadow: 0 4px 12px rgba(255, 152, 0, 0.5);
}

/* 🔹 Draggable Note & Folder */
[draggable="true"] {
  cursor: grab;
  transition: transform 0.2s, box-shadow 0.3s;
}

/* 🔹 Dragging effect */
[draggable="true"]:active {
  cursor: grabbing;
  opacity: 0.7;
  transform: scale(1.1);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* 🔹 Bounce Animation on Drop */
@keyframes dropBounce {
  0% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

.item-dropped {
  animation: dropBounce 0.3s ease-out;
}
.containerChild {
  display: flex;
  flex-direction: column;
  padding: 2% 1%;
}

.notes-header {
  display: flex;
  justify-content: space-between; /* Aligns title to the left and buttons to the right */
  align-items: center; /* Vertically centers the content */
  padding-bottom: 20px; /* Space between header and cards */
  border-bottom: 1px solid;
}

.notes-title {
  font-weight: bold;
  margin-left: 22px; /* Remove extra margin */
}

.notes-buttons {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap; /* Allow wrapping for responsiveness */
}

.notes-buttons button {
  color: #202020;
  background-color: #dfdede;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  padding: 8px 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.notes-buttons button:hover {
  background-color: cornflowerbluse;
  transform: translateY(-2px);
  color: #171717;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

.notes-img {
  height: 22px;
  width: 22px;
}

.plus-img {
  height: 22px;
  width: 22px;
  padding: 3px;
}
.close-img {
  height: 22px;
  width: 22px;
  padding: 3px;
}

@media (max-width: 768px) {
  .notes-buttons {
    justify-content: center; /* Center buttons on smaller screens */
  }

  .notes-buttons button {
    font-size: 12px; /* Adjust font size for smaller screens */
    padding: 6px 12px; /* Adjust padding for smaller screens */
  }
}

@media (max-width: 480px) {
  .notes-buttons {
    flex-direction: column; /* Stack buttons vertically on very small screens */
    gap: 8px; /* Adjust gap between buttons */
  }

  .notes-buttons button {
    width: 100%; /* Make buttons full-width */
  }
}

/* General Styles */
.cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
}

.card {
  background-color: #ffffff;
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.card:hover {
  transform: translateY(-6px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

/* Card Header */
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  background-color: lavenderblush;
  padding: 10px 15px;
  border: none;
}

.folder-header {
  height: 150px;
  background-color: rgba(244, 244, 244, 0.845);
  padding: 10px 15px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  display: table-column;
  align-items: center;
  text-align: center;
  border: none;
}

.folder-img {
  height: 100px;
  width: 100px;
  object-fit: cover;
}

.card-header .title {
  font-size: 16px;
  font-weight: 600;
  color: #2c3e50;
  margin: 0;
}

.icons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.profile-icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.profile-icon:hover {
  transform: scale(1.1);
}

.collaborator-container {
  position: relative;
  display: flex;
  align-items: center;
}

.collaborator-initials {
  font-size: 10px;
  background-color: #4884f7;
  border-radius: 50%;
  color: #fcfcfc;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-left: -8px;
  /* Adjust this to control overlap */
  border: 2px solid #fff;
  /* Optional: adds a white border around each badge */
}
/* Card Body */
.card-body {
  padding: 15px;
  flex: 1;
  display: flex;
  min-height: 100px;
  border-bottom: 1px solid #e4e4e4;
  flex-direction: column;
}

.task-description {
  font-size: 14px;
  color: #888;
  margin: 5px 0 0;
  line-height: 1.5;
}

/* Card Footer */
.card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  background-color: transparent;
}

/* Card Footer */
.folder-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  background-color: transparent;
}

.task-stats {
  display: flex;
  align-items: center;
  gap: 15px;
}

.stat {
  display: flex;
  align-items: center;
  gap: 25px;

  font-size: 14px;
  color: #333;
}

.stat-icon {
  width: 16px;
  height: 16px;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.stat-icon:hover {
  opacity: 1;
}

/* Profile Section */
.profile-details {
  display: flex;
  align-items: center;
  gap: 10px;
}

.profile-image {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  object-fit: cover;
}

.profile-name {
  font-size: 10px;
  font-weight: 500;
  color: #333;
}

.profile-date {
  font-size: 10px;
  color: #888;
}

/* Responsive Design */
@media (max-width: 768px) {
  .cards-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .cards-container {
    grid-template-columns: 1fr;
  }

  .profile-image {
    height: 40px;
    width: 40px;
  }

  .team-member {
    height: 25px;
    width: 25px;
  }
}
</style>
