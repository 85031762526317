<template>
  <div>
    <SourceCreate @sourceCreated="fetchSource" />
    <Header />
    <div class="content-Container">
      <Nav />
      <div class="containerChild">
        <div class="SalesSource col-12">
          <div class="row h-10 p-1 SalesSourceHeader align-items-center d-flex">
            <div class="col-12 d-flex align-content-center">
              <div class="row text-size-responsive mt-2 w-100">
                <div class="col-6">
                  <h3 class="mainHeading ms-3">Integrations</h3>
                </div>
                <div class="col-6 d-flex justify-content-end align-items-center gap-3">
                  <button
                    class="btn btn-primary new-integrationbutton"
                    data-bs-toggle="modal"
                    data-bs-target="#integrationModal"
                  >
                    New Integration +
                  </button>
                  <div class="">
                    <img
                      @click="goBack()"
                      class="cancel-icon"
                      src="../../assets/icons/cancelIcon.png"
                      alt="Cancel"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="row mt-1 col-12" v-if="isSalesManager()"></div> -->
          <div class="card-section">
            <div
              class="integration-card"
              v-for="integration in integrations"
              :key="integration.id"
            >
              <div class="card-header">
                <img
                  src="../../assets/icons/meta.png"
                  v-if="integration.name === 'Meta Ads'"
                  alt="Meta Ads"
                  class="logo"
                />
                <img
                  src="../../assets/icons/laptop.png"
                  v-else
                  alt="Meta Ads"
                  class="logo"
                />

                <h6 style="padding-top: 10px; font-weight: bold">
                  {{ integration.name }}
                </h6>
              </div>

              <label class="webhook-label">Webhook</label>
              <div class="webhook-container">
                <input type="text" :value="integration.webhook" />
                <button class="copy-btn" @click="copyToClipboard(integration.webhook)">
                  📋
                </button>
              </div>

              <!-- Uncomment if needed -->
              <!-- 
                <hr />
                <div class="card-footer">
                  <div class="manage-section">
                    <span class="settings-icon">
                      <img class="settingimg" src="../../assets/icons/setting.png" />
                    </span>
                    Manage
                  </div>
                  <div class="toggle-container">
                    <input type="checkbox" id="toggle-switch" :checked="integration.status === 'Linked'" />
                    <label for="toggle-switch" class="toggle-label"></label>
                    <span>{{ integration.status }}</span>
                  </div>
                </div>
                -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Delete Confirmation Modal -->
    <!-- <div
      class="modal fade"
      id="deleteConfirmationModal"
      tabindex="-1"
      aria-labelledby="deleteConfirmationModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteConfirmationModalLabel">
              Confirm Deletion
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">Are you sure you want to delete this item?</div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              @click="cancelDelete"
            >
              Cancel
            </button>
            <button type="button" class="btn btn-primary" @click="confirmDelete">
              Delete
            </button>
          </div>
        </div>
      </div>
    </div> -->
    <NewintegrationModal @metasourceCreated="addMetasource" />
  </div>
</template>

<script>
import Header from "../Header.vue";
import Nav from "../Nav.vue";
import NewintegrationModal from "../Modal/NewIntegrationModal.vue";
import axios from "axios";

export default {
  name: "integration",
  components: {
    Header,
    Nav,
    NewintegrationModal,
  },

  data() {
    return {
      integrations: [],
    };
  },

  mounted() {
    this.fetchIntegrations();
  },

  methods: {
    goBack() {
      window.history.back();
    },

    addMetasource(newMetasource) {
      this.integrations.push(newMetasource); // Add new meta source to integrations array
    },

    async fetchIntegrations() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/source/integration`);
        this.integrations = response.data;
        console.log("the fetched integration data is:", this.integrations);
      } catch (error) {
        console.error("Error fetching integrations:", error);
      }
    },

    copyToClipboard(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.$toast("Copied", "success");
        })
        .catch((err) => {
          console.error("Failed to copy:", err);
        });
    },
  },
};
</script>

<style scoped>
.card-section {
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 cards per row */
  gap: 15px;
  padding: 20px;
  justify-content: center;
}

.integration-card {
  width: 100%; /* Let the grid handle the width */
  max-width: 350px;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  padding: 15px;
  font-family: Arial, sans-serif;
}

@media (max-width: 1200px) {
  .card-container {
    grid-template-columns: repeat(3, 1fr); /* 3 cards per row */
  }
}

@media (max-width: 900px) {
  .card-container {
    grid-template-columns: repeat(2, 1fr); /* 2 cards per row */
  }
}

@media (max-width: 600px) {
  .card-container {
    grid-template-columns: repeat(1, 1fr); /* 1 card per row */
  }
}

.card-header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.logo {
  width: 40px;
  height: 40px;
}

.webhook-label {
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
  display: block;
}

.webhook-container {
  display: flex;
  align-items: center;
  background: #f5f5f5;
  padding: 8px;
  border-radius: 6px;
  margin-top: 5px;
}

.webhook-container input {
  border: none;
  background: transparent;
  flex-grow: 1;
  font-size: 14px;
}

.copy-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.manage-section {
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
}

.settings-icon {
  margin-right: 5px;
}

.settingimg {
  height: 16px;
  width: 16px;
}

.toggle-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

/* Toggle Switch */
#toggle-switch {
  display: none;
}

.toggle-label {
  width: 30px;
  height: 16px;
  background: grey;
  border-radius: 15px;
  position: relative;
  cursor: pointer;
  display: inline-block;
}

.toggle-label::after {
  content: "";
  width: 14px;
  height: 14px;
  background: white;
  position: absolute;
  border-radius: 50%;
  top: 1px;
  left: 2px;
  transition: 0.3s;
}

#toggle-switch:checked + .toggle-label {
  background: green;
}

#toggle-switch:checked + .toggle-label::after {
  left: 15px;
}

.SalesSource {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.cancel-icon {
  height: 22px;
  width: 22px;
}

.new-integrationbutton {
  padding: 5px;
  font-size: small;
}

/* Header styling */
.SalesSourceHeader {
  border-bottom: 5px solid #bcbfc6;
}

@media (max-width: 768px) {
  .CreateSourceButton {
    font-size: 0.8rem;
    padding: 3px 8px;
  }

  .table-wrapper {
    max-height: 85%;
    overflow-y: auto;
  }
  .createSourcebutton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

/* Additional media queries for desktop version can be added here */
</style>
